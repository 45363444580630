<template>
  <div class="overflow-auto">
    <ValidationObserver v-slot="{ validate }">
      <form @submit.prevent>
        <div class="flex flex-col justify-center p-4">
          <div class="form-elements-doubled">
            <span class="labels-of-forms">Şirket</span>
            <span>
              {{ currentSirket.isim }}
            </span>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Şube</label>
            <div class="flex flex-col">
              <ValidationProvider name="Şube" rules="required" v-slot="{ errors }">
                <wo-select v-model="values.subeValue" :data="subeler" :error="errors">
                  <template v-slot:defaultValue>
                    <wo-select-item itemValue="" text="-Seçiniz-" />
                  </template>
                  <template v-slot:default="{ item }">
                    <wo-select-item
                      :itemValue="item.id"
                      :text="item.isim"
                      :key="'sube' + item.id"
                    />
                  </template>
                </wo-select>
              </ValidationProvider>
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4"> Seri-Sıra </label>
            <div class="flex">
              <WOSerialSequence
                module="maliyet-analiz"
                :serialValue.sync="values.seriValue"
                :sequenceValue.sync="values.siraValue"
              />
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Tarih</label>
            <ValidationProvider name="Tarih" rules="required" v-slot="{ errors }">
              <WODatePicker v-model="values.tarihValue" :error="errors" />
            </ValidationProvider>

            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">Depo</label>
            <wo-select v-model="values.depoValue" :data="depolar">
              <template v-slot:defaultValue>
                <wo-select-item itemValue="tumu" text="-Tümü-" />
              </template>
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="'depo' + item.id"
                />
              </template>
            </wo-select>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">İsim</label>
            <ValidationProvider name="İsim" rules="required" v-slot="{ errors }">
              <vs-input
                autocomplete="off"
                v-model="values.isimValue"
                v-validate="'required'"
                name="İsim"
                :danger="!!errors[0]"
                :danger-text="errors[0]"
              />
            </ValidationProvider>
          </div>

          <div>
            <TabloMaliyetAnalizForm
              :tableArray="tableArray"
              :removeItem="removeSatir"
              :birimler="birimler"
              :depo="values.depoValue"
              :tarih="values.tarihValue"
              :sube="values.subeValue"
            />
          </div>

          <div class="flex flex-col mt-4 md:flex-row justify-between">
            <div class="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
              <wo-button
                text="Satır Ekle (F2)"
                data-id="yeni"
                @click="handleSatirEkleButton()"
              />

              <wo-button text="Maliyetleri Güncelle" @click="handleMaliyetleriGuncelle" />

              <wo-button
                text="Temizle (Alt + T)"
                data-id="temizle"
                @click="handleTemizle(true)"
              />
            </div>
            <div
              class="flex flex-col sm:flex-row mt-4 md:mt-0 space-y-2 sm:space-y-0 sm:space-x-2"
            >
              <wo-button
                text="Sepetler"
                @click="handleSepetlerModal(true)"
                @keydown.esc="handleSepetlerModal(false)"
              />

              <wo-button
                v-if="$route.params.id"
                text="Teklif Oluştur"
                @click="handleTeklifOlustur"
              />
            </div>
          </div>

          <label class="mt-2">Açıklama</label>
          <vs-textarea v-model="values.aciklamaValue" />

          <div
            class="flex flex-col sm:flex-row justify-items-start space-y-2 sm:space-y-0 sm:space-x-2"
          >
            <wo-button
              text="Kaydet (Alt + S)"
              data-id="kaydet"
              @click="
                () => {
                  validate().then(handleFormSubmit);
                }
              "
            />
            <wo-button
              v-if="authorizationControl('maliyet_analiz_liste')"
              text="Listeye Dön"
              data-id="geri"
              :href="`${$baseURL}/maliyet_analiz`"
            />
            <wo-button
              v-if="$route.params.id"
              text="Yazdır (Alt + P)"
              data-id="yazdir"
              @click="handlePrint()"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>

    <vs-prompt
      @close="handleSepetlerModal(false)"
      :active.sync="sepetlerModal"
      title="Sepetler"
      :buttons-hidden="true"
    >
      <WOBaskets noPadding class="pb-4" @updateBasket="updateLineSepettoTable" />
    </vs-prompt>
  </div>
</template>

<script>
// Helper functionlar
import {
  getBasketsDetail,
  getStockUnits,
  getUpdateCosts,
  getBranches,
  getDepots,
} from "@/helpers/ApiController";

import {
  fetchMaliyetAnalizDetails,
  saveMaliyetAnalizEkle,
} from "./helpers/ApiFactory.js";

import { numToTrStr } from "@/helpers/NumberController";
import useCompany from "@/consumables/useCompany";
import { authorizationControl } from "@/consumables/useAuthorizationControl";
import {
  errorDialog,
  errorNotify,
  errorConfirmDialog,
  errorConfirmReloadDialog,
} from "@/helpers/errorDialogs";
import { primaryConfirmDialog } from "@/helpers/primaryDialogs.js";

import printCostAnalysis from "./helpers/printCostAnalysis.js";

// Kutuphaneler
import { ref, reactive, computed } from "@vue/composition-api";

// Component ve alakali importlar
import TabloMaliyetAnalizForm from "./TabloMaliyetAnalizForm.vue";
import WOBaskets from "@/components/WO/Baskets/WOBaskets";
import WODatePicker from "@/components/WO/WODatePicker.vue";
import WOSerialSequence from "@/components/WO/WOSerialSequence.vue";

export default {
  components: {
    TabloMaliyetAnalizForm,
    WOBaskets,
    WODatePicker,
    WOSerialSequence,
  },
  setup(props, { root }) {
    // #region Data Initializations ============================
    const currentSirket = useCompany();

    const costAnalysisID = computed(() => {
      return root.$route.params.id;
    });

    const values = reactive({
      subeValue: "",
      seriValue: "",
      siraValue: "",
      tarihValue: "",
      depoValue: "tumu",
      isimValue: "",
      aciklamaValue: "",
    });

    const tableArray = reactive({
      list: [],
    });

    const subeler = ref([]);
    const depolar = ref([]);
    const birimler = ref([]);
    // #endregion

    // #region Table Functions ================================
    const createNewTableObj = () => {
      return {
        urun: "",
        urunId: 0,
        urunKod: "",
        urunIsim: "",
        kaynakUrun: "",
        kaynakUrunKod: "",
        kaynakUrunIsim: "",
        kaynakUrunId: 0,
        birim: birimler.value[0].id, // val.stok_birim1_ad
        kaynakUrunMaliyeti: "0,00",
        verimlilikOrani: 0,
        maliyetDusurucuOran: 0,
        sarfiyat: 0,
        iscilikPayi: 0,
        nihaiMaliyet: 0,
        minimumMarjin: 0,
        maksimumMarjin: 0,
      };
    };

    const applyNewRow = (newRow) => {
      root.$set(tableArray.list, tableArray.list.length, newRow);
      root.$nextTick(() => {
        document
          .querySelector(".tr-values:last-child")
          .children[1].querySelector("input")
          .focus();
      });
    };

    const handleSatirEkleButton = () => {
      const newRow = createNewTableObj();
      applyNewRow(newRow);
    };

    const removeSelectedRow = (index) => {
      tableArray.list.splice(index, 1);
    };

    const removeSatir = (index) => {
      document.activeElement.blur();

      const acceptRemoveSatir = () => {
        removeSelectedRow(index);
        const tablo = document.querySelector(".vs-table");
        const satir = tablo.children[index ? index : 1];
        if (satir) {
          satir.querySelector("input").focus();
        }
      };

      errorConfirmDialog("Silme işlemini onaylıyor musunuz?", acceptRemoveSatir);
    };
    // #endregion

    // * Dialoglar =======================================
    // Maliyetleri Gunceller Handlerlar
    const handleMaliyetleriGuncelleOk = () => {
      const postData = {
        tarih: values.tarihValue,
        depo: values.depoValue,
        coklu_depo: values.depoValue === "tumu" ? 1 : 0,
        stok_id: tableArray.list.map((item) => {
          if (item.kaynakUrunId) {
            return item.kaynakUrunId;
          }
          return item.urunId;
        }),
      };
      const kaynakUrunMaliyetiChildIndex = 4;
      const tableRowIndexOffset = 2;
      getUpdateCosts(postData)
        .then((response) => {
          for (const i in tableArray.list) {
            const row = tableArray.list[i];
            const stockId = row.kaynakUrunId ? row.kaynakUrunId : row.urunId;
            row.kaynakUrunMaliyeti = numToTrStr(response.data[0][stockId]);
            document
              .querySelector(`.tr-values:nth-child(${i + tableRowIndexOffset})`)
              .children[kaynakUrunMaliyetiChildIndex].querySelector("input")
              .focus();
          }
        })
        .catch((response) => {
          console.log(response);
        });
    };

    const handleMaliyetleriGuncelle = () => {
      if (tableArray.list.length === 0) {
        errorNotify("Maliyetleri güncellemek için ilk önce satır eklemelisiniz.");
      } else if (!values.tarihValue) {
        errorDialog("Maliyetleri güncellemek için ilk önce tarih seçmelisiniz.");
      } else {
        primaryConfirmDialog(
          "Maliyetlerin güncellemesini onaylıyor musunuz?",
          handleMaliyetleriGuncelleOk
        );
      }
    };

    // Temizle Handlerlar
    const handleTemizle = () => {
      if (tableArray.list.length > 0) {
        errorConfirmDialog("Liste sıfırlansın mı?", () => tableArray.list.splice(0));
      } else {
        errorNotify("Silme işlemi yapmak için ilk önce satır eklemelisiniz.");
      }
    };

    // #region Basket Functions =============================
    const sepetlerModal = ref(false);

    const handleSepetlerModal = (val) => {
      sepetlerModal.value = val;
    };

    const removeNoIdRows = () => {
      for (let row = tableArray.list.length - 1; row >= 0; row--) {
        if (!tableArray.list[row].urunId) {
          removeSelectedRow(row);
        }
      }
    };

    const applyBasketDetails = (data) => {
      for (const listItem of data) {
        const newLine = createNewTableObj();

        newLine.urun = `${listItem.stok_kod}  ${listItem.stok_isim}`;
        newLine.urunKod = listItem.stok_kod;
        newLine.urunIsim = listItem.stok_isim;
        newLine.urunId = listItem.id;
        newLine.birim = listItem.stok_birim1_ad;

        applyNewRow(newLine);
      }
    };

    const updateLineSepettoTable = ({ id: sepetId }) => {
      getBasketsDetail(sepetId)
        .then((data) => {
          removeNoIdRows();
          applyBasketDetails(data);
          sepetlerModal.value = false;
        })
        .catch(() => {
          errorNotify("Sepet stoklarını getirirken hata oluştu");
        });
    };
    //#endregion

    // Teklif Olustur Dialog
    const handleTeklifOlustur = () => {
      errorConfirmDialog(
        "Eğer gerekli bilgileri doldurup kaydetmezseniz teklif otomatik olarak sistemden silinecektir.",
        () =>
          window.open(
            `${root.$baseURL}/teklifler/${costAnalysisID.value}/maliyet_analiz`,
            "_blank"
          ),
        "Dikkat!"
      );
    };

    // #region CRUD Functions ================================
    const checkIfTableArrayEmpty = () => {
      if (tableArray.list.length === 0) {
        errorDialog("Lütfen satır ekleyiniz.");
        return false;
      }
      return true;
    };

    const checkIfTableArrayNoProduct = () => {
      for (const i in tableArray.list) {
        const item = tableArray.list[i];
        if (item.urunId === 0) {
          errorDialog(`Lütfen geçerli ürün giriniz. (Tablo, Satır: ${parseInt(i) + 1})`);
          return false;
        }
      }
      return true;
    };

    const validateFormSubmit = () => {
      return checkIfTableArrayEmpty() && checkIfTableArrayNoProduct();
    };

    const handleFormSubmit = () => {
      document.activeElement.blur();
      if (validateFormSubmit()) {
        saveMaliyetAnalizEkle(values, tableArray.list, costAnalysisID.value)
          .then(() => (window.location = `${root.$baseURL}/maliyet_analiz`))
          .catch(() => {
            errorDialog(
              "Maliyet analizi kaydederken bir sıkıntı oluştu. Lütfen tekrar deneyiniz."
            );
          });
      }
    };

    const fetchDetails = async () => {
      try {
        const data = await fetchMaliyetAnalizDetails(
          costAnalysisID.value,
          createNewTableObj
        );

        Object.assign(values, data.values);

        tableArray.list = [...data.tableArray];
      } catch (e) {
        errorConfirmReloadDialog(
          "Detayları alırken bir sıkıntı oluştu. Lütfen sayfayı yenileyiniz."
        );
      }
    };

    //! API CALLS
    const applyAPICalls = async () => {
      try {
        const waitSube = getBranches();
        const waitDepo = getDepots();
        const waitUnits = getStockUnits();

        await Promise.all([waitSube, waitDepo, waitUnits]);
        subeler.value = await waitSube;
        depolar.value = await waitDepo;
        birimler.value = await waitUnits;
      } catch (error) {
        errorConfirmReloadDialog(error);
      }

      if (costAnalysisID.value) {
        fetchDetails();
      }
    };
    applyAPICalls();

    const handlePrint = () => {
      printCostAnalysis(
        costAnalysisID.value,
        currentSirket.value.isim,
        values,
        depolar.value,
        tableArray.list
      );
    };

    return {
      currentSirket,
      values,
      subeler,
      depolar,
      birimler,
      handleFormSubmit,
      handleSepetlerModal,
      sepetlerModal,
      updateLineSepettoTable,
      handleTemizle,
      handleSatirEkleButton,
      handleMaliyetleriGuncelle,
      handleTeklifOlustur,
      tableArray,
      removeSatir,
      handlePrint,
      authorizationControl,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.labels-of-forms {
  @apply w-20 self-start md:self-center mr-4;
}

.form-elements-doubled {
  @apply flex flex-col md:flex-row  mb-4;
}
</style>
