import { factory, addDimensionPhrase, addInformationPhrases } from '@/helpers/ApiController.js';
import { useModelPlanlamaState } from '../composables/useModelPlanlamaState';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { trStrToNum, numToTrStr } from '@/helpers/NumberController';
import Vue from 'vue';
import { dateFromClient } from '../../../../helpers/clientToServerConversion';
import { dateFromServer } from '../../../../helpers/serverToClientConversion';
import { authorizationControl } from '@/consumables/useAuthorizationControl';
import { id, formsData } from '../composables/formsData';
// import FileSaver from 'file-saver';


//numToTrStr
const {
  state,
  stateGenerator,
  detailsTableFormTemplate,
  exchangeRates,
  isConfirmedDisabled,
  isPrototypeDisabled,
  currencyInputValue,
  newDimensionPhrases,
  stokOzellikler,
  newMachineInfoTitles,
  selectedColors,
  selectedSizes,
  colorsWithID,
  idListByColor,
  idListBySize,
} = useModelPlanlamaState();

export async function deleteImage(id) {
  try {
    const url = 'model-planning/image-delete';
    await factory.post(url, {
      image_id: id
    });
    primaryDialog('Resim başarıyla silindi.');
  } catch (error) {
    if (typeof error === 'string') {
      throw 'Resmi silerken bir hata oluştu.';
    } else {
      throw error.response.message;
    }
  }
}

export async function downloadPDF(html, name, code) {
  const mywindow = window.open('', 'PRINT', 'height=1000,width=1000');
  mywindow.document.write('<html><head><title>',
    `Model-${name}-${code}`,
    '</title><style> table,th, td {border: 1px solid black; border-collapse: collapse;}td {padding: 4px;}img {width: 50%;}#table3, #table4, #table5, #table6 {width: 100%;}td {text-align: right;}.center {text-align: center}</style></head><body ><table><tr><th',
    html,
    '</body></html>');
  mywindow.document.close();
  mywindow.onload = function () {
    mywindow.focus();
    mywindow.print();
    // mywindow.close();
  };
}

const apiInfoMapper = [
  { server: 'id', client: 'id' },
  { server: 'house_id', client: 'houseId' },
  { server: 'sube', client: 'branch' },
  { server: 'draftId', client: 'draftID' },
  { server: 'model_code', client: 'modelCode' },
  { server: 'model_id', client: 'modelID' },
  { server: 'siparis_no', client: 'orderNumber' },
  { server: 'order_id', client: 'orderID' },
  { server: 'model_name', client: 'modelName' },
  { server: 'product_description', client: 'productDescription' },
  { server: 'yarn_quality', client: 'yarnQuality' },
  { server: 'yikama_ibaresi', client: 'yikamaIbaresi' },
  { server: 'varyant_paketleme', client: 'paketleme' },
  { server: 'varyant_etiket', client: 'etiket' },
  { server: 'process', client: 'process' },
  { server: 'depot_id', client: 'depot' },
  { server: 'model_description', client: 'explanation' },
  { server: 'model_price', client: 'currentCurrencyRate', format: 'number' },
  { server: 'model_currency', client: 'currentCurrency' },
  { server: 'status', client: 'status' },
  { server: 'main_current_id', client: 'mainContractorID' },
  { server: 'seri', client: 'serial' },
  { server: 'sira', client: 'sequence' },
  { server: 'collection', client: 'collection' },
  { server: 'date', client: 'date', format: 'date' },
  { server: 'delivery_date', client: 'deliveryDate', format: 'date' },
  { server: 'variants', client: 'modelVariants' },
  { server: 'final_product_id', client: 'finalProductID' },
  { server: 'final_product_name', client: 'finalProductName' },
  { server: 'machine_id', client: 'machineID' },
  { server: 'other_informations', client: 'otherInformations' },
];

const apiCalculationsMapper = [
  { server: 'direct_material_cost', client: 'directMaterialCosts' },
  { server: 'indirect_cost', client: 'indirectCosts' },
  { server: 'smm', client: 'smm' },
  { server: 'upload_cost', client: 'loadingCosts' },
  { server: 'sales_and_organization', client: 'salesAndOrganization' },
  { server: 'profit_margin', client: 'profitMargin' },
  { server: 'profit_net_selling_price', client: 'netProfitSalesPrice' },
  { server: 'discount', client: 'discount' },
  { server: 'last_selling_price', client: 'lastSalesPrice' }
];

const apiDetailsMapper = [
  { server: 'id', client: 'id' },
  { server: 'entry_type', client: 'entryType' },
  { server: 'entry_id', client: 'entryID' },
  { server: 'entry_name', client: 'entryName' },
  { server: 'current_id', client: 'prevalentID' },
  // { server: 'current_name', client: 'prevalentName' },
  { server: 'quantity', client: 'amount', format: 'number' },
  { server: 'order_quantity', client: 'orderAmount', format: 'number' },
  { server: 'unit', client: 'unit' },
  { server: 'cost', client: 'cost', format: 'number' },
  { server: 'recete_id', client: 'recete_id' },
  { server: 'currency', client: 'currency' },
  { server: 'semi_product_detail', client: 'semiProductDetails' },
  { server: 'production_slip_data', client: 'productionSlipData', format: 'object' },
  { server: 'process', client: 'process' },
  { server: 'color', client: 'color' },
  { server: 'size', client: 'size' },
  {
    server: 'main_currency_price',

    client: 'mainCurrencyPrice',
    format: 'number'
  },
  { server: 'description', client: 'explanation' },
  { server: 'is_primary_material', client: 'primaryMaterial' },
  // { server: 'uretim_id', client: 'productionId' },
  // { server: 'is_production', client: 'slipIsCreated' }
];

export const productSlipMapper = [
  { server: 'id', client: 'stockID' },
  { server: 'stok_isim', client: 'stockName' },
  { server: 'stok_kod', client: 'stockCode' },
  { server: 'para_birimi', client: 'currency' },
  { server: 'kullanilan_recete', client: 'usingRecipe' },
  // { server: 'siparis_birimno', client: 'unit'},
  // { server: 'stok_fiyat', client: 'price' },
];

export async function getLabels() {
  try {
    const url = 'model-planning/get-labels';
    const response = await factory.get(url);
    for (const key in formsData) {
      formsData[key].name = response.data[formsData[key].serverKey] ? response.data[formsData[key].serverKey] : formsData[key].name;
    }
    id.value = response.data.id;
  } catch (error) {
    errorDialog(error);
  }
}

export async function updateLabels(id, labels) {
  try {
    const url = 'model-planning/update-labels';
    const response = await factory.post(url, { id, labels });
    return response;
  } catch (error) {
    errorDialog(error);
  }
}

const apiDetailsMapperToServer = array => {
  const arr = [];
  for (const item of array) {
    const mappedItem = apiDetailsMapper.reduce((acc, a) => {
      if (a.format === 'number') {
        acc[a.server] = trStrToNum(item[a.client]);
      } else if (a.format === 'date') {
        acc[a.server] = dateFromClient(item[a.client]);
      } else if (a.format === 'object') {
        acc[a.server] = JSON.stringify(item[a.client]);
      } else {
        acc[a.server] = item[a.client];
      }
      return acc;
    }, {});
    arr.push(mappedItem);
  }
  return arr;
};

const apiCalculationsMapperToServer = data => {
  return apiCalculationsMapper.reduce((acc, item) => {
    acc[item.server] = trStrToNum(data[item.client]);
    return acc;
  }, {});
};

const apiMapperToServer = stateKey => {
  const currentState = state[stateKey];
  let result = apiInfoMapper.reduce((acc, item) => {
    if (item.format === 'number') {
      acc[item.server] = trStrToNum(currentState.info[item.client]);
    } else if (item.format === 'date') {
      acc[item.server] = dateFromClient(currentState.info[item.client]);
    } else {
      acc[item.server] = currentState.info[item.client];
    }
    return acc;
  }, {});
  result.model_id = state['design'].model_id;
  result.model_code = state['design'].model_code;
  result.model_name = state['design'].model_name;
  result.house_id = state['design'].id;
  result.seri = result.seri.toUpperCase();
  result.model_images = [...currentState.info.imageFiles];
  currentState.info.imageUrls.forEach(item => {
    result.model_images.push(item);
  });
  result = {
    ...result,
    ...apiCalculationsMapperToServer(currentState.details.calculations)
  };
  const composedDetails = [
    ...currentState.details.stocks,
    ...currentState.details.semiProducts,
    ...currentState.details.expenses
  ];

  
  result.model_planning_detail = apiDetailsMapperToServer(composedDetails);

  result.model_planning_detail.filter(detail => detail.entry_type === '2').forEach(item => { item["subId"] = '0'; });

  if (result.model_planning_detail[1]) {
    for (let i = 0; i < result.model_planning_detail[1]; i++) {
      if (result.model_planning_detail[1].semi_product_detail) {
        result.model_planning_detail[1].semi_product_detail = JSON.stringify(
          result.model_planning_detail[1].semi_product_detail
        );
      }
    }
  }
  if (result.model_planning_detail.length > 0) {
    for (let i = 0; i < result.model_planning_detail.length; i++) {
      if (result.model_planning_detail[i].semi_product_detail) {
        result.model_planning_detail[i].semi_product_detail.forEach((item, index, arr) => { 
          if (item.entryType === "1") {
            arr[index] = { ...item, rowId: `${result.model_planning_detail[i].id}}`, subId: String(index)};
          }
        });
        result.model_planning_detail[
          i
        ].semi_product_detail = JSON.stringify(
          result.model_planning_detail[i].semi_product_detail
        );
      }
    }
  }

  // result['variant_recipe_data'] = JSON.stringify({
  //   colorRecipes: currentState.details.colorRecipes,
  //   sizeRecipes: currentState.details.sizeRecipes
  // });

  result.currencies = exchangeRates.value[stateKey].map(a => a.kod);
  result.currency_price = exchangeRates.value[stateKey].map(a =>
    trStrToNum(a.kur)
  );
  return result;
};

const modelDesignDataMapperForServer = () => {
  const dataToSend = state['design'];
  delete dataToSend['designer_name'];
  dataToSend.model_images = dataToSend.image_files ? [...dataToSend.image_files] : [];
  state['design'].image_urls.forEach(url => dataToSend.model_images.push(url));
  delete dataToSend['image_files'];
  delete dataToSend['image_urls'];
  return dataToSend;
};

export async function updateModelPlanning(stateKey) {
  try {
    const url = stateKey === 'design' ? '/model-house/update' : 'model-planning/update';
    primaryDialog('Model Güncelleniyor. Lütfen Bekleyiniz...');
    let dataToSend = {};
    // TODO refactoring
    if (stateKey === 'prototype' || stateKey === 'confirmed') {
      dataToSend = apiMapperToServer(stateKey);
    } else if (stateKey === 'design') {
      dataToSend = modelDesignDataMapperForServer();
    }
    await factory.post(url, dataToSend);

  } catch (error) {
    const errorMessage =
      typeof error === 'string'
        ? 'Modeli güncellerken bir hata oluştu.'
        : error.response.data.message;
    errorDialog(errorMessage);
    return -1;
  }
}

export async function storeModelPlanning(stateKey) {
  try {
    primaryDialog('Model Kaydediliyor. Lütfen bekleyiniz...');
    const url = stateKey === 'design' ? '/model-house/store' : '/model-planning/store';
    let dataToSend = {};
    // TODO refactoring
    if (stateKey === 'prototype' || stateKey === 'confirmed') {
      dataToSend = apiMapperToServer(stateKey);
    } else if (stateKey === 'design') {
      dataToSend = modelDesignDataMapperForServer();
    }
    const { data } = await factory.post(url, dataToSend);
    return data.modelPlanningId;
  } catch (error) {
    const errorMessage = 'Modeli kaydederken bir hata oluştu.';
    errorDialog(errorMessage);
    return -1;
  }
}

const validationByState = {
  design: () => {
    return true;
  },
  prototype: (data) => {
    const info = data.info;
    const infoValidator = validationByState.modelPlanningInfoValidator(info);
    if (!infoValidator) {
      return false;
    }
    if (parseInt(info.status) === 1 && !authorizationControl('tasarim_onay')) {
      errorDialog('Yetkileriniz olmadan onaylama yapamazsınız');
      return false;
    }
    const isUnregisteredStockExists = state['prototype'].details.stocks.some(
      a => +a.entryID === 0
    );
    const isUnregisteredSemiProductsExists = state['prototype'].details.semiProducts.some(a => +a.entryID === 0);
    const isUnregisteredExpensesExists = state['prototype'].details.expenses.some(a => +a.entryID === 0);
    if (
      isUnregisteredStockExists ||
      isUnregisteredSemiProductsExists ||
      isUnregisteredExpensesExists
    ) {
      errorDialog(
        'Kayıtlı olmayan kalemler mevcut iken tasarımı onaylayamazsınız.'
      );
      return false;
    }
    return true;
  },

  confirmed: (data) => {
    const info = data.info;
    if (parseInt(info.status) === 1 && !authorizationControl('model_planlama_uretim_onay')) {
      errorDialog('Yetkileriniz olmadan onaylama yapamazsınız');
    }
    const infoValidator = validationByState.modelPlanningInfoValidator(info);
    if (!infoValidator) {
      return false;
    }
    const variants = JSON.parse(JSON.stringify(info.modelVariants));
    let sum = 0;
    for (const color in variants) {
      for (const size in variants[color]) {
        if (variants[color][size] > 0) {
          sum = 1;
        }
      }
    }
    if (sum === 0 && info.status === "1") {
      errorDialog('Üretim onaylama aşamasında varyant miktarları 0 olamaz!');
      return false;
    }
    return true;
  },

  modelPlanningInfoValidator(data) {
    if (!data.serial || data.finalProductName === '') {
      errorDialog('Seri Sıra ve Nihai Ürün alanı boş geçilemez');
      return false;
    }
    return true;
  }
};

export async function saveModelPlanning(stateKey) {
  const data = state[stateKey];
  if (!validationByState[stateKey](data)) {
    return;
  }
  if (newDimensionPhrases.value.length > 0 || Object.keys(stokOzellikler.value).length > 0) {
    const colors = selectedColors[stateKey].map((item) => colorsWithID.value.find(color => color.name === item).id);
    await addDimensionPhrase(newDimensionPhrases.value, stokOzellikler.value, data.info.finalProductID, colors);
  }
  if (newMachineInfoTitles.value.length > 0) {
    await addInformationPhrases(newMachineInfoTitles.value, 5);
  }
  const documentId = stateKey === 'design' ? state[stateKey].id : state[stateKey].info.id;
  if (documentId) {
    const response = await updateModelPlanning(stateKey);
    if (response !== -1) {
      window.location.reload();
    }
    return 0;
  }
  const newModelID = await storeModelPlanning(stateKey);
  if (newModelID !== -1) {
    window.location.href = `${window.location.origin}/model_planlama/${newModelID}/duzenle`;
  }
  return newModelID;
}

const mapForClientDetails = array => {
  const stocks = [];
  const semiProducts = [];
  const expenses = [];
  for (const item of array) {
    const mappedItem = apiDetailsMapper.reduce((acc, a) => {
      if (a.format === 'number') {
        acc[a.client] = numToTrStr(item[a.server], 3);
      } else if (a.format === 'date') {
        acc[a.server] = dateFromServer(item[a.client]);
      } else if (a.format === 'object') {
        acc[a.client] = JSON.parse(item[a.server]);
      } else {
        acc[a.client] = item[a.server];
      }
      return acc;
    }, detailsTableFormTemplate());
    mappedItem.prevalentName = item.cariData;
    mappedItem.entryName = item.entry_name;
    switch (+mappedItem.entryType) {
      case 0:
        if (item.is_selected) {
          mappedItem['isSelected'] = true;
        }
        mappedItem['slipIsCreated'] = item.is_production;
        mappedItem['productionId'] = item.uretim_id;
        semiProducts.push(mappedItem);
        break;
      case 1:
        stocks.push(mappedItem);
        break;
      case 2:
        expenses.push(mappedItem);
        break;
    }
  }
  return [stocks, semiProducts, expenses];
};

const mapForClientInfo = data => {
  return apiInfoMapper.reduce((acc, item) => {
    if (item.format === 'number') {
      acc[item.client] = numToTrStr(data[item.server]);
    } else if (item.format === 'date') {
      acc[item.client] = dateFromServer(data[item.server]);
    } else {
      acc[item.client] = data[item.server];
    }
    return acc;
  }, {});
};

const mapForClientCalculations = data => {
  return apiCalculationsMapper.reduce((acc, a) => {
    acc[a.client] = numToTrStr(data[a.server]);
    return acc;
  }, {});
};

const variantMapperForClient = (data, stateKey) => {
  const variants = Object.assign({}, data);
  delete variants['varyant_stok_id'];
  selectedColors[stateKey] = Object.keys(variants);
  selectedSizes[stateKey] = Object.keys(variants[selectedColors[stateKey][0]]);
  if (selectedSizes[stateKey][selectedSizes[stateKey].length - 1] === "amount") {
    selectedSizes[stateKey].pop();
  }
  if (Object.keys(variants[selectedColors[stateKey][0]]).length === 0 && selectedColors[stateKey].length > 0) {
    for (const key in variants) {
      variants[key] = {};
    }
  }
};

const variantRecipeMapper = (data) => {
  const variantRecipeByColor = {};
  const colorIdList = {};
  const sizeIdList = {};
  for (const key in data.varyant_stok_id) {
    for (const key2 in data.varyant_stok_id[key]) {
      colorIdList[key] = key2;
      sizeIdList[key2] = Object.assign({}, data.varyant_stok_id[key][key2]);
      const merged = [].concat.apply([], (Object.values(data.varyant_stok_id[key][key2]))).map(a => {
        return {
          stockName: a.stok__isim,
          stockID: a.stok_id,
          stockCode: a.stok_kod,
          amount: "0.00",
          cost: "0.00",
          price: "0.00",
          stockUnitID: "",
          currency: "TL",
        };
      });
      variantRecipeByColor[key2] = merged;
    }
  }
  for (const key in sizeIdList) {
    for (const key2 in sizeIdList[key]) {
      sizeIdList[key][key2] = sizeIdList[key][key2][0].stok_id;
    }
  }
  idListByColor.value = colorIdList;
  idListBySize.value = sizeIdList;
  return variantRecipeByColor;
};

const mapForBaseKeysToClient = baseData => {
  const result = stateGenerator();
  result.info = { ...result.info, ...mapForClientInfo(baseData.data) };
  result.info.finalProductName = baseData.nihaiData;
  result.info.mainContractorName = baseData.cariData;
  const calculations = mapForClientCalculations(baseData.data);
  const [stocks, semiProducts, expenses] = mapForClientDetails(
    baseData.detailData
  );
  result.details.sizeRecipes = baseData.data.variant_recipe_data ? JSON.parse(baseData.data.variant_recipe_data).sizeRecipes : {};
  result.details.stocks = stocks;
  result.details.semiProducts = semiProducts;
  result.details.expenses = expenses;
  result.details.calculations = {
    ...result.details.calculations,
    ...calculations
  };
  const stateKey = result.info.draftID > 0 ? 'confirmed' : 'prototype';
  // result.info.modelVariants ? variantMapperForClient(result.info.modelVariants, result.info.draftID) : se;
  if (result.info.modelVariants && Object.keys(result.info.modelVariants).filter(key => key !== 'varyant_stok_id').length > 0) {
    variantMapperForClient(result.info.modelVariants, stateKey);
    if (result.info.modelVariants.varyant_stok_id) {
      result.details.colorRecipes = variantRecipeMapper(result.info.modelVariants);
      if (baseData.data.variant_recipe_data) {
        result.details.colorRecipes = Object.keys(JSON.parse(baseData.data.variant_recipe_data).colorRecipes).length > 0 ? JSON.parse(baseData.data.variant_recipe_data).colorRecipes : result.details.colorRecipes;
      }
    }
  } else {
    result.info.modelVariants = {};
    selectedColors[stateKey] = [];
    selectedSizes[stateKey] = [];
  }
  return result;
};

const mapCurrenciesToClient = (data, stateKey, stateData) => {
  for (const item of data.currencyData) {
    const rateIndex = exchangeRates.value[stateKey].findIndex(
      a => a.kod === item.currency
    );
    exchangeRates.value[stateKey][rateIndex].kur = numToTrStr(
      item.currency_price
    );
    if (item.currency === stateData.info.currentCurrency) {
      currencyInputValue.value[stateKey] = numToTrStr(item.currency_price);
    }
  }
};

const apiMapperToClient = data => {
  const prototype = mapForBaseKeysToClient(data.prototypeData);
  mapCurrenciesToClient(data.prototypeData, 'prototype', prototype);
  prototype.info.imageUrls = [...data.modelHouseData.imageData];
  isConfirmedDisabled.value = +prototype.info.status !== 1;

  if (data.confirmedData.data && Object.keys(data.confirmedData.data).length) {
    const confirmed = mapForBaseKeysToClient(data.confirmedData);
    mapCurrenciesToClient(data.confirmedData, 'confirmed', confirmed);
    confirmed.info.imageUrls = [...data.modelHouseData.imageData];
    Vue.set(state, 'confirmed', confirmed);
  }
  Vue.set(state, 'prototype', prototype);
};

const modelDesignDataMapperForClient = data => {
  const design = Object.assign(data.data, {});
  design.calculations = JSON.parse(data.data.calculations);
  design.machine_information = JSON.parse(data.data.machine_information);
  design.image_urls = [...data.imageData];
  design.image_files = [];
  design.manufacture_details = JSON.parse(data.data.manufacture_details);
  design.weights = JSON.parse(data.data.weights) || [];
  isPrototypeDisabled.value = +data.data.status !== 1;
  for (const key in design.machine_information) {
    if (Array.isArray(design.machine_information[key])) {
      design.machine_information[key] = {};
    }
  }
  Vue.set(state, 'design', design);
};

export function showModelPlanning(id) {
  const url = '/model-planning/get-data-with-id';
  factory
    .get(url, { params: { id } })
    .then(response => {
      if (Object.keys(response.data.prototypeData).length > 0) {
        response.data.prototypeData.detailData.forEach((item, index) => {
          if (item.semi_product_detail) {
            response.data.prototypeData.detailData[
              index
            ].semi_product_detail = JSON.parse(item.semi_product_detail);
          }
        });
      }
      if (Object.keys(response.data.confirmedData).length > 0) {
        response.data.confirmedData.detailData.forEach((item, index) => {
          if (item.semi_product_detail) {
            response.data.confirmedData.detailData[
              index
            ].semi_product_detail = JSON.parse(item.semi_product_detail);
          }
        });
      }
      modelDesignDataMapperForClient(response.data.modelHouseData);
      if (Object.keys(response.data.prototypeData).length > 0) {
        apiMapperToClient(response.data);
      }
    })
    .catch(error => {
      const errorMessage =
        typeof error === 'string'
          ? 'Modeli getirirken bir hata oluştu.'
          : error;
      errorDialog(errorMessage);
    });
}

export async function deleteDetailsRow(id) {
  try {
    const url = `model-planning-detail/${id}`;
    const { data } = factory.post(url);
    return data;
  } catch (error) {
    const errorMessage =
      typeof error === 'string'
        ? 'Satırı silerken bir hata oluştu.'
        : error.response.message;
    errorDialog(errorMessage);
    return false;
  }
}

export async function getStocksForOrder(type, stockID, colors, sizes) {
  try {
    const url = `/model-planning/stocks-for-purchase-order`;
    return await factory.get(url, { params: { type, stockID, colors, sizes } });

  } catch (error) {
    const errorMessage =
      typeof error === 'string'
        ? 'Stokları getirirken bir hata oluştu.'
        : error.response.data.message;
    errorDialog(errorMessage);
    return false;
  }
}
