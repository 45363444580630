<template>
  <div class="space-y-4">
    <div class="y-centered-flex space-x-4">
      <wo-select :data="agreementsFilters" v-model="agreementsFilterValue">
        <template #defaultValue>
          <wo-select-item itemValue="0" text="Tümü" />
        </template>
        <template #default="{ item }">
          <wo-select-item
            :itemValue="item.year"
            :text="item.year"
            :key="`agreements-filter-${item.year}`"
          />
        </template>
      </wo-select>
    </div>

    <wo-table>
      <template #thead>
        <wo-th>Sıra</wo-th>
        <wo-th>Sözleşme Adı</wo-th>
        <wo-th>Durum</wo-th>
        <wo-th>Sözleşme Tarihi</wo-th>
        <wo-th>Başlangıç Tarihi</wo-th>
        <wo-th>Bitiş Tarihi</wo-th>
        <wo-th>Sözleşme ID</wo-th>
        <wo-th>İşlemler</wo-th>
      </template>
      <template>
        <wo-tr
          v-for="(item, index) in filteredAgreements"
          :key="`agreements-${index}`"
          :class="{ 'text-blue-500': isCurrentlyEdited === item.id }"
        >
          <wo-td class="text-center"> {{ index + 1 }} </wo-td>
          <wo-td> {{ item.name }} </wo-td>
          <wo-td> {{ item.state }} </wo-td>
          <wo-td> {{ item.date }} </wo-td>
          <wo-td> {{ item.startDate }} </wo-td>
          <wo-td> {{ item.endDate }} </wo-td>
          <wo-td> {{ item.id }} </wo-td>
          <wo-td>
            <div class="y-centered-flex space-x-4 justify-center">
              <vx-tooltip
                text="Düzenle"
                v-if="authorizationControl('sozlesme_yonet')"
              >
                <feather-icon
                  icon="EditIcon"
                  class="icon-style"
                  @click="() => editAgreement(item.id)"
                />
              </vx-tooltip>
              <vx-tooltip
                text="Kopyala"
                v-if="authorizationControl('sozlesme_yonet')"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="icon-style"
                  @click="() => copyAgreement(item.id)"
                />
              </vx-tooltip>
              <vx-tooltip
                text="Taşı"
                v-if="authorizationControl('sozlesme_yonet')"
              >
                <feather-icon
                  icon="CornerUpRightIcon"
                  class="icon-style"
                  @click="() => moveAgreement(item.id)"
                />
              </vx-tooltip>
              <vx-tooltip
                text="Sil"
                v-if="authorizationControl('sozlesme_yonet')"
              >
                <feather-icon
                  icon="XIcon"
                  class="icon-style text-danger"
                  @click="() => deleteAgreement(item.id)"
                />
              </vx-tooltip>
              <vx-tooltip
                text="İncele"
                v-if="authorizationControl('sozlesme_incele')"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="icon-style"
                  @click="() => examineAgreement(item.id)"
                />
              </vx-tooltip>
              <vx-tooltip text="Yazdır">
                <feather-icon
                  icon="PrinterIcon"
                  class="icon-style text-warning"
                  @click="() => printAgreement(item.id)"
                />
              </vx-tooltip>
            </div>
          </wo-td>
        </wo-tr>
      </template>
    </wo-table>

    <vs-prompt
      color="primary"
      :active.sync="moveDialog"
      accept-text="Taşı"
      cancel-text="İptal"
      @accept="handleMoveAgreementAccept"
      @cancel="resetMoveValues"
    >
      <wo-quick-search
        v-model="movedPrevalent"
        :queryParameters="`?tablo=cari&sirket=${company.id}&q=`"
        @selected="handlePrevalentQuickSearch"
        placeholder="Cari"
        class="justify-center"
        :itemRenderer="prevalentRenderer"
      />
    </vs-prompt>
  </div>
</template>

<script>
import { authorizationControl } from '@/consumables/useAuthorizationControl';

import { ref, computed } from '@vue/composition-api';
import useCompany from '@/consumables/useCompany';
import { errorDialog } from '@/helpers/errorDialogs';

import { prevalentRenderer } from '@/helpers/quickSearchListRenderers/qsPrevalent.js';
import { primaryDialog } from '@/helpers/primaryDialogs';

import {
  deleteAgreement as deleteAgreementAPI,
  moveAgreement as moveAgreementAPI
} from '../../helpers/ApiFactory.js';

export default {
  props: {
    agreements: Array
  },
  setup(props, { emit }) {
    const company = useCompany();

    const isCurrentlyEdited = ref();
    const moveDialog = ref(false);

    const movedPrevalent = ref('');
    const movedPrevalentID = ref(0);
    const toBeMovedAgreement = ref(-1);

    const handlePrevalentQuickSearch = item => {
      if (item.isSelected) {
        movedPrevalent.value = item.text;
        movedPrevalentID.value = item.id;
      } else if (!item.isSelected && movedPrevalentID.value !== 0) {
        movedPrevalent.value = '';
        movedPrevalentID.value = 0;
      }
    };

    const editAgreement = id => {
      emit('editAgreement', id);
    };

    const copyAgreement = id => {
      emit('copyAgreement', id);
    };

    const moveAgreement = id => {
      toBeMovedAgreement.value = id;
      moveDialog.value = true;
    };

    const handleMoveAgreementAccept = () => {
      if (movedPrevalentID.value !== 0) {
        moveAgreementAPI(toBeMovedAgreement.value, movedPrevalentID.value).then(
          () => {
            emit('renewAgreementList');
          }
        );
      } else {
        errorDialog('Lütfen geçerli bir cari seçiniz');
      }
    };

    const resetMoveValues = () => {
      movedPrevalent.value = '';
      movedPrevalentID.value = 0;
    };

    const deleteAgreement = async id => {
      const data = await deleteAgreementAPI(id);
      primaryDialog(data.message);
      emit('renewAgreementList');
    };

    const examineAgreement = id => {
      emit('examineAgreement', id);
    };

    const printAgreement = id => {
      window.open(`/yazdir.php?mod=sozlesme&id=${id}`, '_blank');
    };

    const agreementsFilterValue = ref('');

    const agreementsDateYearIndex = 2;

    const agreementsFilters = computed(() => {
      const years = props.agreements.reduce((acc, curr) => {
        const year = curr.date.split('.')[agreementsDateYearIndex];
        if (!acc.includes(year)) {
          acc.push(year);
        }
        return acc;
      }, []);
      return years.map(y => {
        return { year: y };
      });
    });

    const filteredAgreements = computed(() => {
      return props.agreements.filter(a => {
        return (
          !parseInt(agreementsFilterValue.value) ||
          a.date.split('.')[agreementsDateYearIndex] ===
            agreementsFilterValue.value
        );
      });
    });

    return {
      prevalentRenderer,
      company,
      isCurrentlyEdited,
      moveDialog,
      movedPrevalent,
      handlePrevalentQuickSearch,
      editAgreement,
      copyAgreement,
      moveAgreement,
      handleMoveAgreementAccept,
      resetMoveValues,
      deleteAgreement,
      examineAgreement,
      printAgreement,
      authorizationControl,
      agreementsFilterValue,
      agreementsFilters,
      filteredAgreements,
      movedPrevalentID
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-style {
  @apply cursor-pointer w-5 h-5;
}
</style>
