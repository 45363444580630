<template functional>
  <th
    class="wo-th"
    :class="[data.staticClass || '', data.class]"
    v-bind="data.attrs"
    :style="data.staticStyle"
  >
    <slot />
  </th>
</template>

<script>
export default {
  name: 'WOTh'
};
</script>

<style>
.wo-th {
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  background-color: white;
  z-index: 10;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}
</style>
