<template>
  <vs-button
    @click="$emit('click')"
    @focus="$emit('focus')"
    :type="type"
    :color="buttonColor"
    :textColor="textColor"
    :icon="icon"
    :iconPack="iconPack"
    :iconAfter="iconAfter"
    :lineOrigin="lineOrigin"
    :linePosition="linePosition"
    :gradientDirection="gradientDirection"
    :gradientColorSecondary="gradientColorSecondary"
    :size="size"
    :radius="radius"
    :to="to"
    :href="href"
    :target="target"
    :button="button"
    :disabled="disabled"
    v-html="text"
  >
    <!-- <slot /> -->
  </vs-button>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  props: {
    text: {
      type: String,
    },
    type: {
      default: "filled",
      type: String,
    },
    color: {
      default: "",
      type: String,
    },
    textColor: {
      default: null,
      type: String,
    },
    lineOrigin: {
      default: "center",
      type: String,
    },
    linePosition: {
      default: "bottom",
      type: String,
    },
    gradientDirection: {
      default: "30deg",
      type: String,
    },
    gradientColorSecondary: {
      default: "primary",
      type: String,
    },
    size: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconPack: {
      type: String,
      default: "material-icons",
    },
    iconAfter: {
      default: false,
      type: Boolean,
    },
    radius: {
      default: false,
      type: Boolean,
    },
    to: {
      default: "",
      type: [String, Object],
    },
    href: {
      default: "",
      type: [String, Object],
    },
    target: {
      default: false,
      type: [Boolean, String],
    },
    button: {
      default: "button",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { attrs }) {
    const buttonUsage = attrs["data-id"];
    const buttonColor = computed(() => {
      if (props.color) {
        return props.color;
      }
      if (buttonUsage === "yazdir") {
        return "#ff9f43";
      }
      if (buttonUsage === "yeni") {
        return "#25b364";
      }
      if (buttonUsage === "kaydet") {
        return "#ff5722";
      }
      if (
        buttonUsage === "temizle" ||
        buttonUsage === "satir_sil" ||
        buttonUsage === "geri"
      )
        return "#f43636";
      return "button";
    });

    return {
      buttonColor,
    };
  },
};
</script>
