<template>
  <wo-modal
    :active="subSemiProductModal"
    title="Yarı Mamül Düzenle"
    class="wide-dialog-90vw one-step-behind-dialog"
    :accept="saveNewSubSemiProduct"
    @active="$emit('subSemiProductModal', $event)"
    :closeAct="cancel"
    id="subSemiProductModal"
  >
    <template>
      <!-- <div class="d-inline flex ml-2 mb-2">
        <label class="mr-2 mt-1">Depo</label>
        <wo-quick-search
          queryParameters="?tablo=depolar&uretim_depo=1&q="
          :itemRenderer="nameAndCodeRenderer"
          v-model="productionSlipData.depotName"
          @selected="handleDepot"
        />
        <label class="ml-4 mt-1">Reçete Grubu</label>
        <wo-select
          :data="recipeGroups"
          v-model="productionSlipData.recipeGroupID"
          class="ml-2"
          elementClasses="w-28 m-auto"
        >
          <template #defaultValue>
            <wo-select-item :itemValue="0" text="- Seçiniz -" />
          </template>
          <template #default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.isim"
              :key="`recipeGroup-${item.id}`"
            />
          </template>
        </wo-select>
      </div> -->
      <wo-table>
        <template #thead>
          <wo-th
            v-for="col in titles"
            :key="`sub-semi-product-${semiProductIndex}-${col}`"
          >
            {{ col }}
          </wo-th>
        </template>
        <template>
          <wo-tr v-for="(order, index) in rowCount" :key="'title' + index">
            <wo-td class="text-center">{{ order }}</wo-td>
            <wo-td>
              <wo-select
                :data="entryTypes"
                @focus="currentIndex = index"
                v-model="localData[index].entryType"
                elementClasses="w-32"
              >
                <template #default="{ item, index }">
                  <wo-select-item
                    :itemValue="index"
                    :text="item"
                    :key="`entry-types-new-${index}`"
                  />
                </template>
              </wo-select>
            </wo-td>
            <wo-td class="text-center">
              <wo-quick-search
                :id="`firstInput-${index}`"
                @focus="currentIndex = index"
                inputClasses="m-auto"
                v-model="localData[index].stockName"
                :queryParameters="formEntryConfig.queryParameters"
                @selected="item => formEntryConfig.handler(item)"
                :placeholder="formEntryConfig.placeholder"
                :itemRenderer="
                  item =>
                    nameAndCodeWithLinkRenderer(
                      item,
                      formEntryConfig.link,
                      formEntryConfig.prefix
                    )
                "
              />
            </wo-td>
            <wo-td>
              <wo-quick-search
                :disabled="localData[index].entryType === '0'"
                @focus="currentIndex = index"
                inputClasses="m-auto"
                v-model="localData[index]['prevalentName']"
                :queryParameters="formPrevalentConfig.queryParameters"
                @selected="item => formPrevalentConfig.handler(item)"
                :itemRenderer="prevalentRenderer"
              />
            </wo-td>
            <wo-td class="text-center">
              <wo-select
                :data="stockUnits"
                v-model="localData[index].unit"
                elementClasses="w-28 m-auto"
              >
                <template #defaultValue>
                  <wo-select-item :itemValue="0" text="- Seçiniz -" />
                </template>
                <template #default="{ item }">
                  <wo-select-item
                    :itemValue="item.id"
                    :text="item.isim"
                    :key="`unit-${item.id}`"
                  />
                </template>
              </wo-select>
            </wo-td>
            <wo-td class="text-center">
              <wo-select
                @change="isComplement()"
                :data="[
                  { id: 'E', isim: 'Evet' },
                  { id: 'H', isim: 'Hayır' }
                ]"
                :value="localData[index].isComplement ? 'E' : 'H'"
                elementClasses="w-28 m-auto"
              >
                <template #default="{ item }">
                  <wo-select-item
                    :itemValue="item.id"
                    :text="item.isim"
                    :key="`isComplement-${item.id}`"
                  />
                </template>
              </wo-select>
            </wo-td>
            <wo-td class="text-center">
              <!-- <wo-float-input
                v-model="localData[index].amount"
                @change="calculatePrices"
                class="m-auto"
              /> -->
              <wo-float-input
                :decimal="4"
                v-model="localData[index].amount"
                @change="calculatePrices"
                class="m-auto"
              />
            </wo-td>
            <wo-td class="text-center">
              <wo-float-input
                :decimal="4"
                v-model="localData[index].cost"
                class="m-auto"
                @change="calculatePrices"
              />
            </wo-td>
            <wo-td class="text-center">
              <wo-select
                :data="localExchangeRates"
                v-model="localData[index].currency"
                elementClasses="w-26"
              >
                <template #default="{ item }">
                  <wo-select-item
                    :itemValue="item.kod"
                    :text="item.kod"
                    :key="`currency-table-new-${item.kod}`"
                  />
                </template>
              </wo-select>
            </wo-td>
            <wo-td class="text-center">
              <wo-float-input
                :value="localData[index].price"
                readonly
                class="m-auto"
                :decimal="4"
              />
            </wo-td>
            <wo-td>
              <wo-select
                :disabled="localData[index].entryType === '0'"
                :data="processes"
                v-model="localData[index]['process']"
                elementClasses="w-28 m-auto"
              >
                <template #defaultValue>
                  <wo-select-item :itemValue="0" text="- Seçiniz -" />
                </template>
                <template #default="{ item }">
                  <wo-select-item
                    :itemValue="item.id"
                    :text="item.isim"
                    :key="`process-${item.id}`"
                  />
                </template>
              </wo-select>
            </wo-td>
            <wo-td>
              <base-input
                v-model="localData[index].description"
                :elementClasses="'w-16'"
              />
            </wo-td>
            <wo-td class="text-center">
              <vx-tooltip text="Sil">
                <feather-icon
                  icon="XIcon"
                  class="icon-style text-danger"
                  @click="() => deleteRow(index)"
                  v-if="!productionCheck"
                />
              </vx-tooltip>
            </wo-td>
          </wo-tr>
        </template>
      </wo-table>
      <div>
        <wo-button
          type="flat"
          color="success"
          class="float-right"
          text="Satır Ekle"
          :disabled="productionCheck"
          @click="() => focusFirstInput()"
        />
      </div>
    </template>
    <div class="d-inline-flex">
      <!-- <wo-button text="Yarı Mamül Üretim Fişi Oluştur" class="mt-5" @click="createProductionSlip('3')" /> -->
      <!-- <wo-button text="Hammadde Hesapla" @click="calculateHammaddeApiCall" class="ml-2 mr-2 mt-2" /> -->
      <wo-button
        text="Reçete Oluştur"
        class="ml-2 mr-2"
        @click="createProductionSlip()"
        v-if="stateKey === 'confirmed'"
        :disabled="productionCheck"
      />
      <wo-button
        text="Reçeteye Git"
        @click="routeProductionSlip"
        v-if="semiProduct.recete_id"
      />
    </div>
  </wo-modal>
</template>
<script>
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';
//import { factory } from "@/helpers/ApiController";
import { ref, computed, watch, onMounted } from '@vue/composition-api';
import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';
import { errorDialog } from '@/helpers/errorDialogs';
import {
  getSemiProductDetail,
  storeProductionSlip,
  // calculateHammadde
} from '@/helpers/ApiController';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import { prevalentRenderer } from '@/helpers/quickSearchListRenderers/qsPrevalent';

export default {
  name: 'SubSemiProductModal',
  props: {
    subSemiProductModal: Boolean,
    semiProductIndex: Number,
    stateKey: String,
    // subSemiProducts: Array,
    entryID: [String, Number]
  },
  setup(props, { emit }) {
    const entryTypes = ['Stok', 'Gider'];

    const titles = [
      'Sıra',
      'Kalem Türü',
      'Stok',
      'Cari',
      'Birim',
      'Tamamlayıcı Madde',
      'Miktar',
      'Maliyet',
      'Para Birimi',
      'Tutar',
      'Süreç',
      "Açıklama",
      'İşlem'
    ];
    const isActive = ref(true);

    const { stockUnits, state, exchangeRates, recipeGroups, processes } =
      useModelPlanlamaState();

    const localExchangeRates = computed(() => {
      return exchangeRates.value[props.stateKey];
    });

    const rowCount = ref(5);
    
    const semiProduct = computed(
      () => state[props.stateKey].details.semiProducts[props.semiProductIndex]
    );

    const boilerPlate = {
      entryType: '0',
      stockName: '',
      stockID: '',
      unit: '',
      amount: '0.00',
      cost: '0.00',
      currency: window.anaparabirimi,
      description: "",
      price: '',
      isComplement: false
    };

    const semiProductDetailsMapper = {
      stok_id: 'stockID',
      stok_isim: 'stockName',
      birim: 'unit',
      miktar: 'amount',
      fiyat: 'cost',
      doviz_cinsi: 'currency',
      tutar: 'price',
      tamamlayici_madde: 'isComplement'
    };

    const semiProductsNullToArray = () => {
      state[props.stateKey].details.semiProducts[
        props.semiProductIndex
      ].semiProductDetails = [];
    };

    const subSemiProducts = computed({
      get() {
        if (
          !state[props.stateKey].details.semiProducts[props.semiProductIndex]
            .semiProductDetails
        ) {
          semiProductsNullToArray();
        }
        return state[props.stateKey].details.semiProducts[
          props.semiProductIndex
        ].semiProductDetails;
      },
      set(val) {
        state[props.stateKey].details.semiProducts[
          props.semiProductIndex
        ].semiProductDetails = val;
      }
    });

    // const subSemiProductsFirstState =
    //   subSemiProducts.value.length < 1 ? [] : [subSemiProducts.value];
    const newSubSemiProductData = [
      { ...boilerPlate },
      { ...boilerPlate },
      { ...boilerPlate },
      { ...boilerPlate },
      { ...boilerPlate }
    ];

    const localData = ref([]);

    // const productionSlipData = ref({
    //   depotID: 0,
    //   depotName: "",
    //   recipeGroupID: 0,
    // });

    // const productionSlipData = ref(
    //   state[props.stateKey].details.
    //       semiProducts[props.semiProductIndex].
    //         productionSlipData
    // );

    const currentIndex = ref(0);

    const calculatePrices = () => {
      for (let i = 0; i < localData.value.length; i++) {
        // localData.value[i].amount = parseFloat(localData.value[i].amount);
        // localData.value[i].cost = parseFloat(localData.value[i].cost);
        const exchangeRate = localExchangeRates.value.find(
          item => item.kod === localData.value[i].currency
        );
        localData.value[i].price =
          parseFloat(
            localData.value[i].amount.replace('.', '').replace(',', '.')
          ) *
          parseFloat(
            localData.value[i].cost.replace('.', '').replace(',', '.')
          ) *
          parseFloat(exchangeRate.kur);
      }
    };

    const created = () => {
      if (parseInt(props.entryID) === 0 && subSemiProducts.value.length === 0) {
        localData.value = [...newSubSemiProductData];
      } else if (
        parseInt(props.entryID) > 0 &&
        subSemiProducts.value.length === 0
      ) {
        rowCount.value = subSemiProducts.value.length;
        getSemiProductDetail(props.entryID, 'sarf').then(res => {
          for (const key in res) {
            localData.value.push({});
            for (const index in res[key]) {
              localData.value[key][semiProductDetailsMapper[index]] =
                res[key][index];
              rowCount.value = parseInt(key) + 1;
            }
            localData.value[key]['entryType'] = '0';
          }
        });
      } else if (subSemiProducts.value.length > 0) {
        rowCount.value = subSemiProducts.value.length;
        localData.value = [...subSemiProducts.value];
      }
    };

    created();

    // const subSemiProductsMapper = (obj, key) => {

    // };

    watch(
      () => JSON.parse(JSON.stringify(subSemiProducts.value)),
      () => {
        created();
      },
      { deep: true }
    );

    watch(
      () => localData.value,
      () => {
        calculatePrices();
      },
      { deep: true }
    );

    // const calculateHammaddeApiCall = () => {
    //   const { data } = calculateHammadde(
    //     localData.value.map(item => {
    //       return {
    //         stockId: item.stockID,
    //         amount: item.amount
    //       };
    //     }),
    //     state[props.stateKey].details.semiProducts[props.semiProductIndex]
    //       .entryID,
    //     state[props.stateKey].info.id
    //   );
    // };

    const saveNewSubSemiProduct = () => {
      let errorStock = false;
      localData.value.forEach(item => {
        if (item.stockName && !item.stockID) {
          errorDialog('Varolan stoklardan birini seçmelisiniz.');
          errorStock = true;
        }
      });
      if (errorStock === true) {
        return;
      }
      calculatePrices();
      state[props.stateKey].details.semiProducts[
        props.semiProductIndex
      ].semiProductDetails = localData.value.filter(item => {
        return item.stockID !== '';
      });
      emit('subSemiProductModal', false);
    };
    const cancel = () => {
      emit('subSemiProductModal', false);
    };

    // const handleDepot = item => {
    //   if (item.isSelected) {
    //     state[props.stateKey].details.semiProducts[
    //       props.semiProductIndex
    //     ].productionSlipData['depotID'] = item.id;
    //     state[props.stateKey].details.semiProducts[
    //       props.semiProductIndex
    //     ].productionSlipData['depotName'] = item.isim;
    //   } else {
    //     state[props.stateKey].details.semiProducts[
    //       props.semiProductIndex
    //     ].productionSlipData['depotID'] = 0;
    //     state[props.stateKey].details.semiProducts[
    //       props.semiProductIndex
    //     ].productionSlipData['depotName'] = '';
    //   }
    // };

    const productionSlipValidator = () => {
      const rowCount = localData.value.length;

      // const depotID = parseInt(productionSlipData.value.depotID);

      // const recipeGroupID = parseInt(productionSlipData.value.recipeGroupID);

      const rowIsValid = localData.value.every(item => {
        return (
          item.stockID !== '' &&
          item.stockName !== '' &&
          item.amount !== '0,00' &&
          item.cost !== '0,00' &&
          item.price !== '0,00' &&
          item.unit !== ''
        );
      });

      return rowCount && rowIsValid;

      // return type === '3'
      //   ? rowCount > 0 && depotID > 0 && recipeGroupID > 0 && rowIsValid
      //   : rowCount > 0 && recipeGroupID > 0 && rowIsValid;
    };

    const productionSlip = computed(() => {
      const semiProduct =
        state[props.stateKey].details.semiProducts[props.semiProductIndex];
      return {
        documentID:
          props.stateKey === 'confirmed'
            ? state[props.stateKey].info.draftID
            : state[props.stateKey].info.id,
        branch: state[props.stateKey].info.branch,
        serial: state[props.stateKey].info.serial,
        status: '1',
        // recipeGroupID: productionSlipData.value.recipeGroupID,
        stockID: semiProduct.entryID,
        unit: semiProduct.unit,
        amount: semiProduct.amount,
        cost: semiProduct.cost,
        price: semiProduct.price,
        stocks: localData.value
          .filter(item => item.entryType === '0')
          .map(item => {
            return {
              stockID: item.stockID,
              stockName: item.stockName,
              amount: item.amount,
              cost: item.cost,
              price: item.price,
              unit: item.unit,
              description: item.description ||  "",
              currency: item.currency,
              usingRecipe: 0
            };
          }),
        expenses: localData.value
          .filter(item => item.entryType === '1')
          .map(item => {
            return {
              entryID: item.stockID,
              entryName: item.stockName,
              mainCurrencyPrice: item.price,
              currency: item.currency,
              description: item.description ||  "",
              prevalentID: item.prevalentID,
              process: item.process
            };
          })
      };
    });
    
    const setRowIdToExpenses = () => {
      subSemiProducts.value.forEach((item, index, arr) => { 
        if (item.entryType === "1") {
          arr[index] = { ...item, rowId: `${semiProduct.value.id}-${index}`};
        }
      });
    };

    const createProductionSlip = async () => {
      if (productionSlipValidator()) {
        const sendedProductionSlip =
        //   type === '3' || type === '1'
        //     ? {
        //         ...productionSlip.value,
        //         type,
        //         status: '2',
        //         // depotID: productionSlipData.value.depotID,
        //         // prescriptionType: 0
        //       }
        //     : 
        {
            // depotID: productionSlipData.value.depotID,
            ...productionSlip.value,
            type: '2',
            // prescriptionType: 1,
            status: '1'
          };
          setRowIdToExpenses();
          storeProductionSlip(sendedProductionSlip).then(response => {
            if (response.status === 200) {
              primaryDialog(response.data.message, 'Üretim Fişi', () => {
              window.open(
                `${window.location.origin}/${response.data.redirect}`,
                '_blank'
              );
            });
          }
        });
      } else {
        errorDialog('Lütfen tüm alanları doldurunuz.');
      }
    };

    const deleteRow = index => {
      rowCount.value -= 1;
      localData.value.splice(index, 1);
    };

    const addRow = async function () {
      localData.value.push({ ...boilerPlate });
      rowCount.value += 1;
      return true;
    };

    const focusFirstInput = async () => {
      await addRow();
      document
        .getElementById(`firstInput-${rowCount.value - 1}`)
        .querySelector('input')
        .focus();
    };

    const stockIsExist = stockID => {
      return localData.value.find(item => item.stockID === stockID);
    };

    const handleStockQuickSearch = item => {
      if (item.isSelected) {
        if (stockIsExist(item.id)) {
          errorDialog('Bu stok zaten eklenmiş.');
          return;
        }
        localData.value[currentIndex.value].stockID = item.id;
        localData.value[currentIndex.value].stockName = item.isim;
        if (item.birim) {
          localData.value[currentIndex.value].unit = item.birim;
        }
        if (item.fiyat) {
          localData.value[currentIndex.value].cost = item.fiyat;
        }
      } else if (!item.isSelected) {
        localData.value[currentIndex.value].stockID = 0;
        localData.value[currentIndex.value].stockName = '';
      }
    };

    const handlePrevalentQuickSearch = item => {
      if (item.isSelected) {
        localData.value[currentIndex.value].prevalentID = item.id;
        localData.value[currentIndex.value].prevalentName = item.isim;
      } else {
        localData.value[currentIndex.value].prevalentID = 0;
        localData.value[currentIndex.value].prevalentName = '';
      }
    };

    // TODO handlleri helpersa ekle
    const handleExpenseQuickSearch = item => {
      if (item.isSelected) {
        localData.value[currentIndex.value].stockID = item.id;
        localData.value[currentIndex.value].stockName = item.gdr_isim;
      } else {
        localData.value[currentIndex.value].stockID = 0;
        localData.value[currentIndex.value].stockName = '';
      }
    };

    const formEntryConfig = computed(() => {
      const isStock = localData.value[currentIndex.value].entryType === '0';
      return {
        queryParameters: isStock
          ? `?tablo=stok&depo=${state[props.stateKey].info.depot}&q=`
          : '?tablo=giderler&q=',
        handler: isStock ? handleStockQuickSearch : handleExpenseQuickSearch,
        link: isStock ? '/?s=stok&duzenle=' : '/?s=giderler&duzenle=',
        prefix: isStock ? undefined : 'gdr_',
        placeholder: isStock ? 'Stok' : 'Gider'
      };
    });

    const formPrevalentConfig = computed(() => {
      return {
        queryParameters: '?tablo=cari&q=',
        handler: handlePrevalentQuickSearch,
        placeholder: 'Cari'
      };
    });

    const productionCheck = computed(() => semiProduct.value.slipIsCreated);

    onMounted(() => {
      if (productionCheck.value) {
        const modalElement = document.getElementById('subSemiProductModal');
        const inputs = modalElement.getElementsByTagName('input');
        const selectBoxes = modalElement.getElementsByTagName('select');
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].disabled = true;
        }
        for (let i = 0; i < selectBoxes.length; i++) {
          selectBoxes[i].disabled = true;
        }
      }
    });

    const routeProductionSlip = () => {
      window.open(
        `${window.location.origin}/?s=receteler&tur=2&duzenle=${semiProduct.value.recete_id}`
      );
    };

    const isComplement = value => {
      console.log(value);
    };

    return {
      saveNewSubSemiProduct,
      titles,
      rowCount,
      stockUnits,
      deleteRow,
      addRow,
      focusFirstInput,
      localData,
      cancel,
      formEntryConfig,
      nameAndCodeWithLinkRenderer,
      currentIndex,
      state,
      semiProductDetailsMapper,
      subSemiProducts,
      semiProductsNullToArray,
      isComplement,
      isActive,
      calculatePrices,
      stockIsExist,
      localExchangeRates,
      createProductionSlip,
      // handleDepot,
      nameAndCodeRenderer,
      recipeGroups,
      productionSlipValidator,
      entryTypes,
      prevalentRenderer,
      formPrevalentConfig,
      processes,
      handleExpenseQuickSearch,
      // productionSlipData,
      productionCheck,
      semiProduct,
      routeProductionSlip,
      // calculateHammaddeApiCall
    };
  }
};
</script>

<style lang="scss" scoped></style>
