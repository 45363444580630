<template>
  <wo-int-input v-model="inputVal" maxlength="11" />
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';

export default {
  mixins: [inputComponentMixin]
};
</script>

<style lang="scss" scoped></style>
