import { render, staticRenderFns } from "./TabloKontrolListesi.vue?vue&type=template&id=bfbf6922"
import script from "./TabloKontrolListesi.vue?vue&type=script&lang=js"
export * from "./TabloKontrolListesi.vue?vue&type=script&lang=js"
import style0 from "./TabloKontrolListesi.vue?vue&type=style&index=0&id=bfbf6922&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TabloKontrolListesi.vue?vue&type=custom&index=0&blockType=div&class=page-numbers"
if (typeof block0 === 'function') block0(component)

export default component.exports