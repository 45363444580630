<template>
  <div class="space-y-16">
    <ModelPlanlamaDetailsTableWithHeader
      v-for="(table, index) in tables"
      :key="`detail-table-${index}`"
      :stateKey="stateKey"
      :title="table.title"
      :source="table.source"
      :class="{
        'bg-yellow-50': table.source === 'stocks',
        'bg-blue-50': table.source === 'semiProducts',
        'bg-purple-50': table.source === 'expenses',
      }"
    />
    <ModelPlanlamaVariantTable :stateKey="stateKey" v-if="selectedColors[stateKey].length > 0"/>
  </div>
</template>

<script>
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import ModelPlanlamaDetailsTableWithHeader from './ModelPlanlamaDetailsTableWithHeader.vue';
import ModelPlanlamaVariantTable from './ModelPlanlamaVariantTable.vue';

export default {
  components: {
    ModelPlanlamaDetailsTableWithHeader,
    ModelPlanlamaVariantTable,
    // BaseInput
  },
  props: {
    stateKey: String
  },
  setup () {
    const { selectedColors } = useModelPlanlamaState();
    const tables = [
      { title: 'Stoklar', source: 'stocks' },
      { title: 'Yarı Mamüller', source: 'semiProducts' },
      { title: 'Giderler', source: 'expenses' }
    ];

    return {
      tables,
      selectedColors
    };
  }
};
</script>

<style lang="scss" scoped>

</style>