<template>
  <option
    v-bind="$attrs"
    v-on="$listeners"
    :value="itemValue"
    :selected="`${itemValue}` === `${selectValue}`"
  >
    {{ text }}
  </option>
</template>

<script>
export default {
  name: 'WOSelectItem',
  props: {
    itemValue: [String, Number],
    text: String
  },
  computed: {
    selectValue() {
      return this.$parent.selectVal;
    }
  }
};
</script>
