<template>
    <wo-modal
        :active="consumptionSlipModal"
        class="wide-dialog-90vw one-step-behind-dialog"
        @active="$emit('consumptionSlipModal', $event)"
        :buttonsHidden="true"
        title="Reçete Detayları"
    >
    <div class="d-inline flex ml-2 mb-5">
         <label class="ml-4" style="font-weight: bold;">Reçete Grubu</label>
        <wo-select
            class="ml-2"
            :data="recipeGroups"
            v-model="consumptionSlip.recipeGroupID"
            elementClasses="w-28 m-auto"
        >
            <template #defaultValue>
                <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
                <wo-select-item
                    :itemValue="item.id"
                    :text="item.isim"
                    :key="`recipeGroup-${item.id}`"
                />
            </template>
        </wo-select>
    </div>
    <wo-table>
        <template #thead>
            <wo-th v-for="title in titles" :key="`title-${title}`" class="text-left">
                {{ title }}
            </wo-th>
        </template>
        <wo-tr id="main-product">
            <wo-td>{{consumptionSlip.stockName}}</wo-td>
            <wo-td>
                <wo-select
                    v-model="consumptionSlip.currency"
                    :data="localExchangeRates"
                    elementClasses="w-16"
                >
                    <template #default="{ item }">
                        <wo-select-item
                        :itemValue="item.kod"
                        :text="item.kod"
                        :key="`exchange-rate-${item.kod}`"
                        />
                    </template>
                </wo-select>
            </wo-td>
            <wo-td>
                <wo-select
                    v-model="consumptionSlip.unit"
                    :data="stockUnits"
                    elementClasses="w-28 m-auto"
                >
                    <template #defaultValue>
                        <wo-select-item :itemValue="0" text="- Seçiniz -" />
                    </template>
                    <template #default="{ item }">
                        <wo-select-item
                            :itemValue="item.id"
                            :text="item.isim"
                            :key="`unit-${item.id}`"
                        />
                    </template>
                </wo-select>
            </wo-td>
            <wo-td>
                <wo-float-input
                    @blur="calculatePrice"
                    v-model="consumptionSlip.amount"
                    :elementClasses="'w-28 m-auto'"
                />
            </wo-td>
            <wo-td>
                <wo-float-input 
                    @blur="calculatePrice"
                    v-model="consumptionSlip.cost"
                    :elementClasses="'w-28 m-auto'"
                />
            </wo-td>
            <wo-td>
                <wo-float-input
                    readonly
                    v-model="consumptionSlip.price"
                    id="price"
                >
                </wo-float-input>
            </wo-td>
        </wo-tr>
        <wo-tr v-for="stock in consumptionSlip.stocks" :key="`detail-${stock.stockID}`">
            <wo-td>{{stock.stockName}}</wo-td>
            <wo-td class="text-center">{{stock.currency}}</wo-td>
            <wo-td class="text-center">{{getUnitName(stock.unit)}}</wo-td>
            <wo-td class="text-center">{{stock.amount}}</wo-td>
            <wo-td class="text-center">{{stock.cost}}</wo-td>
            <wo-td class="text-center">{{stock.price}}</wo-td>
        </wo-tr>
    </wo-table>
    <wo-button class="mt-4" text="Reçete Oluştur" @click="createConsumptionSlip"/>
    </wo-modal>
</template>
<script>
import { computed } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';
import { storeProductionSlip } from '@/helpers/ApiController';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';


export default {
    name: "ConsumptionSlipModal",
    props: {
        consumptionSlipModal: Boolean,
        stateKey: String,
    },
    setup(props) {
        const { state, exchangeRates, stockUnits, recipeGroups} = useModelPlanlamaState();

        const titles = [
            "Stok",
            "Döviz",
            "Birim",
            "Miktar",
            "Maliyet",
            "Tutar",
        ];
        const localExchangeRates = computed(() => {
            return exchangeRates.value['confirmed'];
        });
        
        const consumptionSlip = computed(() => {
            const stocks = [...state['confirmed'].details.stocks, ...state['confirmed'].details.semiProducts];
            return {
                documentID: props.stateKey === 'confirmed' ? state[props.stateKey].info.draftID : state[props.stateKey].info.id,
                recipeGroupID: 0,
                branch: state['confirmed'].info.branch,
                serial: state['confirmed'].info.serial,
                depotID: 0,
                prescriptionType: 1,
                type: "2",
                status: '1',
                stockID: state['confirmed'].info.finalProductID,
                stockName: state['confirmed'].info.finalProductName,
                unit: "",
                currency: state['confirmed'].info.currentCurrency,
                amount: "0,00",
                cost: "0,00",
                price: "0,00",
                stocks : stocks.map(stock => {
                    return {
                            stockID: stock.entryID,
                            stockName: stock.entryName,
                            amount: stock.amount,
                            cost: stock.cost,
                            usingRecipe: 0,
                            price: stock.mainCurrencyPrice,
                            currency: stock.currency,
                            unit: stock.unit,
                    };
                }),
            };
        });

        const calculatePrice = () => {
            consumptionSlip.value.price = (parseFloat(consumptionSlip.value.amount.replace(',', '.')) * parseFloat(consumptionSlip.value.cost.replace(',', '.'))).toFixed(2);
            document.getElementById('price').value = consumptionSlip.value.price;
        };

        const getUnitName = (id) => {
            return stockUnits.value.find(unit => unit.id === parseInt(id)).isim;
        };

        const consumptionSlipValidator = () => { 
            return consumptionSlip.value.recipeGroupID !== 0 &&  
            parseInt(consumptionSlip.value.unit) !== 0 && parseInt(consumptionSlip.value.amount) !== 0 && 
            parseInt(consumptionSlip.value.cost) !== 0 && parseInt(consumptionSlip.value.price) !== 0;
        };

        const createConsumptionSlip = () => {
            if (consumptionSlipValidator()) {
                    storeProductionSlip(consumptionSlip.value).then(response => {
                        if (response.status === 200) {
                            primaryDialog(response.data.message, 'Üretim Fişi', () => {
                                window.open(`${window.location.origin}/${response.data.redirect}`, '_blank');
                            });
                        }
                    });
            } else {
                errorDialog('Lütfen tüm alanları doldurunuz.');
            }
        };

        return {
            state,
            consumptionSlip,
            titles,
            localExchangeRates,
            stockUnits,
            calculatePrice,
            getUnitName,
            recipeGroups,
            createConsumptionSlip,
        };
    }
};
</script>
<style>
    #main-product {
        background-color: rgb(184, 183, 183);
        font-size: 1.1rem;
    }
</style>