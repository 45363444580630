import { render, staticRenderFns } from "./WOMultiselect.vue?vue&type=template&id=61e98e1e&scoped=true"
import script from "./WOMultiselect.vue?vue&type=script&lang=js"
export * from "./WOMultiselect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e98e1e",
  null
  
)

export default component.exports