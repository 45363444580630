<template>
  <div class="space-y-4">
    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <a
            :href="`${$baseURL}/?s=gelirler`"
            class="underlined-hovered-link"
            target="_blank"
          >
            Hesap Kapama Gelir Kartı
          </a>
        </template>
        <template #firstElement>
          <wo-quick-search
            v-model="state.accountIncomeCardText"
            :queryParameters="
              `?tablo=gelirler&sube=${state.accountingBranch}&q=`
            "
            @selected="handleIncomeQuickSearch"
            placeholder="Gelir"
          >
            <template v-slot:default="{ item }">
              <a
                class="underlined-hovered-link"
                :href="`${$baseURL}/?s=gelirler&duzenle=${item.id}`"
                >{{ item.glr_kod }}</a
              >
              <span>&nbsp; - {{ item.glr_isim }}</span>
            </template>
          </wo-quick-search>
        </template>
        <template #secondLabel>
          <a
            :href="`${$baseURL}/?s=giderler`"
            class="underlined-hovered-link"
            target="_blank"
          >
            Hesap Kapama Gider Kartı
          </a>
        </template>
        <template #secondElement>
          <wo-quick-search
            v-model="state.accountExpenseCardText"
            :queryParameters="
              `?tablo=giderler&sube=${state.accountingBranch}&q=`
            "
            @selected="
              item => handleExpenseQuickSearch(item, 'accountExpenseCard')
            "
            placeholder="Gider"
          >
            <template v-slot:default="{ item }">
              <a
                class="underlined-hovered-link"
                :href="`${$baseURL}/?s=giderler&duzenle=${item.id}`"
                >{{ item.gdr_kod }}</a
              >
              <span>&nbsp; - {{ item.gdr_isim }}</span>
            </template>
          </wo-quick-search>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Hesap Kapama Carisi
        </template>
        <template #firstElement>
          <wo-quick-search
            v-model="state.accountPrevalentText"
            :queryParameters="`?tablo=cari&sube=${state.accountingBranch}&q=`"
            @selected="handlePrevalentQuickSearch"
            placeholder="Cari"
            :itemRenderer="prevalentRenderer"
          />
        </template>
        <!-- <template #secondLabel>
          <a
            :href="`${$baseURL}/?s=giderler`"
            class="underlined-hovered-link"
            target="_blank"
          >
            Personel Tazminat Gider Kartı
          </a>
        </template>
        <template #secondElement>
          <wo-quick-search
            v-model="state.personalCompensationExpenseCardText"
            :queryParameters="
              `?tablo=giderler&sube=${state.accountingBranch}&q=`
            "
            @selected="
              item =>
                handleExpenseQuickSearch(
                  item,
                  'personalCompensationExpenseCard'
                )
            "
            placeholder="Gider"
          >
            <template v-slot:default="{ item }">
              <a
                class="underlined-hovered-link"
                :href="`${$baseURL}/?s=giderler&duzenle=${item.id}`"
                >{{ item.gdr_kod }}</a
              >
              <span>&nbsp; - {{ item.gdr_isim }}</span>
            </template>
          </wo-quick-search>
        </template> -->
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormSectionDivider />

    <CariFormSectionHeader>
      Bağlı Üst Cari
    </CariFormSectionHeader>

    <wo-quick-search
      v-model="state.connectedParentPrevalentText"
      :queryParameters="`?tablo=cari&sube=${state.accountingBranch}&q=`"
      @selected="handleConnectedParentPrevalenttQuickSearch"
      placeholder="Cari"
      :itemRenderer="prevalentRenderer"
    />
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState.js';
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import { prevalentRenderer } from '@/helpers/quickSearchListRenderers/qsPrevalent.js';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

export default {
  name: 'CariFormTabRelatedCards',
  components: {
    CariFormSectionHeader,
    CariFormSectionDivider,
    CariFormQuadrupleFormWrapper
  },
  setup() {
    const { labelSize, state } = usePrevalentState();

    const handleIncomeQuickSearch = item => {
      if (item.isSelected) {
        state.accountIncomeCardID = item.id;
        state.accountIncomeCardText = `${item.glr_kod} - ${item.glr_isim}`;
      } else if (!item.isSelected && state.accountIncomeCardID) {
        state.accountIncomeCardID = '';
        state.accountIncomeCardText = '';
      }
    };

    const handleExpenseQuickSearch = (item, key) => {
      if (item.isSelected) {
        state[`${key}ID`] = item.id;
        state[`${key}Text`] = `${item.gdr_kod} - ${item.gdr_isim}`;
      } else if (!item.isSelected && state[`${key}ID`]) {
        state[`${key}ID`] = '';
        state[`${key}Text`] = '';
      }
    };

    const handlePrevalentQuickSearch = item => {
      if (item.isSelected) {
        state.accountPrevalentID = item.id;
        state.accountPrevalentText = item.text;
      } else if (!item.isSelected && state.accountPrevalentID) {
        state.accountPrevalentID = '';
        state.accountPrevalentText = '';
      }
    };

    const handleConnectedParentPrevalenttQuickSearch = item => {
      if (item.isSelected) {
        state.connectedParentPrevalentID = item.id;
        state.connectedParentPrevalentText = item.text;
      } else if (!item.isSelected && state.connectedParentPrevalentID) {
        state.connectedParentPrevalentID = 0;
        state.connectedParentPrevalentText = '';
      }
    };

    return {
      prevalentRenderer,
      labelSize,
      state,
      handleIncomeQuickSearch,
      handleExpenseQuickSearch,
      handlePrevalentQuickSearch,
      handleConnectedParentPrevalenttQuickSearch
    };
  }
};
</script>

<style lang="scss" scoped></style>
