import { computed } from '@vue/composition-api';

export function useVueMultiple(data, state, key, filterKey = 'id') {
  const selectedValues = computed(() => {
    if (!data.value.length || !state[key].length) {
      return [];
    }
    return data.value.filter(a => state[key].includes(a[filterKey]));
  });

  const removeElement = removedElement => {
    const removedIndex = state[key].findIndex(
      a => a[filterKey] === removedElement[filterKey]
    );
    state[key].splice(removedIndex, 1);
  };

  const addElement = addedElement => {
    state[key] = [...state[key], addedElement[filterKey]];
  };

  return {
    selectedValues,
    removeElement,
    addElement
  };
}
