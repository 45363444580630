export default function printDocument(content) {
  const iframeToPrint = document.createElement('iframe');
  iframeToPrint.name = 'iframeToPrint';
  iframeToPrint.style.position = 'absolute';
  iframeToPrint.style.top = '-1000000px';
  document.body.appendChild(iframeToPrint);
  const frameDoc =
    iframeToPrint.contentWindow ||
    iframeToPrint.contentDocument.document ||
    iframeToPrint.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(content);
  frameDoc.document.close();
  setTimeout(function() {
    window.frames['iframeToPrint'].focus();
    window.frames['iframeToPrint'].print();
    document.body.removeChild(iframeToPrint);
  }, 500);
}
