<template>
  <div class="space-y-4">
    <CariFormTabGenelPrevalentGroup />

    <CariFormSectionDivider />

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Ünvanı</template>
      <template #firstAfter>
        <div class="w-full md:w-1/2">
          <base-input
            class="w-full"
            v-model="state.title"
            @focus="state.titleSyncer = state.title"
            @blur="syncTitleAndCompanyTitle"
          />
        </div>
      </template>
    </wo-double-form>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Cari Kodu
          <span
            class="underlined-hovered-link"
            @click="handlePrevalentCodeAlert"
            >?</span
          >
        </template>
        <template #firstElement>
          <div class="flex space-x-1">
            <wo-pattern-input
              v-model="state.prevalentCode"
              class="w-40 uppercase"
              :pattern="/[.\d\w]/"
            />
            <wo-button
              text="!"
              tabindex="-1"
              class="py-2 px-3"
              @click="generatePrevalentCode"
            />
            <wo-button
              text=".."
              tabindex="-1"
              class="py-2 px-3"
              @click="handlePrevalentCodeMiniModule"
            />
          </div>
        </template>
        <template #secondLabel>
          <span
            class="underlined-hovered-link"
            @click="handleAccountingCodeMiniModule"
          >
            Muhasebe Kodu
          </span>
        </template>
        <template #secondElement>
          <wo-quick-search
            v-model="state.accountingCode"
            queryParameters="?tablo=muhasebe_hesap_plani&kod=1&q="
            ref="accountingCode"
            @selected="handleAccountingCodeQuickSearch"
            @blur="controlAccountCode"
            @keypress="
              e => (!e.key.match(codeRegex) ? e.preventDefault() : true)
            "
            :itemRenderer="item => nameAndCodeRenderer(item, 'w-20')"
          />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Açılış Tarihi </template>
        <template #firstElement>
          <wo-date-picker v-model="state.openingDate" />
        </template>
        <template #secondLabel> Yetkili Kişi </template>
        <template #secondElement>
          <base-input v-model="state.authorizedPerson" />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Durum</template>
        <template #firstElement>
          <vs-radio
            v-model="state.prevalentState"
            vs-name="prevalent-state"
            vs-value="1"
          >
            Aktif
          </vs-radio>
          <vs-radio
            v-model="state.prevalentState"
            vs-name="prevalent-state"
            vs-value="0"
            class="ml-2"
          >
            Pasif
          </vs-radio>
        </template>
        <template #secondLabel>
          <div></div>
        </template>
        <template #secondElement>
          <wo-date-picker
            v-show="state.prevalentState === '0'"
            v-model="state.prevalentStateInactiveDate"
            placeholder="Tarih"
          />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormSectionDivider />

    <CariFormTabGenelInvoice />

    <CariFormSectionDivider />

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Sorumlu Personel </template>
        <template #firstElement>
          <wo-quick-search
            v-model="state.responsiblePersonelText"
            queryParameters="?tablo=personel&q="
            @selected="item => handleSelectedResponsiblePersonel(item)"
            :itemRenderer="personnelRenderer"
          />
        </template>
        <template #secondLabel> Sicil No </template>
        <template #secondElement>
          <base-input v-model="state.taxRegisterNo" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Üretici Kodu </template>
        <template #firstElement>
          <base-input maxlength="10" v-model="state.manufacturerCode" />
        </template>
        <template #secondLabel> İhracat Kodu </template>
        <template #secondElement>
          <base-input maxlength="10" v-model="state.exportCode" />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        <a
          v-if="canStockSettingsBeSeen"
          class="underline"
          href="/?s=depolar"
          target="_blank"
        >
          Deposu
        </a>
      </template>
      <template #firstElement>
        <wo-select
          v-if="canStockSettingsBeSeen"
          v-model="state.depot"
          :data="depots"
        >
          <template #defaultValue>
            <wo-select-item itemValue="" text="Depo Seçiniz" />
          </template>
          <template v-slot:default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.isim"
              :key="`depot-${item.id}`"
            />
          </template>
        </wo-select>
      </template>
      <template #secondLabel>
        <span v-if="authorizationControl('cari_butcede_goster')">
          Bütçede
        </span>
      </template>
      <template #secondElement>
        <div class="flex space-x-2">
          <vs-radio
            v-if="authorizationControl('cari_butcede_goster')"
            v-model="state.showInBudget"
            vs-name="show-in-budget"
            vs-value="1"
          >
            Göster
          </vs-radio>
          <vs-radio
            v-if="authorizationControl('cari_butcede_goster')"
            v-model="state.showInBudget"
            vs-name="show-in-budget"
            vs-value="0"
          >
            Gösterme
          </vs-radio>
        </div>
      </template>
    </wo-double-form>

    <CariFormSectionDivider />

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel> Grup İçi mi </template>
      <template #firstElement>
        <div class="y-centered-flex space-x-4">
          <vs-radio
            v-model="state.insideGroup"
            vs-name="inside-group"
            vs-value="1"
          >
            Evet
          </vs-radio>
          <vs-radio
            v-model="state.insideGroup"
            vs-name="inside-group"
            vs-value="0"
          >
            Hayır
          </vs-radio>
        </div>
      </template>
      <template #secondLabel> Çalışma Türü </template>
      <template #secondAfter>
        <div
          class="responsive-margin-lg flex flex-col lg:flex-row lg:items-center"
        >
          <div class="space-x-4">
            <vs-radio
              v-model="state.workingType"
              vs-name="working-type"
              vs-value="0"
            >
              Hiçbiri
            </vs-radio>
            <vs-radio
              v-model="state.workingType"
              vs-name="working-type"
              vs-value="1"
            >
              Tedarikçi
            </vs-radio>
          </div>
          <div class="space-x-4">
            <vs-radio
              v-model="state.workingType"
              vs-name="working-type"
              vs-value="2"
            >
              İşletmeci
            </vs-radio>
            <vs-radio
              v-model="state.workingType"
              vs-name="working-type"
              vs-value="3"
            >
              Mağaza
            </vs-radio>
          </div>
        </div>
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel> Ekstre Tipi </template>
      <template #firstElement>
        <div class="y-centered-flex space-x-4">
          <vs-radio
            v-model="state.extractType"
            vs-name="extract-type"
            vs-value="1"
          >
            Fatura
          </vs-radio>
          <vs-radio
            v-model="state.extractType"
            vs-name="extract-type"
            vs-value="2"
          >
            Genel
          </vs-radio>
        </div>
      </template>
      <template #secondLabel> İrsaliye Tipi </template>
      <template #secondAfter>
        <div
          class="responsive-margin-xl flex flex-col xl:flex-row xl:items-center"
        >
          <div class="space-x-4">
            <vs-radio
              v-model="state.waybillType"
              vs-name="waybill-type"
              vs-value="4"
            >
              Fatura
            </vs-radio>
            <vs-radio
              v-if="canStockSettingsBeSeen"
              v-model="state.waybillType"
              vs-name="waybill-type"
              vs-value="1"
            >
              Toptan Çıkış
            </vs-radio>
          </div>
          <div class="space-x-4">
            <vs-radio
              v-if="canStockSettingsBeSeen"
              v-model="state.waybillType"
              vs-name="waybill-type"
              vs-value="2"
            >
              Konsinye Sevk
            </vs-radio>
            <vs-radio
              v-if="canStockSettingsBeSeen"
              v-model="state.waybillType"
              vs-name="waybill-type"
              vs-value="5"
            >
              Konsinye Toptan
            </vs-radio>
          </div>
          <div>
            <vs-radio
              v-if="canStockSettingsBeSeen"
              v-model="state.waybillType"
              vs-name="waybill-type"
              vs-value="3"
            >
              Depo Sevk
            </vs-radio>
          </div>
        </div>
      </template>
    </wo-double-form>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> İşletme Defteri </template>
        <template #firstElement>
          <div class="y-centered-flex space-x-4">
            <vs-radio
              v-model="state.managementRegistry"
              vs-name="management-registry"
              vs-value="1"
            >
              Evet
            </vs-radio>
            <vs-radio
              v-model="state.managementRegistry"
              vs-name="management-registry"
              vs-value="0"
            >
              Hayır
            </vs-radio>
          </div>
        </template>
        <template #secondLabel> Siparişte satış şartına bakma </template>
        <template #secondElement>
          <vs-checkbox
            v-model="state.checkPurchasingConditionOnSale"
            vs-value="1"
          />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Otomatik Bakiye Maili Gönder </template>
        <template #firstElement>
          <vs-checkbox
            v-model="state.automaticallySendBalanceMail"
            vs-value="1"
          />
        </template>
        <template #secondLabel> Açık Hesap Çalışabilir </template>
        <template #secondElement>
          <vx-tooltip
            text="Bu seçenek aktif değilse ve şirket tanımlarında Kapalı Çalışan Carilerde Tahsilat Bekleme Gün Süresi dolu ise, cariye yapılan satışlarda tahsilat kontrolü yapıp, satış yapılabilir yada yapılamaz kontrolüne karar verir"
          >
            <vs-checkbox v-model="state.openAccountCanWork" vs-value="1" />
          </vx-tooltip>
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <div v-show="state.workingType === '2'">
      <CariFormSectionDivider />

      <CariFormTabGenelActiveStores />
    </div>

    <div v-show="state.workingType === '3'">
      <CariFormSectionDivider />

      <CariFormTabGenelStoreSettings />

      <CariFormSectionDivider />

      <CariFormTabGenelStoreProfessions
        class="mb-4"
        v-if="authorizationControl('kadro_tanimlama')"
      />
    </div>
  </div>
</template>

<script>
import useCompany from '@/consumables/useCompany';
import CariFormTabGenelInvoice from './CariFormTabGenelInvoice.vue';
import CariFormTabGenelActiveStores from './CariFormTabGenelActiveStores.vue';
import CariFormTabGenelStoreSettings from './CariFormTabGenelStoreSettings.vue';
import CariFormTabGenelStoreProfessions from './CariFormTabGenelStoreProfessions.vue';
import CariFormTabGenelPrevalentGroup from './CariFormTabGenelPrevalentGroup.vue';

import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

import usePrevalentState from '../../consumables/usePrevalentState';

import { ref, onMounted } from '@vue/composition-api';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { errorConfirmDialog, errorDialog } from '@/helpers/errorDialogs';
import {
  getPrevalentCode,
  controlAccountingCodeExists
} from '../../helpers/ApiFactory';
import controlPrevalentCode from '../../helpers/controlPrevalentCode';
import { authorizationControl } from '@/consumables/useAuthorizationControl.js';

import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import { personnelRenderer } from '@/helpers/quickSearchListRenderers/qsPersonnel';

import { getDepots } from '@/helpers/ApiController';

export default {
  components: {
    CariFormTabGenelPrevalentGroup,
    CariFormTabGenelInvoice,
    CariFormTabGenelActiveStores,
    CariFormTabGenelStoreSettings,
    CariFormTabGenelStoreProfessions,
    CariFormSectionDivider,
    CariFormQuadrupleFormWrapper
  },
  setup() {
    // #region refs
    const accountingBranch = ref(undefined);
    const accountingCode = ref(undefined);

    const returnRefs = {
      accountingBranch,
      accountingCode
    };
    // #endregion
    const codeRegex = /[.\d\w]/;

    const currentCompany = useCompany();

    const { labelSize, state } = usePrevalentState();

    const canStockSettingsBeSeen = ref('');

    onMounted(() => {
      canStockSettingsBeSeen.value =
        window.cari_kartlarda_stok_ayarlari_gozuksun === '1';
    });

    const depots = ref([]);

    const initializeDepots = async () => {
      depots.value = await getDepots();
    };
    initializeDepots();

    const syncTitleAndCompanyTitle = () => {
      if (
        state.companyTitle === '' ||
        state.titleSyncer === state.companyTitle
      ) {
        state.companyTitle = state.title;
      }
    };

    const handlePrevalentCodeAlert = () => {
      primaryDialog('Örnek Cari Kodu : 320.001.001', 'Örnek');
    };

    const controlAccountCode = () => {
      controlAccountingCodeExists(state.accountingCode).then(doesExists => {
        if (!doesExists.kontrol) {
          errorConfirmDialog(
            'Muhasebe kodu, hesap planında bulunamadı,\nOtomatik oluşturulsun mu?',
            () => {
              window.ajaxpen(
                850,
                350,
                'Muhasebe Hesap Planı',
                `muhasebe_hesap_plani&yeni_kod=${state.accountingCode}&yeni_isim=${state.title}`
              );
            }
          );
          return false;
        }
      });
    };

    const generatePrevalentCode = () => {
      if (state.accountingBranch === '') {
        errorDialog('Lütfen şube seçiniz.');
        accountingBranch.value.$el.focus();
      } else {
        getPrevalentCode(currentCompany.value.id)
          .then(() => {
            controlAccountCode();
          })
          .catch(e => {
            console.log('GET PREVALENT CODE ERROR', e);
          });
      }
    };

    const handlePrevalentCodeMiniModule = () => {
      window.ajaxpen(
        650,
        300,
        'Cariler',
        `altmodul&ozellik=509&id=37&sirket=${currentCompany.value.id}&sube=${state.accountingBranch}`
      );
    };

    const handleAccountingCodeMiniModule = () => {
      window.MiniModulPen(
        850,
        430,
        'Muhasebe Hesap Planı',
        'muhasebe_hesap_plani'
      );
    };

    const handleAccountingCodeQuickSearch = item => {
      if (item.isSelected) {
        state.accountingCode = item.kod;
        state.accountingCodeID = item.id;
      } else if (!item.isSelected && state.accountingCodeID) {
        state.accountingCode = '';
        state.accountingCodeID = 0;
      }
    };

    const handleSelectedResponsiblePersonel = item => {
      const { isSelected, id, ad, soyad, carikod_personel } = item;
      if (isSelected) {
        state.responsiblePersonelID = id;
        state.responsiblePersonelText = `${carikod_personel} - ${ad} ${soyad}`;
      } else if (!isSelected && state.responsiblePersonelID) {
        state.responsiblePersonelID = '';
        state.responsiblePersonelText = '';
      }
    };

    const handlePrevalentCodeBlur = () => {
      controlPrevalentCode(state.prevalentCode);
      accountingCode.value.handleInput(state.accountingCode);
      // focus accounting code
      accountingCode.value.$el.getElementsByTagName('input')[0].focus();
    };

    return {
      nameAndCodeRenderer,
      codeRegex,
      ...returnRefs,
      authorizationControl,
      depots,
      currentCompany,
      labelSize,
      state,
      syncTitleAndCompanyTitle,
      handlePrevalentCodeAlert,
      controlPrevalentCode,
      generatePrevalentCode,
      handlePrevalentCodeMiniModule,
      canStockSettingsBeSeen,
      handleAccountingCodeMiniModule,
      handleAccountingCodeQuickSearch,
      handleSelectedResponsiblePersonel,
      personnelRenderer,
      controlAccountCode,
      handlePrevalentCodeBlur
    };
  }
};
</script>

<style lang="scss" scoped></style>
