import { ref } from '@vue/composition-api';
import { getPermissions } from '@/helpers/ApiController';

const userPermissions = ref({});
const isAuthDataRecieved = ref(false);

export async function setUserPermissions() {
  try {
    isAuthDataRecieved.value = false;
    const { data } = await getPermissions();
    isAuthDataRecieved.value = true;
    userPermissions.value = data.data;
  } catch {
    throw 'İşlem sırasında bir hata oluştu lütfen tekrar deneyiniz.';
  }
}

function authorizationControl(auth) {
  if (auth === '') return true;
  if (Object.keys(userPermissions).length > 0) {
    return !!userPermissions.value[auth];
  }
  return false;
}

export { authorizationControl, isAuthDataRecieved };