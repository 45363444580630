// Inputlari sadece sayi alacak sekilde sekillendiren fonksiyon
// Input componentindeki @keypress eventine fonksiyon olarak gonderiliyor
export function inputNumericConverter(event) {
  event = event ? event : window.event;
  const charCode = event.which ? event.which : event.keyCode;
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) && //sayi olmayan diger karakterler
    charCode !== 44 && // ','
    charCode !== 45 //'-'
  ) {
    //sayilar ve virgul
    event.preventDefault();
  } else {
    return true;
  }
}

// Sayi degeri formatlayan fonsiyon altta kullaniliyor
export function number_format(number, decimals, dec_point, thousands_sep) {
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'

  let n = number,
    prec = decimals;
  n = !isFinite(+n) ? 0 : +n;
  prec = !isFinite(+prec) ? 0 : Math.abs(prec);
  const sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
  const dec = typeof dec_point === 'undefined' ? '.' : dec_point;

  let s = prec > 0 ? n.toFixed(prec) : Math.round(n).toFixed(prec); //fix for IE parseFloat(0.55).toFixed(0) = 0;

  const abs = Math.abs(n).toFixed(prec);
  let _ = '',
    i = 0;

  if (abs >= 1000) {
    _ = abs.split(/\D/);
    i = _[0].length % 3 || 3;

    _[0] =
      s.slice(0, i + (n < 0)) + _[0].slice(i).replace(/(\d{3})/g, `${sep}$1`);

    s = _.join(dec);
  } else {
    s = s.replace('.', dec);
  }

  return s;
}

export function numToTrStr(_number, _decimal = 2) {
  //tr_number
  // if (typeof _number === 'string') {
  //   return _number;
  // }

  if (!_number) {
    _number = 0;
  }
  _number = `${_number}`.valueOf().split('e-')[0];

  const orj_decimal = _decimal;
  const len = `${parseFloat(_number, _decimal) + 0}.`.split('.')[1].length;
  if (_decimal > 2 && len < 2) {
    _decimal = 2;
  } //1.2 > 1.20
  if (_decimal > 2 && len >= 2) {
    _decimal = len;
  } //1.23000 > 1.23
  if (_decimal > orj_decimal) {
    _decimal = orj_decimal;
  }
  const num = number_format(_number, _decimal, ',', '.');
  return num;
}

export function trStrToNum(x) {
  //db_number
  if (typeof x === 'number') {
    return x;
  }

  if (typeof x === 'undefined' || x === null) {
    return 0;
  }
  let r = parseFloat(`${x}`.replace(/\./g, '').replace(/,/g, '.'));
  if (isNaN(r)) {
    r = 0;
  }
  return r;
}

export function strToNumToStr(x) {
  // Bazi verileri dondururken gereken bir stil surekli fonksiyonu tekrarlamamk icin ekledim
  return numToTrStr(trStrToNum(x));
}

export function numToStrToNum(x) {
  // Bazi verileri dondururken gereken bir stil surekli fonksiyonu tekrarlamamk icin ekledim
  return trStrToNum(numToTrStr(x));
}
