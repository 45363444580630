class ImageOperations {
    getUrl(image) {
        return `${window.location.origin}/${image.image_path}${image.image_name}`;
    }
    async getWidth (image, url) {
        const img = new Image();
        if (!url) {
            img.src = this.getUrl(image);
        }
        img.onload = () => {
          const width = img.width > img.height ? "250" : "100";
          return width;
        };
      }
}

const imageOperations = new ImageOperations();

export default imageOperations;