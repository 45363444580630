import { ref, reactive } from '@vue/composition-api';

const addressList = ref<IMMAddress[]>([]);
const editedAddress = ref<number>(0);
const labelSize: doubleComponentLabelSize = 'lg';

const createEmptyState = (): IMMAddress => {
  return {
    id: '',
    source: '',
    sourceID: '',
    name: '',
    countryID: '0',
    countryName: '',
    provinceID: '0',
    provinceName: '',
    districtID: '0',
    districtName: '',
    neighbourhood: '',
    street: '',
    avenue: '',
    doorNo: '',
    telephone: '',
    explanation: '',
    depotName: '',
    depotID: 0,
    zipCode: ''
  };
};

const addressFormState: IMMAddress = reactive(createEmptyState());

export function useMMAddressesState() {
  return {
    addressList,
    labelSize,
    editedAddress,
    addressFormState,
    createEmptyState
  };
}
