<template>
  <div class="space-y-4">
    <div class="flex flex-col 2xl:flex-row responsive-margin-2xl">
      <wo-double-form :labelSize="labelSize" id="station-multiselect">
        <template #firstLabel> Süreçler </template>
        <template #firstElement>
          <multiselect
            :options="stationNames"
            placeholder="Seçiniz"
            :value="filteredStations"
            :close-on-select="false"
            :multiple="true"
            tag-position="above"
            @select="station => addStation(station)"
            @remove="station => removeStation(station)"
            :hide-selected="false"
          />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Cari </template>
        <template #firstElement>
          <wo-quick-search
            :queryParameters="`?tablo=cari&q=`"
            @selected="handlePrevalentQuickSearch"
            :itemRenderer="prevalentRenderer"
          />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Başlangıç Tarihi </template>
        <template #firstElement>
          <wo-date-picker v-model="startDate" />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Bitiş Tarihi </template>
        <template #firstElement>
          <wo-date-picker v-model="endDate" />
        </template>
        <template #secondBefore>
          <wo-button @click="getProductCards" text="Getir" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstElement>
          <wo-select v-model="source" :data="fetchCardOptionsData">
            <template #default="{ item }">
              <wo-select-item
                :key="`option-${item.id}`"
                :itemValue="item.id"
                :text="item.text"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
    </div>
    <div class="min-h-screen flex justify-center overflow-x-scroll py-12">
      <div class="bg-gray-100 rounded-lg px-3 py-3 m-2" :style="columnWidth">
        <p class="text-gray-700 font-semibold font-sans tracking-wide">TÜMÜ</p>
        <UretimPlaniUrunCard
          v-for="product in firstStationData"
          :key="`product-${product.lineID}`"
          :product="product"
          class="mt-3 cursor-move"
          :data-line-id="
            product.source !== 'siparis'
              ? `${product.lineID}`
              : `${product.detailId}`
          "
          @detailModal="handleCardClick($event)"
        >
        </UretimPlaniUrunCard>
      </div>
      <div
        v-for="(station, key) in filteredStations"
        :key="`station-${station}`"
        class="bg-gray-100 rounded-lg px-3 py-3 column-width m-2"
        :style="columnWidth"
      >
        <p class="text-gray-700 font-semibold font-sans tracking-wide">
          {{ station }}
        </p>
        <draggable
          class="h-full"
          :list="cardData[key + 1]"
          :animation="200"
          ghost-class="ghost-card"
          group="tasks"
          @end="handleDrag"
          :data-station-id="key + 1"
        >
          <UretimPlaniUrunCard
            v-for="product in cardData[key + 1]"
            :key="`product-${product.lineID}`"
            :product="product"
            class="mt-3 cursor-move"
            :data-line-id="
              product.source !== 'siparis'
                ? `${product.lineID}`
                : `${product.detailId}`
            "
            @detailModal="handleCardClick($event)"
          ></UretimPlaniUrunCard>
        </draggable>
      </div>
    </div>
    <UretimDetayModalVue
      :detailModal="detailModal"
      @detailModal="detailModal = $event"
      :sourceStationID="dragItemSourceStationID"
      :targetStationID="dragItemTargetStationID"
      :detailData="detailData"
      :stockUnits="stockUnits"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import UretimPlaniUrunCard from './components/UretimPlaniUrunCard.vue';
import UretimDetayModalVue from './components/UretimDetayModal.vue';
import { computed, ref, watchEffect } from '@vue/composition-api';
import {
  fetchProductCards,
  fetchTransferDetails
} from './helpers/uretimPlanlamaAPIService';
import { prevalentRenderer } from '@/helpers/quickSearchListRenderers/qsPrevalent';
import { useUretimPlanlamaState } from './composables/useUretimPlanlamaState';
import arraySorter from '@/helpers/arraySorter';

export default {
  components: {
    UretimPlaniUrunCard,
    draggable,
    UretimDetayModalVue
  },
  setup() {
    const {
      prevalentID,
      stations,
      startDate,
      endDate,
      getStations,
      getStockUnits,
      labelSize,
      stockUnits,
      fetchCardOptionsData
    } = useUretimPlanlamaState();

    getStations();
    getStockUnits();

    const cardData = ref({});
    const source = ref('1');

    const cartFilter = ref('');

    const stationNames = computed(() => {
      return stations.value
        .filter(station => station.id !== 0)
        .map(station => station.isim);
    });

    const filteredStations = ref([]);

    const allCards = ref([]);

    const firstStationData = computed(() => {
      return source.value === '2' ? allCards.value : cardData.value['0'];
    });

    const addStation = station => {
      filteredStations.value.push(station);
    };

    const columnWidth = computed(() => {
      return {
        width: `${100 / filteredStations.value.length}%`
      };
    });

    const removeStation = station => {
      filteredStations.value.splice(filteredStations.value.indexOf(station), 1);
    };

    const getProductCards = async () => {
      const data = await fetchProductCards(source.value);
      cardData.value = data;
      let cards = [];
      for (const key in cardData.value) {
        cards = [...cardData.value[key], ...cards];
      }
      for (const key in cardData.value) {
        cardData.value[key] = arraySorter(cardData.value[key], 'stockName');
      }

      const groupedCards = cards.reduce((acc, card) => {
        const key = card.stockID;
        acc[key]
          ? (acc[key].amount += card.amount)
          : (acc[key] = JSON.parse(JSON.stringify(card)));
        return acc;
      }, {});
      allCards.value = arraySorter(Object.values(groupedCards), 'stockName');
    };

    const handlePrevalentQuickSearch = item => {
      if (item.isSelected) {
        prevalentID.value = item.id;
      } else if (!item.isSelected && prevalentID.value) {
        prevalentID.value = '';
      }
    };

    const dragItemSourceStationID = ref('');
    const dragItemTargetStationID = ref('');
    const detailModal = ref(false);
    const detailData = ref([]);

    const handleDrag = async event => {
      const stationAttributeName = 'data-station-id';
      const lineAttributeName = 'data-line-id';
      const itemLineID = event.item.getAttribute(lineAttributeName);
      const sourceStationID = event.from.getAttribute(stationAttributeName);
      const targetStationID = event.to.getAttribute(stationAttributeName);
      const item = cardData.value[sourceStationID].find(
        item => Number(item.detailId) === Number(itemLineID)
      );
      const data = await fetchTransferDetails(
        itemLineID,
        sourceStationID,
        item.source
      );
      detailData.value = data.filter(item => item !== null);
      if (sourceStationID !== targetStationID) {
        // updateStation(itemLineID, targetStationID);
        dragItemSourceStationID.value = sourceStationID;
        dragItemTargetStationID.value = targetStationID;
        detailModal.value = true;
        detailModal.value = true;
      }
    };

    const handleCardClick = event => {
      detailModal.value = event;
    };

    // const cancelDrag = (dataToSend) => {
    //   // cardData.value[dataToSend.sourceStationID].find(item => item.stockID === dataTo)
    //   console.log(dataToSend.value, cardData.value);
    // };

    watchEffect(() => {
      filteredStations.value = stationNames.value;
    });

    return {
      stations,
      startDate,
      endDate,
      cardData,
      prevalentRenderer,
      handlePrevalentQuickSearch,
      getProductCards,
      handleDrag,
      labelSize,
      stationNames,
      filteredStations,
      addStation,
      removeStation,
      detailModal,
      dragItemTargetStationID,
      dragItemSourceStationID,
      detailData,
      stockUnits,
      handleCardClick,
      allCards,
      fetchCardOptionsData,
      source,
      columnWidth,
      firstStationData,
      cartFilter
      // filteredCards
    };
  }
};
</script>

<style lang="scss">
#station-multiselect {
  .multiselect {
    max-height: 100px;
  }

  .multiselect,
  .multiselect__select,
  .multiselect__tags {
    line-height: 1.4;
    font-size: 0.9em;
    max-height: 20px;
  }
  .multiselect__tags {
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .multiselect__tags {
    padding: 0 7px;
  }

  .multiselect__select::before {
    display: none;
  }
  .multiselect__option {
    padding: 0 7px;
  }
  .multiselect__option,
  .multiselect__option--highlight {
    background: none;
  }
  .multiselect__option--highlight {
    background: #5897fb;
  }
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #00769b;
  }
  // .column-width {
  //   min-width: 320px;
  //   width: 320px;
  // }
  .ghost-card {
    opacity: 0.5;
    background: #f7fafc;
    border: 1px solid #4299e1;
  }
}
</style>
