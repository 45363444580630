export function dateTimeFromServer(value) {
  if (!value) {
    return '';
  }
  const dateTimeArray = value.split(' ');
  const date = dateTimeArray[0];
  const time = dateTimeArray[1];
  if (date === '0000-00-00') {
    return '';
  }
  const dateArray = date.split('-');
  return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]} ${time}`;
}

export function dateFromServer(value) {
  //Y-m-d > d.m.Y
  if (!value) {
    return '';
  }
  const dateArray = value.split('-');
  let resultDate = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
  if (resultDate === '00.00.0000' || value === '' || value.length < 8) {
    resultDate = '';
  }
  return resultDate;
}
