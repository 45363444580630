<template>
  <div class="space-y-16">
    <ModelPlanlamaDetailsForm :stateKey="stateKey" :tableColumns="tableColumns" />
    <!-- <span class="text-xs text-danger">
      * Arkaplanı kırmızı olan satırların kayıtları bulunmamaktadır.
    </span> -->
    <ModelPlanlamaDetailsTables :stateKey="stateKey" :tableColumns="tableColumns" />
    <div class="flex-container">
      <ModelPlanlamaDetailsCalculations :stateKey="stateKey" class="ml-2 mr-12" />
      <ModelPlanlamaDetailsMaterialCastingList :stateKey="stateKey" style="max-height: 470px; max-width: 900px;" class="ml-12"/>
    </div>
    <ModelPlanlamaDetailsButtons
      :stateKey="stateKey"
      :modelId="state[stateKey].info.id"
    />
  </div>
</template>

<script>
import ModelPlanlamaDetailsCalculations from "./ModelPlanlamaDetailsCalculations.vue";
import ModelPlanlamaDetailsButtons from "./ModelPlanlamaDetailsButtons.vue";
import ModelPlanlamaDetailsForm from "./ModelPlanlamaDetailsForm.vue";
import ModelPlanlamaDetailsTables from "./ModelPlanlamaDetailsTables.vue";
import ModelPlanlamaDetailsMaterialCastingList from "./ModelPlanlamaDetailsMaterialCastingList.vue";
import { useModelPlanlamaState } from "../../composables/useModelPlanlamaState";

export default {
  components: {
    ModelPlanlamaDetailsCalculations,
    ModelPlanlamaDetailsButtons,
    ModelPlanlamaDetailsForm,
    ModelPlanlamaDetailsTables,
    ModelPlanlamaDetailsMaterialCastingList,
  },

  props: {
    stateKey: String,
  },
  setup() {
    const { tableColumns, state } = useModelPlanlamaState();

    return {
      tableColumns,
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
  @media (min-width: 800px) {
    .flex-container {
      display: flex;
    }
  }
  @media (max-width: 799.9px) {
    .flex-container {
      display: block;
    }
  }
</style>
