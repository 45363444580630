import { ref } from '@vue/composition-api';
import { errorDialog } from '@/helpers/errorDialogs.js';
const overlay = ref(false);

function handleTriggerOverlay(data) {
  if (!data.enable) {
    overlay.value = true;
    errorDialog(
      data.enableMessage
        ? data.enableMessage
        : 'Bu evrak başkası tarafından düzenlenmektedir.'
    );
  }
}

export { overlay, handleTriggerOverlay };
