<template>
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="model_planlama"
    :auth="moduleConfig.auth"
  >
    <ModelPlanlama />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import ModelPlanlama from './ModelPlanlama.vue';

import { computed } from '@vue/composition-api';

export default {
  components: {
    WOModuleWrapper,
    ModelPlanlama
  },

  setup(props, { root }) {
    const moduleConfig = computed(() => {
      if (root.$route.params.id) {
        return {
          title: 'Model Planlama ve Üretim Emri Düzenle',
           auth: 'model_planlama_duzenle' //'model_planlama_duzenle'
        };
      } else {
        return { title: 'Yeni Model Planlama ve Üretim Emri', auth: 'model_planlama_ekle' }; //'model_planlama_ekle' };
      }
    });
    return {
      moduleConfig
    };
  }
};
</script>
