<template>
  <div class="vfa-demo w-9/12">
    <VueFileAgent
      v-model="fileInput"
      ref="vfaDemoRef"
      class="upload-block"
      theme="list"
      :helpText="fileInputText"
      :errorText="fileInputErrorText"
      multiple
      :deletable="true"
    >
      <template v-slot:file-preview="slotProps">
        <div
          :key="slotProps.index"
          class="grid-box-item grid grid-cols-2 file-row"
        >
          <div>
            <strong>{{ slotProps.fileRecord.name() }}</strong>
            <span class="text-muted">
              ({{ slotProps.fileRecord.size() }})
            </span>
          </div>
          <div class="flex flex-row justify-end items-center">
            <div
              class="progress my-auto"
              :class="{
                completed: slotProps.fileRecord.progress() == 100
              }"
            >
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: slotProps.fileRecord.progress() + '%' }"
              ></div>
            </div>
            <wo-button
              class="p-0 text-2xl"
              type="flat"
              color="dark"
              @click="removeFileRecord(slotProps.fileRecord)"
              text="×"
            />
          </div>
        </div>
      </template>
      <template v-slot:file-preview-new>
        <div class="text-left my-3" key="new">
          <span class="text-primary">Select files</span> or drag & drop here
        </div>
      </template>
    </VueFileAgent>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  setup() {
    const vfaDemoRef = ref(null);

    const fileInput = ref(null);
    const fileInputText = 'Dosya seç veya sürükle';
    const fileInputErrorText = {
      type: 'Geçersiz dosya tipi',
      size: 'Dosya <maxSize> boyutunu geçmemeli'
    };

    const removeFileRecord = fileRecord => {
      return vfaDemoRef.value.removeFileRecord(fileRecord);
    };

    return {
      vfaDemoRef,
      fileInput,
      fileInputText,
      fileInputErrorText,
      removeFileRecord
    };
  }
};
</script>

<style></style>
