<template>
  <div
    class="bg-gray-500 shadow rounded px-3 pt-3 pb-5 border border-white relative imaged-bg"
    :style="bgImageStyling"
  >
    <div class="flex justify-between">
      <a
        @click="$emit('detailModal', true)"
        class="block font-semibold font-sans tracking-wide text-sm text-gray-100 underlined-hovered-link"
        target="_blank"
      >
        {{ product.stockName }}
      </a>
    </div>
    <div class="flex flex-col mt-4 justify-between">
      <span class="text-sm text-gray-200"
        >Miktar: {{ product.amount }} {{ unit }}</span
      >
      <span class="text-sm text-gray-200">Tarih: {{ product.date }}</span>
      <span class="text-sm text-gray-200"
        >Birim Fiyat: {{ product.stockPrice }}</span
      >
      <a
        class="underlined-hovered-link text-gray-200 text-sm"
        :href="product.source !== 'siparis' ? `${$baseURL}/?s=receteler&tip=1&duzenle=${product.id}` : `${$baseURL}/?s=siparisler&duzenle=${product.id}`"
        target="_blank"
        >{{ `${product.serial} - ${product.sequence}` }}</a
      >
      <!-- <img src="https://takipet.webofis.im/test/dosyalar/webofisim_qustyle_test/model_planlama/1651139445-7dd094fdf12b090d.png" :width="imageOperations.getWidth('', 'https://takipet.webofis.im/test/dosyalar/webofisim_qustyle_test/model_planlama/1651139445-7dd094fdf12b090d.png')"> -->
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api';
import { useUretimPlanlamaState } from '../composables/useUretimPlanlamaState';
import imageOperations from '@/helpers/imageOperations';
export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { stockUnits } = useUretimPlanlamaState();

    const unit = computed(() => {
      if (!stockUnits || !stockUnits.value) {
        return '';
      }
      const foundUnit = stockUnits.value.find(
        a => +a.id === +props.product.stockUnit
      );
      return foundUnit ? foundUnit.kod : '';
    });

    const bgImageStyling = computed(() => {
      return !props.product.stockImage
        ? ''
        : `background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.product.stockImage}) no-repeat center;`;
    });

    return {
      stockUnits,
      unit,
      bgImageStyling,
      imageOperations
    };
  }
};
</script>

<style scoped>
.imaged-bg {
  background-size: cover !important;
}
</style>
