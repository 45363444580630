<template>
  <WOBasketsRaw
    :noPadding="noPadding"
    quickSearchQuery="?tablo=sepetler&q="
    label="Sepetler"
    directLink="/?s=sepetler&duzenle="
    @updateLines="item => $emit('updateBasket', item)"
  >
    <template v-slot:quickSearch="{ item }">
      <span>
        {{ item.isim }}
      </span>
    </template>
  </WOBasketsRaw>
</template>

<script>
import WOBasketsRaw from './WOBasketsRaw';

export default {
  components: {
    WOBasketsRaw
  },
  props: {
    noPadding: {
      type: Boolean,
      default: false
    }
  }
};
</script>
