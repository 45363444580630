<template>
  <vs-prompt
    :active="active"
    title="Gönderim Parametreleri"
    buttons-hidden
    @close="$emit('update:active', false)"
    class="one-step-behind-dialog"
  >
    <div class="grid grid-cols-2 gap-2">
      <span>Gönderilecek Para Birimi:</span>
      <wo-select
        elementClasses="w-26"
        v-model="currencyValue"
        :data="usedExchangeRates"
      >
        <template v-slot:defaultValue>
          <wo-select-item itemValue="" text="- Seçiniz -" />
        </template>
        <template v-slot:default="{ item }">
          <wo-select-item
            :itemValue="item.kod"
            :text="item.kod"
            :key="`email-currencies-${item.kod}`"
          />
        </template>
      </wo-select>
      <span>Gönderilecek Dil:</span>
      <wo-select elementClasses="w-26" v-model="langValue" :data="langData">
        <template v-slot:default="{ item }">
          <wo-select-item
            :itemValue="item.val"
            :text="item.text"
            :key="`preview-mail-lang-${item.val}`"
          />
        </template>
      </wo-select>
    </div>
    <div class="flex flex-row justify-end mt-4">
      <wo-button text="Email Penceresini Aç" @click="handleInnerPopup" />
    </div>

    <vs-prompt
      buttons-hidden
      :active.sync="emailPreviewModal"
      title="Gönderilecek Mail Önizleme"
      class="wide-dialog-800"
    >
      <div
        v-html="emailPreviewHTML"
        class="max-h-96 overflow-auto w-full"
      ></div>
    </vs-prompt>

    <!-- <WOEmailDialog
      :title="title"
      :active.sync="emailModalVisibility"
      :previewHTML="emailPreviewHTML"
      :predeterminedEmails="predeterminedEmails"
    /> -->
  </vs-prompt>
</template>

<script>
import { getPreviewEmail } from '@/helpers/ApiController';
import { errorNotify } from '@/helpers/errorDialogs';

// import WOEmailDialog from './WOEmailDialog.vue';

import { ref } from '@vue/composition-api';

export default {
  // components: { WOEmailDialog },
  props: {
    active: Boolean,
    title: {
      type: String,
      default: 'Mail Gönder'
    },
    id: [Number, String],
    usedExchangeRates: Array,
    willSendMail: Boolean,
    predeterminedEmails: {
      type: Array,
      default: () => []
    },
    isForCurrentCompany: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // * E-mail Button Actions
    const emailPreviewModal = ref(false);
    const emailModalVisibility = ref(false);

    const currencyValue = ref(window.anaparabirimi || 'TL'); // will be main currency

    // ileride burasi mevcut dilleri i18nden cekebilir
    const langData = [
      { val: '0', text: '- Varsayılan -' },
      { val: 'EN', text: 'İngilizce' }
    ];
    const langValue = ref('0');

    const emailPreviewHTML = ref('');

    const apiValueAssign = async () => {
      try {
        emailPreviewHTML.value = await getPreviewEmail(
          props.id,
          currencyValue.value,
          langValue.value,
          props.isForCurrentCompany
        );
      } catch (error) {
        if (error.data) {
          errorNotify(error.data.message);
        } else {
          errorNotify('Bir hata oluştu.');
        }
      }
    };

    window.mail_yaz_renderHtml = () => {
      const mailForm = document.getElementById('mailYazForm');
      mailForm.querySelector('[name=her_alici_icin_ayri_mail]').value = 1;
      mailForm.querySelector('[name=footer]').value = 0;

      const recieverInput = mailForm.querySelector('[name=alici]');
      if (window.predeterminedEmails.length) {
        for (const obj of window.predeterminedEmails) {
          recieverInput.value = obj.email;
          window.mail_secildi(recieverInput);
        }
      }
      return window.emailPreviewHTML;
    };

    const openEmailModal = () => {
      window.ajaxpen(400, 250, props.title, 'mail_yaz&tip=normal');

      emit('update:active', false);
      // setTimeout(() => {
      //   document.querySelector('[name="html"]').value = emailPreviewHTML.value;
      // }, 0);
    };

    const handleInnerPopup = () => {
      apiValueAssign().then(() => {
        if (props.willSendMail) {
          window.predeterminedEmails = [...props.predeterminedEmails];
          window.emailPreviewHTML = emailPreviewHTML.value;
          openEmailModal();
          // emailModalVisibility.value = true;
        } else {
          emailPreviewModal.value = true;
        }
      });
    };

    return {
      emailPreviewModal,
      emailModalVisibility,
      currencyValue,
      langData,
      langValue,
      emailPreviewHTML,
      handleInnerPopup
    };
  }
};
</script>

<style></style>
