<template>
  <div class="space-y-4">
    <div
      v-if="$route.params.id"
      class="flex flex-col sm:flex-row responsive-margin-sm"
    >
      <label>Firma ÜTS Kurum No</label>
      <vx-tooltip text="ÜTS : Ürün Takip Sistemi">
        <base-input v-model="state.companyProductTrackingSystemNo" />
      </vx-tooltip>
      <wo-button text="Uts Kaydet" @click="saveCompanyProductTrackingSystem" />
    </div>

    <CariFormSectionDivider v-if="$route.params.id" />

    <CariFormSectionHeader> E-Fatura Kod Alanları </CariFormSectionHeader>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        <span v-if="canStockSettingsBeSeen">
          Depo Teslim Kodu
        </span>
      </template>
      <template #firstElement>
        <base-input
          v-if="canStockSettingsBeSeen"
          v-model="state.depotDeliveryCode"
        />
      </template>
      <template #secondLabel>
        <span v-if="canStockSettingsBeSeen">
          Mağaza Teslim Kodu
        </span>
      </template>
      <template #secondElement>
        <base-input
          v-if="canStockSettingsBeSeen"
          v-model="state.storeDeliveryCode"
        />
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        <span v-if="canStockSettingsBeSeen">
          Mağaza Kodu.
        </span>
      </template>
      <template #firstElement>
        <base-input v-if="canStockSettingsBeSeen" v-model="state.storeCode" />
      </template>
      <template #secondLabel>
        Firma Kodu
      </template>
      <template #secondElement>
        <base-input v-model="state.companyCode" />
      </template>
    </wo-double-form>

    <CariFormSectionDivider />

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        Müşteri Kartları / <br />
        Sadakat Kartları <br />
        <span class="text-warning">
          (Kart numaralarını alt-alta yazınız)
        </span>
      </template>
      <template #firstElement>
        <wo-textarea v-model="state.cards" />
      </template>
    </wo-double-form>

    <CariFormSectionDivider />

    <CariFormSectionHeader> Kullanıcı Bilgileri </CariFormSectionHeader>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        Parola
      </template>
      <template #firstElement>
        <base-input type="password" v-model="state.password" />
      </template>
    </wo-double-form>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState.js';

import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';

import { saveUts } from '../../helpers/ApiFactory.js';
import { primaryDialog } from '@/helpers/primaryDialogs.js';
import { ref, onMounted } from '@vue/composition-api';

export default {
  components: {
    CariFormSectionDivider,
    CariFormSectionHeader
  },
  setup(props, { root }) {
    const { labelSize, state } = usePrevalentState();

    const saveCompanyProductTrackingSystem = () => {
      saveUts(root.$route.params.id, state.companyProductTrackingSystemNo).then(
        data => {
          if (data) {
            primaryDialog(data.message, 'Başarılı');
          }
        }
      );
    };

    const canStockSettingsBeSeen = ref('');

    onMounted(() => {
      canStockSettingsBeSeen.value =
        window.cari_kartlarda_stok_ayarlari_gozuksun === '1';
    });

    return {
      labelSize,
      canStockSettingsBeSeen,
      state,
      saveCompanyProductTrackingSystem
    };
  }
};
</script>

<style lang="scss" scoped></style>
