<template>
  <div class="space-y-4">
    <CariFromSectionHeader> Aktif Çalışılan Mağazalar </CariFromSectionHeader>
    <!-- <wo-multiselect-listbox
      v-model="selectedActivelyWorkingStores"
      :options="activelyWorkingStores"
      :reduce-display-property="option => option.isim"
      :reduce-value-property="option => option.id"
      search-options-placeholder="Ara"
      no-options-text=""
      :is-api-recieved="isApiRecieved"
      selected-options-placeholder="Ara"
      selected-no-options-text=""
    /> -->
    <multiselect
      :value="selectedActivelyWorkingStores"
      :options="activelyWorkingStores"
      :multiple="true"
      :close-on-select="false"
      :preserve-search="true"
      placeholder=""
      label="isim"
      track-by="id"
      @select="selectElement"
      @remove="removeElement"
    />
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState';
import CariFromSectionHeader from '../common/CariFormSectionHeader.vue';

import { ref, computed } from '@vue/composition-api';

import { getActivelyWorkingStores } from '../../helpers/ApiFactory.js';

export default {
  components: {
    CariFromSectionHeader
  },
  setup() {
    const { state } = usePrevalentState();
    const activelyWorkingStores = ref([]);

    const isApiRecieved = ref(false);

    const initializeAPI = async () => {
      activelyWorkingStores.value = await getActivelyWorkingStores();
      console.log('Actively working stores: ', activelyWorkingStores.value);
      isApiRecieved.value = true;
    };
    initializeAPI();

    const selectedActivelyWorkingStores = computed({
      get() {
        console.log(state.activelyWorkingStores);
        const result = isApiRecieved.value
          ? activelyWorkingStores.value.filter(store =>
              state.activelyWorkingStores.includes(store.id)
            )
          : [];
        return result;
      },
      set(val) {
        console.log('set: ', val);
        state.activelyWorkingStores = [...val];
      }
    });

    const removeElement = value => {
      selectedActivelyWorkingStores.value = selectedActivelyWorkingStores.value
        .filter(store => store.id !== value.id)
        .map(store => store.id);
    };

    const selectElement = value => {
      console.log(
        'selectElement: ',
        value,
        selectedActivelyWorkingStores.value
      );
      selectedActivelyWorkingStores.value = [
        ...state.activelyWorkingStores,
        value.id
      ];
    };

    return {
      state,
      activelyWorkingStores,
      selectedActivelyWorkingStores,
      isApiRecieved,
      removeElement,
      selectElement
    };
  }
};
</script>

<style lang="scss" scoped></style>
