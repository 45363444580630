import { ref } from '@vue/composition-api';
import moment from 'moment';
import { fetchStations } from '../helpers/uretimPlanlamaAPIService';
import { getStockUnits as fetchStockUnits } from '@/helpers/ApiController';

const stations = ref([
  { id: 0, isim: 'Tümü' }
]);

const stockUnits = ref([]);

const getStations = async () => {
  stations.value.push(...await fetchStations());
};

const getStockUnits = async () => {
  stockUnits.value = await fetchStockUnits();
};

const fetchCardOptionsData = [
  {
    id: '1',
    text: 'Tümü',
    value: 'tumu'
  },
  {
    id: '2',
    text: 'Üretim Fişlerinden Getir',
    value: 'uretim_fisi'
  },
  {
    id: '3',
    text: 'Model Planlamadan Getir',
    value: 'model_planlama'
  },
  {
    id: '4',
    text: 'Siparişlerden Getir',
    value: 'siparis'
  }
];

const labelSize = 'sm';

const prevalentID = ref(0);

const startDate = ref(
  moment()
    .startOf('year')
    .format('DD.MM.YYYY')
);

const endDate = ref(
  moment()
    .endOf('year')
    .format('DD.MM.YYYY')
);

export function useUretimPlanlamaState() {
  return {
    stations,
    prevalentID,
    startDate,
    endDate,
    getStations,
    getStockUnits,
    stockUnits,
    labelSize,
    fetchCardOptionsData
  };
}
