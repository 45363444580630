import { ref, computed } from '@vue/composition-api';

const teklifGonderilecekFirmalarData = ref([]);
const selectedPrevalentsIDs = ref([]);
const predeterminedEmails = computed(() => {
  return teklifGonderilecekFirmalarData.value.map(item => {
    return {
      id: item.rehber_id,
      name: item.teklif_yetkili,
      email: item.teklif_yetkili_mail,
      from: 'rehber'
    };
  });
});

const handleSelectedCompanies = data => {
  teklifGonderilecekFirmalarData.value = [...data];
  selectedPrevalentsIDs.value = data.map(item => item.cari_id);
};

export default function useCompaniesSendOffer() {
  return {
    teklifGonderilecekFirmalarData,
    selectedPrevalentsIDs,
    predeterminedEmails,
    handleSelectedCompanies
  };
}
