<template>
  <base-input
    v-model="inputVal"
    @keypress="handlePattern"
    :danger="danger"
    :dangerText="dangerText"
    v-on="$listeners"
    v-bind="$attrs"
    :inputClass="inputClass"
  />
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';

export default {
  name: 'WOIntInput',
  inheritAttrs: false,
  mixins: [inputComponentMixin],
  props: {
    pattern: RegExp
  },
  methods: {
    handlePattern(event) {
      event = event ? event : window.event;
      const key = event.key;
      if (!key.match(this.pattern)) {
        event.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
