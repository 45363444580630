<template>
    <wo-table v-if="renderComponent">
        <wo-tr><wo-th colspan="4" style="font-size: 1.2rem;">MAKİNE BİLGİLERİ</wo-th></wo-tr>
        <wo-tr>
            <wo-th style="width: 10px;">MEKİKLER</wo-th>
            <wo-th v-for="info in columnInformation" :key="info.title" :customStyle="info.rowStyle">
                {{info.title}}
            </wo-th> 
        </wo-tr>
        <wo-tr v-for="index in titles" :key="index">
            <wo-td style="font-weight: 600;" class="text-right">{{index}}</wo-td>
            <wo-td v-for="info in columnInformation" :key="index + info.title" class="text-center machine-info-column">
                <div v-if="info.title === 'İPLİK CİNSİ'" class="flex justify-center">
                    <wo-quick-search
                        v-if="machineInfo[index] ? !machineInfo[index]['isNewYarnType'] : true"
                        :value="stokHammaddeData[index]"
                        queryParameters="?tablo=stok_hammadde&q="
                        @selected="(item) => handleRowStockSelected(item, index)"
                        :itemRenderer="(item) => nameAndCodeWithLinkRenderer(item)"
                    />
                    <input type="text" v-model="machineInfo[index]['İPLİK CİNSİ']" :style="info.inputStyle" :placeholder="info.placeholder" class="text-center" v-else>
                    <vs-checkbox title="Yeni eklemek için seçiniz" v-model="machineInfo[index]['isNewYarnType']" @change="renderDom"/>
                </div>
                <wo-quick-search
                    v-else-if="info.title === 'MEKİK RENKLERİ'"
                    :queryParameters="`?tablo=stok_renk_kodu&q=`"
                    :value="stockColorData[index]"
                    :itemRenderer="nameAndCodeRenderer"
                    @selected="(item) => handleRowColorSelected(item, index)"
                />
                <input type="text" v-model="machineInfo[index][info.title]" :style="info.inputStyle" :placeholder="info.placeholder" class="text-center" v-else>
            </wo-td>
        </wo-tr>
        <wo-tr>
            <wo-tr class="text-center">
                <wo-td class="text-right"><input type="text" style="border-radius: 3px; object-fit: fill;" v-model="newTitle" @keypress.enter="addNewTitle">
                    <feather-icon
                        icon="PlusIcon"
                        style="cursor: pointer;"
                        class="h-5 w-5 align-middle plusIcon text-success ml-2"
                        @click="addNewTitle"
                    /> 
                </wo-td>
            </wo-tr>
        </wo-tr>
    </wo-table>
</template>

<script>
import { computed, onUpdated, reactive, ref, watch } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';

export default {

    setup(_, { root }) {

        const renderComponent = ref(true);

        const renderDom = () => {
            renderComponent.value = false;
            renderComponent.value = true;
        };

        const { state, newMachineInfoTitles } = useModelPlanlamaState();

        const machineInfo = computed(() => state['design'].machine_information);
        
        const titles = computed(() => Object.keys(machineInfo.value));

        const newTitle = ref("");

        const addNewTitle = () => {
            if (newTitle.value !== "") {   
                machineInfo.value[newTitle.value] = { isNewYarnType: false };
                titles.value.push(newTitle.value);
                newMachineInfoTitles.value.push(newTitle.value);
                newTitle.value = "";
            }
        };

        const stokHammaddeData = reactive({});

        const stockColorData = reactive({});

        const handleRowStockSelected = (item, index) => {
            if (item.isSelected) {
                machineInfo.value[index]['İPLİK CİNSİ'] = `${item.kod} ${item.isim}`;
                root.$set(stokHammaddeData, index, `${item.kod} ${item.isim}`);
            } else {
                machineInfo.value[index]['İPLİK CİNSİ'] = "";
            }
        };
        const handleRowColorSelected = (item, index) => {
            if (item.isSelected) {
                machineInfo.value[index]['MEKİK RENKLERİ'] = `${item.isim} ${item.kod}`;
                root.$set(stockColorData, index, `${item.kod} ${item.isim}`);
            } else {
                machineInfo.value[index]['MEKİK RENKLERİ'] = "";
            }
        };

        const isNewYarnType  = (index) => {
            const result = machineInfo.value[index] ? !machineInfo.value[index]['isNewYarnType'] : true;
            return result;
        };

        // dinamik yapilabilir
        const columnInformation = [
            {
                title: "İPLİK CİNSİ",
                inputStyle: 'border-radius: 3px; height: 30px;',
                placeholder: '',
                rowStyle: 'width: 30px'
            },
            {
                title: "MEKİK RENKLERİ",
                inputStyle: 'border-radius: 3px; height: 30px;',
                placeholder: 'KOD/RENK',
                rowStyle: 'width: 40px;'
            },
            {
                title: "İPLİK KATI",
                inputStyle: 'border-radius: 3px; height: 30px;',
                placeholder: '',
                rowStyle: 'width: 20px;'
            },
        ];

        const setEmptyValues = () => {
            titles.value.forEach(title => {
                if (!machineInfo.value[title]) {
                    machineInfo.value[title] = {};
                }
                machineInfo.value[title]['isNewYarnType'] = false;
            });
        };

        watch(() => titles.value, () => setEmptyValues());

        onUpdated(() => {
            for (const key in machineInfo.value) {
                if (Object.keys(machineInfo.value[key]).includes("İPLİK CİNSİ")) {
                    root.$set(stokHammaddeData, key, machineInfo.value[key]["İPLİK CİNSİ"]);
                    // stokHammaddeData[key] = machineInfo.value[key]["İPLİK CİNSİ"];
                }
                if (Object.keys(machineInfo.value[key]).includes("MEKİK RENKLERİ")) {
                    root.$set(stockColorData, key, machineInfo.value[key]["MEKİK RENKLERİ"]);
                }
            }
        });

        // onBeforeMount(() => {
        //     setEmptyValues();
        // });

        return {
            columnInformation,
            machineInfo,
            newMachineInfoTitles,
            // addedTitle,
            // addTitle,
            titles,
            nameAndCodeRenderer,
            nameAndCodeWithLinkRenderer,
            handleRowStockSelected,
            stokHammaddeData,
            renderDom,
            renderComponent,
            newTitle,
            addNewTitle,
            isNewYarnType,
            handleRowColorSelected,
            stockColorData,
        };
    }
};
</script>

<style lang="scss" scoped>
.machine-info-column {
    div.relative {
        justify-content: center;
    }
}

input::placeholder {
    font-size: x-small;
    font-weight: light;
}
</style>