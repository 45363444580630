<template>
  <!-- @keypress="groupInput" -->
  <base-input
    v-model="inputVal"
    @keypress="groupInput"
    @blur="groupInputBlur"
    :danger="danger"
    :dangerText="dangerText"
    v-on="$listeners"
    v-bind="$attrs"
    :inputClass="`${inputClass} uppercase`"
  />
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';

export default {
  name: 'WOIntInput',
  inheritAttrs: false,
  props: {
    seperator: {
      type: String,
      default: ' '
    },
    groupedChars: {
      type: Number,
      default: 4
    }
  },
  mixins: [inputComponentMixin],
  methods: {
    groupInput() {
      const trimmedVal = this.removeSeperators();
      const isReachedSeperationLimit =
        trimmedVal.length % this.groupedChars === 0;
      if (trimmedVal.length && isReachedSeperationLimit) {
        this.inputVal = `${this.inputVal} `;
      }
    },
    groupInputBlur() {
      if (this.checkCorrectlySeperated()) {
        return;
      }
      this.inputVal = this.addSeperatorBlur();
    },
    removeSeperators() {
      return this.inputVal.replaceAll(this.seperator, '');
    },
    getSupposedNumberOfSeperators() {
      return Math.ceil(this.removeSeperators().length / this.groupedChars) - 1;
    },
    getSupposedIndex(i) {
      const seperatorOffset = i * (this.groupedChars + 1);
      return seperatorOffset + this.groupedChars;
    },
    addSeperatorBlur() {
      const trimmedVal = this.removeSeperators();
      const valArr = trimmedVal.split('');
      for (let i = 0; i < this.getSupposedNumberOfSeperators(); i++) {
        const supposedIndex = this.getSupposedIndex(i);
        valArr.splice(supposedIndex, 0, this.seperator);
      }
      return valArr.join('');
    },
    checkCorrectlySeperated() {
      const indices = this.getSeperatorIndices();
      const supposedNumberOfSeperators = this.getSupposedNumberOfSeperators();
      for (let i = 0; i < supposedNumberOfSeperators; i++) {
        const supposedIndex = this.getSupposedIndex(i);
        if (indices[i] !== supposedIndex) {
          return false;
        }
      }
      return true;
    },
    getSeperatorIndices() {
      const indices = [];
      let lastCheckedIndex = this.inputVal.indexOf(this.seperator);
      while (lastCheckedIndex >= 0) {
        indices.push(lastCheckedIndex);
        lastCheckedIndex = this.inputVal.indexOf(
          this.seperator,
          lastCheckedIndex + 1
        );
      }
      return indices;
    }
  }
};
</script>
