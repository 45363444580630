<template>
    <div>
        <wo-table>
            <template #thead>
                <wo-th class="text-right">Renk/Varyant</wo-th>
                <wo-th></wo-th>
                <wo-th v-for="size in selectedSizes[stateKey]" :key="`col-${size}`" class="size-row">
                    {{size}}
                </wo-th>
            </template>
            <wo-tr v-for="color in selectedColors[stateKey]" :key="`row-${color}`">
                <wo-td class="text-right" style="width: 200px;">
                    {{color}}
                </wo-td>
                <wo-td style="width: 100px">
                    <!-- <input
                        type="number"
                        v-model="localVariants[color]['amount']"
                        :min="0"
                        class="text-right"
                        style="width: 50px; border-radius: 4px;"
                    > -->
                    <!-- <feather-icon
                        v-if="stateKey==='confirmed'"
                        icon="PlusIcon"
                        class="h-5 w-5 align-middle plusIcon text-success"
                        @click="handleVariantRecipeModal('color', color)"
                    /> -->
                </wo-td>
                <wo-td v-for="size in selectedSizes[stateKey]" :key="`input-${{color}}${{size}}`" class="text-center">
                    <input type="number"
                        v-model="localVariants[color][size]"
                        :min="0"
                        class="text-right"
                        style="width: 50px; border-radius: 4px;"
                    >
                    <!-- <feather-icon
                        v-if="stateKey==='confirmed'"
                        icon="PlusIcon"
                        class="h-5 w-5 align-middle plusIcon text-success"
                        @click="handleVariantRecipeModal('size', color, size)"
                    /> -->
                </wo-td>
            </wo-tr>
        </wo-table>
        <!-- <VariantRecipeModal
            :variantRecipeModal="variantRecipeModalByColor"
            @variantRecipeModalByColor="variantRecipeModalByColor = $event"
            :color="colorProp"
        />
        <VariantRecipeModal
            :variantRecipeModal="variantRecipeModalBySize"
            @variantRecipeModalBySize="variantRecipeModalBySize = $event"
            :color="colorProp"
            :size="sizeProp"
        /> -->
    </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
// import VariantRecipeModal from './VariantRecipeModal.vue';
export default {
    props: {
        stateKey: String
    },
    components: {
        // VariantRecipeModal
    },

    setup (props) {

        const { selectedColors, selectedSizes, state, idListByColor, idListBySize} = useModelPlanlamaState();

        const variantRecipeModalBySize = ref(false);
        const variantRecipeModalByColor = ref(false);

        const colorProp = ref('');
        const sizeProp = ref(0);

        const localVariants = computed(() => state[props.stateKey].info.modelVariants);

        // const localVariants = computed(() => {
        //     const variants = {};
        //     selectedColors[props.stateKey].forEach(color => {
        //         variants[color] = {};
        //         variants[color]['amount'] = state[props.stateKey].info.modelVariants[color].amount;
        //         selectedSizes[props.stateKey].forEach(size => {
        //             variants[color][size] = state[props.stateKey].info.modelVariants[color][size];
        //             });
        //         });
        //     // const variants = Object.assign({}, state[props.stateKey].info.modelVariants);
        //     // delete variants['varyant_stok_id'];
        //     return variants;
        // });

        const handleVariantRecipeModal = (key, color, size) => {
            colorProp.value = idListByColor.value[color];
            const sizeKey = size ? `data_${size}` : undefined;
            sizeProp.value = sizeKey ? idListBySize.value[colorProp.value][sizeKey] : undefined;
            key === 'color' ? variantRecipeModalByColor.value = true : variantRecipeModalBySize.value = true;
        };

        return {
            selectedColors,
            selectedSizes,
            localVariants,
            handleVariantRecipeModal,
            variantRecipeModalByColor,
            variantRecipeModalBySize,
            colorProp,
            sizeProp
        };
    }
};
</script>

<style lang="scss" scoped>
    .size-row {
        .plusIcon svg{
            width: 14px;
            color: #006400;
        }
    }
</style>