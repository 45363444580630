import { ref } from '@vue/composition-api';
import {
  fetchFeedbackList,
  fetchStatusList
} from '../helpers/jobApplicationAPIService';

const statusList = ref([]);

const statusIndexNameMap = ref([]);

const getStatusList = async () => {
  statusList.value = await fetchStatusList();
  const nullOrZeroAvoider = '';
  statusIndexNameMap.value = [
    nullOrZeroAvoider,
    ...statusList.value.map(a => a.name)
  ];
};

const statusPopup = ref(false);

const openStatusPopup = () => {
  statusPopup.value = true;
};

const feedbackList = ref([]);

const getFeedbackList = async id => {
  feedbackList.value = await fetchFeedbackList(id);
};

export function useJobApplicationState() {
  return {
    statusList,
    getStatusList,
    statusIndexNameMap,
    feedbackList,
    getFeedbackList,
    statusPopup,
    openStatusPopup
  };
}
