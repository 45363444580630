<template>
  <div ref="tableRoot" id="tableRoot">
    <table id="table0">
      <tr>
        <td
          style="border-style: none; height: 250px"
          :colspan="localState.state.imageUrls.length"
        >
          <img
            :src="imageProccess.getUrl(image)"
            v-for="(image, index) in localState.state.imageUrls"
            :key="'images-' + index"
            :width="imageOperations.getWidth(image)"
          />
        </td>
      </tr>
    </table>
    <table
      ref="tableRoot1"
      id="table1"
      style="border: 1px solid black; font-size: 16px"
    >
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Tarih
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.date }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px"
          class="deletedColumn"
          colspan="2"
          v-for="(image, index) in localState.state.imageUrls"
          :key="'images-' + index"
        >
          <img
            :src="imageProccess.getUrl(image)"
            style="max-width: 100%; width: auto; max-height: 200px"
          />
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Teslim Tarihi
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.deliveryDate }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Model Kodu
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.modelCode }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Model Adı
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.modelName }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Ana Yüklenici
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.mainContractorName }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Depo
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ getValueById(localState.state.depot, 'depots') }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Seri Sıra
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.serial + ' ' + localState.state.sequence }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Durum
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ getValueById(localState.state.status, 'statuses') }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Koleksiyon
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ getValueById(localState.state.collection, 'collections') }}
        </td>
      </tr>

      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Açıklama
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.explanation }}
        </td>
      </tr>
      <tr>
        <th
          colspan="1"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          Döviz Kuru
        </th>
        <td
          colspan="8"
          style="text-align: left; border: 1px solid black; font-size: 16px"
        >
          {{ localState.state.currentCurrency }}
        </td>
      </tr>
    </table>
    {{ entryTypes }}
    <table ref="tableRoot2" style="border: 1px solid black; font-size: 16px">
      <!-- <tr></tr>
      <tr v-if="localState.details.stocks.length > 0">
        <th
          colspan="10"
          style="text-align: center; border: 1px solid black; font-size: 16px"
        >
          Stoklar
        </th>
      </tr> -->
      <template v-for="(entry, index) in entryTypes">
        {{ columns(entry.id, 'table') }}
          <tr v-for="(col, index2) in columns(entry.id, 'table')" :key="`${index - index2}`">
              <th v-if="localState.details[entry].length !== 0">
              {{ col.title }}
            </th>
          </tr>

          <tr :key="item.id" v-for="item in localState.details[entry]">
            <td
              style="border: 1px solid black; font-size: 16px; text-align: center"
              v-for="(col, index) in columns(entry.id, 'table')"
              :key="index"
            >
              <span v-if="col.key === 'primaryMaterial'">
                {{ getValueById(item[col.key], 'primaryMaterials') }}
              </span>
              <span v-else-if="col.key === 'process'">
                {{ getValueById(item[col.key], 'processes') }}
              </span>
              <span v-else-if="col.key === 'unit'">
                {{ getValueById(item[col.key], 'stockUnits') }}
              </span>
              <span v-else>
                {{ item[col.key] }}
              </span>
          </td>
          </tr>
      </template>
      <!-- <tr v-if="localState.details.stocks.length > 0">
        <template v-for="(col, index) in columns('1', 'table')">
          <th
            style="text-align: center; border: 1px solid black; font-size: 16px"
            v-if="col.key !== 'entryType' && col.key !== ''"
            :key="'stocksTitle-' + index"
          >
            {{ col.title }}
          </th>
        </template>
      </tr> -->
      <!-- <tr
        v-for="(item, index) in localState.details.stocks"
        :key="'stocks-' + index"
      >
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
          v-for="(col, index) in columns('1', 'table')"
          :key="index"
        >
          <span v-if="col.key === 'primaryMaterial'">
            {{ getValueById(item[col.key], 'primaryMaterials') }}
          </span>
          <span v-else-if="col.key === 'process'">
            {{ getValueById(item[col.key], 'processes') }}
          </span>
          <span v-else-if="col.key === 'unit'">
            {{ getValueById(item[col.key], 'stockUnits') }}
          </span>
          <span v-else>
            {{ item[col.key] }}
          </span>
        </td>
      </tr> -->

      <!-- <tr v-if="localState.details.semiProducts.length > 0">
        <th
          colspan="10"
          style="text-align: center; border: 1px solid black; font-size: 16px"
        >
          Yarı Mamüller
        </th>
      </tr> -->
      <!-- <tr v-if="localState.details.semiProducts.length > 0">
        <template v-for="(col, idx) in columns('0', 'table')">
          <th
            style="text-align: center; border: 1px solid black; font-size: 16px"
            v-if="col.key !== 'entryType' && col.key !== ''"
            :key="'semiProductsTitle-' + idx"
          >
            {{ col.title }}
          </th>
        </template>
      </tr> -->
      <!-- <tr
        v-for="(item, index) in localState.details.semiProducts"
        :key="'semiProducts-' + index"
      >
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ getValueById(item.primaryMaterial, 'primaryMaterials') }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ getValueById(item.process, 'processes') }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.entryName }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.prevalentName }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.amount }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ getValueById(item.unit, 'stockUnits') }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.cost }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.currency }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.price }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
          v-if="item.explanation"
        >
          {{ item.explanation }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
          v-else
        >
          Yok
        </td>
      </tr> -->

      <!-- <tr v-if="localState.details.expenses.length > 0">
        <th
          colspan="9"
          style="text-align: center; border: 1px solid black; font-size: 16px"
        >
          Giderler
        </th>
      </tr> -->
      <!-- <tr v-if="localState.details.expenses.length > 0">
        <template v-for="(col, i) in columns('2', 'table')">
          <th
            style="text-align: center; border: 1px solid black; font-size: 16px"
            v-if="
              col.key !== 'entryType' &&
              col.key !== '' &&
              col.key !== 'primaryMaterial'
            "
            :key="'expensesTitle-' + i"
          >
            {{ col.title }}
          </th>
        </template>
      </tr> -->
      <!-- <tr
        v-for="(item, index) in localState.details.expenses"
        :key="'expenses-' + index"
      >
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.entryName }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.prevalentName }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.amount }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.unit }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.cost }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.currency }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
        >
          {{ item.price }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
          v-if="item.explanation"
        >
          {{ item.explanation }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: center"
          v-else
        >
          Yok
        </td>
      </tr> -->
      <tr></tr>
    </table>
    <table
      ref="tableRoot3"
      id="table3"
      style="border: 1px solid black; font-size: 16px"
    >
      <tr v-if="localState.state.modelVariants !== null">
        <th colspan="1">Varyant</th>
      </tr>
      <tr v-if="localState.state.modelVariants !== null">
        <th style="border: 1px solid black; font-size: 16px; text-align: left">
          Renk/Varyant
        </th>
        <th
          style="border: 1px solid black; font-size: 16px; text-align: right"
          v-for="(size, sizeIndex3) in selectedSizes[stateKey]"
          :key="size + sizeIndex3"
        >
          {{ size }}
        </th>
      </tr>
      <tr v-for="color in selectedColors[stateKey]" :key="color">
        <td style="border: 1px solid black; font-size: 16px; text-align: left">
          {{ color }}
        </td>
        <td
          style="border: 1px solid black; font-size: 16px; text-align: right"
          v-for="(size, sizeIndex2) in selectedSizes[stateKey]"
          :key="size + sizeIndex2"
        >
          {{ localVariants[color][size] }}
        </td>
      </tr>
    </table>
    <!-- <table ref="tableRoot4" id="table4" style="border: 1px solid black; font-size: 16px;">
      <tr v-if="localOtherInformations.dimensions">
        <th  colspan="1" style="border: 1px solid black; font-size: 16px;">ÖLÇÜLER</th>
      </tr>
      <tr v-if="localOtherInformations.dimensions">
        <td style="border: 1px solid black; font-size: 16px; text-align: left;"></td>
        <th style="border: 1px solid black; font-size: 16px; text-align: center;" v-for="size in selectedSizes" :key="'C' + size">
          {{ size }}
        </th>
      </tr>
      <tr v-for="dimensionPhrase in formattedDimensionNames" :key="dimensionPhrase">
        <td style="border: 1px solid black; font-size: 16px; text-align: left; border: 1px solid black; font-size: 16px;">{{dimensionPhrase}}</td>
        <td style="border: 1px solid black; font-size: 16px; text-align: right;" v-for="(size, sizeIndex1) in selectedSizes" :key="size + sizeIndex1">
          {{ localOtherInformations.dimensions[dimensionPhrase] ? localOtherInformations.dimensions[dimensionPhrase][size] : '' }}
        </td>
      </tr>
    </table>
    <table ref="tableRoot5" id="table5" style="border: 1px solid black; font-size: 16px;">
      <tr>
        <th style="border: 1px solid black; font-size: 16px;">Mekik</th>
        <th style="border: 1px solid black; font-size: 16px;" v-for="dressPhrase in dressPhrasesNames" :key="dressPhrase">
          {{dressPhrase}}
        </th>
      </tr>
      <tr v-for="shuttle in shuttleNames" :key="shuttle">
        <th style="border: 1px solid black; font-size: 16px; text-align: center;">{{shuttle}}</th>
        <td style="border: 1px solid black; font-size: 16px; text-align: center;" v-for="dressPhrase in dressPhrasesNames" :key="dressPhrase">
          {{localOtherInformations.dressPhrases[shuttle] ? localOtherInformations.dressPhrases[shuttle][dressPhrase] : ''}}
        </td>
      </tr>
      <tr></tr>
    </table> -->
    <!-- <table ref="tableRoot6" id="table6" style="border: 1px solid black; font-size: 16px;">
      <tr>
        <th  colspan="1" style="border: 1px solid black; font-size: 16px;">GRAMAJLAR</th>
      </tr>
      <tr>
        <th  v-for="index in 9" :key="index" style="border: 1px solid black; font-size: 16px;">{{index}}.Renk</th>
      </tr>
      <tr>
        <td style="border: 1px solid black; font-size: 16px; text-align: right;" v-for="index in 9" :key="index">{{localOtherInformations.weights[index-1] ? localOtherInformations.weights[index-1] : ''}}</td>
      </tr>
      <tr>
        <th >TOPLAM</th>
        <td style="border: 1px solid black; font-size: 16px; text-align: right;">{{totalWeightComputed}}</td>
      </tr>
    </table> -->
  </div>
</template>

<script>
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { computed, ref } from '@vue/composition-api';
import imageOperations from '@/helpers/imageOperations';
import getColumns from '@/config/menu/model-planning/detailsHeader';

export default {
  props: {
    stateKey: {
      type: String,
      required: true
    },
    materialCastingListTable: {
      type: String
    }
  },
  setup(props) {
    const {
      state,
      selectedColors,
      selectedSizes,
      primaryMaterials,
      stockUnits,
      depots,
      collections,
      processes
    } = useModelPlanlamaState();
    const tableRoot = ref(null);
    const localState = computed(() => {
      return {
        state: state[props.stateKey].info,
        details: state[props.stateKey].details
      };
    });

    const columns = (type, component) => getColumns(type, component);

    const localVariants = computed(() => {
      return localState.value.state.modelVariants;
    });

    const imageProccess = computed(() => {
      return {
        getUrl: image => {
          return `${window.location.origin}/${image.image_path}${image.image_name}`;
        },
        getHeight: image => {
          const img = new Image();
          img.src = imageProccess.value.getUrl(image);
          return img.height > img.width ? '200' : '150';
        },
        getWidth: async image => {
          const img = new Image();
          img.src = imageProccess.value.getUrl(image);
          img.onload = () => {
            return img.width > img.height ? '250' : '100';
          };
        }
      };
    });

    const localPrimaryMaterials = computed(() => {
      return primaryMaterials.value;
    });
    const localStockUnits = computed(() => {
      return stockUnits.value;
    });

    const entryTypes = [
      {
        id: 0,
        type: "semiProducts"
      },
      {
        id: 1,
        type: "stocks"
      },
      {
        id: 2,
        type: "expenses"
      },
    ];

    const objectForSearch = computed(() => {
      return {
        primaryMaterials: primaryMaterials.value,
        stockUnits: stockUnits.value,
        depots: depots.value,
        collections: collections.value,
        processes: processes.value,
        statuses: {
          prototype: [
            {
              id: 0,
              name: 'Tasarım'
            },
            {
              id: 1,
              name: 'Tasarım Onay'
            },
            {
              id: 2,
              name: 'İptal'
            }
          ],
          confirmed: [
            {
              id: 0,
              name: 'Planlama'
            },
            {
              id: 1,
              name: 'Üretim Onay'
            }
          ]
        }
      };
    });

    const getValueById = (id, key) => {
      let name = '';
      if (key === 'statuses') {
        objectForSearch.value[key][props.stateKey].forEach(item => {
          if (item.id === parseInt(id)) {
            name = item.name;
          }
        });
      } else {
        objectForSearch.value[key].forEach(item => {
          if (parseInt(item.id) === parseInt(id)) {
            name = item.isim || item.name;
          }
        });
      }
      return name;
    };

    const pdfRoot = computed(() => {
      const pdfRoot = document.createElement('div');
      pdfRoot.innerHTML = document.getElementById('tableRoot').innerHTML;
      if (pdfRoot.firstChild.id === 'table0') {
        pdfRoot.removeChild(pdfRoot.firstChild);
      }
      return pdfRoot.innerHTML;
    });

    const excelRoot = computed(() => {
      const fakeRoot = document.createElement('div');
      fakeRoot.innerHTML = document.getElementById('tableRoot').innerHTML;
      const elements = fakeRoot.getElementsByClassName('deletedColumn');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      return fakeRoot.innerHTML;
    });

    // onUpdated(() => {
    //   if (tableRoot.value !== null) {
        
    //     emit('htmlTableInner:update', fakeRoot.innerHTML);
        
    //     emit('htmlTable:update', pdfRoot.innerHTML);
    //   }
    // });

    return {
      localState,
      tableRoot,
      localPrimaryMaterials,
      localStockUnits,
      getValueById,
      objectForSearch,
      imageProccess,
      selectedColors,
      selectedSizes,
      localVariants,
      imageOperations,
      columns,
      entryTypes,
      pdfRoot,
      excelRoot,
    };
  }
};
</script>

<style scoped>
img {
  width: 50%;
}
</style>
