<template>
  <div class="p-4">
    <ValidationObserver v-slot="{ validate }">
      <form @submit.prevent>
        <div class="space-y-6">
          <div class="form-elements-doubled">
            <label class="labels-of-forms">Teklif Veren</label>

            <ValidationProvider
              name="Teklif Veren"
              rules="required"
              v-slot="{ errors }"
            >
              <wo-quick-search
                v-model="formValues.teklifVeren"
                queryParameters="?tablo=kullanicilar&q="
                @selected="item => handleSelectedUser(item)"
                :error="errors"
                :itemRenderer="usersRenderer"
              />
            </ValidationProvider>

            <label class="labels-of-forms"> Karşılayan Depo </label>

            <ValidationProvider
              name="Depo"
              rules="required"
              v-slot="{ errors }"
            >
              <wo-quick-search
                v-model="formValues.karsilayanDepo"
                queryParameters="?tablo=depolar&q="
                @selected="item => handleSelectedDepot(item)"
                :error="errors"
                :itemRenderer="nameAndCodeRenderer"
                :readonly="table.tableArray.list.some(item => item.lotNo)"
              />
            </ValidationProvider>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Teklif Şekli</label>
            <ValidationProvider
              name="Teklif Şekli"
              rules="required"
              v-slot="{ errors }"
            >
              <wo-select
                v-model="formValues.teklifSekli"
                :data="teklifSekilleri"
                :error="errors"
              >
                <template v-slot:defaultValue>
                  <wo-select-item itemValue="0" text="- Seçiniz -" />
                </template>
                <template v-slot:default="{ item }">
                  <wo-select-item
                    :itemValue="item.id"
                    :text="item.text"
                    :key="`teklifSekli-${item.id}`"
                  />
                </template>
              </wo-select>
            </ValidationProvider>

            <label class="labels-of-forms"> Teklif Bilgilendirme </label>
            <vs-checkbox v-model="formValues.teklifBilgilendirme" />
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Planlanan Teslimat Tarihi</label>
            <wo-date-picker v-model="formValues.planlananTeslimatTarihi" />
            <label class="labels-of-forms"> Teklif Tarihi </label>
            <wo-date-picker
              v-model="formValues.teklifTarihi"
              @blur="handleTeklifTarihiBlur"
            />
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Geçerlilik Tarihi</label>
            <wo-date-picker v-model="formValues.gecerlilikTarihi" />
            <label class="labels-of-forms">
              <span> Stok Fazlası İçin </span>
              <vx-tooltip
                text="Birim fiyat maliyetten küçük olmasını istiyorsanız bu alanı seçiniz."
              >
                <span class="underline"> ? </span>
              </vx-tooltip>
            </label>
            <vs-checkbox v-model="formValues.stokFazlasi" />
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Seri-Sıra</label>
            <WOSerialSequence
              module="teklif"
              :serialValue.sync="formValues.seri"
              :sequenceValue.sync="formValues.sira"
            />
            <label class="labels-of-forms"> Konu </label>
            <ValidationProvider
              name="Konu"
              rules="required"
              v-slot="{ errors }"
            >
              <wo-textarea
                v-model="formValues.konu"
                elementClasses="w-58"
                :error="errors"
              />
            </ValidationProvider>
          </div>

          <div class="form-elements-doubled">
            <!-- <WOMarqueeAlternative :data="teklifGonderilecekFirmalarData">
              <template v-slot:default="{ item }">
                <p>
                  {{ item.teklif_yetkili }}
                </p>
              </template>
            </WOMarqueeAlternative> -->
            <label class="labels-of-forms">
              Ödeme Türü <span class="text-danger">*</span>
            </label>
            <wo-select v-model="formValues.odemeTuru" :data="payTypeData">
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="`pay-type-${item.id}`"
                />
              </template>
            </wo-select>
            <div class="form-elements-doubled">
              <label class="labels-of-forms ml-auto">
                <span
                  class="hover:text-primary cursor-pointer underline"
                  @click="openMiniModulePayPlan"
                >
                  Ödeme Planı
                </span>
                <span class="text-danger"> * </span>
              </label>
              <wo-select v-model="formValues.odemePlani" :data="payPlanData">
                <template v-slot:defaultValue>
                  <wo-select-item itemValue="0" text=" - " />
                </template>
                <template v-slot:default="{ item }">
                  <wo-select-item
                    :itemValue="item.id"
                    :text="item.isim"
                    :key="`pay-plan-${item.id}`"
                  />
                </template>
              </wo-select>
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">
              Ödeme Türü <span class="text-danger">*</span>
            </label>
            <wo-select v-model="formValues.odemeTuru" :data="payTypeData">
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="`pay-type-${item.id}`"
                />
              </template>
            </wo-select>
            <label class="labels-of-forms"> Kullanılacak Kurlar </label>
            <multiselect
              v-model="usedExchangeRates"
              :options="usableExchangeRates"
              :multiple="true"
              :close-on-select="false"
              :preserve-search="true"
              placeholder="Kurlar"
              label="kod"
              track-by="kod"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length && !isOpen"
                  >{{ values.map(a => a.kod).join(', ') }}</span
                ></template
              >
            </multiselect>
            <!-- <label
              class="labels-of-forms underlined-hovered-link cursor-pointer"
              @click="teklifGonderilecekFirmalarModal = true"
            >
              Teklif Gönderilecek Firmalar ?
            </label> -->
            <wo-button
              text="Teklif Gönderilecek Firmalar"
              @click="teklifGonderilecekFirmalarModal = true"
              class="labels-of-forms"
            />
          </div>

          <div class="space-x-4">
            <wo-button text="Sepetler" @click="basketsModal = true" />
            <OfferExchangeRatesModal />
          </div>

          <div class="flex gap-2">
            <!-- {{ addedBasketsOutput }} -->
            <div
              class="flex items-center"
              v-for="basket in Object.keys(addedBasketsData)"
              :key="basket"
            >
              <span>{{ addedBasketsData[basket].name }} </span>
              <i
                class="fa fa-remove ml-1 mr-2 mb-1 cursor-pointer"
                @click="() => removeTableItem(basket)"
              ></i>
            </div>
          </div>

          <TabloTekliflerForm
            :basketID="basketID"
            :prevalentBasketTitle="prevalentBasketTitle"
            :expenseBasket="expenseBasket"
            :depo="formValues.karsilayanDepoId"
            :teklifTarihi="formValues.teklifTarihi"
            :gecerlilikTarihi="formValues.gecerlilikTarihi"
            :aciklama="formValues.aciklama"
            :editTableDetails="editTableDetails"
            ref="table"
            @removeBasketFromSelectedBaskets="removeBasketFromSelectedBaskets"
            @removeAllBasketsFromSelectedBaskets="
              removeAllBasketsFromSelectedBaskets
            "
          />

          <div>
            <label>Açıklama</label>
            <vs-textarea v-model="formValues.aciklama" />
          </div>

          <div v-if="offerID">
            <OfferLogs />
          </div>

          <div
            class="form-elements-doubled"
            v-if="authorizationControl('teklif_onay')"
          >
            <label class="labels-of-forms"> Teklif Aşaması </label>
            <wo-select v-model="teklifAsamasi" :data="teklifAsamalari">
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.text"
                  :key="`asama-${item.id}`"
                />
              </template>
            </wo-select>
            <label class="labels-of-forms"> Okuyabilenler </label>
            <OfferReaders
              :addedReadersArray="addedReadersArray"
              @addNewReader="addNewReader"
              @removeReader="removeReader"
            />
          </div>

          <OfferEditButtons
            v-if="offerID"
            :orderID="orderID"
            :branchID="branchDetails.id"
          />

          <div class="flex flex-col md:flex-row responsive-margin-md">
            <wo-button
              text="Kaydet (Alt + S)"
              data-id="kaydet"
              v-if="authorizationControl('teklif_ekle')"
              @click="
                () => {
                  validate().then(handleFormSubmit);
                }
              "
            />
            <wo-button
              v-if="authorizationControl('teklif_ekle')"
              text="Yeni Teklif"
              color="success"
              :href="`${$baseURL}/teklifler/yeni`"
            />
            <wo-button
              v-if="authorizationControl('teklif_liste')"
              text="Listeye Dön"
              data-id="geri"
              :href="`${$baseURL}/teklifler`"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>

    <!-- TEKLIF GONDERILECEK FIRMALAR MODAL -->
    <OfferCompaniesSendOfferModal
      :active.sync="teklifGonderilecekFirmalarModal"
    />

    <!-- SEPETLER MODAL -->
    <TeklifBaskets
      :active.sync="basketsModal"
      @selectedBasketID="handleSelectedBasket"
      @selectedPrevalentBasket="handleSelectedBasket"
      @selectedExpenseBasket="handleSelectedExpenseBasket"
    />
  </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api';

import moment from 'moment';
import isEmpty from 'lodash.isempty';

import { authorizationControl } from '@/consumables/useAuthorizationControl';

import { numToTrStr } from '@/helpers/NumberController';
import { getPayType, getPayPlan } from '@/helpers/ApiController.js';
import {
  errorConfirmDialog,
  errorInformDialog,
  errorNotify,
  errorDialog,
  errorConfirmReloadDialog
} from '@/helpers/errorDialogs';

import {
  getOfferDetails,
  saveOffer,
  getCostAnalysisDetails
} from './helpers/ApiFactory.js';
import { useCurrency } from './consumables/useCurrency.js';
import useCompaniesSendOffer from './consumables/useCompaniesSendOffer.js';
import { setLogValues } from './consumables/useLogs.js';

// import WOMarqueeAlternative from '@/components/WO/WOMarqueeAlternative.vue';
import WOSerialSequence from '@/components/WO/WOSerialSequence';

import TabloTekliflerForm from './TabloTekliflerForm.vue';
import TeklifBaskets from './components/TeklifBaskets';
import OfferEditButtons from './components/OfferEditButtons.vue';
import OfferExchangeRatesModal from './components/OfferExchangeRatesModal.vue';
import OfferLogs from './components/OfferLogs.vue';
import OfferReaders from './components/OfferReaders.vue';
import OfferCompaniesSendOfferModal from './components/OfferCompaniesSendOfferModal.vue';

import { usersRenderer } from '@/helpers/quickSearchListRenderers/qsUsers';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';

export default {
  components: {
    // WOMarqueeAlternative,
    WOSerialSequence,

    OfferCompaniesSendOfferModal,
    TabloTekliflerForm,
    TeklifBaskets,
    OfferEditButtons,
    OfferExchangeRatesModal,
    OfferLogs,
    OfferReaders
  },
  setup(props, { root }) {
    const offerID = computed(() => {
      return root.$route.params.id;
    });

    // #region Form Values and Methods
    const teklifSekilleri = [
      { id: 1, text: 'E-mail' },
      { id: 2, text: 'Sms' }
    ];

    const branchDetails = reactive({
      id: '',
      name: '',
      previousDayCurrency: ''
    });

    const formValues = reactive({
      teklifVeren: '',
      teklifVerenName: '',
      teklifVerenID: '',
      teklifVerenUser: '',

      karsilayanDepo: '',
      karsilayanDepoId: '',
      karsiyalanDepoKod: '',

      teklifSekli: '',
      teklifBilgilendirme: false,
      planlananTeslimatTarihi: '',
      teklifTarihi: moment().format('DD.MM.YYYY'),
      gecerlilikTarihi: moment().add(1, 'weeks').format('DD.MM.YYYY'),
      stokFazlasi: false,
      seri: '',
      sira: '',
      konu: '',
      odemeTuru: '',
      odemePlani: '0',
      aciklama: ''
    });

    const handleSelectedUser = ({ isSelected, id, isim, user }) => {
      if (isSelected) {
        formValues.teklifVeren = isim;
        formValues.teklifVerenName = isim;
        formValues.teklifVerenID = id;
        formValues.teklifVerenUser = user;
      } else if (!isSelected && formValues.teklifVerenID) {
        formValues.teklifVeren = '';
        formValues.teklifVerenName = '';
        formValues.teklifVerenID = '';
        formValues.teklifVerenUser = '';
      }
    };

    const handleSelectedDepot = ({
      isSelected,
      id,
      isim,
      kod,
      sube_id,
      sube_isim,
      kur_onceki_gun
    }) => {
      if (isSelected) {
        formValues.karsilayanDepo = isim;
        formValues.karsilayanDepoId = id;
        formValues.karsiyalanDepoKod = kod;
        branchDetails.id = sube_id;
        branchDetails.name = sube_isim;
        branchDetails.previousDayCurrency = kur_onceki_gun;
      } else if (!isSelected && formValues.karsilayanDepoId) {
        formValues.karsilayanDepo = '';
        formValues.karsilayanDepoId = 0;
        formValues.karsiyalanDepoKod = '';
        branchDetails.id = '';
        branchDetails.name = '';
        branchDetails.previousDayCurrency = '';
      }
    };

    const teklifAsamalari = [
      authorizationControl('teklif_onay') && {
        id: 0,
        text: 'Teklif Hazırlanıyor'
      },
      { id: 1, text: 'Teklif Gönderildi - Onay Bekliyor' },
      authorizationControl('teklif_revizyon') && {
        id: 2,
        text: 'Teklif Revize Edildi - Onay Bekliyor'
      },
      authorizationControl('teklif_onay') && { id: 3, text: 'Teklif Onaylı' }
    ].filter(Boolean);

    const teklifAsamasi = ref(teklifAsamalari[0].id);

    const payTypeData = ref([]);

    const getPayTypeFromAPI = async () => {
      try {
        payTypeData.value = await getPayType();
        root.$set(formValues, 'odemeTuru', payTypeData.value[0].id);
      } catch (error) {
        console.log(error);
      }
    };
    getPayTypeFromAPI();

    const table = ref(null);

    const returnForm = {
      teklifSekilleri,
      branchDetails,
      formValues,
      handleSelectedUser,
      handleSelectedDepot,
      teklifAsamalari,
      teklifAsamasi,
      payTypeData,
      table
    };
    // #endregion

    // #region Kurlar Modal
    const {
      usedExchangeRates,
      usableExchangeRates,
      unchangedExchangeRates,
      mainCurrency,
      getExchangeRatesFromAPI
    } = useCurrency();

    let oldOfferDate = formValues.teklifTarihi;

    const exchangeRatePromise = getExchangeRatesFromAPI(
      formValues.teklifTarihi
    );

    const handleTeklifTarihiBlur = () => {
      if (formValues.teklifTarihi && formValues.teklifTarihi !== oldOfferDate) {
        // yeni tarih eskiyle ayni degilse
        oldOfferDate = formValues.teklifTarihi;
        if (
          JSON.stringify(unchangedExchangeRates) ===
          JSON.stringify(usableExchangeRates.value)
        ) {
          // kur degerleri apiden gelen degerlerle ayniysa
          getExchangeRatesFromAPI(formValues.teklifTarihi);
        }
      }
    };

    const returnExhangeRates = {
      usedExchangeRates,
      usableExchangeRates,
      mainCurrency,
      handleTeklifTarihiBlur
    };

    //#endregion

    // #region Teklif Gonderilecek Firmalar
    const teklifGonderilecekFirmalarModal = ref(false);
    const { teklifGonderilecekFirmalarData } = useCompaniesSendOffer();

    const returnOfferTarget = {
      teklifGonderilecekFirmalarModal,
      teklifGonderilecekFirmalarData
    };

    // #endregion

    // #region Odeme Plani
    const payPlanData = ref([]);

    const getPayPlansFromAPI = async () => {
      try {
        payPlanData.value = await getPayPlan();
      } catch (error) {
        console.log(error);
      }
    };

    const openMiniModulePayPlan = () => {
      window.MiniModulPen(700, 300, 'Odeme Planları', 'cari_odeme_planlari');
    };

    getPayPlansFromAPI();

    const returnPayPlan = {
      payPlanData,
      openMiniModulePayPlan
    };
    // #endregion

    // #region Sepetler Modalx
    const basketsModal = ref(false);

    const basketID = ref(0);
    const prevalentBasketTitle = ref('');
    const expenseBasket = ref({});

    const addedBasketsData = ref({});

    const addedBasketsOutput = computed(() => {
      if (isEmpty(addedBasketsData.value)) {
        return '';
      }
      let basketString = '';
      for (const key in addedBasketsData.value) {
        const obj = addedBasketsData.value[key];
        basketString = `${basketString}, ${obj.name} ${
          obj.title ? ` - ${obj.title}` : ''
        }`;
      }
      return `Eklenen Sepetler: ${basketString.replace(/(^,)/g, '')}`;
    });

    const handleSelectedBasket = ({ id, name, title }) => {
      if (id && !addedBasketsData.value[id]) {
        basketID.value = id;
        root.$set(addedBasketsData.value, id, {});
        addedBasketsData.value[id].name = name;
        if (title) {
          // eger cari sepet girilmis ise
          prevalentBasketTitle.value = title;
          addedBasketsData.value[id].title = title;
        } else if (prevalentBasketTitle.value) {
          // eger cari sepet girilmemis fakat onceden cari sepet varsa
          prevalentBasketTitle.value = '-';
        }
      } else {
        errorNotify('Seçilen sepet zaten eklenmiş.');
      }
    };

    const removeBasketFromSelectedBaskets = toBeRemovedBasketID => {
      root.$delete(addedBasketsData.value, toBeRemovedBasketID);
      basketID.value = 0;
    };

    const removeTableItem = basketId => {
      const acceptRemoveItem = () => {
        const tableArray = table.value.tableArray.list;
        const items = tableArray.filter(
          item => item.basketID === parseInt(basketId) && item.comingFromBasket
        );
        items.forEach(item => {
          const index = tableArray.indexOf(item);
          tableArray.splice(index, 1);
        });
        table.value.tableArray.list = tableArray;
        removeBasketFromSelectedBaskets(basketId);
      };
      errorConfirmDialog(
        'Sepete bağlı satırlar silinecektir. Onaylıyor musunuz?',
        acceptRemoveItem
      );
    };

    const removeAllBasketsFromSelectedBaskets = () => {
      addedBasketsData.value = {};
      basketID.value = 0;
    };

    const handleSelectedExpenseBasket = obj => {
      expenseBasket.value = obj;
    };

    const returnBaskets = {
      basketsModal,
      basketID,
      addedBasketsData,
      prevalentBasketTitle,
      expenseBasket,
      addedBasketsOutput,
      handleSelectedBasket,
      removeBasketFromSelectedBaskets,
      removeAllBasketsFromSelectedBaskets,
      handleSelectedExpenseBasket
    };
    // #endregion

    // #region Readers
    const addedReadersArray = ref([]);

    const addNewReader = newReader => {
      addedReadersArray.value.push(newReader);
    };

    const removeReader = reader => {
      const index = addedReadersArray.value.indexOf(reader);
      addedReadersArray.value.splice(index, 1);
    };

    const returnReaders = {
      addedReadersArray,
      addNewReader
    };
    // #endregion

    // #region FORM CRUD
    const checkTableStocks = tableArray => {
      const stokIDEmptyLines = [];
      const tekMiktarEmptyLines = [];
      const tekBirimFiyatEmptyLines = [];
      for (let idx = 0; idx < tableArray; idx++) {
        const row = tableArray[idx];
        if (!row.stokID) {
          stokIDEmptyLines.push(idx);
        }
        if (!row.tekMiktari || row.tekMiktar === '0,00') {
          tekMiktarEmptyLines.push(idx);
        }
        if (!row.tekBirimFiyat || row.tekBirimFiyat === '0,00') {
          tekBirimFiyatEmptyLines.push(idx);
        }
      }
      const stokIDErrorMessage = `${
        stokIDEmptyLines.length
          ? `Stok eklenmemiş olan satırları düzeltiniz (Satırlar: ${stokIDEmptyLines.join(
              ', '
            )}). `
          : ''
      }`;

      let tekMiktarErrorMessage = '';
      let tekBirimFiyatErrorMessage = '';

      if (parseInt(teklifAsamasi.value) !== 0) {
        tekMiktarErrorMessage = `${
          tekMiktarEmptyLines.length
            ? `Teklif miktarı boş olan satırları düzeltiniz (Satırlar: ${tekMiktarEmptyLines.join(
                ', '
              )}). `
            : ''
        }`;
        tekBirimFiyatErrorMessage = `${
          tekBirimFiyatEmptyLines.length
            ? `Birim miktarı hatalı olan satırları düzeltiniz. (Satırlar: ${tekBirimFiyatEmptyLines.join(
                ', '
              )}).`
            : ''
        }`;
      }

      return `${stokIDErrorMessage} ${tekMiktarErrorMessage} ${tekBirimFiyatErrorMessage}`.trim();
    };

    const formSubmission = tableArray => {
      const offerProcessibleValues = {
        expenseBasket: expenseBasket.value,
        formValues,
        teklifGonderilecekFirmalarData: teklifGonderilecekFirmalarData.value,
        addedReadersArray: addedReadersArray.value,
        tableArray,
        showExtraIskonto: table.value.showExtraIskonto,
        teklifAsamasi: teklifAsamasi.value,
        usedExchangeRates: usedExchangeRates.value,
        offerID: offerID.value
      };
      saveOffer(offerProcessibleValues)
        .then(() => (window.location = `${root.$baseURL}/teklifler`))
        .catch(({ response }) => {
          console.log(response);
          if (response.data && response.data.message) {
            errorDialog(response.data.message);
          } else {
            errorDialog(
              'Teklifi kaydederken bir hata oluştu. Lütfen tekrar deneyiniz.'
            );
          }
        });
    };

    const handleFormSubmit = async () => {
      const tableArray = table.value.tableArray.list;
      console.log(tableArray);

      // if (teklifGonderilecekFirmalarData.value.length === 0) {
      //   errorInformDialog(
      //     'Teklifin gönderileceği konsept/konseptler veya firma/firmaları seçiniz.'
      //   );
      //   return;
      // }

      if (tableArray.length === 0) {
        errorInformDialog('Lütfen ürün ekleyiniz.');
        return;
      }

      const endMessage = checkTableStocks(tableArray);
      if (endMessage) {
        errorDialog(endMessage);
        return;
      }

      if (parseInt(formValues.odemePlani) === 0) {
        errorConfirmDialog(
          'Ödeme planı seçmediniz kaydı tamamlamak istiyor musunuz?',
          () => formSubmission(tableArray)
        );
      } else {
        formSubmission(tableArray);
      }
    };

    const editExchangeRates = ref({});

    const orderID = ref(0);

    const updateExchangeRatesOnEdit = () => {
      if (
        !isEmpty(editExchangeRates.value) &&
        usableExchangeRates.value.length
      ) {
        for (const key in editExchangeRates.value) {
          const findedExchangeRate = usableExchangeRates.value.find(item => {
            return item.kod === key;
          });
          findedExchangeRate.kur = numToTrStr(editExchangeRates.value[key]);
          const findedUsedExchangeRate = usedExchangeRates.value.find(item => {
            return item.kod === key;
          });
          if (isEmpty(findedUsedExchangeRate)) {
            root.$set(
              usedExchangeRates.value,
              usedExchangeRates.value.length,
              findedExchangeRate
            );
          } else {
            findedUsedExchangeRate.kur = editExchangeRates.value[key];
          }
        }
      }
    };

    const editTableDetails = ref(null);

    const fillDetails = () => {
      getOfferDetails(offerID.value)
        .then(data => {
          console.log(data);
          branchDetails.value = { ...data.branchDetails };
          expenseBasket.value = { ...data.expenseBasket };

          Object.assign(formValues, data.formValues);

          editExchangeRates.value = { ...data.editExchangeRates };
          exchangeRatePromise.then(() => {
            updateExchangeRatesOnEdit();
          });
          addedReadersArray.value = [...data.addedReadersArray];

          teklifGonderilecekFirmalarData.value = [
            ...data.teklifGonderilecekFirmalarData
          ];
          editTableDetails.value = [...data.editTableDetails];

          orderID.value = data.orderID;
          setLogValues(data.logs);
          teklifAsamasi.value = data.teklifAsamasi;
        })
        .catch(error => {
          errorConfirmReloadDialog(error.data.message);
        });
    };

    if (offerID.value) {
      fillDetails();
    }

    const costAnalysisID = root.$route.params.cost_analysis_id;

    if (costAnalysisID) {
      getCostAnalysisDetails(costAnalysisID).then(data => {
        formValues.karsilayanDepo = data.offer.depo_isim;
        formValues.karsilayanDepoKod = data.offer.depo_kod;
        formValues.karsilayanDepoId = data.offer.depo;
        editTableDetails.value = [...data.tableData];
      });
    }

    const returnFormCRUD = {
      orderID,
      handleFormSubmit,
      editTableDetails
    };

    // #endregion

    return {
      offerID,

      ...returnForm,

      ...returnReaders,

      ...returnExhangeRates,

      ...returnOfferTarget,

      ...returnPayPlan,

      ...returnBaskets,

      ...returnFormCRUD,

      authorizationControl,
      usersRenderer,
      nameAndCodeRenderer,
      removeTableItem,
      removeReader,
    };
  }
};
</script>

<style scoped>
.labels-of-forms {
  @apply w-52;
}

.form-elements-doubled {
  @apply flex flex-col lg:flex-row space-y-4 lg:space-y-2 space-x-0 lg:space-x-4 items-start lg:items-center;
}
</style>
