<template>
    <wo-modal
     class="wide-dialog-90vw one-step-behind-dialog mt-5"
     :active="weavingModal"
     title="DOKUMA"
     @active="$emit('weavingModal', $event)"
     :accept="saveWeavingModal "
     :closeAct="closeWithoutSave"
    >
      <div class="my-5 d-flex text-center container">
          <h2 style="font-weight: bold; color:red;">DOKUMA (ÖRME) ÜRETİM FORMU</h2>
          <table class="info-table">
              <tr v-for="(title, index) in infoTitles" :key="index">
                  <th>{{ title }}</th>
                  <td><input type="text" style="border-radius: 3px; border-top: none; border-left: none; border-right: none;" v-model="weavingInfo[title]"></td>
              </tr>
          </table>
      </div>
      <wo-table style="margin: 60px 20px 20px 20px;" id="custom-table">
        <wo-tr v-for="field in weightFields" :key="field.title">
            <wo-th>
                {{field.title}}
            </wo-th>
            <wo-td v-for="index in 7" :key="index">
                <input :type="field.inputType" style="height:25px; width: 100px; border-radius: 3px;" class="text-center" v-model="localData[field.data][index]">
            </wo-td>
            <wo-td v-if="field.title === 'GRAMAJ'">
                <span>TOPLAM</span>
                {{ totalWeight }}
            </wo-td>
        </wo-tr>
        <!-- <caption style="font-weight: bold; font-size: large; margin-left: 8px; margin-bottom: 5px;">GRAMAJLAR</caption> -->
        <!-- <template #thead>
            <wo-th v-for="index in 9" :key="index">{{ index }}.Renk</wo-th>
        </template>
        <template>
            <wo-tr>
                <wo-td v-for="index in 9" :key="index" class="text-center">Gramaj</wo-td>
            </wo-tr>
            <wo-tr>
                <wo-td v-for="index in 9" :key="index" class="text-center">
                    <input type="number" style="height:25px; width: 100px; border-radius: 3px;" class="text-center" v-model="weights[index-1]" v-on:input="computeTotalWeight()">
                </wo-td>
            </wo-tr>
            <wo-tr>
                <wo-td>
                    TOPLAM {{totalWeight}}
                </wo-td>
            </wo-tr>
        </template> -->
      </wo-table>
      <wo-table>
          <template #thead>
              <wo-th>Mekik</wo-th>
              <wo-th v-for="(col, index) in localDressPhrasesNames" :key="`${col}-${index}`">
                  {{ col }}
              </wo-th>  
              <wo-th style="display: flex"><input type="text" style="border-radius: 3px; object-fit: fill;" v-model="addedDressPhrasesName">
                    <feather-icon
                        icon="PlusIcon"
                        style="cursor: pointer;"
                        class="h-5 w-5 align-middle plusIcon text-success ml-2"
                        @click="addDressPhrase"
                    /> 
            </wo-th>
          </template>
          <template>
              <wo-tr v-for="shuttle in shuttleNames" :key="shuttle" class="text-center">
                  <wo-th style="white-space: nowrap;">{{shuttle}}</wo-th>
                  <wo-td v-for="(col, index) in localDressPhrasesNames" :key="`${col}-${index}`">
                      <input type="text" style="height:35px; width: 195px; border-radius: 3px;" class="text-center" v-model="colorWeaves[shuttle][col]">
                  </wo-td>
              </wo-tr>
          </template>
      </wo-table>
      <wo-table>
        <caption style="font-weight: bold; font-size: large; margin-left: 8px; margin-bottom: 5px;">HAM BOY ÖLÇÜLER</caption>
        <wo-tr>
            <wo-th v-for="title in pureDimensionTitles" :key="title" class="text-center"> {{title}} </wo-th>
        </wo-tr>
        <wo-tr>
            <wo-td v-for="title in pureDimensionTitles" :key="title" class="text-center">
                <input type="text" style="border-radius: 3px; height: 35px;" v-model="pureDimensions[title]">
            </wo-td>
        </wo-tr>
      </wo-table>
        <i class="fa fa-print fa-2x ml-5" aria-hidden="true" @click="exportPDF"></i>
    </wo-modal>
</template>

<script>
import { computed, onUpdated, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { addInformationPhrases } from '@/helpers/ApiController';
import exportTable from '@/helpers/exportTable';
export default {
  name: "WeavingModal",
  props: {
    weavingModal: Boolean,
    dressPhrasesNames: Array,
    shuttleNames: Array,
    stateKey: String,
  },
  setup(props, context) {

    const { state, newDressPhrases, machines} = useModelPlanlamaState();

    const infoTitles = [
            "ÜRETİCİ",
            "MODEL KODU",
            "DESEN NO",
            "MAKİNE GG",
            "DOKUMA DAKİKASI",
            "HIZ"
    ];

    const localData = computed({
        get() {
            return state[props.stateKey].info.otherInformations;
        },
        set(val) {
            state[props.stateKey].info.otherInformations = val;
        },
    });

    const weavingInfo = computed(() => state[props.stateKey].info.otherInformations.weavingInfo || {});

    const colorWeaves = computed(() => {
            const colorWeaves = {};
            props.shuttleNames.forEach(shuttle => {
                colorWeaves[shuttle] = {};
                props.dressPhrasesNames.forEach(phrase => {
                    colorWeaves[shuttle][phrase] = localData.value.colorWeaves[shuttle] === undefined ? '' : localData.value.colorWeaves[shuttle][phrase];
                });
            });
            return colorWeaves;
      });


    const weights = computed(() => {
            const weights = ['', '', '', '', '', '', ''];
            if (localData.value.weights !== undefined) {
                localData.value.weights.forEach((weight, index) => {
                    weights[index] = weight;
                });
            }
            return weights;
    });

    const weightFields = [
        {
            title: "İPLİK CİNSİ",
            inputType: "text",
            data: "yarnType"
        },
        {
            title: "GRAMAJ",
            inputType: "number",
            data: "weights"
        },
        {
            title: "MEKİKLER",
            inputType: "text",
            data: "shuttles"
        }
    ];

    const pureDimensionTitles = [
        "ÖN",
        "ARKA",
        "KOL",
        "YAKA",
        "SOL ÖN",
    ];
    
    const pureDimensions = computed(() => localData.value.pureDimensions || {});

    const localDressPhrasesNames = ref(props.dressPhrasesNames);

    const addedDressPhrasesName = ref('');

    const addDressPhrase = () => {
        if (addedDressPhrasesName.value !== '') {
            state[props.stateKey].info.otherInformations.colorWeaves = colorWeaves.value;
            localDressPhrasesNames.value.push(addedDressPhrasesName.value);
            newDressPhrases.value.push(addedDressPhrasesName.value);
            addedDressPhrasesName.value = '';
        }
    };

    const saveWeavingModal = () => {
      state[props.stateKey].info.otherInformations.colorWeaves = colorWeaves.value;
      state[props.stateKey].info.otherInformations.weights = weights.value;
      state[props.stateKey].info.otherInformations.pureDimensions = pureDimensions.value;
      state[props.stateKey].info.otherInformations.weavingInfo = weavingInfo.value;
      if (newDressPhrases.value.length > 0) {
        addInformationPhrases(newDressPhrases.value, 1);
      }
      context.emit('weavingModal', false);
    };

    const closeWithoutSave = () => {
      context.emit('weavingModal', false);
    };

    const totalWeight = ref(0);

    const computeTotalWeight = () => {
            let total = 0;
            weights.value.forEach(weight => {
                if (weight !== '') {
                    total += parseInt(weight);
                }
            });
            totalWeight.value = total;
    };

    onUpdated(() => {
        localDressPhrasesNames.value = props.dressPhrasesNames;
        computeTotalWeight();
        // if (weavingInfo.value['ÜRETİCİ'] === "" && weavingInfo.value['MODEL KODU'] === "") {
            weavingInfo.value['ÜRETİCİ'] = state[props.stateKey].info.mainContractorName;
            weavingInfo.value['MODEL KODU'] = state[props.stateKey].info.modelCode;
            const matchedMachine = machines.value.find(machine => machine.id === parseInt(state[props.stateKey].info.machineID));
            weavingInfo.value['MAKİNE GG'] = matchedMachine ? matchedMachine.isim : "";
        // }
    });

    const exportPDF = () => {
        const image = state[props.stateKey].info.imageUrls[0];

        const colorWeavesObject = {
            data: colorWeaves.value,
            title: ''
        };

        const weightKeys = weightFields.map(field => field.data);
        let content = "<table style='border: 1px; margin-top: 25px; width: 100%; border-collapse: collapse;'>";
        weightKeys.forEach(key => {
            content += `<tr style='border: 1px solid black;'><th style='border: 1px solid black;'>${weightFields.find(item => item.data === key).title}</th>`;
            localData.value[key].forEach(item => {
                content += `<td style='border: 1px solid black;'>${item}</td>`;
            });
            content += "</tr>";
        });
        content += "</table>";

        const customTable = {
            order: 0,
            table: content
        };

        const pureDimensionsObject = {
            data: pureDimensions.value,
            title: 'HAM BOY ÖLÇÜLER',
        };

        const weavingInfoObject = {
            data: weavingInfo.value,
            title: ""
        };
        exportTable([colorWeavesObject, pureDimensionsObject], weavingInfoObject, image, ['DOKUMA (ÖRME) ÜRETİM FORMU'], customTable);
    };

    return {
      saveWeavingModal,
      closeWithoutSave,
      colorWeaves,
      weights,
      computeTotalWeight,
      totalWeight,
      addDressPhrase,
      addedDressPhrasesName,
      localDressPhrasesNames,
      exportPDF,
      weavingInfo,
      infoTitles,
      weightFields,
      localData,
      pureDimensionTitles,
      pureDimensions
    };
  },
};

</script>