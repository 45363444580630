<template>
  <div class="space-y-4">
    <CariFormTabContactCollectionOfficerInfo />

    <CariFormSectionDivider />

    <CariFormTabContactInfo />

    <section v-if="this.$route.params.id">
      <CariFormSectionHeader>
        Adres Bilgileri
      </CariFormSectionHeader>

      <MMAddress source="1" />
    </section>
  </div>
</template>

<script>
import CariFormTabContactCollectionOfficerInfo from './CariFormTabContactCollectionOfficerInfo.vue';
import CariFormTabContactInfo from './CariFormTabContactInfo.vue';
import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import CariFormSectionHeader from '../common/CariFormSectionDivider.vue';
import MMAddress from '@/components/MiniModules/Addresses/MMAddresses.vue';

export default {
  components: {
    CariFormTabContactCollectionOfficerInfo,
    CariFormTabContactInfo,
    CariFormSectionDivider,
    CariFormSectionHeader,
    MMAddress
  }
};
</script>

<style lang="scss" scoped></style>
