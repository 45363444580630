import PrintD from 'printd';

export default function(id, companyName, values, depots, tableArray) {
  const p = new PrintD();

  const wrapperDiv = document.createElement('div');

  wrapperDiv.innerHTML = `
  <table>
    <thead>
    <th>
      Maliyet Analiz ID: ${id}
    </th>
    </thead>
    <tr>
      <td>Şirket:</td>
      <td>${companyName}</td>
    </tr>
    <tr>
      <td>Şube:</td>
      <td>${values.subeValue}</td>
      <td>Seri-Sira:</td>
      <td style="text-transform: uppercase;">${values.seriValue} - ${
    values.siraValue
  }</td>
    </tr>
    <tr>
      <td>Tarih:</td>
      <td>${values.tarihValue}</td>
      <td>Depo:</td>
      <td>${
        values.depoValue === 'tumu'
          ? 'Tümü'
          : depots.filter(obj => obj.id === values.depoValue)[0].isim
      }</td>
    </tr>
    <tr>
      <td>İsim:</td>
      <td>${values.isimValue}</td>
    </tr>
  </table>
  `;

  const tableHeaders = [
    { key: 'sira', text: 'Sıra' },
    { key: 'urun', text: 'Ürün' },
    { key: 'kaynakUrun', text: 'Kaynak Ürün' },
    { key: 'birim', text: 'Birim' },
    { key: 'kaynakUrunMaliyeti', text: 'Kaynak Ürün Maliyeti' },
    { key: 'verimlilikOrani', text: 'V.O (% 10.5)' },
    { key: 'maliyetDusurucuOran', text: 'Maliyet Düşürücü Oran (% 5.2)' },
    { key: 'sarfiyat', text: 'Sarfiyat / Diğer Ml.' },
    { key: 'iscilikPayi', text: 'İşçilik Payı' },
    { key: 'nihaiMaliyet', text: 'N.Ü.M' },
    { key: 'minimumMarjin', text: 'Min Marj' },
    { key: 'maksimumMarjin', text: 'Maks Marj' }
  ];

  const printTable = document.createElement('table');
  const tHead = document.createElement('thead');

  for (const obj of tableHeaders) {
    const col = document.createElement('th');
    col.innerHTML = obj.text;
    tHead.appendChild(col);
  }
  printTable.appendChild(tHead);

  for (const i in tableArray) {
    const row = printTable.insertRow(-1);
    for (const obj of tableHeaders) {
      const cell = row.insertCell(-1);
      if (obj.key === 'sira') {
        cell.innerHTML = `${parseInt(i) + 1}`;
      } else {
        cell.innerHTML = tableArray[i][obj.key];
      }
    }
  }

  const aciklamaTable = document.createElement('table');
  aciklamaTable.innerHTML = `
    <tr>
      <td>Açıklama: </td>
      <td>${values.aciklamaValue}</td>
    </tr>
  `;

  wrapperDiv.appendChild(printTable);
  wrapperDiv.appendChild(aciklamaTable);
  p.print(wrapperDiv);
}
