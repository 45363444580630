<template>
  <div class="space-y-2 model-design-calculations">
    <wo-table>
      <caption class="text-lg font-medium">
        Toplamlar
      </caption>
      <wo-tr v-for="row in rows" :key="row.name">
        <wo-td
          class="text-left"
          v-if="
            row.name !== 'Brüt' &&
            row.name !== 'Net' &&
            row.name !== 'Depodan Verilecek İpler'
          "
          >{{ row.name }}</wo-td
        >
        <wo-td
          v-else-if="
            row.name === 'Brüt' ||
            row.name === 'Net' ||
            row.name === 'Depodan Verilecek İpler'
          "
          style="font-weight: bold"
          >{{ row.name }}</wo-td
        >
        <wo-td
          ><wo-int-input
            class="text-right-input"
            v-model="localState[row.name]"
            :readonly="row.isReadonly"
        /></wo-td>
      </wo-tr>
    </wo-table>
  </div>
</template>
<script>
import { computed, onUpdated, watch } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
export default {
  setup() {
    const { state } = useModelPlanlamaState();

    const localState = computed(() => {
      return state['design'].calculations;
    });

    const rows = [
      { name: 'Dikim İpi', isReadonly: false },
      { name: 'Depodan Verilecek Ara İpi', isReadonly: false },
      { name: 'Makas Fire', isReadonly: false },
      { name: 'Depodan Verilecek İpler', isReadonly: true },
      { name: 'Brüt', isReadonly: true },
      { name: 'Net', isReadonly: true }
    ];

    const calculateTotals = () => {
      localState.value['Depodan Verilecek İpler'] =
        parseInt(localState.value['Dikim İpi']) +
          state['design'].weights
            .filter(weight => weight.calculationType.includes('net'))
            .map(weight => weight.KALAN)
            .reduce((sum, a) => sum + a, 0) || 0;
      localState.value['Net'] =
        parseInt(localState.value['Depodan Verilecek İpler']) -
          parseInt(localState.value['Makas Fire']) || 0;
      localState.value['Brüt'] =
        parseInt(localState.value['Makas Fire']) +
          state['design'].weights
            .map(weight => weight.KALAN)
            .reduce((sum, a) => sum + a, 0) +
          parseInt(localState.value['Depodan Verilecek Ara İpi']) || 0;
    };

    watch(
      localState.value,
      () => {
        calculateTotals();
      },
      {
        deep: true
      }
    );

    onUpdated(() => {
      calculateTotals();
    });

    return {
      rows,
      localState,
      calculateTotals
      // totalCalculations
    };
  }
};
</script>

<style lang="scss">
.model-design-calculations {
  max-width: 450px;
  margin-top: 50px;
  table {
    input {
      width: 100px;
    }
  }
}

.text-right-input {
  input {
    text-align: right;
  }
}
</style>
