<template>
    <wo-modal
        class="wide-dialog-90vw one-step-behind-dialog"
        title="Üretim Detayları"
        :active="detailModal"
        @active="$event => $event === true ? $emit('detailModal', true) : cancelDrag()"
        :buttonsHidden="true"
    >
    <wo-table>
        <template #thead>
            <wo-th></wo-th>
            <wo-th v-for="title in titles" :key="`title-${title}`" class="text-left">
                {{ title }}
            </wo-th>
        </template>
            <wo-tr v-for="detail in detailData" :key="detail.id">
                <wo-td><vs-checkbox v-model="dataToSend.detaylar[detailData.indexOf(detail)]['isSelected']"/></wo-td>
                <wo-td>{{detail.kod}}</wo-td>
                <wo-td>{{detail.stok_isim}}</wo-td>
                <wo-td>
                    <wo-float-input
                        v-model="dataToSend.detaylar[detailData.indexOf(detail)]['miktar']"
                    />
                </wo-td>
                <wo-td>{{parseFloat(detail.maliyet).toFixed(4)}}</wo-td>
                <wo-td>{{getUnitById(detail.birim)}}</wo-td>
                <wo-td>{{detail.para_birimi}}</wo-td>
                <wo-td>{{parseFloat(detail.stok_fiyat_orj).toFixed(4)}}</wo-td>
                <!-- <wo-td>{{getStationById(detail.istasyon)}}</wo-td> -->
            </wo-tr>
    </wo-table>
    <wo-button text="Onayla" @click="updateStation"/>
    </wo-modal>
</template>

<script>
import { computed } from '@vue/composition-api';
import { transferDetail } from '../helpers/uretimPlanlamaAPIService';
import { primaryDialog } from '@/helpers/primaryDialogs';
export default {
    props: {
        detailModal: Boolean,
        detailData: Array,
        sourceStationID: String,
        targetStationID: String,
        stockUnits: Array,
        // stations: Array,
    },
    setup(props, { emit }) {
        const titles = [
            "Stok Kodu",
            "Stok Adı",
            "Miktar",
            "Maliyet",
            "Birim",
            "Para Birimi",
            "Stok Fiyat",
        ];

        const getUnitById = (id) => {
            return props.stockUnits.find(unit => unit.id === parseInt(id)).isim;
        };

        const dataToSend = computed(() => {
            return {
            kaynak_surec_id: props.sourceStationID,
            hedef_surec_id: props.targetStationID,
            detaylar: props.detailData.map(
                detail => {
                    return {
                        kaynak_id: detail.id,
                        stok_id: detail.stok_id,
                        miktar: detail.miktar,
                    };
            }),
            };
        });

        const updateStation = async () => {
            const detailData = {
                ...dataToSend.value,
                detaylar: dataToSend.value.detaylar.filter(detail => detail.isSelected),
            };
            const response = await transferDetail(detailData);
            primaryDialog(response.message, 'İstasyon Güncelle');
        };

        const cancelDrag = () => {
            console.log("cancelDrag modal");
            if (!dataToSend.value.detaylar.some(detail => detail.isSelected)) {
                emit('cancelDrag', dataToSend);
            }
            emit('detailModal', false);
        };

        return {
            titles,
            getUnitById,
            dataToSend,
            updateStation,
            cancelDrag,
        };
    }
};
</script>