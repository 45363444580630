export let editableRowValidationErrorMessage = 'Lütfen kalem, miktar ve maliyet alanlarını doldurunuz.';
export function validateEditableRow (tableData) {
  const isEntryEntered = tableData.entryName;
  const isAmountEntered = tableData.amount;
  const isCostEntered = tableData.cost;
  if (!isEntryEntered || parseFloat(String(isAmountEntered).replace(",", ".")).toFixed(2) * 1 === 0 || 
  parseFloat(String(isCostEntered).replace(",", ".")).toFixed(2) * 1 === 0) {
    editableRowValidationErrorMessage = 'Lütfen kalem, miktar, birim ve maliyet alanlarını doldurunuz.';
    return false;
  }
  return true;
}

export function validateSelectBoxesIsSelected (entryId, entryName, prevalentID, prevalentName) {
  if (!entryId && entryName === '') {
    editableRowValidationErrorMessage = 'Lütfen kalem alanını doldurunuz';
    return false;
  } else if (!entryId && entryName !== '') {
    editableRowValidationErrorMessage = 'Kalem bilgisi bulunamadı';
    return false;
  }
  if (!prevalentID && prevalentName !== '') {
    editableRowValidationErrorMessage = 'Cari bilgisi bulunamadı';
    return false;
  }
  return true;  
}