<template>
  <!-- :style="{ 'background-color': themeBg }" -->
  <div class="mx-auto p-4 rounded-lg bg-white">
    <div id="module-header" class="flex flex-col sm:flex-row justify-between p-1 mb-3">
      <h3 class="module-title">{{ moduleTitle }}</h3>
      <!-- <div class="flex flex-row icon-spacers mr-4">
        <div class="icon cursor-pointer" @click="openInfoModal()">
          <vx-tooltip text="Yardim" position="bottom">
            <feather-icon
              icon="InfoIcon"
              svgClasses="stroke-current"
            ></feather-icon>
          </vx-tooltip>
        </div>
        <div class="icon cursor-pointer" @click="handleFavourite()">
          <vx-tooltip text="Favorilere Ekle" position="bottom">
            <feather-icon icon="StarIcon" :svgClasses="fillStar"></feather-icon>
          </vx-tooltip>
        </div>

        <div class="icon cursor-pointer">
          <vx-tooltip text="Yetkiler" position="bottom">
            <feather-icon icon="EditIcon"></feather-icon>
          </vx-tooltip>
        </div>

        <div class="icon cursor-pointer">
          <vx-tooltip text="Elemanları Göster/Gizle Yönet" position="bottom">
            <feather-icon icon="SettingsIcon"></feather-icon>
          </vx-tooltip>
        </div>
      </div> -->
    </div>

    <template v-if="authorizationControl(auth)">
      <slot />
    </template>

    <div v-else-if="isAuthDataRecieved" class="no-auth flex justify-center items-center">
      <div class="flex flex-col space-y-2 items-center">
        <feather-icon
          icon="AlertTriangleIcon"
          svgClasses="h-12 w-12 mb-1 stroke-current text-danger"
        />
        <span class="text-danger text-xl font-bold">
          Bu bölümü kullanmaya yetkiniz yok!
        </span>
      </div>
    </div>

    <!-- <vs-prompt
      :active.sync="isInfoModalOpen"
      :title="moduleTitle"
      :buttons-hidden="true"
    >
      <div class="w-full">
        <div v-html="infoModalData"></div>
      </div>
    </vs-prompt> -->
    <div
      v-if="overlay"
      class="absolute top-0 left-0 w-full h-full bg-gray-200 bg-opacity-50 z-front"
    ></div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "@vue/composition-api";
import { overlay } from "@/consumables/useModuleOverlay.js";

import {
  setUserPermissions,
  authorizationControl,
  isAuthDataRecieved,
} from "@/consumables/useAuthorizationControl";

// import { getModuleInformation } from '@/helpers/ApiController';
import { setToken, setBaseURL } from "@/helpers/ApiController";

import { errorNotify } from "@/helpers/errorDialogs";

export default {
  props: {
    moduleTitle: {
      type: String,
    },
    informationModuleName: String,
    auth: String,
  },
  setup() {
    // favourite icon
    // const isFavourite = ref(false);

    // const handleFavourite = () => {
    //   isFavourite.value = !isFavourite.value;
    // };

    // const fillStar = computed(() => {
    //   return isFavourite.value
    //     ? 'fill-current stroke-current'
    //     : 'stroke-current';
    // });

    // // info modal
    // const isInfoModalOpen = ref(false);
    // const infoModalData = ref('');
    // const openInfoModal = () => {
    //   getModuleInformation(props.informationModuleName)
    //     .then(data => {
    //       isInfoModalOpen.value = true;
    //       infoModalData.value = data.icerik;
    //     })
    //     .catch(e => {
    //       root.$vs.dialog({
    //         title: 'Hata!',
    //         text: e,
    //         color: 'danger',
    //         acceptText: 'Tamam'
    //       });
    //     });
    // };

    //  const closeInfoModal = () => {
    //   isInfoModalOpen.value = false;
    // };
    if (process.env.NODE_ENV === "production") {
      setToken(`Bearer ${window.userToken}`);
    } else {
      setToken(`Bearer ${process.env.VUE_APP_API_KEY}`);
    }


    setBaseURL(window.baseUrl);

    // const handleEnterToTab = event => {
    //   const form = document.querySelector('form');
    //   if (form) {
    //     if (event.key === 'Enter') {
    //       // form icindeki tum input select ve button elementleri ceker
    //       const selectibles = form.querySelectorAll('input, select, button');
    //       for (const i in selectibles) {
    //         if (selectibles[i] === document.activeElement) {
    //           let j = parseInt(i) + 1;
    //           while (j < selectibles.length) {
    //             if (
    //               selectibles[j].tabIndex === 0 &&
    //               selectibles[j].getAttribute('disabled') === null
    //             ) {
    //               selectibles[j].focus();
    //               if (selectibles[j].tagName === 'INPUT') {
    //                 selectibles[j].select();
    //               }
    //               break;
    //             }
    //             j++;
    //           }
    //           break;
    //         }
    //       }
    //     }
    //   }
    // };

    const handleDialogs = (event) => {
      const dialog = document.querySelector(".vs-dialog");
      if (dialog) {
        // const dialogForm = dialog.querySelector('form');
        // if (event.key === 'Enter' && !dialogForm) {
        //   document.activeElement.blur();
        //   const acceptButton = dialog.querySelector('.vs-dialog-accept-button');
        //   if (acceptButton) {
        //     acceptButton.click();
        //   }
        // }
        if (event.key === "Escape") {
          document.activeElement.blur();
          const cancelIcon = dialog.querySelector(".vs-dialog-cancel");
          const cancelButton = dialog.querySelector(".vs-dialog-cancel-button");
          if (cancelIcon) {
            cancelIcon.click();
          } else if (cancelButton) {
            cancelButton.click();
          }
        }
      }
    };

    onMounted(() => {
      // if (root.$store.state.sirketler.length === 0) {
      //   root.$store.dispatch('updateSirketler');
      // }
      setUserPermissions().catch((error) => {
        errorNotify(error);
      });

      document.addEventListener("keyup", handleDialogs);
      // document.addEventListener('keyup', handleEnterToTab);
    });

    onUnmounted(() => {
      document.removeEventListener("keyup", handleDialogs);
      // document.removeEventListener('keyup', handleEnterToTab);
    });

    return {
      // isFavourite,
      // handleFavourite,
      // fillStar,
      // isInfoModalOpen,
      // openInfoModal,
      // closeInfoModal,
      // infoModalData,

      isAuthDataRecieved,
      authorizationControl,
      overlay,
    };
  },
  // computed: {
  //   themeBg() {
  //     return this.$store.state.theme === 'dark' ? '#313131' : 'white';
  //   }
  // }
};
</script>

<style scoped>
.icon {
  @apply h-6 w-6;
}

.module-title {
  @apply font-semibold;
}

.icon-spacers > * {
  margin: 0 0.5rem;
}
.icon-spacers > :first-child {
  margin-left: 0;
}
.icon-spacers > :last-child {
  margin-right: 0;
}

.no-auth {
  width: inherit;
  height: 70vh;
}
</style>
