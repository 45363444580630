import {
  fetchVaryantEtiket,
  fetchVaryantPaketleme,
  fetchCollections,
  fetchYikamaIbareleri,
  fetchDimensionPhrases,
  fetchPrimaryMaterials,
  fetchProcesses,
  getDepots,
  getExchangeRates,
  getStockUnits, 
  getColorsFromApi,
  getSizesFromApi,
  getBranches,
  fetchRecipeGroups,
  fetchInformationsPhrases,
  fetchMachines
} from '@/helpers/ApiController';
import { errorDialog } from '@/helpers/errorDialogs';
import { reactive, ref } from '@vue/composition-api';
import moment from 'moment';

const labelSize = 'md';

// TODO: INITLERI TEKE CEKEBILIRSIN
window.anaparabirimi = 'TL';

const statuses = {
  design: [
    {
      id: 0,
      name: 'Model'
    },
    {
      id: 1,
      name: 'Model Onay'
    },
    {
      id: 2,
      name: 'İptal'
    }
  ],
  prototype: [
    {
      id: 0,
      name: "Prototip",
    },
    {
      id: 1,
      name: "Prototip Onay",
    },
    {
      id: 2,
      name: "İptal",
    },
  ],
  confirmed: [
    {
      id: 0,
      name: "Planlama",
    },
    {
      id: 1,
      name: "Üretim Onay",
    },
  ],
};

const tableColumns = [
  {
    title: 'Kalem Türü',
    key: 'entryType'
  },
  {
    title: 'Ana Materyal',
    key: 'primaryMaterial'
  },
  {
    title: 'Süreç',
    key: 'process'
  },
  {
    title: 'Kalem',
    key: 'entryName'
  },
  {
    title: 'Cari',
    key: 'prevalentName'
  },
  {
    title: 'Miktar',
    key: 'amount'
  },
  {
    title: 'Birim',
    key: 'unit'
  },
  {
    title: 'Maliyet',
    key: 'cost'
  },
  {
    title: 'Renk/Beden',
    key: 'colorsize'
  },
  {
    title: 'Para Birimi',
    key: 'currency'
  },
  {
    title: `Tutar`,
    key: 'mainCurrencyPrice'
  },
  {
    title: 'Açıklama',
    key: 'explanation'
  },
  {
    title: 'İşlemler',
    key: ''
  }
];

const entryTypes = ['Yarı Mamül', 'Stok', 'Gider'];

const depots = ref([]);

const branches = ref([]);

const initBranches = async () => {
  try {
    const response = await getBranches();
    branches.value = response;
  } catch (error) {
    errorDialog(error);
  }
};


const initDepots = async () => {
  try {
    const allDepots = await getDepots();
    depots.value = allDepots.filter(depot => depot.uretim_yapilir === "1");
  } catch (error) {
    errorDialog(error);
  }
};

const exchangeRates = ref({
  prototype: [],
  confirmed: []
});

const initExchangeRates = async (date = new Date()) => {
  try {
    // date.prototype('d.m.Y');
    date.setDate(date.getDate() - 1);
    const formatedDate = moment(date).format('YYYY-MM-DD');
    const { kurlar } = await getExchangeRates(formatedDate);
    
    exchangeRates.value.prototype = kurlar.map(a => ({ ...a }));
    exchangeRates.value.confirmed = kurlar.map(a => ({ ...a }));
  } catch (error) {
    errorDialog(error);
  }
};

const currencyInputValue = ref({
  prototype: 1,
  confirmed: 1
});

const stockUnits = ref([]);

const machines = ref([]);


const initStockUnits = async () => {
  try {
    stockUnits.value = await getStockUnits();
  } catch (error) {
    errorDialog(error);
  }
};

const initMachines = async () => {
  try {
    machines.value = await fetchMachines();
  } catch (error) {
    errorDialog(error);
  }
};

const collections = ref([]);
const yikamaIbareleri = ref([]);
const varyantEtiket = ref([]); 
const varyantPaketleme = ref([]);
const primaryMaterials = ref([]);
const processes = ref([]);

const selectedColors = reactive({
  prototype: [],
  confirmed: []
});
const selectedSizes = reactive({
  prototype: [],
  confirmed: []
  });

const initProcesses = async () => {
  processes.value = await fetchProcesses();
};

const initPrimaryMaterials = async () => {
  primaryMaterials.value = await fetchPrimaryMaterials();
};

const idListByColor = ref({});
const idListBySize = ref({});

const dimensionPhrases = ref([]);
const dressPhrases = ref([]);
// const washingPhrases = ref([]);
// const pureDimensionTitles = ref([]);
// const dryPhrases = ref([]);

const washingColumns = ref([
  { name: 'S' }, { name: 'M' }, { name: 'L' }, { name: 'STD' }
]);

const shuttles = ref([]);

const newDimensionPhrases = ref([]);

const newMachineInfoTitles = ref([]);

const newDressPhrases = ref([]);

const stokOzellikler = ref({});

const colors = ref([]);
const sizes = ref([]);

const colorsWithID = ref([]);
const sizesWithID = ref([]);

const recipeGroups = ref([]);

const initRecipeGroups = async () => {
  recipeGroups.value = await fetchRecipeGroups();
};

const initDimensionPhrases = async () => {
  const incomingDimensionPhrases = await fetchDimensionPhrases();
  dimensionPhrases.value = incomingDimensionPhrases[0];
};

const initDressPhrases =  () => {
  dressPhrases.value = [
    {
      id: 0,
      name: '1.ZEMİN'
    },
    {
      id: 1,
      name: '2.RENK'
    },
    {
      id: 2,
      name: '3.RENK'
    },
    {
      id: 3,
      name: '4.RENK'
    },
    {
      id: 4,
      name: '5.RENK'
    },
    {
      id: 5,
      name: '6.RENK'
    },
    {
      id: 6,
      name: '7.RENK'
    },
    {
      id: 7,
      name: '8.RENK'
    },
  ];
};

const initShuttles = () => {
  shuttles.value = [
    {
      id: 0,
      name: '1. VARYANT'
    },
    {
      id: 1,
      name: '2. VARYANT'
    },
    {
      id: 2,
      name: '3. VARYANT'
    },
    {
      id: 3,
      name: '4. VARYANT'
    },
    {
      id: 4,
      name: '5. VARYANT'
    },
    {
      id: 5,
      name: '6. VARYANT'
    },
    {
      id: 6,
      name: '7. VARYANT',
    },
    {
      id: 7,
      name: '8. VARYANT'
    },
    {
      id: 8,
      name: '9. VARYANT'
    }
  ];
};

const informationPhrases = ref({
  washingPhrases: [],
  dryPhrases: [],
  pureDimensionTitles: [],
  machineInformationTitles: []
});

const initInformationPhrases = async () => {
  const data = await fetchInformationsPhrases();
  dressPhrases.value = data.filter(item => item.tur === '1');
  informationPhrases.value.washingPhrases = data.filter(item => item.tur === '2');
  informationPhrases.value.dryPhrases = data.filter(item => item.tur === '3');
  informationPhrases.value.pureDimensionTitles = data.filter(item => item.tur === '4');
  informationPhrases.value.machineInformationTitles = data.filter(item => item.tur === '5');
};

const initColors = async ()  => { 
  const data = await getColorsFromApi();
  colorsWithID.value = data.data.map(c => {
    return {
      id: c.id,
      name: c.isim
    };
  });
  colors.value = data.data.map(c => c.isim);
};

const initSizes = async () => {
  const data = await getSizesFromApi();
  sizesWithID.value = data.data.map(s => {
    return {
      id: s.id,
      name: s.isim
    };
  });
  sizes.value = data.data.map(s => s.isim);
};

const initVaryantPaketleme = async () => {
  varyantEtiket.value = await fetchVaryantEtiket();
};

const initVaryantEtiket = async () => {
  varyantPaketleme.value = await fetchVaryantPaketleme();
};

const initCollections = async () => {
  collections.value = await fetchCollections();
};

const initYikamaIbareleri = async () => {
  yikamaIbareleri.value = await fetchYikamaIbareleri();
};

const isConfirmedDisabled = ref(true);
const isPrototypeDisabled = ref(true);

const otherInformationHTML = ref('');

const detailsTableFormTemplate = () => {
  return {
    id: undefined,
    entryType: 0,
    primaryMaterial: "",
    process: "",
    entryName: '',
    color: '',
    size: '',
    entryID: 0,
    prevalentID: '',
    prevalentName: '',
    amount: 0,
    unit: 0,
    cost: 0,
    currency: window.anaparabirimi,
    price: 0,
    explanation: ''
  };
};

const stateGenerator = () => {
  return {
    info: {
      id: 0,
      houseId: 0,
      branch: 1,
      draftID: 0,
      date: moment().format('DD.MM.YYYY'),
      modelCode: '',
      modelID: '',
      orderID: '',
      orderNumber: '',
      modelName: '',
      productDescription:'',
      yarnQuality: '',
      // colors:[],
      bodySize: 0,
      yikamaIbaresi: 0,
      etiket: 0,
      process: 0,
      paketleme: 0,
      serial: '',
      sequence: '',
      mainContractorID: 0,
      mainContractorName: '',
      depot: 0,
      imageFiles: [],
      imageUrls: [],
      status: '0',
      explanation: '',
      currentCurrency: window.anaparabirimi,
      currentCurrencyRate: 1,
      machineID: 0,
      collection: 0,
      deliveryDate: moment().format('DD.MM.YYYY'),
      modelColor: 0,
      modelBodySize: 0,
      modelVariants : {},
      finalProductID: 0,
      finalProductName: '',
      otherInformations : {
        dimensions: {}, 
        dimensionInfo: {},
        colorWeaves: {},
        weavingInfo: {},
        weights: [],
        pureDimensions: [],
        shuttles: [],
        yarnType: [],
        washing: {},
        washingInfo: {},
        washingDimensions: {},
        drying : {}
      }
    },
    details: {
      colorRecipes: {},
      sizeRecipes: {},
      logs: [],
      stocks: [],
      semiProducts: [],
      expenses: [],
      calculations: {
        directMaterialCosts: 0,
        indirectCosts: 0,
        smm: 0,
        loadingCosts: 0,
        salesAndOrganization: 0,
        salesCosts: 0,
        profitMargin: 0,
        netProfitSalesPrice: 0,
        discount: 0,
        lastSalesPrice: 0
      },
      tableForm: detailsTableFormTemplate()
    }
  };
};

const designStateGenerator = () => {
  return {
      id: 0,
      model_id: 0,
      model_code: '',
      model_name: '',
      design_number: "",
      designer_id: "",
      status: '',
      designer_name: "",
      designer_option: "1",
      // machine_staff: "",
      image_files: [],
      image_urls: [],
      speed: "",
      machine_id: 0,
      machine_produced: "",
      total_machine: "",
      machine_working_time: "",
      machine_minute: "",
      fast_by_ssg_machine: "",
      exit_time: "",      
      machine_information: {},
      weights: [],
      manufacture_details: [
        [
          // {
            // "ÜRETİLEN PARÇA": "",
            // "HAM ÖLÇÜ": 0,
            // "DAKİKA": 0
          // }
        ]
      ],
      calculations: {
        "Dikim İpi": 0,
        "Depodan Verilecek İpler": 0,
        "Depodan Verilecek Ara İpi": 0,
        "Makas Fire": 0,
        "Brüt": 0,
        "Net": 0
      },
      design_description: "",
  };
};

const state = reactive({
  prototype: stateGenerator(),
  confirmed: stateGenerator(),
  design: designStateGenerator()
});

export function useModelPlanlamaState() {
  return {
    labelSize,
    tableColumns,
    entryTypes,
    depots,
    initDepots,
    exchangeRates,
    initExchangeRates,
    currencyInputValue,
    stockUnits,
    initStockUnits,
    collections,
    initCollections,
    detailsTableFormTemplate,
    stateGenerator,
    state,
    isConfirmedDisabled,
    colors,
    initColors,
    sizes,
    yikamaIbareleri,
    initYikamaIbareleri,
    varyantEtiket,
    initVaryantEtiket,
    varyantPaketleme,
    initVaryantPaketleme,
    initSizes,
    dimensionPhrases,
    initDimensionPhrases,
    dressPhrases,
    initDressPhrases,
    shuttles,
    initShuttles,
    newDimensionPhrases,
    initPrimaryMaterials,
    primaryMaterials,
    processes,
    initProcesses,
    initBranches,
    branches,
    initRecipeGroups,
    recipeGroups,
    selectedColors,
    selectedSizes,
    colorsWithID,
    sizesWithID,
    otherInformationHTML,
    newDressPhrases,
    initInformationPhrases,
    // washingPhrases,
    washingColumns,
    // dryPhrases,
    idListByColor,
    idListBySize,
    machines,
    initMachines,
    informationPhrases,
    newMachineInfoTitles,
    statuses,
    isPrototypeDisabled,
    stokOzellikler
  };
}