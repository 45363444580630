<template>
  <div class="flex">
    <base-input
      v-model="serial"
      :readonly="readonly"
      inputClass="uppercase"
      class="w-20"
      name="Serial"
      @input="handleSerialInput"
      @blur="handleSerialBlur"
    />
    <base-input v-model="sequence" class="w-36 ml-1" autocomplete="off"/>
    <!-- <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div
        v-if="error && error.length"
        class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
        key="danger"
      >
        <span class="span-text-validation">{{ error[0] }}</span>
      </div>
    </transition> -->
  </div>
  
</template>

<script>
import { getSequenceValue } from '@/helpers/ApiController';
import { errorNotify } from '@/helpers/errorDialogs';

import debounce from 'lodash.debounce';

import { ref, watch } from '@vue/composition-api';

export default {
  props: {
    module: String,
    serialValue: String,
    sequenceValue: [String, Number],
    readonly: Boolean,
    params: Object,
    // error: Array,
  },
  setup(props, { emit }) {
    const serial = ref(props.serialValue || '');
    const sequence = ref(props.sequenceValue || '');

    watch(props, () => {
      serial.value = props.serialValue;
      sequence.value = props.sequenceValue;
    });

    // Serial - Sequence API call
    const callGetSequenceValue = () => {
      getSequenceValue(props.module, serial.value, props.params)
        .then(response => {
          sequence.value = response.data.sira;
        })
        .catch(() => {
          errorNotify('Sıra değerini çekerken bir hata oluştu.');
        });
    };

    const debouncedSequence = debounce(callGetSequenceValue, 300);

    const handleSerialInput = event => {
      debouncedSequence();
      emit('update:serialValue', event);
    };

    const handleSerialBlur = () => {
      callGetSequenceValue();
      emit('update:serialValue', serial.value);
    };

    watch(sequence, () => {
      emit('update:sequenceValue', sequence.value);
      emit('change');
    });

    // const beforeEnter = (el) => {
    //   el.style.height = 0;
    // };
    // const enter = (el, done) => {
    //   const h = el.scrollHeight;
    //   el.style.height = `${h}px`;
    //   done();
    // };
    // const leave = (el) => {
    //   el.style.height = "0px";
    // };

    return {
      serial,
      sequence,
      handleSerialInput,
      handleSerialBlur,
      // beforeEnter,
      // enter,
      // leave,
    };
  }
};
</script>

<style></style>
