<template>
  <multiselect
    :value="value"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :preserve-search="true"
    placeholder=""
    :label="label"
    :track-by="trackBy"
    @select="addElement"
    @remove="removeElement"
  >
    <template slot="selection" slot-scope="{ values, isOpen }"
      ><span
        class="multiselect__single"
        v-if="values.length && !isOpen"
        >{{ values.map(a => a[label]).join(', ') }}</span
      ></template
    >
    <template #noOption>
      Liste boş
    </template>
  </multiselect>
</template>

<script>
export default {
  name: 'WOMultiselect',
  props: {
    value: Array,
    options: Array,
    label: String,
    trackBy: String,
  },
  setup () {
    

    return {};
  }
};
</script>

<style lang="scss" scoped>

</style>