<template>
  <div class="space-y-2">
    <wo-table>
      <caption class="text-lg font-medium">Toplamlar</caption>
      <wo-tr  v-for="(calcs, index) in totalCalculationsMap" :key="`calculations-${index}`">
        <wo-td v-if="!(calcs.label==='Son Satış Fiyatı')">{{calcs.label}}</wo-td>
        <wo-th v-else class="text-left text-lg">{{calcs.label}}</wo-th>
        <wo-td>
          <div class="text-center">
            <div v-if="calcs.component" class="flex items-center">
              <span>%</span>&nbsp; 
              <component :is="calcs.component" v-model="localState.calculations[calcs.key]" style="max-width: 60px;"></component>
              &nbsp;&nbsp;
              <span class="text-danger">{{totalCalculations[calcs.key]}}</span>
            </div>
            <span v-else>
              {{ totalCalculations[calcs.key] }} ₺
            </span>
          </div>
        </wo-td>
      </wo-tr>
    </wo-table>
    <span class="text-danger text-xs" v-if="totalCalculations.lastSalesPrice < totalCalculations.salesCosts">Dikkat! Satış fiyatı maliyet fiyatının altındadır</span>
  </div>
</template>
<script>
import { useModelPlanlamaState } from "../../composables/useModelPlanlamaState";
import { computed, watch } from '@vue/composition-api';
import WOFloatInput from '@/components/WO/WOFloatInput.vue';
import { numToTrStr, trStrToNum } from "@/helpers/NumberController";

export default {
  props: {
    stateKey: String
  },
  setup (props) {
    const { labelSize, state } = useModelPlanlamaState();

    const localState = computed(() => {
      return state[props.stateKey].details;
    });
    const totalCalculationsMap = [
      // stok iceren maliyetler
      { label: 'Direkt Malzeme Maliyeti', key: 'directMaterialCosts'},
      // stok harici maliyetler
      { label: 'İndirekt Maliyetler', key: 'indirectCosts'},
      // total maliyetlerin toplami
      { label: 'SMM', key: 'smm'},
      { label: 'Birim Maliyet', key: 'unitCost' },
      // yuzde girilecek
      { label: 'Yükleme Maliyeti', key: 'loadingCosts', component: WOFloatInput},
      // yuzde girilecek
      { label: 'Satış ve Yönetim', key: 'salesAndOrganization', component: WOFloatInput},
      // usttekilerin toplami
      { label: 'Satış Maliyeti', key: 'salesCosts'},
      // yuzde girilecek
      { label: 'Kar Marjı', key: 'profitMargin', component: WOFloatInput},
      // kar ve satis maliyetinin toplami
      { label: 'Satış Fiyatı', key: 'netProfitSalesPrice'},
      // yuzde girilecek
      { label: 'İndirim', key: 'discount', component: WOFloatInput},
      // total toplam
      { label: 'Son Satış Fiyatı', key: 'lastSalesPrice'},
    ];

    const calculateDirectMaterialCosts = () => {
      return localState.value.stocks.reduce((acc, a) => acc + trStrToNum(a.mainCurrencyPrice), 0) + localState.value.semiProducts.reduce((acc, a) => acc + trStrToNum(a.mainCurrencyPrice), 0);
    };

    const totalCost = () => {
      return localState.value.stocks.reduce((acc, a) => acc + trStrToNum(a.cost), 0) + localState.value.semiProducts.reduce((acc, a) => acc + trStrToNum(a.cost), 0);
    };

    const calculatePercentage = (val, key) => {
      return val * trStrToNum(localState.value.calculations[key]) / 100;
    };

    const totalCalculations = computed(() => {
      const directMaterialCosts = calculateDirectMaterialCosts();
      const indirectCosts = localState.value.expenses.reduce((acc, a) => acc + trStrToNum(a.mainCurrencyPrice), 0);
      const smm = directMaterialCosts + indirectCosts;
      const unitCost = (totalCost() / (localState.value.stocks.length + localState.value.semiProducts.length)) + indirectCosts;
      const loadingCosts = calculatePercentage(unitCost, 'loadingCosts');
      const salesAndOrganization = calculatePercentage(unitCost, 'salesAndOrganization');
      const salesCosts =  unitCost + loadingCosts + salesAndOrganization;
      const profitMargin = calculatePercentage(salesCosts, 'profitMargin');
      const netProfitSalesPrice = salesCosts + profitMargin;
      const discount = calculatePercentage(netProfitSalesPrice, 'discount');
      const lastSalesPrice = netProfitSalesPrice - discount; 
      return {
        directMaterialCosts: numToTrStr(directMaterialCosts),
        indirectCosts: numToTrStr(indirectCosts),
        smm: numToTrStr(smm),
        unitCost: numToTrStr(unitCost),
        loadingCosts: numToTrStr(loadingCosts),
        salesAndOrganization: numToTrStr(salesAndOrganization),
        salesCosts: numToTrStr(salesCosts),
        profitMargin: numToTrStr(profitMargin),
        netProfitSalesPrice: numToTrStr(netProfitSalesPrice),
        discount: numToTrStr(discount),
        lastSalesPrice: numToTrStr(lastSalesPrice)
      };
    });
    watch(totalCalculations, () => {
      // state[props.stateKey].details.calculations.directMaterialCosts = totalCalculations.value.directMaterialCosts;
      // state[props.stateKey].details.calculations.indirectCosts = totalCalculations.value.indirectCosts;
      // state[props.stateKey].details.calculations.smm = totalCalculations.value.smm;
      // state[props.stateKey].details.calculations.loadingCosts = totalCalculations.value.loadingCosts;
      // state[props.stateKey].details.calculations.salesAndOrganization = totalCalculations.value.salesAndOrganization;
      // state[props.stateKey].details.calculations.salesCosts = totalCalculations.value.salesCosts;
      // state[props.stateKey].details.calculations.profitMargin = totalCalculations.value.profitMargin;
      // state[props.stateKey].details.calculations.netProfitSalesPrice = totalCalculations.value.netProfitSalesPrice;
      // state[props.stateKey].details.calculations.discount = totalCalculations.value.discount;
      state[props.stateKey].details.calculations.lastSalesPrice = totalCalculations.value.lastSalesPrice;
    });
    return {
      labelSize,
      localState,
      totalCalculationsMap,
      totalCalculations,
    };
  }
};
</script>

<style lang="scss" scoped>

</style>