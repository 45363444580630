<template>
  <div class="space-y-4">
    <CariFormSectionHeader>Fatura Bilgileri</CariFormSectionHeader>
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Firma Ünvanı</template>
      <template #firstAfter>
        <div class="w-full md:w-1/2">
          <base-input class="w-full" v-model="state.companyTitle" />
        </div>
      </template>
      <template #secondLabel>
        <span v-if="eInvoice"> E-Fatura Ünvanı </span>
      </template>
      <template #secondElement>
        <span v-if="eInvoice">{{ eInvoice.isim }}</span>
      </template>
    </wo-double-form>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Vergi Dairesi </template>
        <template #firstElement>
          <wo-char-input v-model="state.taxOffice" />
        </template>
        <template #secondLabel> Vergi Numarası </template>
        <template #secondElement>
          <div class="flex space-x-4">
            <base-input
              maxlength="10"
              v-model="state.taxNumber"
              class="w-40"
              @input="debouncedMailBoxes"
            />
            <wo-button
              text="?"
              @click="() => taxpayerControl(state.taxNumber)"
            />
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Şahıs Şirketi Sahibi ise İsim Soyisim</template>
        <template #firstElement>
          <div class="flex space-x-2">
            <base-input
              placeholder="İsim"
              class="w-26"
              v-model="state.soleProprietorshipsOwnerName"
            />
            <base-input
              placeholder="Soyisim"
              class="w-26"
              v-model="state.soleProprietorshipsOwnerSurname"
            />
          </div>
        </template>
        <template #secondLabel> TC Numarası </template>
        <template #secondElement>
          <div class="flex space-x-4">
            <wo-tc-input
              class="w-40"
              v-model="state.identityNumber"
              @input="debouncedMailBoxes"
            />
            <wo-button
              text="?"
              @click="() => taxpayerControl(state.identityNumber)"
            />
          </div>
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <wo-double-form :labelSize="labelSize">
      <template #firstBefore>
        <wo-country-province-district
          class="2xl:space-y-0 flex flex-col 2xl:flex-row space-x-0 2xl:space-x-4"
          :labelSize="labelSize"
          :district.sync="state.district"
          :province.sync="state.province"
          :country.sync="state.country"
        />
      </template>
      <template #secondLabel> Fatura Adresi </template>
      <template #secondElement>
        <wo-textarea v-model="state.invoiceAddress" />
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize" v-if="eInvoice || eWaybill">
      <template #firstLabel>
        <span v-if="eInvoice"> Varsayılan E-Fatura Posta Kutusu </span>
      </template>
      <template #firstElement>
        <wo-select
          v-if="eInvoice"
          :data="eInvoiceMailBoxes"
          v-model="state.eInvoiceMail"
        >
          <template #defaultValue>
            <wo-select-item itemValue="" text=" - " />
          </template>
          <template #default="{ item }">
            <wo-select-item :itemValue="item.urn" :text="item.urn" />
          </template>
        </wo-select>
      </template>
      <template #secondLabel>
        <span v-if="eWaybill"> Varsayılan E-İrsaliye Posta Kutusu </span>
      </template>
      <template #secondElement>
        <wo-select
          v-if="eWaybill"
          :data="eWaybillMailBoxes"
          v-model="state.eWaybillMail"
        >
          <template #defaultValue>
            <wo-select-item itemValue="" text=" - " />
          </template>
          <template #default="{ item }">
            <wo-select-item :itemValue="item.urn" :text="item.urn" />
          </template>
        </wo-select>
      </template>
    </wo-double-form>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState';
import taxpayerControl from '../../helpers/taxpayerControl';
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

import {
  getEInvoiceMailBox,
  getEWaybillMailBox
} from '../../helpers/ApiFactory.js';

import debounce from 'lodash.debounce';

import { ref } from '@vue/composition-api';

export default {
  components: {
    CariFormSectionHeader,
    CariFormQuadrupleFormWrapper
  },
  setup() {
    const { labelSize, state } = usePrevalentState();

    const eInvoice = window.efatura_durum_sor;
    const eWaybill = window.eirsaliyeDurumSor;

    const eInvoiceMailBoxes = ref([]);
    const eWaybillMailBoxes = ref([]);

    const getMailBoxes = async () => {
      if (state.taxNumber || state.identityNumber) {
        eInvoiceMailBoxes.value = await getEInvoiceMailBox(
          state.taxNumber,
          state.identityNumber
        );

        eWaybillMailBoxes.value = await getEWaybillMailBox(
          state.taxNumber,
          state.identityNumber
        );
      }
    };

    const debouncedMailBoxes = debounce(getMailBoxes, 500);

    debouncedMailBoxes();

    return {
      taxpayerControl,
      labelSize,
      state,
      eInvoice,
      eWaybill,
      eInvoiceMailBoxes,
      eWaybillMailBoxes,
      debouncedMailBoxes
    };
  }
};
</script>

<style lang="scss" scoped></style>
