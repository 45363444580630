<template functional>
  <td
    class="wo-td"
    :class="[data.staticClass || '', data.class]"
    v-bind="data.attrs"
    :style="data.staticStyle"
  >
    <slot />
  </td>
</template>

<script>
export default {
  name: 'WOTd'
};
</script>

<style lang="scss">
.wo-td {
  border: 0;
  padding: 10px 15px;
  &:first-child {
    border-left: 1px solid transparent;
  }
}
</style>
