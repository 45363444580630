<template functional>
  <tr
    class="tr-values vs-table--tr hoverFlat"
    :class="[data.staticClass || '', data.class]"
    v-bind="data.attrs"
    :style="data.staticStyle"
  >
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'WOTr'
};
</script>
