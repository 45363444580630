<template>
  <div class="vs-input vs-width vs-input-primary">
    <input
      :value="inputVal"
      v-bind="$attrs"
      class="vs-input--input"
      v-on="listeners"
      :autocomplete="autocomplete"
      :class="inputClass"
      @change="emitChange"
    />
    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div
        v-if="danger"
        key="danger"
        class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
      >
        <span class="span-text-validation">
          {{ dangerText }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';

export default {
  props: {
    inputType: String,
    idKey: Number,
    autocomplete: { type: String, default: 'on' }
  },
  name: 'BaseInput',
  inheritAttrs: false,
  mixins: [inputComponentMixin],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: evt => {
          if (this.inputType === 'quickSearch') {
            document.dispatchEvent(
              new CustomEvent(`quickSearch${this.idKey}`, {
                detail: {
                  value: evt.target.value,
                  name: this.name
                }
              })
            );
          } else {
            this.$emit('input', evt.target.value);
          }
        },
        focus: evt => {
          this.$emit('focus', evt);
        },
        blur: evt => {
          this.$emit('blur', evt);
        }
      };
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el, done) {
      const h = el.scrollHeight;
      el.style.height = `${h}px`;
      done();
    },
    leave(el) {
      el.style.height = '0px';
    },
    emitChange(evt) {
      this.$emit('change', evt.target.value);
    }
  }
};
</script>
