<template>
  <div
    class="flex flex-row justify-center items-center gap-1"
    :class="{ 'pb-12': !noPadding }"
  >
    <label>{{ label }}</label>
    <wo-quick-search
      v-model="basketInputValue"
      :queryParameters="quickSearchQuery"
      @selected="item => handleSelectedBasket(item)"
    >
      <template v-slot:default="{ item }">
        <slot name="quickSearch" v-bind:item="item" />
      </template>
    </wo-quick-search>
    <wo-button
      color="primary"
      type="border"
      @click="buttonAddBasket()"
      class="py-1 px-4"
      text="+"
    />
    <wo-button
      color="primary"
      class="py-1 px-4"
      type="border"
      :href="`${$baseURL}${directLink}${basketID}`"
      target="_blank"
      text="↱"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { errorDialog } from '@/helpers/errorDialogs';

export default {
  props: {
    noPadding: {
      type: Boolean,
      default: false
    },
    quickSearchQuery: String,
    label: String,
    directLink: String
  },
  setup(props, { emit }) {
    const basketID = ref(0);
    const basketInputValue = ref('');
    const basketTitle = ref('');

    const buttonAddBasket = () => {
      if (basketID.value) {
        emit('updateLines', {
          id: basketID.value,
          name: basketInputValue.value,
          title: basketTitle.value
        });
      } else {
        errorDialog(
          'Sepet eklemeyi denemeden önce geçerli bir sepet seçmelisiniz.'
        );
      }
    };

    const handleSelectedBasket = item => {
      if (item.isSelected) {
        basketID.value = item.id;
        basketInputValue.value = item.isim;
        basketTitle.value = item.unvan;
      } else if (!item.isSelected && basketID.value) {
        basketInputValue.value = '';
        basketID.value = 0;
      }
    };

    return {
      basketID,
      basketInputValue,
      buttonAddBasket,
      handleSelectedBasket
    };
  }
};
</script>

<style></style>
