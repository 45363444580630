<template>
  <transition>
    <div v-if="active" ref="con" class="vs-component con-vs-dialog custom-dialog">
      <div class="vs-dialog-dark" @click="$emit('active', false)" />
      <div ref="dialogx" class="vs-dialog">
        <header class="vs-dialog-header">
          <div class="con-title-after">
            <span :class="'text-' + color" class="after" />
            <h3 :class="['dialog-title', 'text-' + color]">
              {{ title }}
            </h3>
          </div>
          <feather-icon
            icon="XIcon"
            class="icon-style vs-dialog-cancel vs-dialog-cancel--icon notranslate text-danger"
            @click="() => $emit('active', false)"
          />
        </header>
        <div class="vs-dialog-text" style="max-height: 500px; overflow: scroll;">
          <slot />
        </div>
        <footer v-if="!buttonsHidden">
          <wo-button :color="color" :text="acceptText" @click="() => accept()" />
          <wo-button
            type="flat"
            color="danger"
            text="İptal"
            @click="() => closeWithoutSave()"
          />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from "@vue/composition-api";
export default {
  name: "WOModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    buttonsHidden: {
      type: Boolean,
      default: false,
    },
    accept: Function,
    acceptText: {
      type: String,
      default: "Tamam",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    closeAct: {
      type: Function,
      required: false,
    },
  },
  setup(props, { emit }) {
    const closeWithoutSave = () => {
      props.closeAct();
      emit("active", false);
    };

    const returnColor = computed(() => {
      if (props.color.startsWith("#")) {
        return props.color;
      } else if (props.color === "primary") {
        return "#ff5722";
      } else {
        return "#ff5722";
      }
    });

    return {
      closeWithoutSave,
      returnColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  overflow: scroll;
}
</style>
