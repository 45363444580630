<template>
  <base-input
    v-model="inputVal"
    @keypress="handleIntKeyPress"
    :danger="danger"
    :dangerText="dangerText"
    v-on="$listeners"
    v-bind="$attrs"
    :inputClass="inputClass"
  />
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';

export default {
  name: 'WOIntInput',
  inheritAttrs: false,
  mixins: [inputComponentMixin],
  methods: {
    handleIntKeyPress(event) {
      event = event ? event : window.event;
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        //rakam olmayan diger karakterler
        event.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
