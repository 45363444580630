import { factory } from '@/helpers/ApiController.js';

import { dateFromServer } from '@/helpers/serverToClientConversion';
import { numToTrStr } from '@/helpers/NumberController';
import { detailsFromClient } from '@/helpers/clientToServerConversion';

// * SAVE =========================================

const processSaveData = (formValues, tableArray, id) => {
  return {
    kaydet: 1,
    duzenle: id,
    sube: formValues.subeValue,
    seri: formValues.seriValue,
    sira: formValues.siraValue,
    tarih: formValues.tarihValue,
    depo: formValues.depoValue,
    isim: formValues.isimValue,
    stok_id: detailsFromClient(tableArray, 'urunId', false),
    kaynak_stok_id: detailsFromClient(tableArray, 'kaynakUrunId', false),
    birim: detailsFromClient(tableArray, 'birim', false),
    maliyet: detailsFromClient(tableArray, 'kaynakUrunMaliyeti'),
    verimlilik_orani: detailsFromClient(tableArray, 'verimlilikOrani'),
    maliyet_dusurucu_oran: detailsFromClient(tableArray, 'maliyetDusurucuOran'),
    sarfiyat_ve_diger_masraflar: detailsFromClient(tableArray, 'sarfiyat'),
    iscilik_payi: detailsFromClient(tableArray, 'iscilikPayi'),
    net_urun_maliyet: detailsFromClient(tableArray, 'nihaiMaliyet'),
    minimum_marj: detailsFromClient(tableArray, 'maksimumMarjin'),
    maksimum_marj: detailsFromClient(tableArray, 'minimumMarjin'),
    aciklama: formValues.aciklamaValue
  };
};

export async function saveMaliyetAnalizEkle(formValues, tableArray, id) {
  const url = 'maliyet/save';
  const resultObj = processSaveData(formValues, tableArray, id);

  const { data } = await factory.post(url, resultObj);
  return data;
}

// * EDIT =========================================

const processFetchedFormValues = data => {
  return {
    subeValue: data.sube === '0' ? '' : data.sube,
    seriValue: data.seri,
    siraValue: data.sira,
    tarihValue: dateFromServer(data.tarih),
    depoValue: data.depo,
    isimValue: data.isim,
    aciklamaValue: data.aciklama
  };
};

const processFetchedTableDetails = (data, createNewTableObj) => {
  const tableArray = [];
  for (const i in data.detaylar) {
    tableArray.push(createNewTableObj());
    const apiRow = data.detaylar[i];
    const tableRow = tableArray[i];
    tableRow.birim = apiRow.birim;
    tableRow.iscilikPayi = numToTrStr(apiRow.iscilik_payi);
    tableRow.kaynakUrunMaliyeti = numToTrStr(apiRow.maliyet);
    tableRow.maksimumMarjin = numToTrStr(apiRow.maksimum_marj);
    tableRow.maliyetDusurucuOran = numToTrStr(apiRow.maliyet_dusurucu_oran);
    tableRow.minimumMarjin = numToTrStr(apiRow.minimum_marj);
    tableRow.nihaiMaliyet = numToTrStr(apiRow.net_urun_maliyet);
    tableRow.sarfiyat = numToTrStr(apiRow.sarfiyat_ve_diger_masraflar);
    tableRow.verimlilikOrani = numToTrStr(apiRow.verimlilik_orani);
    if (apiRow.stok) {
      tableRow.urun = `${apiRow.stok.stok_kod} - ${apiRow.stok.stok_isim}`;
      tableRow.urunId = apiRow.stok.id;
      tableRow.urunIsim = apiRow.stok.stok_isim;
      tableRow.urunKod = apiRow.stok.stok_kod;
    }
    if (apiRow.kaynak_stok) {
      tableRow.kaynakUrun = `${apiRow.kaynak_stok.stok_kod} - ${apiRow.kaynak_stok.stok_isim}`;
      tableRow.kaynakUrunId = apiRow.kaynak_stok.id;
      tableRow.kaynakUrunIsim = apiRow.kaynak_stok.stok_isim;
      tableRow.kaynakUrunKod = apiRow.kaynak_stok.stok_kod;
    }
  }
  return tableArray;
};

export async function fetchMaliyetAnalizDetails(id, createNewTableObj) {
  const url = `maliyet/show?id=${id}`;
  const { data } = await factory.get(url);

  const values = processFetchedFormValues(data);
  const tableArray = processFetchedTableDetails(data, createNewTableObj);

  return {
    values,
    tableArray
  };
}

// * COPY =========================================

export function maliyetAnalizCopy(data) {
  const url = 'maliyet/copy';
  return factory.get(url, {
    params: {
      id: data
    }
  });
}
