<template>
  <div>
    <wo-table
      stripe
      :data="tableArray.list"
      hoverFlat
      noDataText=""
      class="minimalize-table-paddings"
    >
      <template slot="thead">
        <wo-th> Sıra </wo-th>
        <wo-th> Satır ID </wo-th>
        <wo-th> Stok </wo-th>
        <wo-th> Miktar </wo-th>
        <wo-th> Brüt Miktar </wo-th>
        <wo-th> Birim </wo-th>
        <wo-th>
          <div class="flex items-center gap-1">
            <span>Palet No </span>
            <i
              title="Otomatik Üret"
              class="fa fa-arrow-down cursor-pointer"
              aria-hidden="true"
              @click="generateKoliNo('paletNo')"
              v-if="
                tableArray.list.length === 1 && !generatedColumns['paletNo']
              "
            ></i>
          </div>
        </wo-th>
        <wo-th
          ><span> Koli No </span>
          <i
            title="Otomatik Üret"
            class="fa fa-arrow-down cursor-pointer"
            aria-hidden="true"
            @click="generateKoliNo('koliNo')"
            v-if="tableArray.list.length === 1 && !generatedColumns['koliNo']"
          ></i
        ></wo-th>
        <wo-th> Koli İçi Miktar </wo-th>
        <wo-th> Lot No </wo-th>
        <wo-th> Son Kullanım Tarih </wo-th>
        <wo-th> İşlemler </wo-th>
      </template>

      <template>
        <wo-tr :key="indextr" v-for="(satirData, indextr) in tableArray.list">
          <wo-td>
            <span
              class="w-full h-full inline-block"
              @click="handleRowPrint(indextr)"
            >
              {{ indextr + 1 }}
            </span>
          </wo-td>
          <wo-td>
            <span class="w-full h-full inline-block font-bold">{{
              satirData.id
            }}</span>
          </wo-td>
          <wo-td>
            <wo-quick-search
              :inputType="'quickSearch'"
              :idKey="indextr"
              v-model="satirData.stok"
              :queryParameters="satirData.stokQuery"
              @selected="item => handleQuickSearch({ item, index: indextr })"
              canReadGitnCode
              @gitnCodeSelected="data => gitnCodeSelected(indextr, data)"
              @blur="
                () => handleAutomateSaving(satirData.stok, 'stok', indextr)
              "
              :itemRenderer="
                item => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')
              "
            />
          </wo-td>

          <wo-td>
            <ValidationProvider
              name="Miktar"
              rules="notZero"
              v-slot="{ errors }"
            >
              <wo-float-input
                v-model="satirData.miktar"
                class="miktar-small"
                @blur="
                  () =>
                    handleAutomateSaving(satirData.miktar, 'miktar', indextr)
                "
                :error="errors"
              />
            </ValidationProvider>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.brutMiktar"
              class="miktar-small"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.brutMiktar,
                    'brutMiktar',
                    indextr
                  )
              "
            />
          </wo-td>
          <wo-td>
            <wo-select
              v-model="satirData.birim"
              :data="birimler"
              elementClasses="w-28"
              @blur="
                () => handleAutomateSaving(satirData.birim, 'birim', indextr)
              "
            >
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="window.mobile ? item.kod : item.isim"
                  :key="'birim' + item.id"
                />
              </template>
            </wo-select>
          </wo-td>

          <wo-td>
            <div class="flex items-center gap-1">
              <vs-input
                v-model="satirData.paletNo"
                class="wo-numeric-input w-28"
                @keypress="inputNumericConverter"
                @blur="
                  () =>
                    handleAutomateSaving(satirData.paletNo, 'paletNo', indextr)
                "
              />
            </div>
          </wo-td>

          <wo-td class="flex items-center gap-1">
            <vs-input
              v-model="satirData.koliNo"
              class="wo-numeric-input w-28"
              @keypress="inputNumericConverter"
              @blur="
                () => handleAutomateSaving(satirData.koliNo, 'koliNo', indextr)
              "
            />
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.koliIciMiktar"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.koliIciMiktar,
                    'koliIciMiktar',
                    indextr
                  )
              "
            />
          </wo-td>
          <wo-td>
            <vs-input
              autocomplete="off"
              v-model="satirData.lotNo"
              v-validate="'required'"
              name="İsim"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              @blur="
                () => handleAutomateSaving(satirData.lotNo, 'lotNo', indextr)
              "
            />
          </wo-td>
          <wo-td>
            <wo-date-picker
              v-model="satirData.sonKullanimTarih"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.sonKullanimTarih,
                    'sonKullanimTarih',
                    indextr
                  )
              "
            />
          </wo-td>
          <wo-td>
            <div class="flex flex-row space-x-4">
              <wo-button
                data-id="satir_sil"
                class="text-base px-4 py-2"
                @click="removeItem(indextr)"
                color="danger"
                tabindex="-1"
                :text="RemoveIcon"
              />

              <wo-button
                size="small"
                class="ml-1 text-base px-4 py-2"
                color="warning"
                tabindex="-1"
                @click="handleRowPrint(indextr)"
                :text="PrinterIcon"
              />
            </div>
          </wo-td>
        </wo-tr>
      </template>
    </wo-table>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { inputNumericConverter } from '@/helpers/NumberController';
import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';
import { factory } from '@/helpers/ApiController';
import { reactive } from '@vue/composition-api';

export default {
  props: {
    tableArray: Object,
    removeItem: Function, // call as removeSatir(index) click event
    birimler: Array,
    depo: [String, Number],
    tarih: String,
    sube: [String, Number]
  },

  setup(props, { emit }) {
    const handleQuickSearch = data => {
      emit('quickSearchEvent', data);
    };

    const emitGitnSelected = (indextr, data) => {
      emit('gitnCodeSelected', { indextr, data });
    };

    const debouncedGitnSelected = debounce(obj => {
      emitGitnSelected(obj.indextr, obj.data);
    }, 100);

    const gitnCodeSelected = (indextr, data) => {
      debouncedGitnSelected({ indextr, data });
    };

    const handleAutomateSaving = (data, column, indextr) => {
      emit('handleAutomateSaving', { data, column, indextr });
    };

    const handleRowPrint = indextr => {
      emit('handleRowPrint', indextr);
    };

    const generatedColumns = reactive({
      koliNo: false,
      paletNo: false
    });

    const generateKoliNo = column => {
      const keys = {
        koliNo: { endPoint: 'koli-number', param: 'koli_no' },
        paletNo: { endPoint: 'palet-number', param: 'palet_no' }
      };
      const endPoint = keys[column].endPoint;
      const satirData = props.tableArray.list[0];
      factory
        .get(`/modules/checklist/${endPoint}`, {
          params: {
            [keys[column].param]: satirData[column]
          }
        })
        .then(response => {
          const { data } = response;
          satirData[column] = data;
          generatedColumns[column] = true;
        });
    };

    const PrinterIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>`;

    const RemoveIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`;

    return {
      nameAndCodeWithLinkRenderer,
      inputNumericConverter,
      handleQuickSearch,
      gitnCodeSelected,
      handleAutomateSaving,
      handleRowPrint,
      PrinterIcon,
      RemoveIcon,
      window,
      generateKoliNo,
      generatedColumns
    };
  }
};
</script>

<style scoped>
@media (max-width: 420px) {
  .minimalize-table-paddings .wo-th,
  .minimalize-table-paddings .wo-td {
    padding: 5px;
  }
  .wo-numeric-input.miktar-small {
    width: 5rem !important;
  }
}
</style>
