<template>
    <div id="cost-table">
        <table>
            <tr v-for="(infoKey, index) in Object.keys(infoData)" :key="infoKey">
                <th colspan="3" style="text-align: left;">{{infoKey}}</th>
                <td colspan="4" style="text-align: left;">{{infoData[infoKey]}}</td>
                <img v-if="index === 0" width="200" height="220" :src="imageUrl">
            </tr>
        </table>
        <tr></tr>
        <table style="margin-top: 20px;">
            <tr>
                <th rowspan="2">{{ manufactureData.title }}</th>
                <th
                    v-for="manufactureKey in 
                    Object.keys(manufactureData).filter(item => item !== 'title')" :key="manufactureKey"
                >
                    {{ manufactureKey }}
                </th>
            </tr>
            <tr>
                <td 
                    v-for="manufactureKey in 
                    Object.keys(manufactureData).filter(item => item !== 'title')" :key="manufactureKey"
                    :style="`background-color: ${manufactureData[manufactureKey].color}`"
                >
                    {{ manufactureData[manufactureKey].value }}
                </td>
            </tr>
        </table>
        <tr></tr>
        <table style="margin-top: 20px;">
            <tr>
                <th></th>
                <th v-for="title in titles" :key="title">{{title}}</th>
                <th v-for="process in expenseProcesses" :key="process.isim">{{ process.isim }}</th>
            </tr>
            <div v-for="(stockId, index) in Object.keys(subSemiProducts)" :key="stockId">
                <tr v-for="rowTitle in rowTitles" :key="rowTitle">
                    <th>{{ rowTitle }}</th>
                    <td v-for="title in titles" :key="title">
                        {{ getSemiProductValues(stockId, rowTitle, title) }}
                    </td>
                    <td v-for="process in expenseProcesses" :key="process.id">
                        {{ getExpenseValues(rowTitle, process.id, index) }}
                    </td>
                </tr>
            </div>
        </table>
    </div>
</template>

<script>
import { getValueById } from '@/helpers/dataFilterHelper';
import { trStrToNum } from '@/helpers/NumberController';
import { computed, onMounted, onUpdated, reactive } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
export default {
    props: {
        stateKey: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {

        const { state, selectedColors, exchangeRates, machines, processes } = useModelPlanlamaState();

        const localState = computed(() => state[props.stateKey]);

        const designState = computed(() => state['design']);

        const imageUrl = computed(() => {
            const imageData = designState.value.image_urls[0];
            return `${window.location.origin}/${imageData.image_path}${imageData.image_name}`;
        });

        const variantAmount = computed(() => {
            let sum = 0;
            const variants = localState.value.info.modelVariants;
            for (const color in variants) {
                for (const size in variants[color]) {
                    if (size !== 'amount') {
                        sum += parseInt(variants[color][size]);
                    }
                }
            }
            return sum;
        });

        const titles = ["BİRİM GR", "İP BİRİM FİYATI", "GR FİYATI", "TL", "İPLİK FİYATI", "ÖRGÜ DAKİKA", "DAKİKA BİRİM FİYATI", "BİRİM FİYAT"];

        const expenseProcesses = computed(() => processes.value.filter(process => process.tip === "1"));

        const rowTitles = ["ZEMİN", "FİRE", "ARA İPİ"];

        const infoData = reactive({
                "TARİH": localState.value.info.date,
                'MÜŞTERİ NO': localState.value.info.mainContractorName,
                'RESİM KOD': '',
                'MODEL ADI': designState.value.model_name,
                'MODEL KODU': designState.value.model_code,
                'İPLİĞİ': '',
                'MAKİNE': designState.value.machine_id !== "0" ? getValueById(machines.value, parseInt(designState.value.machine_id), 'isim') : "",
                'ADET': variantAmount.value || 0,
                'RENK': selectedColors[props.stateKey].length,
        });

        const manufactureData = reactive({
            title: 'ÖRGÜ TEMİN',
            'M.DAK.SURESİ': {
                value: parseInt(designState.value.machine_minute) || "",
                color: 'white'
            },
            'ÜRÜN DAKİKA': {
                value: parseInt(designState.value.machine_produced) || "",
                color: 'white',
            },
            'BİRİM ÜRÜN AD': {
                value: parseInt(designState.value.machine_minute) / parseInt(designState.value.machine_produced) || "",
                color: 'white'
            },
            'MAKİNE AD': {
                value: parseInt(designState.value.total_machine) || 0,
                color: 'yellow',
            },
            'GÜNLÜK ÜRETİM': {
                value: parseInt(designState.value.total_machine) * parseInt(designState.value.machine_produced) || 0,
                color: 'white'
            },
            'GÜN SAYISI': {
                value: (variantAmount.value / parseInt(designState.value.total_machine) * parseInt(designState.value.machine_produced)) || 0,
                color: 'white'
            }
        });

        const image = computed(() => {
            const imageData = designState.value.image_urls[0];
            return `https://takipet.webofis.im/test/${imageData.image_path}${imageData.image_name}` || '';
        });

        const subSemiProducts = computed(() => {
            const stocks = [];
            localState.value.details.semiProducts.forEach(semiProduct => stocks.push(...semiProduct.semiProductDetails));
            const groupedStocks = {};
            stocks.forEach(stock => {
                if (!groupedStocks[stock.stockID]) {
                    groupedStocks[stock.stockID] = {
                        cost: parseFloat(stock.cost.replace('.', '').replace(',', '.')).toFixed(2),
                        unitCost: trStrToNum(stock.cost) / trStrToNum(stock.amount),
                        currency: stock.currency,
                        price: stock.price,
                    };
                } else {
                    groupedStocks[stock.stockID].cost = parseFloat(stock.cost.replace('.', '').replace(',', '.')) + parseFloat(groupedStocks[stock.stockID].cost);
                    groupedStocks[stock.stockID].unitCost += trStrToNum(stock.cost) / trStrToNum(stock.amount);
                    groupedStocks[stock.stockID].price += parseInt(stock.price);
                }
            });
            return groupedStocks;
        });

        const getSemiProductValues = (stockId, rowTitle, title) => {
            const commonValues = {
                "İP BİRİM FİYATI": parseFloat(parseFloat(subSemiProducts.value[stockId].unitCost).toFixed(2)),
                "GR FİYATI": designState.value.calculations.Net === 0 ? " " : parseFloat(subSemiProducts.value[stockId].unitCost / designState.value.calculations.Net),
                "TL": designState.value.calculations.Net === 0 ? " " :  (subSemiProducts.value[stockId].unitCost / parseInt(designState.value.calculations.Net) * 
                    parseFloat(exchangeRates.value[props.stateKey].find(item => item.kod === subSemiProducts.value[stockId].currency).kur)),
                "İPLİK FİYATI": parseFloat(parseFloat(subSemiProducts.value[stockId].price * 
                    parseFloat(exchangeRates.value[props.stateKey].find(item => item.kod === subSemiProducts.value[stockId].currency).kur)).toFixed(2)),
                "ÖRGÜ DAKİKA": parseInt(designState.value.machine_produced) || ""
            };
            const values = {
                "ZEMİN": {
                    "BİRİM GR": designState.value.calculations.Net,
                    ...commonValues
                },
                "FİRE": {
                    "BİRİM GR": designState.value.calculations['Makas Fire'],
                    ...commonValues
                },
                "ARA İPİ": {
                    "BİRİM GR": designState.value.calculations['Depodan Verilecek Ara İpi'],
                    ...commonValues
                }
            };
            return values[rowTitle][title] === 0 ? "" : values[rowTitle][title];
        };

        const getExpenseValues = (rowTitle, process, index) => {
            const expenses = localState.value.details.expenses.filter(expense => parseInt(expense.process) === process);
            const values = {
                "ZEMİN": expenses[index] ? expenses[index].cost : 0,
                "FİRE": designState.value.calculations['Makas Fire'],
                "ARA İPİ": designState.value.calculations['Depodan Verilecek Ara İpi'],
            };
            return values[rowTitle] === 0 ? "" : values[rowTitle];
        };

        onUpdated(() => {
            emit("costTableEvent", document.getElementById('cost-table').innerHTML);
        });

        onMounted(() => {
            emit("costTableEvent", document.getElementById('cost-table').innerHTML);
        });

        return {
            localState,
            infoData,
            image,
            manufactureData,
            subSemiProducts,
            titles,
            rowTitles,
            getSemiProductValues,
            getExpenseValues,
            expenseProcesses,
            imageUrl
        };
    }
};

</script>