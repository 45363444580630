<template>
  <vs-prompt
    buttons-hidden
    :active="active"
    title="Sepetler"
    class="wide-dialog-500 one-step-behind-dialog"
    @close="$emit('update:active', false)"
  >
    <div class="space-y-4 p-1">
      <WOBaskets noPadding class="pb-4" @updateBasket="addSelectedBasket" />
      <WOPrevalentBaskets
        noPadding
        :prevalentIDs="prevalentIDsProcessed"
        class="pb-4"
        @updateBasket="addSelectedPrevalentBasket"
      />
      <WOCostBaskets noPadding @updateBasket="addSelectedExpenseBasket" />
    </div>
  </vs-prompt>
</template>

<script>
import { computed } from '@vue/composition-api';

import useCompaniesSendOffer from '../consumables/useCompaniesSendOffer.js';

import WOBaskets from '@/components/WO/Baskets/WOBaskets';
import WOPrevalentBaskets from '@/components/WO/Baskets/WOPrevalentBaskets';
import WOCostBaskets from '@/components/WO/Baskets/WOCostBaskets';

export default {
  components: {
    WOBaskets,
    WOPrevalentBaskets,
    WOCostBaskets
  },
  props: {
    active: Boolean
  },
  setup(props, { emit }) {
    const addSelectedBasket = ({ id, name }) => {
      emit('selectedBasketID', { id, name });
    };

    // #region Cari
    const { selectedPrevalentsIDs } = useCompaniesSendOffer();

    const prevalentIDsProcessed = computed(() => {
      return selectedPrevalentsIDs.value
        .join(',')
        .replace(/(,{2,})/g, ',')
        .replace(/(^,)|(,$)/g, '');
    });

    const addSelectedPrevalentBasket = ({ id, name, title }) => {
      emit('selectedBasketID', { id, name, title });
    };

    // #endregion

    // #region Masraf Sepeti
    const addSelectedExpenseBasket = ({ id, name }) => {
      emit('selectedExpenseBasket', { id, name });
    };
    // #endregion

    return {
      addSelectedBasket,

      addSelectedPrevalentBasket,
      prevalentIDsProcessed,

      addSelectedExpenseBasket
    };
  }
};
</script>
