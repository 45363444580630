<template>
  <div v-if="active" ref="con" class="con-vs-dialog dialog wide-dialog-90vw">
    <div class="vs-dialog-dark"  @click="() => close()" /> 
    <div ref="dialogx" class="vs-dialog relative">
      <div class="imgDiv">
        <img :style="'width: ' + imageWidth + 'px; height: ' + imageHeight + 'px'" :src="image" />
      </div>
    </div>
  </div>
</template>

<script>
// $('')
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    const that = this;
    document.addEventListener('keydown', function(e) {
      if (e.keyCode === 27) {
        that.close();
      }
    });
  },
  updated() {
    const image = new Image();
    image.src = this.image;
  },
  computed: {
    imageWidth () {
      const image = new Image();
      image.src = this.image;
      let width = 0;
      if (image.width > image.height) {
        width = image.width > 1500 ? 1500 : image.width > 1000 ? 1000 : image.width;
      } else {
        width = image.height > 1500 ? 700 / (image.height / image.width) : image.height > 1000 ? 500 / (image.height / image.width) : image.width;
      }
      if (window.matchMedia('(max-width: 767px)').matches) {
        width = 250;
      }
      return width;
      
    },
    imageHeight ()  {
      const image = new Image();
      image.src = this.image;
      let height =  0;
      if (image.width > image.height) {
        height = image.width > 1500 ? 700 : image.width > 1000 ? 500 : image.height;
      } else {
        height = image.height > 1500 ? 700 : image.height > 1000 ? 500 : image.height;
      }
      if (window.matchMedia('(max-width: 767px)').matches) {
        height = window.screen.height / (864 / height);
      }
      return height;      
    },
  },
  setup(_, { emit }) {
    const close = () => {
      emit("active", false);
    };   
    return {
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
.vs-dialog{  
  width: auto;
  height: auto;
  // margin-top: 90px;
}
.imgDiv {
  display: flex;
  width: 100%;
  height: auto;
}
// img {
//   width: 100%;
//   height: 100%;
// }
</style>
