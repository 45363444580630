<template>
  <vs-prompt
    @close="$emit('update:active', false)"
    buttons-hidden
    :active="active"
    class="wide-dialog-800 one-step-behind-dialog"
    title="Başvuru Durumları"
  >
    <div class="space-y-4 p-1">
      <wo-table>
        <template #thead>
          <wo-th v-for="col in tableColumns" :key="`header-${col.valueKey}`">
            {{ col.title }}
          </wo-th>
        </template>
        <template>
          <wo-tr v-for="(item, index) in statusList" :key="`statuses-${index}`">
            <wo-td
              v-for="col in tableColumns"
              :key="`data-${index}-${col.valueKey}`"
            >
              <template v-if="col.valueKey !== ''">
                <base-input
                  v-if="col.valueKey === 'name' && editedIndex === index"
                  v-model="editedStatusVal"
                />
                <span v-else>
                  {{ item[col.valueKey] }}
                </span>
              </template>
              <template v-else>
                <div
                  v-if="editedIndex === index"
                  class="flex space-x-4 justify-center"
                >
                  <vx-tooltip text="Kaydet">
                    <feather-icon
                      icon="CheckIcon"
                      class="icon-style text-success"
                      @click="saveEditedStatus"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="İptal">
                    <feather-icon
                      icon="XIcon"
                      class="icon-style text-danger"
                      @click="closeEditedStatus"
                    />
                  </vx-tooltip>
                </div>
                <div class="flex space-x-4 justify-center" v-else>
                  <vx-tooltip text="Düzenle">
                    <feather-icon
                      icon="EditIcon"
                      class="icon-style"
                      @click="() => editStatus(index)"
                    />
                  </vx-tooltip>
                </div>
              </template>
            </wo-td>
          </wo-tr>
          <wo-tr v-if="isNewLineOpen">
            <wo-td
              v-for="col in tableColumns"
              :key="`data-new-${col.valueKey}`"
            >
              <base-input
                v-if="col.valueKey === 'name'"
                v-model="newStatusVal"
              />
              <div
                class="flex space-x-4 justify-center"
                v-if="col.valueKey === ''"
              >
                <vx-tooltip text="Kaydet">
                  <feather-icon
                    icon="CheckIcon"
                    class="icon-style text-success"
                    @click="saveNewStatus"
                  />
                </vx-tooltip>
                <vx-tooltip text="İptal">
                  <feather-icon
                    icon="XIcon"
                    class="icon-style text-danger"
                    @click="closeNewLine"
                  />
                </vx-tooltip>
              </div>
            </wo-td>
          </wo-tr>
        </template>
      </wo-table>
      <wo-button text="Yeni Ekle" data-id="yeni" @click="openNewLine" />
    </div>
  </vs-prompt>
</template>

<script>
import useCompany from '@/consumables/useCompany';
import { reactive, ref } from '@vue/composition-api';
import {
  createStatus,
  updateStatus
} from '../helpers/jobApplicationAPIService';
import { useJobApplicationState } from '../composables/useJobApplicationState';

export default {
  props: {
    active: Boolean
  },
  setup() {
    const tableColumns = [
      { title: 'Durum', valueKey: 'name' },
      { title: 'Aktif', valueKey: 'isActive' },
      { title: 'Eklenme Tarihi', valueKey: 'createdAt' },
      { title: 'Ekleyen', valueKey: 'createdBy' },
      { title: 'Güncellenme Tarihi', valueKey: 'updatedAt' },
      { title: 'Güncelleyen', valueKey: 'updatedBy' },
      { title: 'İşlemler', valueKey: '' }
    ];

    const company = useCompany();

    const { statusList, getStatusList } = useJobApplicationState();

    const isNewLineOpen = ref(false);

    const newStatusVal = ref('');

    const closeNewLine = () => {
      isNewLineOpen.value = false;
      newStatusVal.value = '';
    };

    const openNewLine = () => {
      isNewLineOpen.value = true;
    };

    const saveNewStatus = async () => {
      await createStatus({
        name: newStatusVal.value,
        companyID: company.value.id
      });
      closeNewLine();
      await getStatusList();
    };

    const editedIndex = ref(-1);
    const editedStatusVal = ref('');

    const editStatus = index => {
      editedStatusVal.value = statusList.value[index].name;
      editedIndex.value = index;
    };

    const closeEditedStatus = () => {
      editedIndex.value = -1;
      editedStatusVal.value = '';
    };

    const saveEditedStatus = async () => {
      const selectedStatus = statusList.value[editedIndex.value];
      await updateStatus({
        id: selectedStatus.id,
        name: editedStatusVal.value
      });
      closeEditedStatus();
      await getStatusList();
    };

    const state = reactive({
      id: 0,
      name: '',
      companyID: company.value.id,
      isActive: 1
    });

    return {
      tableColumns,
      statusList,
      isNewLineOpen,
      newStatusVal,
      closeNewLine,
      openNewLine,
      saveNewStatus,
      editedIndex,
      editedStatusVal,
      editStatus,
      closeEditedStatus,
      saveEditedStatus,
      state
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-style {
  @apply cursor-pointer w-5 h-5;
}
</style>
