<template>
  <div class="relative">
    <feather-icon
      v-if="imageUrl !== 'https://via.placeholder.com/160x250'"
      icon="XIcon"
      class="icon-style"
      @click="clickHandler"
    />
    <img :src="imageUrl" v-on="$listeners" />
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    clickHandler: Function,
  },
};
</script>

<style lang="scss" scoped>
  img {
    width: 160px;
    height: 250px;
  }
.icon-style {
  position: absolute !important;
  top: 0;
  right: 0;
  @apply w-6 h-6 text-danger transition-all rounded-full hover:bg-red-900;
}
</style>
