<template>
  <div class="form-elements-doubled responsive-margin-md item-align-md">
    <slot name="firstBefore" />
    <label
      v-if="$slots.firstLabel"
      class="align-labels"
      :class="calculatedLabelSize"
    >
      <slot name="firstLabel" />
    </label>
    <div v-if="$slots.firstElement" class="w-60">
      <slot name="firstElement" />
    </div>
    <slot name="firstAfter" />

    <slot name="secondBefore" />
    <label
      v-if="$slots.secondLabel"
      class="align-labels"
      :class="calculatedLabelSize"
    >
      <slot name="secondLabel" />
    </label>
    <div v-if="$slots.secondElement" class="w-60">
      <slot name="secondElement" />
    </div>
    <slot name="secondAfter" />
  </div>
</template>

<script>
export default {
  name: 'WODoubleForm',
  props: {
    labelSize: {
      validator(value) {
        return ['sm', 'md', 'lg'].indexOf(value) !== -1;
      },
      default: 'lg'
    }
  },
  computed: {
    calculatedLabelSize() {
      const obj = {
        sm: 'w-20',
        md: 'w-32',
        lg: 'w-52'
      };
      return obj[this.labelSize];
    }
  }
};
</script>

<style lang="scss" scoped>
.form-elements-doubled {
  @apply flex flex-col md:flex-row;
}

.align-labels {
  @apply font-medium;
}

.item-align-lg {
  @apply items-start lg:items-center;
}
.item-align-md {
  @apply items-start md:items-center;
}
</style>
