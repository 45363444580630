import './public-path';

import Vue from 'vue';

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

import App from './App.vue';

import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css';
import 'vuesax/dist/vuesax.css'; // Vuesax

// import store from '@/store/store';
import router from '@/router';

// import IdleVue from 'idle-vue';

// const eventsHub = new Vue();

// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   idleTime: 1200000
// });

// #region styling

import 'vue-multiselect/dist/vue-multiselect.min.css';

// Theme Configurations
import '@/../themeConfig.js';

// Globally Registered Components
import '@/globalComponents.js';

// Styles: SCSS
import '@/assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

//Material Icons
import 'font-awesome/css/font-awesome.min.css';

// Feather font icon
require('@/assets/css/iconfont.css');
import '@hokify/vuejs-datepicker/dist/vuejs-datepicker.css';

import vMultiselectListbox from 'vue-multiselect-listbox';

Vue.component('v-multiselect-listbox', vMultiselectListbox);
// #endregion

// eslint-disable-next-line

// import shortkeys from '@/helpers/ShortcutsController';

Vue.use(Vuesax);

import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);


// #region VEEVALIDATE

import messages from 'vee-validate/dist/locale/tr';
import VeeValidate, { Validator } from 'vee-validate';

Validator.localize('tr', messages);
Validator.extend('notZero', {
  getMessage: field => `${field} 0 olmamalı.`,
  validate: val => {
    return val !== 0 && val !== '0,00';
  }
});
Validator.extend('percentage', {
  getMessage: field => `${field} 0 olmamalı.`,
  validate: val => {
    return val <= 100 || val >= 0;
  }
});
Vue.use(VeeValidate, { locale: 'tr', fieldsBagName: 'veeFields' });
// #endregion

Vue.config.productionTip = false;

// ! Global Variables
Vue.prototype.$baseURL = window.woBaseUrl
  ? window.woBaseUrl.slice(0, -3)
  : window.location.origin;


Vue.prototype.$window = window;

const vm = new Vue({
  router,
  // store,
  render: h => h(App)
});

vm.$mount('#app');

export default vm;
