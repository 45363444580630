<template>
  <div class="space-y-4">
    <MMAddressTable :source="source" />

    <wo-button data-id="yeni" @click="openNewForm" text="Yeni Adres" />

    <transition name="fade">
      <MMAddressesForm
        v-if="isFormOpen"
        :source="source"
        @closeForm="closeForm"
        @refreshList="refreshList"
      />
    </transition>
  </div>
</template>

<script>
import MMAddressTable from './MMAddressesTable.vue';
import MMAddressesForm from './MMAddressesForm.vue';
import { ref, watch } from '@vue/composition-api';
import { useMMAddressesState } from './useMMAddressesState';
import { fetchAddressList } from './mmAddressApi';

export default {
  name: 'MMAddress',
  components: {
    MMAddressTable,
    MMAddressesForm
  },
  props: {
    source: String
  },
  setup(props, { root }) {
    const {
      editedAddress,
      addressList,
      createEmptyState,
      addressFormState
    } = useMMAddressesState();

    const getAddressList = async () => {
      addressList.value = await fetchAddressList(
        props.source,
        root.$route.params.id
      );
      console.log(addressList.value);
    };

    getAddressList();

    const refreshList = () => {
      const dbProcessFinishWaitMs = 50;
      setTimeout(() => {
        getAddressList();
      }, dbProcessFinishWaitMs);
    };

    const resetState = () => {
      Object.assign(addressFormState, createEmptyState());
    };

    const isFormOpen = ref(false);

    const closeForm = () => {
      isFormOpen.value = false;
      resetState();
    };

    const openForm = () => {
      if (isFormOpen.value) {
        closeForm();
      }
      root.$nextTick(() => {
        isFormOpen.value = true;
      });
    };

    const openNewForm = () => {
      editedAddress.value = 0;
      openForm();
    };

    watch(editedAddress, () => {
      if (editedAddress.value > 0) {
        openForm();
      }
    });

    return {
      isFormOpen,
      closeForm,
      openNewForm,
      refreshList
    };
  }
};
</script>

<style lang="scss" scoped></style>
