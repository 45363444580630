<template>
  <div class="w-11/12 p-4 m-auto shadow-md">
    <CariFormSectionHeader class="mb-4 y-centered-flex justify-between">
      {{ title[formType] }}
      <wo-button color="danger" @click="closeAgreement" text="X" />
    </CariFormSectionHeader>
    <form
      class="space-y-4"
      :class="{ 'pointer-events-none': formType === 'examine' }"
    >
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Sözleşme Adı </template>
        <template #firstElement>
          <base-input v-model="state.name" />
        </template>
        <template #secondLabel> Sözleşmeye Katılanlar </template>
        <template #secondElement>
          <wo-textarea v-model="state.participants" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Sözleşme Tarihi </template>
        <template #firstElement>
          <wo-date-picker v-model="state.agreementDate" />
        </template>
        <template #secondLabel> Başlangıç Tarihi </template>
        <template #secondElement>
          <wo-date-picker v-model="state.startDate" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Bitiş Tarihi </template>
        <template #firstElement>
          <wo-date-picker v-model="state.endDate" />
        </template>
        <template #secondLabel> Yetkili İsim </template>
        <template #secondElement>
          <base-input v-model="state.authorizedName" />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Üretici Ünvanı </template>
        <template #firstElement>
          <base-input v-model="state.producerTitle" />
        </template>
        <template #secondLabel> Yetkili Telefon </template>
        <template #secondElement>
          <wo-tel-input v-model="state.authorizedPhone" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Üretici Adresi </template>
        <template #firstElement>
          <base-input v-model="state.producerAddress" />
        </template>
        <template #secondLabel> Yetkili E-Mail </template>
        <template #secondElement>
          <base-input v-model="state.authorizedEmail" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Onay Durumu </template>
        <template #firstElement>
          <div class="flex space-x-4">
            <vs-radio
              v-model="state.approvalStatus"
              vs-name="approval-status"
              vs-value="1"
            >
              Teklif Aşamasında
            </vs-radio>
            <vs-radio
              v-model="state.approvalStatus"
              vs-name="approval-status"
              vs-value="2"
            >
              Onaylı
            </vs-radio>
            <vs-radio
              v-model="state.approvalStatus"
              vs-name="approval-status"
              vs-value="3"
            >
              Kapalı
            </vs-radio>
          </div>
        </template>
      </wo-double-form>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4">
        Sözleşmeye Dahil Ürün Grupları
      </CariFormSectionHeader>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Ürün Grupları </template>
        <template #firstElement>
          <wo-quick-search
            queryParameters="?tablo=stok_altgruplar&q="
            @selected="handleProductGroupsQuickSearch"
            :itemRenderer="nameAndCodeRenderer"
          />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <div></div>
        </template>
        <template #firstElement>
          <CariFormDeletableList
            :data="state.productGroups"
            :rootObj="state"
            objKey="productGroups"
          />
        </template>
      </wo-double-form>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4">
        Ciro Primi İçin Üretici / Marka / Model Tanımlamaları
      </CariFormSectionHeader>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Üreticiler </template>
        <template #firstElement>
          <wo-quick-search
            queryParameters="?tablo=stok_ureticiler&q="
            @selected="handleProducersQuickSearch"
            :itemRenderer="nameAndCodeRenderer"
          />
        </template>
        <template #secondLabel> Marka </template>
        <template #secondElement>
          <wo-quick-search
            :queryParameters="`?tablo=stok_markalar&uretici=${producersString}&q=`"
            @selected="handleBrandQuickSearch"
            :itemRenderer="nameAndCodeRenderer"
          />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize" class="items-start">
        <template #firstLabel>
          <div></div>
        </template>
        <template #firstElement>
          <CariFormDeletableList
            :data="state.producers"
            :rootObj="state"
            objKey="producers"
          />
        </template>
        <template #secondLabel>
          <div></div>
        </template>
        <template #secondElement>
          <CariFormDeletableList
            :data="state.brand"
            :rootObj="state"
            objKey="brand"
          />
        </template>
      </wo-double-form>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4">
        İskonto Oranları
      </CariFormSectionHeader>

      <CariFormTabAgreementsFormDiscount
        isRed
        labelPrefix="Normal"
        objKey="normal"
      />
      <CariFormTabAgreementsFormDiscount
        labelPrefix="Özel"
        objKey="special"
        :isRed="false"
      />
      <CariFormTabAgreementsFormDiscount
        labelPrefix="Peşin"
        objKey="advance"
        :isRed="false"
      />

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4">
        Açılış Uygulamaları (Yeni Şube)
      </CariFormSectionHeader>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Yeni Şube Açılış İskonto </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-float-input
              class="new-branch-numeric-input-width"
              v-model="state.newBranchOpeningDiscount"
            />
            <span class="new-branch-span-margin"> % </span>
            <base-input
              placeholder="Aciklama"
              v-model="state.newBranchOpeningExplanation"
            />
          </div>
        </template>
        <template #secondLabel> Açılış İskonto Süresi </template>
        <template #secondElement>
          <div class="y-centered-flex">
            <wo-int-input
              class="new-branch-numeric-input-width"
              v-model="state.newBranchOpeningTime"
            />
            <span class="new-branch-span-margin"> gün </span>
            <base-input
              placeholder="Aciklama"
              v-model="state.newBranchOpeningTimeExplanation"
            />
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Özel/Ek Vade </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-int-input
              class="new-branch-numeric-input-width"
              v-model="state.specialExpiryTime"
            />
            <span class="new-branch-span-margin"> gün </span>
            <base-input
              placeholder="Aciklama"
              v-model="state.specialExpiryTimeExplanation"
            />
          </div>
        </template>
        <template #secondLabel> Özel Peşin İskonto </template>
        <template #secondElement>
          <div class="y-centered-flex">
            <wo-float-input
              class="new-branch-numeric-input-width"
              v-model="state.specialAdvanceDiscount"
            />
            <span class="new-branch-span-margin"> % </span>
            <base-input
              placeholder="Aciklama"
              v-model="state.specialAdvanceDiscountExplanation"
            />
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Peşin İskonto Vadesi </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-int-input
              class="new-branch-numeric-input-width"
              v-model="state.newBranchAdvanceDiscountExpiry"
            />
            <span class="new-branch-span-margin"> gün </span>
            <base-input
              placeholder="Aciklama"
              v-model="state.newBranchAdvanceDiscountExpiryExplanation"
            />
          </div>
        </template>
        <template #secondLabel>
          <span class="text-danger"> Yeni Şube Açılış Katılım Bedeli </span>
        </template>
        <template #secondElement>
          <div class="y-centered-flex">
            <wo-float-input
              class="new-branch-numeric-input-width"
              v-model="state.newBranchOpeningParticipationCost"
            />
            <span class="new-branch-span-margin"> TL </span>
            <base-input
              placeholder="Aciklama"
              v-model="state.newBranchOpeningParticipationCostExplanation"
            />
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Yıl Dönümü Etkinlik Bedeli </template>
        <template #firstElement>
          <wo-float-input v-model="state.anniversaryEventCost" />
        </template>
      </wo-double-form>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4">
        Ciro-Aktivite Primi
      </CariFormSectionHeader>

      <div class="overflow-y-auto">
        <table class="turnover-activity-premium">
          <tr>
            <th colspan="1"></th>
            <th colspan="3" class="text-center">Aylık</th>
            <th colspan="3" class="text-center">3 Aylık</th>
            <th colspan="3" class="text-center">Yıllık</th>
          </tr>
          <tr>
            <th colspan="1"></th>
            <th colspan="1" class="text-center">Koşul</th>
            <th colspan="1" class="text-center">Açıklama</th>
            <th colspan="1" class="text-center">Oran</th>
            <th colspan="1" class="text-center">Koşul</th>
            <th colspan="1" class="text-center">Açıklama</th>
            <th colspan="1" class="text-center">Oran</th>
            <th colspan="1" class="text-center">Koşul</th>
            <th colspan="1" class="text-center">Açıklama</th>
            <th colspan="1" class="text-center">Oran</th>
          </tr>
          <tr
            v-for="(item, idx) in state.turnoverPremiumArray"
            :key="`turnover-premium-row-${idx}`"
          >
            <td colspan="1">
              <div class="text-danger whitespace-nowrap">
                Ciro Primi
                <span
                  v-if="idx === 0"
                  class="hover:underline hover:text-danger text-red-700 cursor-pointer"
                  @click="addTurnoverPremiumRow"
                >
                  (+)
                </span>
                <span
                  v-else
                  class="hover:underline hover:text-danger text-red-700 cursor-pointer"
                  @click="() => removeTurnoverPremiumRow(idx)"
                >
                  (-)
                </span>
              </div>
            </td>
            <td colspan="1">
              <wo-float-input decimal="2" v-model="item.monthlyCondition" />
            </td>
            <td colspan="1">
              <base-input class="w-24" v-model="item.monthlyExplanation" />
            </td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  maxlength="5"
                  v-model="item.monthlyRate"
                />
                <span class="text-danger"> % </span>
              </div>
            </td>
            <td colspan="1">
              <wo-float-input
                decimal="2"
              v-model="item.threeMonthlyCondition" />
            </td>
            <td colspan="1">
              <base-input class="w-24" v-model="item.threeMonthlyExplanation" />
            </td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  maxlength="5"
                  v-model="item.threeMonthlyRate"
                />
                <span class="text-danger"> % </span>
              </div>
            </td>
            <td colspan="1">
              <wo-float-input
                decimal="2"
              v-model="item.annuallyCondition" />
            </td>
            <td colspan="1">
              <base-input class="w-24" v-model="item.annuallyExplanation" />
            </td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  maxlength="5"
                  v-model="item.annuallyRate"
                />
                <span class="text-danger"> % </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="1">Aktivite Primi</td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.activityPremiumMonthly"
                />
                <span>%</span>
              </div>
            </td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.activityPremiumThreeMonthly"
                />
                <span>%</span>
              </div>
            </td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.activityPremiumAnnually"
                />
                <span>%</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="1">Lojistik Primi</td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.logisticPremiumMonthly"
                />
                <span>%</span>
              </div>
            </td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.logisticPremiumThreeMonthly"
                />
                <span>%</span>
              </div>
            </td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.logisticPremiumAnnually"
                />
                <span>%</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="1">Fire-Kayıp Primi</td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.lostPremiumMonthly"
                />
                <span>%</span>
              </div>
            </td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.lostPremiumThreeMonthly"
                />
                <span>%</span>
              </div>
            </td>
            <td colspan="2"></td>
            <td colspan="1">
              <div class="y-centered-flex">
                <wo-float-input
                  decimal="2"
                  v-model="state.lostPremiumAnnually"
                />
                <span>%</span>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <CariFormQuadrupleFormWrapper>
        <wo-double-form :labelSize="labelSize">
          <template #firstLabel>İadeler</template>
          <template #firstElement>
            <div class="y-centered-flex space-x-2">
              <vs-radio
                v-model="state.premiumRefund"
                vs-name="premium-refund"
                vs-value="1"
              >
                Dikkate Alınsın
              </vs-radio>
              <vs-radio
                v-model="state.premiumRefund"
                vs-name="premium-refund"
                vs-value="0"
              >
                Dikkate Alınmasın
              </vs-radio>
            </div>
          </template>
          <template #secondLabel>Fiyat Farkları</template>
          <template #secondElement>
            <div class="y-centered-flex space-x-2">
              <vs-radio
                v-model="state.premiumPriceDifference"
                vs-name="premium-price-difference"
                vs-value="1"
              >
                Dikkate Alınsın
              </vs-radio>
              <vs-radio
                v-model="state.premiumPriceDifference"
                vs-name="premium-price-difference"
                vs-value="0"
              >
                Dikkate Alınmasın
              </vs-radio>
            </div>
          </template>
        </wo-double-form>

        <wo-double-form :labelSize="labelSize">
          <template #firstLabel>Farklı KDV Oranları</template>
          <template #firstElement>
            <div class="y-centered-flex space-x-2">
              <vs-radio
                v-model="state.premiumDifferentVatRates"
                vs-name="premium-different-vat-rates"
                vs-value="1"
              >
                Dikkate Alınsın
              </vs-radio>
              <vs-radio
                v-model="state.premiumDifferentVatRates"
                vs-name="premium-different-vat-rates"
                vs-value="0"
              >
                Dikkate Alınmasın
              </vs-radio>
            </div>
          </template>
        </wo-double-form>
      </CariFormQuadrupleFormWrapper>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4"> Promosyonlar </CariFormSectionHeader>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="text-danger"> İnsert Bedeli </span>
        </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-int-input
              class="promotions-small-input"
              v-model="state.insertCostPiece"
            />
            <span class="whitespace-nowrap promotions-span-margin">
              adet X
            </span>
            <wo-float-input v-model="state.insertCostPrice" />
            <span class="promotions-span-margin"> TL </span>
          </div>
        </template>
        <template #secondLabel>
          <span class="text-danger"> Ek Yerleşim Bedelleri </span>
        </template>
        <template #secondElement>
          <div class="y-centered-flex">
            <wo-int-input v-model="state.additionalSettlementPrice" />
            <span class="promotions-span-margin mr-2"> TL </span>
            <wo-int-input
              class="promotions-small-input"
              v-model="state.additionalSettlementPiece"
            />
            <span class="whitespace-nowrap promotions-span-margin">
              adet X
            </span>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> İnsert İskonto </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-float-input
              v-model="state.insertDiscount"
              class="w-input"
              decimal="2"
            />
            <span class="promotions-span-margin"> % </span>
          </div>
        </template>
        <template #secondLabel> Mal Fazlası </template>
        <template #secondElement>
          <div class="y-centered-flex">
            <wo-float-input v-model="state.promotionSurplus" class="w-input" />
            <span class="promotions-span-margin"> TL </span>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="text-danger"> Yıllk Sözleşme Bedeli </span>
        </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-float-input
              v-model="state.annualAgreementCostInstallment"
              class="w-20"
            />
            <span class="promotions-span-margin"> X </span>
            <wo-float-input v-model="state.annualAgreementCost" />
          </div>
        </template>
        <template #secondLabel> Açıklama </template>
        <template #secondElement>
          <base-input v-model="state.annualAgreementCostExplanation" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Açılış yıldonümü etkinlikleri</template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-float-input
              class="w-input"
              v-model="state.openingAnniversaryEventPromotion"
            />
            <span class="promotions-span-margin"> TL </span>
          </div>
        </template>
        <template #secondLabel> Radyo-TV-İnternet Reklamı </template>
        <template #secondElement>
          <div class="y-centered-flex">
            <wo-float-input
              class="w-input"
              v-model="state.radioTvInternetAdPromotion"
            />
            <span class="promotions-span-margin"> TL </span>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Açıklama</template>
        <template #firstElement>
          <wo-textarea v-model="state.promotionsExplanation" />
        </template>
      </wo-double-form>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4"> Teslimat </CariFormSectionHeader>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Teslim Süresi </template>
        <template #firstElement>
          <div class="y-centered-flex">
            <wo-int-input v-model="state.deliveryTime" />
            <span class="ml-1"> gün </span>
          </div>
        </template>
        <template #secondLabel> İade Durumu </template>
        <template #secondElement>
          <div class="y-centered-flex space-x-4">
            <vs-radio
              v-model="state.rebateSituation"
              vs-name="rebate-situation"
              vs-value="1"
            >
              Var
            </vs-radio>
            <vs-radio
              v-model="state.rebateSituation"
              vs-name="rebate-situation"
              vs-value="0"
            >
              Yok
            </vs-radio>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Fiyat Listesi</template>
        <template #firstElement>
          <base-input v-model="state.priceList" maxlength="100" />
        </template>
        <template #secondLabel>Fiyat Geçiş Op.</template>
        <template #secondElement>
          <base-input v-model="state.priceTransition" maxlength="100" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Yıllık Hedef Planı</template>
        <template #firstElement>
          <base-input v-model="state.annualGoalPlan" />
        </template>
        <template #secondLabel>Notlar</template>
        <template #secondElement>
          <base-input v-model="state.deliveryNotes" />
        </template>
      </wo-double-form>

      <CariFormSectionDivider />

      <CariFormSectionHeader class="mb-4">
        Sözleşme Detayları
      </CariFormSectionHeader>

      <div class="y-centered-flex space-x-4">
        <wo-select
          v-model="state.agreementDetailsSelect"
          :data="agreementTextData"
        >
          <template #defaultValue>
            <wo-select-item itemValue="" text="-Seçiniz-" />
          </template>
          <template v-slot:default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.isim"
              :key="`text-${item.id}`"
            />
          </template>
        </wo-select>
        <wo-button text=".." @click="handleAgreementTextMiniModule" />
      </div>

      <wo-textarea
        class="w-full"
        readonly
        v-model="state.agreementDetailsList"
      />

      <CariFormSectionDivider />

      <div class="flex flex-col md:flex-row responsive-margin-md">
        <wo-button
          v-if="formType !== 'examine'"
          text="Kaydet"
          color="primary"
          @click="saveAgreement"
        />
        <wo-button
          v-if="formType !== 'examine'"
          text="Vazgeç"
          color="danger"
          @click="closeAgreement"
        />
        <wo-button
          class="do-not-disable pointer-events-auto"
          text="Ciro Prim Aktivasyonları"
          target="_blank"
          :href="`${$baseURL}/?s=satinalma_ciro_primleri&goster=1&cari_id=${$route.params.id}`"
        />
        <wo-button
          class="do-not-disable pointer-events-auto"
          text="Promosyon Aktivasyonları"
          target="_blank"
          :href="`${$baseURL}/?s=satinalma_hizmet_satis&goster=1&tip=saglayici&kayit_id=${$route.params.id}`"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from '@vue/composition-api';

import usePrevalentState from '../../consumables/usePrevalentState';
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import CariFormDeletableList from '../common/CariFormDeletableList.vue';
import CariFormTabAgreementsFormDiscount from './CariFormTabAgreementsFormDiscount.vue';
import { errorDialog } from '@/helpers/errorDialogs';

import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode.js';
import {
  hydrateFromClient,
  hydrateFromServer
} from '../../helpers/hydrateAgreements';

import {
  getAgreementDetails,
  getAgreementTexts,
  saveAgreement as saveApiAgreement
} from '../../helpers/ApiFactory.js';
import useCompany from '@/consumables/useCompany';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

export default {
  components: {
    CariFormSectionHeader,
    CariFormSectionDivider,
    CariFormDeletableList,
    CariFormTabAgreementsFormDiscount,
    CariFormQuadrupleFormWrapper
  },
  props: {
    formType: {
      type: String,
      validator: val => {
        return ['new', 'edit', 'copy', 'examine'].includes(val);
      }
    },
    agreementID: Number
  },
  setup(props, { emit, root }) {
    const {
      labelSize,
      handleDeletableListQuickSearch,
      agreementState: state
    } = usePrevalentState();

    const title = {
      new: 'YENİ SÖZLEŞME',
      edit: 'SÖZLEŞME DÜZENLE',
      copy: 'YENİ SÖZLEŞME',
      examine: 'SÖZLEŞME İNCELE'
    };

    const closeAgreement = () => {
      emit('closeAgreement');
    };

    const saveAgreement = () => {
      if (state.name === '') {
        errorDialog('Lütfen sözleşme adını yazınız.');
        return false;
      }

      if (state.agreementDate === '') {
        errorDialog('Lütfen sözleşme tarihini yazınız.');
        return false;
      }

      if (state.startDate === '') {
        errorDialog('Lütfen başlangıç tarihini yazınız.');
        return false;
      }

      if (state.endDate === '') {
        errorDialog('Lütfen bitiş tarihini yazınız.');
        return false;
      }

      if (state.agreementDetailsSelect === '') {
        errorDialog('Lütfen sözleşme metni seçiniz.');
        return false;
      }

      // ! API KAYDET
      if (props.formType === 'edit') {
        state.formID = props.agreementID;
      }

      if (props.formType === 'copy') {
        state.formID = '';
      }

      const reqBody = hydrateFromClient(state, root.$route.params.id);
      saveApiAgreement(reqBody).then(response => {
        emit('updateAgreementsList', response);
        closeAgreement();
      });
    };

    const handleProductGroupsQuickSearch = item => {
      handleDeletableListQuickSearch(state, 'productGroups', item);
    };

    const handleProducersQuickSearch = item => {
      handleDeletableListQuickSearch(state, 'producers', item);
    };

    const handleBrandQuickSearch = item => {
      handleDeletableListQuickSearch(state, 'brand', item);
    };

    const producersString = computed(() => {
      return Object.keys(state.producers).join(',');
    });

    const handleAgreementTextMiniModule = () => {
      window.ajaxpen(950, 450, 'Sözleşme Metinleri', 'sozlesme_metinleri');
    };

    const agreementTextData = ref([]);
    const company = useCompany();

    const initializeAgreementsTexts = async () => {
      agreementTextData.value = await getAgreementTexts(company.value.id);
    };
    initializeAgreementsTexts();

    watchEffect(() => {
      if (state.agreementDetailsSelect) {
        for (const item of agreementTextData.value) {
          if (`${item.id}` === state.agreementDetailsSelect) {
            state.agreementDetailsList = item.metin;
          }
        }
      }
    });

    const initPage = async () => {
      if (props.agreementID && props.formType !== 'new') {
        const data = await getAgreementDetails(props.agreementID);
        hydrateFromServer(state, data);
      }
    };
    initPage();

    const createTurnoverPremiumObj = () => {
      return {
        monthlyCondition: '',
        monthlyExplanation: '',
        monthlyRate: '',
        threeMonthlyCondition: '',
        threeMonthlyExplanation: '',
        threeMonthlyRate: '',
        annuallyCondition: '',
        annuallyExplanation: '',
        annuallyRate: ''
      };
    };

    const addTurnoverPremiumRow = () => {
      root.$set(
        state.turnoverPremiumArray,
        state.turnoverPremiumArray.length,
        createTurnoverPremiumObj()
      );
    };

    const removeTurnoverPremiumRow = idx => {
      state.turnoverPremiumArray.splice(idx, 1);
    };

    const initializeFirstTurnoverPremiumRow = () => {
      if (state.turnoverPremiumArray.length === 0) {
        addTurnoverPremiumRow();
      }
    };
    initializeFirstTurnoverPremiumRow();

    return {
      nameAndCodeRenderer,
      labelSize,
      state,
      title,
      closeAgreement,
      saveAgreement,
      handleProductGroupsQuickSearch,
      handleProducersQuickSearch,
      handleBrandQuickSearch,
      producersString,
      addTurnoverPremiumRow,
      removeTurnoverPremiumRow,
      handleAgreementTextMiniModule,
      agreementTextData
    };
  }
};
</script>

<style lang="scss" scoped>
.new-branch-span-margin {
  @apply ml-1 mr-3;
}

.new-branch-numeric-input-width {
  @apply w-24;
}

.turnover-activity-premium {
  & > tr > th,
  & > tr > td {
    padding: 0.4rem;
  }
  & span {
    @apply ml-1;
  }
}

.promotions-small-input {
  @apply w-20;
}

.promotions-span-margin {
  @apply ml-1 mr-1;
}
</style>
