const hydrateMap = [
  { server: 'company_id', client: 'companyID' },
  { server: 'company_name', client: 'companyName' },
  { server: 'department_id', client: 'departmentID' },
  { server: 'department_name', client: 'departmentName' },
  { server: 'adverb_title', client: 'applicationHeader' },
  { server: 'position', client: 'position' },
  { server: 'qualifications', client: 'qualifications' },
  { server: 'job_description', client: 'jobDescription' },
  { server: 'contract_type', client: 'workingType' },
  { server: 'education_status', client: 'education' },
  { server: 'experience', client: 'experience' },
  { server: 'military_status', client: 'military' },
  { server: 'gender', client: 'gender' },
  { server: 'address', client: 'address' },
  { server: 'disabled', client: 'disabled' }
];

export function hydrateFromServer(data) {
  return hydrateMap.reduce((acc, item) => {
    acc[item.client] = data[item.server];
    return acc;
  }, {});
}

export function hydrateFromClient(payload) {
  const resObj = hydrateMap.reduce((acc, item) => {
    acc[item.server] = payload[item.client];
    return acc;
  }, {});
  delete resObj.company_name;
  delete resObj.department_name;
  return resObj;
}
