import Vue from 'vue';
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue';
import FeatherIcon from './components/FeatherIcon.vue';

Vue.component(VxTooltip.name, VxTooltip);
Vue.component(FeatherIcon.name, FeatherIcon);

// file upload component
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);

// WO Components
import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';
import WOTextArea from '@/components/WO/WOTextArea.vue';

Vue.component('wo-textarea', WOTextArea);

const ignoredComponents = {
  './WOModuleWrapper.vue': true,
  './WOMarqueeAlternative.vue': true,
  './WOFileUploadMin.vue': true,
  './WOAutoCompleteInput.vue': true
};

const requireComponent = require.context(
  './components/WO',
  false,
  /WO[\w-]+\.vue$/
);

requireComponent.keys().forEach(fileName => {
  if (!ignoredComponents[fileName]) {
    const componentConfig = requireComponent(fileName);

    const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
    );

    Vue.component(componentName, componentConfig.default || componentConfig);
  }
});

import WODoubleForm from '@/components/WO/Layout/WODoubleForm.vue';

Vue.component('WoDoubleForm', WODoubleForm);

import WOTable from '@/components/WO/Table/WOTable.vue';
import WOTd from '@/components/WO/Table/WOTd.vue';
import WOTh from '@/components/WO/Table/WOTh.vue';
import WOTr from '@/components/WO/Table/WOTr.vue';

Vue.component('WoTable', WOTable);
Vue.component('WoTd', WOTd);
Vue.component('WoTh', WOTh);
Vue.component('WoTr', WOTr);

import WOTabMaker from '@/components/WO/Tabs/WOTabMaker.vue';
Vue.component('WoTabMaker', WOTabMaker);

import JsonExcel from '@/components/excel/JsonExcel.vue';
Vue.component('DownloadExcel', JsonExcel);

// Vee-Validate
import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

//Base Inputs
import BaseInput from './components/Base/BaseInput.vue';
Vue.component('BaseInput', BaseInput);

import Multiselect from 'vue-multiselect';
Vue.component('Multiselect', Multiselect);
