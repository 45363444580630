<template>
  <div>
    <div
      v-for="(name, id) in data"
      :key="`categories-${id}`"
      class="flex items-center"
      :class="{ 'mb-4': itemMargin }"
    >
      <span
        class="w-8 text-danger hover:text-red-700 cursor-pointer text-2xl"
        @click="deleteSelected(id)"
      >
        ✗
      </span>
      <span :style="`width: ${nameWidth};`">{{ name }}</span>
      <slot v-bind:obj="{ name, id }" />
    </div>
  </div>
</template>

<script>
import { errorConfirmDialog } from '@/helpers/errorDialogs';

export default {
  props: {
    data: Object,
    rootObj: Object,
    objKey: String,
    nameWidth: String,
    itemMargin: Boolean
  },
  setup(props, { root, emit }) {
    const deleteSelected = id => {
      const errorMessage = `${
        props.rootObj[props.objKey][id]
      } listeden kaldırılsın mı?`;
      errorConfirmDialog(errorMessage, () => {
        root.$delete(props.rootObj[props.objKey], id);
        emit('itemDeleted', id);
      });
    };

    return {
      deleteSelected
    };
  }
};
</script>

<style lang="scss" scoped></style>
