<template>
  <wo-modal
    title="Bağlı Stok Hareketleri"
    class="wide-dialog-90vw one-step-behind-dialog"
    :active="stockMovementsModal"
    @active="$emit('stockMovementsModal', $event)"
    :closeAct="() => $emit('stockMovementsModal', false)"
    :buttonsHidden="true"
  >
    <div v-for="(movement, index) in stockMovements" :key="index" class="p-3">
        <div class="movement-text">{{ movement }}</div>
        <wo-divider />
    </div>
  </wo-modal>
</template>

<script>
export default {
  name: 'stock-movements-modal',
  props: {
    stockMovementsModal: Boolean,
    stockMovements: Array
  },
};
</script>

<style lang="scss">
.movement-text {
    font-weight:600;
}
</style>