<template>
  <section>
    <form @submit.prevent class="space-y-4">
      <wo-double-form>
        <template #firstLabel>Şirket</template>
        <template #firstElement>
          <wo-quick-search
            v-model="state.companyName"
            queryParameters="?tablo=sirketler&q="
            @selected="(item) => handleQuickSearch(item, 'company')"
            :itemRenderer="nameRenderer"
          />
        </template>
        <template #secondLabel>Departman</template>
        <template #secondElement>
          <wo-quick-search
            v-model="state.departmentName"
            :queryParameters="`?tablo=departmanlar&sirket=${state.companyID}&q=`"
            @selected="(item) => handleQuickSearch(item, 'department')"
            :itemRenderer="nameRenderer"
          />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>İlan Başlığı</template>
        <template #firstAfter>
          <wo-textarea class="textarea-width" v-model="state.applicationHeader" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Pozisyon</template>
        <template #firstAfter>
          <wo-textarea class="textarea-width" v-model="state.position" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Aranan Özellikler</template>
        <template #firstAfter>
          <wo-textarea class="textarea-width" v-model="state.qualifications" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>İş Tanımı</template>
        <template #firstAfter>
          <wo-textarea class="textarea-width" v-model="state.jobDescription" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Adres</template>
        <template #firstAfter>
          <wo-textarea class="textarea-width" v-model="state.address" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Çalışma Şekli</template>
        <template #firstElement>
          <wo-select v-model="state.workingType" :data="workingTypeData">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="Seçiniz" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`working-type-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>Eğitim Durumu</template>
        <template #secondElement>
          <wo-select v-model="state.education" :data="educationData">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="Seçiniz" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`education-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Askerlik Durumu</template>
        <template #firstElement>
          <wo-select v-model="state.military" :data="militaryData">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="Seçiniz" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`military-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>Deneyim</template>
        <template #secondElement>
          <wo-select v-model="state.experience" :data="experienceData">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="Seçiniz" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`experience-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Cinsiyet</template>
        <template #firstElement>
          <wo-select v-model="state.gender" :data="genderData">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="Seçiniz" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`gender-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>Engelli</template>
        <template #secondElement>
          <wo-select v-model="state.disabled" :data="disabledData">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="Seçiniz" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`disabled-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>

      <div class="space-x-4">
        <wo-button text="Kaydet (Alt + S)" data-id="kaydet" @click="saveJobListing" />
        <wo-button text="Listeye Dön" data-id="geri" :href="`${$baseURL}/job_listing`" />
      </div>
    </form>
    <!-- <JobApplicationFormTable v-if="$route.params.id" /> -->
  </section>
</template>

<script>
import { nameRenderer } from "@/helpers/quickSearchListRenderers/qsName";
import { useJobListingState } from "./consumables/useJobListingState";
import { jobListingSelectData } from "./helpers/jobListingSelectData";
import {
  fetchDetailsData,
  saveJobListing as saveJobListingAPI,
} from "./helpers/jobListingAPIService";

export default {
  name: "JobListingForm",
  setup(props, { root }) {
    const {
      workingTypeData,
      educationData,
      militaryData,
      experienceData,
      genderData,
      disabledData,
    } = jobListingSelectData();

    const state = useJobListingState();

    const saveJobListing = () => {
      saveJobListingAPI(root.$route.params.id);
    };

    const handleQuickSearch = (item, keyPrefix) => {
      if (item.isSelected) {
        state[`${keyPrefix}Name`] = item.isim;
        state[`${keyPrefix}ID`] = item.id;
      } else if (!item.isSelected && state[`${keyPrefix}ID`]) {
        state[`${keyPrefix}Name`] = "";
        state[`${keyPrefix}ID`] = 0;
      }
    };

    if (root.$route.params.id) {
      fetchDetailsData(root.$route.params.id);
    }

    return {
      nameRenderer,
      workingTypeData,
      educationData,
      militaryData,
      experienceData,
      genderData,
      disabledData,
      state,
      saveJobListing,
      handleQuickSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.textarea-width {
  @apply w-full md:w-2/3 xl:w-1/2;
}
.vs-textarea {
  @apply h-80;
}
</style>
