import Vue from '@/main';

export function errorConfirmReloadDialog(text, title = 'Hata!') {
  Vue.$vs.dialog({
    type: 'confirm',
    color: 'danger',
    title,
    text,
    acceptText: 'Tamam',
    cancelText: 'Yenile',
    cancel: () => location.reload()
  });
}

export function errorConfirmDialog(
  text,
  acceptCallback,
  title = 'Onay',
  cancelCallback
) {
  Vue.$vs.dialog({
    type: 'confirm',
    color: 'danger',
    title,
    text,
    acceptText: 'Tamam',
    accept: acceptCallback,
    cancelText: 'İptal',
    cancel: cancelCallback
  });
}

export function errorNotify(text, title = 'Hata!') {
  Vue.$vs.notify({
    color: 'danger',
    title,
    text
  });
}

export function errorDialog(text, title = 'Hata!', acceptCallback) {
  Vue.$vs.dialog({
    color: 'danger',
    title,
    text,
    acceptText: 'Tamam',
    accept: acceptCallback
  });
}

export function errorInformDialog(text, title = 'Bilgilendirme') {
  Vue.$vs.dialog({
    color: 'danger',
    title,
    text,
    acceptText: 'Tamam'
  });
}
