import Vue from 'vue';
import { ref, watch } from '@vue/composition-api';

import { getExchangeRates } from '@/helpers/ApiController.js';
import { dateFromClient } from '@/helpers/clientToServerConversion';
import { errorConfirmReloadDialog } from '@/helpers/errorDialogs';

const usedExchangeRates = ref([]);
const usableExchangeRates = ref([]);
const exchangeRatesMessage = ref('');
const tempExchangeRates = ref([]);

const mainCurrency = window.anaparabirimi || 'TL';
const unchangedExchangeRates = [];

watch(usedExchangeRates, () => {
  tempExchangeRates.value = [];
  for (const idx in usedExchangeRates.value) {
    tempExchangeRates.value[idx] = {
      ...usedExchangeRates.value[idx]
    };
  }
});

const handleExchangeRatesData = data => {
  exchangeRatesMessage.value = data.message ? data.message : '';
  for (const idx in data.kurlar) {
    data.kurlar[idx].kur = parseFloat(data.kurlar[idx].kur);
    unchangedExchangeRates[idx] = {
      ...data.kurlar[idx]
    };

    if (data.kurlar[idx].kod === mainCurrency) {
      Vue.set(usedExchangeRates.value, 0, data.kurlar[idx]);
    }
    Vue.set(usableExchangeRates.value, idx, data.kurlar[idx]);
  }
};

const handleExchangeRatesError = () => {
  errorConfirmReloadDialog(
    'Sistem kurları getirirken problem yaşadı. Lütfen sayfayı yenileyiniz.'
  );
};

const getExchangeRatesFromAPI = date => {
  return getExchangeRates(dateFromClient(date))
    .then(handleExchangeRatesData)
    .catch(handleExchangeRatesError);
};

export function useCurrency() {
  return {
    usedExchangeRates,
    usableExchangeRates,
    exchangeRatesMessage,
    unchangedExchangeRates,
    mainCurrency,
    tempExchangeRates,
    getExchangeRatesFromAPI
  };
}
