import { dateTimeFromClient } from '../../../../helpers/clientToServerConversion';
import { dateTimeFromServer } from '../../../../helpers/serverToClientConversion';

export const applicantMap = [
  { key: 'id', val: 'id' },
  { key: 'name_surname', val: 'fullName' },
  { key: 'phone', val: 'phone' },
  { key: 'email', val: 'email' },
  { key: 'cv', val: 'cv' },
  { key: 'job_id', val: 'jobID' },
  { key: 'advert_name', val: 'jobName' },
  { key: 'notes', val: 'notes' }
];

export const statusMap = [
  { key: 'id', val: 'id' },
  { key: 'name', val: 'name' },
  { key: 'order', val: 'order' },
  { key: 'company_id', val: 'companyID' },
  { key: 'active', val: 'isActive' },
  { key: 'created_at', val: 'createdAt', convert: 'datetime' },
  { key: 'created_by', val: 'createdBy' },
  { key: 'updated_at', val: 'updatedAt', convert: 'datetime' },
  { key: 'updated_by', val: 'updatedBy' }
];

export const feedbackMap = [
  { key: 'id', val: 'id' },
  { key: 'status', val: 'status' },
  { key: 'application_id', val: 'applicationID' },
  { key: 'note', val: 'note' },
  { key: 'hr_id', val: 'interviewer' },
  { key: 'hr_name', val: 'interviewerName' },
  { key: 'created_at', val: 'createdAt', convert: 'datetime' },
  { key: 'created_by', val: 'createdBy' }
];

export function hydrateFromServer(data, mapper) {
  return mapper.reduce((acc, item) => {
    if (item.convert === 'datetime') {
      acc[item.val] = dateTimeFromServer(data[item.key]);
    } else {
      acc[item.val] = data[item.key];
    }
    return acc;
  }, {});
}

export function hydrateFromClient(data, mapper) {
  return mapper.reduce((acc, item) => {
    if (item.convert === 'datetime') {
      acc[item.key] = dateTimeFromClient(data[item.val]);
    } else {
      acc[item.key] = data[item.val];
    }
    return acc;
  }, {});
}
