const listTypes = [
  {
    id: 1,
    name: 'Paketleme'
  },
  {
    id: 2,
    name: 'Sevk'
  },
  {
    id: 3,
    name: 'İhracat'
  },
  {
    id: 4,
    name: 'Diğer'
  }
];

export { listTypes };
