<template>
  <div class="space-y-4">
    <CariFormSectionHeader>Banka Bilgileri</CariFormSectionHeader>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="handleBankMiniModule">
            Banka
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="state.bank" :data="banks">
            <template #defaultValue>
              <wo-select-item itemValue="0" text="-Yok-" />
            </template>
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`bank-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>
          Şube - Şube Kodu
        </template>
        <template #secondElement>
          <div class="flex space-x-2">
            <base-input class="w-26" v-model="state.bankBranch" />
            <span> - </span>
            <base-input class="w-24" v-model="state.bankBranchCode" />
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Hesap No</template>
        <template #firstElement>
          <div class="flex space-x-2">
            <base-input class="w-26" v-model="state.bankAccountNo" />
            <span> - </span>
            <base-input class="w-24" v-model="state.bankAccountAdditionalNo" />
          </div>
        </template>
        <template #secondLabel>
          IBAN
        </template>
        <template #secondElement>
          <wo-spaced-input v-model="state.iban" />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState.js';

import { ref } from '@vue/composition-api';

import { getBanks } from '../../helpers/ApiFactory.js';

import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

export default {
  components: {
    CariFormSectionHeader,
    CariFormQuadrupleFormWrapper
  },
  setup() {
    const { labelSize, state } = usePrevalentState();

    const banks = ref([]);

    const bankInitializor = async () => {
      banks.value = await getBanks();
    };
    bankInitializor();

    const handleBankMiniModule = () => {
      window.MiniModulPen(450, 430, 'Bankalar', 'bankalar');
    };

    return {
      labelSize,
      state,
      banks,
      handleBankMiniModule
    };
  }
};
</script>

<style lang="scss" scoped></style>
