<template>
  <div>
    <wo-table stripe hoverFlat noDataText="">
      <template slot="thead">
        <wo-th>
          Sıra
        </wo-th>
        <wo-th>
          Ürün
        </wo-th>
        <wo-th>
          Kaynak Ürün
        </wo-th>
        <wo-th>
          Birim
        </wo-th>
        <wo-th>
          <vx-tooltip
            text="Maliyet fiyatı sarflardan geldiği için manuel değişiklik yapılamaz."
          >
            Kaynak Ürün Maliyeti
          </vx-tooltip>
        </wo-th>
        <wo-th>
          <vx-tooltip text="Verimlilik Oranı">
            V.O (% 10.5)
          </vx-tooltip>
        </wo-th>
        <wo-th>
          Maliyet Düşürücü Oran (% 5.2)
        </wo-th>
        <wo-th>
          <vx-tooltip text="Sarfiyat / Diğer Maliyetler">
            Sarfiyat / Diğer Ml. (1 {{ currency }})
          </vx-tooltip>
        </wo-th>
        <wo-th> İşçilik Payı (3 {{ currency }}) </wo-th>
        <wo-th>
          <vx-tooltip text="Yeni Maliyet">
            N.Ü.M
          </vx-tooltip>
        </wo-th>
        <wo-th>
          Min Marj
        </wo-th>
        <wo-th>
          Maks Marj
        </wo-th>
        <wo-th>
          İşlemler
        </wo-th>
      </template>

      <template>
        <wo-tr :key="indextr" v-for="(satirData, indextr) in tableArray.list">
          <wo-td>
            {{ indextr + 1 }}
          </wo-td>
          <wo-td class="w-60">
            <ValidationProvider
              name="Ürün"
              rules="required"
              v-slot="{ errors }"
            >
              <wo-quick-search
                v-model="satirData.urun"
                :queryParameters="`?tablo=stok&sube=${sube}&depo=${depo}&q=`"
                :error="errors"
                @selected="item => handleStockQuickSearch(item, satirData)"
                :itemRenderer="
                  item => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')
                "
              />
            </ValidationProvider>
          </wo-td>
          <wo-td>
            <wo-quick-search
              v-model="satirData.kaynakUrun"
              :queryParameters="`?tablo=stok&sube=${sube}&depo=${depo}&q=`"
              @selected="item => handleSourceStockQuickSearch(item, satirData)"
              :itemRenderer="
                item => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')
              "
            />
          </wo-td>
          <wo-td>
            <wo-select
              v-model="satirData.birim"
              :data="birimler"
              elementClasses="w-28"
            >
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="'birim' + item.id"
                />
              </template>
            </wo-select>
          </wo-td>
          <wo-td>
            <ValidationProvider
              name="Maliyet"
              rules="required"
              mode="lazy"
              v-slot="{ errors }"
            >
              <wo-quick-search
                v-model="satirData.kaynakUrunMaliyeti"
                class="w-28 wo-numeric-input"
                :queryParameters="
                  `?tablo=stok_kalibre_maliyet&stok_id=${satirData.kaynakUrunId}&birim=${satirData.birim}&depo=${depo}&tarih=${tarih}&sube=${sube}&q=`
                "
                :error="errors"
                @blur="calculateNihaiMaliyet(satirData)"
                @selected="item => handleCostQuickSearch(item, satirData)"
                :itemRenderer="stockCaliberCostRenderer"
              />
            </ValidationProvider>
            <!-- </vx-tooltip> -->
          </wo-td>
          <wo-td>
            <vx-tooltip text="Verimlilik Oranı" position="bottom">
              <wo-float-input
                v-model="satirData.verimlilikOrani"
                :error="errors"
                percentage
                percentageErrorText="Verimlilik oranı 0 ila 100 arasında olmalıdır."
                @blur="calculateNihaiMaliyet(satirData)"
                :isAuthorized="
                  authorizationControl(
                    'maliyet_analiz_verimlilik_orani_degistirme'
                  )
                "
              />
            </vx-tooltip>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.maliyetDusurucuOran"
              percentage
              percentageErrorText="Glase Oranı 0 ila 100 arasında olmalıdır."
              @blur="calculateNihaiMaliyet(satirData)"
            />
          </wo-td>
          <wo-td>
            <vx-tooltip text="Sarfiyat / Diğer Maliyetler" position="bottom">
              <wo-float-input
                v-model="satirData.sarfiyat"
                @blur="calculateNihaiMaliyet(satirData)"
              />
            </vx-tooltip>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.iscilikPayi"
              @blur="calculateNihaiMaliyet(satirData)"
            />
          </wo-td>
          <wo-td>
            <vx-tooltip text="Yeni Maliyet" position="bottom">
              <wo-float-input
                v-model="satirData.nihaiMaliyet"
                @blur="calculateNihaiMaliyet(satirData)"
              />
            </vx-tooltip>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.minimumMarjin"
              :isAuthorized="
                authorizationControl('maliyet_analiz_marj_guncelleme')
              "
            />
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.maksimumMarjin"
              :isAuthorized="
                authorizationControl('maliyet_analiz_marj_guncelleme')
              "
            />
          </wo-td>
          <wo-td>
            <wo-button
              text="✗"
              data-id="satir_sil"
              @click="removeItem(indextr)"
              color="danger"
              tabindex="-1"
            />
          </wo-td>
        </wo-tr>
      </template>
    </wo-table>
    <!-- :style="{ 'background-color': themeBg }" -->
    <div class="p-0.5 bg-white"></div>
  </div>
</template>

<script>
import Decimal from 'decimal.js';

import { numToTrStr, trStrToNum } from '@/helpers/NumberController';

import { authorizationControl } from '@/consumables/useAuthorizationControl';

import { stockCaliberCostRenderer } from '@/helpers/quickSearchListRenderers/qsStockCaliberCost';

import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';

export default {
  props: {
    tableArray: Object,
    removeItem: Function, // call as removeSatir(index) click event
    birimler: Array,
    depo: [String, Number],
    tarih: String,
    sube: [String, Number],
    currency: {
      type: String,
      default: 'TRY'
    }
  },
  setup(props, { root }) {
    const handleSourceStockQuickSearch = (item, row) => {
      if (item.isSelected) {
        row.kaynakUrun = item.text;
        row.kaynakUrunId = item.id;
        row.kaynakUrunKod = item.kod;
        row.kaynakUrunIsim = item.isim;
        if (item.birim) {
          row.birim = item.birim;
        }
      } else if (!item.isSelected && row.kaynakUrunId) {
        row.kaynakUrun = '';
        row.kaynakUrunId = 0;
        row.kaynakUrunKod = '';
        row.kaynakUrunIsim = '';
      }
    };

    const handleCostQuickSearch = (item, row) => {
      if (item.isSelected) {
        row.kaynakUrunMaliyeti = numToTrStr(item.kalibre_fiyat);
      } else if (!item.isSelected && row.kaynakUrunMaliyeti) {
        row.kaynakUrunMaliyeti = '';
      }
    };

    const handleStockQuickSearch = (item, row) => {
      if (item.isSelected) {
        row.urun = item.text;
        row.urunId = item.id;
        row.urunKod = item.kod;
        row.urunIsim = item.isim;
        if (item.birim) {
          row.birim = item.birim;
        }
      } else if (!item.isSelected && row.urunId) {
        row.urun = '';
        row.urunId = 0;
        row.urunKod = '';
        row.urunIsim = '';
      }
    };

    const calculateNihaiMaliyet = row => {
      const maliyet = trStrToNum(row.kaynakUrunMaliyeti);
      if (maliyet) {
        const vo = trStrToNum(row.verimlilikOrani);
        const mdo = trStrToNum(row.maliyetDusurucuOran);
        const sarfiyat = trStrToNum(row.sarfiyat);
        const ip = trStrToNum(row.iscilikPayi);

        let newNihaiMaliyet = maliyet;
        if (vo)
          newNihaiMaliyet = Decimal(newNihaiMaliyet)
            .div(vo)
            .mul(100)
            .toNumber();
        if (mdo > 0)
          newNihaiMaliyet = Decimal(newNihaiMaliyet)
            .minus(
              Decimal(newNihaiMaliyet)
                .mul(mdo)
                .div(100)
            )
            .toNumber();
        //masraf giderleri var 2015de bualmadim eklemedim
        if (sarfiyat > 0)
          newNihaiMaliyet = Decimal(newNihaiMaliyet)
            .plus(sarfiyat)
            .toNumber();
        if (ip > 0)
          newNihaiMaliyet = Decimal(newNihaiMaliyet)
            .plus(ip)
            .toNumber();
        root.$set(row, 'nihaiMaliyet', numToTrStr(newNihaiMaliyet)); // hesaplama kismi
      }
    };

    return {
      nameAndCodeWithLinkRenderer,
      stockCaliberCostRenderer,
      handleSourceStockQuickSearch,
      handleCostQuickSearch,
      handleStockQuickSearch,
      numToTrStr,
      authorizationControl,
      calculateNihaiMaliyet
    };
  }
};
</script>

<style></style>
