export function usersRenderer(item) {
  return `
    <span>
      ${item.isim}
      <span class="font-semibold">K.Adı: </span>
      <span class="text-primary">
        ${item.user}
      </span>
    </span>
  `;
}
