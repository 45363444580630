var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wo-modal',{staticClass:"wide-dialog-90vw one-step-behind-dialog",attrs:{"active":_vm.productionSlipModal,"buttonsHidden":true,"title":"Üretim Fişi Oluştur"},on:{"active":function($event){return _vm.$emit('productionSlipModal', $event)}}},[_c('div',{staticClass:"d-inline flex ml-2 mb-2"},[_c('label',{staticClass:"mr-2 mt-1"},[_vm._v("Depo")]),_c('wo-quick-search',{attrs:{"queryParameters":"?tablo=depolar&uretim_depo=1&q=","itemRenderer":_vm.nameAndCodeRenderer},on:{"selected":_vm.handleDepot},model:{value:(_vm.depot.name),callback:function ($$v) {_vm.$set(_vm.depot, "name", $$v)},expression:"depot.name"}}),_c('label',{staticClass:"ml-4"},[_vm._v("Reçete Grubu")]),_c('wo-select',{attrs:{"data":_vm.recipeGroups,"elementClasses":"w-28 m-auto"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("recipeGroup-" + (item.id)),attrs:{"itemValue":item.id,"text":item.isim}})]}}]),model:{value:(_vm.recipeGroup.id),callback:function ($$v) {_vm.$set(_vm.recipeGroup, "id", $$v)},expression:"recipeGroup.id"}})],1),_c('div',{staticClass:"d-flex"},[_c('wo-table',{scopedSlots:_vm._u([{key:"thead",fn:function(){return _vm._l((_vm.titles),function(title){return _c('wo-th',{key:("title-" + title)},[_vm._v(" "+_vm._s(title)+" ")])})},proxy:true}])},[_vm._l((_vm.stockToProductionSlip),function(stock){return [(stock.details.length > 0)?_c('wo-tr',{key:stock.id,staticClass:"text-left",attrs:{"id":"main-product"}},[_c('wo-td',{staticClass:"text-left"},[_vm._v(_vm._s(stock.stockCode)+" "+_vm._s(stock.entryName))]),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.localExchangeRates,"elementClasses":"w-16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("exchange-rate-" + (item.kod)),attrs:{"itemValue":item.kod,"text":item.kod}})]}}],null,true),model:{value:(stock.currency),callback:function ($$v) {_vm.$set(stock, "currency", $$v)},expression:"stock.currency"}})],1),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.stockUnits,"elementClasses":"w-28 m-auto"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("stockUnit-" + (item.id)),attrs:{"itemValue":item.id,"text":item.isim}})]}}],null,true),model:{value:(stock.unit),callback:function ($$v) {_vm.$set(stock, "unit", $$v)},expression:"stock.unit"}})],1),_c('wo-td',[_c('wo-float-input',{attrs:{"elementClasses":'w-16'},on:{"blur":function($event){_vm.calculateDetailsAmount(stock.stockID), _vm.calculatePrice(stock.stockID), _vm.calculateDetailsPrice(stock.stockID)}},model:{value:(stock.amount),callback:function ($$v) {_vm.$set(stock, "amount", $$v)},expression:"stock.amount"}})],1),_c('wo-td',[_c('wo-float-input',{attrs:{"readonly":"","elementClasses":'w-16'},model:{value:(stock.cost),callback:function ($$v) {_vm.$set(stock, "cost", $$v)},expression:"stock.cost"}})],1),_c('wo-td',[_c('wo-float-input',{attrs:{"elementClasses":'w-16'},on:{"blur":function($event){return _vm.calculateCost(stock.stockID)}},model:{value:(stock.price),callback:function ($$v) {_vm.$set(stock, "price", $$v)},expression:"stock.price"}})],1),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.stations,"elementClasses":"w-28 m-auto"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("stockStation-" + (item.id + 1)),attrs:{"itemValue":item.id,"text":item.isim}})]}}],null,true),model:{value:(stock['station']),callback:function ($$v) {_vm.$set(stock, 'station', $$v)},expression:"stock['station']"}})],1),_c('wo-td',[_c('base-input',{attrs:{"elementClasses":'w-16'},model:{value:(stock.description),callback:function ($$v) {_vm.$set(stock, "description", $$v)},expression:"stock.description"}})],1)],1):_vm._e(),_vm._l((stock.details),function(detail){return _c('wo-tr',{key:detail.stockID},[_c('wo-td',[_vm._v(_vm._s(detail.stockCode)+" "+_vm._s(detail.stockName))]),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.localExchangeRates,"elementClasses":"w-16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("exchange-rate-" + (item.kod)),attrs:{"itemValue":item.kod,"text":item.kod}})]}}],null,true),model:{value:(detail.currency),callback:function ($$v) {_vm.$set(detail, "currency", $$v)},expression:"detail.currency"}})],1),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.stockUnits,"elementClasses":"w-28 m-auto"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("detailUnit-" + (item.id)),attrs:{"itemValue":item.id,"text":item.isim}})]}}],null,true),model:{value:(detail.stockUnitID),callback:function ($$v) {_vm.$set(detail, "stockUnitID", $$v)},expression:"detail.stockUnitID"}})],1),_c('wo-td',[_c('wo-float-input',{attrs:{"readonly":"","id":("calculatedAmount-" + (detail.stockID)),"elementClasses":'w-16'},on:{"blur":function($event){_vm.calculateDetailsAmount(stock.stockID), _vm.calculatePrice(stock.stockID)}},model:{value:(detail.calculatedAmount),callback:function ($$v) {_vm.$set(detail, "calculatedAmount", $$v)},expression:"detail.calculatedAmount"}})],1),_c('wo-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(detail.cost)+" ")]),_c('wo-td',{staticClass:"text-center",attrs:{"id":("detailPrice-" + (detail.stockID))}},[_vm._v(" "+_vm._s(detail.price)+" ")]),_c('wo-td'),_c('wo-td',[_c('base-input',{attrs:{"elementClasses":'w-16',"placeholder":"Açıklama"},model:{value:(detail.description),callback:function ($$v) {_vm.$set(detail, "description", $$v)},expression:"detail.description"}})],1)],1)})]})],2),_c('div',{staticClass:"ml-2 mt-2"},[_c('wo-button',{attrs:{"text":"Kaydet"},on:{"click":_vm.createProductionSlip}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }