import Vue from 'vue';

import { factory, getNumber } from '@/helpers/ApiController.js';
import usePrevalentState from '../consumables/usePrevalentState';
import controlPrevalentCode from './controlPrevalentCode';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { dateFromServer } from '@/helpers/serverToClientConversion';
import useCompany from '../../../../consumables/useCompany';
import { removeSpaces } from '@/helpers/removeSpaces';
import ServerError from '../../../../types/ServerError';

const { state } = usePrevalentState();

export async function getPrevalentCode(company) {
  const { data } = await getNumber(
    'cari',
    'code',
    company,
    state.accountingBranch,
    state.prevalentCode
  );

  const returnedCode = data[0];

  controlPrevalentCode(returnedCode);

  Vue.set(state, 'prevalentCode', returnedCode);
}

export async function getTaxpayerByNumber(number) {
  const url = 'current/getEmukellefByNumber';
  const { data } = await factory.post(url, { number: removeSpaces(number) });
  return data;
}

export async function confirmPrevalent(id) {
  try {
    const url = 'current/cariOnay';
    const { data } = await factory.post(url, { cariId: id });
    primaryDialog(data.message, 'Başarılı!');
  } catch (error) {
    const e = error.response;
    if (e.data) {
      const errorMessage = `Bazı alanlarda eksik bilgiler mevcut. Öncellikle bu bilgileri doldurunuz. (${e.data.join(
        ', '
      )})`;
      errorDialog(errorMessage);
    } else if (e.message) {
      errorDialog(e.message);
    }
  }
}

export async function getAccountingBranches() {
  try {
    const url = 'accounting-branch';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Şubeleri çekerken bir hata oluştu.');
    return [];
  }
}

export async function getPrevalentKinds() {
  try {
    const url = 'current/cari-cins';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Cari cinslerini getirirken bir hata oluştu.');
    return [];
  }
}

export async function getConcepts() {
  try {
    const url = 'konsept';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Konseptleri getirirken bir hata oluştu.');
    return [];
  }
}

export async function getResponsibilityCenters() {
  try {
    const url = 'sorumluluk-merkezi';
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog('Sorumluluk Merkezlerini getirirken bir hata oluştu.');
    return [];
  }
}

export async function getBanks() {
  try {
    const url = 'banka';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Bankaları getirirken bir hata oluştu.');
    return [];
  }
}

export async function getCollectionIntervals() {
  try {
    const company = useCompany();
    const url = 'sales/tahsilat-araligi';
    const { data } = await factory.get(url, {
      params: {
        sirket: company.value.id
      }
    });
    return data;
  } catch (error) {
    errorDialog(error.response.data.message);
    return [];
  }
}

export async function getActivelyWorkingStores() {
  try {
    const url = 'current/aktif-calisilan-magazalar';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Mağazaları getirirken bir problem oluştu.');
    return [];
  }
}

export async function getDatabaseConnections() {
  try {
    const url = 'database/connections';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Veritabanlarını getiriken bir hata oluştu.');
    return [];
  }
}

export async function getGroupCodes() {
  try {
    const url = 'current/grup-kodu';
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Grup kodunu getiriken bir hata oluştu.');
    return [];
  }
}

export async function saveUts(id, utsNumber) {
  try {
    const url = 'current/uts-kaydet';
    const { data } = await factory.post(url, {
      cariId: id,
      uts_kod: utsNumber
    });
    return data;
  } catch (e) {
    errorDialog(e.response.data.message);
  }
}

export async function getAgreements(prevalentID) {
  try {
    const url = `contract/${prevalentID}`;
    const { data } = await factory.get(url);
    const res = [];
    data.map(item => {
      res.push({
        name: item.isim,
        id: item.id,
        date: dateFromServer(item.tarih),
        startDate: dateFromServer(item.baslangic_tarih),
        endDate: dateFromServer(item.bitis_tarih),
        state: item.durum_adi
      });
    });
    return res;
  } catch {
    errorDialog('Sözleşmeleri getirirken bir hata oluştu.');
    return [];
  }
}

export async function saveAgreement(body) {
  try {
    const url = 'contract';
    const { data } = await factory.post(url, body);
    primaryDialog(data.message);
    return getAgreements(body.kayit_id);
  } catch {
    errorDialog('Sözleşmeyi kaydederken bir problem oluştu');
  }
}

export async function getAgreementTexts(companyID) {
  try {
    const url = `contract/text/${companyID}`;
    const { data } = await factory.get(url);
    return data;
  } catch {
    errorDialog('Sözleşme metinlerini getirirken bir problem oluştu.');
  }
}

export async function getAgreementDetails(agreementID) {
  try {
    const url = `contract/edit/${agreementID}`;
    const { data } = await factory.get(url, {
      params: {
        id: agreementID
      }
    });
    return data;
  } catch {
    errorDialog('Sözleşme detaylarını getirirken bir problem oluştu.');
  }
}

export async function deleteAgreement(agreementID) {
  try {
    const url = 'contract';
    const { data } = await factory.delete(url, {
      params: {
        id: agreementID
      }
    });
    return data;
  } catch (e) {
    errorDialog(e.response.data.message);
  }
}

export async function moveAgreement(agreementID, prevalentID) {
  try {
    const url = `contract/transport/${agreementID}`;
    const { data } = await factory.put(url, {
      cari: prevalentID
    });
    primaryDialog(data.message);
  } catch (e) {
    errorDialog(e.response.data.message);
  }
}

export async function savePrevalentForm(resultObj) {
  try {
    const url = 'current/cari-kaydet';
    const { data } = await factory.post(url, resultObj);
    return data;
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Cariyi kaydederken bir problem oluştu.');
    }
  }
}

export async function updatePrevalentForm(resultObj) {
  try {
    const url = 'current/cari-update';
    const { data } = await factory.put(url, resultObj);
    return data;
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Cariyi kaydederken bir problem oluştu.');
    }
  }
}

export async function fetchPrevalentFormData(editID) {
  try {
    const url = 'current/cari-duzenle';
    const { data } = await factory.get(url, {
      params: {
        duzenle: editID
      }
    });
    return data;
  } catch ({ response }) {
    throw new ServerError(response.data.message, response.status);
  }
}

export async function getEInvoiceMailBox(taxNum, tcNo) {
  try {
    const url = 'current/fatura-posta-kutusu';
    const { data } = await factory.get(url, {
      params: {
        vn: removeSpaces(taxNum),
        tc: tcNo
      }
    });
    return data;
  } catch {
    errorDialog('E-fatura posta kutularını getirirken bir hata oluştu.');
    return [];
  }
}

export async function getEWaybillMailBox(taxNum, tcNo) {
  try {
    const url = 'current/irsaliye-posta-kutusu';
    const { data } = await factory.get(url, {
      params: {
        vn: removeSpaces(taxNum),
        tc: tcNo
      }
    });
    return data;
  } catch {
    errorDialog('E-irsaliye posta kutularını getirirken bir hata oluştu.');
    return [];
  }
}

export async function controlAccountingCodeExists(code) {
  try {
    const url = 'muhasebe-hesap-plani/control';
    const { data } = await factory.get(url, {
      params: { code }
    });
    return data;
  } catch {
    errorDialog('Muhasebe kod kontrolü sırasında bir hata oluştu.');
    return false;
  }
}
