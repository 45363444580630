<template>
    <wo-table class="my-10">
        <wo-tr><wo-th colspan="6" style="font-size: 1rem;">GRAMAJ HESAPLAMA</wo-th></wo-tr>
        <wo-tr>
            <wo-th v-for="title in titles" :key="title">{{ title }}</wo-th>
            <wo-th></wo-th>
        </wo-tr>
        <wo-tr v-for="(color, index) in localData.map(data => data.RENKLER)" :key="color">
            <wo-td class="text-center">{{ color }}</wo-td>
            <wo-td>
                <div class="flex">
                    <wo-quick-search
                        v-if="!localData[index]['isNewYarnType']"
                        v-model="stokHammaddeData[index]"
                        :queryParameters="`?tablo=stok_hammadde&q=`"
                        @selected="(item) => handleRowStockSelected(item, index)"
                        :itemRenderer="(item) => nameAndCodeWithLinkRenderer(item)"
                    />
                    <input type="text" v-model="stokHammaddeData[index]" style="border-radius: 3px; height: 30px;" class="text-center" v-else>
                    <vs-checkbox title="Yeni eklemek için seçiniz" v-model="localData[index]['isNewYarnType']"/>
                </div>
            </wo-td>
            <wo-td> <wo-int-input class="text-info-right" v-model="localData[index]['İLK GRAMAJ']" @change="calculateRemaining(index)" /> </wo-td>
            <wo-td> <wo-int-input class="text-info-right" v-model="localData[index]['SON GRAMAJ']" @change="calculateRemaining(index)" /> </wo-td>
            <wo-td> {{ parseInt(localData[index]['KALAN']) || 0 }} </wo-td>
            <wo-td>
                <vs-radio
                    v-model="localData[index]['calculationType']"
                    :vs-value="`gross-${index}`"
                >
                    Brüt
                </vs-radio>
                <vs-radio
                    class="ml-2"
                    v-model="localData[index]['calculationType']"
                    :vs-value="`net-${index}`"
                >
                    Net
                </vs-radio>
            </wo-td>
        </wo-tr>
        <wo-tr class="text-center">
            <wo-td class="text-left flex">
                <wo-quick-search
                    v-model="addedColor"
                    :queryParameters="`?tablo=stok_renk_kodu&q=`" 
                    :itemRenderer="nameAndCodeRenderer"
                    @selected="(item) => addedColor = `${item.isim} - ${item.kod}`"
                    @keypress.enter="addColor"
                />
                <feather-icon
                    icon="PlusIcon"
                    style="cursor: pointer;"
                    class="h-5 w-5 align-middle plusIcon text-success ml-2 mt-1"
                    @click="addColor"
                />
            </wo-td>
        </wo-tr>
    </wo-table>
</template>
<script>
import { computed, onUpdated, reactive, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';
export default {
    setup(_, { root }) {

        const { state } = useModelPlanlamaState();

        // const weights = reactive({
        //     "RENKLER" : [],
        //     "İLK GRAMAJ": [],
        //     "SON GRAMAJ": [],
        //     "KALAN": []
        // });

        const localData = computed(() => {
            return state['design'].weights;
        });

        const titles = ["RENKLER", "İPLİK CİNSİ", "İLK GRAMAJ", "SON GRAMAJ", "KALAN"];

        const addedColor = ref("");

        const addColor = () => {
            if (addedColor.value !== "" && addedColor.value !== " ") {
                localData.value.push({"RENKLER": addedColor.value, calculationType: `gross-${localData.value.length}`});
                addedColor.value = "";
            }
        };

        const stokHammaddeData = reactive({});

        const handleRowStockSelected = (item, index) => {
            if (item.isSelected) {
                localData.value[index]['İPLİK CİNSİ'] = `${item.kod} ${item.isim}`;
                root.$set(stokHammaddeData, String(index), `${item.kod} ${item.isim}`);
            } else {
                localData.value[index]['İPLİK CİNSİ'] = "";
            }
        };

        const calculateRemaining = (index) => {
            root.$set(localData.value[index], 'KALAN', parseInt(localData.value[index]['İLK GRAMAJ']) - parseInt(localData.value[index]['SON GRAMAJ']));
        };

        onUpdated(() => {
            localData.value.forEach((item, index) => {
                    if (Object.keys(item).includes("İPLİK CİNSİ")) {
                        root.$set(stokHammaddeData, String(index), item["İPLİK CİNSİ"]);
                    }
            });
        });

        return {
            // weights,
            addedColor,
            addColor,
            localData,
            titles,
            calculateRemaining,
            nameAndCodeRenderer,
            handleRowStockSelected,
            nameAndCodeWithLinkRenderer,
            stokHammaddeData
        };
    }
};

</script>

<style lang="scss">
.text-info-right {
    input {
        text-align: right;
    }
}

</style>