<template>
  <div class="space-y-4">
    <!-- <vs-divider /> -->
    <wo-button text="Yeni Not Ekle" data-id="yeni" @click="openFeedbackForm" />
    <form @submit.prevent v-if="isFormOpen" class="space-y-4">
      <wo-double-form>
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openStatusPopup">
            Durum
          </span>
        </template>
        <template #firstElement>
          <wo-select :data="statusList" v-model="formState.status">
            <template #defaultValue>
              <wo-select-item text="- Seçiniz -" :itemValue="0" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.name"
                :key="`feedback-status-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>Görüşmeyi Yapan Kişi</template>
        <template #secondElement>
          <wo-quick-search
            v-model="qsInterviewer"
            queryParameters="?tablo=personel&q="
            @selected="item => handleInterviewer(item)"
            :itemRenderer="personnelRenderer"
          />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Not</template>
        <template #firstAfter>
          <wo-textarea
            v-model="formState.note"
            class="w-full sm:w-1/2 md:w-2/3"
          />
        </template>
      </wo-double-form>
      <div class="space-x-4 flex">
        <wo-button text="Kaydet" data-id="kaydet" @click="saveFeedback" />
        <wo-button text="Kapat" data-id="geri" @click="closeFeedback" />
      </div>
    </form>
    <div
      class="space-y-4"
      v-for="feedback in reversedFeedbackList"
      :key="`feedback-${feedback.id}`"
    >
      <vs-divider />
      <wo-double-form>
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openStatusPopup">
            Durum
          </span>
        </template>
        <template #firstElement>
          {{ statusIndexNameMap[feedback.status] }}
        </template>
        <template #secondLabel>Görüşmeyi Yapan Kişi</template>
        <template #secondElement>{{ feedback.interviewerName }}</template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Not</template>
        <template #firstAfter>
          <wo-textarea
            :value="feedback.note"
            readonly
            class="w-full sm:w-1/2 md:w-2/3"
          />
        </template>
      </wo-double-form>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import { useJobApplicationState } from '../composables/useJobApplicationState';
import { createFeedback } from '../helpers/jobApplicationAPIService';
import { personnelRenderer } from '@/helpers/quickSearchListRenderers/qsPersonnel';
import { errorDialog } from '@/helpers/errorDialogs';
export default {
  setup(props, { root }) {
    const {
      statusList,
      statusIndexNameMap,
      feedbackList,
      getFeedbackList,
      openStatusPopup
    } = useJobApplicationState();

    const isFormOpen = ref(false);

    const openFeedbackForm = () => {
      isFormOpen.value = true;
    };

    const reversedFeedbackList = computed(() => {
      return feedbackList.value.slice().reverse();
    });

    const formState = reactive({
      status: 0,
      interviewer: 0,
      note: ''
    });

    const qsInterviewer = ref('');

    const handleInterviewer = item => {
      if (item.isSelected) {
        qsInterviewer.value = `${item.ad} ${item.soyad}`;
        formState.interviewer = item.id;
      } else if (!item.isSelected && formState.interviewer) {
        formState.interviewer = 0;
      }
    };

    const closeFeedback = () => {
      formState.status = 0;
      formState.note = '';
      isFormOpen.value = false;
    };

    const validateSaveFeedback = () => {
      if (!formState.status) {
        errorDialog('Lütfen başvuru durumunu seçiniz.');
        return false;
      }
      if (!formState.interviewer) {
        errorDialog('Lütfen görüşmeyi yapan kişiyi seçiniz.');
        return false;
      }
      if (!formState.note) {
        errorDialog('Lütfen not ekleyiniz.');
        return false;
      }
      return true;
    };

    const saveFeedback = async () => {
      if (validateSaveFeedback()) {
        const payload = {
          ...formState,
          applicationID: root.$route.params.id
        };
        await createFeedback(payload);
        getFeedbackList(root.$route.params.id);
        closeFeedback();
      }
    };

    return {
      statusList,
      statusIndexNameMap,
      reversedFeedbackList,
      isFormOpen,
      openFeedbackForm,
      formState,
      qsInterviewer,
      handleInterviewer,
      closeFeedback,
      saveFeedback,
      personnelRenderer,
      openStatusPopup
    };
  }
};
</script>

<style lang="scss" scoped></style>
