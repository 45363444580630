<template>
  <div class="relative flex border border-gray-500 rounded shadow-xs bg-gray">
    <base-input
      :inputType="inputType"
      :idKey="idKey"
      ref="input"
      :placeholder="placeholder"
      :class="inputClasses + ' bg-transparent'"
      :danger="!!validationError"
      :danger-text="validationError"
      :value="searchQuery"
      v-on="$listeners"
      v-bind="$attrs"
      @input="e => handleInput(e)"
      @keyup.esc="escPressed"
      @keydown.up="e => increaseIndex(e, false)"
      @keydown.down="e => increaseIndex(e, true)"
      @keyup.enter="suggestionSelected()"
      @keyup.tab="suggestionSelected()"
      @keyup.f10="handleDropdown(true)"
      @blur="handleBlur"
    >
    </base-input>

    <div
      class="fixed top-0 left-0 h-full w-full z-front"
      v-if="isDropdownVisible"
      @click="handleDropdown(false)"
    >
      <div ref="dropdown" class="wo-dropdown lg:w-1/4">
        <span class="wo-auto-complete__list-item" v-if="isError">
          {{ errorMessage }}
        </span>
        <span class="wo-auto-complete__list-item" v-else-if="isLoading">
          Aranıyor...
        </span>
        <template v-else>
          <template v-for="(listItem, index) in dropdownList.list">
            <div
              :key="`qs-item-${index}`"
              @mouseenter="currentSelected = index"
              class="rounded"
              :class="{
                'dropdown-item-border': isThisItemLast(index),
                'wo-auto-complete__current-selected': isThisItemSelected(index)
              }"
              @click="suggestionSelected()"
              @mousedown="e => e.preventDefault()"
            >
              <div class="wo-auto-complete__list-item">
                <span v-if="itemRenderer" v-html="itemRenderer(listItem)" />
                <slot v-bind:item="listItem" />
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuickSearchResults } from '@/helpers/ApiController';
import dropdownPositioner from '@/helpers/dropdownPositioner.js';
import debounce from 'lodash.debounce';
import { GitnCodeReader, shouldBeChecked } from '@/helpers/GitnCodeReader.js';

import {
  ref,
  reactive,
  watch,
  computed,
  onMounted
} from '@vue/composition-api';

import { errorNotify } from '@/helpers/errorDialogs';

// import { useModelPlanlamaState } from '@/views/ModelPlanlama/Form/composables/useModelPlanlamaState';

export default {
  props: {
    inputType: {
      type: String,
      default: null
    },
    idKey: Number,
    value: [String, Number],
    inputClasses: String,
    queryParameters: [Object, String],
    canReadGitnCode: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    itemRenderer: Function,
    error: Array
  },
  setup(props, { emit, root }) {
    // html refs
    // const {incomingColor, incomingSize} = useModelPlanlamaState();
    const input = ref(null);
    const dropdown = ref(null);

    // * Dropdown positioning
    let currentScroll = 0;

    const searchQuery = ref(props.value ? props.value : '');
    const currentSelected = ref(-1);
    const isDropdownVisible = ref(false);

    const dropdownList = reactive({ list: [] });
    const isLoading = ref(false);
    const query = ref('');
    const isError = ref(false);
    const errorMessage = ref('');

    watch(
      () => props.value,
      () => {
        if (props.value !== undefined && props.value !== searchQuery.value) {
          searchQuery.value = props.value;
        }
      }
    );

    // * API handler functions
    const apiListHandler = () => {
      if (typeof props.queryParameters === 'object') {
        query.value = `${props.queryParameters.value}${searchQuery.value}`;
      } else {
        query.value = `${props.queryParameters}${searchQuery.value}`;
      }
      getQuickSearchResults(query.value)
        .then(response => {
          if (typeof response.data === 'object' && response.data.length) {
            dropdownList.list = response.data;
            isLoading.value = false;
          } else {
            throw 'Sonuç bulunamadı';
          }
        })
        .catch(err => {
          isError.value = true;
          errorMessage.value = err.response ? err.response.data.message : err;
        });
    };

    // * Dropdown open close functions
    const handleDropdown = val => {
      isDropdownVisible.value = val;

      currentSelected.value = -1;
      currentScroll = 0;
      if (val) {
        isError.value = false;
        if (!searchQuery.value || searchQuery.value === '0,00') {
          isLoading.value = true;
          apiListHandler();
        }
        root.$nextTick(() => {
          dropdownPositioner(dropdown.value, input.value.$el);
        });
      }
    };

    const debouncedApiCall = debounce(apiListHandler, 300);

    const handleDebouncedApiCall = () => {
      if (
        props.canReadGitnCode &&
        shouldBeChecked(searchQuery.value) === true
      ) {
        return;
      }

      isError.value = false;
      if (searchQuery.value) {
        handleDropdown(true);
        isLoading.value = true;
        debouncedApiCall();
      } else {
        isDropdownVisible.value = false;
      }
      emit('selected', {
        isSelected: false
      });
    };

    const handleInput = event => {
      searchQuery.value = event;
      handleDebouncedApiCall();
    };

    onMounted(() => {
      if (props.inputType !== null) {
        document.addEventListener(`quickSearch${props.idKey}`, e => {
          searchQuery.value = e.detail.value;
          handleDebouncedApiCall();
        });
      }
    });

    // * Dropdown selection functions
    const escPressed = () => {
      isDropdownVisible.value = false;
    };

    const suggestionSelected = async () => {
      let _continue = true;
      if (props.canReadGitnCode) {
        let data = undefined;
        const gtinValues = async () => {
          try {
            data = await GitnCodeReader(searchQuery.value);
          } catch (error) {
            errorNotify(error.response.data.message);
          }
        };
        await gtinValues();
        if (typeof data === 'object') {
          _continue = false;
          emit('gitnCodeSelected', data);
        }
      }

      if (_continue && currentSelected.value > -1) {
        const itemIndex = currentSelected.value;
        const selectedItem = dropdownList.list[itemIndex];
        searchQuery.value = selectedItem.text ? selectedItem.text : '';
        emit('selected', { isSelected: true, ...selectedItem });
        handleDropdown(false);
      }
    };

    const increaseIndex = (event, val = true) => {
      event.preventDefault(); // oka bastiginda imlecin basa ve sona gidisini engeller
      if (isDropdownVisible.value) {
        //event islemlerini sadece dropdown acikken tetikle
        if (dropdownList.list.length === 0) {
          // Liste bos ise indexi sifirla
          currentSelected.value = -1;
        } else {
          // Liste bos degilse olacak olan islemler
          // listede assagi inme hareketi
          if (val) {
            if (currentSelected.value === dropdownList.list.length - 1) {
              currentSelected.value = 0;
              currentScroll = 0;
              dropdown.value.scroll(0, currentScroll);
            } else if (currentSelected.value < dropdownList.list.length - 1) {
              // alt siniri secerken index in length i asmasini engeller
              const currentPositionOfTheSelectedDiv = dropdown.value.children
                .item(currentSelected.value + 1)
                .getBoundingClientRect();
              const currentPositionOfTheMenu =
                dropdown.value.getBoundingClientRect();
              if (
                currentPositionOfTheSelectedDiv.bottom >=
                currentPositionOfTheMenu.bottom
              ) {
                dropdown.value.scroll(
                  0,
                  (currentScroll += currentPositionOfTheSelectedDiv.height)
                );
              }
              currentSelected.value++;
            }
          } else {
            // listede yukari cikma hareketi
            if (currentSelected.value === 0) {
              currentSelected.value = dropdownList.list.length - 1;

              currentScroll =
                dropdown.value.children
                  .item(dropdownList.list.length - 1)
                  .getBoundingClientRect().bottom -
                dropdown.value.getBoundingClientRect().bottom;

              dropdown.value.scroll(0, currentScroll);
            } else if (currentSelected.value >= 1) {
              // ust siniri secerken index 0 i asmasini engeller
              const currentPositionOfTheSelectedDiv = dropdown.value.children
                .item(currentSelected.value - 1)
                .getBoundingClientRect();

              const currentPositionOfTheMenu =
                dropdown.value.getBoundingClientRect();

              if (
                currentPositionOfTheSelectedDiv.y < currentPositionOfTheMenu.y
              ) {
                dropdown.value.scroll(
                  0,
                  (currentScroll -= currentPositionOfTheSelectedDiv.height)
                );
              }
              currentSelected.value--;
            }
          }
        }
      }
    };

    // * Form validation
    const validationError = computed(() => {
      if (props.error) {
        return props.error[0];
      }
      return '';
    });

    // * Functions for class manipulation
    const isThisItemSelected = index => {
      return currentSelected.value === index;
    };
    const isThisItemLast = index => {
      return index + 1 !== dropdownList.list.length;
    };

    // * Event functions
    const handleBlur = () => {
      handleDropdown(false);
      emit('input', searchQuery.value);
    };

    // * scroll event listener
    const handleScroll = () => {
      if (isDropdownVisible.value) {
        dropdownPositioner(dropdown.value, input.value.$el);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return {
      input,
      dropdown,
      searchQuery,
      currentSelected,
      isDropdownVisible,
      dropdownList,
      isLoading,
      isError,
      errorMessage,
      handleDropdown,
      handleDebouncedApiCall,
      escPressed,
      suggestionSelected,
      increaseIndex,
      validationError,
      isThisItemSelected,
      isThisItemLast,
      handleBlur,
      handleInput
    };
  }
};
</script>
