<template>
    <div>
        <!-- <div class="flex justify-between mb-10"> -->
            <MachineInfoTable/>
            <WeightCalculationTable/>
        <!-- </div> -->
        <ManufactureDetails />
        <ModelDesignCalculations />
        <ModelDesignDescription />
    </div>
</template>

<script>
import MachineInfoTable from './MachineInfoTable.vue';
import WeightCalculationTable from './WeightCalculationTable.vue';
import ManufactureDetails from './ManufactureDetails.vue';
import ModelDesignCalculations from './ModelDesignCalculations.vue';
import ModelDesignDescription from './ModelDesignDescription.vue';
export default {
    components: {
    MachineInfoTable,
    WeightCalculationTable,
    ManufactureDetails,
    ModelDesignCalculations,
    ModelDesignDescription
}
};
</script>