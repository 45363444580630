<template>
  <div>
    <wo-tab-maker :tabData="tabData" :currentTabIndex="setCurrentTab" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import ModelPlanlamaForm from './components/ModelPlanlamaForm.vue';
import ModelPlanlamaDesignForm from './components/Design/ModelDesignForm.vue';
import { useModelPlanlamaState } from './composables/useModelPlanlamaState';
import { showModelPlanning, getLabels } from './helpers/modelPlanningApiService';
import { useUretimPlanlamaState } from '@/views/UretimPlanlama/Form/composables/useUretimPlanlamaState';
// import { showModelPlanning } from './helpers/modelPlanningApiService';
export default {
  created() {
    document.title = 'Model Planlama';
  },
  setup(props, { root }) {
    const {
      initDepots,
      initExchangeRates,
      initStockUnits,
      initCollections,
      initYikamaIbareleri,
      initVaryantEtiket,
      initVaryantPaketleme,
      initPrimaryMaterials,
      initProcesses,
      isConfirmedDisabled,
      isPrototypeDisabled,
      initColors,
      initSizes,
      initDimensionPhrases,
      initShuttles,
      initDressPhrases,
      initBranches,
      initRecipeGroups,
      initInformationPhrases,
      initMachines,
    } = useModelPlanlamaState();

    const { getStations } = useUretimPlanlamaState();

    const tabData = computed(() => {
      return [
          {
            label: 'Modelhane',
            component: ModelPlanlamaDesignForm
          },
          {
            label: 'Prototip',
            component: ModelPlanlamaForm,
            props: { stateKey: 'prototype' },
            disabled: isPrototypeDisabled.value
          },
          {
            label: 'Onaylı',
            component: ModelPlanlamaForm,
            props: { stateKey: 'confirmed' },
            disabled: isConfirmedDisabled.value,
          }
      ];
    });

    const setCurrentTab = computed(() => {
      return !isConfirmedDisabled.value ? 2 : !isPrototypeDisabled ? 1 : 0;
    });

    const initializeApis = async () => {
      getLabels();
      initBranches();
      initDimensionPhrases();
      initShuttles();
      initDepots();
      initStockUnits();
      initCollections();
      initYikamaIbareleri();
      initVaryantEtiket();
      initVaryantPaketleme();
      initPrimaryMaterials();
      initProcesses();
      initColors();
      initSizes();
      initDressPhrases();
      getStations();
      initRecipeGroups();
      initInformationPhrases();
      initMachines();
      await initExchangeRates();
      if (root.$route.params.id) {
       showModelPlanning(root.$route.params.id);
      }
    };
    initializeApis();

    return {
      tabData,
      setCurrentTab
    };
  }
};
</script>

<style lang="scss" scoped></style>
