<template>
    <div id="manufacture-details" class="flex flex-wrap flex-col md:flex-row">
        <wo-table id="manufacture-table" v-for="(detail, index) in localData.manufacture_details" :key="index">
            <wo-tr><wo-th colspan="3" style="font-size: 1.3rem!important;">{{index + 1}}.DENEME</wo-th></wo-tr>
            <wo-tr>
                <wo-th v-for="title in titles" :key="title">{{ title }}</wo-th>
            </wo-tr>
            <wo-tr v-for="(row, rowIndex) in detail" :key="rowIndex">
                <wo-td class="text-center">{{ row['ÜRETİLEN PARÇA'] }}</wo-td>
                <wo-td> <base-input class="text-info-right" v-model="row['HAM ÖLÇÜ']"/> </wo-td>
                <wo-td> <wo-int-input class="text-info-right" v-model="row['DAKİKA']"/> </wo-td>
                <wo-td>
                    <wo-select v-model="row['SANİYE']" :data="seconds()" elementClasses="w-26">
                        <template #default="{ item }">
                            <wo-select-item
                                :key="`second-${item}`"
                                :itemValue="item"
                                :text="item"
                            />
                        </template>
                    </wo-select>
                </wo-td>
            </wo-tr>
            <wo-tr class="text-center">
                <wo-td class="text-right flex"><base-input type="text" v-model="addedPiece[index]" @v-on:keyup.enter="addPiece(index)"/>
                    <feather-icon
                        icon="PlusIcon"
                        style="cursor: pointer;"
                        class="h-5 w-5 align-middle plusIcon text-success ml-2 mt-1"
                        @click="addPiece(index)"
                    />
                </wo-td>
            </wo-tr>
            <wo-tr>
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-td class="text-right" colspan="2" style="font-weight: bold">TOPLAM: {{ totalMinute(index) || 0}}</wo-td>
        </wo-tr>
        </wo-table>
        <feather-icon
            icon="PlusIcon"
            style="cursor: pointer;"
            class="h-7 w-7 align-middle plusIcon text-success ml-2 mt-1"
            @click="addTable"
        />
    </div>
</template>
<script>
import { computed, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
export default {
    setup() {
        const { state } = useModelPlanlamaState();

        const seconds = () => {
            const arr = [];
            for (let i = 0; i <= 59; i++) {
                arr.push(String(i));
            }
            return arr;
        };

        const localData = computed(() => state['design']);

        const titles = ["ÜRETİLEN PARÇA", "HAM ÖLÇÜ", "DAKİKA", "SANİYE"];

        const addedPiece = ref([]);

        const addPiece = (tableIndex) => {
            if (addedPiece.value[tableIndex] && addedPiece.value[tableIndex] !== "") {
                localData.value.manufacture_details[tableIndex].push({"ÜRETİLEN PARÇA": "", "HAM ÖLÇÜ": null, "DAKİKA": 0, "SANİYE": 0});
                const lastIndex = localData.value.manufacture_details[tableIndex].length - 1;
                localData.value.manufacture_details[tableIndex][lastIndex]['ÜRETİLEN PARÇA'] = addedPiece.value[tableIndex];
                addedPiece.value[tableIndex] = "";
            }
        };
        
        const totalMinute = (tableIndex) => {
            const seconds = localData.value.manufacture_details[tableIndex].map(data => data.SANİYE).reduce((a, b) => a + parseInt(b), 0);
            const minutes = localData.value.manufacture_details[tableIndex].map(data => data.DAKİKA).reduce((a, b) => a + parseInt(b), 0);
            if (tableIndex !== undefined) {
                return `${parseFloat(minutes + seconds / 60).toFixed(0)} dakika ${seconds % 60} saniye`;
            }
        };

        const addTable = () => {
            localData.value.manufacture_details.push([]);
        };

        return {
            addedPiece,
            addPiece,
            totalMinute,
            titles,
            localData,
            addTable,
            seconds
        };
    }
};

</script>

<style lang="scss">
#manufacture-details {
    
    .wo-table-parent {
        @media (max-width: 768px) {
            width: 100%;
        }
        margin-right: 10px;
        margin-bottom: 15px;
        width: 49%;
    }
}

.text-info-right {
    input {
        text-align: right;
    }
}
</style>