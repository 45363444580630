<template>
        <OrderModal
            :stateKey="stateKey"
            :orderModal="depotOrderModal"
            :columns="columns"
            :depotNames="depotNames"
            :dataToSend="dataToSend"
            @orderModal="$emit('depotOrderModal', $event)"
            orderType="depot"
            :isInvoce="false"
            title="Depo Sipariş Oluştur"
        >
            <template #quickSearches>
                <div class="d-inline flex">
                    <label class="mr-2">Kaynak Depo</label>
                    <wo-quick-search
                        v-model="depotNames.sourceDepotName"
                        queryParameters="?tablo=depolar&q="
                        @selected="handleDepot($event, 'sourceDepotID', 'sourceDepotName')"
                        :itemRenderer="nameAndCodeRenderer"
                    />
                    <label class="ml-4 mr-2">Hedef Depo</label>
                        <wo-quick-search
                            v-model="depotNames.targetDepotName"
                            queryParameters="?tablo=depolar&q="
                            @selected="handleDepot($event, 'targetDepotID', 'targetDepotName')"
                            :itemRenderer="nameAndCodeRenderer"
                        />
                </div>
            </template>
            <template #depotOrderTypes>
                <div class="d-flex flex-column mt-2">
                    <vs-radio title="Konsinye Sevk" vs-value="1" v-model="depotOrderType" vs-name="depot-type">Konsinye Sevk</vs-radio>
                    <vs-radio title="Depolar Arası Sevk" vs-value="2" v-model="depotOrderType" vs-name="depot-type" class="ml-2">Depolar Arası Sevk</vs-radio>
                </div>
            </template>
        </OrderModal>

</template>

<script>
import { computed, onUpdated, ref, watch } from '@vue/composition-api';
import OrderModal from './OrderModal';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';

export default {
    name: "PurchaseOrderModal",
    components: {
        OrderModal
    },
    props: {
        stateKey: String,
        depotOrderModal: Boolean
    },    
    setup() {
        const { state, depots, currencyInputValue} = useModelPlanlamaState();

        const infosData = computed(() => {
            return state['confirmed'].info;
        });
        
        // const localExchangeRates = computed(() => {
        //     return exchangeRates.value['confirmed'];
        // });


        const columns = [
            "Kod",
            "Stok Adı",
            "Renk",
            "Beden",
            "Sip. Miktarı",
            "Birim",
            "Maliyet",
            "Birim Fiyat",
            "Döviz Birim Fiyat",
            "Döviz Cinsi",
            "Kur",
            "Tutar",
            "Stok Durum",
            "Durum",
            "K. Depo",
            "H. Depo"
        ];

        const depotOrderType = ref('1');

        const depotNames = ref({
            sourceDepotName: '',
            targetDepotName: '',
        });

        const dataToSend = computed(() => {
            return {
                rowType: '1',
                waybill: '1',
                sort: '3',
                kind: '1',
                sourceDepotID: depotNames.value.sourceDepotID,
                targetDepotID: depotNames.value.targetDepotID,
                type: '25',
                invoiceType: '10',
                currency: infosData.value.currentCurrency,
                exchangeRate: currencyInputValue.value['confirmed'],
                modelID: infosData.value.draftID,
            };
        });

        watch(
            () => depotOrderType.value,
            () => {
                dataToSend.value.type = depotOrderType.value === '1' ? '25' : '26';
                dataToSend.value.invoiceType = depotOrderType.value === '1' ? '10' : '11';
            }
        );

        const handleDepot = (item, idKey, nameKey) => {
            if (item.isSelected) {
                dataToSend.value[idKey] = item.id;
                depotNames.value[nameKey] = item.isim;
            } else {
                dataToSend.value[idKey] = '';
                depotNames.value[nameKey] = '';
            }
        };

        onUpdated(() => {
            if (depotNames.value.targetDepotName === '') {
                depotNames.value.targetDepotName = depots.value.find(depot => depot.id === infosData.value.depot) ? depots.value.find(depot => depot.id === infosData.value.depot).isim : '';
                dataToSend.value.targetDepotID = infosData.value.depot;
            }
        });

        return {
            columns,
            handleDepot,
            dataToSend,
            nameAndCodeRenderer,
            depotNames,
            infosData,
            depotOrderType,
            // localExchangeRates
        };        
    }
};
</script>

<style>

</style>