<template>
  <div>
    <wo-table>
      <template #thead>
        <wo-th v-for="col in tableColumns" :key="`header-${col.valueKey}`">
          {{ col.title }}
        </wo-th>
      </template>
      <template>
        <wo-tr
          v-for="(item, index) in addressList"
          :key="`addresses-${index}`"
          :class="{ 'text-primary': editedAddress === item.id }"
        >
          <wo-td
            v-for="col in tableColumns"
            :key="`data-${index}-${col.valueKey}`"
          >
            <span v-if="col.valueKey !== ''">
              {{ item[col.valueKey] }}
            </span>
            <div class="flex space-x-4 justify-center" v-else>
              <vx-tooltip text="Düzenle">
                <feather-icon
                  icon="EditIcon"
                  class="icon-style"
                  @click="() => editAddress(item.id)"
                />
              </vx-tooltip>
              <vx-tooltip text="Sil">
                <feather-icon
                  icon="XIcon"
                  class="icon-style text-danger"
                  @click="() => deleteAddress(item.id)"
                />
              </vx-tooltip>
            </div>
          </wo-td>
        </wo-tr>
      </template>
    </wo-table>
  </div>
</template>

<script>
import { errorConfirmDialog } from '@/helpers/errorDialogs';

import { useMMAddressesState } from './useMMAddressesState';
import { deleteAddressAPI } from './mmAddressApi';

export default {
  name: 'MiniModuleAdressesTable',
  props: {
    source: String
  },
  setup() {
    const tableColumns = [
      { title: 'Adres İsmi', valueKey: 'name' },
      { title: 'Ülke', valueKey: 'countryName' },
      { title: 'İl', valueKey: 'provinceName' },
      { title: 'İlçe', valueKey: 'districtName' },
      { title: 'Mahalle', valueKey: 'neighbourhood' },
      { title: 'Telefon', valueKey: 'telephone' },
      { title: 'Sokak', valueKey: 'street' },
      { title: 'Kapı No', valueKey: 'doorNo' },
      { title: 'Açıklama', valueKey: 'explanation' },
      { title: 'Depo', valueKey: 'depotName' },
      { title: 'Zip Kodu', valueKey: 'zipCode' },
      { title: 'İşlemler', valueKey: '' }
    ];

    const { addressList, editedAddress } = useMMAddressesState();

    const editAddress = id => {
      editedAddress.value = id;
    };

    const deleteAddress = id => {
      errorConfirmDialog('Adresi silmek istediğinize emin misiniz?', () => {
        deleteAddressAPI(id);
      });
    };

    return {
      tableColumns,
      addressList,
      editedAddress,
      editAddress,
      deleteAddress
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-style {
  @apply cursor-pointer w-5 h-5;
}
</style>
