import { VueMaskFilter } from 'v-mask';

export default function formatTel(tel) {
  const pureValueLength = tel.replaceAll(/[( )]/g, '').length;
  let addedZeros = '';

  if (pureValueLength === 7) {
    addedZeros = '0090000';
  } else if (pureValueLength === 10) {
    addedZeros = '0090';
  } else if (pureValueLength === 11) {
    addedZeros = '009';
  }

  addedZeros = `${addedZeros}${tel}`;
  const formattedVal = VueMaskFilter(addedZeros, '(####) (###) ### ## ##');
  return formattedVal;
}
