import { detailsFromClient } from '@/helpers/clientToServerConversion';
import formatTel from './../../../../helpers/formatTel';

const objToString = obj => {
  return Object.keys(obj);
};

export function hydrateFromClient(state, prevalentID) {
  const result = {};
  result.edit_id = state.formID;
  result.isim = state.name;
  result.durum = state.approvalStatus;
  result.yetkili = state.authorizedName;
  result.yetkili_telefon = state.authorizedPhone;
  result.yetkili_email = state.authorizedEmail;
  result.sozlesme_tarihi = state.agreementDate;
  result.baslangic_tarih = state.startDate;
  result.bitis_tarih = state.endDate;
  result.kayit_id = prevalentID;
  result.katilanlar = state.participants;
  result.urun_gruplari = objToString(state.productGroups);
  result.urun_ureticiler = objToString(state.producers);
  result.urun_markalar = objToString(state.brand);
  result.normal_iskonto = state.normalDiscount1;
  result.normal_iskonto1 = state.normalDiscount2;
  result.normal_iskonto2 = state.normalDiscount3;
  result.normal_iskonto_aciklama = state.normalDiscountExplanation;
  result.normal_vade = state.normalDiscountExpiry;
  result.ozel_iskonto = state.specialDiscount1;
  result.ozel_iskonto1 = state.specialDiscount2;
  result.ozel_iskonto2 = state.specialDiscount3;
  result.ozel_iskonto_aciklama = state.specialDiscountExplanation;
  result.ozel_vade = state.specialDiscountExpiry;
  result.sozlesme_metin = state.agreementDetailsSelect;
  result.pesin_iskonto = state.advanceDiscount1;
  result.pesin_iskonto1 = state.advanceDiscount2;
  result.pesin_iskonto2 = state.advanceDiscount3;
  result.pesin_iskonto_aciklama = state.advanceDiscountExplanation;
  result.pesin_vade = state.advanceDiscountExpiry;
  result.insert_adet = state.insertCostPiece;
  result.insert_tutar = state.insertCostPrice;
  result.acilis_iskonto = state.newBranchOpeningDiscount;
  result.acilis_iskonto_aciklama = state.newBranchOpeningExplanation;
  result.acilis_iskonto_sure = state.newBranchOpeningTime;
  result.acilis_iskonto_sure_aciklama = state.newBranchOpeningTimeExplanation;
  result.acilis_promosyon = state.openingAnniversaryEventPromotion;
  result.ozel_acilis_vade = state.specialExpiryTime;
  result.ozel_acilis_vade_aciklama = state.specialExpiryTimeExplanation;
  result.ozel_pesin_iskonto = state.specialAdvanceDiscount;
  result.ozel_pesin_iskonto_aciklama = state.specialAdvanceDiscountExplanation;
  result.pesin_iskonto_vade = state.newBranchAdvanceDiscountExpiry;
  result.pesin_iskonto_vade_aciklama =
    state.newBranchAdvanceDiscountExpiryExplanation;
  result.katilim_bedeli = state.newBranchOpeningParticipationCost;
  result.katilim_bedeli_aciklama =
    state.newBranchOpeningParticipationCostExplanation;
  result.insert_iskonto = state.insertDiscount;
  result.sozlesme_taksit = state.annualAgreementCostInstallment;
  result.sozlesme_bedeli = state.annualAgreementCost;
  result.sozlesme_bedeli_aciklama = state.annualAgreementCostExplanation;

  // ALTTAKILER DUZENLENECEK
  result.ciro_prim_aylik = detailsFromClient(
    state.turnoverPremiumArray,
    'monthlyCondition',
    false
  );
  result.ciro_prim_aylik_aciklama = detailsFromClient(
    state.turnoverPremiumArray,
    'monthlyExplanation',
    false
  );
  result.ciro_prim_aylik_kosul = detailsFromClient(
    state.turnoverPremiumArray,
    'monthlyRate',
    false
  );
  result.ciro_prim_3aylik = detailsFromClient(
    state.turnoverPremiumArray,
    'threeMonthlyCondition',
    false
  );
  result.ciro_prim_3aylik_aciklama = detailsFromClient(
    state.turnoverPremiumArray,
    'threeMonthlyExplanation',
    false
  );
  result.ciro_prim_3aylik_kosul = detailsFromClient(
    state.turnoverPremiumArray,
    'threeMonthlyRate',
    false
  );
  result.ciro_prim_yillik = detailsFromClient(
    state.turnoverPremiumArray,
    'annuallyCondition',
    false
  );
  result.ciro_prim_yillik_aciklama = detailsFromClient(
    state.turnoverPremiumArray,
    'annuallyExplanation',
    false
  );
  result.ciro_prim_yillik_kosul = detailsFromClient(
    state.turnoverPremiumArray,
    'annuallyRate',
    false
  );
  // USTTEKILER FORM SERVE DE DUZENLENECEK

  result.aktivite_prim_aylik = state.activityPremiumMonthly;
  result.aktivite_prim_3aylik = state.activityPremiumThreeMonthly;
  result.aktivite_prim_yillik = state.activityPremiumAnnually;
  result.lojistik_prim_aylik = state.logisticPremiumMonthly;
  result.lojistik_prim_3aylik = state.logisticPremiumThreeMonthly;
  result.lojistik_prim_yillik = state.logisticPremiumAnnually;
  result.fire_prim_aylik = state.lostPremiumMonthly;
  result.fire_prim_3aylik = state.lostPremiumThreeMonthly;
  result.fire_prim_yillik = state.lostPremiumAnnually;
  result.prim_iade_durum = state.premiumRefund;
  result.prim_ff_durum = state.premiumPriceDifference;
  result.prim_kdv_durum = state.premiumDifferentVatRates;
  result.mal_fazlasi = state.promotionSurplus;
  result.teslim_suresi = state.deliveryTime;
  result.gondol_bedeli = state.additionalSettlementPiece;
  result.gondol_bedeli_carpan = state.additionalSettlementPrice;
  result.iade_durum = state.rebateSituation;
  result.fiyat_liste = state.priceList;
  result.medya_reklam = state.radioTvInternetAdPromotion;
  result.yildonumu_etkinlikler = state.anniversaryEventCost;
  result.fiyat_gecis = state.priceTransition;
  result.genel_vs = state.annualGoalPlan;
  result.notlar = state.deliveryNotes;
  result.uretici = state.producerTitle;
  result.uretici_adres = state.producerAddress;
  result.promosyon_aciklama = state.promotionsExplanation;
  return result;
}

function turnoverPremiumBuilder(serverData) {
  let {
    ciro_prim_aylik,
    ciro_prim_aylik_aciklama,
    ciro_prim_aylik_kosul,
    ciro_prim_3aylik,
    ciro_prim_3aylik_aciklama,
    ciro_prim_3aylik_kosul,
    ciro_prim_yillik,
    ciro_prim_yillik_aciklama,
    ciro_prim_yillik_kosul
  } = serverData;

  ciro_prim_aylik = ciro_prim_aylik.split('\n');
  ciro_prim_aylik_aciklama = ciro_prim_aylik_aciklama.split('\n');
  ciro_prim_aylik_kosul = ciro_prim_aylik_kosul.split('\n');
  ciro_prim_3aylik = ciro_prim_3aylik.split('\n');
  ciro_prim_3aylik_aciklama = ciro_prim_3aylik_aciklama.split('\n');
  ciro_prim_3aylik_kosul = ciro_prim_3aylik_kosul.split('\n');
  ciro_prim_yillik = ciro_prim_yillik.split('\n');
  ciro_prim_yillik_aciklama = ciro_prim_yillik_aciklama.split('\n');
  ciro_prim_yillik_kosul = ciro_prim_yillik_kosul.split('\n');

  const returnArray = [];

  for (const i in ciro_prim_aylik) {
    returnArray.push({
      monthlyCondition: ciro_prim_aylik[i],
      monthlyExplanation: ciro_prim_aylik_aciklama[i],
      monthlyRate: ciro_prim_aylik_kosul[i],
      threeMonthlyCondition: ciro_prim_3aylik[i],
      threeMonthlyExplanation: ciro_prim_3aylik_aciklama[i],
      threeMonthlyRate: ciro_prim_3aylik_kosul[i],
      annuallyCondition: ciro_prim_yillik[i],
      annuallyExplanation: ciro_prim_yillik_aciklama[i],
      annuallyRate: ciro_prim_yillik_kosul[i]
    });
  }

  return returnArray;
}

export function hydrateFromServer(state, serverData) {
  state.formID = serverData.edit_id;
  state.name = serverData.isim;
  state.participants = serverData.katilanlar;
  state.agreementDate = serverData.sozlesme_tarihi;
  state.startDate = serverData.baslangic_tarih;
  state.endDate = serverData.bitis_tarih;
  state.authorizedName = serverData.yetkili;
  state.producerTitle = serverData.uretici;
  state.authorizedPhone = formatTel(serverData.yetkili_telefon);
  state.producerAddress = serverData.uretici_adres;
  state.authorizedEmail = serverData.yetkili_email;
  state.approvalStatus = serverData.durum;
  state.productGroups = !Array.isArray(serverData.urun_gruplari)
    ? serverData.urun_gruplari
    : {}; // duzenlenecek
  state.producers = !Array.isArray(serverData.urun_ureticiler)
    ? serverData.urun_ureticiler
    : {}; // duzenlenecek
  state.brand = !Array.isArray(serverData.urun_markalar)
    ? serverData.urun_markalar
    : {}; // duzenlenecek

  state.normalDiscount1 = serverData.normal_iskonto;
  state.normalDiscount2 = serverData.normal_iskonto1;
  state.normalDiscount3 = serverData.normal_iskonto2;
  state.normalDiscountExplanation = serverData.normal_iskonto_aciklama;
  state.normalDiscountExpiry = serverData.normal_vade;

  state.specialDiscount1 = serverData.ozel_iskonto;
  state.specialDiscount2 = serverData.ozel_iskonto1;
  state.specialDiscount3 = serverData.ozel_iskonto2;
  state.specialDiscountExplanation = serverData.ozel_iskonto_aciklama;
  state.specialDiscountExpiry = serverData.ozel_vade;

  state.advanceDiscount1 = serverData.pesin_iskonto;
  state.advanceDiscount2 = serverData.pesin_iskonto1;
  state.advanceDiscount3 = serverData.pesin_iskonto2;
  state.advanceDiscountExplanation = serverData.pesin_iskonto_aciklama;
  state.advanceDiscountExpiry = serverData.pesin_vade;

  state.newBranchOpeningDiscount = serverData.acilis_iskonto;
  state.newBranchOpeningExplanation = serverData.acilis_iskonto_aciklama;
  state.newBranchOpeningTime = serverData.acilis_iskonto_sure;
  state.newBranchOpeningTimeExplanation =
    serverData.acilis_iskonto_sure_aciklama;

  state.specialExpiryTime = serverData.ozel_acilis_vade;
  state.specialExpiryTimeExplanation = serverData.ozel_acilis_vade_aciklama;
  state.specialAdvanceDiscount = serverData.ozel_pesin_iskonto;
  state.specialAdvanceDiscountExplanation =
    serverData.ozel_pesin_iskonto_aciklama;

  state.newBranchAdvanceDiscountExpiry = serverData.pesin_iskonto_vade;
  state.newBranchAdvanceDiscountExpiryExplanation =
    serverData.pesin_iskonto_vade_aciklama;
  state.newBranchOpeningParticipationCost = serverData.katilim_bedeli;
  state.newBranchOpeningParticipationCostExplanation =
    serverData.katilim_bedeli_aciklama;

  state.anniversaryEventCost = serverData.yildonumu_etkinlikler;

  state.turnoverPremiumArray = turnoverPremiumBuilder(serverData);

  state.activityPremiumMonthly = serverData.aktivite_prim_aylik;
  state.activityPremiumThreeMonthly = serverData.aktivite_prim_3aylik;
  state.activityPremiumAnnually = serverData.aktivite_prim_yillik;

  state.logisticPremiumMonthly = serverData.lojistik_prim_aylik;
  state.logisticPremiumThreeMonthly = serverData.lojistik_prim_3aylik;
  state.logisticPremiumAnnually = serverData.lojistik_prim_yillik;

  state.lostPremiumMonthly = serverData.fire_prim_aylik;
  state.lostPremiumThreeMonthly = serverData.fire_prim_3aylik;
  state.lostPremiumAnnually = serverData.fire_prim_yillik;

  state.premiumRefund = serverData.prim_iade_durum;
  state.premiumPriceDifference = serverData.prim_ff_durum;
  state.premiumDifferentVatRates = serverData.prim_kdv_durum;

  state.insertCostPiece = serverData.insert_adet;
  state.insertCostPrice = serverData.insert_tutar;

  state.additionalSettlementPiece = serverData.gondol_bedeli;
  state.additionalSettlementPrice = serverData.gondol_bedeli_carpan;

  state.insertDiscount = serverData.insert_iskonto;
  state.promotionSurplus = serverData.mal_fazlasi;

  state.annualAgreementCostInstallment = serverData.sozlesme_taksit;
  state.annualAgreementCost = serverData.sozlesme_bedeli;
  state.annualAgreementCostExplanation = serverData.sozlesme_bedeli_aciklama;
  state.openingAnniversaryEventPromotion = serverData.acilis_promosyon;
  state.radioTvInternetAdPromotion = serverData.medya_reklam;
  state.promotionsExplanation = serverData.promosyon_aciklama;

  state.deliveryTime = serverData.teslim_suresi;
  state.rebateSituation = serverData.iade_durum;
  state.priceList = serverData.fiyat_liste;
  state.priceTransition = serverData.fiyat_gecis;
  state.annualGoalPlan = serverData.genel_vs;
  state.deliveryNotes = serverData.notlar;
  state.agreementDetailsSelect = serverData.sozlesme_metin;
  state.agreementDetailsList = serverData.sozlesme_metin2;
}
