<template>
    <div class="model-design-description">
        <h4>AÇIKLAMA</h4>
        <wo-text-area v-model="localData.design_description" />
    </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
export default {
    setup() {
        const { state } = useModelPlanlamaState();
        const localData = computed(() => state['design']);
        return {
            localData
        };
    }
};
</script>

<style lang="scss">
.model-design-description {
    width: 100%;
    margin-top: 50px;
    padding: 5px;
    .vs-width {
        width: 90%;
        margin-top: 10px;
    }
}
</style>