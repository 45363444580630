<template>
  <div class="space-y-4">
    <wo-double-form>
      <template #firstLabel>İsim Soyisim</template>
      <template #firstElement>{{ state.fullName }}</template>
    </wo-double-form>
    <wo-double-form>
      <template #firstLabel>E-posta</template>
      <template #firstElement>{{ state.email }}</template>
    </wo-double-form>
    <wo-double-form>
      <template #firstLabel>Telefon</template>
      <template #firstElement>{{ state.phone }}</template>
    </wo-double-form>
    <wo-double-form>
      <template #firstLabel>Başvurduğu İlan</template>
      <template #firstElement>
        <a
          class="underlined-hovered-link"
          :href="`${$baseURL}/job_listing/${state.jobID}/duzenle`"
          target="_blank"
        >
          {{ state.jobName }}
        </a>
      </template>
    </wo-double-form>
    <wo-double-form>
      <template #firstLabel>
        <span class="underlined-hovered-link" @click="openStatusPopup">
          Başvuru Durumu
        </span>
      </template>
      <template #firstElement>{{
        statusIndexNameMap[state.status] || 'Beklemede'
      }}</template>
    </wo-double-form>
    <wo-double-form>
      <template #firstLabel>CV</template>
      <template #firstElement>
        <a
          class="whitespace-nowrap underlined-hovered-link"
          :href="state.cv"
          target="_blank"
        >
          CV linki
        </a>
      </template>
    </wo-double-form>
    <JobApplicationFeedback />
    <JobApplicationStatusPopup :active.sync="statusPopup" />
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

import JobApplicationStatusPopup from './components/JobApplicationStatusPopup.vue';
import JobApplicationFeedback from './components/JobApplicationFeedback.vue';
import { useJobApplicationState } from './composables/useJobApplicationState';
import { fetchApplicantData } from './helpers/jobApplicationAPIService';

export default {
  components: {
    JobApplicationStatusPopup,
    JobApplicationFeedback
  },
  setup(props, { root }) {
    const {
      getStatusList,
      statusIndexNameMap,
      feedbackList,
      getFeedbackList,
      statusPopup,
      openStatusPopup
    } = useJobApplicationState();
    getStatusList();

    const state = reactive({
      id: '',
      fullName: '',
      phone: '',
      email: '',
      cv: '',
      jobID: '',
      jobName: '',
      notes: '',
      status: ''
    });

    const getApplicantData = async () => {
      const data = await fetchApplicantData(root.$route.params.id);
      Object.assign(state, data);
    };
    getApplicantData();

    const setStatusToState = async () => {
      await getFeedbackList(root.$route.params.id);
      state.status = feedbackList.value[feedbackList.value.length - 1].status;
    };
    setStatusToState();

    return {
      state,
      statusPopup,
      openStatusPopup,
      statusIndexNameMap
    };
  }
};
</script>

<style lang="scss" scoped></style>
