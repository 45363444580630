<template>
  <div class="p-4">
    <div class="text-xl text-medium mb-4">
      {{ title }}
    </div>
    <div>
      <wo-table>
        <template #thead>
          <wo-th
            v-if="
              (source === 'stocks' || source === 'semiProducts') && !isEditing
            "
          ></wo-th>
          <wo-th
            v-for="col in processedTableColumns"
            :key="`header-${col.valueKey}`"
          >
            {{ col.title }}
          </wo-th>
        </template>
        <template #default>
          <template v-for="(row, index) in localData">
            <wo-tr
              v-if="editedRow !== index"
              :key="`${source}-${index}`"
              :class="{
                'bg-red-200': row.entryID === '0' || row.entryID === 0
              }"
            >
              <wo-td
                v-if="
                  (source === 'stocks' || source === 'semiProducts') &&
                  !isEditing
                "
              >
                <vs-checkbox
                  v-if="!row.isSelected && !row.slipIsCreated"
                  v-model="row['isProductionData']"
                  title="Reçetesi olan ürünler için seçilebilir"
                />
                <feather-icon
                v-else
                icon="CornerUpRightIcon"
                id="routeToBom"
                title="Üretim Fişine Git"
                @click="$window.open(`${$window.location.origin}/?s=receteler&duzenle=${row.productionId}`)"
                svgClasses="h-4 w-4 mb-1 stroke-current hover:text-primary"
                />
              </wo-td>
              <wo-td
                v-for="col in processedTableColumns"
                :key="`column-${col.valueKey}`"
                class="text-center"
              >
                <span v-if="col.key === 'primaryMaterial'">{{
                  getPrimaryMaterialById(row[col.key])
                }}</span>
                <span v-else-if="col.key === 'process'">{{
                  getProcessById(row[col.key])
                }}</span>
                <span v-else-if="col.key === 'entryName'">
                  {{ row[col.key] }}
                  <feather-icon
                    v-if="source === 'semiProducts' && row[col.key]"
                    icon="PlusIcon"
                    class="h-5 w-5 align-middle plusIcon text-success"
                    @click="handleSubSemiProductModal(index)"
                  />
                </span>
                <span v-else-if="col.key === 'unit'">
                  {{ stockUnitNameFinder(row[col.key]) }}
                </span>
                <!-- <span v-else-if="col.key === 'amount'">
                  {{ typeof row[col.key] }}
                  {{ numToTrStr(row[col.key], 3) }}
                </span> -->
                <span v-else-if="col.key === 'entryType'">
                  {{ entryTypes[row[col.key]] }}
                </span>
                <span v-else-if="col.key === 'colorsize'"
                  >{{ row['color'] }} {{ row['size'] }}</span
                >
                <span v-else-if="col.key === 'cost'">
                  {{ totalCost(index) }}
                </span>
                <span v-else-if="col.key !== ''">
                  {{ row[col.key] }}
                </span>

                <div v-else class="flex justify-center space-x-2">
                  <vx-tooltip text="Düzenle">
                    <feather-icon
                      icon="EditIcon"
                      class="icon-style"
                      @click="() => editRow(index)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Sil">
                    <feather-icon
                      icon="XIcon"
                      class="icon-style text-danger"
                      @click="() => deleteRow(index)"
                    />
                  </vx-tooltip>
                </div>
              </wo-td>
              <SubSemiProductModal
                v-if="subSemiProductModal && index === selectedRow"
                :semiProductIndex="index"
                :subSemiProductModal="subSemiProductModal"
                @subSemiProductModal="subSemiProductModal = $event"
                :stateKey="stateKey"
                @saveNewSubSemiProduct="newSubSemiProduct"
                :entryID="row.entryID"
              />
            </wo-tr>
            <ModelPlanlamaDetailsEditableRow
              v-else
              :stateKey="stateKey"
              :source="tempRowData"
              :entryTypeEditable="false"
              :key="`edited-${source}-${index}`"
            >
              <template #processes>
                <div class="flex justify-around items-center">
                  <vx-tooltip text="Kaydet">
                    <feather-icon
                      icon="CheckIcon"
                      class="icon-style text-success"
                      ref="saveRowRef"
                      @click="() => saveRow(index)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="İptal">
                    <feather-icon
                      icon="XIcon"
                      class="icon-style text-danger"
                      @click="closeEditedRow"
                    />
                  </vx-tooltip>
                </div>
              </template>
            </ModelPlanlamaDetailsEditableRow>
          </template>
          <!-- <wo-tr>
            <wo-td class="text-center">TOPLAM</wo-td>
            <wo-td></wo-td>
            <wo-td></wo-td>
            <wo-td class="text-center">{{ totalAmount }}</wo-td>
            <wo-td></wo-td>
            <wo-td class="text-center">{{ totalCost }}</wo-td>
            <wo-td></wo-td>
            <wo-td></wo-td>
            <wo-td></wo-td>
            <wo-td></wo-td>
          </wo-tr> -->
        </template>
      </wo-table>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { deleteDetailsRow } from '../../helpers/modelPlanningApiService';
import {
  validateEditableRow,
  editableRowValidationErrorMessage
} from '../../helpers/modelPlanningEditableRowValidator';

import ModelPlanlamaDetailsEditableRow from './ModelPlanlamaDetailsEditableRow.vue';
import SubSemiProductModal from './Modals/SubSemiProductModal.vue';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryConfirmDialog } from '@/helpers/primaryDialogs';
import { getExchangeRatesFromAPI } from '../../../../Teklifler/TekliflerForm/consumables/useCurrency.js';
import { numToTrStr, trStrToNum } from '@/helpers/NumberController';
import getColumns from '@/config/menu/model-planning/detailsHeader';
// import { strToNumToStr } from '@/helpers/NumberController';
// import { getCurrentInstance } from "vue";

export default {
  name: 'ModelPlanlamaDetailsTableWithHeader',
  components: {
    ModelPlanlamaDetailsEditableRow,
    SubSemiProductModal
  },

  data() {
    return {
      exchangeRates: {}
    };
  },

  props: {
    stateKey: String,
    clickByEnter: Boolean,
    title: String,
    source: String,
    subSourceIndex: Number
  },

  mounted() {
    this.exchangeRates = getExchangeRatesFromAPI;
  },

  setup(props) {
    const {
      state,
      detailsTableFormTemplate,
      entryTypes,
      stockUnits,
      primaryMaterials,
      processes
    } = useModelPlanlamaState();

    const localData = computed({
      get() {
        if (props.subSourceIndex) {
          return state[props.stateKey].details[props.source][
            props.subSourceIndex
          ];
        }
        return state[props.stateKey].details[props.source];
      },
      set(value) {
        if (props.subSourceIndex) {
          state[props.stateKey].details[props.source][props.subSourceIndex] =
            value;
        }
        state[props.stateKey].details[props.source] = value;
      }
    });

    const getPrimaryMaterialById = id => {
      let name = '';
      primaryMaterials.value.forEach(primaryMaterial => {
        if (primaryMaterial.id === parseInt(id)) {
          name = primaryMaterial.isim;
        }
      });
      return name;
    };

    const getProcessById = id => {
      let name = '';
      processes.value.forEach(process => {
        if (process.id === parseInt(id)) {
          name = process.isim;
        }
      });
      return name;
    };

    const editedRow = ref(-1);

    const isEditing = ref(false);

    const sourceToTypeObj = {
      semiProducts: '0',
      stocks: '1',
      expenses: '2'
    };

    const processedTableColumns = (() => {
      return getColumns(sourceToTypeObj[props.source], 'table');
    })();

    watch(isEditing, newValue => {
      if (newValue && props.source !== 'expenses') {
        processedTableColumns.splice(7, 1);
      } else if (!newValue && props.source !== 'expenses') {
        processedTableColumns.splice(7, 0, {
          title: 'Renk/Beden',
          key: 'colorsize'
        });
      }
    });

    const selectedRow = ref(null);

    const totalAmount = computed({
      get() {
        let total = 0;
        if (localData.value.length > 0) {
          total = localData.value.reduce((acc, item) => {
            return acc + parseInt(item.amount);
          }, 0);
        }
        return total;
      }
    });

    const totalCost = index => {
      if (
        state[props.stateKey].details[props.source][index]
          .semiProductDetails !== null &&
        state[props.stateKey].details[props.source][index]
          .semiProductDetails !== undefined &&
        state[props.stateKey].details[props.source][index].semiProductDetails
          .length > 0
      ) {
        let total = 0;
        state[props.stateKey].details[props.source][
          index
        ].semiProductDetails.forEach(item => {
          if (item.price !== undefined) {
            total += parseFloat(item.price);
          } else {
            total += item.amount * item.cost;
          }
        });
        state[props.stateKey].details[props.source][index].mainCurrencyPrice =
          numToTrStr(
            trStrToNum(total) *
              trStrToNum(
                state[props.stateKey].details[props.source][index].amount
              ),
            3
          );
        state[props.stateKey].details[props.source][index].cost = numToTrStr(
          total,
          3
        );
        return state[props.stateKey].details[props.source][index].cost;
      }
      return state[props.stateKey].details[props.source][index].cost;
    };

    const stockUnitNameFinder = id => {
      const unit = stockUnits.value.find(a => +a.id === +id);
      return unit ? unit.isim : '';
    };

    const tempRowData = ref(detailsTableFormTemplate());

    const checkIfBomIsCreated = index => {
      if (localData.value[index].slipIsCreated) {
        errorDialog(
          'Üretim fişi oluşturulan bir kayıt üzerinde değişiklik yapamazsınız.'
        );
        return false;
      }
      return true;
    };

    const checkIfAnyStockHasBom = () => {
      const semiProducts = state.confirmed.details.semiProducts;
      const stocks = state.confirmed.details.stocks;
      const result = semiProducts
        .concat(stocks)
        .some(item => item.slipIsCreated);
      if (result) {
        errorDialog(
          "Güncelleme yapabilmek için lütfen üretim ve mal kabul durumunu kontrol ediniz."
        );
      }
      return !result;
    };

    const checkIfDocumentIsConfirmed = () => {
      if (
        props.stateKey === 'prototype' &&
        state[props.stateKey].info.status === '1' &&
        state[props.stateKey].info.id !== 0 &&
        state['confirmed'].info.id !== 0
      ) {
        errorDialog('Onaylanmış dosyayı değiştiremezsiniz');
        return false;
      }
      return true;
    };

    const checkRowIsEditable = index => {
      return {
        stocks: [checkIfBomIsCreated(index), checkIfDocumentIsConfirmed()],
        semiProducts: [
          checkIfBomIsCreated(index),
          checkIfDocumentIsConfirmed()
        ],
        expenses: [checkIfAnyStockHasBom(), checkIfDocumentIsConfirmed()]
      };
    };

    const editRow = index => {
      const result = checkRowIsEditable(index)[props.source].some(
        item => !item
      );
      if (!result) {
        isEditing.value = true;
        editedRow.value = index;
        tempRowData.value = { ...localData.value[index] };
      }
    };

    const deleteRow = index => {
      const result = checkRowIsEditable(index)[props.source].some(
        item => !item
      );
      if (!result) {
        primaryConfirmDialog('Silmek istiyor musunuz?', () => {
          if (localData.value[index].id) {
            deleteDetailsRow(localData.value[index].id);
          }
          localData.value.splice(index, 1);
        });
      }
    };

    const closeEditedRow = () => {
      editedRow.value = -1;
      tempRowData.value = detailsTableFormTemplate();
    };

    const saveRow = () => {
      isEditing.value = false;
      if (validateEditableRow(tempRowData.value)) {
        // localData.value[editedRow.value] = { ...tempRowData.value };
        localData.value.splice(editedRow.value, 1, { ...tempRowData.value });
        editedRow.value = -1;
      } else {
        errorDialog(editableRowValidationErrorMessage);
      }
    };

    const subSemiProductModal = ref(false);

    const subSemiProductIndex = ref(-1);

    const handleSubSemiProductModal = index => {
      // if (localData.value[index].slipIsCreated) {
      //   errorDialog('Üretim fişi oluşturulan bir kayıt üzerinde değişiklik yapamazsınız!');
      // } else {
      selectedRow.value = index;
      subSemiProductIndex.value = index;
      subSemiProductModal.value = true;
      // }
    };

    const newSubSemiProduct = value => {
      state[props.stateKey].details.semiProducts[
        value.semiProductIndex
      ].semiProductDetails = value.subSemiProducts;
    };

    return {
      processedTableColumns,
      entryTypes,
      localData,
      stockUnitNameFinder,
      editedRow,
      tempRowData,
      editRow,
      deleteRow,
      closeEditedRow,
      saveRow,
      subSemiProductModal,
      handleSubSemiProductModal,
      totalAmount,
      totalCost,
      newSubSemiProduct,
      selectedRow,
      getPrimaryMaterialById,
      getProcessById,
      isEditing,
      numToTrStr
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-style {
  @apply cursor-pointer w-5 h-5;
}

#routeToBom {
  cursor: pointer;
}

.plusIcon:hover {
  cursor: pointer;
}
</style>
