<template>
    <OrderModal
        :stateKey="stateKey"
        :orderModal="invoiceModal"
        @orderModal="$emit('invoiceModal', $event)"
        title="Açık Hesap Masraf Alış Faturası Oluştur"
        :isInvoice="true"
        :expenses="expenses"
        :columns="columns"
        :dataToSend="dataToSend"
    >

    </OrderModal>    
</template>

<script>
import { computed, reactive } from '@vue/composition-api';
import OrderModal from './OrderModal';
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';

export default {
    name: "InvoiceModal",
    components: {
        OrderModal
    },
    props: {
        stateKey: String,
        invoiceModal: Boolean
    },
    
    setup(props) {
        
        const { state, currencyInputValue } = useModelPlanlamaState();
        
        const infosData = computed(() => {
            return state['confirmed'].info;
        });

        const expenses = computed(() =>  {
            return state[props.stateKey].details.expenses;
        });

        const dataToSend = reactive({
            waybill: 0,
            //cesit
            type: '3',                       
            // tip
            sort: '2',
            // tur
            kind: '3',
            rowType: '3',
            //cins
            invoiceType: '0',
            currency: infosData.value.currentCurrency,
            exchangeRate: currencyInputValue.value['confirmed'],
            modelID: infosData.value.draftID,
        });

        const columns = [
            "Kod",
            "Stok Adı",
            // "Renk",
            // "Beden",
            "Sip. Miktarı",
            "Birim",
            "Maliyet",
            "Birim Fiyat",
            "Döviz Birim Fiyat",
            "Döviz Cinsi",
            "Kur",
            "Tutar",
            // "Stok Durum",
            // "Durum",
        ];

        return {
            expenses,
            columns,
            dataToSend
        };
    }
};
</script>
