import axios from 'axios';
import { objectSorterByValue } from '@/helpers/objectSorter';
import { errorDialog } from './errorDialogs';

import memoize from 'lodash.memoize';

// * AXIOS INITIALIZATION =====================================
// #region
const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'https://api.wodev.webofis.im/'
    : 'http://localhost:8080/test/';

axios.interceptors.request.use(
  request => {
    return request;
  },
  error => {
    return Promise.reject(error.response);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

export const factory = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
});
// #endregion

// * CRUD =====================================
// #region
export function setToken(token) {
  factory.defaults.headers.common['Authorization'] = token;
}

export function setBaseURL(url) {
  factory.defaults.baseURL = url ? url : baseURL;
}
// #endregion

// * AUTHENTICATION ======================================
// #region
export function getPermissions() {
  const url = 'authentication/list';
  return factory.get(url);
}
// #endregion

// * LOGIN LOGOUT PASSWORD ======================================
// #region
export function apiLoginCall({ user, password, program, step, dbname }) {
  const url = 'auth/login';
  const data = {
    user,
    pass: password,
    program,
    platform: 'mobil',
    step
  };
  if (step === 2) {
    data.dbname = dbname;
  }
  return factory.post(url, data);
}

export function passwordChange({
  user,
  currentPassword,
  newPassword,
  repeatNewPassword
}) {
  const url = 'user/password-reset';
  const data = {
    user,
    available_pass: currentPassword,
    new_pass: newPassword,
    new_pass_confirm: repeatNewPassword
  };
  return factory.post(url, data);
}

export function apiLogoutCall() {
  const url = 'auth/logout';
  return factory.post(url, {});
}
// #endregion

// * MENULER =====================================
//#region
export function getSideMenus() {
  const url = 'menu/side-menu/list';
  return factory.get(url);
}
export function getMenuGroups() {
  const url = 'menu/groups/list';
  return factory.get(url);
}
export function getAllMenus() {
  const url = 'menu/all/list';
  return factory.get(url);
}
//#endregion

// * SIRKETLER ====================================
//#region
export function getCompanies() {
  const url = 'companies';
  return factory.get(url);
}
//#endregion

// * SUBELER =====================================
//#region
export async function getBranches() {
  const url = 'branches';
  try {
    const { data } = await factory.get(url);

    data.sort((a, b) => objectSorterByValue(a, b, 'isim'));

    return data;
  } catch (e) {
    throw 'Şubelerde bir hata oluştu. Lütfen sayfayı yenileyiniz.';
  }
}
// #endregion

// * DEPOLAR ======================================
//#region
export async function getDepots() {
  const url = 'depots';
  try {
    const { data } = await factory.get(url);

    data.sort((a, b) => objectSorterByValue(a, b, 'isim'));

    return data;
  } catch (e) {
    throw 'Depolarda bir hata oluştu. Lütfen sayfayı yenileyiniz.';
  }
}

// #endregion

// * SEPET =========================================
// #region
export function getBasketsDropdown() {
  const url = 'stock/basket/list';
  return factory.get(url);
}

export async function getBasketsDetail(id) {
  const url = `stock/basket/${id}/detail`;

  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    throw 'Sepetlerde bir hata oluştu. Lütfen tekrar deneyiniz.';
  }
}
// #endregion

export async function getColorsFromApi() {
  const url = '/variants/colors';
  const data = await factory.get(url);
  return data;
}

export async function getSizesFromApi() {
  const url = 'variants/sizes';
  const data = await factory.get(url);
  return data;
}

// * SIRA ==========================================
// #region
export function getSequenceValue(module, seri, params) {
  const url = `${module}/get-sequence`;
  return factory.post(url, { seri, ...params });
}
//#endregion

// * HIZLIARA =====================================
// #region
export function getQuickSearchResults(queries) {
  const query = queries || '';
  const url = `search${query}`;
  return factory.get(url);
}
// #endregion

// * MALIYET ======================================
export async function getCostsFromCostAnalysis(costID) {
  const url = 'maliyet/fetch-cost';
  try {
    const { data } = await factory.get(url, { params: { id: costID } });
    return data;
  } catch (error) {
    throw error.response;
  }
}

// * BIRIMLER ==================================
export async function getStockUnits() {
  const url = 'stock-units';
  try {
    const { data } = await factory.get(url);
    return data;
  } catch {
    throw 'Sistemde ekli stok birimi bulunamadı. Lütfen sayfayı yenileyiniz.';
  }
}

// * MALIYETLERI GUNCELLE ======================
export function getUpdateCosts(data) {
  const url = 'stock-motion/final-costs';
  return factory.post(url, data);
}

// * KURLAR ================================
export async function getExchangeRates(date) {
  const url = 'currency/exchange-rates';
  try {
    const { data } = await factory.post(url, { tarih: date });
    return data;
  } catch (error) {
    throw error.response;
  }
}

// * Whats New YENILIKLER ================
export async function getWhatsNewList() {
  const url = 'whats-new/list';
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    throw error.response;
  }
}

// * INFORMATION YARDIM ================
export async function getModuleInformation(module) {
  const url = 'system/information';
  try {
    const { data } = await factory.get(url, { params: { module } });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

// * ODEME PLANI ================
export async function getPayPlan() {
  const url = 'payment/plan';
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    throw error.response;
  }
}

// * ODEME TURLERI ================
export async function getPayType() {
  const url = 'payment/mode';
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    throw error.response;
  }
}

// * EMAIL =====================================
// #region
// export async function sendEmail(body) {
//   const url = 'currency/exchange-rates';
//   try {
//     const { data } = await factory.post(url, { ...body, header: 1, footer: 1 });
//     return data;
//   } catch (error) {
//     throw error.response;
//   }
// }

export async function getPreviewEmail(id, currency, lang, ilgililere_mail) {
  const url = `teklif/mail/preview/${id}?gonderilecek_para_birimi=${currency}&lang=${lang}${ilgililere_mail ? '&ilgililere_mail=1' : ''
    }`;
  const { data } = await factory.get(url);
  return data;
}
// #endregion

// * STOK =======================================
export async function getBatch(depo_id, stok_id) {
  const url = 'stock-motion/batch-number';
  try {
    const { data } = await factory.get(url, {
      params: {
        stok_id,
        depo_id
      }
    });
    return data;
  } catch (error) {
    throw error.response;
  }
}

export async function getSemiProductDetail(id, req) {
  const url = `model-planning/prescription-summary?id=${id}&tur=${req}`;
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    throw error.response;
  }
}

// * Numbers =====================================
export function getNumber(module, variant, company, branch, code) {
  const url = 'numbers';
  const payload = {
    bolum: module,
    variant,
    sirket: company,
    sube: branch,
    kod: code
  };
  return factory.post(url, payload);
}

// * Kontrol Listesi ==================
// #region
export function saveKontrolListesiKaydet(data) {
  const url = '/checklist';
  return factory.post(url, data);
}

export function saveKontrolListesiDuzenleKaydet(id, data) {
  const url = `/checklist/${id}`;
  return factory.put(url, data);
}

export function fetchKontrolListesi(id) {
  const url = `/checklist/${id}`;
  return factory.get(url);
}

export function deleteKontrolListesi(id) {
  const url = `/checklist/${id}`;
  return factory.delete(url);
}

export async function gitnCodeInformation(params) {
  const url = '/stock-gitn-information';
  const { data } = await factory.post(url, params);
  return data;
}

export async function getBoxNumber(params) {
  const url = '/checklist/get-box-number';
  const { data } = await factory.get(url, { params });
  return data;
}

export async function kontrolListesiAutomateSaving(params) {
  const url = '/checklist/automate-saving';
  const { data } = await factory.post(url, params);
  return data;
}

export async  function deleteKontrolListesiRow(id) {
  const url = `/modules/checklist/row-delete/${id}`;
  const { data } = await factory.delete(url);
  return data;
}

export async function hasatBeyanMiktarKontrol(id, amount) {
  const url = '/ciftlik/hasat-beyan-miktar-kontrol';
  const { data } = await factory.post(url, { hasat_beyan_id: id, miktar: amount });
  return data;
}

export async function fetchStockDifferentLanguageData(params) {
  const url = '/stock-different-language';
  const { data } = await factory.post(url, params);
  return data.result;
}

export async function fetchManufactureAutomateLotNumber(params) {
  const url = '/manufacturing/manufacture-automate-lot';
  const { data } = await factory.post(url, params);
  return data;
}

export async function fetchStockData(params) {
  const url = '/stock/get-stock';
  const { data } = await factory.post(url, params);
  return data;
}

export async function fetchBarcode(params) {
  const url = '/barcode';
  const { data } = await factory.post(url, params);
  return data;
}

export function handleKontrolListesiLastAccess(id) {
  const url = `/checklist/${id}/last-access`;
  return factory.get(url);
}

export async function kontrolListesiSavePalletPrinted(params) {
  const url = '/checklist/pallet/printed';

  const { data } = await factory.post(url, params);
  return data;
}

export async function kontrolListesiPalletPrintedCount(params) {
  const url = '/checklist/pallet/printed-count';

  const { data } = await factory.post(url, params);
  return data;
}

export async function getCheckListChanges(id) {
  const url = '/modules/checklist/changes';

  const { data } = await factory.get(url, {
    params: {
      id
    }
  });
  return data;
}
// #endregion

// * Il Ilce Ulke ==================
//#region

const getCountriesAPI = async () => {
  try {
    const url = 'countries';
    const { data } = await factory.get(url);
    return data;
  } catch (e) {
    if (e.response.data.message) {
      errorDialog(e.response.data.message);
    } else {
      errorDialog('Ülkeleri getirirken bir hata oluştu.');
    }
    return [];
  }
};
export const getCountries = memoize(getCountriesAPI);

const getProvincesAPI = async country => {
  try {
    const url = 'cities';
    const { data } = await factory.get(url, {
      params: {
        ulke: country
      }
    });
    return data;
  } catch (e) {
    if (e.response.data.message) {
      errorDialog(e.response.data.message);
    } else {
      errorDialog('İlleri getirirken bir hata oluştu.');
    }
    return [];
  }
};
export const getProvinces = memoize(getProvincesAPI);

const getDistrictsAPI = async province => {
  try {
    const url = 'districts';
    const { data } = await factory.get(url, {
      params: {
        il: province
      }
    });
    return data;
  } catch (e) {
    if (e.response.data.message) {
      errorDialog(e.response.data.message);
    } else {
      errorDialog('İlçeleri getirirken bir hata oluştu.');
    }
    return [];
  }
};
export const getDistricts = memoize(getDistrictsAPI);
//#endregion

export async function fetchCollections() {
  try {
    const url = `/model_planning/get-collection`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchYikamaIbareleri() {
  try {
    const url = `/sub-module/yikama_ibareleri`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchVaryantPaketleme() {
  try {
    const url = `/sub-module/varyant_paketleme`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchVaryantEtiket() {
  try {
    const url = `/sub-module/varyant_etiket`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchDimensionPhrases() {
  try {
    const url = `/model-planning/dimension-phrases`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchInformationsPhrases() {
  try {
    const url = `/model-planning/informations-phrases`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchPrimaryMaterials() {
  try {
    const url = `/model-planning/main-material`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function fetchProcesses() {
  try {
    const url = `/model-planning/processes`;
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function addDimensionPhrase(addedItems, selectedItems, finalProductId, colors) {
  try {
    const url = `/model-planning/dimension-store`;
    const { data } = await factory.post(url, {
      addedItems,
      selectedItems,
      finalProductId,
      colors
    });
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function addInformationPhrases(phrases, type) {
  try {
    const url = '/model-planning/informations-store';
    const { data } = await factory.post(url, {
      phrases,
      type,
    });
    return data;
  } catch (error) {
    errorDialog(error);
    return [];
  }
}

export async function getStokMizan(stocksID, depotID) {
  try {
    const url = '/model-planning/stocks-quantity';
    const { data } = await factory.get(url, {
      params: {
        stocksID,
        depotID
      }
    });
    return data;
  } catch (error) {
    errorDialog(error.response.data.message);
    return [];
  }
}

export async function storePurchaseOrder(order) {
  try {
    const url = '/model-planning/purchase-order';
    return await factory.post(url, order);
  } catch (error) {
    errorDialog(error.response.data.message);
  }
}

export async function storeDepotOrder(order) {
  try {
    const url = '/model-planning/depot-order';
    return await factory.post(url, order);
  } catch (error) {
    errorDialog(error.response.data.message);
  }
}

export async function getStockRecipeDetails(id) {
  try {
    const url = '/model-planning/stock-recipe-details';
    const { data } = await factory.get(url, {
      params: {
        id
      }
    });
    return data;
  } catch (error) {
    errorDialog(error.response.data.message);
    return [];
  }
}

export async function storeProductionSlip(productionSlip) {
  try {
    const url = '/model-planning/production-slip';
    return await factory.post(url, productionSlip);
  } catch (error) {
    errorDialog(error.response.data.message);
  }
}

export async function storeVariantProductionSlip(productionSlip) {
  const url = '/model-planning/variants-production-slip';
  return await factory.post(url, {
    productionSlip,
  });
}

export async function fetchRecipeGroups() {
  const url = '/model-planning/get-recipe-groups';
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error.response.data.message);
    return [];
  }
}

export async function fetchMachines() {
  const url = '/model-planning/machine';
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    errorDialog(error.response.data.message);
    return [];
  }
}

export async function calculateHammadde(stockList, semiProductId, documentId) {
  const url = '/calculate-hammadde';
  try {
    return await factory.post(url, {
      stockList,
      semiProductId,
      documentId,
    });
  } catch (error) {
    errorDialog(error.response.data.message);
  }
}