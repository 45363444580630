<template>
  <v-multiselect-listbox
    v-model="selectedValues"
    :options="options"
    :reduce-display-property="reduceDisplayProperty"
    :reduce-value-property="reduceValueProperty"
    :search-options-placeholder="searchOptionsPlaceholder"
    :no-options-text="noOptionsText"
    :selected-options-placeholder="selectedOptionsPlaceholder"
    :selected-no-options-text="selectedNoOptionsText"
  />
</template>

<script>
export default {
  name: 'WOMultiselectListbox',
  props: {
    value: Array,
    options: Array,
    reduceDisplayProperty: Function,
    reduceValueProperty: Function,
    searchOptionsPlaceholder: String,
    noOptionsText: String,
    selectedOptionsPlaceholder: String,
    selectedNoOptionsText: String,
    isApiRecieved: Boolean
  },
  computed: {
    selectedValues: {
      get() {
        return this.isApiRecieved ? this.value : [];
      },
      set(val) {
        this.$emit('input', [...this.value, ...val]);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/scss/vuexy/variables';

.msl-multi-select {
  .msl-search-list-input {
    padding: 0.4rem;
    border: 1px solid $theme-dark-border-color;
    border-radius: 5px;
  }

  .msl-searchable-list {
    &__items {
      border: 1px solid $theme-dark-border-color;
      border-radius: 5px;
      min-width: 220px;
    }
    &__item:hover {
      background-color: $primary;
    }
  }
}

@media (max-width: 500px) {
  div.msl-multi-select {
    height: 500px;
    flex-direction: column;
    &__list {
      height: 45%;
    }
    &__actions {
      transform: rotateZ(90deg);
      height: 10%;
    }
  }
}
</style>
