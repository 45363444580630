import { strToNumToStr } from './NumberController';

export function dateFromClient(value) {
  if (!value) {
    return;
  }
  const dateArray = value.split('.');
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
}
export function dateTimeFromClient(value) {
  if (!value) {
    return;
  }
  const dateTimeArray = value.split(' ');
  const dateArray = dateTimeArray[0].split('.');
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]} ${dateTimeArray[1]}`;
}

export function detailsFromClient(array, key, convert = true) {
  return convert
    ? array.map(a => strToNumToStr(a[key]))
    : array.map(a => a[key]);
}
