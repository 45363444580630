<template>
  <vs-collapse type="border">
    <vs-collapse-item>
      <div slot="header">
        Loglar
      </div>
      <div class="log-underline" v-html="logValues"></div>
    </vs-collapse-item>
  </vs-collapse>
</template>

<script>
import useLogs from '../consumables/useLogs.js';

export default {
  setup() {
    const logValues = useLogs();
    return {
      logValues
    };
  }
};
</script>

<style scoped>
.log-underline >>> a {
  @apply underline hover:text-primary !important;
}
</style>
