<template>
    <wo-modal
     class="wide-dialog-90vw one-step-behind-dialog mt-5"
     :active="otherInformationModal"
     title="DİKİM"
     @active="$emit('otherInformationModal', $event)"
     :accept="saveOtherInformation "
     :closeAct="closeWithoutSave"
    >
        <div class="my-5 d-flex text-center container">
            <h2 style="font-weight: bold; color:red;">DİKİM KONFEKSİYON FORMU</h2>
            <table class="info-table">
                <tr v-for="(title, index) in infoTitles" :key="index">
                    <th>{{ title }}</th>
                    <td><input type="text" style="border-radius: 3px; border-top: none; border-left: none; border-right: none;" v-model="dimensionInfo[title]"></td>
                </tr>
            </table>
        </div>
        <template>
            <div id="table">
                <wo-table>
                    <template #thead>
                        <wo-th></wo-th>
                        <wo-th></wo-th>
                        <wo-th v-for="col in sizes" :key="col">
                            {{ col }}
                        </wo-th>
                    </template>
                    <template>
                        <wo-tr v-for="phrase in localDimensionPhrasesNames" :key="phrase" class="text-center">
                            <wo-td style="width: fit-content" class="text-right">
                                {{phrase}}
                                <i
                                    class="fa fa-remove pl-2" 
                                    v-if="inAddedPhrases(phrase)"
                                    style="cursor: pointer;"
                                    @click="removeDimensionPhrase(phrase)"
                                >
                                </i>
                            </wo-td>
                            <wo-td>
                                <vs-checkbox v-model="dimensions[phrase]['isSelected']" />
                            </wo-td>
                            <wo-td v-for="col in sizes" :key="col">
                                    <input type="number" style="height:25px; width: 100px; border-radius: 3px;" class="text-center" id="dimensions" v-model="dimensions[phrase][col]">
                            </wo-td>
                        </wo-tr>
                        <wo-tr class="text-center">
                            <wo-td class="text-right"><input type="text" style="border-radius: 3px; object-fit: fill;" v-model="addedDimensionPhrasesName">
                                <feather-icon
                                    icon="PlusIcon"
                                    style="cursor: pointer;"
                                    class="h-5 w-5 align-middle plusIcon text-success ml-2"
                                    @click="addDimensionPhrase"
                                />
                            </wo-td>
                        </wo-tr>
                    </template>
                </wo-table>
                </div>
            </template>
        <i class="fa fa-print fa-2x ml-5" aria-hidden="true" @click="exportPDF"></i>
    </wo-modal>
</template>

<script>
import { computed, ref, onUpdated, watch, onMounted } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
// import { downloadPDF } from '../../helpers/modelPlanningApiService';
import exportTable from '@/helpers/exportTable';
import { filterObject } from '@/helpers/dataFilterHelper';

export default {
    name: "DimensionDetailsModal",
    props: {
        otherInformationModal: Boolean,
        sizes: Array,
        dimensionPhrasesNames: Array,
        dressPhrasesNames: Array,
        shuttleNames: Array,
        stateKey: String,
    },
    setup (props, context) {
        
        const { state, newDimensionPhrases, selectedSizes, stokOzellikler, sizesWithID } = useModelPlanlamaState();

        const infoTitles = [
            "ÜRETİCİ",
            "MODEL CİNSİ",
            "MODEL KODU",
            "BEDEN",
            "AKSESUAR"
        ];        

        const localData = computed({
            get() {
                return state[props.stateKey].info.otherInformations;
            },
            set(val) {
                state[props.stateKey].info.otherInformations = val;
            },
        });
        
        const localDimensionPhrasesNames = ref(props.dimensionPhrasesNames);
        
        const addedDimensionPhrasesName = ref('');
        
        const dimensionsStorage = ref({});
        
        const dimensionInfo = computed(() => state[props.stateKey].info.otherInformations.dimensionInfo || []);

        const dimensions = computed(() => {
            const localDimensions = {};
            const titleArray = [...props.sizes, 'isSelected'];
            localDimensionPhrasesNames.value.forEach(phrase => {
                localDimensions[phrase] = {};
                titleArray.forEach(size => {
                    localDimensions[phrase][size] = localData.value.dimensions[phrase] === undefined ? '' : localData.value.dimensions[phrase][size];
                });
            });
            return localDimensions;
        });

        const selectedPhrases = ref([]);

        onMounted(() => {
            selectedPhrases.value = Object.keys(filterObject(dimensions.value, (key, value) => {
                if (value.isSelected) {
                    return {
                        [key]: value,
                    };
                }
            }));
        });
        
        const getIdByName = (name) => {
            return sizesWithID.value.find(size => size.name === name).id;
        };
        
        const addStokOzellik = () => {
            const filteredObject = filterObject(dimensions.value, (key, value) => {
                if (value.isSelected && !selectedPhrases.value.includes(key)) {
                    return {
                        [key]: value,
                    };
                }
            });
            const result = {};
            for (const key in filteredObject) {
                result[key] = {};
                selectedSizes[props.stateKey].forEach((size) => {
                    result[key][getIdByName(size)] = filteredObject[key][size];
                });
            }
            stokOzellikler.value = result;
        };


        const saveOtherInformation = () => {
            state[props.stateKey].info.otherInformations.dimensions = dimensions.value;
            state[props.stateKey].info.otherInformations.dimensionInfo = dimensionInfo.value;
            addStokOzellik();
            context.emit("otherInformationModal", false);
        };

        const closeWithoutSave = () => {
            context.emit("otherInformationModal", false);
        };

        const addDimensionPhrase = () => {
            if (addedDimensionPhrasesName.value !== '') {
                state[props.stateKey].info.otherInformations.dimensions = dimensions.value;
                localDimensionPhrasesNames.value.push(addedDimensionPhrasesName.value);
                newDimensionPhrases.value.push(addedDimensionPhrasesName.value);
                addedDimensionPhrasesName.value = '';
            }
        };

        const removeDimensionPhrase = (phrase) => {
            localDimensionPhrasesNames.value.splice(localDimensionPhrasesNames.value.indexOf(phrase), 1);
            newDimensionPhrases.value.splice(newDimensionPhrases.value.indexOf(phrase), 1);
        };
        
        const inAddedPhrases = (phrase) => {
            return newDimensionPhrases.value.includes(phrase);
        }; 

        const exportPDF = () => {
            const image = state[props.stateKey].info.imageUrls[0];
            const dimensionsObect = {
                data: dimensions.value,
                title: 'ÖLÇÜLER ÜTÜ PAKET' 
            };
            const dimensionInfoObject = {
                data: dimensionInfo.value,
                title: "",
            };

            exportTable([dimensionsObect], dimensionInfoObject, image, 'DİKİM KONFEKSİYON FORMU');
        };
        
        onUpdated(() => {
            localDimensionPhrasesNames.value = props.dimensionPhrasesNames;
            // if (dimensionInfo.value['ÜRETİCİ'] === "" && dimensionInfo.value['MODEL KODU'] === "") {
                dimensionInfo.value['ÜRETİCİ'] = state[props.stateKey].info.mainContractorName;
                dimensionInfo.value['MODEL KODU'] = state[props.stateKey].info.modelCode;
                dimensionInfo.value['MODEL CİNSİ'] = state[props.stateKey].info.modelName;
                dimensionInfo.value['BEDEN'] = selectedSizes[props.stateKey].join('-');
            // }
        });

        watch(
            () => props.otherInformationModal,
            () => {
                if (props.sizes.length > 0) {
                    document.getElementById('dimensions').focus();
                }
            }
        );

        return {
            localData,
            dimensions,
            saveOtherInformation,
            addDimensionPhrase,
            addedDimensionPhrasesName,
            localDimensionPhrasesNames,
            inAddedPhrases,
            removeDimensionPhrase,
            closeWithoutSave,
            dimensionsStorage,
            exportPDF,
            infoTitles,
            dimensionInfo,
            stokOzellikler,
            addStokOzellik
        };
    }
};
</script>

<style lang="scss" scoped>
 input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
 .info-table {
    border: none;
 }
</style>
