<template>
  <div class="space-y-4">
    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Muhasebe Şube</template>
        <template #firstElement>
          <wo-select
            ref="accountingBranch"
            v-model="state.accountingBranch"
            :data="accountingBranches"
          >
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`branch-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>
          <span
            class="underlined-hovered-link"
            @click="handlePrevalentKindMiniModule"
          >
            Cari Cinsi
          </span>
        </template>
        <template #secondElement>
          <div @click="handleHalfAccountPrevalentError">
            <wo-select
              v-model="state.prevalentKind"
              :data="prevalentKinds"
              :disabled="!!state.halfAccountPrevalent"
            >
              <template #defaultValue>
                <wo-select-item itemValue="" text=" - " />
              </template>
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="`prevalent-kind-${item.id}`"
                />
              </template>
            </wo-select>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span
            class="underlined-hovered-link"
            @click="handleGroupCodeMiniModule"
          >
            Grup Kodu
          </span>
        </template>
        <template #firstElement>
          <multiselect
            :value="selectedValues"
            :options="groupCodes"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            placeholder=""
            label="isim"
            track-by="id"
            @select="addElement"
            @remove="removeElement"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length && !isOpen"
                >{{ values.map(a => a.isim).join(', ') }}</span
              ></template
            >
          </multiselect>
        </template>
        <template #secondLabel>
          <span
            class="underlined-hovered-link"
            @click="handleResponsibilityCenterMiniModule"
          >
            Sorumluluk Merkezi
          </span>
        </template>
        <template #secondElement>
          <wo-select
            v-model="state.responsibilityCenter"
            :data="responsibilityCenters"
          >
            <template #defaultValue>
              <wo-select-item itemValue="" text="-Tanımsız-" />
            </template>
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`${item.id}-${item.kod}`"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span
            class="underlined-hovered-link"
            @click="handleConceptMiniModule"
          >
            Konsept
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="state.concept" :data="concepts">
            <template #defaultValue>
              <wo-select-item itemValue="" text=" - " />
            </template>
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`${item.id}-${item.kod}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>
          <vx-tooltip
            text="Özel cari seçildiğinde Muhasebeleştirilmeyecek seri numarasına sahip evrak düzenlenemez."
          >
            Özel Cari (Bankalar vs.)
          </vx-tooltip>
        </template>
        <template #secondElement>
          <div class="y-centered-flex space-x-4">
            <vs-radio
              v-model="state.specialPrevalent"
              vs-name="special-prevalent"
              vs-value="1"
            >
              Evet
            </vs-radio>
            <vs-radio
              v-model="state.specialPrevalent"
              vs-name="special-prevalent"
              vs-value="0"
            >
              Hayır
            </vs-radio>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Kategoriler
        </template>
        <template #firstElement>
          <wo-quick-search
            queryParameters="?tablo=stok_altgruplar&q="
            @selected="handleCategoriesQuickSearch"
            :itemRenderer="nameAndCodeRenderer"
          />
        </template>
        <template #secondElement>
          <CariFormDeletableList
            :data="state.selectedCategories"
            :rootObj="state"
            objKey="selectedCategories"
          />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <wo-double-form :labelSize="labelSize"> </wo-double-form>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState';
import { authorizationControl } from '@/consumables/useAuthorizationControl.js';
import { errorDialog, errorNotify } from '@/helpers/errorDialogs';

import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import {
  getPrevalentKinds,
  getConcepts,
  getResponsibilityCenters,
  getGroupCodes,
  getAccountingBranches
} from '../../helpers/ApiFactory.js';

import CariFormDeletableList from '../common/CariFormDeletableList.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

import { ref } from '@vue/composition-api';
import { useVueMultiple } from '@/consumables/useVueMultiple';

export default {
  components: {
    CariFormDeletableList,
    CariFormQuadrupleFormWrapper
  },
  setup() {
    //#region INITIALIZATIONS
    const {
      labelSize,
      state,
      handleDeletableListQuickSearch
    } = usePrevalentState();

    const accountingBranches = ref([]);

    const initializeAccountingBranch = async () => {
      accountingBranches.value = await getAccountingBranches();
      accountingBranches.value = Array.isArray(accountingBranches.value)
        ? accountingBranches.value
        : [accountingBranches.value];
      state.accountingBranch = !state.accountingBranch
        ? accountingBranches.value[0].id
        : state.accountingBranch;
    };
    initializeAccountingBranch();

    const prevalentKinds = ref([]);
    const concepts = ref([]);
    const responsibilityCenters = ref([]);
    const groupCodes = ref([]);

    const initiateAPICalls = async () => {
      const apiPrevalentKinds = getPrevalentKinds();
      const apiConcepts = getConcepts();
      const apiResponsibilityCenters = getResponsibilityCenters();
      const apiGroupCodes = getGroupCodes();

      await Promise.all([
        apiPrevalentKinds,
        apiConcepts,
        apiResponsibilityCenters,
        apiGroupCodes
      ]);

      prevalentKinds.value = await apiPrevalentKinds;
      concepts.value = await apiConcepts;
      responsibilityCenters.value = await apiResponsibilityCenters;
      groupCodes.value = await apiGroupCodes;
    };
    initiateAPICalls();

    const returnInitializations = {
      labelSize,
      state,
      accountingBranches,
      prevalentKinds,
      concepts,
      responsibilityCenters,
      groupCodes
    };
    //#endregion

    //#region QUICKSEARCH
    const handleCategoriesQuickSearch = item => {
      handleDeletableListQuickSearch(state, 'selectedCategories', item);
    };

    const returnQuickSearch = {
      handleCategoriesQuickSearch,
      nameAndCodeRenderer
    };
    //#endregion

    // #region MINIMODULES

    const handlePrevalentKindMiniModule = () => {
      if (authorizationControl('cari_cins_tanimlama')) {
        window.MiniModulPen(600, 410, 'Cari Cinsleri', 'cins_kodlar');
      } else {
        errorNotify('Cari cins tanımlama yetkiniz bulunmamaktadır.');
      }
    };

    const handleConceptMiniModule = () => {
      window.MiniModulPen(800, 430, 'Konseptler', 'konseptler');
    };

    const handleResponsibilityCenterMiniModule = () => {
      window.ajaxpen(
        450,
        300,
        'Sorumluluk Merkezleri',
        `sorumluluk_merkezleri&amp;sube=${state.accountingBranch}`
      );
    };

    const handleGroupCodeMiniModule = () => {
      window.ajaxpen(700, 300, 'CARI_HESAP_GRUPLARI', 'cari_grup_kodu');
    };

    const returnMiniModules = {
      handlePrevalentKindMiniModule,
      handleConceptMiniModule,
      handleResponsibilityCenterMiniModule,
      handleGroupCodeMiniModule
    };

    //#endregion

    const handleHalfAccountPrevalentError = () => {
      if (state.halfAccountPrevalent) {
        errorDialog(
          'Bu hesap, Şube kartındaki YARIM HESAP CARİSİ olarak tanımlandığından, cari cinsini değiştiremezsiniz.'
        );
      }
    };

    const { selectedValues, removeElement, addElement } = useVueMultiple(
      groupCodes,
      state,
      'groupCode',
      'id'
    );

    return {
      handleHalfAccountPrevalentError,
      selectedValues,
      removeElement,
      addElement,

      ...returnInitializations,
      ...returnQuickSearch,
      ...returnMiniModules
    };
  }
};
</script>

<style lang="scss" scoped></style>
