<template>
  <div class="space-y-4">
    <CariFormSectionHeader>
      Meslekler
    </CariFormSectionHeader>

    <wo-quick-search
      queryParameters="?tablo=meslekler&q="
      @selected="handleProfessionsQuickSearch"
      :itemRenderer="nameRenderer"
    />

    <div class="professions-list">
      <div class="mb-4">
        <span class="ml-8 inline-block w-58">Meslek</span>
        <span>Kadro</span>
      </div>
      <CariFormDeletableList
        :data="state.professions"
        objKey="professions"
        :rootObj="state"
        @itemDeleted="handleDeletedListItem"
        nameWidth="200px"
        itemMargin
      >
        <template #default="{ obj }">
          <wo-int-input
            v-model="state.personnels[obj.id]"
            :key="`profession-${obj.id}`"
          />
        </template>
      </CariFormDeletableList>
    </div>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState';
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormDeletableList from '../common/CariFormDeletableList.vue';

import { nameRenderer } from '@/helpers/quickSearchListRenderers/qsName';

export default {
  components: {
    CariFormSectionHeader,
    CariFormDeletableList
  },
  setup(props, { root }) {
    const { state, handleDeletableListQuickSearch } = usePrevalentState();

    const handleProfessionsQuickSearch = item => {
      handleDeletableListQuickSearch(state, 'professions', item);
      if (item.id) {
        state.personnels[item.id] = '';
      }
    };

    const handleDeletedListItem = id => {
      root.$delete(state.personnels, id);
    };

    return {
      nameRenderer,
      state,
      handleProfessionsQuickSearch,
      handleDeletedListItem
    };
  }
};
</script>

<style lang="scss" scoped></style>
