<template>
        <wo-modal
            class="wide-dialog-90vw one-step-behind-dialog"
            :active="orderModal"
            @active="$emit('orderModal', $event)"
            :title="title"
            :buttonsHidden="true"
        >
            <div class="d-flex" v-if="!isInvoice">
                <div>
                    <vs-radio class="ml-1" title="Renklere Göre Sipariş Oluştur" v-model="getStocksByType" vs-value="1" vs-name="get-stocks"></vs-radio><span>Renklere Göre Sipariş Oluştur</span>
                    <vs-radio class="ml-1" title="Bedenlere Göre Sipariş Oluştur" v-model="getStocksByType" vs-value="2" vs-name="get-stocks"></vs-radio><span>Bedenlere Göre Sipariş Oluştur</span>
                    <vs-radio class="ml-1" title="Modele Göre Sipariş Oluştur" v-model="getStocksByType" vs-value="3" vs-name="get-stocks"></vs-radio><span>Modele Göre Sipariş Oluştur</span>
                </div>    
                <div class="d-inline flex mt-5 mb-2">
                    <slot name="quickSearches"/>
                </div>
            </div>
            <wo-table v-if="getStocksByType!==''">
                <template #thead>
                    <wo-th></wo-th>
                    <wo-th v-for="column in columns" :key="`column-${column}`" class="text-left">{{ column }}
                        <span v-if="column=='Stok Durum'">
                            <feather-icon
                                    icon="PlusIcon"
                                    id="plusIcon"
                                    class="h-5 w-5 align-middle plusIcon text-success ml-2"
                                    @click="getStocksQuantity"
                            /> 
                        </span>
                    </wo-th>
                </template>
                <template>
                    <wo-tr v-for="(stock, index) in stocks[stockTypes[getStocksByType]]" :key="`stock-${stock.stockCode}`" class="text-left">
                        <wo-td><vs-checkbox @change="handleSendToStock($event, stock)"/></wo-td>
                        <wo-td>{{stock.stockCode}}</wo-td> 
                        <wo-td>{{stock.stockName}}</wo-td>
                        <wo-td v-if="!isInvoice">{{stock.color}}</wo-td>
                        <wo-td v-if="!isInvoice">{{stock.size}}</wo-td>
                        <wo-td>
                            <wo-float-input :id="`unit-${stock.stockID}`" v-model="stock.stockAmount" @blur="calculateMainCurrencyPrice(stock.stockCode)"/> 
                        </wo-td>
                        <wo-td>
                            <wo-select
                                :data="stockUnits"
                                v-model="stock.stockUnit"
                                elementClasses="w-28 m-auto"
                            >
                                <template #defaultValue>
                                <wo-select-item :itemValue="stock.stockUnit" text="- Seçiniz -" />
                                </template>
                                <template #default="{ item }">
                                <wo-select-item
                                    :itemValue="item.id"
                                    :text="item.isim"
                                    :key="`unit-${item.id}`"
                                />
                                </template>
                            </wo-select>
                        </wo-td>
                        <wo-td v-if="orderType == 'depot' || isInvoice">
                            <wo-quick-search
                                v-model="stock.cost"
                                class="w-28 wo-numeric-input"
                                :queryParameters="
                                `?tablo=stok_kalibre_maliyet&stok_id=${stock.stockID}&birim=${stock.stockUnit}&depo=${infosData.branch}&tarih=${date}&sube=${infosData.branch}&q=`
                                "
                                @selected="item => handleCostQuickSearch(item, stock.stockCode)"
                                :itemRenderer="stockCaliberCostRenderer"
                            />
                        </wo-td>
                        <wo-td v-if="orderType == 'depot' || isInvoice">
                            <wo-float-input v-model="stock.unitPrice" :readonly="stock.currency !== 'TL' " :id="`unitPrice_${stock.stockCode}`"  @blur="calculateMainCurrencyPrice(stock.stockCode)" />
                        </wo-td>
                        <wo-td v-if="orderType == 'depot' || isInvoice">
                            <wo-float-input v-model="stock.exchangeUnitPrice" :readonly="stock.currency === 'TL' " :id="`exchangeUnitPrice${stock.stockCode}`"  @blur="calculateMainCurrencyPrice(stock.stockCode)" />
                        </wo-td>
                        <wo-td v-if="orderType == 'depot' || isInvoice">
                            <wo-select
                                :data="localExchangeRates"
                                elementClasses="w-16"
                                v-model="stock.currency"
                                @change="getExchangeRate(stock.stockCode)"
                            >
                            <template #default="{ item }">
                                <wo-select-item
                                :itemValue="item.kod"
                                :text="item.kod"
                                :key="`exchange-rate-${item.kod}`"
                                />
                            </template>
                            </wo-select>
                        </wo-td>
                        <wo-td v-if="orderType == 'depot' || isInvoice">
                            <wo-float-input v-model="stock.exchangeRate" @change="calculateMainCurrencyPrice(stock.stockCode)"/>
                        </wo-td>               
                        <wo-td v-if="orderType == 'depot' || isInvoice">
                            <wo-float-input v-model="stock.totalPrice"/>
                        </wo-td>
                        <wo-td><span v-if="stockQuantities[getStocksByType].length > 0">{{ stockQuantities[getStocksByType].find(item => parseInt(item.stok_id) === stock.stockID).miktar}}</span></wo-td>
                        <wo-td><span v-if="stockQuantities[getStocksByType].length > 0">{{ parseFloat(parseFloat(getStockStatus(stock.stockID)) +  parseFloat(stockQuantities[getStocksByType][index].miktar)).toFixed(2) }}</span></wo-td>
                        <wo-td v-if="orderType == 'depot'">{{ depotNames.sourceDepotName }}</wo-td>
                        <wo-td v-if="orderType == 'depot'">{{ depotNames.targetDepotName }}</wo-td>
                    </wo-tr>   
                </template>
            </wo-table>
            <div class="d-flex pl-2">
                <slot name="depotOrderTypes" v-if="getStocksByType!==''" />
                <wo-button @click="createOrder" v-if="getStocksByType!==''" class="mt-2 mr-2" title=
                "Cari seçili olan kayıtlar için konsinye sevk diğerleri için depolar arası sevk evrakı oluşturulacaktır" 
                text="Oluştur" />
            </div>
        </wo-modal>
</template>

<script>
import {computed, onUpdated, reactive, ref, watch} from "@vue/composition-api";
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';
import { getStocksForOrder } from "../../../helpers/modelPlanningApiService";
import { getStokMizan, storeDepotOrder, storePurchaseOrder } from '@/helpers/ApiController';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { stockCaliberCostRenderer } from '@/helpers/quickSearchListRenderers/qsStockCaliberCost';
// import pick from 'lodash/pick';
import moment from 'moment';

export default {
    name: "PurchaseOrderModal",
    props: {
        stateKey: String,
        orderModal: Boolean,
        columns: Array,
        orderType: {
            type: String,
            default: () => ''
        },
        title: String,
        dataToSend: Object,
        depotNames: Object,
        isInvoice: Boolean,
        expenses: {
            type: Array,
            default: () => []  
        }
    },    
    setup(props) {
        const { state, selectedColors, selectedSizes, stockUnits, exchangeRates, currencyInputValue, colorsWithID, sizesWithID} = useModelPlanlamaState();

        const infosData = computed(() => {
            return state['confirmed'].info;
        });

        const localExchangeRates = computed(() => {
            return exchangeRates.value['confirmed'];
        });

        const subKeys = reactive([
            'stockID',
            'stockUnit',
            'orderAmount',
        ]);
        
        const date = ref(moment().format('DD-MM-YYYY'));
        
        const getStocksByType = ref('');

        const stockTypes = reactive({
            '1': 'stocksByColor',
            '2': 'stocksBySize',
            '3': 'stocksByModel',
            '4': 'expenses'
        });

        const stocks = ref({
            stocksByColor: [],
            stocksBySize: [],
            stocksByModel: [],
            expenses: []
        });

        const handleSendToStock = (event, stock) => {
            if (event.target.checked) {
                stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockID === stock.stockID).stockIsChecked = true;
            } else {
                stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockID === stock.stockID).stockIsChecked = false;
            }
        };

        const handleCostQuickSearch = (item, stockCode) => {
            const stock = stocks.value[stockTypes[getStocksByType.value]].find(stock => stock.stockCode === stockCode);
            if (item.isSelected) {
                stock.cost = item.kalibre_fiyat;
            } else {
                stock.cost = 0;
            }            
        };

        const stocksMapper = (data) => {
            const constValues = {
                stockIsChecked: false,
                unitPrice: "0,00",
                exchangeUnitPrice: "0,00",
                exchangeRate:  currencyInputValue.value['confirmed'],
            };
            if (getStocksByType.value !== '4') {
                data.map(item => {
                    stocks.value[stockTypes[getStocksByType.value]].push({
                        ...constValues,
                        stockAmount: "0,00",
                        stockID: item.id,
                        cost: "0,00",
                        totalPrice: "0,00",
                        stockCode: item.stok_kod,
                        stockName: item.stok_isim,
                        stockUnit: parseInt(item.stok_birim1_ad),
                        currency: infosData.value.currentCurrency,
                        color: item.stok_renk,
                        size: item.stok_beden,
                    });
                });
            } else {
                stocks.value['expenses'] = [];
                data.map(item => {
                    stocks.value['expenses'].push({
                        ...constValues,
                        stockID: item.entryID,
                        stockCode: '',
                        totalPrice: item.mainCurrencyPrice,
                        stockAmount: item.amount,
                        cost: item.cost,
                        stockName: item.entryName,
                        stockUnit: parseInt(item.unit),
                        currency: item.currency,
                        color: item.color,
                        size: item.size,
                        prevalentID: item.prevalentID,
                    });
                });
            }
        };

        const getStocks = () => {
            if (getStocksByType.value !== '4' && getStocksByType.value !== '' && stocks.value[stockTypes[getStocksByType.value]].length < 1) {
                const colorsID = colorsWithID.value.filter(c => selectedColors[props.stateKey].includes(c.name)).map(c => c.id);
                const sizesID = sizesWithID.value.filter(s => selectedSizes[props.stateKey].includes(s.name)).map(s => s.id);
                getStocksForOrder(getStocksByType.value, infosData.value.modelID, colorsID, sizesID).then(response => {
                    stocksMapper(response.data);
                });
            } else if (getStocksByType.value === '4') {
                stocksMapper(props.expenses);
            }
        };

        const stockQuantities = ref({
            '1': [],
            '2': [],
            '3': [],
            '4': []
        });

        const getStocksQuantity = () => {
            const stocksID = stocks.value[stockTypes[getStocksByType.value]].map(item => item.stockID);
            getStokMizan(stocksID, infosData.value.depot).then(response => {
                stockQuantities.value[getStocksByType.value] = response;
            });
        };

        const getStockStatus = (stockID) => {
            return stocks.value[stockTypes[getStocksByType.value]].filter(item => item.stockID === stockID)[0] ? 
                    parseFloat(stocks.value[stockTypes[getStocksByType.value]].filter(item => item.stockID === stockID)[0].stockAmount).toFixed(2) : 0;
            
        };

        const setValueToStock = (event, stockID, key) => {
            stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockID === stockID)[key] = event.target.value;
        };
        

        const calculateMainCurrencyPrice = (stockCode) => {
            if (props.orderType === 'depot' || props.isInvoice) {
                const unitPriceIsReadOnly = document.getElementById(`unitPrice_${stockCode}`).readOnly;
                const stock = stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockCode === stockCode);
                unitPriceIsReadOnly ? stock.totalPrice = parseFloat(stock.exchangeUnitPrice) * parseFloat(stock.exchangeRate) * parseFloat(stock.stockAmount) :
                stock.totalPrice = parseFloat(stock.unitPrice) * parseFloat(stock.exchangeRate) * parseFloat(stock.stockAmount);
            }
        };


        const getExchangeRate = (stockCode) => {
            const stock = stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockCode === stockCode);
            const exchangeRate = localExchangeRates.value.find(item => item.kod === stock.currency).kur;
            stock.exchangeRate = exchangeRate;
            calculateMainCurrencyPrice(stockCode);
        };

        const createOrder = () => {
            const selectedStocks = stocks.value[stockTypes[getStocksByType.value]].filter(item => item.stockIsChecked);
            if (props.isInvoice && selectedStocks.length > 1) {
                errorDialog("Fatura için sadece bir stok seçebilirsiniz.");
                return false;
            } 
            if (stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockIsChecked)) {
                const order = {
                    documentID: props.stateKey === 'confirmed' ? state[props.stateKey].info.draftID : state[props.stateKey].info.id,
                    branch: infosData.value.branch,
                    mainContractorID: props.isInvoice ? stocks.value[stockTypes[getStocksByType.value]].find(item => item.stockIsChecked).prevalentID : infosData.value.mainContractorID,
                    depotID: infosData.value.depot,      
                    serial: infosData.value.serial,
                    ...props.dataToSend,
                    stocks: stocks.value[stockTypes[getStocksByType.value]].reduce((acc, item) => {
                        if (item.stockIsChecked) {
                            acc.push({
                                id: stocks.value[stockTypes[getStocksByType.value]].indexOf(item),
                                stock_id: item.stockID,
                                unit: item.stockUnit,
                                siparis_miktari: item.stockAmount,
                                currency: item.currency,
                                cost: item.cost,
                                unit_price: item.unitPrice,
                                exchange_unit_price: item.exchangeUnitPrice,
                                exchange_rate: item.exchangeRate,
                                total_price: item.totalPrice,
                                row_type: props.dataToSend.rowType
                            });
                        }
                        return acc;
                    }, [])
                };
                // TODO storeOrder function yaz
                if (props.orderType === 'purchase') {
                    storePurchaseOrder(order).then(response => {
                        if (response.status === 200) {
                            primaryDialog(response.data.message, 'Depo Sipariş', () => {
                                window.open(`${window.location.origin}/${response.data.redirect}`, '_blank');

                            });
                        }
                    });
                } else if (props.orderType === 'depot') {
                    storeDepotOrder(order).then(response => {
                        if (response.status === 200) {
                            primaryDialog(response.data.message);
                        }
                    });
                } else if (props.isInvoice) {
                    storeDepotOrder(order).then(response => {
                        if (response.status === 200) {
                            primaryDialog(response.data.message);
                        }
                    });
                }

            } else {
                errorDialog('Lütfen stok seçiniz');
            }
        };

        onUpdated(() => {
            if (props.expenses.length > 0) {
                if (props.expenses.length > stocks.value['expenses'].length) {
                    getStocks();
                }
                getStocksByType.value = '4';
            }
        });

        watch(
            () => getStocksByType.value,
            () => getStocks()
        );

        return {
            getStocksByType,
            getStocks,
            handleSendToStock,
            stocks,
            stockTypes,
            createOrder,
            setValueToStock,
            getStocksQuantity,
            stockUnits,
            stockQuantities,
            getStockStatus,
            subKeys,
            infosData,
            localExchangeRates,
            getExchangeRate,
            calculateMainCurrencyPrice,
            date,
            handleCostQuickSearch,
            stockCaliberCostRenderer
        };
    }
};
</script>

<style>
    #plusIcon:hover {
        cursor: pointer;
    }

</style>