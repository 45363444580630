<template>
  <span v-if="!$route.params.id">
    Lütfen öncelikle cariyi kaydediniz.
  </span>
  <div v-else>
    <CariFormTabAgreementsTable
      v-if="authorizationControl('sozlesme_liste')"
      @editAgreement="handleEditAgreement"
      @renewAgreementList="getAgreementsList"
      @copyAgreement="handleCopyAgreement"
      @examineAgreement="handleExamineAgreement"
      :agreements="agreements"
    />

    <wo-button
      v-if="authorizationControl('sozlesme_yonet')"
      class="mt-4"
      color="success"
      text="Yeni Sözleşme"
      @click="() => openAgreementForm(0, 'new')"
    />

    <transition name="fade">
      <CariFormTabAgreementsForm
        v-if="isFormOpen"
        class="mt-4"
        :formType="formType"
        :agreementID="agreementFormId"
        @closeAgreement="closeAgreementForm"
        @updateAgreementsList="updateAgreementsList"
      />
    </transition>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import CariFormTabAgreementsForm from './CariFormTabAgreementsForm.vue';
import CariFormTabAgreementsTable from './CariFormTabAgreementsTable.vue';

import { getAgreements } from '../../helpers/ApiFactory';

import { authorizationControl } from '@/consumables/useAuthorizationControl';

export default {
  components: {
    CariFormTabAgreementsForm,
    CariFormTabAgreementsTable
  },
  setup(props, { root }) {
    const agreementFormId = ref(-1);

    const agreements = ref([]);

    const getAgreementsList = async () => {
      if (root.$route.params.id) {
        agreements.value = await getAgreements(root.$route.params.id);
      }
    };

    getAgreementsList();

    const isFormOpen = ref(false);
    const formType = ref('new');

    const closeAgreementForm = () => {
      isFormOpen.value = false;
    };

    const openAgreementForm = (id, type) => {
      if (isFormOpen.value) {
        closeAgreementForm();
      }
      root.$nextTick(() => {
        isFormOpen.value = true;
        agreementFormId.value = id ? id : -1;
        formType.value = type;
      });
    };

    const handleEditAgreement = id => {
      openAgreementForm(id, 'edit');
    };

    const handleExamineAgreement = id => {
      openAgreementForm(id, 'examine');
    };

    const handleCopyAgreement = id => {
      openAgreementForm(id, 'copy');
    };

    const updateAgreementsList = listData => {
      agreements.value = listData;
    };

    return {
      authorizationControl,
      isFormOpen,
      agreementFormId,
      formType,
      openAgreementForm,
      closeAgreementForm,
      handleEditAgreement,
      handleExamineAgreement,
      handleCopyAgreement,
      agreements,
      updateAgreementsList,
      getAgreementsList
    };
  }
};
</script>
