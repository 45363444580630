import { numToTrStr } from '../../../../helpers/NumberController';
import {
  dateFromServer,
  dateTimeFromServer
} from '../../../../helpers/serverToClientConversion';
import { objNameKeyMerger } from './objNameKeyMerger';

export function hydrateFromClient(state, company) {
  const resultObj = {};
  resultObj.client_id = state.client_id;
  resultObj.sirket = company;
  resultObj.sube = state.accountingBranch;
  resultObj.depo = state.depot;
  resultObj.durum = state.prevalentState;
  resultObj.pdks_otomatik = state.pdksAutomatic;
  resultObj.tampon_kasa = state.bufferRegisterID;
  resultObj.nakit_kasa = state.cashRegisterID;
  resultObj.kasiyeravans_kasa = state.cashierAdvanceRegisterID;
  resultObj.tarih = state.openingDate;
  resultObj.pasif_tarih = state.prevalentStateInactiveDate;
  resultObj.ozel = state.specialPrevalent;
  resultObj.carikod = state.prevalentCode;
  resultObj.muhasebe_kod_id = state.accountingCodeID;
  resultObj.muhasebe_kod = state.accountingCode;
  resultObj.sicilno = state.taxRegisterNo;
  resultObj.konsept = state.concept;
  resultObj.cinskodu = state.prevalentKind;
  resultObj.ekstre_tipi = state.extractType;
  resultObj.isletme_defteri = state.managementRegistry;
  resultObj.is_ciftlik = state.farmControl;
  resultObj.irsaliye_tipi = state.waybillType;
  resultObj.unvan = state.title;
  resultObj.yetkili = state.authorizedPerson;
  resultObj.yetkili_telefon = state.contactAuthorizedPhone;
  resultObj.yetkili_email = state.contactAuthorizedEmail;
  resultObj.muhasebe_email = state.contactAccountantEmail;
  resultObj.musteri_email = state.contactCustomerEmail;
  resultObj.kdv_oran = state.vat;
  resultObj.uretici_kodu = state.manufacturerCode;
  resultObj.ihracat_kodu = state.exportCode;
  resultObj.komisyon = state.commissionRate;
  resultObj.indirim_oran = state.discountRate;
  resultObj.risklimit = state.riskLimit;
  resultObj.para_birimi = state.riskLimitCurrency;
  resultObj.hesap_kapama_gelir = state.accountIncomeCardID;
  resultObj.hesap_kapama_gider = state.accountExpenseCardID;
  resultObj.hesap_kapama_cari = state.accountPrevalentID;
  // resultObj.personel_tazminat_gider = state.personalCompensationExpenseCardID;
  resultObj.odeme_plani = state.payPlan;
  resultObj.odeme_tur = state.payTypes;
  resultObj.katilim_bedeli = state.participationPrice;
  resultObj.reklam_pay_oran = state.advertisementShareRate;
  resultObj.tel = state.contactFirstPhone;
  resultObj.tel2 = state.contactSecondPhone;
  resultObj.faks = state.contactFax;
  resultObj.mobil = state.contactCell;
  resultObj.email = state.contactEmail;
  resultObj.web = state.contactWeb;
  resultObj.adres = state.contactAddress;
  resultObj.banka = state.bank;
  resultObj.bankasube = state.bankBranch;
  resultObj.bankasubekodu = state.bankBranchCode;
  resultObj.hesapno = state.bankAccountNo;
  resultObj.hesapekno = state.bankAccountAdditionalNo;
  resultObj.iban = state.iban;
  resultObj.fatura_unvan = state.companyTitle;
  resultObj.vd = state.taxOffice;
  resultObj.vn = state.taxNumber;
  resultObj.tc = state.identityNumber;
  resultObj.sahis_isim = state.soleProprietorshipsOwnerName;
  resultObj.sahis_soyisim = state.soleProprietorshipsOwnerSurname;
  resultObj.aciklama = state.explanation;
  resultObj.notlar = state.screenNote;
  resultObj.siparis_notu = state.orderNote;
  resultObj.calisma_tur = state.workingType;
  resultObj.grupici = state.insideGroup;
  resultObj.fatura_adres = state.invoiceAddress;
  resultObj.ulke = state.country;
  resultObj.il = state.province;
  resultObj.ilce = state.district;
  resultObj.parola = state.password;
  resultObj.kartlar = state.cards;
  resultObj.magaza_entegre_ip = state.apacheIntegratedIP;
  resultObj.parametrede_goster = state.showParameters;
  resultObj.siparis_baglanti = state.orderDatabaseConnection;
  resultObj.data_baglanti = state.dataDatabaseConnection;
  resultObj.printer_siparis = state.orderPrinter;
  resultObj.kasa_hareket_yolu = state.cashRegisterMovementWay;
  resultObj.depoteslim_kod = state.depotDeliveryCode;
  resultObj.magazateslim_kod = state.storeDeliveryCode;
  resultObj.firma_kod = state.companyCode;
  resultObj.magaza_kod = state.storeCode;
  resultObj.grup_kodu = state.groupCode; // array
  resultObj.sorumluluk_merkezi = state.responsibilityCenter;
  resultObj.tahsilat_araligi = state.collectionInterval;
  resultObj.butcede_goster = state.showInBudget;
  resultObj.varsayilan_urn = state.eInvoiceMail;
  resultObj.emukellef = (state.eInvoiceMail && 1) || 0;
  resultObj.varsayilan_irsaliye_urn = state.eWaybillMail;
  resultObj.eirsaliye_mukellef = (state.eWaybillMail && 1) || 0;
  resultObj.hesap_kapama_tipi = state.accountClosingType;
  resultObj.odeme_sekli = Object.keys(state.selectedPayPlans);
  resultObj.tahsilat_yetkili = state.collectionOfficerName;
  resultObj.tahsilat_yetkili_mail = state.collectionOfficerEmail;
  resultObj.tahsilat_yetkili_telefon = state.collectionOfficerCell;
  resultObj.sipariste_satis_sarti = state.checkPurchasingConditionOnSale;
  resultObj.sorumlu_personel = state.responsiblePersonelID;
  resultObj.otomatik_bakiye_mail = state.automaticallySendBalanceMail;
  resultObj.acik_kapali_calisir = state.openAccountCanWork;
  resultObj.calisilan_magazalar = state.activelyWorkingStores;
  resultObj.bagli_ust_cari = state.connectedParentPrevalentID;
  resultObj.meslekler = Object.keys(state.professions);
  resultObj.kadro = Object.values(state.personnels);
  resultObj.kategoriler = Object.keys(state.selectedCategories);
  resultObj.msn = '';
  return resultObj;
}

export function hydrateFromServer(state, serverData) {
  state.client_id = serverData.id;
  state.accountingBranch = serverData.sube;
  state.depot = serverData.depo;
  state.prevalentState = serverData.durum;
  state.pdksAutomatic = serverData.pdks_otomatik;
  state.bufferRegisterID = serverData.tampon_kasa;
  state.cashRegisterID = serverData.nakit_kasa;
  state.cashierAdvanceRegisterID = serverData.kasiyeravans_kasa;
  state.openingDate = dateFromServer(serverData.tarih);
  state.prevalentStateInactiveDate = dateFromServer(serverData.pasif_tarih);
  state.specialPrevalent = serverData.ozel;
  state.prevalentCode = serverData.carikod;
  state.accountingCodeID = serverData.muhasebe_kod_id;
  state.taxRegisterNo = serverData.sicilno;
  state.concept = serverData.konsept;
  state.prevalentKind = serverData.cinskodu;
  state.extractType = serverData.ekstre_tipi;
  state.managementRegistry = serverData.isletme_defteri;
  state.farmControl = serverData.is_ciftlik;
  state.waybillType = serverData.irsaliye_tipi;
  state.title = serverData.unvan;
  state.authorizedPerson = serverData.yetkili;
  state.contactAuthorizedPhone = serverData.yetkili_telefon;
  state.contactAuthorizedEmail = serverData.yetkili_email;
  state.contactAccountantEmail = serverData.muhasebe_email;
  state.contactCustomerEmail = serverData.musteri_email;
  state.vat = serverData.kdv_oran;
  state.manufacturerCode = serverData.uretici_kodu;
  state.exportCode = serverData.ihracat_kodu;
  state.commissionRate = serverData.komisyon;
  state.discountRate = serverData.indirim_oran;
  state.riskLimit = numToTrStr(serverData.risklimit);
  state.riskLimitCurrency = serverData.para_birimi;
  state.accountIncomeCardID = serverData.hesap_kapama_gelir;
  state.accountIncomeCardText = serverData.hesap_kapama_gelir_isim;
  state.accountExpenseCardID = serverData.hesap_kapama_gider;
  state.accountExpenseCardText = serverData.hesap_kapama_gider_isim;
  state.accountPrevalentID = serverData.hesap_kapama_cari;
  state.accountPrevalentText = serverData.hesap_kapama_cari_isim;
  // state.personalCompensationExpenseCardID = serverData.personel_tazminat_gider;
  // state.personalCompensationExpenseCardText =
  //   serverData.personel_tazminat_gider_isim;
  state.payPlan = serverData.odeme_plani;
  state.payTypes = serverData.odeme_tur.split(',').map(a => +a);
  state.participationPrice = serverData.katilim_bedeli;
  state.advertisementShareRate = serverData.reklam_pay_oran;
  state.contactFirstPhone = serverData.tel;
  state.contactSecondPhone = serverData.tel2;
  state.contactFax = serverData.faks;
  state.contactCell = serverData.mobil;
  state.contactEmail = serverData.email;
  state.contactWeb = serverData.web;
  state.contactAddress = serverData.adres;
  state.bank = serverData.banka;
  state.bankBranch = serverData.bankasube;
  state.bankBranchCode = serverData.bankasubekodu;
  state.bankAccountNo = serverData.hesapno;
  state.bankAccountAdditionalNo = serverData.hesapekno;
  state.iban = serverData.iban;
  state.companyTitle = serverData.fatura_unvan;
  state.taxOffice = serverData.vd;
  state.taxNumber = serverData.vn;
  state.identityNumber = serverData.tc;
  state.soleProprietorshipsOwnerName = serverData.sahis_isim;
  state.soleProprietorshipsOwnerSurname = serverData.sahis_soyisim;
  state.explanation = serverData.aciklama;
  state.screenNote = serverData.notlar;
  state.orderNote = serverData.siparis_notu;
  state.workingType = serverData.calisma_tur;
  state.insideGroup = serverData.grupici;
  state.invoiceAddress = serverData.fatura_adres;
  state.country = +serverData.ulke;
  state.province = +serverData.il;
  state.district = +serverData.ilce;
  state.password = serverData.parola;
  state.cards = serverData.kartlar;
  state.apacheIntegratedIP = serverData.magaza_entegre_ip;
  state.showParameters = serverData.parametrede_goster;
  state.orderDatabaseConnection = serverData.siparis_baglanti;
  state.dataDatabaseConnection = serverData.data_baglanti;
  state.orderPrinter = serverData.printer_siparis;
  state.cashRegisterMovementWay = serverData.kasa_hareket_yolu;
  state.depotDeliveryCode = serverData.depoteslim_kod;
  state.storeDeliveryCode = serverData.magazateslim_kod;
  state.companyCode = serverData.firma_kod;
  state.storeCode = serverData.magaza_kod;
  state.groupCode = serverData.grup_kodu.split(',').map(a => +a);
  state.responsibilityCenter = serverData.sorumluluk_merkezi;
  state.collectionInterval = serverData.tahsilat_araligi;
  state.showInBudget = serverData.butcede_goster;
  state.eInvoiceMail = serverData.varsayilan_urn;
  state.eWaybillMail = serverData.varsayilan_irsaliye_urn;
  state.accountClosingType = serverData.hesap_kapama_tipi;
  state.selectedPayPlans = objNameKeyMerger(
    serverData.odeme_sekli,
    serverData.odeme_sekli_isim
  );
  state.collectionOfficerName = serverData.tahsilat_yetkili;
  state.collectionOfficerEmail = serverData.tahsilat_yetkili_mail;
  state.collectionOfficerCell = serverData.tahsilat_yetkili_telefon;
  state.checkPurchasingConditionOnSale = +serverData.sipariste_satis_sarti;
  state.responsiblePersonelID = serverData.sorumlu_personel;
  state.automaticallySendBalanceMail = +serverData.otomatik_bakiye_mail;
  state.openAccountCanWork = +serverData.acik_kapali_calisir;
  state.activelyWorkingStores = serverData.calisilan_magazalar
    ? serverData.calisilan_magazalar.split(',').map(a => +a)
    : [];
  state.connectedParentPrevalentID = serverData.bagli_ust_cari;
  state.connectedParentPrevalentText = serverData.bagli_ust_cari_isim;
  state.professions = objNameKeyMerger(
    serverData.meslekler,
    serverData.meslekler_isim
  );
  state.personnels = objNameKeyMerger(
    serverData.meslekler,
    serverData.kadro.split(',')
  );
  state.selectedCategories = objNameKeyMerger(
    serverData.kategoriler,
    serverData.kategoriler_isim
  );
  state.companyProductTrackingSystemNo = serverData.firma_uts;
  state.creator = serverData.ekleyen_adsoyad;
  state.creationDate = dateTimeFromServer(serverData.eklenme_tarihi);
  state.editor = serverData.duzenleyen_adsoyad;
  state.editDate = dateTimeFromServer(serverData.duzenleme_tarihi);

  state.accountingCode = serverData.muhasebe_kod_id_kod;
  state.bufferRegisterText = serverData.tampon_kasa_isim;
  state.cashRegisterText = serverData.nakit_kasa_isim;
  state.cashierAdvanceRegisterText = serverData.kasiyeravans_kasa_isim;
  state.responsiblePersonelText = serverData.sorumlu_personel_isim;
}
