<template>
  <div class="flex flex-wrap">
    <div class="space-y-4 responseive-margin-allocation">
      <wo-double-form>
        <template #firstLabel>{{ formsData.designNumber.name }}</template>
        <template #firstElement>
          <base-input v-model="localState.design_number" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel> {{ formsData.modelCode.name }} </template>
        <template #firstElement>
          <base-input v-model="localState.model_code" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel> {{ formsData.modelName.name }} </template>
        <template #firstElement>
          <div class="flex items-center">
            <base-input v-model="localState.model_name" @blur="localState.model_id='0'" v-if="activeQuickSearchs.isNewModelName"/>
            <wo-quick-search
              v-else
              v-model="localState.model_name"
              :queryParameters="`?tablo=stok&q=`"
              @selected="handleModelQuickSearch"
              :itemRenderer="(item) => nameAndCodeWithLinkRenderer(item)"
            />
            <vs-checkbox v-model="activeQuickSearchs.isNewModelName" title="Yeni eklemek için seçiniz"/>
          </div>
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel> Görsel</template>
        <template #firstElement>
          <div class="flex items-center">
            <base-input
              accept="image/*"
              multiple
              type="file"
              @change="addUploadedFilesUrls"
            />
          </div>
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>
          {{ formsData.designer.name }}
        </template>
        <template #firstElement>
          <wo-quick-search
            v-model="localState.designer_name"
            queryParameters="?tablo=personel&q="
            @selected="item => handleSelectedDesigner(item)"
            :itemRenderer="personnelRenderer"
          />
        </template>
        <!-- <template #secondElement class="w-20">
          <wo-select :data="['Personel', 'Cari']" elementClasses="w-24">
            <template #defaultValue>
              <wo-select-item text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`etiket-${item}`"
                :itemValue="item"
                :text="item"
              />
            </template>
          </wo-select>
        </template> -->
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openMachineModal">
            Makine GG
          </span>
        </template>
        <template #firstElement>
          <wo-select :data="machines" v-model="localState.machine_id">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`etiket-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Makine Adeti</template>
        <template #firstElement>
          <base-input v-model="localState.total_machine" />
        </template>
      </wo-double-form>
    </div>
    <ImageGallery
      :imageUrls="localImages"
      class="responseive-margin-allocation"
      @deleteImage="(url) => deleteImage(url)"
    />
    <div class="space-y-4 responseive-margin-allocation">
      <wo-double-form>
        <template #firstLabel> Durum</template>
        <template #firstElement>
          <wo-select v-model="localState.status" :data="statusData">
            <template #default="{ item }">
              <wo-select-item
                :key="`status-${item.id}`"
                :itemValue="item.id"
                :text="item.name"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Hız</template>
        <template #firstElement>
          <base-input v-model="localState.speed" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Üretilen Makine ve Dk</template>
        <template #firstElement>
          <base-input v-model="localState.machine_produced" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>SSG Makineye Göre Dk</template>
        <template #firstElement>
          <base-input v-model="localState.fast_by_ssg_machine" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>{{ formsData.exitTime.name }}</template>
        <template #firstElement>
          <base-input v-model="localState.exit_time" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Makine Günlük Çalışma Süresi</template>
        <template #firstElement>
          <base-input v-model="localState.machine_working_time" />
        </template>
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>Toplam Gün</template>
        <template #firstElement>
          <base-input v-model="localState.machine_minute" readonly />
        </template>
      </wo-double-form>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { deleteImage as deleteImageAPI } from "../../helpers/modelPlanningApiService";
import { nameAndCodeWithLinkRenderer } from "@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink";
import ImageGallery from '../Information/ImageGallery.vue';
import { personnelRenderer } from '@/helpers/quickSearchListRenderers/qsPersonnel';
import { errorDialog } from '@/helpers/errorDialogs';
import { formsData } from '../../composables/formsData';

export default {
    components: {
        ImageGallery
    },
    setup() {
      const { state, machines, statuses } = useModelPlanlamaState();

      const localState = computed(() => state['design']);

      const activeQuickSearchs = reactive({
        isNewModelName: localState.value.model_id === 0,
      });

      const handleModelQuickSearch = (item) => {
        if (item.isSelected) {
          localState.value.model_id = item.id;
          localState.value.model_code = item.kod;
          localState.value.model_name = item.isim;
        } else if (!item.isSelected && localState.value.model_code) {
          localState.value.model_code = "";
          localState.value.model_name = "";
        }
    };

    const handleSelectedDesigner = (item) => {
      if (item.isSelected) {
        localState.value.designer_id = item.id;
        localState.value.designer_name = `${item.ad} ${item.soyad}`;
      } else {
        localState.value.designer_id = "";
        localState.value.designer_name = "";
      }
    };

    const localImages = ref([]);

    watch(
    [
      () => JSON.parse(JSON.stringify(localState.value.image_urls)),
      () => JSON.parse(JSON.stringify(localState.value.image_files)),
    ],
    () => {
      localImages.value = [
        ...localState.value.image_urls,
        ...localState.value.image_files,
        ];
      },
    );

    onMounted(() => {
      localImages.value = localState.value.image_urls;
    });

    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    const addUploadedFilesUrls = async (event) => {
      const fileUrls = [];
      const filesArray = Array.from(event.target.files);
      for (const file of filesArray) {
        const baseSixtyFourResult = await getBase64(file);
        fileUrls.push(baseSixtyFourResult);
      }
      localState.value.image_files.push(...fileUrls);
    };

    const deleteImage = (url) => {
        if (typeof url === "string") {
        const index = localState.value.image_files.findIndex((a) => a === url);
        localState.value.image_files.splice(index, 1);
        } else {
        try {
          deleteImageAPI(url.id);
          const index = localState.value.image_urls.findIndex((a) => a.id === url.id);
          localState.value.image_urls.splice(index, 1);
        } catch (error) {
          errorDialog(error);
        }
      }
    };

    watch(
      [
        () => localState.value.machine_produced,
        () => localState.value.total_machine,
        () => localState.value.machine_working_time
      ],
      () => {
        const result = parseFloat(parseInt(localState.value.machine_working_time) / 
            parseInt(localState.value.total_machine) * parseInt(localState.value.machine_produced)).toFixed(2);
        localState.value.machine_minute = isNaN(result) ? 0 : result;
      }
    );

    const statusData = computed(() => {
      return statuses['design'];
    });


    const openMachineModal = () => {
      window.MiniModulPen(450, 300, "Süreçler", "gp_makineler");
    };

      return {
        localState,
        handleModelQuickSearch,
        nameAndCodeWithLinkRenderer,
        openMachineModal,
        machines,
        personnelRenderer,
        handleSelectedDesigner,
        addUploadedFilesUrls,
        deleteImage,
        localImages,
        activeQuickSearchs,
        statusData,
        formsData
      };
    }
};
</script>