<template>
        <OrderModal
            :stateKey="stateKey"
            :orderModal="purchaseOrderModal"
            @orderModal="$emit('purchaseOrderModal', $event)"
            :columns="columns"
            orderType="purchase"
            :dataToSend="dataToSend"
            title="Satın Alma Sipariş Oluştur"
            :isInvoice="false"
        >
            <template #quickSearches>
                <div class="d-inline flex">
                    <label class="mr-2">Satın Almacı Seçiniz</label>
                    <wo-quick-search 
                        v-model="quickSearchesNames.buyerName"
                        :queryParameters="`?tablo=personel&aktif=1&stok_satinalmaci=1&q=`"
                        @selected="handleBuyer"
                        :itemRenderer="(item) => personnelRenderer(item)"
                    />
                    <label class="ml-4 mr-2">Üretici Seçiniz</label>
                    <wo-quick-search
                        v-model="quickSearchesNames.manufacturerName"
                        :queryParameters="`?tablo=stok_ureticiler&anasaglayici=&sorumlu=&q=`"
                        :itemRenderer="(item) => nameAndCodeRenderer(item)"
                        @selected="handleManufacturer"
                    />
                </div>
            </template>
        </OrderModal>
</template>

<script>
import { nameAndCodeRenderer } from "@/helpers/quickSearchListRenderers/qsNameAndCode";
import { personnelRenderer } from '@/helpers/quickSearchListRenderers/qsPersonnel';
import OrderModal from './OrderModal';
import { ref } from '@vue/composition-api';

export default {
    name: "PurchaseOrderModal",
    props: {
        stateKey: String,
        purchaseOrderModal: Boolean
    },    
    components: {
        OrderModal
    },
    setup() {

        const columns = [
            "Kod",
            "Stok Adı",
            "Renk",
            "Beden",
            "Sip. Miktarı",
            "Birim",
            "Stok Durum",
            "Durum"
        ];

        const quickSearchesNames = ref({
            buyerName: '',
            manufacturerName: ''
        });

        const dataToSend = ref({
            buyerID: '',
            manufacturerID: '',
        });

        const handleBuyer = (item) => {
            const { isSelected, id, ad, soyad } = item;
            if (isSelected) {
                dataToSend.value.buyerID = id;
                quickSearchesNames.value.buyerName = `${ad} ${soyad}`;
            } else {
                dataToSend.value.buyerID = '';
                quickSearchesNames.value.buyerName = '';
            }
        };

        const handleManufacturer = (item) => {
            if (item.isSelected) {
                dataToSend.value.manufacturerID = item.id;
                quickSearchesNames.value.manufacturerName = item.isim;
            } else {
                dataToSend.value.manufacturerID = '';
                quickSearchesNames.value.manufacturerName = '';
            }
        };

        return {
            columns,
            handleBuyer,
            handleManufacturer,
            dataToSend,
            personnelRenderer,
            nameAndCodeRenderer,
            quickSearchesNames
        };
    }
};
</script>

<style>

</style>            
