import Vue from 'vue';
import usePrevalentState from '../consumables/usePrevalentState';

const { state } = usePrevalentState();

export default function controlPrevalentCode(code) {
  if (window.carikartlarda_muhasebe_hesabi_zorunlu_olmasin !== '1') {
    if (
      state.accountingCode === '' ||
      state.accountingCode === state.prevalentCode
    ) {
      Vue.set(state, 'accountingCode', code);
    }
  }
}
