const workingTypeData = [
  { id: '1', name: 'Staj' },
  { id: '2', name: 'Freelance' },
  { id: '3', name: 'Yarı Zamanlı' },
  { id: '4', name: 'Tam Zamanlı' }
];

const educationData = [
  { id: '1', name: 'İlkokul' },
  { id: '2', name: 'Lise' },
  { id: '3', name: 'Üniversite' },
  { id: '4', name: 'Aranmıyor' }
];

const militaryData = [
  { id: '1', name: 'Yapıldı' },
  { id: '2', name: 'Yapılmadı' },
  { id: '3', name: 'Aranmıyor' }
];

const experienceData = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5+' },
  { id: '6', name: 'Aranmıyor' }
];

const genderData = [
  { id: '1', name: 'Bay' },
  { id: '2', name: 'Bayan' },
  { id: '3', name: 'Aranmıyor' }
];

const disabledData = [
  { id: '1', name: 'Engelli' },
  { id: '2', name: 'Engelsiz' },
  { id: '3', name: 'Aranmıyor' }
];

export function jobListingSelectData() {
  return {
    workingTypeData,
    educationData,
    militaryData,
    experienceData,
    genderData,
    disabledData
  };
}
