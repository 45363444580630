<template>
    <wo-modal
        title="Üretim Detayları"
        class="wide-dialog-90vw one-step-behind-dialog"
        :active="variantSlipModal"
        @active="$emit('variantSlipModal', $event)"
        :closeAct="cancel"
        :buttonsHidden="true"
    >
        <wo-table>
            <template #thead>
                <wo-th
                    v-for="title in titles"
                    :key="title"
                >
                    {{title}}
                </wo-th>                    
            </template>
            <template v-for="(recipe, index) in recipes">
                <wo-tr :key="`recipe-${index}`" id="main-product" class="text-left">
                    <wo-td>
                        {{ index + 1 }}
                    </wo-td>
                    <wo-td class="text-left">
                        {{recipe.stockCode}}{{recipe.stockName}}
                    </wo-td>
                    <wo-td  style="width: 15px;">
                        <wo-select v-model="recipe.depotID" :data="depots">
                            <template #defaultValue>
                            <wo-select-item :itemValue="0" text="- Seçiniz -" />
                            </template>
                            <template #default="{ item }">
                            <wo-select-item
                                :key="`depot-${item.id}`"
                                :itemValue="item.id"
                                :text="item.isim"
                            />
                            </template>
                        </wo-select>
                    </wo-td>
                    <wo-td>
                        <wo-select
                            v-model="recipe.stockUnitID"
                            :data="stockUnits"
                            elementClasses="w-28 m-auto"
                        >
                            <template #defaultValue>
                                <wo-select-item :itemValue="0" text="- Seçiniz -" />
                            </template>
                            <template #default="{ item }">
                                <wo-select-item
                                    :itemValue="item.id"
                                    :text="item.isim"
                                    :key="`unit-${item.id}`"
                                />
                            </template>
                        </wo-select>
                    </wo-td>
                    <wo-td>
                        <wo-float-input 
                            v-model="recipe.amount"
                            @blur="calculatePrice(recipe.stockID)"
                        />
                    </wo-td>
                    <wo-td>
                        <wo-float-input 
                            v-model="recipe.cost"
                            @blur="calculatePrice(recipe.stockID)"
                        />
                    </wo-td>
                    <wo-td>
                        <wo-select
                            v-model="recipe.currency"
                            :data="localExchangeRates"
                            elementClasses="w-16"
                        >
                            <template #default="{ item }">
                                <wo-select-item
                                    :itemValue="item.kod"
                                    :text="item.kod"
                                    :key="`exchange-rate-${item.kod}`"
                                />
                            </template>
                        </wo-select>
                    </wo-td>
                    <wo-td>
                        <wo-float-input
                            v-model="recipe.price"
                        >

                        </wo-float-input>
                    </wo-td>
                </wo-tr>
                <wo-tr v-for="detail in recipe.details" :key="detail.stockID">
                    <wo-td></wo-td>
                    <wo-td>{{ detail.stockCode }} {{ detail.stockName }}</wo-td>
                    <wo-td></wo-td>
                    <wo-td>{{ detail.unit }}</wo-td>
                    <wo-td>{{ parseFloat(detail.amount).toFixed(2) }}</wo-td>
                    <wo-td>{{ parseFloat(detail.cost).toFixed(2) }}</wo-td>
                    <wo-td>{{ detail.currency }}</wo-td>
                    <wo-td>{{ parseFloat(detail.price).toFixed(2) }}</wo-td>
                </wo-tr>
            </template>
        </wo-table>
        <wo-button 
            class="mt-4"
            text="Üretim Fişi Oluştur"
            @click="createProductionSlip()"
        />
    </wo-modal>
</template>

<script>
import { storeVariantProductionSlip } from '@/helpers/ApiController';
import { errorDialog } from '@/helpers/errorDialogs';
import { computed } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';
import { primaryConfirmDialog, primaryDialog } from '@/helpers/primaryDialogs';
export default {
    name: "VariantSlipModal",
    props: {
        variantSlipModal: Boolean,
        recipesForVariantSlip: Array
    },
    setup(props, {emit}) {

        const { state, stockUnits, exchangeRates, depots} = useModelPlanlamaState();

        const recipes = computed(() => props.recipesForVariantSlip);
        
        const localExchangeRates = computed(() => {
            return exchangeRates.value['confirmed'];
        });

        const amountControl = (recipes) => {
            const details = Array.prototype.concat.apply([], recipes.map(recipe => recipe.details));
            const result = { status: true, message: "" };
            details.forEach(detail => {
                recipes.forEach(recipe => {
                    if (parseInt(detail.stockID) === recipe.stockID) {
                        const mainStock = recipes.find(stock => stock.details.find(detailStock => parseInt(detailStock.stockID) === parseInt(recipe.stockID)));
                        if ((parseInt(recipe.amount) / parseInt(detail.amount)) < parseInt(mainStock.amount)) {
                            result.status = false;
                            result.message = `${mainStock.stockName} stoğundan üretebilmeniz için ${recipe.stockName} stoğundan yeterli miktar bulunmamaktadır. Yine de devam etmek istiyor musunuz?`;
                        }
                    }
                });
            });
            return result;
        };

        const storeProductionSlip = () => {
            const productionSlip = {
                documentID: state['confirmed'].info.draftID,
                branch: state['confirmed'].info.branch,
                serial: state['confirmed'].info.serial,
                depotID: state['confirmed'].info.depot,
                recipeGroupID: "1",
                type: '1',
                expenses: state['confirmed'].details.expenses,
                recipes: recipes.value.map(recipe => [recipe]), // backend istedigi icin boyle yapiyorum
                status: '2',
            };
            storeVariantProductionSlip(productionSlip).then((response) => {
                if (response.status === 200) {
                primaryDialog(response.data.message, "Üretim Fişi Oluştur", () => window.open(`${window.location.origin}/${response.data.redirect}`, '_blank'));
                }
            }).catch(error => {
                errorDialog(error.response.data.message, "Hata", () => window.open(`${window.location.origin}/${error.response.data.redirect}`, '_blank'));
            });
        };

        const createProductionSlip = () => {
            const control = amountControl(recipes.value);
            if (!control.status) {
                primaryConfirmDialog(control.message, storeProductionSlip);
            } else {
                storeProductionSlip();
            }
        };
        
        const titles = [
        "Sıra",
        "Stok",
        "Depo",
        "Birim",
        "Miktar",
        "Maliyet",
        "Para Birimi",
        "Tutar",
        ];

        const calculatePrice = (stockID) => {
            const stock = recipes.value.find(item => item.stockID === stockID);
            stock.price = parseFloat(stock.cost) * parseFloat(stock.amount);
        };

        const cancel = () => {
            emit("variantSlipModal", false);
        };

        return {
            cancel,
            titles,
            recipes,
            stockUnits,
            localExchangeRates,
            calculatePrice,
            createProductionSlip,
            depots
        };
    }
};
</script>

<style lang="scss" scoped>
#main-product {
        background-color: rgb(184, 183, 183);
        font-size: 1.1rem;
}
</style>