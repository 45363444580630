<template>
  <div>
    <div class="mb-3">
      <ul class="list-none w-full flex overflow-auto pb-3 space-x-4">
        <li v-for="(tabDatum, idx) in tabData" :key="`tab-${idx}`">
          <wo-button
            class="shadow-none whitespace-nowrap hover:bg-gray-100 rounded rounded-bl-none rounded-br-none font-medium"
            :text="tabDatum.label"
            type="line"
            :disabled="tabDatum.disabled"
            :color="currentTab.label === tabDatum.label ? 'primary' : 'dark'"
            @click="changeTab(idx)"
          />
        </li>
      </ul>
    </div>
    <div>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <component :is="currentComponent" v-bind="currentTab.props" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WOTabMaker',
  props: {
    tabData: Array,
    currentTabIndex: {type: Number, default: 0},
  },
  data() {
    return {
      currentTab: { label: '', component: '', props: {} }
    };
  },
  methods: {
    changeTab(idx) {
      Object.assign(this.currentTab, this.tabData[idx]);
    }
  },

  mounted() {
    this.changeTab(this.currentTabIndex);
  },

  watch: {
    currentTabIndex(newVal) {
      this.changeTab(newVal);
    },
  },

  computed: {
    tabComponents() {
      return this.tabData.map(a => a.component);
    },
    currentComponent() {
      return this.currentTab.component;
    }
  }
};
</script>

<style lang="scss" scoped></style>
