<template>
  <div class="flex flex-row relative items-start">
    <MarqueeText
      class="w-56 h-8"
      :repeat="3"
      :duration="20"
      :key="'okuyanlar-marquee-text'"
    >
      {{ readersTextShow }}
    </MarqueeText>
    <feather-icon
      icon="ChevronDownIcon"
      class="cursor-pointer hover:text-primary"
      @click="handleReadersDropdown(true)"
    />
    <div
      class="fixed top-0 left-0 h-full w-full z-front"
      v-if="readersDropdown"
      @click="handleReadersDropdown(false)"
    ></div>
    <div v-if="readersDropdown" class="wo-dropdown top-10 right-0 p-2">
      <WOQuickSearch
        ref="readerQuickSearch"
        queryParameters="?tablo=kullanicilar&q="
        @selected="item => selectedReader(item)"
        :itemRenderer="usersRenderer"
      />
      <div class="flex flex-col items-center mt-4">
        <div
          v-for="obj in addedReadersArray"
          :key="`kullanici-${obj.id}`"
          class="w-full mb-4 flex items-center"
        >
          <vs-checkbox
            v-model="obj.isAddedToReaders"
            @input="e => handleColumnVisibility(e, obj)"
            class="font-normal non-selectible-text"
          >
            {{ obj.isim }}
          </vs-checkbox>
          <i
            class="fa fa-remove pl-2"
            style="cursor: pointer"
            @click="removeReader(obj)"
          >
          </i>
        </div>
        <div class="flex flex-row justify-between w-full px-1">
          <span class="hover:text-primary" @click="selectAllCheckboxes(true)">
            Tümünü Seç
          </span>
          <span class="hover:text-primary" @click="selectAllCheckboxes(false)">
            Bırak
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOQuickSearch from '@/components/WO/WOQuickSearch.vue';

import MarqueeText from 'vue-marquee-text-component';

import { usersRenderer } from '@/helpers/quickSearchListRenderers/qsUsers.js';

export default {
  name: 'OfferReaders',
  components: {
    WOQuickSearch,
    MarqueeText
  },
  props: {
    addedReadersArray: Array
  },
  data() {
    return {
      readersDropdown: false
    };
  },
  methods: {
    usersRenderer,
    selectedReader(item) {
      if (item.isSelected) {
        this.$emit('addNewReader', { ...item, isAddedToReaders: true });
        this.$nextTick(() => {
          // entere a basinca normal form enter skipini engeller
          this.$refs.readerQuickSearch.$el.focus();
          window.event.stopPropagation();
        });
      }
    },
    selectAllCheckboxes(val) {
      for (const obj of this.addedReadersArray) {
        obj.isAddedToReaders = val;
      }
    },
    handleReadersDropdown(val) {
      this.readersDropdown = val;
    },
    removeReader(obj) {
      this.$emit('removeReader', obj);
    }
  },
  computed: {
    readersTextShow() {
      let returnString = '';
      for (const obj of this.addedReadersArray) {
        if (obj.isAddedToReaders) {
          returnString = `${returnString} ${obj.isim}, `;
        }
      }
      return returnString.slice(0, -2);
    }
  }
};
</script>

<style scoped>
.marquee-text-text {
  margin-left: 4rem;
}
</style>
