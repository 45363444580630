<template>
    <wo-modal
        :active="productionSlipModal"
        class="wide-dialog-90vw one-step-behind-dialog"
        @active="$emit('productionSlipModal', $event)"
        :buttonsHidden="true"
        title="Üretim Fişi Oluştur"
    >
        <div class="d-inline flex ml-2 mb-2">
            <label class="mr-2 mt-1">Depo</label>
            <wo-quick-search
                queryParameters="?tablo=depolar&uretim_depo=1&q="
                :itemRenderer="nameAndCodeRenderer"
                v-model="depot.name"
                @selected="handleDepot"
            />
            <label class="ml-4">Reçete Grubu</label>
                <wo-select
                    :data="recipeGroups"
                    v-model="recipeGroup.id"
                    elementClasses="w-28 m-auto"
                >
                    <template #defaultValue>
                        <wo-select-item :itemValue="0" text="- Seçiniz -" />
                    </template>
                    <template #default="{ item }">
                        <wo-select-item
                            :itemValue="item.id"
                            :text="item.isim"
                            :key="`recipeGroup-${item.id}`"
                        />
                    </template>
                </wo-select>
        </div>
        <div class="d-flex">
            <wo-table>
                <template #thead>
                    <wo-th v-for="title in titles" :key="`title-${title}`">
                        {{ title }}
                    </wo-th>
                </template>
                <template v-for="stock in stockToProductionSlip">
                    <wo-tr id="main-product" class="text-left" :key="stock.id" v-if="stock.details.length > 0">
                        <wo-td class="text-left">{{stock.stockCode}} {{stock.entryName}}</wo-td>
                        <wo-td>
                            <wo-select
                                v-model="stock.currency"
                                :data="localExchangeRates"
                                elementClasses="w-16"
                            >
                                <template #default="{ item }">
                                    <wo-select-item
                                    :itemValue="item.kod"
                                    :text="item.kod"
                                    :key="`exchange-rate-${item.kod}`"
                                    />
                                </template>
                            </wo-select>
                        </wo-td>
                        <wo-td>
                            <wo-select
                                v-model="stock.unit"
                                :data="stockUnits"
                                elementClasses="w-28 m-auto"
                            >
                                <template #defaultValue>
                                    <wo-select-item :itemValue="0" text="- Seçiniz -" />
                                </template>
                                <template #default="{ item }">
                                    <wo-select-item
                                        :itemValue="item.id"
                                        :text="item.isim"
                                        :key="`stockUnit-${item.id}`"
                                    />
                                </template>
                            </wo-select>
                        </wo-td>
                        <wo-td>
                            <wo-float-input
                                v-model="stock.amount"
                                @blur="calculateDetailsAmount(stock.stockID), calculatePrice(stock.stockID), calculateDetailsPrice(stock.stockID)"
                                :elementClasses="'w-16'"
                            />
                        </wo-td>
                        <wo-td>
                            <wo-float-input
                                v-model="stock.cost"
                                readonly
                                :elementClasses="'w-16'"
                            />
                        </wo-td>
                        <wo-td>
                            <wo-float-input
                                v-model="stock.price"
                                @blur="calculateCost(stock.stockID)"
                                :elementClasses="'w-16'"
                            />
                        </wo-td>
                        <wo-td>
                            <wo-select
                                v-model="stock['station']"
                                :data="stations"
                                elementClasses="w-28 m-auto"
                            >
                                <template #defaultValue>
                                    <wo-select-item :itemValue="0" text="- Seçiniz -" />
                                </template>
                                <template #default="{ item }">
                                    <wo-select-item
                                        :itemValue="item.id"
                                        :text="item.isim"
                                        :key="`stockStation-${item.id + 1}`"
                                    />
                                </template>
                            </wo-select>
                        </wo-td>
                        <wo-td>
                            <base-input
                                v-model="stock.description"
                                :elementClasses="'w-16'"
                            />
                        </wo-td>
                    </wo-tr>
                    <wo-tr v-for="detail in stock.details" :key="detail.stockID">
                        <wo-td>{{detail.stockCode}} {{detail.stockName}}</wo-td>
                        <wo-td>
                            <wo-select
                                v-model="detail.currency"
                                :data="localExchangeRates"
                                elementClasses="w-16"
                            >
                                <template #default="{ item }">
                                    <wo-select-item
                                        :itemValue="item.kod"
                                        :text="item.kod"
                                        :key="`exchange-rate-${item.kod}`"
                                    />
                                </template>
                            </wo-select>
                        </wo-td>
                        <wo-td>
                            <wo-select
                                v-model="detail.stockUnitID"
                                :data="stockUnits"
                                elementClasses="w-28 m-auto"
                            >
                                <template #defaultValue>
                                    <wo-select-item :itemValue="0" text="- Seçiniz -" />
                                </template>
                                <template #default="{ item }">
                                    <wo-select-item
                                        :itemValue="item.id"
                                        :text="item.isim"
                                        :key="`detailUnit-${item.id}`"
                                    />
                                </template>
                            </wo-select>
                        </wo-td>
                        <wo-td>
                            <wo-float-input
                                v-model="detail.calculatedAmount"
                                readonly
                                @blur="calculateDetailsAmount(stock.stockID), calculatePrice(stock.stockID)"
                                :id="`calculatedAmount-${detail.stockID}`"
                                :elementClasses="'w-16'"
                            />
                        </wo-td>
                        <wo-td class="text-center">
                            {{ detail.cost }}
                        </wo-td>
                        <wo-td class="text-center" :id="`detailPrice-${detail.stockID}`">
                            {{ detail.price }}
                        </wo-td>
                        <wo-td></wo-td>
                        <wo-td>
                            <base-input
                                v-model="detail.description"
                                :elementClasses="'w-16'"
                                placeholder="Açıklama"
                            />
                        </wo-td>
                    </wo-tr>
                </template>
            </wo-table>
            <div class="ml-2 mt-2">
                <wo-button text="Kaydet" @click="createProductionSlip" />
            </div>
        </div>
    </wo-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../../composables/useModelPlanlamaState';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';
import { useUretimPlanlamaState } from '@/views/UretimPlanlama/Form/composables/useUretimPlanlamaState';
import { storeProductionSlip } from '@/helpers/ApiController';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { trStrToNum } from '@/helpers/NumberController';
import { getValueById } from '@/helpers/dataFilterHelper';
export default {
    name: "ProductionSlip",
    props: {
        productionSlipModal: Boolean,
        stockToProductionSlip: Array,
        stateKey: String,
    },
    setup(props) {

        const { exchangeRates, stockUnits, state, recipeGroups, depots} = useModelPlanlamaState();

        const { stations } = useUretimPlanlamaState();

        const localState = computed(() => {
            return state['confirmed'];
        });

        const depot = computed(() => {
            return {
                id: localState.value.info.depot,
                // name: depots.value.find(depot => depot.id === parseInt(localState.value.info.depot)).isim,
                name: getValueById(depots.value, parseInt(localState.value.info.depot), "isim"),
            };
        });

        const recipeGroup = ref({
            id: 1,
            name: '',
        });

        const handleDepot = (item) => {
            if (item.isSelected) {
                depot.value.id = item.id;
                depot.value.name = item.isim;
            } else {
                depot.value.id = 0;
                depot.value.name = '';
            }
        };

        const localSemiPruducts = computed(() => {
            return props.semiProducts;
        });

        const localExchangeRates = computed(() => {
            return exchangeRates.value['confirmed'];
        });

        const localStocks = computed(() => {
            return props.stockToProductionSlip;
        });

        const calculateDetailsAmount = (stockID) => {
            const stock = localStocks.value.find(item => item.stockID === stockID);
            stock.details.forEach(detail => {
                detail.calculatedAmount = parseFloat(detail.stockAmount) * parseFloat(stock.amount);
                document.getElementById(`calculatedAmount-${detail.stockID}`).value = parseFloat(detail.calculatedAmount).toFixed(4);
            });
        };

        const calculateDetailsPrice = (stockID) => {
            const stock = localStocks.value.find(item => item.stockID === stockID);
            stock.details.forEach(detail => {
                detail.price = trStrToNum(detail.calculatedAmount) * trStrToNum(detail.cost);
                // document.getElementById(`detailPrice-${detail.stockID}`).value = parseFloat(detail.price).toFixed(4);
            });
        };

        const calculateCost = (stockID) => {
            const stock = localStocks.value.find(item => item.stockID === stockID);
            stock.cost = trStrToNum(stock.price) / trStrToNum(stock.amount);
        };

        const calculatePrice = (stockID) => {
            const stock = localStocks.value.find(item => item.stockID === stockID);
            stock.price = trStrToNum(stock.cost) * trStrToNum(stock.amount);
        };

        const rowIsValid = (row) => {
            return row.stockID !== "" && row.stockName !== "" && row.amount !== "0,00" &&
                row.cost !== "0,00"  && row.price !== "0,00" && row.unit !== "";
        };

        const productionSlipValidator = () => {
            const depotID = parseInt(depot.value.id);
            const recipeGroupID = parseInt(recipeGroup.value.id);
            const mainStockIsValid = localStocks.value.every(row => rowIsValid(row));
            const detailsIsValid = localStocks.value.every(row => row.details.every(detail => rowIsValid(detail)));

            return depotID > 0 && recipeGroupID > 0 && mainStockIsValid && detailsIsValid;
        };

        const createProductionSlip = () => {
            if (!productionSlipValidator()) {
                primaryDialog('Lütfen gerekli alanları doldurunuz.', 'Hata');
                return;
            }
            const stocksID = localStocks.value.map(item => item.stockID);
            const semiProductDetails = localState.value.details.semiProducts.filter(item => stocksID.includes(item.entryID)).
            flatMap(item => {
                return item.semiProductDetails.map(detail => ({...detail, id: item.id}));
            });
            const semiProductsExpenses = semiProductDetails.filter(item => item.entryType === "1");
            const semiProducts = JSON.parse(JSON.stringify(localState.value.details.semiProducts));
            semiProductsExpenses.forEach(item => {
                const semiProductIndex = semiProducts.findIndex(semiProduct => semiProduct.id === item.id);
                const idIndex = semiProducts[semiProductIndex].semiProductDetails.findIndex(detail => item.stockID === detail.stockID);
                item['subId'] = idIndex;
                item['entryID'] = item.stockID;
                item['entryName'] = item.stockName;
                item['mainCurrencyPrice'] = item.price;
                semiProducts[semiProductIndex].semiProductDetails[idIndex] = {};
            });
            

            const productionSlip = {
                documentID: props.stateKey === 'confirmed' ? state[props.stateKey].info.draftID : state[props.stateKey].info.id,
                type: 1,
                status: '2',
                branch: localState.value.info.branch,
                date: localState.value.info.date,
                deliveryDate: localState.value.info.deliveryDate,
                depotID: depot.value.id,
                serial: localState.value.info.serial,
                sequence: localState.value.info.sequence,
                modelCode: localState.value.info.modelCode,
                // expenses: localState.value.details.stocks.filter
              // (item => item.isSelected).length > 0 ? localState.value.details.expenses.concat(semiProductsExpenses) : semiProductsExpenses,
                expenses: localState.value.details.expenses.map(item => { return { ...item, subId: "0" }; }).concat(semiProductsExpenses),
                recipeGroupID: recipeGroup.value.id,
                prescriptionType: 0,
                stocks: localStocks.value.map((stock) => {
                    return {
                        amount: stock.amount,
                        cost: stock.cost,
                        currency: stock.currency,
                        price: stock.price,
                        stockID: stock.stockID,
                        stockName: stock.stockName,
                        unit: stock.unit,
                        usingRecipe: stock.usingRecipe,
                    };
                }),
            };

            storeProductionSlip(productionSlip).then(response => {
                    if (response.status === 200) {
                        primaryDialog(response.data.message, 'Üretim Fişi', () => {
                            window.open(`${window.location.origin}/${response.data.redirect}`, '_blank');
                        });
                    }
                });
        };

        const titles = [
            // "Sıra",
            "Stok",
            "Döviz",
            "Birim",
            "Miktar",
            // "Koli İçi Miktar",
            // "Koli No",
            "Maliyet",
            "Tutar",
            "İstasyon",
            "Açıklama",
            // "İşlem",
        ];

        return {
            titles,
            // localFinalProduct,
            localExchangeRates,
            stockUnits,
            createProductionSlip,
            calculateDetailsAmount,
            calculateCost,
            stations,
            localSemiPruducts,
            localState,
            calculatePrice,
            nameAndCodeRenderer,
            depot,
            handleDepot,
            recipeGroup,
            recipeGroups,
            calculateDetailsPrice
        };

    },
};
</script>

<style>
    #main-product {
        background-color: rgb(184, 183, 183);
        font-size: 1.1rem;
    }
</style>
