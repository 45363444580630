/* 
  Acilan dropdownlarin pozisyonlarini hesaplamaya ve yerlestirmeye yariyor
  Hizli ara, tablo filtreleri gibi componentlerde kullaniliyor.
  
  dropdown: Acilacak dropdown Vue ref => someDropdownTagRef.value
  anchor: dropdownu acan buton input vs Vue ref => someButtonRef.value.$el 
*/

export default function dropdownPositioner(
  dropdown,
  anchor,
  offset = { top: 0, right: 0, left: 0, bottom: 0 }
) {
  if (dropdown) {
    const anchorPosition = anchor.getBoundingClientRect();
    dropdown.style.width = `${anchorPosition.width}px`;
    if (screen.width <= dropdown.clientWidth + anchorPosition.left) {
      dropdown.style.left = `${anchorPosition.right - dropdown.clientWidth}px`;
    } else {
      dropdown.style.left = `${anchorPosition.left}px`;
    }
    if (screen.height <= dropdown.clientHeight + anchorPosition.bottom) {
      dropdown.style.top = `${anchorPosition.top -
        dropdown.clientHeight -
        offset.top}px`;
    } else {
      dropdown.style.top = `${anchorPosition.bottom + offset.top}px`;
    }
  }
}
