import { ref } from '@vue/composition-api';

const logValues = ref('');

export default function() {
  return logValues;
}

export function setLogValues(payload) {
  logValues.value = payload;
}
