<template>
  <wo-table>
    <template #thead>
      <template v-for="col in columns">
        <wo-th :key="`headerWithLink-${col.key}`" v-if="!col.modalLink">
          {{ col.title }}
        </wo-th>
        <wo-th :key="`header-${col.key}`" v-else>
          <span
            class="underlined-hovered-link"
            @click="handleModal(col.title, col.modalLink)"
            >{{ col.title }}</span
          >
        </wo-th>
        <!-- <template
          v-if="col.key !== 'primaryMaterial' && col.key !== 'colorsize'"
        >
          <wo-th :key="`header-${col.valueKey}`">
            {{ col.title }}
          </wo-th>
        </template>
        <template v-else-if="col.key !== 'colorsize'">
          <wo-th :key="`header-${col.valueKey}`">
            <span
              class="underlined-hovered-link"
              @click="openPrimaryMaterialModal"
              >{{ col.title }}</span
            >
          </wo-th>
        </template> -->
      </template>
    </template>
    <template>
      <ModelPlanlamaDetailsEditableRow
        :stateKey="stateKey"
        source="tableForm"
        @entryType="event => (entryType = event)"
      >
        <template #processes>
          <wo-button text="Ekle" data-id="yeni" @click="saveDetailLine" />
        </template>
      </ModelPlanlamaDetailsEditableRow>
    </template>
  </wo-table>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import getColumns from '@/config/menu/model-planning/detailsHeader';
import ModelPlanlamaDetailsEditableRow from './ModelPlanlamaDetailsEditableRow.vue';
import { errorDialog } from '@/helpers/errorDialogs';
import {
  editableRowValidationErrorMessage,
  validateEditableRow,
  validateSelectBoxesIsSelected
} from '../../helpers/modelPlanningEditableRowValidator';

export default {
  components: {
    ModelPlanlamaDetailsEditableRow
  },
  props: {
    stateKey: String,
    tableColumns: Array
  },
  setup(props) {
    const { state, detailsTableFormTemplate } = useModelPlanlamaState();

    const localStateInfo = state[props.stateKey];

    const handleModal = (title, link) => {
      window.MiniModulPen(450, 300, title, link);
    };

    const entryType = ref('0');

    const localState = computed({
      get() {
        return state[props.stateKey].details.tableForm;
      },
      set(val) {
        state[props.stateKey].details.tableForm = val;
      }
    });

    const columns = computed(() => {
      console.log(getColumns(entryType.value, 'form'));
      return getColumns(entryType.value, 'form');
    });

    // const setTableColumns = event => {
    //   if (event === '2') {
    //     columns.value = formHeaders([
    //       {
    //         title: 'Sepet Miktarı',
    //         key: 'amount'
    //       },
    //       {
    //         title: 'Sipariş Miktarı',
    //         key: 'orderAmount'
    //       }
    //     ]);
    //   } else {
    //     columns.value = formHeaders([
    //       {
    //         title: 'Miktar',
    //         key: 'amount'
    //       }
    //     ]);
    //   }
    // };

    const saveDetailLine = () => {
      if (
        validateEditableRow(localState.value) &&
        validateSelectBoxesIsSelected(
          localState.value.entryID,
          localState.value.entryName,
          localState.value.prevalentID,
          localState.value.prevalentName
        )
      ) {
        switch (+localState.value.entryType) {
          case 0:
            state[props.stateKey].details.semiProducts.push(localState.value);
            break;
          case 1:
            state[props.stateKey].details.stocks.push(localState.value);
            break;
          case 2:
            state[props.stateKey].details.expenses.push(localState.value);
            break;
        }
        const entryType = localState.value.entryType;
        localState.value = {...detailsTableFormTemplate(), entryType};
      } else {
        errorDialog(editableRowValidationErrorMessage);
      }
    };

    return {
      localState,
      saveDetailLine,
      localStateInfo,
      columns,
      entryType,
      handleModal
    };
  }
};
</script>

<style lang="scss" scoped></style>
