<template>
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="kontrol_listesi"
    :auth="moduleConfig.auth"
  >
    <!-- <div class="mb-3">
      <ul class="list-none w-full flex overflow-auto pb-3 space-x-4">
        <li v-for="(tabDatum, idx) in tabData" :key="`tab-${idx}`">
          <wo-button
            class="shadow-none whitespace-nowrap hover:bg-gray-100 rounded rounded-bl-none rounded-br-none font-medium"
            :text="tabDatum"
            :color="version === tabDatum ? 'primary' : 'dark'"
            type="line"
            @click="version = tabDatum"
          />
        </li>
      </ul>
    </div> -->
    <KontrolListesiForm />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import KontrolListesiForm from './KontrolListesiForm.vue';

import { computed } from '@vue/composition-api';

export default {
  components: {
    WOModuleWrapper,
    KontrolListesiForm
  },
  setup(props, { root }) {
    const moduleConfig = computed(() => {
      if (root.$route.params.id) {
        return {
          title: 'Kontrol Listesi Düzenle',
          auth: 'kontrol_listesi_duzenle'
        };
      } else {
        return { title: 'Kontrol Listesi', auth: 'kontrol_listesi_ekle' };
      }
    });

    // const tabData = ref(['v1', 'v2']);

    // const version = ref('v2');

    return {
      moduleConfig,
      // tabData,
      // version
    };
  }
};
</script>

<style></style>
