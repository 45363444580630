<template>
  <form @submit.prevent class="space-y-4">
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Adres İsim</template>
      <template #firstElement>
        <base-input v-model="state.name" />
      </template>
      <template #secondBefore>
        <wo-country-province-district
          :labelSize="labelSize"
          :country.sync="state.countryID"
          :province.sync="state.provinceID"
          :district.sync="state.districtID"
        />
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Mahalle</template>
      <template #firstElement>
        <base-input v-model="state.neighbourhood" />
      </template>
      <template #secondLabel>Cadde</template>
      <template #secondElement>
        <base-input v-model="state.avenue" />
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Sokak</template>
      <template #firstElement>
        <base-input v-model="state.street" />
      </template>
      <template #secondLabel>Kapı No</template>
      <template #secondElement>
        <base-input v-model="state.doorNo" />
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Telefon</template>
      <template #firstElement>
        <wo-tel-input v-model="state.telephone" />
      </template>
      <template #secondLabel>Açıklama</template>
      <template #secondElement>
        <wo-textarea v-model="state.explanation" />
      </template>
    </wo-double-form>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Depo</template>
      <template #firstElement>
        <wo-quick-search
          v-model="state.depotName"
          queryParameters="?tablo=depolar&q="
          @selected="handleSelectedDepot"
          :itemRenderer="nameAndCodeRenderer"
        />
      </template>
      <template #secondLabel>Zip Kodu</template>
      <template #secondElement>
        <base-input v-model="state.zipCode" />
      </template>
    </wo-double-form>

    <div class="space-x-4">
      <wo-button data-id="kaydet" @click="handleSave" text="Kaydet" />
      <wo-button data-id="geri" @click="handleClose" text="Kapat" />
    </div>
  </form>
</template>

<script>
import { useMMAddressesState } from './useMMAddressesState';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';

import { saveAddressForm } from './mmAddressApi';
import { errorDialog } from '@/helpers/errorDialogs';

export default {
  name: 'MiniModuleAddressesForm',
  props: {
    source: String
  },
  setup(props, { emit, root }) {
    const {
      labelSize,
      editedAddress,
      addressList,
      addressFormState: state
    } = useMMAddressesState();

    state.source = props.source;
    state.sourceID = root.$route.params.id;

    const handleSelectedDepot = item => {
      if (item.isSelected) {
        state.depotName = item.isim;
        state.depotID = item.id;
      } else if (!item.isSelected && state.depotID) {
        state.depotName = '';
        state.depotID = 0;
      }
    };

    const checkEditedAndGetData = async () => {
      if (editedAddress.value > 0) {
        const data = addressList.value.find(a => a.id === editedAddress.value);
        Object.assign(state, data);
      }
    };
    checkEditedAndGetData();

    const handleClose = () => {
      editedAddress.value = 0;
      emit('closeForm');
    };

    const handleSave = () => {
      saveAddressForm(state)
        .then(() => {
          editedAddress.value = 0;
          emit('refreshList');
          emit('closeForm');
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            const error = Object.values(response.data.errors)[0][0];
            errorDialog(error);
          } else {
            errorDialog(response.data.message);
          }
        });
    };

    return {
      labelSize,
      editedAddress,
      state,
      nameAndCodeRenderer,
      handleSelectedDepot,
      handleClose,
      handleSave
    };
  }
};
</script>

<style lang="scss" scoped></style>
