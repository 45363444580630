import { errorDialog } from '@/helpers/errorDialogs';
import usePrevalentState from '../consumables/usePrevalentState';
import { getTaxpayerByNumber } from './ApiFactory';

export default async function(number) {
  try {
    const response = await getTaxpayerByNumber(number);
    const { state } = usePrevalentState();
    state.title = response.unvan;
    state.companyTitle = response.unvan;
  } catch (error) {
    errorDialog(error.response.data.message);
  }
}
