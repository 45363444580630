<template>
  <!-- :auth="moduleConfig.auth" -->
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="teklif"
    :auth="moduleConfig.auth"
  >
    <CariForm />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import CariForm from './CariForm.vue';

export default {
  components: {
    WOModuleWrapper,
    CariForm
  },
  computed: {
    moduleConfig() {
      if (this.$route.params.id) {
        return {
          title: 'Cari Düzenle',
          auth: 'cari_duzenle'
        };
      } else {
        return { title: 'Yeni Cari Ekle', auth: 'cari_ekle' };
      }
    }
  }
};
</script>

<style scoped></style>
