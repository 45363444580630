export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    dangerText: {
      default: '',
      type: String
    },
    danger: {
      default: false,
      type: Boolean
    },
    inputClass: String
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit('input', e);
      }
    }
  }
};
