import moment from 'moment';
import Vue from 'vue';

import { reactive } from '@vue/composition-api';
import { errorDialog } from '@/helpers/errorDialogs';

const labelSize = 'md';

const state = reactive({
  accountingBranch: '',
  prevalentType: '1',
  prevalentTypeTc: '',
  prevalentTypeVkno: '',
  prevalentState: '1',
  prevalentStateInactiveDate: moment().format('DD.MM.YYYY'),
  specialPrevalent: '0',
  titleSyncer: '',
  title: '',
  authorizedPerson: '',
  prevalentCode: '',
  accountingCodeID: 0,
  accountingCode: '',
  openingDate: moment().format('DD.MM.YYYY'),
  groupCode: [],
  taxRegisterNo: '',
  manufacturerCode: '',
  exportCode: '',
  depot: '',
  responsiblePersonelID: '',
  responsiblePersonelText: '',
  insideGroup: '0',
  activelyWorkingStores: [],
  workingType: '0',
  extractType: '2',
  waybillType: window.cari_kartlarda_stok_ayarlari_gozuksun === '1' ? '1' : '4',
  operatingLedger: '0',
  checkPurchasingConditionOnSale: '',
  automaticallySendBalanceMail: '',
  openAccountCanWork: '',
  apacheIntegratedIP: '',
  orderPrinter: '',
  cashRegisterMovementWay: '',
  orderDatabaseConnection: '',
  dataDatabaseConnection: '',
  pdksAutomatic: '0',
  bufferRegisterID: '',
  bufferRegisterText: '',
  cashRegisterID: '',
  cashRegisterText: '',
  cashierAdvanceRegisterID: '',
  cashierAdvanceRegisterText: '',
  showParameters: '0',
  farmControl: '0',
  concept: '',
  responsibilityCenter: '',
  showInBudget: '1',
  prevalentKind: '',
  codeStart: '',
  selectedCategories: {},
  explanation: '',
  screenNote: '',
  orderNote: '',
  collectionOfficerName: '',
  collectionOfficerEmail: '',
  collectionOfficerCell: '',
  contactFirstPhone: '',
  contactFax: '',
  contactSecondPhone: '',
  contactWeb: '',
  contactCell: '',
  contactEmail: '',
  contactAuthorizedPhone: '',
  contactAuthorizedEmail: '',
  contactAddress: '',
  contactAccountantEmail: '',
  contactCustomerEmail: '',
  companyProductTrackingSystemNo: '',
  depotDeliveryCode: '',
  storeDeliveryCode: '',
  storeCode: '',
  companyCode: '',
  cards: '',
  password: '',
  accountIncomeCardID: '',
  accountIncomeCardText: '',
  accountExpenseCardID: '',
  accountExpenseCardText: '',
  accountPrevalentID: '',
  accountPrevalentText: '',
  personalCompensationExpenseCardID: '',
  // personalCompensationExpenseCardText: '',
  bank: '',
  bankBranch: '',
  bankBranchCode: '',
  bankAccountNo: '',
  bankAccountAdditionalNo: '',
  iban: '',
  riskLimit: '',
  riskLimitCurrency: '',
  participationPrice: '',
  payPlan: '',
  commissionRate: '',
  discountRate: '',
  payTypes: [],
  advertisementShareRate: '',
  collectionInterval: '0',
  accountClosingType: '',
  vat: '',
  selectedPayPlans: {},
  companyTitle: '',
  taxOffice: '',
  taxNumber: '',
  soleProprietorshipsOwnerName: '',
  soleProprietorshipsOwnerSurname: '',
  identityNumber: '',
  invoiceAddress: '',
  country: 0,
  province: 0,
  district: 0,
  connectedParentPrevalentID: '',
  connectedParentPrevalentText: '',
  creator: '',
  creationDate: '',
  editor: '',
  editDate: '',
  personnels: {},
  professions: {},
  halfAccountPrevalent: 0,
  managementRegistry: '0',
  eInvoiceMail: '',
  eWaybillMail: ''
});

const handleDeletableListQuickSearch = (s, key, item) => {
  if (item.isSelected) {
    if (s[key][item.id]) {
      // normalde setTimeout vuede kullanilmasina gerek yok,
      // fakat burada islemi event cycle'a yollamak isimizi cozmedigi icin
      // Vue.nextTick yerine 20 milisaniyelik setTimeout eklendi
      setTimeout(() => {
        errorDialog(`${item.isim} kaydı zaten mevcut.`);
      }, 20);
    } else {
      Vue.set(s[key], `${item.id}`, item.isim);
    }
  }
};

const agreementState = reactive({
  formID: 0,
  name: '',
  participants: '',
  agreementDate: moment().format('DD.MM.YYYY'),
  startDate: moment()
    .startOf('year')
    .format('DD.MM.YYYY'),
  endDate: moment()
    .endOf('year')
    .format('DD.MM.YYYY'),
  authorizedName: '',
  producerTitle: '',
  authorizedPhone: '',
  producerAddress: '',
  authorizedEmail: '',
  approvalStatus: '',
  productGroups: {},
  producers: {},
  brand: {},

  normalDiscount1: '',
  normalDiscount2: '',
  normalDiscount3: '',
  normalDiscountExplanation: '',
  normalDiscountExpiry: '',

  specialDiscount1: '',
  specialDiscount2: '',
  specialDiscount3: '',
  specialDiscountExplanation: '',
  specialDiscountExpiry: '',

  advanceDiscount1: '',
  advanceDiscount2: '',
  advanceDiscount3: '',
  advanceDiscountExplanation: '',
  advanceDiscountExpiry: '',

  newBranchOpeningDiscount: '',
  newBranchOpeningExplanation: '',
  newBranchOpeningTime: '',
  newBranchOpeningTimeExplanation: '',

  specialExpiryTime: '',
  specialExpiryTimeExplanation: '',
  specialAdvanceDiscount: '',
  specialAdvanceDiscountExplanation: '',

  newBranchAdvanceDiscountExpiry: '',
  newBranchAdvanceDiscountExpiryExplanation: '',
  newBranchOpeningParticipationCost: '',
  newBranchOpeningParticipationCostExplanation: '',

  anniversaryEventCost: '',

  turnoverPremiumArray: [],

  activityPremiumMonthly: '',
  activityPremiumThreeMonthly: '',
  activityPremiumAnnually: '',

  logisticPremiumMonthly: '',
  logisticPremiumThreeMonthly: '',
  logisticPremiumAnnually: '',

  lostPremiumMonthly: '',
  lostPremiumThreeMonthly: '',
  lostPremiumAnnually: '',

  premiumRefund: '',
  premiumPriceDifference: '',
  premiumDifferentVatRates: '',

  insertCostPiece: '',
  insertCostPrice: '',

  additionalSettlementPiece: '',
  additionalSettlementPrice: '',

  insertDiscount: '',
  promotionSurplus: '',

  annualAgreementCostInstallment: '',
  annualAgreementCost: '',
  annualAgreementCostExplanation: '',
  openingAnniversaryEventPromotion: '',
  radioTvInternetAdPromotion: '',
  promotionsExplanation: '',

  deliveryTime: '2',
  rebateSituation: '1',
  priceList: 'GÜNCEL',
  priceTransition: '15',
  annualGoalPlan: '',
  deliveryNotes: '',
  agreementDetailsSelect: '',
  agreementDetailsList: ''
});

export default function() {
  return {
    labelSize,
    state,
    handleDeletableListQuickSearch,
    agreementState
  };
}
