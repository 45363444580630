import Vue from '@/main';

export function registerRenderer(item) {
  return `
    <a
      class="underlined-hovered-link"
      href="${Vue.$baseURL}/kasa_hesap_tanimla&duzenle=${item.id}"
      target="_blank"
    >${item.kod}</a>
    <span>
      ${item.isim}
    </span>
    <span class="text-danger">
      ${item.sube_isim ? item.sube_isim : ''}
    </span>
  `;
}
