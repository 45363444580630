<template>
  <div class="image-gallery">
    <div class="image-gallery__primary-image">
      <DeletableImage
        id="primary-image"
        :clickHandler="() => deleteImage(primaryImage)"
        @click="() => zoomImage(primaryImage)"
        :imageUrl="
          typeof primaryImage === 'string'
            ? primaryImage
            : `${$baseURL}/${primaryImage.image_path}${primaryImage.image_name}`
        "
      />
    </div>
    <div class="image-gallery__side-gallery">
      <DeletableImage
        v-for="(url, index) in sideGalleryImageUrls"
        :id="`side-image-${index}`"
        :key="`side-gallery-${index}`"
        :clickHandler="() => deleteImage(url)"
        :imageUrl="
          typeof url === 'string' ? url : `${$baseURL}/${url.image_path}${url.image_name}`
        "
        @click="
          () => {
            changePrimaryImage(url);
            zoomImage(url);
          }
        "
      />
    </div>
    <LightBox
      class="lightBox"
      :active="isOpenLightBox"
      :image="currentClickedImage"
      @active="isOpenLightBox = $event"
    ></LightBox>
  </div>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";
import DeletableImage from "./DeletableImage.vue";
import LightBox from "./LightBox.vue";
export default {
  components: {
    DeletableImage,
    LightBox,
  },
  props: {
    imageUrls: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  
  setup(props, { emit }) {

    const placeholderImageUrl = "https://via.placeholder.com/160x250";
    const primaryImage = ref(props.imageUrls[0] || placeholderImageUrl);

    watch(
      () => props.imageUrls,
      () => {
        if (props.imageUrls[0]) {
          primaryImage.value = props.imageUrls[0];
        } else {
          primaryImage.value = placeholderImageUrl;
        }
      }
    );
    const sideGalleryImageUrls = computed(() => {
      return props.imageUrls.filter((a) => {
        const arrayImage = typeof a === "string" ? a : a.id;
        const primaryImageUrl =
          typeof primaryImage.value === "string"
            ? primaryImage.value
            : primaryImage.value.id;
        return arrayImage !== primaryImageUrl;
      });
    });

    const currentClickedImage = ref("");
    const isOpenLightBox = ref(false);

    const changePrimaryImage = (url) => {
      primaryImage.value = url;
    };

    const zoomImage = (url) => {
      isOpenLightBox.value = true;
      if (typeof url === "string") {
        currentClickedImage.value = url;
      } else {
        currentClickedImage.value = `${window.location.origin}/${url.image_path}${url.image_name}`;
      }
    };

    const deleteImage = (url) => {
      emit("deleteImage", url);
    };

    return {
      primaryImage,
      sideGalleryImageUrls,
      changePrimaryImage,
      deleteImage,
      currentClickedImage,
      isOpenLightBox,
      zoomImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-gallery {
  width: 250px;
  height: 160px;
  display: flex;
  justify-content: center;

  &__primary-image {
    margin-right: 20px;

    ::v-deep img {
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
      width: 150px;
      height: 166px;
      object-fit: cover;
    }
  }

  &__side-gallery {
    height: 100%;
    overflow: auto;

    ::v-deep img {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }
}
.fancyImg {
  position: relative;
  display: block;
  max-width: 100%;
  border-radius: 50%;
  height: auto;
  z-index: 1;
  transition: 0.3s;
}

</style>
