<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import {
  ref,
  watch,
  // computed,
  onMounted,
  // onUnmounted
} from '@vue/composition-api';

import { setBaseURL } from '@/helpers/ApiController.js';
// import { fetchToken } from '@/helpers/ApiController.js';

export default {

  mounted() {
    if (document.getElementById('sistem_mali_yil')) {
      document.querySelector('#sistem_mali_yil').addEventListener('change', () => {
        this.$store.commit('UPDATE_FISCAL_YEAR', document.getElementById('sistem_mali_yil').value);
      });
    }
  },

  setup(props, { root }) {
    // * THEME ================================================
    // const toggleClassInBody = className => {
    //   if (className === 'dark') {
    //     if (document.body.className.match('theme-semi-dark'))
    //       document.body.classList.remove('theme-semi-dark');
    //     document.body.classList.add('theme-dark');
    //   } else if (className === 'semi-dark') {
    //     if (document.body.className.match('theme-dark'))
    //       document.body.classList.remove('theme-dark');
    //     document.body.classList.add('theme-semi-dark');
    //   } else {
    //     if (document.body.className.match('theme-dark'))
    //       document.body.classList.remove('theme-dark');
    //     if (document.body.className.match('theme-semi-dark'))
    //       document.body.classList.remove('theme-semi-dark');
    //   }
    // };

    setBaseURL(window.baseUrl);
    // setToken(fetchToken() || '');

    // const currentTheme = computed(() => {
    //   return root.$store.state.theme;
    // });

    // watch(
    //   () => root.$store.state.theme,
    //   val => {
    //     toggleClassInBody(val);
    //   }
    // );

    // * STYLING ============================================
    const vueAppClasses = ref([]);

    const setAppClasses = classesStr => {
      vueAppClasses.value.push(classesStr);
    };

    watch(
      () => root.$vs.rtl,
      val => {
        document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr');
      }
    );

    // // * WINDOW =============================================
    // const handleWindowResize = () => {
    //   root.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

    //   // Set --vh property
    //   document.documentElement.style.setProperty(
    //     '--vh',
    //     `${window.innerHeight * 0.01}px`
    //   );
    // };
    // const handleScroll = () => {
    //   root.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    // };

    onMounted(() => {
      console.log("###^#");
      // toggleClassInBody(currentTheme.value);
      // root.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

      const appLoading = document.getElementById('loading-bg');
      if (appLoading) {
        appLoading.style.display = 'none';
      }

      // const vh = window.innerHeight * 0.01;
      // // Then we set the value in the --vh custom property to the root of the document
      // document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    const dir = root.$vs.rtl ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);

    // window.addEventListener('resize', handleWindowResize);
    // window.addEventListener('scroll', handleScroll);

    // // root.$store.commit('UPDATE_CURRENT_PATH', currentPath);

    // onUnmounted(() => {
    //   window.removeEventListener('resize', handleWindowResize);
    //   window.removeEventListener('scroll', handleScroll);
    // });

    return {
      vueAppClasses,
      setAppClasses
    };
  }
  // onIdle() {
  //   removeToken();
  //   location.reload();
  // }
};
</script>

<style>
/* html {
  font-size: 14px;
  min-width: 100vw;
  min-height: 100vh;
} */
</style>
