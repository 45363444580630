export function getValueById(arr, id, key) {
    return arr.find(item => item.id === id) ? arr.find(item => item.id === id)[key] : "";
}

export function filterObject(obj, callback) {
    const asArray = Object.entries(obj);
    const filtered = asArray.filter(([key, value]) => {
        return callback(key, value);
    });
    return Object.fromEntries(filtered);
}