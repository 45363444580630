import { factory } from '@/helpers/ApiController.js';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';

interface IHydrater {
  server: keyof IMMAddressServer;
  client: keyof IMMAddress;
  serverGet?: string;
}

const hydrateMap: IHydrater[] = [
  { server: 'id', client: 'id' },
  { server: 'kaynak', client: 'source' },
  { server: 'kaynak_id', client: 'sourceID' },
  { server: 'adres_isim', client: 'name' },
  { server: 'adres_ulke', client: 'countryID', serverGet: 'ulke'},
  { server: 'ulke_isim', client: 'countryName', serverGet: 'ulke_isim' },
  { server: 'adres_il', client: 'provinceID', serverGet: 'il' },
  { server: 'il_isim', client: 'provinceName', serverGet: 'il_isim' },
  { server: 'adres_ilce', client: 'districtID', serverGet: 'ilce' },
  { server: 'ilce_isim', client: 'districtName', serverGet: 'ilce_isim' },
  { server: 'mahalle', client: 'neighbourhood' },
  { server: 'sokak', client: 'street' },
  { server: 'cadde', client: 'avenue' },
  { server: 'kapi_no', client: 'doorNo' },
  { server: 'telefon', client: 'telephone' },
  { server: 'aciklama', client: 'explanation' },
  { server: 'depo', client: 'depotID' },
  { server: 'depo_isim', client: 'depotName' },
  { server: 'zip_code', client: 'zipCode' }
];

function hydrateFromServer(data: IMMAddressServer) {
  return hydrateMap.reduce((acc, item) => {
    if (item.serverGet) {
      (acc[item.client] as string | number | undefined) = data[item.serverGet as keyof IMMAddressServer];
      return acc;
    }
    (acc[item.client] as string | number | undefined) = data[item.server];
    return acc;
  }, {} as Partial<IMMAddress>) as IMMAddress;
}

function hydrateFromClient(payload: IMMAddress) {
  return hydrateMap.reduce((acc, item) => {
    (acc[item.server] as string | number) = payload[item.client];
    return acc;
  }, {} as Partial<IMMAddressServer>) as IMMAddressServer;
}

export async function fetchAddressList(source: number, sourceID: number) {
  try {
    const url = `/address`;
    const params = {
      source,
      source_id: sourceID
    };
    const { data } = await factory.get(url, {
      params
    });
    return data.map((a: IMMAddressServer) => hydrateFromServer(a));
  } catch {
    errorDialog('Adresleri getirirken bir problem oluştu');
    return [];
  }
}

export async function deleteAddressAPI(id: IMMAddress['id']) {
  try {
    const url = `/address/${id}`;
    const { data } = await factory.delete(url);
    primaryDialog(data.message);
  } catch {
    errorDialog('Adresi silerken bir problem oluştu');
  }
}

async function updateAddressAPI(payload: IMMAddressServer) {
  try {
    const url = `/address/${payload.id}`;
    const { data } = await factory.post(url, payload);
    primaryDialog(data.message);
  } catch {
    errorDialog('Adresi güncellerken bir problem oluştu');
  }
}

async function saveAddressAPI(payload: IMMAddressServer) {
  const url = `/address`;
  return factory.post(url, payload);
}

export function saveAddressForm(payload: IMMAddress) {
  const reqBody = hydrateFromClient(payload);
  delete reqBody['ulke_isim'];
  delete reqBody['il_isim'];
  delete reqBody['ilce_isim'];
  delete reqBody['depo_isim'];
  if (reqBody.id) {
    return updateAddressAPI(reqBody);
  } else {
    return saveAddressAPI(reqBody);
  }
}
