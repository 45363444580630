<template>
  <div class="flex flex-col xl:flex-row responsive-margin-xl">
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        <span :class="{ isRed: 'text-danger' }">
          {{ labelPrefix }} İskonto
        </span>
      </template>
      <template #firstElement>
        <div class="col-span-2 flex space-x-2">
          <div class="y-centered-flex">
            <wo-float-input
              v-model="state[`${objKey}Discount1`]"
              class="discount-area-input-width"
              :decimal="2"
            />
            <span class="discount-area-percentage-margin">%</span>
          </div>
          <div class="y-centered-flex">
            <wo-float-input
              v-model="state[`${objKey}Discount2`]"
              class="discount-area-input-width"
              :decimal="2"
            />
            <span class="discount-area-percentage-margin">%</span>
          </div>
          <div class="y-centered-flex">
            <wo-float-input
              v-model="state[`${objKey}Discount3`]"
              class="discount-area-input-width"
              :decimal="2"
            />
            <span class="discount-area-percentage-margin">%</span>
          </div>
        </div>
      </template>
      <template #secondLabel> Açıklama </template>
      <template #secondElement>
        <base-input v-model="state[`${objKey}DiscountExplanation`]" />
      </template>
    </wo-double-form>
    <wo-double-form labelSize="md">
      <template #firstLabel>Vade</template>
      <template #firstElement>
        <div class="flex space-x-2">
          <base-input v-model="state[`${objKey}DiscountExpiry`]" />
          <span>
            gün
          </span>
        </div>
      </template>
    </wo-double-form>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState';
export default {
  props: {
    isRed: Boolean,
    labelPrefix: String,
    objKey: String
  },
  setup() {
    const { labelSize, agreementState: state } = usePrevalentState();

    return {
      labelSize,
      state
    };
  }
};
</script>

<style lang="scss" scoped>
.y-centered-flex {
  @apply flex items-center;
}

.discount-area-input-width {
  @apply w-14;
}

.discount-area-percentage-margin {
  @apply ml-1;
}
</style>
