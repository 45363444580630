var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wo-tr',[(_vm.entryTypeEditable)?_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.entryTypes,"elementClasses":"w-32"},on:{"change":_vm.resetEntryData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('wo-select-item',{key:("entry-types-new-" + index),attrs:{"itemValue":index,"text":item}})]}}],null,false,4146589546),model:{value:(_vm.localState.entryType),callback:function ($$v) {_vm.$set(_vm.localState, "entryType", $$v)},expression:"localState.entryType"}})],1):_vm._e(),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.primaryMaterials,"elementClasses":"w-28 m-auto"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("primary-material-" + (item.id)),attrs:{"itemValue":item.id,"text":item.isim}})]}}]),model:{value:(_vm.localState.primaryMaterial),callback:function ($$v) {_vm.$set(_vm.localState, "primaryMaterial", $$v)},expression:"localState.primaryMaterial"}})],1),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.processes,"elementClasses":"w-28 m-auto"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('wo-select-item',{key:("process-" + (item.id)),attrs:{"itemValue":item.id,"text":item.isim}})]}}]),model:{value:(_vm.localState.process),callback:function ($$v) {_vm.$set(_vm.localState, "process", $$v)},expression:"localState.process"}})],1),_c('wo-td',[_c('wo-quick-search',{attrs:{"inputClasses":"m-auto","queryParameters":_vm.formEntryConfig.queryParameters,"placeholder":_vm.formEntryConfig.placeholder,"itemRenderer":function (item) { return _vm.nameAndCodeWithLinkRenderer(
            item,
            _vm.formEntryConfig.link,
            _vm.formEntryConfig.prefix
          ); }},on:{"selected":function (item) { return _vm.formEntryConfig.handler(item); }},model:{value:(_vm.localState.entryName),callback:function ($$v) {_vm.$set(_vm.localState, "entryName", $$v)},expression:"localState.entryName"}})],1),_c('wo-td',[_c('wo-quick-search',{attrs:{"inputClasses":"m-auto","queryParameters":"?tablo=cari&q=","itemRenderer":_vm.prevalentRenderer},on:{"selected":function (item) { return _vm.handlePrevalentQuickSearch(item); }},model:{value:(_vm.localState.prevalentName),callback:function ($$v) {_vm.$set(_vm.localState, "prevalentName", $$v)},expression:"localState.prevalentName"}})],1),_c('wo-td',[_c('wo-float-input',{staticClass:"m-auto",attrs:{"decimal":"3"},model:{value:(_vm.localState.amount),callback:function ($$v) {_vm.$set(_vm.localState, "amount", $$v)},expression:"localState.amount"}})],1),(_vm.localState.entryType === '2')?_c('wo-td',[_c('wo-float-input',{attrs:{"decimal":"3"},model:{value:(_vm.localState.orderAmount),callback:function ($$v) {_vm.$set(_vm.localState, "orderAmount", $$v)},expression:"localState.orderAmount"}})],1):_vm._e(),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.stockUnits,"elementClasses":"w-28 m-auto","valueType":"number"},scopedSlots:_vm._u([{key:"defaultValue",fn:function(){return [_c('wo-select-item',{attrs:{"itemValue":0,"text":"- Seçiniz -"}})]},proxy:true},{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('wo-select-item',{key:("unit-" + (item.id)),attrs:{"itemValue":item.id,"text":item.isim}})]}}]),model:{value:(_vm.localState.unit),callback:function ($$v) {_vm.$set(_vm.localState, "unit", $$v)},expression:"localState.unit"}})],1),_c('wo-td',[_c('wo-float-input',{staticClass:"m-auto",attrs:{"decimal":"3"},model:{value:(_vm.localState.cost),callback:function ($$v) {_vm.$set(_vm.localState, "cost", $$v)},expression:"localState.cost"}})],1),_c('wo-td',[_c('wo-select',{attrs:{"data":_vm.localExchangeRates,"elementClasses":"w-26"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('wo-select-item',{key:("currency-table-new-" + (item.kod)),attrs:{"itemValue":item.kod,"text":item.kod}})]}}]),model:{value:(_vm.localState.currency),callback:function ($$v) {_vm.$set(_vm.localState, "currency", $$v)},expression:"localState.currency"}})],1),_c('wo-td',[_c('wo-float-input',{staticClass:"m-auto",attrs:{"decimal":4,"readonly":""},model:{value:(_vm.localState.mainCurrencyPrice),callback:function ($$v) {_vm.$set(_vm.localState, "mainCurrencyPrice", $$v)},expression:"localState.mainCurrencyPrice"}})],1),_c('wo-td',[_c('base-input',{staticClass:"m-auto",model:{value:(_vm.localState.explanation),callback:function ($$v) {_vm.$set(_vm.localState, "explanation", $$v)},expression:"localState.explanation"}})],1),_c('wo-td',[_vm._t("processes")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }