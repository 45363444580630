<template>
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="kontrol_listesi"
    :auth="moduleConfig.auth"
  >
    <KontrolListesiForm />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import KontrolListesiForm from './KontrolListesiForm.vue';

import { computed } from '@vue/composition-api';

export default {
  components: {
    WOModuleWrapper,
    KontrolListesiForm
  },
  setup(props, { root }) {
    const moduleConfig = computed(() => {
      if (root.$route.params.id) {
        return {
          title: 'Kontrol Listesi Düzenle',
          auth: 'kontrol_listesi_duzenle'
        };
      } else {
        return { title: 'Kontrol Listesi', auth: 'kontrol_listesi_ekle' };
      }
    });
    return {
      moduleConfig
    };
  }
};
</script>

<style></style>
