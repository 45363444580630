<template>
    <div id="model-design-table">
        <h1 style="font-weight:bold; margin-top: 20px;">MODELHANE FORMU</h1>
        <table style="margin-top: 20px;">
            <tr>
                <th style="border: 1px solid black; text-align: left;">{{ formsData.designNumber.name }}</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.design_number }}</td>
                <td style="border-style:none;"><img style="max-width: 100%; width: auto; max-height: 250px;" :src="imageUrl" height="200"></td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">{{ formsData.modelCode.name }}</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.model_code }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">{{ formsData.modelName.name }}</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.model_name }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">{{ formsData.designer.name }}</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.designer_name }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">MAKİNE GG</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.machine_id }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">HIZ</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.speed }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">ÜRETİLEN MAKİNE VE DK</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.machine_produced }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">SSG MAKİNE'YE GÖRE DK</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.fast_by_ssg_machine }}</td>
            </tr>
            <tr>
                <th style="border: 1px solid black; text-align: left;">{{ formsData.exitTime.name }}</th>
                <td style="border: 1px solid black; text-align: right;">{{ localState.exit_time }}</td>
            </tr>
        </table>
        <table style="margin-top: 20px;">
            <tr></tr>
            <tr style="background-color: #DCE6F1"> <th style="border: 1px solid black; text-align: center; font-size: 20px;" colspan="4">MAKİNE BİLGİLERİ</th></tr>
            <tr style="background-color: #DCE6F1"> <th style="border: 1px solid black; text-align: center;" v-for="title in machineInfoTitles" :key="title">{{ title }}</th> </tr>
            <tr style="background-color: #DCE6F1" v-for="shuttle in Object.keys(machineInformation)" :key="shuttle">
                <td style="border: 1px solid black; text-align: right;">{{ shuttle }}</td>
                <td style="border: 1px solid black; text-align: right;" v-for="title in machineInfoTitles.filter(title => title !== 'MEKİKLER')" :key="title">{{ machineInformation[shuttle][title] }}</td>
            </tr>
        </table>
        <table style="margin-top: 20px;">
            <tr></tr>
            <tr style="background-color: #EBF1DE"><th style="border: 1px solid black; text-align: center; font-size: 20px;" colspan="4">GRAMAJ HESAPLAMA</th></tr>
            <tr style="background-color: #EBF1DE"><th style="border: 1px solid black; text-align: center;" v-for="title in weightTitles" :key="title">{{ title }}</th></tr>
            <tr style="background-color: #EBF1DE" v-for="(data, index) in weightCalculation" :key="`weightCalculation-${index}`">
                <td style="border: 1px solid black; text-align: right;" v-for="title in weightTitles" :key="title">{{ data[title] }}</td>
            </tr>
        </table>
        <div style="display:flex; margin-top: 20px;">
            <table v-for="(table, index) in manufactureData" :key="`manufactureDataTable-${index}`" style="margin-right: 20px;">
                <tr></tr>
                <tr style="background-color: #EBF1DE"><th style="border: 1px solid black; text-align: center; font-size: 20px;" colspan="3">{{ index + 1 }}. DENEME</th></tr>
                <tr style="background-color: #EBF1DE"><th style="border: 1px solid black; text-align: center;" v-for="title in manufactureTableTitles" :key="title">{{ title }}</th></tr>
                <tr style="background-color: #EBF1DE" v-for="(data, index) in table" :key="`manufactureData-${index}`">
                    <td style="border: 1px solid black; text-align: right;" v-for="manufactureTitle in manufactureTableTitles" :key="manufactureTitle">{{ data[manufactureTitle] }}</td>
                </tr>
            </table>
        </div>
        <table style="margin-top: 20px;">
            <tr></tr>
            <tr style="background-color: #EBF1DE"><th style="border: 1px solid black; text-align: center; font-size: 20px;" colspan="2">TOPLAMLAR</th></tr>
            <tr style="background-color: #EBF1DE" v-for="key in Object.keys(calculations)" :key="key">
                <th style="border: 1px solid black; text-align: left;">{{ key }}</th>
                <td style="border: 1px solid black; text-align: right;">{{ calculations[key] }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
import { computed, onMounted, onUpdated } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { formsData } from '../..//composables/formsData';

export default {
    setup(_, { emit }) {
        const { state } = useModelPlanlamaState();

        const localState = computed(() => state['design']);

        const imageUrl = computed(() => {
            const imageData = localState.value.image_urls;
            const result = imageData.length > 0 ? `http://takipet.webofis.im/test/${imageData[0].image_path}${imageData[0].image_name}` : "";
            return result;
        });

        const machineInformation = computed(() => localState.value.machine_information);

        const weightCalculation = computed(() => localState.value.weights);

        const machineInfoTitles = ["MEKİKLER", "İPLİK CİNSİ", "MEKİK RENKLERİ", "İPLİK KATI"];

        const weightTitles = ["RENKLER", "KALAN", "İLK GRAMAJ", "SON GRAMAJ"];

        const manufactureData = computed(() => localState.value.manufacture_details);

        const manufactureTableTitles = ["ÜRETİLEN PARÇA", "HAM ÖLÇÜ", "DAKİKA"];

        const calculations = computed(() => localState.value.calculations);

        onMounted(() => {
            emit("modelDesignTableEvent", document.getElementById('model-design-table').innerHTML);
        });

        onUpdated(() => {
            emit("modelDesignTableEvent", document.getElementById('model-design-table').innerHTML);
        });

        return {
            localState,
            formsData,
            machineInfoTitles,
            machineInformation,
            weightCalculation,
            weightTitles,
            manufactureData,
            manufactureTableTitles,
            calculations,
            imageUrl
        };
    }
};
</script>
