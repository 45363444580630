<template>
  <div>
    <wo-tab-maker :tabData="tabData" />

    <CariFormSectionDivider />

    <p class="text-danger">
      Otomatik rehber oluşturulacağından, rehberde telefon, eposta ve adres
      gözükmesi için cari kartta yetkili telefon, eposta ve adres alanı dolu
      olmalıdır.
    </p>

    <div class="mt-4 flex flex-col sm:flex-row responsive-margin-sm">
      <wo-button
        text="Kaydet (Alt+S)"
        data-id="kaydet"
        @click="savePrevalent"
      />
      <wo-button text="Listeye Dön" data-id="geri" @click="returnToList" />
      <wo-button
        v-if="buttonsIsVisible"
        text="Kopyala"
        :href="`${$baseURL}/cari/${$route.params.id}/kopyala`"
      />
      <wo-button
        v-if="buttonsIsVisible"
        text="Yeni Cari"
        :href="`${$baseURL}/cari/yeni`"
      />
      <wo-button
        v-if="buttonsIsVisible"
        text="Rehberini Görüntüle"
        :href="`${$baseURL}/?s=rehber&tur=2&cari=${$route.params.id}`"
        target="_blank"
      />
      <wo-button
        v-if="buttonsIsVisible"
        text="Cari Hareketler"
        target="_blank"
        :href="`${$baseURL}${prevalentMovementsLink}`"
      />
      <wo-button
        v-if="buttonsIsVisible && authorizationControl('cari_dosya')"
        text="Cari Dosyaları"
        @click="handlePrevalentFilesMiniModule"
      />
      <wo-button
        v-if="buttonsIsVisible && authorizationControl('cari_onay')"
        text="Cari Onay"
        @click="handleConfirmPrevalent"
      />
    </div>

    <CariFormSectionDivider v-if="creatorInformation" />

    <CariFormSectionHeader v-if="creatorInformation">
      Ekleyen Bilgileri
    </CariFormSectionHeader>

    <div
      class="mt-4 flex responsive-margin-md flex-col md:flex-row"
      v-if="creatorInformation"
    >
      <div v-if="state.creator">
        <b> Ekleyen: </b>
        {{ state.creator }}
      </div>
      <div v-if="state.creationDate">
        <b> Eklenme Tarihi: </b>
        {{ state.creationDate }}
      </div>
      <div v-if="state.editor">
        <b> Düzenleyen: </b>
        {{ state.editor }}
      </div>
      <div v-if="state.editDate">
        <b> Düzenleme Tarihi: </b>
        {{ state.editDate }}
      </div>
    </div>
  </div>
</template>

<script>
import CariFormTabGenel from './components/TabGenel/CariFormTabGenel.vue';
import CariFormTabNotes from './components/TabNotlar/CariFormTabNotes.vue';
import CariFormTabContact from './components/TabIletisim/CariFormTabContact.vue';
import CariFormTabAdditionalFields from './components/TabEkAlanlar/CariFormTabAdditionalFields.vue';
import CariFormTabAgreements from './components/TabSozlesmeler/CariFormTabAgreements.vue';
import CariFormTabPaymentInfo from './components/TabOdemeBilgileri/CariFormTabPaymentInfo.vue';
import CariFormTabRelatedCards from './components/TabIliskiliKartlar/CariFormTabRelatedCards.vue';

import CariFormSectionDivider from './components/common/CariFormSectionDivider';
import CariFormSectionHeader from './components/common/CariFormSectionHeader';

import {
  confirmPrevalent,
  fetchPrevalentFormData,
  savePrevalentForm,
  updatePrevalentForm
} from './helpers/ApiFactory';
import {
  hydrateFromClient,
  hydrateFromServer
} from './helpers/hydratePrevalent';
import usePrevalentState from './consumables/usePrevalentState';
import useCompany from '@/consumables/useCompany';
import { primaryConfirmDialog } from '@/helpers/primaryDialogs';
import { errorConfirmDialog, errorDialog } from '@/helpers/errorDialogs';

import moment from 'moment';
import { computed, onMounted } from '@vue/composition-api';

import { authorizationControl } from '@/consumables/useAuthorizationControl.js';
import ServerError from '@/types/ServerError';

export default {
  components: {
    CariFormSectionDivider,
    CariFormSectionHeader
  },
  setup(props, { root }) {
    onMounted(() => (document.title = 'Cari'));
    const tabData = [
      { label: 'Genel', component: CariFormTabGenel },
      { label: 'İletişim', component: CariFormTabContact },
      { label: 'Ödeme Bilgileri', component: CariFormTabPaymentInfo },
      { label: 'İlişkili Kartlar', component: CariFormTabRelatedCards },
      { label: 'Notlar', component: CariFormTabNotes },
      { label: 'Sözleşme', component: CariFormTabAgreements },
      { label: 'Ek Alanlar', component: CariFormTabAdditionalFields }
    ];

    const { state } = usePrevalentState();

    const currentCompany = useCompany();

    const returnToList = () => {
      window.location = `${root.$baseURL}/?s=cari_v2`;
    };

    const getEditFormData = async () => {
      fetchPrevalentFormData(root.$route.params.id)
        .then(data => {
          hydrateFromServer(state, data);
        })
        .catch(error => {
          if (error instanceof ServerError) {
            returnToList();
          }
        });
    };

    if (root.$route.params.id) {
      getEditFormData();
    }

    const buttonsIsVisible = computed(
      () => root.$route.params.id && !root.$route.fullPath.includes('kopyala')
    );

    const submitPrevalent = () => {
      const result = hydrateFromClient(state, currentCompany.value.id);
      // result.kaydet =
      //   root.$route.path.split('/').reverse()[0] === 'duzenle'
      //     ? root.$route.params.id
      //     : 'yeni';
      if (!root.$route.params.id || root.$route.fullPath.includes('kopyala')) {
        savePrevalentForm(result).then(result => {
          if (result) {
            primaryConfirmDialog(
              'Kayıt tamamladı!',
              returnToList,
              'Kayıt Sonu',
              'Listeye Dön',
              () =>
                (window.location = `${root.$baseURL}/cari/${result.id}/duzenle`),
              'Aynı Sayfada Kal'
            );
          }
        });
      } else {
        updatePrevalentForm(result).then(result => {
          if (result) {
            primaryConfirmDialog(
              'Kayıt tamamladı!',
              returnToList,
              'Kayıt Sonu',
              'Listeye Dön',
              () => window.location.reload(),
              'Aynı Sayfada Kal'
            );
          }
        });
      }
    };

    const savePrevalent = () => {
      if (!state.accountingBranch) {
        errorDialog('Lütfen şube seçiniz.');
        return false;
      }

      if (!state.title) {
        errorDialog('Lütfen cari ünvanı yazınız.');
        return false;
      }

      if (state.prevalentState === '0') {
        if (state.prevalentCode === '') {
          errorDialog('Lütfen cari kodunu yazınız.');
          return false;
        }
        console.log(state.prevalentKind);
        if (state.prevalentKind === '') {
          errorDialog('Lütfen cari cins kodunu seçiniz.');
          return false;
        }

        if (state.concept === '') {
          errorDialog('Lütfen konsepti seçiniz.');
          return false;
        }

        if (state.responsibilityCenter === '') {
          errorDialog('Lütfen sorumluluk merkezini seçiniz.');
          return false;
        }

        if (
          state.codeStart !== '' &&
          !state.prevalentCode.startsWith(state.codeStart)
        ) {
          errorDialog(
            `${state.prevalentKind} cari kodu ${state.codeStart} ile başlamalıdır.`
          );
          return false;
        }

        if (
          state.accountingCodeID !== '' &&
          (state.taxOffice === '' || state.taxNumber === '')
        ) {
          let answer = false;
          errorConfirmDialog(
            'Vergi dairesi, vergi numarası alanlarını doldurmadınız. Devam etmek istiyor musunuz ?',
            () => {
              answer = true;
            }
          );

          if (!answer) {
            return false;
          }
        }

        if (state.workingType === '2') {
          if (state.activelyWorkingStores.length) {
            errorDialog(
              'Lütfen işletmecinin çalıştığı aktif mağazaları belirleyiniz.'
            );
            return false;
          }
        }

        if (state.taxNumber.length !== 10) {
          errorDialog('Vergi numarası 10 haneli olmalıdır.');
          return false;
        }

        if (window.carikartlarda_muhasebe_hesabi_zorunlu_olmasin === '0') {
          if (
            (state.taxOffice !== '' || state.taxNumber !== '') &&
            state.accountingCodeID === ''
          ) {
            errorDialog('Lütfen muhasebe kodlarını doldurunuz');
            return false;
          } else {
            if (state.accountingCodeID === '') {
              errorDialog('Muhasebe kodunu girmediniz');
              return false;
            }
          }
        }
      }
      submitPrevalent();
    };

    const prevalentMovementsLink = computed(() => {
      return `/?s=hareketler&goster=1&sirket=${currentCompany.value.id}&sube=${
        state.accountingBranch
      }&tur=cari&devir=1&cari_id=${root.$route.params.id}&tarih1=${moment()
        .startOf('month')
        .format('DD.MM.YYYY')}&tarih2=${moment().format(
        'DD.MM.YYYY'
      )}&yarim_fis=1&x=1`;
    });

    const handlePrevalentFilesMiniModule = () => {
      window.ajaxpen(
        750,
        400,
        'Cari Dosyaları',
        `dosyalar&kaynak=10&kaynak_id=${root.$route.params.id}&sirket=${currentCompany.id}&sube=${state.accountingBranch}`
      );
    };

    const handleConfirmPrevalent = () => {
      confirmPrevalent(root.$route.params.id);
    };

    const creatorInformation = computed(() => {
      return (
        state.creator || state.creationDate || state.editor || state.editDate
      );
    });

    return {
      tabData,
      state,
      savePrevalent,
      returnToList,
      prevalentMovementsLink,
      handlePrevalentFilesMiniModule,
      handleConfirmPrevalent,
      creatorInformation,
      authorizationControl,
      buttonsIsVisible
    };
  }
};
</script>

<style lang="scss"></style>
