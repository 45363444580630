<template>
  <div>
    <ValidationObserver v-slot="{ validate }">
      <form @submit.prevent>
        <div class="overflow-auto">
          <div class="form-elements-doubled">
            <span class="labels-of-forms">Şirket</span>
            <span>
              {{ currentSirket.isim }}
            </span>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-12"
              >Liste Türü</label
            >
            <div class="flex flex-col">
              <ValidationProvider
                name="Liste Türü"
                rules="required"
                v-slot="{ errors }"
              >
                <wo-select
                  :data="listTypes"
                  :error="errors"
                  v-model="values.listType"
                >
                  <template v-slot:defaultValue>
                    <wo-select-item itemValue="0" text="-Seçiniz-" />
                  </template>
                  <template v-slot:default="{ item }">
                    <wo-select-item
                      :itemValue="item.id"
                      :text="item.name"
                      :key="'listType' + item.id"
                    />
                  </template>
                </wo-select>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Şube</label>
            <div class="flex flex-col">
              <ValidationProvider
                name="Şube"
                rules="required"
                v-slot="{ errors }"
              >
                <wo-select
                  v-model="values.subeValue"
                  :data="subeler"
                  :error="errors"
                >
                  <template v-slot:defaultValue>
                    <wo-select-item itemValue="" text="-Seçiniz-" />
                  </template>
                  <template v-slot:default="{ item }">
                    <wo-select-item
                      :itemValue="item.id"
                      :text="item.isim"
                      :key="'sube' + item.id"
                    />
                  </template>
                </wo-select>
              </ValidationProvider>
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">
              Seri-Sıra
            </label>
            <div class="flex flex-col-2">
              <WOSerialSequence
                module="kontrol-listesi"
                :serialValue.sync="values.seriValue"
                :sequenceValue.sync="values.siraValue"
              />
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Tarih</label>
            <div class="flex flex-col">
              <ValidationProvider
                name="Tarih"
                rules="required"
                v-slot="{ errors }"
              >
                <WODatePicker v-model="values.tarihValue" :error="errors" />
              </ValidationProvider>
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">Depo</label>
            <div class="flex flex-col-2">
              <ValidationProvider
                name="Depo"
                rules="required"
                v-slot="{ errors }"
              >
                <wo-select
                  v-model="values.depoValue"
                  :data="depolar"
                  @input="debouncedHandleDepoChange"
                  :error="errors"
                >
                  <template v-slot:defaultValue>
                    <wo-select-item itemValue="tumu" text="-Tümü-" />
                  </template>
                  <template v-slot:default="{ item }">
                    <wo-select-item
                      :itemValue="item.id"
                      :text="item.isim"
                      :key="'depo' + item.id"
                    />
                  </template>
                </wo-select>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Üretim Tarihi</label>
            <div class="flex flex-col">
              <ValidationProvider
                name="Tarih"
                rules="required"
                v-slot="{ errors }"
              >
                <WODatePicker
                  v-model="values.uretimTarihValue"
                  :error="errors"
                />
              </ValidationProvider>
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4"
              >Firma Lot No</label
            >
            <div class="flex flex-col-2 items-center gap-1">
              <vs-input v-model="values.firmaLotNo" class="w-58" />
              <i
                title="Otomatik Üret"
                class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true"
                @click="generatePartiNo('firmaLotNo')"
              ></i>
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">
              <vx-tooltip
                text="Brüt ağırlığı hesaplamak için kullanılacaktır."
                position="right"
              >
                Dara Ağırlığı <span class="font-bold text-orange-500">?</span>
              </vx-tooltip>
            </label>
            <div class="flex flex-col w-58">
              <vs-input
                v-model="values.daraAgirligi"
                @keypress="inputNumericConverter"
                class="wo-numeric-input w-58"
              />
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">
              Son Kalınan Palet No
            </label>
            <div class="flex flex-col-2">
              <vs-input
                v-model="values.sonPaletNo"
                class="wo-numeric-input w-58"
              />
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">
              <vx-tooltip
                text="İlk satır girilip ikinci satıra geçildiğinde evrak kaydedilecektir."
                position="right"
              >
                Otomatik Kaydet <span class="font-bold text-orange-500">?</span>
              </vx-tooltip>
            </label>
            <div class="flex flex-col w-58">
              <vs-checkbox v-model="values.otomatikKaydet">Evet</vs-checkbox>
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">
              Parti No
            </label>
            <div class="flex flex-col-2 items-center gap-1">
              <vs-input
                v-model="values.partiNoValue"
                class="wo-numeric-input w-58"
              />
              <i
                title="Otomatik Üret"
                class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true"
                @click="generatePartiNo('partiNoValue')"
              ></i>
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Otomatik Yazdır</label>
            <div class="flex flex-col w-58">
              <vs-checkbox v-model="values.stokSecildiYazdir">Evet</vs-checkbox>
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">
              Yazdırma Miktarı
            </label>
            <div class="flex flex-col-2">
              <vs-input
                v-model="values.yazdirmaMiktari"
                class="wo-numeric-input w-28"
              />
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Palet Başına Koli Sayısı</label>
            <div class="flex flex-col-2 w-58 items-center">
              <vs-input
                v-model="values.paletBasinaKoli"
                class="wo-numeric-input w-28"
                @keypress="inputNumericConverter"
              />
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">Açıklama</label>
            <div class="flex flex-col-2">
              <wo-textarea v-model="values.aciklamaValue" />
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Yükleme Numarası</label>
            <div class="flex flex-col-2 w-58 items-center">
              <vs-input v-model="values.yuklemeNumarasi" class="w-58" />
            </div>
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">
              <vx-tooltip
                text="Seçiliyken bir palette bir tane stok girilebilir, seçildiğinde birden fazla stok girilebilir hale gelecektir."
                position="right"
              >
                Palet Stok Kontrol
                <span class="font-bold text-orange-500">?</span>
              </vx-tooltip>
            </label>
            <div class="flex flex-col w-58">
              <vs-checkbox v-model="values.paletStokKontrol">Evet</vs-checkbox>
            </div>
          </div>

          <div class="form-elements-doubled">
            <label class="labels-of-forms">Durum</label>
            <div class="flex flex-col-2 w-58 items-center">
              <wo-select v-model="values.durumValue" :data="durumlar">
                <template v-slot:defaultValue>
                  <wo-select-item itemValue="" text="-Seçiniz-" />
                </template>
                <template v-slot:default="{ item }">
                  <wo-select-item
                    :itemValue="item.value"
                    :text="item.text"
                    :key="'durum' + item.value"
                  />
                </template>
              </wo-select>
            </div>
            <!--
            <label class="labels-of-forms mt-4 md:mt-0 md:ml-4">
              <vx-tooltip
                text="Seçiliyken bir satırlarda mükerrer koli numarası olamaz."
                position="right"
              >
                Koli Numarası Kontrol <span class="font-bold text-orange-500">?</span>
              </vx-tooltip>
            </label>
            <div class="flex flex-col w-58">
              <vs-checkbox v-model="values.koliNumarasiKontrol">Evet</vs-checkbox>
            </div>
            -->
            <label
              class="labels-of-forms mt-4 md:mt-0 md:ml-4"
              v-if="version === 'v2'"
            >
              Sayfa Başına Satır Sayısı
            </label>
            <div
              class="flex flex-col-2 w-58 items-center"
              v-if="version === 'v2'"
            >
              <wo-int-input style="width: 50%" v-model="values.itemPerPage" />
            </div>
          </div>

          <div>
            <TabloKontrolListesi
              :tableArray="tableArray"
              :removeItem="removeSatir"
              :depo="values.depoValue"
              :sube="values.subeValue"
              :birimler="birimler"
              @quickSearchEvent="data => quickSearchEvent(data)"
              @gitnCodeSelected="item => gitnCodeSelected(item, validate)"
              @handleAutomateSaving="debouncedHandleAutomateSaving"
              @handleRowPrint="index => handleRowPrint(index)"
              :item-per-page="parseInt(values.itemPerPage)"
              ref="tabloKontrolListesi"
            />
          </div>

          <div class="flex flex-col mt-4 md:flex-row justify-between">
            <div class="flex flex-col sm:flex-row sm:space-x-2">
              <wo-button
                data-id="yeni"
                @click="validate().then(handleSatirEkleButton)"
                text="Satır Ekle (F2)"
              />
            </div>
          </div>

          <div
            class="flex flex-col sm:flex-row justify-items-start mt-4 space-y-2 sm:space-x-2"
          >
            <wo-button
              data-id="kaydet"
              @click="
                () => {
                  validate().then(handleFormSubmit);
                }
              "
              id="submit-button"
              text="Kaydet (Alt + S)"
            />
            <wo-button
              v-if="isEditing()"
              data-id="yenile"
              @click="handleRefreshPage"
              text="Yenile"
            />
            <wo-button
              data-id="kopyala"
              @click="handleCopyRecord"
              text="Kopyala"
            />
            <wo-button
              text="Palet Yazdır"
              @click="handlePalletModal(true)"
              data-id="yazdir"
            />
            <wo-button
              text="Yazdır"
              @click="handleKontrolListesiYazdir('tr')"
              data-id="yazdir"
            />
            <wo-button
              text="Yazdır (EN)"
              @click="handleKontrolListesiYazdir('en')"
              data-id="yazdir"
            />
            <wo-button
              text="Excel"
              @click="handleKontrolListesiExcel('tr')"
              data-id="excel"
            />
            <wo-button
              text="Excel (EN)"
              @click="handleKontrolListesiExcel('en')"
              data-id="excel"
            />
            <wo-button text="Log Göster" @click="viewLogs" />
            <wo-button text="v1" @click="changeVersion" />
            <wo-button
              data-id="geri"
              :href="`${$baseURL}/kontrol_listesi`"
              text="Listeye Dön"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>

    <vs-prompt
      class="one-step-behind-dialog"
      @close="handlePalletModal(false)"
      :active.sync="palletModal"
      title="Palet Yazdır"
      :buttons-hidden="true"
    >
      <div>
        <div class="flex flex-row items-center m-2">
          <div>Yazdırılacak Palet Numarası</div>
          <vs-input
            v-model="values.yazdirilacakPaletNo"
            class="wo-numeric-input w-20 ml-auto"
          />
        </div>
        <div class="flex flex-row items-center m-2">
          <div>Palet Ağırlığı</div>
          <vs-input
            v-model="values.paletAgirligi"
            class="wo-numeric-input w-20 ml-auto"
          />
        </div>

        <wo-button
          data-id="yazdir"
          @click="palletPrintValidation"
          text="Yazdır"
          class="float-right mb-2"
        />
      </div>
    </vs-prompt>
    <LogModal
      :logModal="logModal"
      :logData="logData"
      @logModal="() => (logModal = false)"
    />
  </div>
</template>

<script>
import {
  getStockUnits,
  getBranches,
  getDepots,
  saveKontrolListesiKaydet,
  saveKontrolListesiDuzenleKaydet,
  fetchKontrolListesi,
  getBoxNumber,
  kontrolListesiAutomateSaving,
  fetchManufactureAutomateLotNumber,
  fetchStockDifferentLanguageData,
  fetchStockData,
  fetchBarcode,
  handleKontrolListesiLastAccess,
  kontrolListesiPalletPrintedCount,
  kontrolListesiSavePalletPrinted,
  getCheckListChanges,
  factory
} from '@/helpers/ApiController';

import Decimal from 'decimal.js';
import moment from 'moment';
import { dateFromClient } from '@/helpers/clientToServerConversion';
import useCompany from '@/consumables/useCompany';

import { ref, reactive, computed } from '@vue/composition-api';

import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';

import { dateFromServer } from '@/helpers/serverToClientConversion';

import TabloKontrolListesi from './TabloKontrolListesi.vue';
import WODatePicker from '@/components/WO/WODatePicker.vue';
import WOSerialSequence from '@/components/WO/WOSerialSequence.vue';
import printDocument from '@/helpers/printDocument';

import {
  errorDialog,
  errorConfirmReloadDialog,
  errorConfirmDialog,
  errorNotify
} from '@/helpers/errorDialogs';

import {
  inputNumericConverter,
  numToTrStr,
  strToNumToStr,
  trStrToNum
} from '@/helpers/NumberController';
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue';
import { primaryConfirmDialog } from '@/helpers/primaryDialogs';
import {
  overlay,
  handleTriggerOverlay
} from '@/consumables/useModuleOverlay.js';
import LogModal from '@/views/KontrolListesi/KontrolListesiForm/LogModal.vue';
import { deleteKontrolListesiRow } from '@/helpers/ApiController';
import { listTypes } from '@/views/KontrolListesi/composables/values.js';

export default {
  components: {
    TabloKontrolListesi,
    WODatePicker,
    WOSerialSequence,
    VxTooltip,
    LogModal
  },
  props: {
    version: {
      type: String,
      default: 'v2'
    }
  },
  setup(props, { root }) {
    const currentSirket = ref(useCompany());

    const tableArray = reactive({
      list: []
    });

    const values = reactive({
      durumValue: 0,
      subeValue: '',
      seriValue: '',
      siraValue: '',
      tarihValue: '',
      partiNoValue: '',
      depoValue: 'tumu',
      aciklamaValue: '',
      boxNumber: 1,
      sonPaletNo: 0,
      daraAgirligi: 0,
      yazdirilacakPaletNo: 0,
      paletAgirligi: 0,
      yuklemeNumarasi: '',
      uretimTarihValue: '',
      firmaLotNo: '',
      stokSecildiYazdir: false,
      yazdirmaMiktari: 1,
      otomatikKaydet: true,
      paletBasinaKoli: 24,
      paletStokKontrol: true,
      koliNumarasiKontrol: true,
      itemPerPage: 27,
      listType: ''
    });

    if (window.localStorage['kontrol_listesi_kopya']) {
      const copiedValues = JSON.parse(
        window.localStorage['kontrol_listesi_kopya']
      );
      delete copiedValues.siraValue;
      Object.keys(copiedValues).forEach(function (key) {
        values[key] = copiedValues[key];
      });
      window.localStorage.removeItem('kontrol_listesi_kopya');
    }

    const durumlar = ref([
      { value: 0, text: 'Hazırlanıyor' },
      { value: 1, text: 'Hazır' },
      { value: 2, text: 'Sevk Edildi' }
    ]);

    const subeler = ref([]);
    const depolar = ref([]);
    const birimler = ref([]);
    const palletModal = ref(false);

    const oldValues = [];
    const palletBoxChecking = {};
    const palletStockChecking = {};
    const boxNumberChecking = {};
    let errorDialogOpen = false;

    const handleErrorDialog = () => {
      errorDialogOpen = false;
    };

    const showErrorDialog = (message, title = 'Hata') => {
      if (!errorDialogOpen) {
        errorConfirmDialog(
          message,
          handleErrorDialog,
          title,
          handleErrorDialog
        );
        errorDialogOpen = true;
        document.activeElement.blur();
      }
    };

    const generatePartiNo = key => {
      const endPoints = {
        partiNoValue: 'parti-number',
        firmaLotNo: 'lot-number'
      };

      const uretim_tarih = moment(values.uretimTarihValue, 'DD.MM.YYYY')
        .add(1, 'days')
        .format('DD.MM.YYYY');
      const tarih = values.tarihValue;
      factory
        .get(`/modules/checklist/${endPoints[key]}`, {
          params: {
            seri_no: values.seriValue,
            tarih,
            uretim_tarih
          }
        })
        .then(({ data }) => {
          values[key] = data;
        })
        .catch(err => {
          showErrorDialog(err.response.data.message);
        });
    };

    const handlePalletStockDelete = (palletNo, stockId) => {
      palletStockChecking[`${palletNo}`][`${stockId}`] -= 1;

      if (palletStockChecking[`${palletNo}`][`${stockId}`] === 0) {
        delete palletStockChecking[`${palletNo}`][`${stockId}`];

        if (isEmpty(palletStockChecking[`${palletNo}`])) {
          delete palletStockChecking[`${palletNo}`];
        }
      }
    };

    const handlePalletStockAdd = (paletNo, stockId) => {
      if (!palletStockChecking[`${paletNo}`]) {
        palletStockChecking[`${paletNo}`] = {};
      }

      if (!palletStockChecking[`${paletNo}`][stockId]) {
        palletStockChecking[`${paletNo}`][stockId] = 1;
      } else {
        palletStockChecking[`${paletNo}`][stockId] += 1;
      }
    };

    const handleBoxNumberCheckingDelete = boxNumber => {
      delete boxNumberChecking[`${boxNumber}`];
    };

    const handleBoxNumberCheckingAdd = (boxNumber, index) => {
      boxNumber = parseInt(boxNumber || 0);
      if (!boxNumber > 0) {
        return false;
      }

      if (
        (boxNumberChecking[`${boxNumber}`] ||
          boxNumberChecking[`${boxNumber}`] === 0) &&
        boxNumberChecking[`${boxNumber}`] === index
      ) {
        return true;
      } else if (boxNumberChecking[`${boxNumber}`] >= 0) {
        return false;
      }

      if (oldValues[index] && oldValues[index].koliNo !== boxNumber) {
        delete boxNumberChecking[`${oldValues[index].koliNo}`];
      }

      boxNumberChecking[`${boxNumber}`] = index;

      return true;
    };

    const handlePalletModal = val => {
      palletModal.value = val;
    };

    const getPaletNo = () => {
      const paletNumbers = Object.keys(palletBoxChecking);
      if (paletNumbers.length > 0) {
        paletNumbers.sort(function (a, b) {
          return b - a;
        });

        return palletBoxChecking[`${paletNumbers[0]}`] >= values.paletBasinaKoli
          ? parseInt(paletNumbers[0] || 0) + 1
          : paletNumbers[0];
      }
      return parseInt(values.sonPaletNo || 0) + 1;
    };

    const handleAutomateSaving = async (
      data,
      column,
      indextr,
      fromGtin = false
    ) => {
      if (!(tableArray.list[indextr] && tableArray.list[indextr].stokId)) {
        return false;
      }

      if (
        typeof oldValues[indextr] === 'undefined' ||
        oldValues[indextr][column] !== data
      ) {
        if (
          typeof oldValues[indextr] === 'undefined' ||
          oldValues[indextr].paletNo !== tableArray.list[indextr].paletNo
        ) {
          if (
            palletBoxChecking[`${tableArray.list[indextr].paletNo}`] + 1 >
            values.paletBasinaKoli
          ) {
            showErrorDialog(
              `Girdiğiniz palet tamamlanmış. Palet başına ${values.paletBasinaKoli} adet koli tanımlayabilirsiniz.`
            );

            if (oldValues[indextr]) {
              tableArray.list[indextr].paletNo = oldValues[indextr].paletNo;
            } else {
              tableArray.list[indextr].paletNo = '';
            }
            return false;
          } else {
            palletBoxChecking[`${tableArray.list[indextr].paletNo}`] =
              palletBoxChecking[`${tableArray.list[indextr].paletNo}`]
                ? palletBoxChecking[`${tableArray.list[indextr].paletNo}`] + 1
                : 1;
            if (oldValues[indextr]) {
              handlePalletStockDelete(
                oldValues[indextr].paletNo,
                oldValues[indextr].stokId
              );
            }
            handlePalletStockAdd(
              tableArray.list[indextr].paletNo,
              tableArray.list[indextr].stokId
            );
            if (values.paletStokKontrol) {
              if (
                Object.keys(
                  palletStockChecking[tableArray.list[indextr].paletNo]
                ).length > 1
              ) {
                showErrorDialog(
                  'Palet Stok Kontrolüne göre aynı paletler yalnızca tek bir çeşit stok bulundurabilir. Değişiklik eski haline getirildi.'
                );

                handlePalletStockDelete(
                  tableArray.list[indextr].paletNo,
                  tableArray.list[indextr].stokId
                );
                if (oldValues[indextr]) {
                  handlePalletStockAdd(
                    oldValues[indextr].paletNo,
                    oldValues[indextr].stokId
                  );
                }

                if (oldValues[indextr]) {
                  tableArray.list[indextr].paletNo = oldValues[indextr].paletNo;
                }
                return false;
              }
            }

            if (oldValues[indextr]) {
              palletBoxChecking[`${oldValues[indextr].paletNo}`] -= 1;
            }
          }
        } else if (
          typeof oldValues[indextr] === 'undefined' ||
          oldValues[indextr].stokId !== tableArray.list[indextr].stokId
        ) {
          handlePalletStockDelete(
            oldValues[indextr].paletNo,
            oldValues[indextr].stokId
          );
          handlePalletStockAdd(
            tableArray.list[indextr].paletNo,
            tableArray.list[indextr].stokId
          );

          if (values.paletStokKontrol) {
            if (
              Object.keys(palletStockChecking[tableArray.list[indextr].paletNo])
                .length > 1
            ) {
              errorDialog(
                'Palet Stok Kontrolüne göre aynı paletler yalnızca tek bir çeşit stok bulundurabilir. Değişiklik eski haline getirildi.'
              );

              handlePalletStockDelete(
                tableArray.list[indextr].paletNo,
                tableArray.list[indextr].stokId
              );
              handlePalletStockAdd(
                oldValues[indextr].paletNo,
                oldValues[indextr].stokId
              );

              tableArray.list[indextr].stokId = oldValues[indextr].stokId;
              tableArray.list[indextr].stok = oldValues[indextr].stok;
              tableArray.list[indextr].stokIsim = oldValues[indextr].stokIsim;
              tableArray.list[indextr].stokKod = oldValues[indextr].stokKod;
              return false;
            }
          }
        }

        if (
          !handleBoxNumberCheckingAdd(
            tableArray.list[indextr].koliNo,
            indextr
          ) &&
          values.koliNumarasiKontrol
        ) {
          showErrorDialog(
            'Koli numarası mevcut olduğu için satır kaydedilmedi.Koli numarası eski değeri mevcut ise ona, değilse 0 olarak güncellenecektir. Lütfen kontrol ediniz.',
            'Aynı Koli Numarası Tekrar Edilemez.'
          );
          tableArray.list[indextr].koliNo = oldValues[indextr]
            ? oldValues[indextr].koliNo
            : 0;
          return false;
        }

        if (!values.otomatikKaydet) {
          return false;
        }

        const miktarDbNumber = trStrToNum(tableArray.list[indextr].miktar);
        const brutMiktarDbnumber = trStrToNum(
          tableArray.list[indextr].brutMiktar
        );
        const daraAgirligi = trStrToNum(values.daraAgirligi);
        if (!miktarDbNumber > 0) {
          errorNotify('Miktar 0 olduğu için otomatik kaydetme yapılmadı.');
          return false;
        }

        if (!fromGtin || (fromGtin && brutMiktarDbnumber === 0)) {
          const brutMiktar = new Decimal(miktarDbNumber)
            .plus(daraAgirligi)
            .toNumber();
          tableArray.list[indextr].brutMiktar = numToTrStr(brutMiktar);
        }

        const obj = {
          row: tableArray.list[indextr]
        };

        if (!root.$route.params.id) {
          obj.sube = values.subeValue;
          obj.depo = values.depoValue;
          obj.tarih = values.tarihValue;
          obj.seri_no = values.seriValue;
          obj.sira_no = values.siraValue;
          obj.parti_no = values.partiNoValue;
          obj.palet_no_baslangic = values.sonPaletNo;
          obj.dara_agirligi = values.daraAgirligi;
          obj.firma_lotno = values.firmaLotNo;
          obj.uretim_tarih = values.uretimTarihValue;
          obj.palet_basi_koli_sayisi = values.paletBasinaKoli;
          obj.yukleme_numarasi = values.yuklemeNumarasi;
          obj.palet_stok_kontrol = values.paletStokKontrol;
          obj.koli_no_kontrol = values.koliNumarasiKontrol;
          obj.durum = values.durumValue;
          obj.aciklama = values.aciklamaValue;
        } else {
          obj.record_id = root.$route.params.id;
        }

        try {
          const data = await kontrolListesiAutomateSaving(obj);
          if (
            data &&
            data.record_id > 0 &&
            typeof obj.record_id === 'undefined'
          ) {
            window.location.href = `${root.$baseURL}/kontrol_listesi/${data.record_id}/duzenle`;
          } else {
            oldValues[indextr] = JSON.parse(
              JSON.stringify(tableArray.list[indextr])
            );
            tableArray.list[indextr].id = data.id;
          }
        } catch (err) {
          errorNotify(err.response.data.message);
        }
      }
    };

    const fetchLotNumber = async (stokId, tuketimSure) => {
      const lotNumberParams = {
        stok_id: '',
        parti_no: '',
        dayNumber: '',
        tarih: '',
        skt: ''
      };

      const dbTarih = dateFromClient(values.uretimTarihValue);
      const dbTarihMoment = moment(dbTarih);
      const dayOfYear = dbTarihMoment.dayOfYear().toString().padStart(3, '0');

      lotNumberParams.stok_id = stokId;
      lotNumberParams.dayNumber = dayOfYear.toString().padStart(3, '0');
      lotNumberParams.parti_no = values.partiNoValue;
      lotNumberParams.tarih = dbTarih;
      lotNumberParams.skt =
        parseInt(tuketimSure || 0) > 0
          ? dbTarihMoment
              .add(parseInt(tuketimSure || 0), 'days')
              .format('YYYY-MM-DD')
          : '0000-00-00';

      let lotNo = [values.firmaLotNo];
      if (values.firmaLotNo === '') {
        lotNo = await fetchManufactureAutomateLotNumber(lotNumberParams);
      }

      return {
        lotNo: lotNo[0],
        sonKullanimTarih: dateFromServer(lotNumberParams.skt)
      };
    };

    const tabloKontrolListesi = ref();

    const handleSatirEkleButton = () => {
      const filteredRows = tableArray.list.filter(
        item => parseInt(item.stokId || 0) === 0
      );
      if (Object.keys(filteredRows).length > 0) {
        errorNotify(
          'Yeni bir satır eklemeden önce boş satırları doldurunuz.',
          'Uyarı'
        );

        return false;
      }
      const newLine = {
        stok: '',
        stokId: 0,
        stokKod: '',
        stokIsim: '',
        stokQuery: computed(() => {
          return `?tablo=stok&sube=${values.subeValue}&depo=${values.depoValue}&q=`;
        }),
        birim: birimler.value[0].id,
        miktar: 0,
        brutMiktar: 0,
        koliIciMiktar: 0,
        koliNo: 0,
        lotNo: '',
        sonKullanimTarih: ''
      };

      if (
        tableArray.list.length === 0 &&
        typeof root.$route.params.id === 'undefined'
      ) {
        newLine.koliNo = values.boxNumber + 1;
      } else if (tableArray.list.length > 0) {
        newLine.koliNo =
          parseInt(tableArray.list[tableArray.list.length - 1].koliNo) + 1;
      }
      newLine.paletNo = getPaletNo();

      root.$set(tableArray.list, tableArray.list.length, newLine);
      root.$nextTick(() => {
        document
          .querySelector('.tr-values:last-child')
          .children[2].querySelector('input')
          .focus();
      });
    };

    const quickSearchEvent = async data => {
      const { item, index } = data;
      const row = tableArray.list[index];

      if (item && item.id) {
        row.stok = window.mobile ? item.isim : item.text;
        row.stokId = item.id;
        row.stokKod = item.kod;
        row.stokIsim = item.isim;
        row.birim = item.birim;
        const lotSktObject = await fetchLotNumber(item.id, item.tuketim_sure);
        row.lotNo = lotSktObject.lotNo ? lotSktObject.lotNo : '';
        row.sonKullanimTarih = lotSktObject.sonKullanimTarih
          ? lotSktObject.sonKullanimTarih
          : '';
      }
    };

    const removeSatir = index => {
      document.activeElement.blur();
      const acceptRemoveSatir = async () => {
        if (tableArray.list[index].id && values.otomatikKaydet) {
          await deleteKontrolListesiRow(tableArray.list[index].id);
        }
        palletBoxChecking[`${tableArray.list[index].paletNo}`] -= 1;
        handlePalletStockDelete(
          tableArray.list[index].paletNo,
          tableArray.list[index].stokId
        );
        handleBoxNumberCheckingDelete(tableArray.list[index].koliNo);
        tableArray.list.splice(index, 1);
        const tablo = document.querySelector('.wo-table');
        const satir = tablo.children[index ? index : 1];
        if (satir) {
          satir.querySelector('input').focus();
        }

        oldValues.splice(index, 1);
      };
      errorConfirmDialog(
        'Silme işlemini onaylıyor musunuz?',
        acceptRemoveSatir
      );
    };

    const handleFormSubmit = () => {
      document.getElementById('submit-button').setAttribute('disabled', true);
      document.activeElement.blur();
      if (tableArray.list.length === 0) {
        errorDialog('Lütfen satır ekleyiniz.');
        return;
      }

      const boxNumbers = {};
      for (const i in tableArray.list) {
        const item = tableArray.list[i];

        if (!boxNumbers[item.koliNo]) {
          boxNumbers[item.koliNo] = 1;
        } else {
          errorDialog(
            `Aynı koli numarası birden fazla girildi. (Tablo, Satır: ${
              parseInt(i) + 1
            })`
          );
          document
            .getElementById('submit-button')
            .setAttribute('disabled', false);
          return;
        }

        if (item.urunId === 0) {
          errorDialog(
            `Lütfen geçerli ürün giriniz. (Tablo, Satır: ${parseInt(i) + 1})`
          );
          document
            .getElementById('submit-button')
            .setAttribute('disabled', false);
          return;
        }
      }

      const resultObj = {
        sube: values.subeValue,
        depo: values.depoValue,
        tarih: values.tarihValue,
        seri_no: values.seriValue,
        sira_no: values.siraValue,
        parti_no: values.partiNoValue,
        palet_no_baslangic: values.sonPaletNo,
        dara_agirligi: values.daraAgirligi,
        firma_lotno: values.firmaLotNo,
        uretim_tarih: values.uretimTarihValue,
        palet_basi_koli_sayisi: values.paletBasinaKoli,
        yukleme_numarasi: values.yuklemeNumarasi,
        palet_stok_kontrol: values.paletStokKontrol,
        koli_no_kontrol: values.koliNumarasiKontrol,
        item_per_page: values.itemPerPage,
        durum: values.durumValue,
        aciklama: values.aciklamaValue,
        list_type: values.listType,
        satirlar: []
      };

      tableArray.list.forEach(data => {
        resultObj.satirlar.push({
          stok_id: data.stokId,
          birim: data.birim,
          lotno: data.lotNo,
          sonkullanim_tarih: data.sonKullanimTarih,
          miktar: strToNumToStr(data.miktar),
          brut_miktar: strToNumToStr(data.brutMiktar),
          koli_ici_miktar: strToNumToStr(data.koliIciMiktar),
          koli_no: data.koliNo,
          palet_numarasi: data.paletNo
        });
      });

      let saveKontrolListesi = null;
      if (root.$route.params.id) {
        saveKontrolListesi = saveKontrolListesiDuzenleKaydet(
          root.$route.params.id,
          resultObj
        );
      } else {
        saveKontrolListesi = saveKontrolListesiKaydet(resultObj);
      }

      saveKontrolListesi
        .then(response => {
          window.location.href = response.data.redirect;
        })
        .catch(({ response }) => {
          document.getElementById('submit-button').removeAttribute('disabled');
          if (response.data.message) {
            errorDialog(response.data.message);
          } else {
            errorDialog(
              'Kontrol listesi kaydederken bir sıkıntı oluştu. Lütfen tekrar deneyiniz.'
            );
          }
        });
    };

    const handleCopyRecord = () => {
      primaryConfirmDialog(
        'Kayıt kopyalanıp yeni evrak olarak açılacaktır, kaydetmediğiniz müddetçe kopyalama tamamlanmayacaktır. Onaylıyor musunuz?',
        () => {
          window.localStorage['kontrol_listesi_kopya'] = JSON.stringify(values);
          window.open('/kontrol_listesi/yeni', '_self');
        }
      );
    };

    const debouncedHandleAutomateSaving = debounce(
      (obj, callback = undefined) => {
        console.log(obj, tableArray.list);
        if (
          obj.indextr !== 0 &&
          tableArray.list[obj.indextr - 1].miktar ===
            tableArray.list[obj.indextr].miktar &&
          parseInt(tableArray.list[obj.indextr - 1].stokId) ===
            parseInt(tableArray.list[obj.indextr].stokId)
        ) {
          errorDialog(
            'Dikkat! Aynı ürünü ve miktarı tekrar okuttunuz, lütfen kontrol ediniz.'
          );
        }
        handleAutomateSaving(
          obj.data,
          obj.column,
          obj.indextr,
          obj.fromGtin || false
        ).then(response => {
          if (response !== false && callback) {
            callback();
          }
        });
      },
      250
    );

    const gitnCodeSelected = async item => {
      const apiRow = item.data;
      const tableRow = tableArray.list[item.indextr];

      if (apiRow.params.miktar === 0) {
        errorNotify(
          "Okuttuğunuz barkod'da miktar sıfır, yanlış tartılmıştır tekrar tartıp etiketlemelisiniz."
        );

        return false;
      }

      const daraAgirligi = trStrToNum(values.daraAgirligi);
      const hesaplananBrutMiktarDbNumber = new Decimal(apiRow.params.miktar)
        .plus(daraAgirligi)
        .toNumber();
      let brutMiktar = 0;

      if (
        apiRow.params.brut_miktar > 0 &&
        apiRow.params.brut_miktar !== hesaplananBrutMiktarDbNumber
      ) {
        brutMiktar = numToTrStr(apiRow.params.brut_miktar);
      } else {
        brutMiktar = numToTrStr(hesaplananBrutMiktarDbNumber);
      }

      tableRow.stok = `${apiRow.stok_kod} - ${apiRow.stok_isim}`;
      tableRow.stokId = apiRow.id;
      tableRow.stokKod = apiRow.stok_kod;
      tableRow.stokIsim = apiRow.stok_isim;
      tableRow.birim = apiRow.stok_birim1_ad;
      tableRow.miktar = numToTrStr(apiRow.params.miktar);
      tableRow.brutMiktar = brutMiktar;
      if (apiRow.params.koli_no > 0) {
        tableRow.koliNo = apiRow.params.koli_no;
      } else {
        tableRow.koliNo =
          item.indextr > 0
            ? parseInt(tableArray.list[item.indextr - 1].koliNo || 0) + 1
            : values.boxNumber + 1;
      }

      tableRow.paletNo = getPaletNo();
      tableRow.koliIciMiktar = apiRow.stok_birim4_deger;
      tableRow.lotNo = apiRow.params.lotno;
      tableRow.sonKullanimTarih = dateFromServer(apiRow.params.skt);

      if (!apiRow.params.lotno) {
        const lotSktObject = await fetchLotNumber(
          apiRow.id,
          apiRow.tuketim_sure
        );

        tableRow.lotNo = lotSktObject.lotNo ? lotSktObject.lotNo : '';
        tableRow.sonKullanimTarih = lotSktObject.sonKullanimTarih
          ? lotSktObject.sonKullanimTarih
          : '';
      }

      if (values.stokSecildiYazdir) {
        const obj = {};
        obj[`${apiRow.id}`] = apiRow.id;
        const stockName = await fetchStockDifferentLanguageData({
          stok_id: obj
        });
        const etiketData = {
          KOPYA_SAYISI: parseInt(values.yazdirmaMiktari || 1),
          STOK_ADI: apiRow.stok_isim,
          INGILIZCE_STOK_ADI: stockName[apiRow.id]
            ? stockName[apiRow.id].en_isim
            : '',
          PROCESS_TIPI: apiRow.isleme_tipi_isim,
          KALITE_KONTROL_KODU: apiRow.kalite_kontrol_kodu_isim,
          KORUMA_TIPI: apiRow.koruma_tipi_isim,
          KALIBRE_KODU: apiRow.kalibre_kodu_isim,
          BARKOD: apiRow.params.barcode,
          BRUT_MIKTAR: tableRow.brutMiktar,
          NET_MIKTAR: numToTrStr(apiRow.params.miktar),
          SAKLAMA_KOSULU: apiRow.saklama,
          PALET_NO: tableRow.paletNo,
          KUTU_NO: tableRow.koliNo,
          URETIM_TARIHI: values.uretimTarihValue,
          SKT: tableRow.sonKullanimTarih,
          LOT_NO: tableRow.lotNo,
          GTIN: `01 ${apiRow.params.barcode} 10 ${tableRow.lotNo} 17 ${moment(
            tableRow.sonKullanimTarih,
            'DD.MM.YYYY'
          ).format('YYMMDD')} 3102 ${numToTrStr(apiRow.params.miktar)} 3302 ${
            tableRow.brutMiktar
          } 00 ${tableRow.koliNo}`
        };

        window.otomatikYazdir(etiketData);
      }

      await debouncedHandleAutomateSaving(
        {
          data: true,
          column: 'gtinAdded',
          indextr: item.indextr,
          fromGtin: true
        },
        handleSatirEkleButton
      );
    };

    // ! Duzenle scripti
    const fetchDetails = async () => {
      try {
        const { data } = await fetchKontrolListesi(root.$route.params.id);
        root.$set(values, 'depoValue', data.depo === '0' ? '' : data.depo);
        root.$set(values, 'subeValue', data.sube === '0' ? '' : data.sube);
        root.$set(values, 'seriValue', data.seri_no);
        root.$set(
          values,
          'siraValue',
          data.sira_no === '0' ? '' : data.sira_no
        );
        root.$set(values, 'partiNoValue', data.parti_no);
        root.$set(values, 'daraAgirligi', numToTrStr(data.dara_agirligi));
        root.$set(
          values,
          'uretimTarihValue',
          dateFromServer(data.uretim_tarih)
        );
        root.$set(values, 'firmaLotNo', data.firma_lotno);
        root.$set(values, 'sonPaletNo', data.palet_no_baslangic);
        root.$set(values, 'tarihValue', dateFromServer(data.tarih));
        root.$set(values, 'paletBasinaKoli', data.palet_basi_koli_sayisi);
        root.$set(values, 'aciklamaValue', data.aciklama);
        root.$set(values, 'yuklemeNumarasi', data.yukleme_numarasi);
        root.$set(values, 'itemPerPage', data.item_per_page || 27);
        root.$set(values, 'listType', data.list_type);
        root.$set(
          values,
          'paletStokKontrol',
          parseInt(data.palet_stok_kontrol || 0) === 1
        );
        root.$set(
          values,
          'koliNumarasiKontrol',
          parseInt(data.koli_no_kontrol || 0) === 1
        );
        root.$set(values, 'durumValue', data.durum);

        const rows = Object.values(data.satirlar);
        rows.sort(function (a, b) {
          return a.id - b.id;
        });
        data.satirlar = rows;

        for (const i in data.satirlar) {
          const apiRow = data.satirlar[i];
          if (apiRow.id > 0) {
            handleSatirEkleButton();
            const tableRow = tableArray.list[i];
            tableRow.id = apiRow.id;
            tableRow.stok = window.mobile
              ? apiRow.stok.isim
              : `${apiRow.stok.kod} - ${apiRow.stok.isim}`;
            tableRow.stokId = apiRow.stok.id;
            tableRow.stokKod = apiRow.stok.kod;
            tableRow.stokIsim = apiRow.stok.isim;
            tableRow.birim = apiRow.birim;
            tableRow.miktar = numToTrStr(apiRow.miktar);
            tableRow.brutMiktar = numToTrStr(apiRow.brut_miktar);
            tableRow.koliIciMiktar = numToTrStr(apiRow.koli_ici_miktar);
            tableRow.paletNo = apiRow.palet_numarasi;
            tableRow.koliNo = apiRow.koli_no;
            tableRow.lotNo = apiRow.lotno;
            tableRow.sonKullanimTarih = dateFromServer(
              apiRow.sonkullanim_tarih
            );

            oldValues[i] = JSON.parse(JSON.stringify(tableRow));

            if (palletBoxChecking[`${apiRow.palet_numarasi}`]) {
              palletBoxChecking[`${apiRow.palet_numarasi}`] += 1;
            } else {
              palletBoxChecking[`${apiRow.palet_numarasi}`] = 1;
            }

            boxNumberChecking[`${apiRow.koli_no}`] = parseInt(i || 0);

            handlePalletStockAdd(apiRow.palet_numarasi, apiRow.stok.id);
          }
        }

        handleTriggerOverlay({
          enable: data.enable,
          enableMessage: data.enable_message
        });
        if (root.$route.params.id) {
          console.log('data', data.sirket_adi);
          currentSirket.value.isim = data.sirket_adi;
        }
      } catch (e) {
        errorConfirmReloadDialog(
          'Detayları alırken bir sıkıntı oluştu. Lütfen sayfayı yenileyiniz.'
        );
      }
    };

    const handleDepoChange = async () => {
      if (typeof root.$route.params.id === 'undefined') {
        const boxNumber = await getBoxNumber({
          seri: values.seriValue,
          depo: values.depoValue
        });

        values.boxNumber = parseInt(boxNumber.koli_no || 0);
      }
    };

    const debouncedHandleDepoChange = debounce(handleDepoChange, 10);

    const handleKontrolListesiYazdir = async (
      lang = 'tr',
      returnHtml = false
    ) => {
      const tableHeaders = {
        tr: [
          'PALET NO',
          'KUTU NO',
          'STOK',
          'ÜRETİM TARİHİ',
          'SON KULLANIM TARİHİ',
          'LOT NO',
          'NET AĞIRLIK (KG)',
          'BRÜT AĞIRLIK (KG)'
        ],
        en: [
          'PALLET N.',
          'BOX NR.',
          'DESCRIPTION OF THE GOODS',
          'PRODUCTION DATE',
          'EXPIRY DATE',
          'LOT NR',
          'NET WEIGHT WITH GLAZING (KG)',
          'GROSS WEIGHT (KG)'
        ]
      };

      const data = JSON.parse(JSON.stringify(tableArray.list));
      const stokIdList = {};
      const stokKutuSayisi = {};
      const summary = [];
      data.forEach(item => {
        const itemClone = JSON.parse(JSON.stringify(item));
        const key = `${itemClone.stokId}_${itemClone.lotNo}_${itemClone.sonKullanimTarih}`;

        stokIdList[`${item.stokId}`] = item.stokId;
        if (stokKutuSayisi[`${key}`]) {
          stokKutuSayisi[`${key}`] += 1;
        } else {
          stokKutuSayisi[`${key}`] = 1;
        }

        const paletNo = itemClone.paletNo;
        const miktarDbNumber = trStrToNum(itemClone.miktar);
        const brutMiktarDbNumber = trStrToNum(itemClone.brutMiktar);
        item.paletNo = paletNo;
        item.miktar = miktarDbNumber;
        item.brut_miktar = brutMiktarDbNumber;

        if (summary[key]) {
          summary[key].miktar = new Decimal(miktarDbNumber)
            .plus(summary[key].miktar)
            .toNumber();

          summary[key].brut_miktar = new Decimal(item.brut_miktar)
            .plus(summary[key].brut_miktar)
            .toNumber();
          summary[key].koliIciMiktar = new Decimal(
            trStrToNum(itemClone.koliIciMiktar)
          )
            .plus(summary[key].koliIciMiktar)
            .toNumber();
        } else {
          summary[key] = itemClone;
          summary[key].miktar = miktarDbNumber;
          summary[key].brut_miktar = item.brut_miktar;
          summary[key].koliIciMiktar = trStrToNum(itemClone.koliIciMiktar);
        }
      });

      const stockData = await fetchStockDifferentLanguageData({
        stok_id: stokIdList
      });

      let summaryRows = '';

      const summaryValues = Object.values(summary);
      const summaryKeys = Object.keys(summary);

      for (let i = 0; i < summaryKeys.length; i++) {
        const item = summaryValues[i];
        const stockIsim =
          lang !== 'tr'
            ? stockData[item.stokId][`${lang}_isim`]
            : item.stokIsim;

        const key = `${item.stokId}_${item.lotNo}_${item.sonKullanimTarih}`;

        summaryRows += `<tr>
        <td style="font-weight: bold;padding:3px;" colspan="2"></td>
        <td style="font-weight: bold;padding:3px;">${stockIsim}</td>
        <td style="font-weight: bold;padding:3px;">${
          values.uretimTarihValue
        }</td>
        <td style="font-weight: bold;padding:3px;">${item.sonKullanimTarih}</td>
        <td style="font-weight: bold;padding:3px;">${item.lotNo}</td>
        <td style="font-weight: bold;padding:3px;" align="right">${numToTrStr(
          item.miktar
        )}</td>
        <td style="font-weight: bold;padding:3px;" align="right">${numToTrStr(
          item.brut_miktar ? item.brut_miktar : 0
        )}</td>
        <td style="font-weight: bold;padding:3px;" align="right">${
          stokKutuSayisi[key]
        }</td>
      </tr>`;
      }

      const headerTh = tableHeaders[lang]
        .map(item => `<td>${item}</td>`)
        .join('');

      const rows = data.map(item => {
        const stockIsim =
          lang !== 'tr'
            ? stockData[item.stokId][`${lang}_isim`]
            : item.stokIsim;

        return `<tr>
        <td>${item.paletNo ? item.paletNo : ''}</td>
        <td>${item.koliNo ? item.koliNo : ''}</td>
        <td>${stockIsim}</td>
        <td>${values.uretimTarihValue}</td>
        <td>${item.sonKullanimTarih ? item.sonKullanimTarih : ''}</td>
        <td>${item.lotNo ? item.lotNo : ''}</td>
        <td align="right">${numToTrStr(item.miktar ? item.miktar : 0)}</td>
        <td align="right">${numToTrStr(
          item.brut_miktar ? item.brut_miktar : 0
        )}</td>
      </tr>`;
      });

      const html = `<style>a,abbr,acronym,address,applet,article,aside,audio,big,blockquote,body,button,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,iframe,img,input,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,select,small,span,strike,sub,summary,sup,table,tbody,td,textarea,tfoot,th,thead,time,tr,tt,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}a{text-decoration:none}img{border:0}:focus{outline:0}.clear{clear:both}
      table { font-family: 'Tahoma'; font-size: 10px; } </style>
      <table width="100%" style="margin-top: 15px;">
        <colgroup>
          <col style="width: 7.5%;">
          <col style="width: 7.5%;">
          <col style="width: 50%;">
          <col style="width: 7.5%;">
          <col style="width: 7.5%;">
          <col style="width: 7.5%;">
          <col style="width: 7.5%;">
          <col style="width: 5%;">
        </colgroup>
        <thead>
          <tr>
           ${headerTh}
          </tr>
        </thead>
        <tr><td colspan="6">&nbsp;</td></tr>
        ${rows.join('')}
        <tr><td colspan="6">&nbsp;</td></tr>
        <tr><td colspan="6">&nbsp;</td></tr>
        ${summaryRows}
      </table>`;

      if (returnHtml === true) {
        return html;
      }

      printDocument(html);
    };

    const exportExcel = (html, name) => {
      const uri = 'data:application/vnd.ms-excel;base64,',
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
          });
        };
      const ctx = { worksheet: name || 'Worksheet', table: html };
      window.location.href = uri + base64(format(template, ctx));
    };

    const handleKontrolListesiExcel = async (lang = 'tr') => {
      const HTML = await handleKontrolListesiYazdir(lang, true);
      exportExcel(HTML, 'Kontrol Listesi');
    };

    const handleRowPrint = index => {
      primaryConfirmDialog(
        'Kayıt yazıcıya gönderilecek onaylıyor musunuz?',
        async () => {
          const stokId = tableArray.list[index].stokId;

          const obj = {};
          obj[`${stokId}`] = stokId;

          const stockData = await fetchStockData({ stok_id: stokId });
          const stockName = await fetchStockDifferentLanguageData({
            stok_id: obj
          });

          const etiketData = {
            KOPYA_SAYISI: parseInt(values.yazdirmaMiktari || 1),
            STOK_ADI: stockData.stok_isim,
            INGILIZCE_STOK_ADI: stockName[stokId]
              ? stockName[stokId].en_isim
              : '',
            PROCESS_TIPI: stockData.isleme_tipi_isim,
            KALITE_KONTROL_KODU: stockData.kalite_kontrol_kodu_isim,
            KORUMA_TIPI: stockData.koruma_tipi_isim,
            KALIBRE_KODU: stockData.kalibre_kodu_isim,
            BARKOD: stockData.barcode,
            SAKLAMA_KOSULU: stockData.saklama,
            BRUT_MIKTAR: tableArray.list[index].brutMiktar,
            NET_MIKTAR: tableArray.list[index].miktar,
            PALET_NO: tableArray.list[index].paletNo,
            KUTU_NO: tableArray.list[index].koliNo,
            URETIM_TARIHI: values.uretimTarihValue,
            SKT: tableArray.list[index].sonKullanimTarih,
            LOT_NO: tableArray.list[index].lotNo,
            GTIN: `01 ${stockData.barcode} 10 ${
              tableArray.list[index].lotNo
            } 17 ${moment(
              tableArray.list[index].sonKullanimTarih,
              'DD.MM.YYYY'
            ).format('YYMMDD')} 3102 ${tableArray.list[index].miktar} 3302 ${
              tableArray.list[index].brutMiktar
            } 00 ${tableArray.list[index].koliNo}`
          };

          window.otomatikYazdir(etiketData);
        }
      );
    };

    const handlePalletPrint = async () => {
      if (!palletStockChecking[values.yazdirilacakPaletNo]) {
        errorNotify(
          `${values.yazdirilacakPaletNo} numaralı paleti yazdırmak istediniz fakat satırlarda bu palet numarası bulunamadı.`
        );
        return false;
      }

      const stockIdList = Object.keys(
        palletStockChecking[values.yazdirilacakPaletNo]
      );

      if (
        values.paletStokKontrol &&
        Object.keys(palletStockChecking[values.yazdirilacakPaletNo]).length > 1
      ) {
        errorNotify('Aynı palete birden fazla stok giremezsiniz.');
        return false;
      }

      const printCallback = async () => {
        const stockDataList = {};
        for (const stockId of stockIdList) {
          stockDataList[`${stockId}`] = await fetchStockData({
            stok_id: stockId
          });
        }

        // const stockName = await fetchStockDifferentLanguageData({
        //   stok_id: stockIdList
        // });

        const total = {
          net: 0,
          brut: 0,
          stocks: {}
        };

        const yazdirilacakPaletAgirligiDbNumber = trStrToNum(
          values.paletAgirligi
        );

        const data = tableArray.list.filter(item => {
          return (
            parseInt(item.paletNo || 0) ===
            parseInt(values.yazdirilacakPaletNo || 0)
          );
        });

        const rows = data.map(item => {
          const miktarDbNumber = trStrToNum(item.miktar);
          const brutMiktar = trStrToNum(item.brutMiktar);

          total.net = new Decimal(total.net).plus(miktarDbNumber).toNumber();
          total.brut = new Decimal(total.brut).plus(brutMiktar).toNumber();
          if (!total.stocks[`${item.stokId}`]) {
            total.stocks[`${item.stokId}`] = {
              net: 0,
              brut: 0,
              kutu_sayisi: 0
            };
          }
          total.stocks[`${item.stokId}`].net = new Decimal(
            total.stocks[`${item.stokId}`].net
          )
            .plus(miktarDbNumber)
            .toNumber();
          total.stocks[`${item.stokId}`].brut = new Decimal(
            total.stocks[`${item.stokId}`].brut
          )
            .plus(brutMiktar)
            .toNumber();
          total.stocks[`${item.stokId}`].kutu_sayisi = new Decimal(
            total.stocks[`${item.stokId}`].kutu_sayisi
          )
            .plus(1)
            .toNumber();

          return `<tr>
          <td style="text-align: center;">${item.stokKod}</td>
          <td style="text-align: center;">${item.koliNo}</td>
          <td style="text-align: center;">${values.uretimTarihValue}</td>
          <td style="text-align: center;">${item.sonKullanimTarih}</td>
          <td style="text-align: center;">${item.lotNo}</td>
          <td style="text-align: right;">${item.miktar}</td>
          <td style="text-align: right;">${numToTrStr(brutMiktar)}</td>
          </tr>`;
        });

        const stockDataListKeys = Object.keys(stockDataList);

        const footer = async () => {
          let stocksFooter = '';

          for (const index in stockDataListKeys) {
            const stockId = stockDataListKeys[index];
            const totalByStock = total.stocks[`${stockId}`];
            const stockData = stockDataList[`${stockId}`];
            const GTIN = `01 ${stockData.barcode} 3102 ${`${numToTrStr(
              totalByStock.net
            )}`.replace('.', '')} 3302 ${`${numToTrStr(
              totalByStock.brut
            )}`.replace('.', '')} ${values.yazdirilacakPaletNo}`;

            const barcodeBase64Image = await fetchBarcode({
              barcode_type: 'QRCODE,H',
              barcode_content: GTIN,
              width: 100,
              height: 100
            });
            console.log(GTIN);
            stocksFooter += `<tr style="font-weight: bold; margin-top 5pt;">
              <td colspan="4">
                <span style="width: 100%;display: inline-block;">
                ${stockData.stok_kod} ${stockData.stok_isim}
                </span>
            </td>
              <td colspan="2">
                <span style="width: 100%;display: inline-block;">
                  Total Net : <span style="float: right;">${numToTrStr(
                    totalByStock.net
                  )}</span>
                </span>
                <br>
                <span style="width: 100%;display: inline-block;">
                  Total Brüt/Gross : <span style="float: right;">${numToTrStr(
                    totalByStock.brut
                  )}</span>
                </span>
                <br>
                <span style="width: 100%;display: inline-block;">
                  Total Box : <span style="float: right;">${
                    totalByStock.kutu_sayisi
                  }</span>
                </span>
              </td>
              <td><img src="${
                barcodeBase64Image.barcode
              }" align="right" style="height: 50pt; width: 50pt; padding: 15px;"></td>
            </tr>`;
          }
          stocksFooter += `<tr><td colspan="7" style="border-top: 1px solid black;">
              <b>Evrak Seri-Sıra / Document Serial-Sequence<b>: ${values.seriValue} - ${values.siraValue}
              </td></tr>`;

          return stocksFooter;
        };

        footer().then(stocksFooter => {
          const style = `<style>
                          table {
                            font-family: Tahoma;
                            font-size: 8pt;
                          }
                      </style>`;
          const html = `<table width="100%">
                      <thead>
                        <tr style="font-weight: bold;">
                          <td style="color: red; font-size: 24pt; font-weight: bold; text-align: center;">${
                            values.yazdirilacakPaletNo
                          }</td>
                          <td colspan="4">- Numaralı Palet Detayı / Pallet Detail</td>
                          <td style="color: red; font-size: 16pt; font-weight: bold; text-align: center;">${
                            values.yuklemeNumarasi
                          }</td>
                        </tr>
                        <tr style="font-weight: bold;text-align: center;">
                          <td style="border-bottom: 1px solid black;">STOK KOD / STOCK CODE</td>
                          <td style="border-bottom: 1px solid black;">KUTU / BOX NO</td>
                          <td style="border-bottom: 1px solid black;">ÜRETİM / PRODUCTION DATE</td>
                          <td style="border-bottom: 1px solid black;">SKT / EXPIRE DATE</td>
                          <td style="border-bottom: 1px solid black;">LOT NO</td>
                          <td style="border-bottom: 1px solid black;">NET AĞIRLIK WEIGHT(KG)</td>
                          <td style="border-bottom: 1px solid black;">BRÜT/GROSS WEIGHT(KG)</td>
                        </tr>
                      </thead>
                      <tbody>
                      ${rows.join('')}
                      </tbody>
                      <tfoot>
                        <tr style="font-weight: bold;">
                          <td colspan="4" rowspan="3" style="text-align: right; border-top: 1px solid black;"></td>
                          <td style="border-top: 1px solid black;">Total: </td>
                          <td style="text-align: right; border-top: 1px solid black;">${numToTrStr(
                            total.net
                          )}</td>
                          <td style="text-align: right; border-top: 1px solid black;">${numToTrStr(
                            total.brut
                          )}</td>
                        </tr>
                        <tr style="font-weight: bold;">
                          <td>PLT Total:</td>
                          <td colspan="2" style="text-align:right;">${numToTrStr(
                            total.brut + yazdirilacakPaletAgirligiDbNumber
                          )}</td>
                        </tr>
                        <tr style="font-weight: bold;">
                          <td>Total Box:</td>
                          <td colspan="2" style="text-align:right;">${
                            rows.length
                          }</td>
                        </tr>
                        <tr><td colspan="7" style="border-top: 1px solid black;"></td></tr>
                        ${stocksFooter}
                      </tfoot>
                    </table>`;
          printDocument(style + html);
        });

        kontrolListesiSavePalletPrinted({
          record_id: root.$route.params.id,
          pallet_number: values.yazdirilacakPaletNo
        });
      };

      const printedCount = await kontrolListesiPalletPrintedCount({
        record_id: root.$route.params.id,
        pallet_number: values.yazdirilacakPaletNo
      });
      if (printedCount.printed_count >= 1) {
        errorConfirmDialog(
          `Yazdırmak istediğiniz palet daha önce yazdırılmış. Toplam yapılan yazdırma ${printedCount.printed_count} adettir.
        Devam etmek istiyor musunuz?`,
          printCallback
        );
      } else {
        printCallback();
      }
    };

    const palletPrintValidation = () => {
      if (
        palletBoxChecking[values.yazdirilacakPaletNo] < values.paletBasinaKoli
      ) {
        errorConfirmDialog(
          `Paleti tamamlamak için ${
            values.paletBasinaKoli -
            palletBoxChecking[values.yazdirilacakPaletNo]
          } adet koli daha girmelisiniz. Devam etmek istiyor musunuz?`,
          () => {
            handlePalletPrint();
          },
          'Uyarı'
        );
      } else {
        handlePalletPrint();
      }
    };

    const handleLastAccess = () => {
      if (!overlay.value) {
        handleKontrolListesiLastAccess(root.$route.params.id);
      }
    };

    const handleRefreshPage = () => {
      window.location.href = `${root.$baseURL}/kontrol_listesi/v2/${root.$route.params.id}/duzenle`;
    };

    const isEditing = () => {
      return root.$route.params.id > 0;
    };

    const logData = ref({});
    const logModal = ref(false);

    const viewLogs = () => {
      getCheckListChanges(root.$route.params.id).then(data => {
        logData.value = data;
        logModal.value = true;
      });
    };

    //! API CALLS
    const applyAPICalls = async () => {
      try {
        const waitSube = getBranches();
        const waitDepo = getDepots();
        const waitUnits = getStockUnits();

        await Promise.all([waitSube, waitDepo, waitUnits]);
        subeler.value = await waitSube;
        depolar.value = await waitDepo;
        birimler.value = await waitUnits;
      } catch (error) {
        errorConfirmReloadDialog(error);
      }

      if (root.$route.params.id) {
        fetchDetails().then(() => {
          setInterval(
            (function interval() {
              handleLastAccess();
              return interval;
            })(),
            5000
          );
        });
      }
    };

    const changeVersion = () => {
      window.location.href = root.$route.params.id
        ? `${root.$baseURL}/kontrol_listesi/${root.$route.params.id}/duzenle`
        : `${root.$baseURL}/kontrol_listesi/yeni`;
    };

    applyAPICalls();

    return {
      handleSatirEkleButton,
      handleKontrolListesiYazdir,
      handleKontrolListesiExcel,
      handleFormSubmit,
      handleRowPrint,
      handlePalletPrint,
      handlePalletModal,
      handleCopyRecord,
      handleRefreshPage,
      handleBoxNumberCheckingAdd,
      handleBoxNumberCheckingDelete,
      isEditing,
      debouncedHandleDepoChange,
      debouncedHandleAutomateSaving,
      currentSirket,
      values,
      durumlar,
      subeler,
      depolar,
      birimler,
      tableArray,
      quickSearchEvent,
      removeSatir,
      strToNumToStr,
      inputNumericConverter,
      fetchDetails,
      gitnCodeSelected,
      exportExcel,
      palletModal,
      palletStockChecking,
      palletBoxChecking,
      boxNumberChecking,
      oldValues,
      errorDialogOpen,
      viewLogs,
      logModal,
      logData,
      tabloKontrolListesi,
      changeVersion,
      palletPrintValidation,
      generatePartiNo,
      listTypes
    };
  }
};
</script>

<style scoped>
.labels-of-forms {
  @apply w-32 self-start md:self-center mr-4;
}

.form-elements-doubled {
  @apply flex flex-col md:flex-row  mb-4;
}
</style>
