export function nameAndCodeWithLinkRenderer(item, link, prefix) {
  const id = prefix ? item[`${prefix}id`] : item.id;
  const isim = prefix ? item[`${prefix}isim`] : item.isim;
  const kod = prefix ? item[`${prefix}kod`] : item.kod;

  return `
    <span>
      <a
        href="${link}${id}"
        class="underlined-hovered-link"
        tabindex="-1"
        target="_blank"
      >
        ${kod}
      </a>
      - ${isim}
    </span>
  `;
}