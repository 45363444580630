<template>
  <base-input
    v-model="inputAreaValue"
    class="wo-numeric-input"
    :readonly="!isAuthorized || readonly"
    :danger="isErrored"
    :danger-text="validationError"
    @focus="handleFocusSelection"
    @blur="handleBlur"
    @keypress="inputNumericConverter"
    @change="emitChange($event)"
    v-bind="$attrs"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import {
  inputNumericConverter,
  numToTrStr,
  trStrToNum
} from '@/helpers/NumberController';

export default {
  inheritAttrs: false,
  props: {
    value: [String, Number, Object],
    currency: String,
    dangerText: {
      type: String,
      default: ''
    },
    danger: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Boolean,
      default: false
    },
    percentageErrorText: {
      type: String,
      default: 'Yüzdelik değer 0 ila 100 arasında olmalıdır'
    },
    isAuthorized: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    decimal: {
      type: [Number, String],
      default: 2
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    }
  },
  methods: {
    emitChange(value) {
      this.$emit('change', value);
    }
  },
  setup(props, { emit }) {
    const inputAreaValue = ref(`0,${'0'.repeat(props.decimal)}`);
    // #region props value handling
    const formatPropValue = () => {
      if (!props.value) return 0;
      else if (typeof props.value === 'object') return props.value.value;
      else return props.value;
    };
    const initializePropValue = () => {
      if (props.value) {
        const val =
          typeof props.value === 'object' ? props.value.value : props.value;
        if (typeof props.value === 'string') {
          inputAreaValue.value = val;
        } else if (typeof val === 'number') {
          inputAreaValue.value = numToTrStr(val, props.decimal);
        }
      }
    };

    initializePropValue();

    watch(
      () => props.value,
      () => {
        const val = formatPropValue();
        if (!val || typeof val === 'number') {
          inputAreaValue.value = numToTrStr(val, props.decimal);
        } else {
          inputAreaValue.value = val;
        }
      }
    );
    // #endregion

    const handleBlur = () => {
      if (props.min && trStrToNum(inputAreaValue.value) < props.min) {
        inputAreaValue.value = numToTrStr(props.min, props.decimal);
      }
      if (props.max && trStrToNum(inputAreaValue.value) > props.max) {
        inputAreaValue.value = numToTrStr(props.max, props.decimal);
      }
      emit(
        'input',
        numToTrStr(trStrToNum(inputAreaValue.value), props.decimal)
      );
      emit('blur');
    };

    // #region Form validation
    const errorPlaceholder = ref(false);
    const isErrored = computed({
      get() {
        return errorPlaceholder.value || props.danger;
      },
      set(val) {
        errorPlaceholder.value = val;
      }
    });
    ref(false);

    const validationError = computed(() => {
      if (props.percentage && isErrored.value) {
        return props.percentageErrorText;
      }
      if (props.danger) {
        return props.dangerText;
      }
      return '';
    });

    if (props.percentage) {
      watch(
        () => props.value,
        () => {
          if (props.value > 100 || props.value < 0) {
            isErrored.value = true;
            inputAreaValue.value = 0;
            emit('input', 0);
            setTimeout(() => {
              isErrored.value = false;
            }, 4000);
          }
        }
      );
    }
    // #endregion

    const handleFocusSelection = event => {
      event.target.select();
    };

    return {
      inputAreaValue,
      handleBlur,
      inputNumericConverter,
      isErrored,
      validationError,
      handleFocusSelection
    };
  }
};
</script>

<style scoped></style>
