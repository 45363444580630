<template>
  <div class="responsive-margin-2xl flex flex-col 2xl:flex-row">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
