<template>
  <div class="space-y-4">
    <CariFormTabGenelBank />

    <CariFormSectionDivider />

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Katılım Bedeli </template>
        <template #firstElement>
          <wo-float-input v-model="state.participationPrice" class="w-input" />
        </template>
        <template #secondLabel> Risk Limiti </template>
        <template #secondElement>
          <div class="flex space-x-2">
            <wo-float-input v-model="state.riskLimit" />
            <wo-select
              elementClasses="w-26"
              v-model="state.riskLimitCurrency"
              :data="currencies"
            >
              <template #default="{ item }">
                <wo-select-item
                  :itemValue="item"
                  :text="item"
                  :key="`payment-${item}`"
                />
              </template>
            </wo-select>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span
            class="underlined-hovered-link"
            @click="handlePayPlanMiniModule"
          >
            Ödeme Planı
          </span>
        </template>
        <template #firstElement>
          <wo-select :data="payPlans" v-model="state.payPlan">
            <template #defaultValue>
              <wo-select-item itemValue="" text=" - " />
            </template>
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`pay-plan-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel>
          Komisyon Oranı
          <span v-if="authorizationControl('cari_indirim_yapabilir')">
            <br />
            İndirim Oranı %
          </span>
        </template>
        <template #secondElement>
          <wo-int-input v-model="state.commissionRate" />
          <wo-float-input
            class="mt-2"
            v-if="authorizationControl('cari_indirim_yapabilir')"
            v-model="state.discountRate"
            percentage
          />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Ödeme Türü </template>
        <template #firstElement>
          <multiselect
            :value="selectedValues"
            :options="payTypes"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            placeholder=""
            label="isim"
            @select="addElement"
            @remove="removeElement"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length && !isOpen"
                >{{ values.map(a => a.isim).join(', ') }}</span
              ></template
            >
          </multiselect>
        </template>
        <template #secondLabel> Reklam Pay Oranı </template>
        <template #secondElement>
          <wo-float-input
            :min="0"
            :max="100"
            class="w-input"
            decimal="2"
            v-model="state.advertisementShareRate"
          />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span
            class="underlined-hovered-link"
            @click="handleCollectionIntervalMiniModule"
          >
            Tahsilat Aralığı
          </span>
        </template>
        <template #firstElement>
          <wo-select
            v-model="state.collectionInterval"
            :data="collectionIntervals"
          >
            <template #defaultValue>
              <wo-select-item itemValue="0" text=" - " />
            </template>
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`collection-interval-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
        <template #secondLabel> Hesap Kapama Tipi </template>
        <template #secondElement>
          <wo-select
            v-model="state.accountClosingType"
            :data="accountClosingTypes"
          >
            <template #defaultValue>
              <wo-select-item itemValue="" text="-Tanımsız-" />
            </template>
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.id"
                :text="item.isim"
                :key="`account-closing-type-${item.id}`"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <wo-double-form :labelSize="labelSize">
      <template #firstLabel> KDV Oranı </template>
      <template #firstElement>
        <wo-int-input v-model="state.vat" maxlength="2" />
      </template>
      <template #secondLabel> Ödeme Şekilleri </template>
      <template #secondElement>
        <wo-quick-search
          class="relative"
          queryParameters="?tablo=odeme-plani&q="
          @selected="handlePayPlansQuickSearch"
          :itemRenderer="nameAndCodeRenderer"
        />
        <CariFormDeletableList
          class="h-24 overflow-y-scroll"
          :data="state.selectedPayPlans"
          :rootObj="state"
          objKey="selectedPayPlans"
        />
      </template>
    </wo-double-form>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import usePrevalentState from '../../consumables/usePrevalentState';
import { getCollectionIntervals } from '../../helpers/ApiFactory.js';

import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';
import CariFormDeletableList from '../common/CariFormDeletableList.vue';
import CariFormTabGenelBank from './CariFormTabGenelBank.vue';

import {
  getExchangeRates,
  getPayPlan,
  getPayType
} from '@/helpers/ApiController.js';
import { errorDialog } from '@/helpers/errorDialogs';
import { authorizationControl } from '@/consumables/useAuthorizationControl';
import { useVueMultiple } from '@/consumables/useVueMultiple';
import { nameAndCodeRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCode';

export default {
  components: {
    CariFormSectionDivider,
    CariFormTabGenelBank,
    CariFormDeletableList,
    CariFormQuadrupleFormWrapper
  },
  setup(props, { root }) {
    //#region INITIALIZATIONS

    const { labelSize, state, handleDeletableListQuickSearch } =
      usePrevalentState();

    const currencies = ref([]);
    const payPlans = ref([]);
    const payTypes = ref([]);

    const collectionIntervals = ref([]);

    const accountClosingTypes = [
      { id: 1, isim: 'Bakiye Kapama' },
      { id: 2, isim: 'Ay Kapama' },
      { id: 3, isim: 'Vade Kapama' }
    ];

    const returnInitializations = {
      labelSize,
      state,
      currencies,
      payPlans,
      payTypes,
      collectionIntervals,
      accountClosingTypes
    };

    //#endregion

    //#region API CALLS
    const initializeCurrencies = async () => {
      const apiCurrency = await getExchangeRates();
      currencies.value = apiCurrency.kurlar.map(a => a.kod);
      root.$nextTick(() => {
        state.riskLimitCurrency = state.riskLimitCurrency
          ? state.riskLimitCurrency
          : window.anaparabirimi || 'TL';
      });
    };

    const initializePayPlans = async () => {
      try {
        payPlans.value = await getPayPlan();
      } catch (error) {
        errorDialog(error);
      }
    };

    const initializePayTypes = async () => {
      try {
        payTypes.value = await getPayType();
      } catch (error) {
        errorDialog(error);
      }
    };

    const initializeCollectionIntervals = async () => {
      collectionIntervals.value = await getCollectionIntervals();
    };

    const initializeAPICalls = async () => {
      initializeCurrencies();
      initializePayPlans();
      initializePayTypes();
      initializeCollectionIntervals();
    };
    initializeAPICalls();
    //#endregion

    //#region MINIMODULES
    const handlePayPlanMiniModule = () => {
      window.MiniModulPen(700, 300, 'Odeme Planları', 'cari_odeme_planlari');
    };

    const handleCollectionIntervalMiniModule = () => {
      window.ajaxpen(
        600,
        400,
        'Cari Tahsilat Aralığı',
        'cari_tahsilat_araliklari'
      );
    };

    const returnMiniModules = {
      handlePayPlanMiniModule,
      handleCollectionIntervalMiniModule
    };
    //#endregion

    //#region
    const handlePayPlansQuickSearch = item => {
      handleDeletableListQuickSearch(state, 'selectedPayPlans', item);
    };

    const returnQuickSearch = {
      handlePayPlansQuickSearch,
      nameAndCodeRenderer
    };
    //#endregion

    const { selectedValues, removeElement, addElement } = useVueMultiple(
      payTypes,
      state,
      'payTypes',
      'id'
    );

    return {
      authorizationControl,
      selectedValues,
      removeElement,
      addElement,

      ...returnInitializations,

      ...returnMiniModules,

      ...returnQuickSearch
    };
  }
};
</script>

<style lang="scss" scoped></style>
