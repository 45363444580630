<template>
  <div class="space-y-4">
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        Ülke
      </template>
      <template #firstElement>
        <wo-select
          :value="country"
          @input="e => $emit('update:country', e)"
          :data="countries"
        >
          <template #defaultValue>
            <wo-select-item itemValue="0" text="-Seçiniz-" />
          </template>
          <template v-slot:default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.isim"
              :key="`country-${item.id}`"
            />
          </template>
        </wo-select>
      </template>
    </wo-double-form>
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        İl
      </template>
      <template #firstElement>
        <wo-select
          :value="province"
          @input="e => $emit('update:province', e)"
          :data="provinces"
        >
          <template #defaultValue>
            <wo-select-item itemValue="0" text="-Seçiniz-" />
          </template>
          <template v-slot:default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.isim"
              :key="`province-${item.id}`"
            />
          </template>
        </wo-select>
      </template>
    </wo-double-form>
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>
        <span
          class="inline-block underlined-hovered-link"
          @click="handleDistrictsMiniModule"
        >
          İlçe
        </span>
      </template>
      <template #firstElement>
        <wo-select
          :value="district"
          @input="e => $emit('update:district', e)"
          :data="districts"
        >
          <template #defaultValue>
            <wo-select-item itemValue="0" text="-Seçiniz-" />
          </template>
          <template v-slot:default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.isim"
              :key="`district-${item.id}`"
            />
          </template>
        </wo-select>
      </template>
    </wo-double-form>
  </div>
</template>

<script>
import {
  getCountries,
  getProvinces as getProvincesAPI,
  getDistricts as getDistrictsAPI
} from '@/helpers/ApiController';

import { ref, watch } from '@vue/composition-api';

import debounce from 'lodash.debounce';

export default {
  name: 'WOCountryProvinceDistrict',
  props: {
    country: [String, Number],
    province: [String, Number],
    district: [String, Number],
    labelSize: {
      type: String,
      default: 'sm'
    }
  },
  setup(props, { emit }) {
    const countries = ref([]);
    const provinces = ref([]);
    const districts = ref([]);

    const initCountries = async () => {
      countries.value = await getCountries();
    };
    initCountries();

    const debounceTime = 300;

    const getProvinces = async country => {
      provinces.value = await getProvincesAPI(country);
    };

    const debouncedProvinces = debounce(getProvinces, debounceTime);

    const getDistricts = async province => {
      districts.value = await getDistrictsAPI(province);
    };

    const debouncedDistricts = debounce(getDistricts, debounceTime);

    const handleDistrictsMiniModule = () => {
      let districtUrl = 'ilceler';
      if (props.province) {
        districtUrl = `${districtUrl}&il=${props.province}`;
      }
      window.ajaxpen(450, 300, 'İlçeler', districtUrl);
    };

    watch(
      () => props.country,
      async () => {
        provinces.value = [];
        districts.value = [];
        if (+props.country) {
          await debouncedProvinces(props.country);
        }

        if (
          provinces.value.length &&
          !provinces.value.some(a => +a.id === +props.province)
        ) {
          emit('update:province', 0);
        }
      },
      { immediate: true }
    );

    watch(
      () => props.province,
      async () => {
        districts.value = [];
        if (+props.province) {
          await debouncedDistricts(props.province);
        }

        if (
          districts.value.length &&
          !districts.value.some(a => +a.id === +props.district)
        ) {
          emit('update:district', 0);
        }
      },
      { immediate: true }
    );

    return {
      countries,
      provinces,
      districts,
      handleDistrictsMiniModule
    };
  }
};
</script>

<style lang="scss" scoped></style>
