<template>
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="job_application"
    :auth="moduleConfig.auth"
  >
    <JobApplicationsForm />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import JobApplicationsForm from './JobApplicationsForm.vue';

export default {
  components: {
    WOModuleWrapper,
    JobApplicationsForm
  },
  computed: {
    moduleConfig() {
      if (this.$route.params.id) {
        return {
          title: 'İş Başvurusu Düzenle',
          auth: 'is_basvuru_duzenle'
        };
      } else {
        return {
          title: 'İş Başvurusunu Önizle',
          auth: 'is_basvuru_onizle'
        };
      }
    }
  }
};
</script>

<style scoped></style>
