<template functional>
  <div class="wo-table-parent">
    <table
      class="wo-table"
      :class="[data.staticClass || '', data.class]"
      v-bind="data.attrs"
      :style="data.staticStyle"
    >
      <thead class="wo-thead">
        <tr class="wo-tr">
          <slot name="thead" />
        </tr>
      </thead>
      <slot />
    </table>
  </div>
</template>

<script>
export default {
  name: 'WOTable'
};
</script>

<style lang="scss" scoped>
.wo-table-parent {
  border-radius: 5px;
  width: 100%;
  overflow: auto;
  background: white;
  border: 2px solid #f8f8f8;
  .wo-table {
    width: 100%;
    margin: 0;
    .wo-thead {
      width: 100%;
    }
  }
}
</style>
