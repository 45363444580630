<template>
  <div class="space-y-4">
    <CariFormSectionHeader> Açıklama </CariFormSectionHeader>
    <wo-textarea v-model="state.explanation" class="w-full" />

    <CariFormSectionDivider />

    <CariFormSectionHeader> Ekran Notu </CariFormSectionHeader>
    <wo-textarea v-model="state.screenNote" class="w-full" />

    <CariFormSectionDivider />

    <CariFormSectionHeader> Sipariş Notu </CariFormSectionHeader>
    <wo-textarea v-model="state.orderNote" class="w-full" />
  </div>
</template>

<script>
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormSectionDivider from '../common/CariFormSectionDivider.vue';
import usePrevalentState from '../../consumables/usePrevalentState';

export default {
  components: {
    CariFormSectionHeader,
    CariFormSectionDivider
  },
  setup() {
    const { state } = usePrevalentState();

    return {
      state
    };
  }
};
</script>

<style lang="scss" scoped></style>
