<template>
  <div class="space-x-4 inline-block">
    <wo-button
      text="Kurlar"
      :disabled="
        usedExchangeRates[0].kod === mainCurrency &&
        usedExchangeRates.length === 1
      "
      @click="handleExchangeRatesModal(true)"
    />
    <span v-if="exchangeRatesMessage" class="text-danger">
      {{ exchangeRatesMessage }}
    </span>
    <vs-prompt
      @close="handleExchangeRatesModal(false)"
      @cancel="handleExchangeRatesModal(false)"
      @accept="handleExchangeRatesModal('accept')"
      accept-text="Kaydet"
      cancel-text="İptal"
      :active.sync="exchangeRatesModal"
      title="Kurlar"
    >
      <div class="space-y-4 p-1">
        <p class="text-warning">
          Kaydettiğiniz andan itibaren kurlar belirttiğiniz rakamlara
          sabitlenecektir.
        </p>

        <p v-if="!tempExchangeRates.length" class="text-danger">
          Kurları düzenleyebilmek için önce kullanılacak kurları seçmelisiniz.
        </p>

        <template v-for="item in tempExchangeRates">
          <div
            v-if="item.kod !== mainCurrency"
            :key="`${item.kod}`"
            class="flex flex-row"
          >
            <span class="w-20">{{ item.kod }}</span>
            <wo-float-input v-model="item.kur" />
          </div>
        </template>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useCurrency } from '../consumables/useCurrency.js';

export default {
  name: 'OfferExchangeRatesModal',
  setup() {
    const {
      tempExchangeRates,
      usedExchangeRates,
      mainCurrency,
      exchangeRatesMessage
    } = useCurrency();

    const exchangeRatesModal = ref(false);

    const handleExchangeRatesModal = val => {
      if (val === 'accept') {
        for (const idx in tempExchangeRates.value) {
          usedExchangeRates.value[idx].kur = tempExchangeRates.value[idx].kur;
        }
      } else if (
        val &&
        !(
          usedExchangeRates.value.length === 1 &&
          usedExchangeRates.value[0].kod === mainCurrency
        )
      ) {
        exchangeRatesModal.value = true;
      } else if (!val) {
        exchangeRatesModal.value = false;
      }
    };

    return {
      tempExchangeRates,
      usedExchangeRates,
      mainCurrency,
      exchangeRatesMessage,
      exchangeRatesModal,
      handleExchangeRatesModal
    };
  }
};
</script>

<style lang="scss" scoped></style>
