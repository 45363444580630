<template>
  <div class="space-y-4">
    <CariFormSectionHeader>
      Mağaza Ayarları
    </CariFormSectionHeader>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Apache Entegre IP
        </template>
        <template #firstElement>
          <base-input v-model="state.apacheIntegratedIP" />
        </template>
        <template #secondLabel>
          Sipariş Printer
        </template>
        <template #secondElement>
          <base-input v-model="state.orderPrinter" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Kasa Hareket Yolu
        </template>
        <template #firstElement>
          <base-input v-model="state.cashRegisterMovementWay" />
        </template>
        <template #secondLabel>
          Sipariş Veritabanı Bağlantısı
        </template>
        <template #secondElement>
          <div class="flex">
            <wo-select
              v-model="state.orderDatabaseConnection"
              elementClasses="w-40"
              class="mr-4"
              :data="databaseConnections"
            >
              <template #defaultValue>
                <wo-select-item itemValue="" text="-Yok-" />
              </template>
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="`order-db-${item.id}`"
                />
              </template>
            </wo-select>
            <wo-button
              text=".."
              :href="`${$baseURL}/?s=veritabanlari`"
              target="_blank"
            />
          </div>
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Data Veritabanı Bağlantısı
        </template>
        <template #firstElement>
          <div class="flex">
            <wo-select
              v-model="state.dataDatabaseConnection"
              elementClasses="w-40"
              class="mr-4"
              :data="databaseConnections"
            >
              <template #defaultValue>
                <wo-select-item itemValue="" text="-Yok-" />
              </template>
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="`data-db-${item.id}`"
                />
              </template>
            </wo-select>
            <wo-button
              text=".."
              :href="`${$baseURL}/?s=veritabanlari`"
              target="_blank"
            />
          </div>
        </template>
        <template #secondLabel>
          PDKS otomatik
        </template>
        <template #secondElement>
          <div class="space-x-4">
            <vs-radio
              v-model="state.pdksAutomatic"
              vs-name="pdks-automatic"
              vs-value="1"
            >
              Evet
            </vs-radio>
            <vs-radio
              v-model="state.pdksAutomatic"
              vs-name="pdks-automatic"
              vs-value="0"
            >
              Hayır
            </vs-radio>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Tampon Kasa
        </template>
        <template #firstElement>
          <wo-quick-search
            v-model="state.bufferRegisterText"
            :queryParameters="`?tablo=kasalar&tip=9&q=`"
            @selected="handleBufferRegisterQuickSearch"
            :itemRenderer="registerRenderer"
          />
        </template>
        <template #secondLabel>
          Nakit Kasa
        </template>
        <template #secondElement>
          <wo-quick-search
            v-model="state.cashRegisterText"
            :queryParameters="`?tablo=kasalar&tip=1&q=`"
            @selected="handleCashRegisterQuickSearch"
            :itemRenderer="registerRenderer"
          />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Kasiyer Avans Kasası
        </template>
        <template #firstElement>
          <wo-quick-search
            v-model="state.cashierAdvanceRegisterText"
            :queryParameters="`?tablo=kasalar&q=`"
            @selected="handleCashierAdvanceRegisterQuickSearch"
            :itemRenderer="registerRenderer"
          />
        </template>
        <template #secondLabel>
          Parametrelerde Göster
        </template>
        <template #secondElement>
          <div class="space-x-4">
            <vs-radio
              v-model="state.showParameters"
              vs-name="show-parameters"
              vs-value="1"
            >
              Evet
            </vs-radio>
            <vs-radio
              v-model="state.showParameters"
              vs-name="show-parameter"
              vs-value="0"
            >
              Hayır
            </vs-radio>
          </div>
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Çiftlik Kontrol
        </template>
        <template #firstElement>
          <div class="space-x-4">
            <vs-radio
              v-model="state.farmControl"
              vs-name="farm-control"
              vs-value="1"
            >
              Evet
            </vs-radio>
            <vs-radio
              v-model="state.farmControl"
              vs-name="farm-control"
              vs-value="0"
            >
              Hayır
            </vs-radio>
          </div>
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>
  </div>
</template>

<script>
import usePrevalentState from '../../consumables/usePrevalentState';
import { getDatabaseConnections } from '../../helpers/ApiFactory.js';

import { registerRenderer } from '@/helpers/quickSearchListRenderers/qsRegister';

import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

import { ref } from '@vue/composition-api';

export default {
  components: { CariFormSectionHeader, CariFormQuadrupleFormWrapper },
  setup() {
    const { labelSize, state } = usePrevalentState();

    const databaseConnections = ref([]);

    const initializeAPI = async () => {
      databaseConnections.value = await getDatabaseConnections();
    };
    initializeAPI();

    const handleRegisterQuickSearch = (item, key) => {
      if (item.isSelected) {
        state[`${key}ID`] = item.id;
        state[`${key}Text`] = `${item.kod} - ${item.isim}`;
      } else if (!item.isSelected && state[`${key}ID`]) {
        state[`${key}ID`] = '';
        state[`${key}Text`] = '';
      }
    };

    const handleBufferRegisterQuickSearch = item => {
      handleRegisterQuickSearch(item, 'bufferRegister');
    };
    const handleCashRegisterQuickSearch = item => {
      handleRegisterQuickSearch(item, 'cashRegister');
    };
    const handleCashierAdvanceRegisterQuickSearch = item => {
      handleRegisterQuickSearch(item, 'cashierAdvanceRegister');
    };
    return {
      databaseConnections,
      registerRenderer,
      labelSize,
      state,
      handleBufferRegisterQuickSearch,
      handleCashRegisterQuickSearch,
      handleCashierAdvanceRegisterQuickSearch
    };
  }
};
</script>

<style lang="scss" scoped></style>
