<template>
    <div>
        <wo-button 
            text="Kaydet"
            data-id="kaydet"
            class="mr-4"
            @click="saveDocument"
        />
        <wo-button
            text="Listeye Dön"
            data-id="kaydet"
            class="mr-4"
            @click="returnToList"
        />
        <wo-button 
            text="Excel'e Aktar"
            @click="exportExcel"
            v-if="isModelSaved()"
            class="mr-4"
        />
        <wo-button 
            text="PDF'e Aktar"
            @click="exportPDF"
            v-if="isModelSaved()"
        />
        <ModelDesignToTable 
            v-show="false"
            @modelDesignTableEvent="setTableInner($event)"
        />
    </div>
</template>

<script>
import { authorizationControl } from '@/consumables/useAuthorizationControl';
import { downloadPDF, saveModelPlanning } from '../../helpers/modelPlanningApiService';
import { errorDialog } from '@/helpers/errorDialogs';
import ModelDesignToTable from './ModelDesignToTable.vue';
import { ref } from '@vue/composition-api';
export default {
    components: {
        ModelDesignToTable
    },
    setup(_, { root }) {

        const saveDocument = () => {
            saveModelPlanning('design');
        };
        
        const htmlTableInner = ref('');

        const setTableInner = (event) => {
            htmlTableInner.value = event;
        };

        const isModelSaved = () => {
            if (root._route.params.id) {
                return true;
            }
            return false;
        };

        const returnToList = () => {
            if (authorizationControl("model_planlama_liste")) {
                window.location.href = `${root.$baseURL}/?s=model_planlama&model_hane=1`;
            } else {
                errorDialog("Yetkiniz yok!");
            }
        };

        const exportExcel = () => {
            window.open(`data:application/vnd.ms-excel;charset=utf-8,%EF%BB%BF'${encodeURIComponent(htmlTableInner.value)}`);
        };

        const exportPDF = () => {
            downloadPDF(htmlTableInner.value);
        };

        return {
            saveDocument,
            returnToList,
            exportExcel,
            setTableInner,
            isModelSaved,
            exportPDF
        };
    }
};

</script>

<style scoped>

</style>