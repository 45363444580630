<template>
  <div id="kontrolListesiTable">
    <wo-table
      stripe
      :data="tableArray.list"
      hoverFlat
      noDataText=""
      class="minimalize-table-paddings"
    >
      <template slot="thead">
        <wo-th> Sıra </wo-th>
        <wo-th> Satır ID </wo-th>
        <wo-th> Stok </wo-th>
        <wo-th> Miktar </wo-th>
        <wo-th> Brüt Miktar </wo-th>
        <wo-th> Birim </wo-th>
        <wo-th>
          <div class="flex items-center gap-1">
          <span>Palet No </span>
          <i
                title="Otomatik Üret"
                class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true"
                @click="generateKoliNo('paletNo')"
                v-if="tableArray.list.length === 1 && !generatedColumns['paletNo']"
              ></i>
            </div>
        </wo-th>
        <wo-th> <span> Koli No </span>
          <i
                title="Otomatik Üret"
                class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true"
                @click="generateKoliNo('koliNo')"
                v-if="tableArray.list.length === 1 && !generatedColumns['koliNo']"
              ></i>
        </wo-th>
        <wo-th> Koli İçi Miktar </wo-th>
        <wo-th> Lot No </wo-th>
        <wo-th> Son Kullanım Tarih </wo-th>
        <wo-th> İşlemler </wo-th>
      </template>

      <template v-for="(satirData, indextr) in tableData">
        <wo-tr :key="indextr">
          <wo-td>
            <span class="w-full h-full inline-block" @click="handleRowPrint(indextr + itemPerPage * (pageCount - 1)
            )">
              {{ indextr + itemPerPage * (activePage - 1) + 1 }}
            </span>
          </wo-td>
          <wo-td>
            <span class="w-full h-full inline-block font-bold">{{
              satirData.id
            }}</span>
          </wo-td>
          <wo-td>
            <wo-quick-search
              :inputType="'quickSearch'"
              :idKey="indextr"
              v-model="satirData.stok"
              @focus="activeRow = indextr"
              :queryParameters="satirData.stokQuery"
              @selected="
                item =>
                  handleQuickSearch({
                    item,
                    index: (indextr + (localItemPerPage * (activePage - 1)))
                  })
              "
              canReadGitnCode
              @gitnCodeSelected="
                data =>
                  gitnCodeSelected(
                    (indextr + (localItemPerPage * (activePage - 1))),
                    data
                  )
              "
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.stok,
                    'stok',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              :itemRenderer="
                item => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')
              "
              v-if="
                tableArray.list.length ===
                  (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.stok }}
            </span>
          </wo-td>

          <wo-td>
            <ValidationProvider
              name="Miktar"
              rules="notZero"
              v-slot="{ errors }"
            >
              <wo-float-input
                v-model="satirData.miktar"
                class="miktar-small"
                @blur="
                  () =>
                    handleAutomateSaving(
                      satirData.miktar,
                      'miktar',
                      (indextr + (localItemPerPage * (activePage - 1)))
                    )
                "
                :error="errors"
                v-if="
                  tableArray.list.length ===
                    (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                  device === 'desktop'
                "
              />
              <span class="w-full h-full inline-block" v-else>
                {{ satirData.miktar }}
              </span>
            </ValidationProvider>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.brutMiktar"
              class="miktar-small"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.brutMiktar,
                    'brutMiktar',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                  (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.brutMiktar }}
            </span>
          </wo-td>
          <wo-td>
            <wo-select
              v-model="satirData.birim"
              :data="birimler"
              elementClasses="w-28"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.birim,
                    'birim',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                  (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            >
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="window.mobile ? item.kod : item.isim"
                  :key="'birim' + item.id"
                />
              </template>
            </wo-select>
            <span class="w-full h-full inline-block" v-else>
              {{
                birimler.find(
                  birim => parseInt(birim.id) === parseInt(satirData.birim)
                ) ? birimler.find(
                  birim => parseInt(birim.id) === parseInt(satirData.birim)
                ).isim : ''
              }}
            </span>
          </wo-td>

          <wo-td>
            <div class="flex gap-1 items-center">
            <vs-input
              v-model="satirData.paletNo"
              class="wo-numeric-input w-28"
              @keypress="inputNumericConverter"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.paletNo,
                    'paletNo',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.paletNo }}
            </span>
            </div>
          </wo-td>
          <wo-td class="flex items-center gap-1">
            <vs-input
              v-model="satirData.koliNo"
              class="wo-numeric-input w-28"
              @keypress="inputNumericConverter"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.koliNo,
                    'koliNo',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.koliNo }}
            </span>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.koliIciMiktar"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.koliIciMiktar,
                    'koliIciMiktar',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.koliIciMiktar }}
            </span>
          </wo-td>
          <wo-td>
            <vs-input
              autocomplete="off"
              v-model="satirData.lotNo"
              v-validate="'required'"
              name="İsim"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.lotNo,
                    'lotNo',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.lotNo }}
            </span>
          </wo-td>
          <wo-td>
            <wo-date-picker
              v-model="satirData.sonKullanimTarih"
              @blur="
                () =>
                  handleAutomateSaving(
                    satirData.sonKullanimTarih,
                    'sonKullanimTarih',
                    (indextr + (localItemPerPage * (activePage - 1)))
                  )
              "
              v-if="
                tableArray.list.length ===
                  (indextr + (localItemPerPage * (activePage - 1))) + 1 ||
                device === 'desktop'
              "
            />
            <span class="w-full h-full inline-block" v-else>
              {{ satirData.sonKullanimTarih }}
            </span>
          </wo-td>
          <wo-td>
            <div class="flex flex-row space-x-4">
              <wo-button
                data-id="satir_sil"
                class="text-base px-4 py-2"
                @click="
                  removeItem(indextr + (localItemPerPage * (activePage - 1)))
                "
                color="danger"
                tabindex="-1"
                :text="RemoveIcon"
              />
              <wo-button
                size="small"
                class="ml-1 text-base px-4 py-2"
                color="warning"
                tabindex="-1"
                @click="handleRowPrint((indextr + (localItemPerPage * (activePage - 1))))"
                :text="PrinterIcon"
              />
            </div>
          </wo-td>
        </wo-tr>
      </template>
    </wo-table>
    <div class="flex gap-2 mt-2">
      <wo-button v-for="pageNumber in pageCount" :text="String(pageNumber)" :key="pageNumber"
      :id="`page-${pageNumber}-button`"
        @click="activePage = pageNumber" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { inputNumericConverter } from '@/helpers/NumberController';
import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';
import detectDevice from '@/helpers/detectDevice';
import { computed, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { factory } from '@/helpers/ApiController';

export default {
  props: {
    tableArray: Object,
    removeItem: Function, // call as removeSatir(index) click event
    birimler: Array,
    depo: [String, Number],
    tarih: String,
    sube: [String, Number],
    itemPerPage: Number,
  },

  setup(props, { emit }) {
    const handleQuickSearch = data => {
      emit('quickSearchEvent', data);
    };

    const emitGitnSelected = (indextr, data) => {
      emit('gitnCodeSelected', { indextr, data });
    };

    const debouncedGitnSelected = debounce(obj => {
      emitGitnSelected(obj.indextr, obj.data);
    }, 100);

    const gitnCodeSelected = (indextr, data) => {
      debouncedGitnSelected({ indextr, data });
    };

    const handleAutomateSaving = (data, column, indextr) => {
      emit('handleAutomateSaving', { data, column, indextr });
    };

    const handleRowPrint = indextr => {
      emit('handleRowPrint', indextr);
    };

    const tableData = ref([]);

    const localItemPerPage = computed(() => props.itemPerPage);

    const pageCount = computed(() => {
      return Math.ceil(
          props.tableArray.list.length / props.itemPerPage
        );
    });
    const activePage = ref(1);

    const handlePageChange = (pageNumber) => {
      tableData.value = [];
      for (let i = 0; i < props.tableArray.list.length; i++) {
        if (i >= (pageNumber - 1) * props.itemPerPage && i < pageNumber * props.itemPerPage) {
          tableData.value.push(props.tableArray.list[i]);
        }
      }
    };

    // compare the current page with the new page
    watch(props.tableArray.list, (newList) => {
      const newLength = newList.length;
      const newPage = Math.ceil(newLength / props.itemPerPage);
      activePage.value = newPage;
      handlePageChange(activePage.value);
      const activePageButton = document.getElementById(`page-${activePage.value}-button`);
      if (activePageButton) {
        activePageButton.click();
      }
    });

    watch(
      () => activePage.value,
      () => {
        handlePageChange(activePage.value);
      }
    );

    watch(() => localItemPerPage.value, () => {
      handlePageChange(activePage.value);
    });

    const device = ref('');

    const generatedColumns = reactive({
      koliNo: false,
      paletNo: false,
    });

    const generateKoliNo = (column) => {
      const keys = {
        koliNo: {endPoint: 'koli-number', param: 'koli_no'},
        paletNo: {endPoint: 'palet-number', param: 'palet_no'},
      };
      const endPoint = keys[column].endPoint;
      factory.get(`/modules/checklist/${endPoint}`, {
        params: {
        [keys[column].param]: tableData.value[0][column],
      }
      }).then(response => {
        const { data } = response;
        tableData.value[0][column] = data;
        generatedColumns[column] = true;
      });
    };

    onMounted(() => {
      device.value = detectDevice();
      // activePage.value = 1;
    });

    const PrinterIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>`;

    const RemoveIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`;

    return {
      nameAndCodeWithLinkRenderer,
      inputNumericConverter,
      handleQuickSearch,
      gitnCodeSelected,
      handleAutomateSaving,
      handleRowPrint,
      PrinterIcon,
      RemoveIcon,
      window,
      device,
      pageCount,
      handlePageChange,
      tableData,
      activePage,
      localItemPerPage,
      generateKoliNo,
      generatedColumns,
    };
  }
};
</script>

<style lang="scss">
@media (max-width: 420px) {
  .minimalize-table-paddings .wo-th,
  .minimalize-table-paddings .wo-td {
    padding: 5px;
  }

  .wo-numeric-input.miktar-small {
    width: 5rem !important;
  }
}

#kontrolListesiTable {
  .wo-table-parent {
    // max-height: 200px;
    // overflow-y: scroll;
    scroll-padding-top: 1px;
  }
}
</style>
<div class="page-numbers">
