<template>
  <div>
    <select
      class="wo-select"
      @input="(event) => (selectVal = event.target.value)"
      :value="selectVal"
      :class="elementClasses"
      v-bind="$attrs"
      v-on="listeners"
    >
      <slot name="defaultValue" />
      <template v-for="(item, index) in data">
        <slot v-bind:item="item" v-bind:index="index" />
      </template>
    </select>
    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div
        v-if="error && error.length"
        class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
        key="danger"
      >
        <span class="span-text-validation">{{ error[0] }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
export default {
  name: "WOSelect",
  inheritAttrs: false,
  props: {
    value: [String, Number, Boolean],
    data: {
      type: Array,
      default: () => [],
    },
    elementClasses: String,
    error: Array,
    valueType: String
  },
  setup(props, { emit }) {
    const selectVal = computed({
      get: () => {
        return (props.valueType === "number" ? parseInt(props.value) : props.value) || 0;
      },
      set: (e) => {
        emit("input", e);
      },
    });

    const beforeEnter = (el) => {
      el.style.height = 0;
    };
    const enter = (el, done) => {
      const h = el.scrollHeight;
      el.style.height = `${h}px`;
      done();
    };
    const leave = (el) => {
      el.style.height = "0px";
    };

    return {
      selectVal,
      beforeEnter,
      enter,
      leave,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (evt) => {
          this.$emit("input", evt.target.value);
        },
        focus: (evt) => {
          this.$emit("focus", evt);
        },
        blur: (evt) => {
          this.$emit("blur", evt);
        },
      };
    },
  },
};
</script>

<style scoped></style>
