/* global __webpack_public_path__:readable */
/* exported __webpack_public_path__ */

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: __webpack_public_path__,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // {
    //   path: '',
    //   redirect: '/talha'
    // },
    // {
    //   path: '/talha',
    //   component: {
    //     render(c) {
    //       return c('router-view');
    //     }
    //   },
    //   children: [
    // {
    //   path: '',
    //   name: 'home',
    //   component: () => import('@/views/index/Home.vue'),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/maliyet_analiz/yeni',
      name: 'maliyet-analiz-yeni',
      component: () =>
        import(
          '@/views/MaliyetAnaliz/MaliyetAnalizForm/WrapperMaliyetAnalizForm.vue'
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/uretim_planlama',
      name: 'uretim-planlama',
      component: () =>
        import(
          '@/views/UretimPlanlama/Form/WrapperUretimPlanlama.vue'
        ),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/maliyet_analiz/:id/duzenle',
      name: 'maliyet-analiz-duzenle',
      component: () =>
        import(
          '@/views/MaliyetAnaliz/MaliyetAnalizForm/WrapperMaliyetAnalizForm.vue'
        ),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/teklifler/yeni',
      name: 'teklifler-yeni',
      component: () =>
        import('@/views/Teklifler/TekliflerForm/WrapperTekliflerForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/teklifler/:id/duzenle',
      name: 'teklifler-duzenle',
      component: () =>
        import('@/views/Teklifler/TekliflerForm/WrapperTekliflerForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/teklifler/:cost_analysis_id/maliyet_analiz',
      name: 'teklifler-maliyet-analiz',
      component: () =>
        import('@/views/Teklifler/TekliflerForm/WrapperTekliflerForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cari/yeni',
      name: 'cari-yeni',
      component: () => import('@/views/Cari/Form/CariFormWrapper.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cari/:id/duzenle',
      name: 'cari-duzenle',
      component: () => import('@/views/Cari/Form/CariFormWrapper.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cari/:id/kopyala',
      name: 'cari-kopyala',
      component: () => import('@/views/Cari/Form/CariFormWrapper.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/kontrol_listesi/v2/yeni',
      name: 'kontrol-listesi-v2-yeni',
      component: () =>
        import(
          '@/views/KontrolListesi/KontrolListesiForm/WrapperKontrolListesi.vue'
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/kontrol_listesi/v2/:id/duzenle',
      name: 'kontrol-listesi-v2-duzenle',
      component: () =>
        import(
          '@/views/KontrolListesi/KontrolListesiForm/WrapperKontrolListesi.vue'
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/kontrol_listesi/yeni',
      name: 'kontrol-listesi-yeni',
      component: () =>
        import(
          '@/views/KontrolListesi-v1/KontrolListesiForm/WrapperKontrolListesi.vue'
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/kontrol_listesi/:id/duzenle',
      name: 'kontrol-listesi-duzenle',
      component: () =>
        import(
          '@/views/KontrolListesi-v1/KontrolListesiForm/WrapperKontrolListesi.vue'
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/job_listing/yeni',
      name: 'job-listing-yeni',
      component: () =>
        import('@/views/JobListing/Form/WrapperJobListingForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/job_listing/:id/duzenle',
      name: 'job-listing-duzenle',
      component: () =>
        import('@/views/JobListing/Form/WrapperJobListingForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/job_application/:id/duzenle',
      name: 'job-application-duzenle',
      component: () =>
        import('@/views/JobApplications/Form/WrapperJobApplicationsForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/model_planlama/yeni',
      name: 'model-planlama-yeni',
      component: () =>
        import('@/views/ModelPlanlama/Form/WrapperModelPlanlama.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/model_planlama/:id/duzenle',
      name: 'model-planlama-duzenle',
      component: () =>
        import('@/views/ModelPlanlama/Form/WrapperModelPlanlama.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
  // }
  // ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
