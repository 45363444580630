import { factory } from '@/helpers/ApiController.js';

import { dateFromServer } from '@/helpers/serverToClientConversion';
import { numToTrStr, trStrToNum } from '@/helpers/NumberController';
import { useCurrency } from '../consumables/useCurrency.js';
import { detailsFromClient } from '@/helpers/clientToServerConversion.js';

const { mainCurrency, usedExchangeRates } = useCurrency();

export function calculateOfferRow(rows, kurlar, masrafSepetiID, changedArea) {
  const url = 'teklif/calculate';
  const checkRowExists = rows.length > 0;
  const checkExchangeRatesExist = kurlar.length > 0;
  if (checkRowExists && checkExchangeRatesExist) {
    const data = {
      toplam_miktar: 0,
      masraf_sepeti_id: masrafSepetiID,
      rows,
      kurlar: {},
      change: changedArea
    };
    for (const kurItem of kurlar) {
      data.kurlar[kurItem.kod] = trStrToNum(kurItem.kur);
    }
    return factory.post(url, data);
  }
  return new Promise(() => ({
    data: {
      rows: []
    }
  }));
}

export async function getOfferTargetByConcept(id) {
  const url = `teklif/target/concept/${id}`;
  const { data } = await factory.get(url);
  return data;
}

export async function getOfferPrintHTML(offerID, designID) {
  const url = `teklif/print/${offerID}`;
  const { data } = await factory.get(url, {
    params: { dizayn_id: designID }
  });
  return data;
}

export async function getOfferTargetByPerson(id) {
  const url = `teklif/target/person/${id}`;
  const { data } = await factory.get(url);
  return data;
}

export async function getOfferTargetByCompany(id) {
  const url = `teklif/target/cari/${id}`;
  const { data } = await factory.get(url);
  return data;
}

// * DUZENLE DETAY ===========================================

const offerTableObject = () => {
  const newLine = {
    cariSepet: '-',
    basketID: 0,
    stokID: '',
    stokKod: '',
    stokAdi: '',
    lotlar: [],
    lotNo: 0,
    tekMiktari: 0,
    birim: '0',
    maliyet: '0,00',
    masrafSepetiMaliyet: 0,
    nihaiMaliyet: 0,
    nihaiMaliyetKur: {},
    marj: 0,
    minimumMarj: 0,
    maximumMarj: 0,
    maliyetAnalizSatirID: 0,
    sevkMiktari: '',
    tekBirimFiyat: 0,
    isk1: 0,
    isk2: 0,
    isk3: 0,
    isk4: 0,
    isk5: 0,
    isk6: 0,
    netBirimFiyat: 0,
    netBirimFiyatKDV: 0,
    maliyetTutar: 0,
    maliyetTutarKur: {},
    satisTutar: 0,
    satisTutarKur: {},
    satisTutarKDV: 0,
    kz: '0,00',
    kdv: ''
  };

  for (const kurItem of usedExchangeRates.value) {
    newLine.nihaiMaliyetKur[kurItem.kod] = '0,00';
    newLine.maliyetTutarKur[kurItem.kod] = '0,00';
    newLine.satisTutarKur[kurItem.kod] = '0,00';
  }

  return newLine;
};

const assignCalculatedRows = (row, newCalcs) => {
  row.tekMiktari = numToTrStr(newCalcs.miktar);
  row.maliyet = numToTrStr(newCalcs.maliyet);
  row.masrafSepetiMaliyet = numToTrStr(newCalcs.urun_basi_maliyet);
  row.nihaiMaliyet = numToTrStr(newCalcs.nihai_maliyet);
  row.marj = numToTrStr(newCalcs.marj);
  row.tekBirimFiyat = numToTrStr(newCalcs.brut_fiyat);
  row.isk1 = numToTrStr(newCalcs.isk1);
  row.isk2 = numToTrStr(newCalcs.isk2);
  row.isk3 = numToTrStr(newCalcs.isk3);
  row.isk4 = numToTrStr(newCalcs.isk4);
  row.isk5 = numToTrStr(newCalcs.isk5);
  row.isk6 = numToTrStr(newCalcs.isk6);
  row.iskontoOran = numToTrStr(newCalcs.iskonto_oran);
  row.netBirimFiyat = numToTrStr(newCalcs.net_fiyat);
  row.netBirimFiyatKDV = numToTrStr(newCalcs.net_fiyat_kdvli);
  row.satisTutar = numToTrStr(newCalcs.satis_tutar);
  row.kz = numToTrStr(newCalcs.satir_karzarar);
  row.maliyetTutar = numToTrStr(newCalcs.maliyet_tutar);

  for (const kurItem of usedExchangeRates.value) {
    if (kurItem.kod !== mainCurrency) {
      row.nihaiMaliyetKur[kurItem.kod] = numToTrStr(
        newCalcs.kurlara_gore_birim_fiyatlar[kurItem.kod]
      );
      row.maliyetTutarKur[kurItem.kod] = numToTrStr(
        newCalcs.kurlara_gore_maliyet_tutarlar[kurItem.kod]
      );
      row.satisTutarKur[kurItem.kod] = numToTrStr(
        newCalcs.kurlara_gore_kdvsiz_net_satislar[kurItem.kod]
      );
    }
  }

  row.satisTutarKDV = numToTrStr(newCalcs.tutar);
};

const assignRemainingRows = (row, newData) => {
  row.kdv = newData.kdv_toptan;
  row.minimumMarj = newData.minimum_marj ? parseFloat(newData.minimum_marj) : 0;
  row.maximumMarj = newData.maksimum_marj
    ? parseFloat(newData.maksimum_marj)
    : 0;
  row.maliyetAnalizSatirID = newData.maliyet_analiz_satir_id;
  row.stokID = newData.stok_id;
  row.stokKod = newData.stok_kod;
  row.stokAdi = newData.stok_isim;
  row.lotlar = newData.lotlar || [];
  row.lotNo = newData.lot_skt || 0;
  row.birim = newData.birim;
};

export async function getOfferDetails(id) {
  const url = `offer/${id}`;
  const { data } = await factory.get(url);

  const offer = data.teklif;

  const branchDetails = {
    id: data.sube_id,
    name: data.sube_isim
  };

  const expenseBasket = {
    id: parseInt(offer.masraf_sepeti_id),
    name: data.masraf_sepeti_isim
  };

  const formValues = {
    teklifVeren: data.teklif_veren_adsoyad,
    teklifVerenName: data.teklif_veren_adsoyad,
    teklifVerenID: offer.teklif_veren,
    karsilayanDepo: data.depo_adi,
    karsilayanDepoId: offer.depo,
    teklifSekli: offer.tip,
    teklifBilgilendirme: !!parseInt(offer.bilgilendirme),
    planlananTeslimatTarihi: dateFromServer(offer.planlanan_teslim_tarihi),
    teklifTarihi: dateFromServer(offer.teklif_tarihi),
    gecerlilikTarihi: dateFromServer(offer.gecerlilik_tarihi),
    stokFazlasi: !!parseInt(offer.stok_fazlasi),
    seri: offer.seri,
    sira: offer.sira,
    konu: offer.konu,
    odemeTuru: offer.odeme_tur,
    odemePlani: offer.odeme_plani,
    aciklama: offer.aciklama
  };

  const editExchangeRates = offer.kurlar === '[]' ? [] : offer.kurlar;

  const addedReadersArray = Object.entries(data.okuyabilenler).map(a => {
    return {
      id: a[0],
      isim: a[1],
      isAddedToReaders: true
    };
  });

  const teklifGonderilecekFirmalarData = [];
  for (const idx in data.gonderilen_kisiler) {
    teklifGonderilecekFirmalarData.push({});
    const row = teklifGonderilecekFirmalarData[idx];
    const recievingRow = data.gonderilen_kisiler[idx];
    row.cari_id = recievingRow.cari_id;
    row.id = recievingRow.id;
    row.konsept = recievingRow.konsept;
    row.rehber_id = recievingRow.id;
    row.teklif_yetkili = recievingRow.teklif_yetkili;
    row.teklif_yetkili_mail = recievingRow.teklif_yetkili_mail;
    row.unvan = recievingRow.unvan;
  }

  const editTableDetails = [];
  for (const detailRow of offer.detaylar) {
    const newLength = editTableDetails.push(offerTableObject());
    const currentRow = editTableDetails[newLength - 1];
    assignCalculatedRows(currentRow, detailRow);
    assignRemainingRows(currentRow, detailRow);
  }

  return {
    branchDetails,
    expenseBasket,
    formValues,
    editExchangeRates,
    addedReadersArray,
    teklifGonderilecekFirmalarData,
    editTableDetails,
    orderID: offer.siparis_id,
    logs: offer.loglar,
    teklifAsamasi: offer.asama
  };
}

// * MALIYET ANALIZ ==================================================

export async function getCostAnalysisDetails(id) {
  try {
    const url = `teklif/maliyet_analiz/${id}`;
    const { data } = await factory.get(url);
    const tableData = [];
    for (const detail of data.detaylar) {
      const newLength = tableData.push(offerTableObject());
      const row = tableData[newLength - 1];
      assignRemainingRows(row, detail);
      row.maliyet = numToTrStr(detail.maliyet);
    }

    return {
      offer: data.teklif,
      tableData
    };
  } catch (error) {
    console.log(error);
  }
}

// * KAYDET ==================================================

const keyToValueArray = list => {
  const obj = {};
  for (const row of list) {
    obj[row.kod] = numToTrStr(row.kur);
  }
  return obj;
};

const saveOfferProcessing = processibles => {
  const {
    expenseBasket,
    formValues,
    teklifGonderilecekFirmalarData,
    addedReadersArray,
    tableArray,
    showExtraIskonto,
    teklifAsamasi,
    usedExchangeRates,
    offerID
  } = processibles;

  const resultObj = {
    masraf_sepeti_id: expenseBasket.id ? expenseBasket.id : 0,
    teklif_veren: formValues.teklifVerenID,
    depo: formValues.karsilayanDepoId,
    teklif_tip: formValues.teklifBilgilendirme ? 1 : 0,
    tip: formValues.teklifSekli,
    planlanan_teslimat_tarihi: formValues.planlananTeslimatTarihi,
    tarih: formValues.teklifTarihi,
    gecerlilik_tarihi: formValues.gecerlilikTarihi,
    stok_fazlasi: formValues.stokFazlasi ? 1 : 0,
    seri: formValues.seri,
    sira: formValues.sira,
    konu: formValues.konu,
    gonderilecekler: detailsFromClient(
      teklifGonderilecekFirmalarData,
      'rehber_id',
      false
    ),
    teklif_gorebilecekler: detailsFromClient(addedReadersArray, 'id', false),
    odeme_plani: formValues.odemePlani,
    odeme_tur: formValues.odemeTuru,
    maliyet_analiz_satir_id: detailsFromClient(
      tableArray,
      'maliyetAnalizSatirID',
      false
    ),
    sevk_miktar: detailsFromClient(tableArray, 'sevkMiktari', false),
    stok_id: detailsFromClient(tableArray, 'stokID', false),
    lotno: detailsFromClient(tableArray, 'lotNo', false),
    miktar: detailsFromClient(tableArray, 'tekMiktari', false),
    birim: detailsFromClient(tableArray, 'birim', false),
    maliyet: detailsFromClient(tableArray, 'maliyet', false),
    marj: detailsFromClient(tableArray, 'marj', false),
    brut_fiyat: detailsFromClient(tableArray, 'tekBirimFiyat', false),
    iskonto1: detailsFromClient(tableArray, 'isk1', false),
    iskonto2: showExtraIskonto
      ? detailsFromClient(tableArray, 'isk2', false)
      : undefined,
    iskonto3: showExtraIskonto
      ? detailsFromClient(tableArray, 'isk3', false)
      : undefined,
    iskonto4: showExtraIskonto
      ? detailsFromClient(tableArray, 'isk4', false)
      : undefined,
    iskonto5: showExtraIskonto
      ? detailsFromClient(tableArray, 'isk5', false)
      : undefined,
    iskonto6: showExtraIskonto
      ? detailsFromClient(tableArray, 'isk6', false)
      : undefined,
    iskontoOran: detailsFromClient(tableArray, 'iskontoOran', false),
    net_fiyat: detailsFromClient(tableArray, 'netBirimFiyat', false),
    tutar: detailsFromClient(tableArray, 'satisTutarKDV', false),
    aciklama: formValues.aciklama,
    asama: `${teklifAsamasi}`,
    toplam_tutar: '',
    kurlar: keyToValueArray(usedExchangeRates)
  };

  if (offerID) {
    resultObj.duzenleme_kaydet = 1;
    resultObj.teklif_id = offerID;
  }

  return resultObj;
};

export async function saveOffer(processibles) {
  const url = 'offer';
  const resultObj = saveOfferProcessing(processibles);

  if (resultObj.teklif_id) {
    const { data } = await factory.put(
      `${url}/${resultObj.teklif_id}`,
      resultObj
    );
    return data;
  } else {
    const { data } = await factory.post(url, resultObj);
    return data;
  }
}
