import { gitnCodeInformation } from '@/helpers/ApiController.js';

const applicationIdentifiers = ['00', '01', '10', '17', '240', '3102', '3302'];
const RegexForDots = new RegExp('\\.', 'g');
const RegexForCommos = new RegExp('\\,', 'g');

function GtinSplit(gitnCode) {
  return gitnCode
    .replace(/\/\*\//g, ' ')
    .replace(/\s\s+/g, ' ')
    .split(' ');
}

export function shouldBeChecked(gtinCode, splitedString = null) {
  splitedString = splitedString ? splitedString : GtinSplit(gtinCode);
  const size = Math.ceil(splitedString.length / 2);
  let count = 0;

  for (let i = 0; i <= size; i++) {
    const deger = splitedString[i * 2];
    if (
      typeof deger !== 'undefined' &&
      applicationIdentifiers.includes(deger) !== false
    ) {
      count++;
    }
  }

  return count >= 2;
}

function getValueFromArr(splitedString, index) {
  if (index >= 0 && splitedString[index]) {
    return splitedString[index];
  }

  return '';
}

function miktarValue(miktarString, decimal) {
  miktarString = miktarString
    .replace(RegexForCommos, '')
    .replace(RegexForDots, '')
    .slice(0, 6); // gitn 3102 ve 3302 kodu için 6 hane gerekiyor ve 2 decimal olarak alınıyor ileride farklı decimal hanelerin desteği verilmesi planlanırsa ona göre düzeltme yapılmalı.
  if (miktarString && miktarString > 0) {
    const miktar = parseInt(miktarString).toString();
    const miktarDecimal = miktar.slice(-2);
    const miktarInteger = miktar.slice(0, miktar.length - decimal);

    return parseFloat(`${miktarInteger}.${miktarDecimal}`);
  }

  return 0;
}

function getVariables(splitedString) {
  const obj = {
    barcode: { index: -1, value: '' },
    refno: { index: -1, value: '' },
    lotno: { index: -1, value: '' },
    skt: { index: -1, value: '' },
    miktar: { index: -1, value: '' },
    brut_miktar: { index: -1, value: '' },
    koli_no: { index: -1, value: '' }
  };

  obj.barcode.index =
    splitedString.indexOf('01') !== -1 ? splitedString.indexOf('01') + 1 : -1;
  obj.refno.index =
    splitedString.indexOf('240') !== -1 ? splitedString.indexOf('240') + 1 : -1;
  obj.lotno.index =
    splitedString.indexOf('10') !== -1 ? splitedString.indexOf('10') + 1 : -1;
  obj.skt.index =
    splitedString.indexOf('17') !== -1 ? splitedString.indexOf('17') + 1 : -1;
  obj.miktar.index =
    splitedString.indexOf('3102') !== -1
      ? splitedString.indexOf('3102') + 1
      : -1;
  obj.brut_miktar.index =
    splitedString.indexOf('3302') !== -1
      ? splitedString.indexOf('3302') + 1
      : -1;
  obj.koli_no.index =
    splitedString.indexOf('00') !== -1 ? splitedString.indexOf('00') + 1 : -1;

  obj.barcode.value = getValueFromArr(splitedString, obj.barcode.index);
  obj.refno.value = getValueFromArr(splitedString, obj.refno.index);
  obj.lotno.value = getValueFromArr(splitedString, obj.lotno.index);
  obj.skt.value =
    obj.skt.index >= 0 && splitedString[obj.skt.index]
      ? `20${splitedString[obj.skt.index].substr(0, 2)}-${splitedString[
          obj.skt.index
        ].substr(2, 2)}-${splitedString[obj.skt.index].substr(4, 2)}`
      : '';
  obj.miktar.value = miktarValue(
    getValueFromArr(splitedString, obj.miktar.index),
    2
  );
  obj.brut_miktar.value = miktarValue(
    getValueFromArr(splitedString, obj.brut_miktar.index),
    2
  );
  obj.koli_no.value = getValueFromArr(splitedString, obj.koli_no.index);

  return obj;
}

/*
applicationIdentifiers gtin kodlarından gelmektedir. 00 kodu gtin kodlarında mevcut değil ve koli numarasını ifade etmektedir.
*/
export function GitnCodeReader(gtinCode) {
  const splitedString = GtinSplit(gtinCode);
  if (shouldBeChecked(gtinCode, splitedString) === false) {
    return true;
  }

  const variables = getVariables(splitedString);

  const params = {
    barcode: variables.barcode.value,
    refno: variables.refno.value,
    skt: variables.skt.value,
    lotno: variables.lotno.value,
    miktar: variables.miktar.value,
    brut_miktar: variables.brut_miktar.value,
    koli_no: variables.koli_no.value
  };

  return gitnCodeInformation(params);
}
