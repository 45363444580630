<template>
  <div class="flex flex-wrap">
    <template v-for="(button, index) in buttonsMap">
      <wo-button
        v-if="button.visible() && button.text === 'Kaydet'"
        :key="`buttons-${index}`"
        :data-id="button.dataId"
        :text="button.text"
        class="mr-4 mb-4"
        @click="button.handler"
        :disabled="saveButtonDisabled"
      />
      <wo-button
        v-if="button.visible() && button.text !== 'Kaydet'"
        :key="`buttons-${index}`"
        :data-id="button.dataId"
        :text="button.text"
        class="mr-4 mb-4"
        @click="button.handler"
      />
    </template>
    <ModelPlanlamaToTable
      v-show="false"
      :stateKey="stateKey"
      @htmlTable:update="htmlTable = $event"
      @htmlTableInner:update="htmlTableInner = $event"
      :materialCastingListTable="materialCastingListTable"
    />
    <ModelPlanlamaCostTableVue
      v-show="false"
      :stateKey="stateKey"
      @costTableEvent="costTable = $event"
    />
    <PurchaseOrderModal
      :stateKey="stateKey"
      :purchaseOrderModal="purchaseOrderModal"
      @purchaseOrderModal="purchaseOrderModal = $event"
    />
    <DepotOrderModal
      :stateKey="stateKey"
      :depotOrderModal="depotOrderModal"
      @depotOrderModal="depotOrderModal = $event"
    />
    <ProductionSlipModal
      v-if="productionSlipModal"
      :stateKey="stateKey"
      :productionSlipModal="productionSlipModal"
      @productionSlipModal="productionSlipModal = $event"
      :stockToProductionSlip="stockToProductionSlip"
    />
    <ConsumptionSlipModal
      :stateKey="stateKey"
      :consumptionSlipModal="consumptionSlipModal"
      @consumptionSlipModal="consumptionSlipModal = $event"
    />
    <InvoiceModal
      :invoiceModal="invoiceModal"
      @invoiceModal="invoiceModal = $event"
      :stateKey="stateKey"
    />
    <VariantSlipModal
      :variantSlipModal="variantSlipModal"
      @variantSlipModal="variantSlipModal = $event"
      :recipesForVariantSlip="recipesForVariantSlip"
    />
    <linked-stock-movements-modal
      :stockMovements="stockMovements"
      :stockMovementsModal="stockMovementsModal"
      @stockMovementsModal="stockMovementsModal = $event"
    />
  </div>
</template>

<script>
import useCompany from '@/consumables/useCompany';
import {
  downloadPDF,
  saveModelPlanning
} from '../../helpers/modelPlanningApiService';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { computed, onMounted, onUpdated, ref } from '@vue/composition-api';
import { authorizationControl } from '@/consumables/useAuthorizationControl';
import ModelPlanlamaToTable from './ModelPlanlamaToTable.vue';
import ModelPlanlamaCostTableVue from './ModelPlanlamaCostTable.vue';
import PurchaseOrderModal from './Modals/PurchaseOrderModal.vue';
import DepotOrderModal from './Modals/DepotOrderModal.vue';
import ProductionSlipModal from './Modals/ProductionSlipModal.vue';
import ConsumptionSlipModal from './Modals/ConsumptionSlipModal.vue';
import InvoiceModal from './Modals/InvoiceModal.vue';
import LinkedStockMovementsModal from './Modals/LinkedStockMovementsModal.vue';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryConfirmDialog } from '@/helpers/primaryDialogs';
import { getStockRecipeDetails } from '@/helpers/ApiController';
import { getValueById } from '@/helpers/dataFilterHelper';
import VariantSlipModal from './Modals/VariantSlipModal.vue';
import { linkedStockMovements } from '@/views/ModelPlanlama/Form/helpers/buttonHandlers';
import { number_format, trStrToNum } from '@/helpers/NumberController';

export default {
  components: {
    ModelPlanlamaToTable,
    PurchaseOrderModal,
    DepotOrderModal,
    ProductionSlipModal,
    ConsumptionSlipModal,
    InvoiceModal,
    VariantSlipModal,
    ModelPlanlamaCostTableVue,
    LinkedStockMovementsModal
  },
  props: {
    stateKey: String,
    modelId: Number
  },
  setup(props, { root }) {
    const { state, stockUnits } = useModelPlanlamaState();

    const materialCastingListTable = ref('');

    onMounted(() => {
      window.addEventListener('materialCastingListTableEvent', data => {
        materialCastingListTable.value = data.detail.data.outerHTML;
      });
    });

    onUpdated(() => {
      window.addEventListener('materialCastingListTableEvent', data => {
        materialCastingListTable.value = data.detail.data.outerHTML;
      });
    });

    const stocks = computed(() => {
      return state[props.stateKey].details.stocks
        .concat(state[props.stateKey].details.semiProducts)
        .filter(
          stock =>
            stock.isProductionData && !stock.isSelected && !stock.slipIsCreated
        )
        .map(stock => stock.entryID);
    });

    const stockToProductionSlip = ref([]);

    const stockMovements = ref([]);

    const stockMovementsModal = ref(false);

    const isModelConfirmed = () => {
      if (props.stateKey === 'confirmed') return true;
      else {
        return false;
      }
    };
    const isModelSaved = () => {
      const currentUrl = window.location.pathname;
      if (currentUrl.includes('/duzenle')) return true;
      else {
        return false;
      }
    };

    const currentCompany = useCompany();
    const saveButtonDisabled = ref(false);

    const purchaseOrderModal = ref(false);
    const depotOrderModal = ref(false);
    const productionSlipModal = ref(false);
    const consumptionSlipModal = ref(false);
    const invoiceModal = ref(false);

    const htmlTable = ref('');
    const htmlTableInner = ref('');

    // TODO KEYLER MODALA DEGISTIRLMEDEN YOLLANACAK
    const productionSlipStockMapper = stock => {
      const findedStock = Object.assign({}, state['confirmed'].details.stocks
        .concat(state['confirmed'].details.semiProducts)
        .find(item => parseInt(item.entryID) === stock.id));
      findedStock['stockID'] = findedStock.entryID;
      findedStock.unit = findedStock ? parseInt(findedStock.unit) : '';
      findedStock.currency = "USD";
      findedStock['stockCode'] = stock.stok_kod;
      findedStock['usingRecipe'] = stock.kullanilan_recete;
      findedStock['price'] = findedStock.mainCurrencyPrice;
      // newStock['amount'] = 1;
      // newStock['cost'] = newStock.price / newStock.amount;

      return findedStock;
    };

    const productionSlipStockDetailsMapper = arr => {
      const details = arr.map(detail => {
        return {
          stockID: detail.id || detail.stockID,
          stockCode: detail.stok_kod || '',
          stockName: detail.stok_isim || detail.entryName,
          stockUnitID: detail.birim || parseInt(detail.unit),
          stockUnit:
            detail.birim_adi ||
            getValueById(
              stockUnits.value,
              parseInt(detail.birim || detail.unit),
              'isim'
            ),
          price:
            trStrToNum(number_format(detail.fiyat, 2, ',')) *
            trStrToNum(number_format(detail.miktar, 2, ',')),
          currency: detail.doviz_cinsi || detail.currency,
          stockAmount: detail.miktar || detail.amount,
          calculatedAmount: number_format(detail.miktar, 2, ','),
          cost: number_format(detail.fiyat, 2, ','),
          description: detail.satir_aciklama || detail.explanation
        };
      });
      return details;
    };

    // const stocksLength = ref(0);
    const finalProduct = ref(0);

    const getBomData = () => {
      let stocksID = [state['confirmed'].info.finalProductID];
      if (stocks.value.length > 0 && finalProduct.value === 0) {
        primaryConfirmDialog(
          'Nihai ürünü üretime dahil etmek istiyor musunuz? ',
          () => {
            stocksID.push(...stocks.value);
            stockToProductionSlip.value = [];
            getStockRecipeDetails(stocksID).then(response => {
              response.forEach(stock => {
                if (
                  stock.details ||
                  stock.id === parseInt(state['confirmed'].info.finalProductID)
                ) {
                  const newStock = productionSlipStockMapper(stock);
                  newStock['details'] =
                    stock.id !==
                    parseInt(state['confirmed'].info.finalProductID)
                      ? productionSlipStockDetailsMapper(stock.details)
                      : productionSlipStockDetailsMapper([
                          ...state['confirmed'].details.stocks,
                          ...state['confirmed'].details.semiProducts
                        ]);
                  if (
                    newStock.usingRecipe !== null ||
                    stock.id ===
                      parseInt(state['confirmed'].info.finalProductID)
                  ) {
                    stockToProductionSlip.value.push(newStock);
                  }
                }
                finalProduct.value = 1;
                productionSlipModal.value = true;
              });
            });
          },
          'Üretim Fişi',
          'Evet',
          () => {
            stocksID = stocks.value;
            stockToProductionSlip.value = [];
            getStockRecipeDetails(stocksID).then(response => {
              response.forEach(stock => {
                const newStock = productionSlipStockMapper(stock);
                newStock['details'] = productionSlipStockDetailsMapper(
                  stock.details
                );
                if (newStock.usingRecipe !== null) {
                  stockToProductionSlip.value.push(newStock);
                }
                productionSlipModal.value = true;
              });
            });
          },
          'Hayır'
        );
      } else if (stockToProductionSlip.value.length < 1) {
        getStockRecipeDetails(stocksID).then(response => {
          response.forEach(stock => {
            const newStock = productionSlipStockMapper(stock);
            newStock['details'] = productionSlipStockDetailsMapper(
              stock.details
            );
            stockToProductionSlip.value.push(newStock);
            productionSlipModal.value = true;
          });
        });
      } else {
        stockToProductionSlip.value.forEach(stock => {
          if (
            !stocks.value.includes(String(stock.stockID)) &&
            String(stock.stockID) !== state['confirmed'].info.finalProductID
          ) {
            stockToProductionSlip.value.splice(
              stockToProductionSlip.value.indexOf(JSON.stringify(stock)),
              1
            );
          }
        });
        finalProduct.value = 0;
        productionSlipModal.value = true;
      }
    };

    // TODO REFACTOR
    const handleProductionSlipModal = async () => {
      if (stocks.value.length === 0) {
        errorDialog(
          'Lütfen üretim fişi oluşturulmamış en az bir tane stok seçiniz'
        );
        return;
      }
      if (stocks.value.length > 1) {
        primaryConfirmDialog(
          'Birden fazla ürünü üretime sokmak istediğinizden emin misiniz?',
          () => getBomData(),
          'Üretim Fişi',
          'Evet',
          () => {
            return false;
          },
          'Hayır'
        );
      } else {
        getBomData();
      }
    };

    const openMailModal = () => {
      window.ajaxpen(400, 250, 'Mail Gönder', 'mail_yaz&tip=normal');
    };

    const openFilesMiniModule = () => {
      const kaynakId =
        parseInt(state['confirmed'].info.draftID) > 0
          ? state['confirmed'].info.draftID
          : state['prototype'].info.id;
      window.ajaxpen(
        750,
        400,
        'Model Planlama Dosyaları',
        `dosyalar&kaynak=30&kaynak_id=${kaynakId}&sirket=${currentCompany.value.id}`
      );
    };

    const returnToList = () => {
      if (authorizationControl('model_planlama_liste')) {
        window.location.href = `${root.$baseURL}/?s=model_planlama&listele=1`;
      } else {
        errorDialog('Yetkiniz yok!');
      }
    };

    const variantSlipModal = ref(false);
    const recipesForVariantSlip = ref([]);

    // TODO REFACTOR
    // const createVariantSlip = async (type) => {
    //   let recipes = {};
    //   const colorRecipes = Object.assign({}, state["confirmed"].details.colorRecipes);
    //   const sizeRecipes = Object.assign({}, state["confirmed"].details.sizeRecipes);
    //   for (const key in colorRecipes) {
    //       if (!Array.isArray(colorRecipes[key]) || colorRecipes[key].length === 0) {
    //         delete colorRecipes[key];
    //       }
    //   }
    //   for (const key in sizeRecipes) {
    //       if (!Array.isArray(sizeRecipes[key]) || sizeRecipes[key].length === 0) {
    //         delete sizeRecipes[key];
    //       }
    //   }
    //   if (type === '1') {
    //     const mergedStocks = {...colorRecipes, ...sizeRecipes};
    //     const response = await getStockRecipeDetails(Object.keys(mergedStocks));
    //     const variants = state['confirmed'].info.modelVariants;

    //     const getRecipeAmount = (id, isDetail) => {
    //       for (const key in variants.varyant_stok_id) {
    //           if (parseInt(Object.keys(variants.varyant_stok_id[key])[0]) === id && !isDetail) {
    //             return variants[key].amount;
    //           } else if (isDetail) {
    //             const sizeVariants = variants.varyant_stok_id[key][Object.keys(variants.varyant_stok_id[key])[0]];
    //             for (const sizeKey in sizeVariants) {
    //                 if (sizeVariants[sizeKey][0].stok_id === parseInt(id)) {
    //                   const size = selectedSizes['confirmed'].find(size => sizeKey.includes(size));
    //                   return variants[key][size];
    //                 }
    //             }
    //           }
    //       }
    //       return 0;
    //     };

    //     response.forEach((stock) => {
    //         recipes[stock.id] =  [{
    //           stockID: stock.id,
    //           stockCode: stock.stok_kod,
    //           stockName: stock.stok_isim,
    //           stockUnitID: "0",
    //           amount: getRecipeAmount(stock.id, Object.keys(sizeRecipes).includes(String(stock.id)) ? 1 : 0),
    //           cost: stock.stok_fiyat,
    //           price: "",
    //           depotID: state['confirmed'].info.depot,
    //           currency: stock.para_birimi,
    //           usingRecipe: stock.kullanilan_recete,
    //           details: stock.details.map(detail => {
    //             return {
    //               stockID: detail.stok_id,
    //               stockName: detail.recete_isim,
    //               stockCode: detail.recete_kod,
    //               unit: detail.birim_adi,
    //               currency: detail.doviz_cinsi,
    //               amount: 3,
    //               price: detail.fiyat,
    //               cost:  parseInt(detail.miktar) === 0 ? "0" : parseFloat(detail.fiyat) / parseFloat(detail.miktar)
    //             };
    //           })
    //         }];
    //     });
    //     recipesForVariantSlip.value = Object.values(recipes).map(recipe => recipe[0]);
    //     variantSlipModal.value = true;
    //   } else {
    //       recipes = {
    //         ...colorRecipes,
    //         ...sizeRecipes,
    //       };
    //       const productionSlip = {
    //         documentID: state['confirmed'].info.draftID,
    //         branch: state['confirmed'].info.branch,
    //         serial: state['confirmed'].info.serial,
    //         depotID: state['confirmed'].info.depot,
    //         recipeGroupID: "1",
    //         type,
    //         expenses: state['confirmed'].details.expenses,
    //         recipes,
    //         status: type === '1' ? '2' : '1',
    //       };
    //       storeVariantProductionSlip(productionSlip).then((response) => {
    //         if (response.status === 200) {
    //           primaryDialog(response.data.message, "Üretim Fişi Oluştur", () => window.open(`${window.location.origin}/${response.data.redirect}`, '_blank'));
    //         }
    //       }).catch(error => {
    //           errorDialog(error.response.data.message, "Hata", () => window.open(`${window.location.origin}/${error.response.data.redirect}`, '_blank'));
    //       });
    //   }
    // };

    // const handleConfirmMailSendButtonVisibility = () => {
    //   return props.stateKey === "prototype"
    //     ? authorizationControl("tasarim_onay") && isModelSaved()
    //     : authorizationControl("uretim_onay") && isModelSaved();
    // };

    const costTable = ref('');

    const buttonsMap = computed(() => {
      const buttons = [
        {
          text: 'Kaydet (Alt+S)',
          dataId: 'kaydet',
          handler: async () => {
            saveButtonDisabled.value = true;
            await saveModelPlanning(props.stateKey);
            saveButtonDisabled.value = false;
          },
          visible: () => true
        },
        {
          text: 'Listeye Dön',
          dataId: 'geri',
          handler: () => returnToList(),
          visible: () => true
        }
      ];

      buttons.push(
        ...[
          // {
          //   text: "Onay Maili Gönder",
          //   handler: () => {},
          //   visible: handleConfirmMailSendButtonVisibility
          // },
          {
            text: 'Mail Gönder',
            handler: openMailModal,
            visible: () => isModelSaved()
          },
          {
            text: "PDF'e Aktar",
            handler: () => {
              downloadPDF(
                htmlTable.value + materialCastingListTable.value,
                state[props.stateKey].info.modelName,
                state[props.stateKey].info.modelCode
              );
            },
            visible: () => isModelSaved()
          },
          {
            text: "Excel'e Aktar",
            handler: () => {
              window.open(
                `data:application/vnd.ms-excel;charset=utf-8,%EF%BB%BF'${encodeURIComponent(
                  htmlTableInner.value +
                    materialCastingListTable.value
                      .replace(
                        '<table',
                        "<table style='border: 1px solid black;'"
                      )
                      .replaceAll('<td', "<td style='border: 1px solid black;'")
                      .replaceAll('<th', "<th style='border: 1px solid black;'")
                      .replaceAll('<tr', "<tr style='border: 1px solid black;'")
                      .replaceAll(
                        '<tbody',
                        "<tbody style='border: 1px solid black;'"
                      )
                      .replaceAll(
                        '<thead',
                        "<thead style='border: 1px solid black;'"
                      )
                      .replaceAll(
                        '<tfoot',
                        "<tfoot style='border: 1px solid black;'"
                      )
                      .replaceAll(
                        '<table',
                        "<table style='border: 1px solid black;'"
                      )
                      .replaceAll(
                        '<img',
                        "<img style='border: 1px solid black;'"
                      )
                )}`
              );
            },
            visible: () => isModelSaved()
          },
          {
            text: 'Reçete Oluştur',
            handler: () => {
              consumptionSlipModal.value = true;
            },
            visible: () => isModelConfirmed()
          },
          // {
          //   text: "Stok Kartı Oluştur",
          //   handler: () => {
          //     window.location.href = `${window.location.origin}/?s=stok&yeni=1`;
          //   },
          //   visible: () => isModelConfirmed(),
          // },
          {
            text: 'Dosya Ekle',
            handler: openFilesMiniModule,
            visible: () => isModelSaved()
          },
          {
            text: 'Log Göster',
            handler: () => {
              window.Log.open('Model Planlama', props.modelId);
            },
            visible: () => isModelSaved()
          },
          {
            text: 'Depo Sipariş Oluştur',
            handler: () => {
              depotOrderModal.value = true;
            },
            visible: () => isModelConfirmed()
          },
          {
            text: 'Satın Alma Sipariş Oluştur',
            handler: () => {
              purchaseOrderModal.value = true;
            },
            visible: () => isModelConfirmed()
          },
          {
            text: 'Üretim Fişi Oluştur',
            handler: () => {
              handleProductionSlipModal();
            },
            visible: () => isModelConfirmed()
          },
          {
            text: 'Açık Hesap Masraf Alış Faturası Oluştur',
            handler: () => {
              invoiceModal.value = true;
            },
            visible: () => isModelConfirmed()
          },
          // {
          //   text: "Varyantlara Göre Reçete Oluştur",
          //   visible: () => isModelConfirmed(),
          //   handler: () => {
          //       createVariantSlip('2');
          //   }
          // },
          // {
          //   text: "Varyantlara Göre Üretim Fişi Oluştur",
          //   visible: () => isModelConfirmed(),
          //   handler: () => {
          //     createVariantSlip('1');
          //    }
          // },
          {
            text: 'Maliyet Tablosu Excel Aktar',
            handler: () => {
              window.open(
                `data:application/vnd.ms-excel;charset=utf-8,%EF%BB%BF'${encodeURIComponent(
                  costTable.value
                    .replaceAll('<td', "<td style='border: 1px solid black;'")
                    .replaceAll('<th', "<th style='border: 1px solid black;'")
                    .replaceAll('<tr', "<tr style='border: 1px solid black;'")
                    .replaceAll(
                      '<tbody',
                      "<tbody style='border: 1px solid black;'"
                    )
                    .replaceAll(
                      '<thead',
                      "<thead style='border: 1px solid black;'"
                    )
                    .replaceAll(
                      '<tfoot',
                      "<tfoot style='border: 1px solid black;'"
                    )
                    .replaceAll(
                      '<table',
                      "<table style='border: 1px solid black;'"
                    )
                    .replaceAll('<img', "<img style='border: 1px solid black;'")
                )}`
              );
            },
            visible: () => true
          },
          {
            text: 'Maliyet Tablosu PDF Aktar',
            handler: () => {
              downloadPDF(costTable.value);
            },
            visible: () => true
          },
          {
            text: 'Bağlı Stok Hareketleri',
            handler: async () => {
              if (stockMovements.value.length === 0) {
                const data = await linkedStockMovements(
                  state['confirmed'].info.houseId
                );
                for (const key in data) {
                  stockMovements.value.push(...Object.values(data[key]));
                }
              }
              stockMovementsModal.value = true;
            },
            visible: () => isModelConfirmed()
          }
        ]
      );

      return buttons;
    });

    return {
      buttonsMap,
      saveButtonDisabled,
      htmlTable,
      htmlTableInner,
      materialCastingListTable,
      purchaseOrderModal,
      depotOrderModal,
      productionSlipModal,
      stocks,
      finalProduct,
      stockToProductionSlip,
      productionSlipStockMapper,
      productionSlipStockDetailsMapper,
      consumptionSlipModal,
      invoiceModal,
      variantSlipModal,
      recipesForVariantSlip,
      costTable,
      stockMovements,
      stockMovementsModal
    };
  }
};
</script>

<style lang="scss" scoped></style>
