<template>
    <wo-modal
        class="wide-dialog-90vw one-step-behind-dialog mt-5"
        :active="washingModal"
        title="YIKAMA"
        @active="$emit('washingModal', $event)"
        :accept="saveWashingModal"
        :closeAct="closeWithoutSave"
    >
        <div class="d-flex text-center">
            <h2 style="font-weight: bold; color:red;">YIKAMA VE KURUTMA FORMU</h2>
        </div>
        <div class="my-5 d-flex text-center container">
          <table class="info-table">
              <tr v-for="(title, index) in infoTitles" :key="index">
                  <th>{{ title }}</th>
                  <td><input type="text" style="border-radius: 3px; border-top: none; border-left: none; border-right: none;" v-model="washingInfo[title]"></td>
              </tr>
          </table>
        </div>
        <!-- <wo-table>
            <caption style="font-weight: bold; font-size: large; margin-left: 8px; margin-bottom: 5px;">YIKAMA DETAYLARI</caption>
            <template #thead>
                <wo-th></wo-th>
                <wo-th v-for="column in washingColumnNames" :key="column">
                    {{ column }}
                </wo-th>
            </template>
            <template>
                <wo-tr v-for="phrase in localWashingPhrasesNames" :key="phrase" class="text-center">
                    <wo-td>{{ phrase }}</wo-td>
                    <wo-td v-for="col in washingColumnNames" :key="col">
                        <input type="text" style="height:35px; width: 195px; border-radius: 3px;" class="text-center" v-model="washing[phrase][col]" />
                    </wo-td>
                </wo-tr>  
                <wo-tr class="text-center">
                    <wo-td class="text-right"><input type="text" style="border-radius: 3px; object-fit: fill;" v-model="addedWashingPhrase">
                        <feather-icon
                            icon="PlusIcon"
                            style="cursor: pointer;"
                            class="h-5 w-5 align-middle plusIcon text-success ml-2"
                            @click="addPhrase"
                        /> 
                    </wo-td>
                </wo-tr>
            </template>
        </wo-table> -->
        <wo-table>
            <caption style="font-weight: bold; font-size: large; margin-left: 8px; margin-bottom: 5px;">ÖLÇÜLER</caption>
            <!-- <template #thead> -->
                <wo-tr>
                    <wo-th v-for="phrase in localWashingPhrasesNames" :key="phrase">{{ phrase }}</wo-th>
                    <wo-th class="text-right" style="display: flex;">
                        <input type="text" style="border-radius: 3px; object-fit: fill;" v-model="addedWashingPhrase">
                        <feather-icon
                            icon="PlusIcon"
                            style="cursor: pointer;"
                            class="h-5 w-5 align-middle plusIcon text-success ml-2"
                            @click="addPhrase"
                        /> 
                    </wo-th>
                </wo-tr>
                <wo-tr>
                    <wo-td v-for="phrase in localWashingPhrasesNames" :key="phrase">
                        <input type="text" style="height:35px; width: 195px; border-radius: 3px;" class="text-center" v-model="washingDimensions[phrase]" />
                    </wo-td>
                </wo-tr>
            <!-- </template> -->
        </wo-table>
        <wo-table style="margin: 60px 20px 20px 20px;">
            <caption style="font-weight: bold; font-size: large; margin-left: 8px; margin-bottom: 5px;">KURUTMA DETAYLARI</caption>
            <template #thead>
                <wo-th v-for="dryPhrase in localDryPhrasesNames" :key="dryPhrase">
                    {{ dryPhrase }}
                </wo-th>
                <wo-th style="display: flex;">
                    <input type="text" style="border-radius: 3px; object-fit: fill;" v-model="addedDryPhrase">
                    <feather-icon
                        icon="PlusIcon"
                        style="cursor: pointer;"
                        class="h-5 w-5 align-middle plusIcon text-success ml-2"
                        @click="addDryPhrase"
                    /> 
                </wo-th>   
            </template>
            <template>
                <wo-tr>
                    <wo-td v-for="dryPhrase in localDryPhrasesNames" :key="dryPhrase" class="text-center">
                        <input type="text" style="height:35px; width: 195px; border-radius: 3px;" class="text-center" v-model="drying[dryPhrase]" />
                    </wo-td>
                </wo-tr>
            </template>
        </wo-table>
        <i class="fa fa-print fa-2x ml-5" aria-hidden="true" @click="exportPDF"></i>
    </wo-modal>
</template>

<script>
import { computed, onUpdated, ref } from '@vue/composition-api';
import { useModelPlanlamaState } from '../../composables/useModelPlanlamaState';
import { addInformationPhrases } from '@/helpers/ApiController';
import exportTable from '@/helpers/exportTable';
export default {
    name: "WashingModal",
    props: {
        washingModal: Boolean,
        washingPhrasesNames: Array,
        washingColumnNames: Array,
        dryPhrasesNames: Array,
        stateKey: String,
    },
    setup(props, context) {

    const { state, machines } = useModelPlanlamaState();

    const localData = computed({
        get() {
            return state[props.stateKey].info.otherInformations;
        },
        set(val) {
            state[props.stateKey].info.otherInformations = val;
        },
    });

    const infoTitles = [
        "ÜRETİCİ",
        "MODEL KODU",
        "DESEN NO",
        "MAKİNE GG"
    ];

    const washingInfo = computed(() => state[props.stateKey].info.otherInformations.washingInfo || {});

    const localWashingPhrasesNames = ref(props.washingPhrasesNames);

    const localDryPhrasesNames = ref(props.dryPhrasesNames);

    const setWashing = () => {
        if (!localData.value.washing) {
            localData.value.washing = {};
            localWashingPhrasesNames.value.forEach(phrase => {
                localData.value.washing[phrase] = {};
            });
        }
    };
    
    const washing = computed(() => {
        setWashing();
        const washing = {};
        localWashingPhrasesNames.value.forEach(phrase => {
            washing[phrase] = {};
            props.washingColumnNames.forEach(column => {
                washing[phrase][column] = localData.value.washing[phrase] === undefined ? '' : localData.value.washing[phrase][column];
            });
        });
        return washing;
    });

    const washingDimensions = computed(() => localData.value.washingDimensions || {});
    

    const setDrying = () => {
        if (!localData.value.drying) {
            localData.value.drying = {};
            localDryPhrasesNames.value.forEach(phrase => {
                localData.value.drying[phrase] = '';
            });
        }
    };

    const drying = computed(() => {
        setDrying();
        const drying = {};
        localDryPhrasesNames.value.forEach(phrase => {
            drying[phrase] = localData.value.drying[phrase] === undefined ? '' : localData.value.drying[phrase];
        });
        return drying;
    });

    const addedWashingPhrase = ref('');
    const addedDryPhrase = ref('');

    const newPhrases = ref([]);
    const newDryPhrases = ref([]);

    const addPhrase = () => {
        if (addedWashingPhrase.value !== '') {
            state[props.stateKey].info.otherInformations.washing = washing.value;
            localWashingPhrasesNames.value.push(addedWashingPhrase.value);
            newPhrases.value.push(addedWashingPhrase.value);
            addedWashingPhrase.value = '';
        }
    };

    const addDryPhrase = () => {
        if (addedDryPhrase.value !== '') {
            state[props.stateKey].info.otherInformations.drying = drying.value;
            localDryPhrasesNames.value.push(addedDryPhrase.value);
            newDryPhrases.value.push(addedDryPhrase.value);
            addedDryPhrase.value = '';
        }
    };

    const saveWashingModal = async () => {
        if (newPhrases.value.length > 0) {
            await addInformationPhrases(newPhrases.value, 2);
        }
        if (newDryPhrases.value.length > 0) {
            await addInformationPhrases(newDryPhrases.value, 3);
        }
        state[props.stateKey].info.otherInformations.washing = washing.value;
        state[props.stateKey].info.otherInformations.drying = drying.value;
        state[props.stateKey].info.otherInformations.washingDimensions = washingDimensions.value;
        state[props.stateKey].info.otherInformations.washingInfo = washingInfo.value;
        context.emit('washingModal', false);
    };

    const closeWithoutSave = () => {
        context.emit('washingModal', false);
    };

    const exportPDF = () => {
        const image = state[props.stateKey].info.imageUrls[0];
        const washingDimensionsObject = {
            data: washingDimensions.value,
            title: 'YIKAMA DETAYLARI',
        };
        
        const dryingObject = {
            data: drying.value,
            title: 'KURUTMA DETAYLARI',
        };

        const infoObject = {
            data: washingInfo.value,
            title: ""
        };
        exportTable([washingDimensionsObject, dryingObject], infoObject, image, 'YIKAMA VE KURUTMA FORMU');
    };

    onUpdated(() => {
        localDryPhrasesNames.value = props.dryPhrasesNames;
        localWashingPhrasesNames.value = props.washingPhrasesNames;
        // if (washingInfo.value['ÜRETİCİ'] === "" && washingInfo.value['MODEL KODU'] === "") {
            washingInfo.value['ÜRETİCİ'] = state[props.stateKey].info.mainContractorName;
            washingInfo.value['MODEL KODU'] = state[props.stateKey].info.modelCode;
            const matchedMachine = machines.value.find(machine => machine.id === parseInt(state[props.stateKey].info.machineID));
            washingInfo.value['MAKİNE GG'] = matchedMachine ? matchedMachine.isim : "";
        // }
    });

    return {
        saveWashingModal,
        closeWithoutSave,
        localWashingPhrasesNames,
        addPhrase,
        washing,
        addedWashingPhrase,
        localDryPhrasesNames,
        drying,
        addedDryPhrase,
        addDryPhrase,
        exportPDF,
        infoTitles,
        washingInfo,
        washingDimensions
    };
}
};
</script>