import { factory } from '@/helpers/ApiController.js';
import { errorDialog } from '@/helpers/errorDialogs';
import { numToTrStr } from '@/helpers/NumberController';
import { dateFromServer } from '../../../../helpers/serverToClientConversion';

import { useUretimPlanlamaState } from '../composables/useUretimPlanlamaState';

const detailHydrater = [
  { server: 'id', client: 'lineID' },
  { server: 'stok_id', client: 'stockID' },
  { server: 'miktar', client: 'amount' },
  { server: 'stok_isim', client: 'stockName' },
  { server: 'istasyon', client: 'station' },
  { server: 'resim', client: 'stockImage' },
  { server: 'birim', client: 'stockUnit' },
  { server: 'birim_fiyat', client: 'stockPrice', convert: numToTrStr },
  { server: 'detay_id', client: 'detailId' },
];

const parentHydrater = [
  { server: 'id', client: 'id' },
  { server: 'seri', client: 'serial' },
  { server: 'sira', client: 'sequence' },
  { server: 'tarih', client: 'date', convert: dateFromServer },
  { server: 'kaynak', client: 'source' }
];

const hydrater = (blueprint, detail) => {
  return blueprint.reduce((acc, item) => {
    if (item.convert) {
      acc[item.client] = item.convert(detail[item.server]);
    } else {
      acc[item.client] = detail[item.server];
    }
    return acc;
  }, {});
};

const parseDetailsByStation = details => {
  const detailsByStation = [];
  for (const key in details) {

    for (const stationKey in details[key]) {
      detailsByStation.push({
        ...details[key][stationKey],
        istasyon: stationKey,
      });
    }
  } 
  return detailsByStation;
};

const parseGeneralValuesToDetails = obj => {
  const result = [];
  const newDetails = parseDetailsByStation(obj.detaylar);
  newDetails.forEach(detail => {
    const newDetail = hydrater(detailHydrater, detail);
    const newParent = hydrater(parentHydrater, obj);
    result.push({ ...newDetail, ...newParent });
  });
  return result;
};

const mergeAndParseArray = data => {
  const result = data.reduce((acc, item) => {
    return [...acc, ...parseGeneralValuesToDetails(item)];
  }, []);
  return result;
};

const groupMergedArray = data => {
  const { stations } = useUretimPlanlamaState();
  const accumulator = stations.value.reduce((acc, item) => {
    acc[item.id] = [];
    return acc;
  }, {});
  return data.reduce((acc, item) => {
    acc[parseInt(item.station)].push({ ...item });
    delete acc[parseInt(item.station)].station;
    return acc;
  }, accumulator);
};

const parseProductCardData = data => {
  const mergedArray = mergeAndParseArray(data);
  return groupMergedArray(mergedArray);
};

export async function fetchProductCards(source) {
  const { startDate, endDate, prevalentID, fetchCardOptionsData } = useUretimPlanlamaState();

  const kaynak = fetchCardOptionsData.find(item => item.id === source).value;
  const url = `manufacturing/manufacture-wait`;
  try {
    const { data } = await factory.get(url, {
      params: {
        cari_id: prevalentID.value,
        baslangic_tarihi: startDate.value,
        bitis_tarihi: endDate.value,
        tumu: true,
        kaynak
      }
    });
    const result = parseProductCardData(data);
    return result;
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Üretim fişlerini getirirken bir hata oluştu.');
    }
  }
}

// const stationHydrater = data => {
//   return data.reduce((acc, item) => {
//     return acc.push({ id: item.id, name: item.isim });
//   }, []);
// };

export async function fetchTransferDetails(fis_id, istasyon, kaynak) {
  const url = "manufacturing/fis-detail";
  try {
    const { data } = await factory.get(url, {
      params: {
        fis_id,
        istasyon,
        kaynak
        
      }
    });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Üretim fişlerini getirirken bir hata oluştu.');
    }    
  }
}

export async function fetchStations() {
  const url = `manufacturing/istasyon-list`;
  try {
    const { data } = await factory.get(url);
    return data;
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('İstasyonları getirirken bir hata oluştu.');
    }
    return [];
  }
}

export async function transferDetail(detailData) {
  const url = `manufacturing/manufacture-detail-transfer`;
  try {
    const { data } = await factory.post(url, {
        kaynak_surec_id: detailData.kaynak_surec_id,
        hedef_surec_id: detailData.hedef_surec_id,
        detaylar: detailData.detaylar
    });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Üretim fişlerini getirirken bir hata oluştu.');
    }
  }
}

// export async function updateStation(id, station) {
//   const url = `manufacturing/istasyon-guncelle`;
//   try {
//     const {
//       data: { message }
//     } = await factory.post(url, {
//       id,
//       deger: station
//     });
//     primaryDialog(message);
//   } catch (error) {
//     if (error.response.data.message) {
//       errorDialog(error.response.data.message);
//     } else {
//       errorDialog('İstasyonu güncellerken bir sıkıntı oluştu.');
//     }
//   }
// }
