<template>
  <div class="vs-width">
    <vs-textarea
      v-bind="$attrs"
      :value="value"
      :label="label"
      :color="color"
      :counter="counter"
      :counterDanger="counterDanger"
      :height="height"
      :width="width"
      :class="elementClasses"
      @input="(e) => $emit('input', e)"
    />
    <transition-group @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div
        class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
        key="danger"
      >
        <span v-if="error && error.length" class="span-text-validation">{{
          error[0]
        }}</span>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "WOTextArea",
  inheritAttrs: false,
  props: {
    value: {},
    label: {
      default: null,
      type: String,
    },
    color: {
      default: "primary",
      type: String,
    },
    counter: {
      default: null,
      type: [Number, String],
    },
    counterDanger: {
      default: false,
      type: Boolean,
    },
    height: {
      default: null,
      type: String,
    },
    width: {
      default: null,
      type: String,
    },
    elementClasses: String,
    error: Array,
  },
  setup() {
    const beforeEnter = (el) => {
      el.style.height = 0;
    };

    const enter = (el, done) => {
      const h = el.scrollHeight;
      el.style.height = `${h}px`;
      done();
    };

    const leave = (el) => {
      el.style.height = "0px";
    };

    return {
      beforeEnter,
      enter,
      leave,
    };
  },
};
</script>
