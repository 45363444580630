<template>
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="teklif"
    :auth="moduleConfig.auth"
  >
    <TekliflerForm />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import TekliflerForm from './TekliflerForm.vue';

import { computed, onMounted } from '@vue/composition-api';

export default {
  components: {
    WOModuleWrapper,
    TekliflerForm
  },
  setup(props, { root }) {
    const moduleConfig = computed(() => {
      if (root.$route.params.id) {
        return {
          title: 'Teklif Düzenle',
          auth: 'teklif_duzenle'
        };
      } else {
        return { title: 'Yeni Teklif', auth: 'teklif_ekle' };
      }
    });

    onMounted(() => (document.title = moduleConfig.value.title));

    return {
      moduleConfig
    };
  }
};
</script>

<style scoped></style>
