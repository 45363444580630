<template>
  <div class="flex flex-wrap">
    <div class="space-y-4 responseive-margin-allocation">
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Şube</template>
        <template #firstElement>
          <wo-select v-model="localState.branch" :data="branches">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`branch-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Sipariş Veriliş Tarihi</template>
        <template #firstElement>
          <wo-date-picker v-model="localState.date" id="autofocus"/>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>Sipariş Teslim Tarihi</template>
        <template #firstElement>
          <wo-date-picker v-model="localState.deliveryDate" />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> {{formsData.modelCode.name}}</template>
        <template #firstElement>
          <base-input :value="designState.model_code" readonly />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> {{formsData.modelName.name}}</template>
        <template #firstElement>
          <div class="flex items-center">
            <base-input :value="designState.model_name" readonly />
            <!-- <wo-quick-search
              v-model="localState.modelName"
              :queryParameters="`?tablo=stok&depo=${state[stateKey].info.depot}&q=`"
              @selected="handleModelQuickSearch"
              :itemRenderer="(item) => nameAndCodeWithLinkRenderer(item)"
              v-else
            /> -->
            <!-- <vs-checkbox v-model="activeQuickSearchs.isNewModelName" title="Yeni eklemek için seçiniz"/>
            <feather-icon
              v-if="stateKey === 'confirmed'"
              class="w-6 h-6 mx-3"
              icon="PlusIcon"
              @click="handleStockCreationLink"
            /> -->
          </div>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Sipariş No</template>
        <template #firstElement>
          <div class="flex items-center">  
            <base-input v-model="localState.orderNumber" v-if="activeQuickSearchs.isNewOrderNumber" @blur="localState.orderID = '0'" />
            <wo-quick-search
                v-model="localState.orderNumber"
                :queryParameters="`?tablo=siparisler&sirket=${currentCompany.id}&q=`"
                @selected="handleOrderQuickSearch"
                :itemRenderer="(item) => titleRenderer(item)"
                v-else
              />
            <vs-checkbox v-model="activeQuickSearchs.isNewOrderNumber" title="Yeni eklemek için seçiniz" />
          </div>
        </template>
      </wo-double-form>
       <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Mamül Tanımı</template>
        <template #firstElement>
          <div class="flex items-center">
          <wo-text-area v-model="localState.productDescription" />
          </div>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>{{formsData.yarnQuality.name}}</template>
        <template #firstElement>
          <div class="flex items-center">
          <wo-text-area v-model="localState.yarnQuality" />
          </div>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Ana Yüklenici</template>
        <template #firstElement>
          <wo-quick-search
            v-model="localState.mainContractorName"
            :itemRenderer="prevalentRenderer"
            :queryParameters="`?tablo=cari&q=`"
            @selected="handlePrevalentQuickSearch"
          />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Depo</template>
        <template #firstElement>
          <wo-select v-model="localState.depot" :data="depots">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`depot-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openVaryantEtiketModal">
            Etiket
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="localState.etiket" :data="varyantEtiket">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`etiket-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openProcessModal">
            Süreç
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="localState.process" :data="processes">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`etiket-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
    </div>
    <ImageGallery
      :imageUrls="localImages"
      class="responseive-margin-allocation"
     @deleteImage="(url) => deleteImage(url)"
    />
    <div class="space-y-4 responseive-margin-allocation">
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Seri Sıra</template>
        <template #firstElement>
          <WOSerialSequence
            :sequenceValue.sync="localState.sequence"
            :serialValue.sync="localState.serial"
            module="model_planning"
          />
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Durum</template>
        <template #firstElement>
          <wo-select v-model="localState.status" :data="statusData">
            <template #default="{ item }">
              <wo-select-item
                :key="`status-${item.id}`"
                :itemValue="item.id"
                :text="item.name"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openCollectionModal">
            {{formsData.collection.name}}
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="localState.collection" :data="collections">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`collection-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openVaryantModal">
              {{formsData.paketleme.name}}
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="localState.paketleme" :data="varyantPaketleme">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`paketleme-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
       <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openYikamaIbaresiModal">
            {{formsData.yikamaIbareleri.name}}
          </span>
        </template>
        <template #firstElement>
          <wo-select v-model="localState.yikamaIbaresi" :data="yikamaIbareleri">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`yikamaIbaresi-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>      
      </wo-double-form>
      <wo-double-form>
        <template #firstLabel>
          <label>{{formsData.color.name}}</label>
        </template>
        <template #firstElement>
          <multiselect
            style="margin-left: -70px;"
            :value="selectedColors[stateKey]"
            :options="colors"
            :preserve-search="true"
            :close-on-select="false"
            :multiple="true"
            placeholder="Seçiniz"
            @select="(value) => addColor(value)"
            @remove="(value) => removeColor(value)"
          />
        </template>
    </wo-double-form>
    <wo-double-form>
        <template #firstLabel>
          <label>{{formsData.size.name}}</label>
        </template>
      <template #firstElement>
        <multiselect
          style="margin-left: -70px;"
          :value="selectedSizes[stateKey]"
          :options="sizes"
          :preserve-search="true"
          :close-on-select="false"
          :multiple="true"
          placeholder="Seçiniz"
          @select="(value) => addSize(value)"
          @remove="(value) => removeSize(value)"
        />
      </template>
    </wo-double-form>  
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel>Nihai Ürün</template>
      <template #firstElement>
        <div class="flex items-center">
          <base-input v-model="localState.finalProductName" v-if="activeQuickSearchs.isNewFinalProductName" @blur="localState.finalProductID='0'"/>
          <wo-quick-search
            v-model="localState.finalProductName"
            :queryParameters="`?tablo=stok&depo=${localState.depot}&q=`"
            @selected="handleFinalProductQuickSearch"
            :itemRenderer="(item) => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')"
            v-else
            />
          <vs-checkbox v-model="activeQuickSearchs.isNewFinalProductName" title="Yeni eklemek için seçiniz" />
        </div>
      </template>
    </wo-double-form>
    <wo-double-form :labelSize="labelSize">
      <template #firstLabel> Açıklama</template>
      <template #firstElement>
        <wo-text-area v-model="localState.explanation" />
      </template>
    </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel> Döviz Kuru</template>
        <template #firstElement>
          <div class="flex space-x-5">
            <wo-float-input
              v-model="currencyInputValue[stateKey]"
              class="w-24"
              @blur="handleCurrencyInputBlur"
            />
            <wo-select
              v-model="localState.currentCurrency"
              :data="localExchangeRates"
              elementClasses="w-26"
              @change="handleCurrencyChange"
            >
              <template #default="{ item }">
                <wo-select-item
                  :key="`exchange-rate-${item.kod}`"
                  :itemValue="item.kod"
                  :text="item.kod"
                />
              </template>
            </wo-select>
          </div>
        </template>
      </wo-double-form>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          <span class="underlined-hovered-link" @click="openMachineModal">
            Makine
          </span>
        </template>
        <template #firstElement>
          <wo-select :data="machines" :value="designState.machine_id">
            <template #defaultValue>
              <wo-select-item :itemValue="0" text="- Seçiniz -" />
            </template>
            <template #default="{ item }">
              <wo-select-item
                :key="`etiket-${item.id}`"
                :itemValue="item.id"
                :text="item.isim"
              />
            </template>
          </wo-select>
        </template>
      </wo-double-form>
      
      <div id="buttons">
          <wo-button text="Dikim-Ütü" @click="handleOtherInformationModal"></wo-button>
          <wo-button text="Dokuma" @click="handleWeavingModal"></wo-button>
          <wo-button text="Yıkama" @click="handleWashingModal"></wo-button>
          <wo-button text="Label Düzenle" @click="handleCustomizeLabelsModal" v-if="authorizationControl('model_planlama_ozellestirme')"></wo-button>
      </div>

    </div>
    <DimensionDetailsModal
      :otherInformationModal="otherInformationModal"
      @otherInformationModal="otherInformationModal = $event"
      :dimensionPhrasesNames="dimensionPhrasesNames"
      :shuttleNames="shuttleNames"
      :sizes="selectedSizes[stateKey]"
      :dressPhrasesNames="dressPhrasesNames"
      :stateKey="stateKey"
      @addDimensionPhrase="addDimensionPhrase"
    />

    <CustomizeLabelsModal 
      :customizeLabelsModal="customizeLabelsModal"
      @customizeLabelsModal="customizeLabelsModal = $event"
    />
    <WeavingModal
      :weavingModal="weavingModal"
      @weavingModal="weavingModal = $event"
      :stateKey="stateKey"
      :dressPhrasesNames="dressPhrasesNames"
      :shuttleNames="shuttleNames"
    />
    <WashingModal 
      :washingModal="washingModal"
      @washingModal="washingModal = $event"
      :washingPhrasesNames="washingPhrasesNames"
      :washingColumnNames="washingColumnNames"
      :dryPhrasesNames="dryPhrasesNames"
      :stateKey="stateKey"
    />
  </div>
</template>

<script>
import { computed, ref, watch, onUnmounted, reactive } from "@vue/composition-api";
import { useModelPlanlamaState } from "../../composables/useModelPlanlamaState";
import { prevalentRenderer } from "@/helpers/quickSearchListRenderers/qsPrevalent";
import { deleteImage as deleteImageAPI } from "../../helpers/modelPlanningApiService";
import DimensionDetailsModal from "./DimensionDetailsModal.vue";
import WeavingModal from "./WeavingModal.vue";
import WashingModal from "./WashingModal.vue";
import CustomizeLabelsModal from "./CustomizeLabelsModal.vue";
import { nameAndCodeWithLinkRenderer } from "@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink";
import { titleRenderer } from "@/helpers/quickSearchListRenderers/qsTitle";
import useCompany from '@/consumables/useCompany';
import {formsData} from "../../composables/formsData";

import ImageGallery from "./ImageGallery";
import WOSerialSequence from "@/components/WO/WOSerialSequence.vue";
import { errorDialog } from "@/helpers/errorDialogs";
import { authorizationControl } from "@/consumables/useAuthorizationControl";

export default {
  components: {
    ImageGallery,
    WOSerialSequence,
    DimensionDetailsModal,
    WeavingModal,
    CustomizeLabelsModal,
    WashingModal
  },
  props: {
    stateKey: String,
  },
  setup(props) {
    const {
      labelSize,
      depots,
      exchangeRates,
      collections,
      state,
      currencyInputValue,
      yikamaIbareleri,
      varyantEtiket,
      varyantPaketleme,
      dimensionPhrases,
      dressPhrases,
      shuttles,
      processes,
      branches,
      colors,
      sizes,
      selectedColors,
      selectedSizes,
      washingColumns,
      informationPhrases,
      machines,
      statuses
    } = useModelPlanlamaState();

    const currentCompany = useCompany();

    const otherInformationModal = ref(false);
    const weavingModal = ref(false);
    const washingModal = ref(false);
    const customizeLabelsModal = ref(false);    

    const shuttleNames = computed(() => {
      return shuttles.value.map(item => item.name);
    });

    const dimensionPhrasesNames = computed(() => {
      return dimensionPhrases.value.map(item => item.name);
    });

    const designState = computed(() => {
      return state['design'];
    });

    const addDimensionPhrase = (phrase) => {
      dimensionPhrasesNames.value.push(phrase);
    };

    // TODO CALLBACK KULLAN
    const dressPhrasesNames = computed(() => {
      return dressPhrases.value.map(item => item.name);
    });

    const washingPhrasesNames = computed(() => {
      return informationPhrases.value.washingPhrases.map(item => item.name);
    });

    const washingColumnNames = computed(() => {
      return washingColumns.value.map(item => item.name);
    });

    const dryPhrasesNames = computed(() => {
      return informationPhrases.value.dryPhrases.map(item => item.name);
    });    

    const handleOtherInformationModal = () => {
      otherInformationModal.value = true;
    };

    const handleWeavingModal = () => {
      weavingModal.value = true;
    };

    const handleWashingModal = () => {
      washingModal.value = true;
    };

    const handleCustomizeLabelsModal = () => {
      customizeLabelsModal.value = true;
    };

    const localState = computed(() => {
      return state[props.stateKey].info;
    });

    const activeQuickSearchs = reactive({
      isNewModelName: localState.value.modelID === '0',
      isNewOrderNumber: false,
      isNewFinalProductName: localState.value.finalProductID === '0',
    });

    const addColor = (color) => {
      selectedColors[props.stateKey].push(color);
      // localColors.value.push(color);
      localState.value.modelVariants[color] ? void (0) : localState.value.modelVariants[color] = {};
      localState.value.modelVariants[color]['amount'] = 0;
      selectedSizes[props.stateKey].forEach(size => {
        localState.value.modelVariants[color][size] = 0;
      });
    };

    const removeColor = (color) => {
      selectedColors[props.stateKey].splice(selectedColors[props.stateKey].indexOf(color), 1);
      delete localState.value.modelVariants[color];
    };

    const addSize = (size) => {
      selectedSizes[props.stateKey].push(size);
      for (const color in localState.value.modelVariants) {
        localState.value.modelVariants[color][size] = 0;
      }
    };

    const removeSize = (size) => {
      selectedSizes[props.stateKey].splice(selectedSizes[props.stateKey].indexOf(size), 1);
      for (const color in localState.value.modelVariants) {
        delete localState.value.modelVariants[color][size];
      }
    };

    const localExchangeRates = computed(() => {
      return exchangeRates.value[props.stateKey];
    });

    onUnmounted(() => {
      window.removeEventListener(this, null);
    });

    onUnmounted(() => {
      window.removeEventListener(this, null);
    });

    const statusData = computed(() => {
      return statuses[props.stateKey];
    });

    const localImages = computed(() => localState.value.imageUrls);

    watch(
      [
        () => JSON.parse(JSON.stringify(localState.value.finalProductID)),
        () => JSON.parse(JSON.stringify(localState.value.modelID)),
        () => JSON.parse(JSON.stringify(localState.value.orderID))
      ],
      () => {
        activeQuickSearchs.isNewOrderNumber = localState.value.orderID === '0';
        activeQuickSearchs.isNewModelName = localState.value.modelID === '0';
        activeQuickSearchs.isNewFinalProductName = localState.value.finalProductID === '0';
      }
    );

    const deleteImage = (url) => {
      if (!(props.stateKey === "prototype" && localState.value.status === "1")) {
        if (typeof url === "string") {
        const index = localState.value.imageFiles.findIndex((a) => a === url);
        localState.value.imageFiles.splice(index, 1);
      } else {
        try {
          deleteImageAPI(url.id);
          const index = localState.value.imageUrls.findIndex((a) => a.id === url.id);
          localState.value.imageUrls.splice(index, 1);
        } catch (error) {
          errorDialog(error);
        }
      }
      } else {
        errorDialog("Onaylanmış dosyayı değiştiremezsiniz");
      }
    };

    const handleCurrencyInputBlur = () => {
      const currentCurrency = localExchangeRates.value.find(
        (a) => a.kod === localState.value.currentCurrency
      );
      if (currentCurrency) {
        currentCurrency.kur = currencyInputValue.value[props.stateKey];
      }
    };

    const handleCurrencyChange = () => {
      const currentCurrency = localExchangeRates.value.find(
        (a) => a.kod === localState.value.currentCurrency
      );
      currencyInputValue.value[props.stateKey] = currentCurrency
        ? currentCurrency.kur
        : 1;
      localState.value.currentCurrencyRate = currentCurrency.kur;
    };

    const handlePrevalentQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.mainContractorID = item.id;
        localState.value.mainContractorName = item.text;
      } else if (!item.isSelected && localState.value.mainContractorID) {
        localState.value.mainContractorID = "";
        localState.value.mainContractorName = "";
      }
    };

    const handleFinalProductQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.finalProductID = item.id;
        localState.value.finalProductName = item.isim;
      } else if (!item.isSelected && localState.value.finalProductID) {
        localState.value.finalProductID = "";
        localState.value.finalProductName = "";
      }
    };
    // TODO ortak function yaz
    const handleModelQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.modelID = item.id;
        localState.value.modelCode = item.kod;
        localState.value.modelName = item.isim;
      } else if (!item.isSelected && localState.value.modelCode) {
        localState.value.modelCode = "";
        localState.value.modelName = "";
      }
    };

    const handleOrderQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.orderID = item.id;
        localState.value.orderNumber = item.unvan;
      } else if (!item.isSelected && localState.value.orderNumber) {
        localState.value.orderID = "";
        localState.value.orderNumber = "";
      }
    };
    
    const collectionActiveModal = ref(false);

    const openCollectionModal = () => {
      window.MiniModulPen(450, 300, "Koleksiyon", "koleksiyon");
      // collectionActiveModal.value = true;
    };

    const openVaryantModal = () => {
      window.MiniModulPen(450, 300, "Varyant", "varyant_paketleme");
    };

    const openYikamaIbaresiModal = () => {
      window.MiniModulPen(450, 300, "Yıkama Ibareleri", "yikama_ibareleri");
    };


    const openVaryantEtiketModal = () => {
      window.MiniModulPen(450, 300, "Varyant Etiket", "varyant_etiket");
    };

    const openProcessModal = () => {
      window.MiniModulPen(450, 300, "Süreçler", "gp_model_planning_processes");
    };

    const openMachineModal = () => {
      window.MiniModulPen(450, 300, "Süreçler", "gp_makineler");
    };

    const handleStockCreationLink = () => {
      window.open("/?s=stok&yeni=1", "_blank");
    };

    return {
      prevalentRenderer,
      labelSize,
      depots,
      collections,
      localExchangeRates,
      state,
      deleteImage,
      localImages,
      statusData,
      // addUploadedFilesUrls,
      localState,
      currencyInputValue,
      handleCurrencyInputBlur,
      handleCurrencyChange,
      handlePrevalentQuickSearch,
      handleFinalProductQuickSearch,
      collectionActiveModal,
      openCollectionModal,
      handleStockCreationLink,
      yikamaIbareleri,
      varyantEtiket,
      varyantPaketleme,
      openVaryantModal,
      openYikamaIbaresiModal,
      openVaryantEtiketModal,
      otherInformationModal,
      handleOtherInformationModal,
      dimensionPhrasesNames,
      dressPhrasesNames,
      shuttleNames,
      addDimensionPhrase,
      processes,
      openProcessModal,
      nameAndCodeWithLinkRenderer,
      handleModelQuickSearch,
      currentCompany,
      handleOrderQuickSearch,
      titleRenderer,
      activeQuickSearchs,
      branches,
      colors,
      sizes,
      selectedColors,
      selectedSizes,
      removeColor,
      addColor,
      removeSize,
      addSize,
      formsData,
      handleCustomizeLabelsModal,
      customizeLabelsModal,
      authorizationControl,
      weavingModal,
      handleWeavingModal,
      handleWashingModal,
      washingModal,
      washingPhrasesNames,
      washingColumnNames,
      dryPhrasesNames,
      openMachineModal,
      machines,
      designState
    };
  },
};
</script>

<style lang="scss" scoped>

#buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#buttons > button {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  #buttons {
    flex-direction: column;

  }
  #buttons > button {
    margin-bottom: 10px;
  }

}

.responseive-margin-allocation {
  @apply m-4 ml-0;
}
.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

</style>
