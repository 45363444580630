<template>
  <WOBasketsRaw
    :noPadding="noPadding"
    :quickSearchQuery="`?tablo=sepetler&cari=${prevalentIDs}&q=`"
    label="Seçili Carilerin Sepetleri"
    directLink="/?s=sepetler&duzenle="
    @updateLines="item => $emit('updateBasket', item)"
  >
    <template v-slot:quickSearch="{ item }">
      <span> {{ item.isim }} - {{ item.unvan }} </span>
    </template>
  </WOBasketsRaw>
</template>

<script>
import WOBasketsRaw from './WOBasketsRaw';

export default {
  components: {
    WOBasketsRaw
  },
  props: {
    prevalentIDs: String,
    noPadding: {
      type: Boolean,
      default: false
    }
  }
};
</script>
