import Vue from '@/main';

import { factory } from '@/helpers/ApiController.js';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { useJobListingState } from '../consumables/useJobListingState';
import {
  hydrateFromServer,
  hydrateFromClient,
  hydrateApplicants
} from './hydraterJobListing';

const state = useJobListingState();

export async function fetchDetailsData(id) {
  const url = `job_listing/${id}`;
  try {
    const { data } = await factory.get(url);
    Object.assign(state, hydrateFromServer(data));
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('İlan detaylarını getirirken bir sıkıntı oluştu.');
    }
  }
}

const pageRefresher = url => {
  setTimeout(() => {
    window.location = url;
  }, 2000);
};

export async function createJobListing() {
  const url = 'job_listing';
  try {
    const { data } = await factory.post(url, hydrateFromClient(state));
    primaryDialog(data.message);
    pageRefresher(`${Vue.$baseURL}/job_listing/${data.id}/duzenle`);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('İlanı kaydederken bir sıkıntı oluştu.');
    }
  }
}

export async function updateJobListing(id) {
  const url = `job_listing/${id}`;
  try {
    const { data } = await factory.put(url, hydrateFromClient(state));
    primaryDialog(data.message);
    pageRefresher(`${Vue.$baseURL}/job_listing/${id}/duzenle`);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('İlanı güncellerken bir sıkıntı oluştu.');
    }
  }
}

export function saveJobListing(id) {
  if (id) {
    updateJobListing(id);
  } else {
    createJobListing();
  }
}

export async function getApplicants(id) {
  const url = `job_listing/applicants/${id}`;
  try {
    const { data } = await factory.get(url);
    return data.reduce((acc, a) => [...acc, hydrateApplicants(a)], []);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Başvuruları getirirken bir sıkıntı oluştu.');
    }
  }
}
