<template>
  <div class="relative flex border border-gray-500 rounded shadow-xs">
    <vs-input
      ref="input"
      v-bind="$attrs"
      :placeholder="placeholder"
      :class="inputClasses + ' bg-transparent wo-companies-input'"
      icon-pack="feather"
      :icon="withMagnifier ? 'icon-search' : ''"
      icon-no-border
      v-model="inputValue"
      autocomplete="off"
      @keyup.esc="escPressed"
      @keydown.up="e => increaseIndex(e, false)"
      @keydown.down="e => increaseIndex(e, true)"
      @keyup.enter="suggestionSelected()"
      @focus="handleDropdownOpening"
      @input="handleFilteredListOnInputEvent"
      @blur="handleDropdownClosing()"
    >
    </vs-input>

    <div
      class="fixed top-0 left-0 h-full w-full z-front"
      v-if="isDropdownVisible"
      @click="handleDropdownClosing"
    ></div>
    <div @click="handleDropdownClosing" v-if="isDropdownVisible">
      <div
        class="flex flex-col w-full top-10 right-0 wo-dropdown"
        ref="listDiv"
      >
        <span
          class="w-full p-1 dropdown-item-border"
          :class="{
            'bg-primary': currentSelected === -1
          }"
          @mouseenter="currentSelected = -1"
        ></span>
        <template v-for="(listItem, index) in filteredList">
          <div
            :key="index"
            @mouseenter="currentSelected = index"
            class="rounded"
            :class="{
              'dropdown-item-border': isThisItemLast(index),
              'wo-auto-complete__current-selected': isThisItemSelected(index)
            }"
            @click="suggestionSelected"
            @mousedown="e => e.preventDefault()"
          >
            <slot :item="listItem"> </slot>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

export default {
  inheritAttrs: false,
  props: {
    initialQuery: String,
    data: Array,
    searchProperty: String,
    inputClasses: String,
    withMagnifier: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup(props, { root, emit }) {
    const input = ref(null);
    const listDiv = ref(null);
    const inputValue = ref(props.initialQuery || '');
    const searchQuery = ref('');
    const inputFocused = ref(false);
    const currentSelected = ref(-1);
    const isDropdownVisible = ref(false);
    let currentScroll = 0;

    watch(
      () => props.initialQuery,
      () => {
        inputValue.value = props.initialQuery;
      }
    );

    const escPressed = () => {
      isDropdownVisible.value = false;
    };

    const handleFilteredListOnInputEvent = () => {
      currentSelected.value = -1;
      searchQuery.value = inputValue.value;
    };

    const filteredList = computed(() => {
      if (props.searchProperty) {
        return props.data.filter(item => {
          return item[props.searchProperty]
            .toLocaleLowerCase('tr')
            .includes(searchQuery.value.toLocaleLowerCase('tr'));
        });
      } else {
        return props.data.filter(item => {
          return item
            .toLocaleLowerCase('tr')
            .includes(searchQuery.value.toLocaleLowerCase('tr'));
        });
      }
    });

    const handleDropdownOpening = () => {
      isDropdownVisible.value = true;
    };

    const handleDropdownClosing = () => {
      isDropdownVisible.value = false;
      inputFocused.value = false;
    };

    const suggestionSelected = () => {
      if (currentSelected.value === -1) {
        searchQuery.value = '';
      } else {
        const itemIndex = currentSelected.value;

        const selectedItem = filteredList.value[itemIndex];

        searchQuery.value = selectedItem[props.searchProperty];
        emit('selected', selectedItem);

        handleDropdownClosing();
        window.location.href = `${root.$baseURL}/${selectedItem.url}`;
      }
    };

    const handleDropdownItemClick = index => {
      suggestionSelected(index);
    };

    watch(inputFocused, () => {
      if (inputFocused) isDropdownVisible.value = true;
      else isDropdownVisible.value = false;
    });

    const increaseIndex = (event, val = true) => {
      event.preventDefault(); // oka bastiginda imlecin basa ve sona gidisini engeller
      if (isDropdownVisible.value) {
        //event islemlerini sadece dropdown acikken tetikle
        if (filteredList.value.length === 0) {
          // Liste bos ise indexi sifirla
          currentSelected.value = -1;
        } else {
          // Liste bos degilse olacak olan islemler
          // listede assagi inme hareketi
          if (val) {
            if (currentSelected.value === filteredList.value.length - 1) {
              currentSelected.value = -1;
              currentScroll = 0;
              listDiv.value.scroll(0, currentScroll);
            } else if (currentSelected.value < filteredList.value.length - 1) {
              // alt siniri secerken index in length i asmasini engeller
              const currentPositionOfTheSelectedDiv = listDiv.value.children
                .item(currentSelected.value + 1 + 1)
                .getBoundingClientRect();
              const currentPositionOfTheMenu = listDiv.value.getBoundingClientRect();
              if (
                currentPositionOfTheSelectedDiv.bottom >=
                currentPositionOfTheMenu.bottom
              ) {
                listDiv.value.scroll(
                  0,
                  (currentScroll += currentPositionOfTheSelectedDiv.height)
                );
              }
              currentSelected.value++;
            }
          } else {
            // listede yukari cikma hareketi
            if (currentSelected.value === -1) {
              currentSelected.value = filteredList.value.length - 1;
              currentScroll =
                listDiv.value.children
                  .item(filteredList.value.length)
                  .getBoundingClientRect().bottom -
                listDiv.value.getBoundingClientRect().bottom;
              listDiv.value.scroll(0, currentScroll);
            } else if (currentSelected.value >= 0) {
              // ust siniri secerken index 0 i asmasini engeller
              const currentPositionOfTheSelectedDiv = listDiv.value.children
                .item(currentSelected.value + 1 - 1)
                .getBoundingClientRect();
              const currentPositionOfTheMenu = listDiv.value.getBoundingClientRect();
              if (
                currentPositionOfTheSelectedDiv.y < currentPositionOfTheMenu.y
              ) {
                listDiv.value.scroll(
                  0,
                  (currentScroll -= currentPositionOfTheSelectedDiv.height)
                );
              }
              currentSelected.value--;
            }
          }
          // ilk bos elemanin listeye dahil olmama durumu controlu
          if (currentSelected.value === -1) {
            inputValue.value = '';
          } else {
            inputValue.value =
              filteredList.value[currentSelected.value][props.searchProperty];
          }
        }
      }
    };

    const isThisItemSelected = index => {
      return currentSelected.value === index;
    };
    const isThisItemLast = index => {
      return index + 1 !== filteredList.value.length;
    };

    return {
      inputValue,
      searchQuery,
      isDropdownVisible,
      filteredList,
      handleDropdownOpening,
      handleDropdownItemClick,
      handleFilteredListOnInputEvent,
      escPressed,
      suggestionSelected,
      handleDropdownClosing,
      input,
      listDiv,
      increaseIndex,
      currentSelected,
      isThisItemSelected,
      isThisItemLast
    };
  }
};
</script>
