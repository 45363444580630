<template>
  <wo-int-input
    v-model="inputVal"
    v-mask="'(####) (###) ### ## ##'"
    placeholder="(____) (___) ___ __ __"
    @blur="formatPhone"
  />
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';
import formatTel from "@/helpers/formatTel";

export default {
  mixins: [inputComponentMixin],
  methods: {
    formatPhone() {
      const formattedVal = formatTel(this.inputVal);
      this.$emit('input', formattedVal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
