<template>
  <wo-modal
    :active="logModal"
    title="Loglar"
    class="wide-dialog-90vw one-step-behind-dialog"
    :closeAct="cancel"
    id="logModal"
    @active="$emit('logModal', $event)"
  >
    <div class="log-modal">
      <div class="log-modal__header">
        <div
          class="log-modal__header__title"
          :class="{ 'log-modal__header__title--active': logs === documentLogs }"
          @click="() => (logs = documentLogs)"
        >
          Evrak
        </div>
        <div
          class="log-modal__header__title"
          :class="{ 'log-modal__header__title--active': logs === detailLogs }"
          @click="logs = detailLogs"
        >
          Satırlar
        </div>
      </div>
      <div class="log-modal__body">
        <div class="log-modal__body__logs">
          <div
            class="log-modal__body__logs__item"
            v-for="log in logs"
            :key="log.id"
          >
            <div class="log-modal__body__logs__item__text">{{ log.text }}</div>
            <div class="log-modal__body__logs__item__date">{{ log.date }}</div>
          </div>
        </div>
      </div>
    </div>
  </wo-modal>
</template>

<script>
import { watch, ref } from '@vue/composition-api';
export default {
  name: 'LogModal',
  props: {
    logData: {
      type: Object,
      default: () => {
        return {
          checkListActivites: [],
          checkListDetailActivities: [],
          silininenDatalar: [],
          eklenenDatalar: []
        };
      }
    },
    logModal: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const documentLogs = ref([]);
    const detailLogs = ref([]);

    const logs = ref([]);

    const parseLogData = () => {
      const detailUpdates = props.logData.checkListDetailActivities || [];
      const documentUpdates = props.logData.checkListActivities || [];
      const addedDatas = props.logData.eklenenDatalar || [];
      const deletedDatas = props.logData.silinenDatalar || [];
      const documentUpdatesLogs = [];
      const detailUpdatesLogs = [];

      documentUpdates.forEach(update => {
        update.data.forEach(data => {
          documentUpdatesLogs.push({
            text: `${update.user_name} tarafından ${data}`.replace(
              /<[^>]*>?/gm,
              ''
            ),
            date: update.created_at
          });
        });
      });

      detailUpdates.forEach(update => {
        update.data.forEach(data => {
          detailUpdatesLogs.push({
            text: `${update.id} idli satırda ${update.user_name} tarafından ${data}`.replace(
              /<[^>]*>?/gm,
              ''
            ),
            date: update.created_at
          });
        });
      });

      const addedDatasLogs = addedDatas.map(data => {
        return {
          text: `${data.id} satırı ${data.created_by} tarafından eklendi`,
          date: data.created_at
        };
      });

      const deletedDatasLogs = deletedDatas.map(data => {
        const rowData = JSON.parse(data.previous_data);
        return {
          text: `${data.id} satırı ${data.created_by} tarafından silindi. (Miktar: ${rowData.miktar} Koli No: ${rowData.koli_no} Palet Numarası: ${rowData.palet_numarasi})`,
          date: data.created_at
        };
      });


      // sort logs by date desc and set to logs
      // logs.value = [
      //   ...documentUpdatesLogs,
      //   ...detailUpdatesLogs,
      //   ...addedDatasLogs,
      //   ...deletedDatasLogs
      // ].sort((a, b) => {
      //   return new Date(b.date) - new Date(a.date);
      // });
      documentLogs.value = documentUpdatesLogs.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      detailLogs.value = [
        ...detailUpdatesLogs,
        ...addedDatasLogs,
        ...deletedDatasLogs
      ].sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      logs.value = documentLogs.value;
    };

    watch(
      () => props.logModal,
      () => {
        parseLogData();
      }
    );

    const cancel = () => {
      emit('logModal', false);
    };

    return {
      cancel,
      logs,
      documentLogs,
      detailLogs
    };
  }
};
</script>

<style lang="scss">
// dont use scss variable
.log-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .log-modal__header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    gap: 3rem;
    height: 3rem;
    background-color: white;
    border-bottom: 1px solid gray;
    .log-modal__header__title {
      font-size: 0.875rem;
      font-weight: 500;
      &:hover {
        cursor: pointer;
        color: #1565c0;
      }
    }
    .log-modal__header__title--active {
      color: #1e88e5;
    }
    .log-modal__header__close {
      cursor: pointer;
      font-size: 1.5rem;
      color: gray;
      &:hover {
        color: gray;
      }
    }
  }
  .log-modal__body {
    flex: 1;
    overflow-y: auto;
    .log-modal__body__logs {
      padding: 1rem;
      .log-modal__body__logs__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        border-bottom: 1px solid gray;
        &:last-child {
          border-bottom: none;
        }
        .log-modal__body__logs__item__text {
          font-size: 0.875rem;
          font-weight: 500;
        }
        .log-modal__body__logs__item__date {
          font-size: 0.75rem;
          color: gray;
        }
      }
    }
  }
}
</style>
