<template>
  <div>
    <wo-table ref="teklifTablo" :data="[]" hoverFlat noDataText="">
      <template slot="thead">
        <wo-th v-once> Sıra </wo-th>
        <wo-th v-if="prevalentBasketTitle"> Sepet Cari </wo-th>
        <wo-th v-once> Stok Kodu </wo-th>
        <wo-th v-once> Stok Adı </wo-th>
        <wo-th v-once> Lot No </wo-th>
        <wo-th v-once> Tek.Miktarı </wo-th>
        <wo-th v-once> Birim </wo-th>
        <wo-th v-once> Maliyet </wo-th>
        <wo-th v-once> KDV </wo-th>
        <!-- <wo-th v-once> Masraf Sepeti Maliyet </wo-th> -->
        <wo-th v-once> Nihai Maliyet </wo-th>
        <wo-th v-once> Marj </wo-th>
        <wo-th v-once> Tek.Birim Fiyat </wo-th>
        <template v-for="kurItem in usedExchangeRates">
          <wo-th :key="kurItem.kod" v-if="kurItem.kod !== mainCurrency">
            Nihai Maliyet ({{ kurItem.kod }})
          </wo-th>
        </template>
        <wo-th v-once> İsk.1 </wo-th>
        <wo-th v-if="showExtraIskonto.isk2"> İsk.2 </wo-th>
        <wo-th v-if="showExtraIskonto.isk3"> İsk.3 </wo-th>
        <wo-th v-if="showExtraIskonto.isk4"> İsk.4 </wo-th>
        <wo-th v-if="showExtraIskonto.isk5"> İsk.5 </wo-th>
        <wo-th v-if="showExtraIskonto.isk6"> İsk.6 </wo-th>
        <wo-th>İskonto Toplam Oran</wo-th>
        <wo-th v-once> Net Birim Fiyat (KDV Hariç) </wo-th>
        <wo-th v-once> Net Birim Fiyat (KDV Dahil) </wo-th>
        <wo-th v-once> Maliyet Tutar (KDV Hariç) </wo-th>
        <wo-th v-once> Satış Tutar (KDV Hariç) </wo-th>
        <wo-th v-once> K/Z (KDV Hariç) </wo-th>
        <template v-for="kurItem in usedExchangeRates">
          <wo-th
            :key="`maliyet-${kurItem.kod}`"
            v-if="kurItem.kod !== mainCurrency"
          >
            Maliyet Tutar (KDV Hariç {{ kurItem.kod }})
          </wo-th>
          <wo-th
            :key="`satis-${kurItem.kod}`"
            v-if="kurItem.kod !== mainCurrency"
          >
            Satış Tutar (KDV Hariç {{ kurItem.kod }})
          </wo-th>
        </template>
        <wo-th v-once> Satış Tutar (KDV Dahil) </wo-th>
        <wo-th v-once> Sil </wo-th>
      </template>

      <template>
        <wo-tr :key="indextr" v-for="(satirData, indextr) in tableArray.list">
          <wo-td>
            {{ indextr + 1 }}
          </wo-td>
          <wo-td v-if="prevalentBasketTitle">
            <vs-input readonly v-model="satirData.cariSepet" />
          </wo-td>
          <wo-td>
            <wo-quick-search
              v-model="satirData.stokKod"
              queryParameters="?tablo=stok&kod=1&q="
              @selected="item => handleQuickSearch(item, 'stok-kod', indextr)"
              :itemRenderer="
                item => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')
              "
            />
          </wo-td>
          <wo-td>
            <wo-quick-search
              v-model="satirData.stokAdi"
              queryParameters="?tablo=stok&ad=1&q="
              @selected="item => handleQuickSearch(item, 'stok-ad', indextr)"
              :itemRenderer="
                item => nameAndCodeWithLinkRenderer(item, '/?s=stok&duzenle=')
              "
            >
              <template v-slot:default="{ item }">
                <span class="block float-right">{{ item.fiyat }}</span>
              </template>
            </wo-quick-search>
          </wo-td>
          <wo-td>
            <wo-select
              :data="satirData.lotlar"
              v-model="satirData.lotNo"
              @input.native="handleBatchChange(indextr)"
            >
              <template v-slot:defaultValue>
                <wo-select-item itemValue="0" text="- Seçiniz -" />
              </template>
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="`${item.lotno}|${item.sonkullanim_tarih}`"
                  :text="`${item.lotno} | ${item.sonkullanim_tarih} |
                  ${numToTrStr(item.miktar)}`"
                  :key="`lotlar-${item.lotno}`"
                />
              </template>
            </wo-select>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.tekMiktari"
              @blur="calculateOneRowValues('miktar', indextr)"
              :id="`miktar-${indextr}`"
              :max="maxAmounts[String(indextr)]"
            />
          </wo-td>

          <wo-td>
            <wo-select
              elementClasses="w-32"
              :data="birimler"
              v-model="satirData.birim"
            >
              <template v-slot:defaultValue>
                <wo-select-item itemValue="0" text=" - " />
              </template>
              <template v-slot:default="{ item }">
                <wo-select-item
                  :itemValue="item.id"
                  :text="item.isim"
                  :key="`${item.id}${indextr}`"
                />
              </template>
            </wo-select>
          </wo-td>
          <wo-td>
            <wo-quick-search
              v-model="satirData.maliyet"
              inputClasses="wo-numeric-input"
              :queryParameters="`?tablo=stok_kalibre_maliyet&depo=${depo}&stok_id=${satirData.stokID}&birim=${satirData.birim}&tarih=${teklifTarihi}&sube=&q=${satirData.maliyet}`"
              @selected="item => handleQuickSearch(item, 'maliyet', indextr)"
              @blur="calculateOneRowValues('maliyet', indextr)"
              :itemRenderer="stockCaliberCostRenderer"
            />
          </wo-td>
          <wo-td>
            <wo-float-input readonly v-model="satirData.kdv" />
          </wo-td>
          <!-- <wo-td>
            <wo-float-input v-model="satirData.masrafSepetiMaliyet" />
          </wo-td> -->
          <wo-td>
            <wo-float-input v-model="satirData.nihaiMaliyet" />
          </wo-td>
          <wo-td>
            <div class="flex flex-row items-center">
              <wo-float-input
                v-model="satirData.marj"
                @blur="calculateOneRowValues('marj', indextr)"
              />
              <span class="text-xl ml-1"> % </span>
            </div>
          </wo-td>
          <wo-td>
            <wo-float-input
              v-model="satirData.tekBirimFiyat"
              @blur="calculateOneRowValues('brut_fiyat', indextr)"
            />
          </wo-td>

          <template v-for="kurItem in usedExchangeRates">
            <wo-td
              class="text-right"
              :key="kurItem.kod"
              v-if="kurItem.kod !== mainCurrency"
            >
              <span class="whitespace-nowrap">
                {{ satirData.nihaiMaliyetKur[kurItem.kod] }}
                {{ kurItem.sembol || kurItem.kod }}
              </span>
            </wo-td>
          </template>

          <wo-td class="flex items-center gap-2">
            <wo-float-input
              v-model="satirData.isk1"
              @blur="calculateOneRowValues('iskonto1', indextr)"
              :min="0"
              :max="100"
            />
            <i
              class="fa fa-plus ml-1 mr-2 mb-1 cursor-pointer"
              @click="handleEnterDiscountModal(true, indextr)"
            ></i>
          </wo-td>
          <wo-td v-if="showExtraIskonto.isk2">
            <wo-float-input
              v-model="satirData.isk2"
              @blur="calculateOneRowValues('iskonto2', indextr)"
              :disabled="parseInt(satirData.isk1) === 100"
              :min="0"
              :max="100"
            />
          </wo-td>
          <wo-td v-if="showExtraIskonto.isk3">
            <wo-float-input
              v-model="satirData.isk3"
              @blur="calculateOneRowValues('iskonto3', indextr)"
              :disabled="parseInt(satirData.isk2) === 100"
              :min="0"
              :max="100"
            />
          </wo-td>
          <wo-td v-if="showExtraIskonto.isk4">
            <wo-float-input
              v-model="satirData.isk4"
              @blur="calculateOneRowValues('iskonto4', indextr)"
              :disabled="parseInt(satirData.isk3) === 100"
              :min="0"
              :max="100"
            />
          </wo-td>
          <wo-td v-if="showExtraIskonto.isk5">
            <wo-float-input
              v-model="satirData.isk5"
              @blur="calculateOneRowValues('iskonto5', indextr)"
              :disabled="parseInt(satirData.isk4) === 100"
              :min="0"
              :max="100"
            />
          </wo-td>
          <wo-td v-if="showExtraIskonto.isk6">
            <wo-float-input
              v-model="satirData.isk6"
              @blur="calculateOneRowValues('iskonto6', indextr)"
              :disabled="parseInt(satirData.isk5) === 100"
              :min="0"
              :max="100"
            />
          </wo-td>
          <wo-td>
            <wo-float-input
              readonly
              :value="satirData.iskontoOran"
              @blur="calculateOneRowValues('iskontoTutar', indextr)"
            />
          </wo-td>
          <wo-td>
            <wo-float-input v-model="satirData.netBirimFiyat" />
          </wo-td>
          <wo-td>
            <wo-float-input v-model="satirData.netBirimFiyatKDV" />
          </wo-td>
          <wo-td>
            <wo-float-input readonly v-model="satirData.maliyetTutar" />
          </wo-td>
          <wo-td>
            <wo-float-input v-model="satirData.satisTutar" />
          </wo-td>
          <wo-td class="text-right">
            <span class="whitespace-nowrap">
              {{ satirData.kz }} {{ mainCurrencySymbol }}
            </span>
          </wo-td>

          <template v-for="kurItem in usedExchangeRates">
            <wo-td
              :key="`maliyet-${kurItem.kod}-${indextr}`"
              class="text-right"
              v-if="mainCurrency !== kurItem.kod"
            >
              <span class="whitespace-nowrap">
                {{ satirData.maliyetTutarKur[kurItem.kod] }}
                {{ kurItem.sembol || kurItem.kod }}
              </span>
            </wo-td>
            <wo-td
              :key="`satis-${kurItem.kod}-${indextr}`"
              class="text-right"
              v-if="mainCurrency !== kurItem.kod"
            >
              <span class="whitespace-nowrap">
                {{ satirData.satisTutarKur[kurItem.kod] }}
                {{ kurItem.sembol || kurItem.kod }}
              </span>
            </wo-td>
          </template>

          <wo-td>
            <wo-float-input readonly v-model="satirData.satisTutarKDV" />
          </wo-td>

          <wo-td>
            <wo-button
              text="✗"
              data-id="satir_sil"
              @click="removeItem(indextr)"
              tabindex="-1"
            />
          </wo-td>
        </wo-tr>

        <wo-tr>
          <wo-td v-once></wo-td>
          <wo-td v-once v-if="prevalentBasketTitle"></wo-td>
          <wo-td v-once></wo-td> <wo-td v-once></wo-td><wo-td v-once></wo-td>
          <wo-td class="whitespace-nowrap text-right">
            {{ totalSingleCost }}
          </wo-td>
          <wo-td v-once></wo-td> <wo-td v-once></wo-td><wo-td v-once></wo-td>
          <wo-td v-once></wo-td> <wo-td v-once></wo-td> <wo-td v-once></wo-td>
          <template v-for="kurItem in usedExchangeRates">
            <wo-td
              :key="`ph-0-${kurItem.kod}`"
              v-if="kurItem.kod !== mainCurrency"
            >
            </wo-td>
          </template>
          <wo-td v-once></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once></wo-td><wo-td v-once></wo-td>
          <wo-td class="whitespace-nowrap text-right">
            {{ totalCostAmount }} {{ mainCurrencySymbol }}
          </wo-td>
          <wo-td class="whitespace-nowrap text-right">
            {{ totalSellAmount }} {{ mainCurrencySymbol }}
          </wo-td>
          <wo-td class="whitespace-nowrap text-right">
            {{ totalKZ }} {{ mainCurrencySymbol }}
          </wo-td>
          <template v-for="kurItem in usedExchangeRates">
            <wo-td
              :key="`total-maliyet-${kurItem.kod}`"
              v-if="kurItem.kod !== mainCurrency"
              class="whitespace-nowrap text-right"
            >
              {{
                totalCostCurrencyAmounts[kurItem.kod] &&
                totalCostCurrencyAmounts[kurItem.kod].value
              }}
              {{ kurItem.sembol || kurItem.kod }}
            </wo-td>
            <wo-td
              :key="`total-satis-${kurItem.kod}`"
              v-if="kurItem.kod !== mainCurrency"
              class="whitespace-nowrap text-right"
            >
              {{
                totalSellCurrencyAmounts[kurItem.kod] &&
                totalSellCurrencyAmounts[kurItem.kod].value
              }}
              {{ kurItem.sembol || kurItem.kod }}
            </wo-td>
          </template>

          <wo-td v-once></wo-td><wo-td v-once></wo-td>
          <wo-td v-once></wo-td>
          <wo-td v-once></wo-td>
        </wo-tr>

        <wo-tr>
          <wo-td v-once></wo-td>
          <wo-td v-once v-if="prevalentBasketTitle"></wo-td>
          <wo-td v-once></wo-td> <wo-td v-once></wo-td><wo-td v-once></wo-td>
          <wo-td v-once></wo-td> <wo-td v-once></wo-td> <wo-td v-once></wo-td>
          <wo-td v-once></wo-td><wo-td v-once></wo-td>
          <wo-td v-once></wo-td>
          <wo-td v-once></wo-td>
          <template v-for="kurItem in usedExchangeRates">
            <wo-td
              :key="`ph-1-${kurItem.kod}`"
              v-if="kurItem.kod !== mainCurrency"
            >
            </wo-td>
          </template>
          <wo-td v-once></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td>
          <wo-td v-once v-if="showExtraIskonto.isk2"></wo-td
          ><wo-td v-once></wo-td>
          <wo-td v-once></wo-td>
          <wo-td class="whitespace-nowrap"> Kar Zarar: </wo-td>
          <wo-td v-once></wo-td>
          <wo-td class="whitespace-nowrap text-right">
            {{ totalKZ }} {{ mainCurrencySymbol }}
          </wo-td>
          <wo-td v-once></wo-td>

          <template v-for="kurItem in usedExchangeRates">
            <wo-td
              :key="`difference-${kurItem.kod}`"
              v-if="kurItem.kod !== mainCurrency"
              class="whitespace-nowrap text-right"
            >
              {{
                differenceTotalCurrencies[kurItem.kod] &&
                differenceTotalCurrencies[kurItem.kod].value
              }}
              {{ kurItem.sembol || kurItem.kod }}
            </wo-td>
            <wo-td
              :key="`placeholder-difference-${kurItem.kod}`"
              v-if="kurItem.kod !== mainCurrency"
            ></wo-td>
          </template>

          <wo-td v-once></wo-td><wo-td v-once></wo-td><wo-td v-once></wo-td>
        </wo-tr>
      </template>
    </wo-table>

    <div class="mt-4 responsive-margin-2xl flex flex-col 2xl:flex-row">
      <div class="responsive-margin-md flex flex-col md:flex-row">
        <wo-button
          text="Yeni Ekle (F2)"
          data-id="yeni"
          @click="handleNewLine()"
        />

        <wo-button
          text="Temizle (Alt + T)"
          data-id="temizle"
          @click="handleClearTable"
        />

        <DownloadExcel
          :exportFields="excelFields"
          :data="excelData"
          :name="excelFileName"
          :footer="footerExcel"
          @click.native="handleExcelButton"
        >
          <wo-button
            text="Excel'e Gönder"
            class="w-full"
            @click.native="handleExcelButton"
          />
        </DownloadExcel>

        <wo-button
          text="Marj Giriş Penceresi"
          @click="handleMarginInputModal(true)"
        />
      </div>
      <div class="responsive-margin-md flex flex-col md:flex-row">
        <wo-button
          text="Maliyetleri Güncelle"
          @click="handleUpdateCostsModal(true)"
        />
        <wo-button
          v-if="expenseBasket.id"
          text="Masraf Sepetini Aç"
          :href="`${$baseURL}?s=masraf_sepeti&duzenle=${expenseBasket.id}`"
          target="_blank"
        />
        <span
          class="self-center text-semibold text-success"
          v-if="expenseBasket.id"
        >
          Çağırılan Masraf Sepeti: {{ expenseBasket.name }}
        </span>
      </div>
    </div>

    <!-- ISKONTO MODAL -->
    <vs-prompt
      @close="handleEnterDiscountModal(false)"
      @cancel="handleEnterDiscountModal(false)"
      @accept="handleEnterDiscountModal('accept')"
      accept-text="Uygula"
      cancel-text="Kapat"
      :active.sync="enterDiscountModal"
      title="İskonto Giriş"
    >
      <div class="flex flex-col items-center space-y-4 p-1">
        <div
          v-for="(key, index) of Object.keys(discountValues)"
          :key="`iskonto${index + 2}`"
          class="flex flex-row space-x-4"
        >
          <span> İskonto {{ index + 2 }} </span>
          <wo-float-input
            v-model="discountValues[key]"
            :min="0"
            :max="100"
            :id="`discountInput-${key}`"
            :disabled="
              parseFloat(discountValues[`isk${index + 1}`]) === 0 ||
              parseFloat(discountValues[`isk${index + 1}`]) === 100
            "
          />
        </div>
        <vs-checkbox v-model="applyDiscountAllRowsValue">
          Tüm Satırlara Uygula
        </vs-checkbox>
      </div>
    </vs-prompt>

    <!-- MARJ GIRIS -->
    <vs-prompt
      @close="handleMarginInputModal(false)"
      @cancel="handleMarginInputModal(false)"
      @accept="handleMarginInputModal('accept')"
      accept-text="Uygula"
      cancel-text="İptal"
      :active.sync="marginInputModal"
      title="Toplu Marj Giriş Ekranı"
    >
      <div class="flex flex-row items-center space-x-4 p-1">
        <span> Marj Miktarı % </span>
        <wo-float-input v-model="marginInputInput" />
      </div>
    </vs-prompt>

    <!-- MALIYETLERI GUNCELLE -->
    <vs-prompt
      @close="handleUpdateCostsModal(false)"
      @cancel="handleUpdateCostsModal(false)"
      @accept="handleUpdateCostsModal('accept')"
      accept-text="Maliyet Getir"
      cancel-text="İptal"
      :active.sync="updateCostsModal"
      title="Maliyet Güncelleme Ekranı"
      class="one-step-behind-dialog"
    >
      <div class="flex flex-row items-center space-x-4 p-1">
        <vs-radio
          v-model="updateCostsRadioValue"
          vs-value="sonGirisleridenGetir"
        >
          Son Girişlerden Getir
        </vs-radio>
        <vs-radio
          v-model="updateCostsRadioValue"
          vs-value="maliyetAnalizdenGetir"
        >
          Maliyet Analizden Getir
        </vs-radio>
      </div>
      <div
        v-if="updateCostsRadioValue === 'maliyetAnalizdenGetir'"
        class="flex flex-row space-x-4 items-center mt-4"
      >
        <label>Maliyet Analiz</label>
        <wo-quick-search
          v-model="updatedCostInputValue"
          queryParameters="?tablo=maliyet_analiz&q="
          @selected="item => handleQuickSearch(item, 'maliyetGuncelle')"
        >
          <template v-slot:default="{ item }">
            <span> {{ item.isim }} (ID: {{ item.id }}) </span>
          </template>
        </wo-quick-search>
        <wo-button
          text="↱"
          color="primary"
          class="py-1 px-4"
          type="border"
          @click="handleDirectCostAnalysis"
          target="_blank"
        />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import {
  numToTrStr,
  trStrToNum,
  numToStrToNum
} from '@/helpers/NumberController';

import {
  errorDialog,
  errorNotify,
  errorConfirmDialog,
  errorConfirmReloadDialog
} from '@/helpers/errorDialogs';

import {
  getBatch,
  getStockUnits,
  getUpdateCosts,
  getBasketsDetail,
  getCostsFromCostAnalysis
} from '@/helpers/ApiController.js';

import { calculateOfferRow } from './helpers/ApiFactory.js';

import { ref, reactive, watch, computed } from '@vue/composition-api';

import useLogs from './consumables/useLogs.js';
import { useCurrency } from './consumables/useCurrency.js';

import { stockCaliberCostRenderer } from '@/helpers/quickSearchListRenderers/qsStockCaliberCost';
import { nameAndCodeWithLinkRenderer } from '@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink';

export default {
  props: {
    basketID: [Number, String],
    prevalentBasketTitle: String,
    expenseBasket: Object,
    depo: [String, Number],
    teklifTarihi: String,
    gecerlilikTarihi: String,
    aciklama: String,
    editTableDetails: Array
  },
  setup(props, { emit, root }) {
    // #region Component Variables
    const loglar = useLogs();

    const {
      mainCurrency,
      usedExchangeRates,
      usableExchangeRates: exchangeRates
    } = useCurrency();

    const tableArray = reactive({
      list: []
    });

    const mainCurrencySymbol = computed(() => {
      for (const obj of exchangeRates.value) {
        if (obj.kod === mainCurrency) {
          return obj.sembol || obj.kod;
        }
      }
      return '';
    });

    const teklifTablo = ref(null);

    const birimler = ref([]);

    const showExtraIskonto = ref({
      isk2: false,
      isk3: false,
      isk4: false,
      isk5: false,
      isk6: false
    });

    const oldCalculateValues = [];

    const totalCostCurrencyAmounts = {};
    const totalSellCurrencyAmounts = {};
    const differenceTotalCurrencies = {};

    // #endregion

    // #region Calculate on Backend
    const oldCalculateKeyPairs = {
      miktar: 'tekMiktari',
      maliyet: 'maliyet',
      marj: 'marj',
      brut_fiyat: 'tekBirimFiyat',
      iskonto1: 'isk1',
      iskonto2: 'isk2',
      iskonto3: 'isk3',
      iskonto4: 'isk4',
      iskonto5: 'isk5',
      iskonto6: 'isk6'
    };

    const createRowToCalculate = row => {
      return {
        maliyet: trStrToNum(row.maliyet),
        marj: trStrToNum(row.marj),
        brut_fiyat: trStrToNum(row.tekBirimFiyat),
        net_fiyat: trStrToNum(row.netBirimFiyat),
        miktar: trStrToNum(row.tekMiktari),
        isk1: trStrToNum(row.isk1),
        isk2: trStrToNum(row.isk2),
        isk3: trStrToNum(row.isk3),
        isk4: trStrToNum(row.isk4),
        isk5: trStrToNum(row.isk5),
        isk6: trStrToNum(row.isk6),
        tutar: trStrToNum(row.satisTutarKDV),
        kdv_toptan: trStrToNum(row.kdv)
      };
    };

    const assignCalculatedRows = (row, newCalcs) => {
      row.tekMiktari = numToTrStr(newCalcs.miktar);
      row.maliyet = numToTrStr(newCalcs.maliyet);
      row.masrafSepetiMaliyet = numToTrStr(newCalcs.urun_basi_maliyet);
      row.nihaiMaliyet = numToTrStr(newCalcs.nihai_maliyet);
      row.marj = numToTrStr(newCalcs.marj);
      row.tekBirimFiyat = numToTrStr(newCalcs.brut_fiyat);
      row.isk1 = numToTrStr(newCalcs.isk1);
      row.isk2 = numToTrStr(newCalcs.isk2);
      row.isk3 = numToTrStr(newCalcs.isk3);
      row.isk4 = numToTrStr(newCalcs.isk4);
      row.isk5 = numToTrStr(newCalcs.isk5);
      row.isk6 = numToTrStr(newCalcs.isk6);
      row.netBirimFiyat = numToTrStr(newCalcs.net_fiyat);
      row.netBirimFiyatKDV = numToTrStr(newCalcs.net_fiyat_kdvli);
      row.satisTutar = numToTrStr(newCalcs.satis_tutar);
      row.kz = numToTrStr(newCalcs.satir_karzarar);
      row.maliyetTutar = numToTrStr(newCalcs.maliyet_tutar);

      for (const kurItem of usedExchangeRates.value) {
        if (kurItem.kod !== mainCurrency) {
          row.nihaiMaliyetKur[kurItem.kod] = numToTrStr(
            newCalcs.kurlara_gore_birim_fiyatlar[kurItem.kod]
          );
          row.maliyetTutarKur[kurItem.kod] = numToTrStr(
            newCalcs.kurlara_gore_maliyet_tutarlar[kurItem.kod]
          );
          row.satisTutarKur[kurItem.kod] = numToTrStr(
            newCalcs.kurlara_gore_kdvsiz_net_satislar[kurItem.kod]
          );
        }
      }

      row.satisTutarKDV = numToTrStr(newCalcs.tutar);
    };

    const maxAmounts = ref({});

    const handleBatchNo = (row, index) => {
      if (parseInt(row.lotNo)) {
        if (
          numToStrToNum(maxAmounts.value[String(index)]) < trStrToNum(row.tekMiktari)
        ) {
          root.$set(row, 'tekMiktari', '0');
          errorDialog('Mevcut lotta bu kadar ürün bulunmamaktadır.');
          return false;
        }
      }
      return true;
    };

    const calculateOneRowValues = (changedArea, idx) => {
      const row = tableArray.list[idx];

      const newVal = row[oldCalculateKeyPairs[changedArea]];
      const oldVal = oldCalculateValues[idx][changedArea];

      if (changedArea === 'marj') {
        const message = `Marj Maliyet Analiz modülünde belirlenen sınırlar içerisinde olmalıdır. (Satır: ${
          parseInt(idx) + 1
        })`;
        if (row.minimumMarj && newVal < row.minimumMarj) {
          errorDialog(message);
          row.marj = oldVal;
          return;
        }
        if (row.maximumMarj && newVal > row.maximumMarj) {
          errorDialog(message);
          row.marj = oldVal;
          return;
        }
      }
      if (changedArea === 'miktar' && row.lotlar) {
        const isBatchExists = handleBatchNo(row, idx);
        if (!isBatchExists) {
          return;
        }
      }

      const maliyetNumeric = trStrToNum(row.maliyet);
      if (maliyetNumeric !== 0 && !isNaN(maliyetNumeric) && row.tekMiktari) {
        if (newVal !== oldVal) {
          calculateOfferRow(
            [createRowToCalculate(row)],
            exchangeRates.value,
            props.expenseBasket.id,
            changedArea
          ).then(response => {
            const {
              data: { rows }
            } = response;
            const newCalcs = rows[0];
            assignCalculatedRows(row, newCalcs);
            root.$set(tableArray.list, idx, row);
          });
          oldCalculateValues[idx][changedArea] = newVal;
        }
      }
    };

    const calculateAllRowValues = changedArea => {
      const rows = tableArray.list.map(row => createRowToCalculate(row));
      calculateOfferRow(
        rows,
        exchangeRates.value,
        props.expenseBasket.id,
        changedArea
      ).then(response => {
        const {
          data: { rows: calculatedRows }
        } = response;

        for (const idx in calculatedRows) {
          const row = tableArray.list[idx];
          assignCalculatedRows(row, calculatedRows[idx]);
          root.$set(tableArray.list, idx, row);

          oldCalculateValues[idx][changedArea] = row[changedArea];
        }
      });
    };
    // #endregion

    // #region Table CRUD
    const createNewLine = () => {
      const newLine = {
        cariSepet: '-',
        basketID: 0,
        stokID: '',
        stokKod: '',
        stokAdi: '',
        lotlar: [],
        lotNo: 0,
        tekMiktari: 0,
        birim: '0',
        maliyet: '0,00',
        masrafSepetiMaliyet: 0,
        nihaiMaliyet: 0,
        nihaiMaliyetKur: {},
        marj: 0,
        minimumMarj: 0,
        maximumMarj: 0,
        maliyetAnalizSatirID: 0,
        sevkMiktari: '',
        tekBirimFiyat: 0,
        isk1: 0,
        isk2: 0,
        isk3: 0,
        isk4: 0,
        isk5: 0,
        isk6: 0,
        netBirimFiyat: 0,
        netBirimFiyatKDV: 0,
        maliyetTutar: 0,
        maliyetTutarKur: {},
        satisTutar: 0,
        satisTutarKur: {},
        satisTutarKDV: 0,
        kz: '0,00',
        kdv: ''
      };

      for (const kurItem of usedExchangeRates.value) {
        newLine.nihaiMaliyetKur[kurItem.kod] = '0,00';
        newLine.maliyetTutarKur[kurItem.kod] = '0,00';
        newLine.satisTutarKur[kurItem.kod] = '0,00';
      }

      root.$set(tableArray.list, tableArray.list.length, newLine);
      oldCalculateValues.push({});
    };

    const handleNewLine = () => {
      const lastLine = tableArray.list[tableArray.list.length - 1];
      if (tableArray.list.length !== 0 && !lastLine.stokID) {
        errorDialog('Lütfen stok seçiniz.');
        return;
      }
      const lastLineSelectorWithoutTotalCalculations =
        '.tr-values:nth-last-child(3)';
      createNewLine();
      root.$nextTick(() => {
        document
          .querySelector(lastLineSelectorWithoutTotalCalculations)
          .children[1].querySelector('input')
          .focus();
      });
    };

    const removeItem = index => {
      document.activeElement.blur();
      const acceptRemoveSatir = () => {
        const basketID = tableArray.list[index].basketID;
        tableArray.list.splice(index, 1);
        const satir = teklifTablo.value.$refs.table.children[index ? index : 1];
        if (satir) {
          satir.querySelector('input').focus();
        }

        let isBasketStockStillExist = false;
        for (const row of tableArray.list) {
          if (row.basketID === basketID) {
            isBasketStockStillExist = true;
            break;
          }
        }
        if (!isBasketStockStillExist) {
          emit('removeBasketFromSelectedBaskets', basketID);
        }
      };
      errorConfirmDialog(
        'Silme işlemini onaylıyor musunuz?',
        acceptRemoveSatir
      );
    };

    const handleClearTable = () => {
      if (tableArray.list.length > 0) {
        errorConfirmDialog('Silme işlemini onaylıyor musunuz?', () => {
          tableArray.list.splice(0);
          emit('removeAllBasketsFromSelectedBaskets');
        });
      } else {
        errorNotify('Silme işlemi yapmak için ilk önce satır eklemelisiniz.');
      }
    };

    const applyEditTableDetails = () => {
      for (const detailRow of props.editTableDetails) {
        root.$set(tableArray.list, tableArray.list.length, detailRow);
        oldCalculateValues.push({});

        if (Object.values(showExtraIskonto.value).every(val => !val)) {
          for (let i = 2; i < 7; i++) {
            if (detailRow[`isk${i}`] !== '0,00' && detailRow[`isk${i}`] !== 0) {
              showExtraIskonto.value[`isk${i}`] = true;
            }
          }
        }
      }
    };

    if (props.editTableDetails && props.editTableDetails.length) {
      applyEditTableDetails();
    }

    watch(
      () => props.editTableDetails,
      () => {
        if (props.editTableDetails) {
          applyEditTableDetails();
        }
      }
    );

    const returnTableCRUD = {
      handleNewLine,
      removeItem,
      handleClearTable
    };
    // #endregion

    // #region TOTAL CALCUALTIONS
    const totalValueCalculator = (property, parentProperty) => {
      if (tableArray.list.length === 0) {
        return '0,00';
      } else {
        let onlyProperty = [];
        if (parentProperty) {
          onlyProperty = tableArray.list.map(
            row => row[parentProperty][property]
          );
        } else {
          onlyProperty = tableArray.list.map(row => row[property]);
        }
        const totalVal = onlyProperty.reduce((sum, val) => {
          if (typeof val === 'string') {
            return trStrToNum(sum) + trStrToNum(val);
          }
          return sum + val;
        });
        if (typeof totalVal === 'string') {
          return totalVal;
        }
        return numToTrStr(totalVal);
      }
    };

    const totalSingleCost = computed(() => {
      return totalValueCalculator('tekMiktari');
    });

    const totalCostAmount = computed(() => {
      return totalValueCalculator('maliyetTutar');
    });

    const totalSellAmount = computed(() => {
      return totalValueCalculator('satisTutar');
    });

    const totalKZ = computed(() => {
      return totalValueCalculator('kz');
    });

    const returnTotalCalculations = {
      totalSingleCost,
      totalCostAmount,
      totalSellAmount,
      totalKZ
    };
    // #endregion

    // #region Excel
    const handleExcelButton = e => {
      if (!tableArray.list.length) {
        // e.preventDefault();
        e.stopPropagation();
        errorNotify(
          'Excel indirme işlemi yapmak için ilk önce satır eklemelisiniz.'
        );
      }
    };

    const excelFields = ref({});

    const excelCallback = val => {
      if (typeof val === 'number') {
        return numToTrStr(val);
      }
      return val;
    };

    const excelExchangeRatesCallback = (val, exchangeRate) => {
      if (val) {
        if (exchangeRate.sembol) {
          return `${val} ${exchangeRate.sembol}`;
        } else {
          return `${val} ${exchangeRate.kod}`;
        }
      } else {
        return '';
      }
    };

    const handleExcelFieldsChange = () => {
      excelFields.value = {};

      if (props.prevalentBasketTitle) {
        excelFields.value['Cari Sepet'] = 'cariSepet';
      }

      excelFields.value['Stok Kod'] = 'stokKod';
      excelFields.value['Stok Adı'] = 'stokAdi';
      excelFields.value['Lot No'] = 'lotNo';
      excelFields.value['Tek Miktarı'] = {
        field: 'tekMiktari',
        callback: excelCallback
      };
      excelFields.value['Birim'] = {
        field: 'birim',
        callback: val => {
          for (const birim of birimler.value) {
            if (`${birim.id}` === val || birim.id === val) {
              return birim.isim;
            }
          }
        }
      };
      excelFields.value['Maliyet'] = {
        field: 'maliyet',
        callback: excelCallback
      };
      // excelFields.value['Masraf Sepeti Maliyet'] = {
      //   field: 'masrafSepetiMaliyet',
      //   callback: excelCallback
      // };
      excelFields.value['Nihai Maliyet'] = {
        field: 'nihaiMaliyet',
        callback: excelCallback
      };
      excelFields.value['Marj'] = {
        field: 'marj',
        callback: excelCallback
      };
      excelFields.value['Tek Birim Fiyat'] = {
        field: 'tekBirimFiyat',
        callback: excelCallback
      };
      for (const kurItem of usedExchangeRates.value) {
        excelFields.value[`Nihai Maliyet (${kurItem.kod})`] = {
          field: `nihaiMaliyetKur.${kurItem.kod}`,
          callback: val => excelExchangeRatesCallback(val, kurItem)
        };
      }

      excelFields.value['İsk.1'] = {
        field: 'isk1',
        callback: excelCallback
      };
      for (const key in showExtraIskonto.value) {
        const iskonto = showExtraIskonto.value[key];
        if (iskonto) {
          excelFields.value[`İsk.${key}`] = {
            field: `isk${key}`,
            callback: excelCallback
          };
        }
      }
      excelFields.value['Net Birim Fiyat (KDV Hariç)'] = {
        field: 'netBirimFiyat',
        callback: excelCallback
      };
      excelFields.value['Net Birim Fiyat (KDV Dahil)'] = {
        field: 'netBirimFiyatKDV',
        callback: excelCallback
      };
      excelFields.value['Maliyet Tutar (KDV Hariç)'] = {
        field: 'maliyetTutar',
        callback: val => {
          return val === 'Kar Zarar:' ? val : excelCallback(val);
        }
      };
      excelFields.value['Satış Tutar (KDV Hariç)'] = {
        field: 'satisTutar',
        callback: excelCallback
      };
      excelFields.value['K/Z (KDV Hariç)'] = {
        field: 'kz',
        callback: excelCallback
      };

      for (const kurItem of usedExchangeRates.value) {
        excelFields.value[`Maliyet Tutar (KDV Hariç ${kurItem.kod})`] = {
          field: `maliyetTutarKur.${kurItem.kod}`,
          callback: val => excelExchangeRatesCallback(val, kurItem)
        };
        excelFields.value[`Satış Tutar (KDV Hariç ${kurItem.kod})`] = {
          field: `satisTutarKur.${kurItem.kod}`,
          callback: val => excelExchangeRatesCallback(val, kurItem)
        };
      }

      excelFields.value['Satış Tutar (KDV Dahil)'] = {
        field: 'satisTutarKDV',
        callback: excelCallback
      };
      excelFields.value['KDV'] = {
        field: 'kdv',
        callback: excelCallback
      };
    };

    handleExcelFieldsChange();

    const excelFileName = computed(() => {
      return `teklif-${props.teklifTarihi}-${props.gecerlilikTarihi}.xls`;
    });

    const excelData = computed(() => {
      const firstRowTotals = {
        tekMiktari: totalSingleCost.value,
        maliyetTutar: totalCostAmount.value,
        satisTutar: totalSellAmount.value,
        maliyetTutarKur: {},
        satisTutarKur: {}
      };

      const secondRowTotals = {
        maliyetTutar: 'Kar Zarar:',
        satisTutarKur: {}
      };

      for (const kurItem of usedExchangeRates.value) {
        firstRowTotals.maliyetTutarKur[kurItem.kod] =
          totalCostCurrencyAmounts[kurItem.kod].value;
        firstRowTotals.satisTutarKur[kurItem.kod] =
          totalSellCurrencyAmounts[kurItem.kod].value;
        secondRowTotals.satisTutarKur[kurItem.kod] =
          differenceTotalCurrencies[kurItem.kod].value;
      }

      firstRowTotals.kz = totalKZ.value;
      secondRowTotals.kz = totalKZ.value;

      return [...tableArray.list, firstRowTotals, secondRowTotals];
    });

    const footerExcel = computed(() => {
      return [`Açıklama: ${props.aciklama}`, `Loglar: ${loglar.value}`];
    });

    const returnExcel = {
      handleExcelButton,
      excelFields,
      excelFileName,
      excelData,
      footerExcel
    };
    // #endregion

    // #region Kurlar
    const initiateExchangeRateCalculations = () => {
      for (const kurItem of usedExchangeRates.value) {
        root.$set(
          totalCostCurrencyAmounts,
          kurItem.kod,
          computed(() => {
            return totalValueCalculator(kurItem.kod, 'maliyetTutarKur');
          })
        );

        root.$set(
          totalSellCurrencyAmounts,
          kurItem.kod,
          computed(() => {
            return totalValueCalculator(kurItem.kod, 'satisTutarKur');
          })
        );

        root.$set(
          differenceTotalCurrencies,
          kurItem.kod,
          computed(() => {
            return numToTrStr(
              trStrToNum(totalSellCurrencyAmounts[kurItem.kod].value) -
                trStrToNum(totalCostCurrencyAmounts[kurItem.kod].value)
            );
          })
        );
      }
    };

    if (usedExchangeRates.value) {
      initiateExchangeRateCalculations();
    }

    let oldExchangeRateLength = 0;

    watch(
      usedExchangeRates,
      () => {
        const checkExchangeRateAdded =
          oldExchangeRateLength < usedExchangeRates.value.length;
        const checkOnlyMainCurrency =
          usedExchangeRates.value.length === 1 &&
          usedExchangeRates.value[0].kod === mainCurrency;

        if (checkExchangeRateAdded && !checkOnlyMainCurrency) {
          calculateAllRowValues('maliyet');
        }
        initiateExchangeRateCalculations();
        oldExchangeRateLength = usedExchangeRates.value.length;
        handleExcelFieldsChange();
      }
      // { deep: true }
    );
    //#endregion

    // #region Iskonto
    const enterDiscountModal = ref(false);

    const discountValues = reactive({
      isk2: 0,
      isk3: 0,
      isk4: 0,
      isk5: 0,
      isk6: 0
    });
    const applyDiscountAllRowsValue = ref(false);

    const discountButtonPressedIndex = ref(-1);
    const handleEnterDiscountModal = (val, index) => {
      if (val === 'accept') {
        if (applyDiscountAllRowsValue.value) {
          for (const row of tableArray.list) {
            for (const key in discountValues) {
              row[key] = discountValues[key];
              if (trStrToNum(discountValues[key]) > 0) {
                showExtraIskonto.value[key] = true;
              }
            }
          }
          calculateAllRowValues('maliyet');
        } else {
          for (const key in discountValues) {
            tableArray.list[discountButtonPressedIndex.value][key] =
              discountValues[key];
            if (trStrToNum(discountValues[key]) > 0) {
              showExtraIskonto.value[key] = true;
            }
          }
          calculateOneRowValues('iskonto6', discountButtonPressedIndex.value);
        }
        handleExcelFieldsChange();
      } else {
        discountButtonPressedIndex.value = index;
        enterDiscountModal.value = val;
      }
      discountValues.isk2 = 0;
      discountValues.isk3 = 0;
      discountValues.isk4 = 0;
      discountValues.isk5 = 0;
      discountValues.isk6 = 0;
      applyDiscountAllRowsValue.value = false;
    };

    // check sum of previous discounts is 100] if yes then disable discount input and made it 0
    const handleDiscountInput = key => {
      // made readonly next discount inputs
      const discountKeyIndex = parseInt(key.replace('isk', ''));
      for (let i = discountKeyIndex + 1; i <= 6; i++) {
        if (parseFloat(discountValues[discountKeyIndex]) >= 100) {
          document
            .getElementById(`discountInput-isk${i}`)
            .setAttribute('readonly', true);
        } else {
          document
            .getElementById(`discountInput-isk${i}`)
            .removeAttribute('readonly');
        }
      }
    };

    const returnDiscount = {
      enterDiscountModal,
      discountValues,
      applyDiscountAllRowsValue,
      handleEnterDiscountModal,
      handleDiscountInput
    };
    // #endregion

    // #region Marj Giris
    const marginInputModal = ref(false);
    const marginInputInput = ref('0,00');

    const handleMarginInputModal = val => {
      if (val === 'accept') {
        const erroredMarginArray = [];
        for (const idx in tableArray.list) {
          const row = tableArray.list[idx];
          if (row.minimumMarj && row.marj < row.minimumMarj) {
            erroredMarginArray.push(parseInt(idx) + 1);
            row.marj = '0,00';
          } else if (row.maximumMarj && row.marj > row.maximumMarj) {
            erroredMarginArray.push(parseInt(idx) + 1);
            row.marj = '0,00';
          } else {
            row.marj = marginInputInput.value;
          }
        }
        if (erroredMarginArray.length) {
          const message = `Marj Maliyet Analiz modülünde belirlenen sınırlar içerisinde olmalıdır. (Satır: ${erroredMarginArray.join(
            ', '
          )})`;
          errorDialog(message);
        }

        calculateAllRowValues('marj');
        marginInputModal.value = false;
      } else if (val && !tableArray.list.length) {
        errorNotify('Toplu marj girmeden önce lütfen satır ekleyiniz.');
      } else {
        marginInputModal.value = val;
      }
    };

    const returnMarginInput = {
      marginInputModal,
      marginInputInput,
      handleMarginInputModal
    };
    // #endregion

    // #region Maliyetleri Guncelle
    const updateCostsModal = ref(false);

    const updateCostsRadioValue = ref('');
    const updatedCostValue = ref('');
    const updatedCostInputValue = ref('');

    const handleUpdateCostsModal = val => {
      if (val === 'accept') {
        if (updateCostsRadioValue.value === 'sonGirisleridenGetir') {
          if (!props.teklifTarihi) {
            errorDialog(
              'Maliyetleri güncellemek için ilk önce teklif tarihi seçmelisiniz.'
            );
          } else if (!props.depo) {
            errorDialog(
              'Maliyetleri güncellemek için ilk önce depo seçmelisiniz.'
            );
          } else {
            const postData = {
              tarih: props.teklifTarihi,
              depo: props.depo,
              coklu_depo: 0,
              stok_id: tableArray.list.map(item => item.stokID)
            };
            getUpdateCosts(postData)
              .then(response => {
                for (const row of tableArray.list) {
                  if (
                    Object.keys(response.data[0]).some(el => el === row.stokID)
                  ) {
                    row.maliyet = numToTrStr(response.data[0][row.stokID]);
                  }
                }
                calculateAllRowValues('maliyet');
              })
              .catch(response => {
                console.log(response);
              });
          }
        } else {
          getCostsFromCostAnalysis(updatedCostValue.value)
            .then(data => {
              for (const row of tableArray.list) {
                const selectedStock = data.find(
                  item => item.stok_id === row.stokID
                );
                if (selectedStock) {
                  row.maliyet = numToTrStr(selectedStock.maliyet);
                  row.minimumMarj = parseFloat(selectedStock.minimum_marj);
                  row.maximumMarj = parseFloat(selectedStock.maksimum_marj);
                  row.maliyetAnalizSatirID = selectedStock.satir_id;
                }
              }
              calculateAllRowValues('maliyet');
            })
            .catch(error => {
              errorDialog(error.data.message);
            });
        }
      } else {
        if (val && !tableArray.list.length) {
          errorNotify('Maliyetleri güncellemeden önce satır eklemelisiniz.');
        } else {
          updateCostsModal.value = val;
        }
      }
    };

    const handleDirectCostAnalysis = () => {
      if (updatedCostValue.value) {
        window.location = `${root.$baseURL}/maliyet_analiz/${updatedCostValue.value}/duzenle`;
      } else {
        errorDialog('Lütfen ilk önce maliyet analiz seçiniz.');
      }
    };

    const returnUpdateCosts = {
      handleDirectCostAnalysis,
      updateCostsModal,
      updatedCostValue,
      updateCostsRadioValue,
      updatedCostInputValue,
      handleUpdateCostsModal
    };
    // #endregion

    // Sepet
    watch(
      () => props.basketID,
      () => {
        if (props.basketID) {
          getBasketsDetail(props.basketID)
            .then(response => {
              // clean empty rows
              for (let row = tableArray.list.length - 1; row >= 0; row--) {
                if (!tableArray.list[row].stokID) {
                  tableArray.list.splice(row, 1);
                }
              }

              const data = response;

              if (typeof data === 'object' && data.length) {
                for (const item of data) {
                  const itemExists = tableArray.list.some(
                    el => el.stokID === item.stok_id
                  );
                  if (!itemExists) {
                    createNewLine();
                    const row = tableArray.list[tableArray.list.length - 1];
                    if (props.prevalentBasketTitle) {
                      row.cariSepet = props.prevalentBasketTitle;
                    }
                    row.stokID = item.stok_id;
                    row.stokKod = item.stok_kod;
                    row.stokAdi = item.stok_isim;
                    row.birim = item.stok_birim1_ad;
                    row.kdv = item.kdv_toptan;
                    row.basketID = props.basketID;
                    row.comingFromBasket = true;
                  }
                  handleExcelFieldsChange();
                }
              }
            })
            .catch(() => {
              errorNotify('Sepet stoklarını getirirken hata oluştu');
            });
        }
      }
    );

    // MasrafSepet
    watch(
      () => props.expenseBasket,
      () => {
        if (tableArray.list.length) {
          calculateAllRowValues('maliyet');
        }
      }
    );

    // #region Birimler
    const callApiStockUnits = async () => {
      try {
        const data = await getStockUnits();
        birimler.value = data;
      } catch (error) {
        errorConfirmReloadDialog(error);
      }
    };

    callApiStockUnits();
    // #endregion

    const handleQuickSearch = (item, type, index) => {
      if (index !== undefined) {
        const row = tableArray.list[index];
        if (type.slice(0, 4) === 'stok') {
          if (item.isSelected) {
            row.stokID = item.id;
            row.stokKod = item.kod;
            row.stokAdi = item.isim;
            row.birim = item.birim;
            getBatch(props.depo, item.id)
              .then(data => {
                row.lotlar = [...data];
              })
              .catch(err => {
                if (err) {
                  errorNotify(err.data.message);
                } else {
                  errorNotify('Lotları getirirken bir hata oluştu.');
                }
              });
          } else if (!item.isSelected && row.stokID) {
            row.stokKod = '';
            row.stokAdi = '';
            row.stokID = 0;
            row.lotlar = [];
            row.lotNo = 0;
            row.maliyetAnalizSatirID = 0;
            row.minimumMarj = 0;
            row.maximumMarj = 0;
            row.kdv = 0;
          }
        } else if (type === 'maliyet') {
          if (item.isSelected) {
            row.maliyet = numToTrStr(item.kalibre_fiyat);
          } else if (!item.isSelected && row.maliyet) {
            row.maliyet = '';
          }
        }
      } else {
        if (type === 'maliyetGuncelle') {
          if (item.isSelected) {
            updatedCostValue.value = item.id;
            updatedCostInputValue.value = item.isim;
          } else if (!item.isSelected && updatedCostValue.value) {
            updatedCostValue.value = 0;
            updatedCostInputValue.value = '';
          }
        }
      }
    };

    const handleBatchChange = index => {
      const row = tableArray.list[index];
      const lotNo = row.lotNo.split('|')[0];
      const maxAmount = trStrToNum(
        row.lotlar.find(item => item.lotno === lotNo).miktar.replace('.', ',')
      );
      root.$set(maxAmounts.value, String(index), maxAmount);
    };

    return {
      mainCurrency,
      usedExchangeRates,
      handleBatchChange,
      tableArray,
      teklifTablo,
      birimler,
      handleQuickSearch,
      showExtraIskonto,
      mainCurrencySymbol,

      calculateOneRowValues,

      ...returnTableCRUD,

      ...returnTotalCalculations,

      totalCostCurrencyAmounts,
      totalSellCurrencyAmounts,
      differenceTotalCurrencies,
      maxAmounts,

      ...returnDiscount,

      ...returnMarginInput,

      ...returnUpdateCosts,

      ...returnExcel,

      numToTrStr,
      nameAndCodeWithLinkRenderer,
      stockCaliberCostRenderer
    };
  }
};
</script>

<style></style>
