import { factory } from '@/helpers/ApiController.js';
import { errorDialog } from '@/helpers/errorDialogs';
import { primaryDialog } from '@/helpers/primaryDialogs';
import {
  feedbackMap,
  hydrateFromClient,
  hydrateFromServer,
  statusMap,
  applicantMap
} from './jobApplicationsHydrater';

export async function fetchStatusList() {
  const url = `application_status`;
  try {
    const { data } = await factory.get(url);
    return data.map(a => hydrateFromServer(a, statusMap));
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Durum listesini getirirken bir sıkıntı oluştu.');
    }
    return [];
  }
}

export async function updateStatus(payload) {
  const url = `application_status/${payload.id}`;
  try {
    const { data } = await factory.put(
      url,
      hydrateFromClient(payload, statusMap)
    );
    primaryDialog(data.message);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Durum listesini getirirken bir sıkıntı oluştu.');
    }
  }
}

export async function createStatus(payload) {
  const url = `application_status`;
  try {
    const { data } = await factory.post(
      url,
      hydrateFromClient(payload, statusMap)
    );
    primaryDialog(data.message);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Durum listesini getirirken bir sıkıntı oluştu.');
    }
  }
}

export async function fetchFeedbackList(id) {
  const url = `application_feedback/${id}`;
  try {
    const { data } = await factory.get(url);
    return data.map(a => hydrateFromServer(a, feedbackMap));
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Notlari getirirken bir sıkıntı oluştu.');
    }
    return [];
  }
}

export async function createFeedback(payload) {
  const url = `application_feedback`;
  try {
    const { data } = await factory.post(
      url,
      hydrateFromClient(payload, feedbackMap)
    );
    primaryDialog(data.message);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Durum listesini getirirken bir sıkıntı oluştu.');
    }
  }
}

export async function fetchApplicantData(id) {
  const url = `/job_application/${id}`;
  try {
    const { data } = await factory.get(url);
    return hydrateFromServer(data, applicantMap);
  } catch (error) {
    if (error.response.data.message) {
      errorDialog(error.response.data.message);
    } else {
      errorDialog('Başvuruyu getirirken bir sıkıntı oluştu.');
    }
    return [];
  }
}
