<template>
  <div class="flex flex-col md:flex-row responsive-margin-md">
    <wo-button text="DOSYALAR" @click="openFilesModal" />
    <wo-button text="Mail Önizleme" @click="previewMailModalState = true" />
    <wo-button text="Yazdır" @click="printDialog = true" data-id="yazdir" />
    <wo-button
      text="Sipariş Oluştur"
      :href="`${$baseURL}/?s=siparisler&duzenle=${$route.params.id}`"
      target="_blank"
    />
    <wo-button text="Şirket İçi Mail" @click="companyEmailModalState = true" />
    <wo-button
      text="Firma veya Firmalara E-Mail Gönder"
      @click="outsideEmailModalState = true"
    />

    <!----------- MODALS ------------>

    <!-- MAIL ONIZLEME -->
    <WOEmailPreview
      :active.sync="previewMailModalState"
      :id="offerID"
      :usedExchangeRates="usedExchangeRates"
    />

    <!-- YAZDIR -->
    <vs-prompt :active.sync="printDialog" title="Dizayn Seç" buttons-hidden>
      <div class="flex flex-row items-center space-x-4 justify-center mb-4">
        <span>Tasarım</span>
        <wo-select v-model="printDesignSelected" :data="printDesigns">
          <template v-slot:default="{ item }">
            <wo-select-item
              :itemValue="item.id"
              :text="item.text"
              :key="`print-design-${item.id}`"
            />
          </template>
        </wo-select>
      </div>
      <div class="flex flex-row justify-end">
        <wo-button text="Yazdır" color="warning" @click="handlePrintButton" />
      </div>
    </vs-prompt>

    <!-- SIRKET ICI MAIL -->
    <WOEmailPreview
      :active.sync="companyEmailModalState"
      :id="offerID"
      title="Teklif Mail Gönderim Ekranı"
      :usedExchangeRates="usedExchangeRates"
      isForCurrentCompany
      willSendMail
    />

    <!-- FIRMALARA EMAIL -->
    <WOEmailPreview
      :active.sync="outsideEmailModalState"
      :id="offerID"
      title="Teklif Mail Gönderim Ekranı"
      :usedExchangeRates="usedExchangeRates"
      :predeterminedEmails="predeterminedEmails"
      willSendMail
    />
  </div>
</template>

<script>
import useCompany from '@/consumables/useCompany';
import printDocument from '@/helpers/printDocument';
import WOEmailPreview from '@/components/WO/Email/WOEmailPreview';

import { getOfferPrintHTML } from '../helpers/ApiFactory.js';
import { useCurrency } from '../consumables/useCurrency.js';
import useCompaniesSendOffer from '../consumables/useCompaniesSendOffer.js';

export default {
  name: 'teklif-edit-buttons',
  components: {
    WOEmailPreview
  },
  props: {
    orderID: [Number, String],
    branchID: [Number, String]
  },
  setup() {
    const currentCompany = useCompany();
    const { usedExchangeRates } = useCurrency();
    const { predeterminedEmails } = useCompaniesSendOffer();

    return {
      currentCompany,
      usedExchangeRates,
      predeterminedEmails
    };
  },
  data() {
    return {
      previewMailModalState: false,
      printDialog: false,
      printDesigns: [],
      printDesignSelected: 0,
      companyEmailModalState: false,
      outsideEmailModalState: false
    };
  },
  mounted() {
    this.initializePrintDesigns();
  },
  methods: {
    openFilesModal() {
      window.ajaxpen(
        750,
        400,
        'Teklifler Dosyaları',
        `dosyalar&kaynak=14&kaynak_id=${this.offerID}&sirket=${this.currentCompany.id}&sube=${this.branchID}`
      );
    },
    initializePrintDesigns() {
      if (window.tasarimlar) {
        this.printDesigns = Object.entries(window.tasarimlar).map(item => ({
          id: item[0],
          text: item[1]
        }));
        this.printDesignSelected = this.printDesigns[0].id;
      }
      // else is handled when printDesigns initialized
    },
    handlePrintButton() {
      getOfferPrintHTML(this.offerID, this.printDesignSelected) // teklif idsi ve tasarim idsi,
        .then(data => {
          printDocument(data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    offerID() {
      return this.$route.params.id;
    }
  }
};
</script>
