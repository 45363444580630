<template>
  <div>
    <base-input
      ref="dateInput"
      :class="inputClasses"
      v-model="inputValFormatted"
      @keydown="handleKeyEvents"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      @change="$emit('change')"
      danger-text="Lütfen geçerli bir tarih giriniz."
      name="Tarih"
      v-bind="$attrs"
      autocomplete="off"
      :danger="error ? !!error[0] : isDateInvalid"
    />
    <datepicker
      @change="$emit('change')"
      class="w-58 fixed z-front"
      input-class="py-2"
      v-model="dateVal"
      v-show="showDatepicker"
      ref="datepickerElm"
      :language="lang"
      format="DD.MM.YYYY"
      @selected="selectedDate"
      monday-first
      typeable
      inline
    />
  </div>
</template>

<script>
import Datepicker from '@hokify/vuejs-datepicker';

import { ref, computed, onMounted, watch } from '@vue/composition-api';
import dropdownPositioner from '@/helpers/dropdownPositioner.js';
import moment from 'moment';

export default {
  inheritAttrs: false,
  components: {
    Datepicker
  },
  props: {
    value: String,
    error: Array,
    inputClasses: String,
    lang: {
      type: String,
      default: 'tr'
    },
    disabled: Boolean
  },
  setup(props, { root, emit }) {
    // * Non-Reactive Variables
    const dateFormat = 'DD.MM.YYYY';
    let usedEnter = false;

    // * Reactive Variables
    const inputVal = ref(props.value || '');
    const dateVal = ref(
      props.value ? moment(props.value, dateFormat).toString() : null
    );
    const isDateInvalid = ref(false);
    const showDatepicker = ref(false);
    const datepickerElm = ref(null);
    const dateInput = ref(null);

    watch(
      () => props.value,
      () => {
        if (props.value.length > 8) {
          dateVal.value = moment(props.value, dateFormat).toString();
          inputVal.value = props.value;
        }
      }
    );
    /* eslint-disable */
    const inputValFormatted = computed({
      get: () => {
        const splitted = inputVal.value.split('.');
        if (splitted[0].length === 2 && splitted.length === 1) {
          // return `${inputVal.value}.`;
          // this.set(`${inputVal.value}.`);
          inputVal.value = `${inputVal.value}.`;
        }
        if (splitted.length === 2 && splitted[1].length === 2) {
          // return `${inputVal.value}.`;
          // this.set(`${inputVal.value}.`);
          inputVal.value = `${inputVal.value}.`;
        }
        if (splitted.length === 3 && splitted[2].length > 3) {
          return `${splitted[0]}.${splitted[1]}.${splitted[2].slice(0, 4)}`;
        }

        return inputVal.value;
      },
      set: val => {
        inputVal.value = val;
      }
    });

    // * Hatali Giris fonksiyonu
    const initiateError = () => {
      isDateInvalid.value = true;
      setTimeout(() => {
        isDateInvalid.value = false;
      }, 4000);
      inputVal.value = '';
      dateVal.value = 'Invalid date';
      emit('input', '');
    };

    // * Event Handlers
    const handleFocus = () => {
      if (props.disabled) return;
      showDatepicker.value = true;
      root.$nextTick(() => {
        dropdownPositioner(datepickerElm.value.$el, dateInput.value.$el);
      });
      emit('focus');
    };

    // * scroll event listener
    const handleScroll = () => {
      if (showDatepicker.value) {
        dropdownPositioner(datepickerElm.value.$el, dateInput.value.$el);
      }
    };
    window.addEventListener('scroll', handleScroll);

    const handleBlur = () => {
      showDatepicker.value = false;
      if (!usedEnter) {
        if (dateVal.value !== 'Invalid date' && dateVal.value !== null) {
          inputVal.value = moment(dateVal.value).format(dateFormat);
          emit('input', inputVal.value);
        } else {
          initiateError();
        }
      }
      usedEnter = false;
      emit('blur');
    };

    const handleInput = e => {
      const splitted = e.split('.');
      // ! yilin 4 rakamdan fazla olmasini engeller
      if (splitted.length === 3 && splitted[2].length > 4) {
        splitted[2] = splitted[2].slice(0, 4);
        e = splitted.join('.');
      }

      // ! momentten gelen default yil 2001 i mevcut yil yapar
      const date = moment(e, dateFormat);
      if (date.year() === 2001 && !(e || '').includes('2001')) {
        date.set('year', moment().year());
      }
      dateVal.value = date.toString();
      emit('input', e);
    };

    const selectedDate = e => {
      inputVal.value = moment(e).format(dateFormat);
      emit('input', inputVal.value);
    };

    const handleKeyEvents = e => {
      const allowedKeys = [
        'Backspace',
        'Tab',
        'Enter',
        'End',
        'Home',
        'ArrowLeft',
        'ArrowUp',
        'ArrowRight',
        'ArrowDown',
        'Delete',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.'
      ];
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
        return;
      }
      // son girilmis deger . ise nokta koydurmama ve silme
      if (inputVal.value.slice(-1) === '.') {
        if (e.key === '.') {
          // ! son deger . ise . koymasini engeller
          e.preventDefault();
          return;
        } else if (e.key === 'Backspace') {
          inputVal.value = inputVal.value.slice(0, -2);
          emit('input', inputVal.value);
          e.preventDefault(); // fazla silmeyi engellemek icin
          return;
        }
      }

      const splitted = inputVal.value.split('.');

      if (e.key === 'Enter') {
        usedEnter = true;
        if (splitted.length >= 3) {
          if (splitted[2].length === 0) {
            // yil kismi bossa
            inputVal.value = moment().format(dateFormat);
            dateVal.value = moment(inputVal.value, dateFormat).toString();
            emit('input', inputVal.value);
          } else {
            inputVal.value = moment(dateVal.value).format(dateFormat);
            dateVal.value = moment(inputVal.value, dateFormat).toString();
            emit('input', inputVal.value);
          }
        } else {
          // yil kismi yazilmamissa
          inputVal.value = moment().format(dateFormat);
          dateVal.value = moment(inputVal.value, dateFormat).toString();
          emit('input', inputVal.value);
        }
        if (dateVal.value === 'Invalid date') {
          initiateError();
        }
      }
    };

    // ! Datepicker componentinin hidden inputunu non-tabbable yapar.
    onMounted(() => {
      datepickerElm.value.$el.querySelector('input').tabIndex = -1;
    });

    return {
      inputVal,
      dateVal,
      isDateInvalid,
      showDatepicker,
      datepickerElm,
      dateInput,
      inputValFormatted,
      handleFocus,
      handleBlur,
      handleInput,
      selectedDate,
      handleKeyEvents
    };
  }
};
</script>
