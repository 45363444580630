export function personnelRenderer(item) {
  return `
    <a
      :href="?s=personel&duzenle=${item.id}"
      target="_blank"
      tabindex="-1"
      class="underlined-hovered-link"
    >
      ${item.carikod_personel}
    </a>
    &nbsp;
    ${item.ad}
    ${item.soyad}
    &nbsp;
    <span class="text-danger"> (ID: ${item.id}) </span>
  `;
}
