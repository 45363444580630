import { reactive } from '@vue/composition-api';

const state = reactive({
  companyID: 0,
  companyName: '',
  depatmentID: 0,
  departmentName: '',
  applicationHeader: '',
  position: '',
  qualifications: '',
  jobDescription: '',
  workingType: '0',
  education: '0',
  military: '0',
  experience: '0',
  gender: '0',
  disabled: '0',
  address: ''
});

export function useJobListingState() {
  return state;
}
