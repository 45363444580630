<template>
  <base-input
    v-model="inputVal"
    @keypress="handleCharKeyPress"
    :danger="danger"
    :dangerText="dangerText"
    v-on="$listeners"
    v-bind="$attrs"
    :inputClass="inputClass"
  />
</template>

<script>
import inputComponentMixin from '@/mixins/inputComponentMixin.js';

export default {
  name: 'WOCharInput',
  inheritAttrs: false,
  mixins: [inputComponentMixin],
  methods: {
    handleCharKeyPress(event) {
      event = event ? event : window.event;
      const isNumber = event.key.match(/\D/);
      if (!isNumber) {
        event.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
