import Vue from '@/main';

export function primaryConfirmDialog(
  text,
  acceptCallback,
  title = 'Onay',
  acceptText = 'Tamam',
  cancelCallback,
  cancelText = 'İptal'
) {
  Vue.$vs.dialog({
    type: 'confirm',
    color: 'primary',
    title,
    text,
    acceptText,
    accept: acceptCallback,
    cancelText,
    cancel: cancelCallback
  });
}

export function primaryDialog(text, title, acceptCallback) {
  Vue.$vs.dialog({
    color: 'primary',
    title,
    text,
    acceptText: 'Tamam',
    accept: acceptCallback
  });
}
