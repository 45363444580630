import { ref } from '@vue/composition-api';
import { getCompanies } from '@/helpers/ApiController';

const currentCompany = ref({});

export default function useCompany() {
  const isDev = window.location.origin.includes('localhost');

  getCompanies().then(response => {
    if (isDev) {
      currentCompany.value = response.data[0];
    } else {
      for (const item of response.data) {
        if (item.unvan === window.sirket_unvan) {
          currentCompany.value = item;
        }
      }
    }
  });
  return currentCompany;
}
