import { reactive, ref } from '@vue/composition-api';

const id = ref(0);

const formsData = reactive({
  modelCode: {
    name: 'Model Kodu',
    serverKey: 'model_kodu',
  },
 modelName: {
    name: 'Model Adı',
    serverKey: 'model_adi',
  },
  yarnQuality: {
    name: 'Materyal Kalitesi',
    serverKey: 'materyal_kalitesi',
  },
  collection: {
    name: 'Koleksiyon',
    serverKey: 'koleksiyon',
  },
  paketleme: {
    name: 'Varyant Paketleme',
    serverKey: 'varyant_paketleme',
  },
  yikamaIbareleri: {
    name: 'Yıkama İbareleri',
    serverKey: 'yikama_ibareleri',
  },
  color: {
    name: 'Renk',
    serverKey: 'renk',
  },
  size: {
    name: 'Beden',
    serverKey: 'beden',
  },
  designNumber: {
    name: 'Desen No',
    serverKey: 'desen_numarasi'
  },
  designer: {
    name: 'Desenci',
    serverKey: 'desenci'
  },
  exitTime: {
    name: 'Atölye Çıkış Dk',
    serverKey: 'atölye_cikis_dk'
  },
});

export {id, formsData};