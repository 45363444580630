<template>
  <form>
    <h5 class="text-xl font-medium mb-4">Model Bilgileri</h5>
    <ModelPlanlamaInformation :stateKey="stateKey" />
    <wo-divider />
    <h5 class="text-xl font-medium mb-8">Model Detayları</h5>
    <ModelPlanlamaDetails :stateKey="stateKey" />
  </form>
</template>

<script>
import ModelPlanlamaInformation from './Information/ModelPlanlamaInformation.vue';
import ModelPlanlamaDetails from './Details/ModelPlanlamaDetails.vue';

export default {
  components: {
    ModelPlanlamaInformation,
    ModelPlanlamaDetails
  },
  props: {
    stateKey: {
      validator(val) {
        return ['prototype', 'confirmed'].indexOf(val) !== -1;
      }
    }
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
