const tableColumns = [
    {
        title: 'Kalem Türü',
        key: 'entryType',
        types: ["0", "1", "2"],
        components: ["form"]
    },
    {
        title: 'Ana Materyal',
        key: 'primaryMaterial',
        types: ["0", "1", "2"],
        components: ["form", "table"],
        modalLink: "gp_model_planning_materyal",
    },
    {
        title: 'Süreç',
        key: 'process',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: 'Kalem',
        key: 'entryName',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: 'Cari',
        key: 'prevalentName',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: 'Miktar',
        key: 'amount',
        types: ["0", "1"],
        components: ["form", "table"]
    },
    {
        title: 'Sepet Miktarı',
        key: 'amount',
        types: ["2"],
        components: ["form", "table"]
    },
    {
        title: 'Sipariş Miktarı',
        key: 'orderAmount',
        types: ["2"],
        components: ["form", "table"]
    },
    {
        title: 'Birim',
        key: 'unit',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: 'Maliyet',
        key: 'cost',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: 'Renk/Beden',
        key: 'colorsize',
        types: ["0", "1"],
        components: ["table"]
    },
    {
        title: 'Para Birimi',
        key: 'currency',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: `Tutar`,
        key: 'mainCurrencyPrice',
        types: ["0", "1", "2"],
        components: ["form", "table"],
    },
    {
        title: 'Açıklama',
        key: 'explanation',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    },
    {
        title: 'İşlemler',
        key: '',
        types: ["0", "1", "2"],
        components: ["form", "table"]
    }
];

export default function getColumns(type, component) {
    return tableColumns.filter(
        column => column.types.includes(type) &&
            column.components.includes(component)
    ).map(column => {
        return {
            title: column.title,
            key: column.key,
            modalLink: column.modalLink,
        };
    });
}