<template>
      <wo-modal
        :active="customizeLabelsModal"
        @active="$emit('customizeLabelsModal', $event)"
        title="Label Özelleştir"
        :accept="saveLabels"
        :closeAct="() => $emit('customizeLabelsModal', false)"
      >
            <div class="labelForm" v-for="label in Object.keys(formsData)" :key="label">
                <span>{{formsData[label].name}}</span>
                <base-input v-model="labelsData[label]"/>
        </div>
      </wo-modal>  
</template>

<script>
import { computed } from '@vue/composition-api';
import {id, formsData} from '../../composables/formsData';
import {updateLabels} from '../../helpers/modelPlanningApiService';
import { primaryDialog } from '@/helpers/primaryDialogs';
import { errorDialog } from '@/helpers/errorDialogs';
 export default {
    name: "CustomizeLabelsModal",
    props: {
        customizeLabelsModal: Boolean
    },
    setup(props, context) {

        const labelsData = computed(() => {
            const keys = Object.keys(formsData);
            const obj = Object.fromEntries(keys.map(key => [key, ""]));
            return obj;
        });

        const saveLabels = async () => {
            const keys = Object.keys(labelsData.value);
            const labels = Object.fromEntries(keys.filter(key => labelsData.value[key] !== "").map(key => [key, labelsData.value[key]]));
            context.emit('customizeLabelsModal', false);
            const result = await updateLabels(id.value, labels);
            if (result.status === 200) {
                primaryDialog("Başarıyla güncellendi", "Başarılı");
                for (const key in labels) {
                    formsData[key].name = labels[key];
                }
            } else {
                errorDialog("Güncelleme başarısız", "Hata");
            }
        };

        return {
            formsData,
            labelsData,
            saveLabels,
            props
        };
    }
 };
</script>

<style scoped>
    .labelForm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        flex-direction: row;
    }
    @media screen and (max-width: 799px) {
        .labelForm {
            flex-direction: column;
        }
    }
        
</style>