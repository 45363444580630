<template>
  <div class="marquee-alternative-div">
    <template class="whitespace-nowrap" v-for="item in data">
      <slot v-bind:item="item" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: [Array, Object]
  }
};
</script>

<style lang="scss" scoped></style>
