<template>
    <wo-table v-if="stocks.length > 0 || semiProducts.length > 0" id="table" data-cols-width="45,8,45" data-b-a-s="hair">
        <wo-tr data-height="32.5" data-a-v="middle"><wo-th colspan="5" data-a-v="middle" data-f-sz="14" class="text-lg" data-fill-color="d9d9d9" data-f-bold="true" data-a-h="center">Malzeme Döküm Listesi</wo-th></wo-tr>
        <template>
            <wo-th data-fill-color="d9d9d9" data-f-bold="true" data-f-sz="14" data-a-h="center">STOK</wo-th>
            <wo-th data-fill-color="d9d9d9" data-f-bold="true" data-f-sz="14" data-a-h="center">Miktar</wo-th>
            <wo-th data-fill-color="d9d9d9" data-f-bold="true" data-f-sz="14" data-a-h="center">Miktar</wo-th>
            <wo-th data-fill-color="d9d9d9" data-f-bold="true" data-f-sz="14" data-a-h="center">Toplam</wo-th>
            <wo-th data-fill-color="d9d9d9" data-f-bold="true" data-f-sz="14" data-a-h="center">Birim</wo-th>
        </template>
        <template v-for="(semiProduct, semiProductIndex) in semiProducts">
            <wo-tr :key="semiProduct.id">
                <wo-td class="text-danger text-lg" data-f-color="f53636" data-f-sz="13">{{ semiProduct.entryName }}</wo-td>
                <wo-td class="text-danger" data-a-h="right" data-f-color="f53636" style="text-align: right;">{{ semiProduct.amount }}</wo-td>
                <wo-td></wo-td>
                <wo-td class="text-danger" data-a-h="right" data-f-color="f53636" style="text-align: right;" v-if="!semiProduct.semiProductDetails">{{ semiProduct.amount }}</wo-td>
            </wo-tr>
            <wo-tr v-for="detail in subSemiProducts[semiProductIndex]" :key="detail.stockID">
                <wo-td>{{ detail.stockName }}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{ semiProduct.amount }}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{ detail.amount }}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{ parseFloat(detail.amount.replace('.', '').replace(',', '.')).toFixed(3) * parseFloat(semiProduct.amount.replace('.', '').replace(',', '.')).toFixed(3) }}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{ getUnitById(detail.unit) }}</wo-td>
            </wo-tr>
        </template>
        <template v-for="stock in stocks">
            <wo-tr :key="stock.id">
                <wo-td class="text-danger text-lg" data-f-color="f53636" data-f-sz="13">{{ stock.entryName }}</wo-td>
                <wo-td style="text-align: right;" class="text-danger" data-a-h="right" data-f-color="f53636">{{ stock.amount }}</wo-td>
                <wo-td style="text-align: right;" class="text-danger" data-a-h="right" data-f-color="f53636">{{ stock.amount }}</wo-td>
                <wo-td style="text-align: right;" class="text-danger" data-a-h="right" data-f-color="f53636">{{ stock.amount }}</wo-td>
                <wo-td style="text-align: right;" class="text-danger" data-a-h="right">{{ getUnitById(stock.unit) }}</wo-td>
            </wo-tr>
        </template>
        <wo-tr v-for="(key, value) in unitTotals" :key="key">
            <wo-td></wo-td>
            <wo-td></wo-td>
            <wo-td></wo-td>
            <wo-td style="text-align: right;" data-a-h="right">{{key}}</wo-td>
            <wo-td style="text-align: right;" data-a-h="right">{{value}}</wo-td>
        </wo-tr>
        <wo-tr>
            <wo-td></wo-td>
            <wo-td></wo-td>
            <wo-th data-fill-color="d9d9d9" data-f-bold="true">TOPLAM</wo-th>
            <wo-td data-fill-color="d9d9d9" style="text-align: right;" data-a-h="right">{{totalWeights.replace(".", ",")}}</wo-td>
        </wo-tr>
        <template>
            <wo-tr></wo-tr>
            <wo-tr>
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-th class="text-lg text-left" data-fill-color="d9d9d9" data-f-bold="true">ÖZET</wo-th>
            </wo-tr>
            <wo-tr v-for="code in subSemiProductsCodes" :key="code">
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-td>{{ findSubSemiProductName(code) }}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{ totalWeight[code] }}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{ getUnitById(parseInt(code)) }}</wo-td>
            </wo-tr>
            <wo-tr v-for="semiProduct in filteredSemiProducts" :key="`semi-${semiProduct.stockID}`">
                    <wo-td></wo-td>
                    <wo-td></wo-td>
                    <wo-td class="text-danger" data-f-color="f53636" data-f-sz="13">{{ semiProduct.entryName }}</wo-td>
                    <wo-td class="text-danger" data-a-h="right" style="text-align: right;">{{ semiProduct.amount }}</wo-td>
                    <wo-td class="text-danger" data-a-h="right" style="text-align: right;">{{ getUnitById(semiProduct.unit) }}</wo-td>
            </wo-tr>
            <wo-tr v-for="stock in stocks" :key="`stock-${stock.entryID}`">
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-td class="text-danger" data-f-color="f53636" data-f-sz="13">{{ stock.entryName }}</wo-td>
                <wo-td class="text-danger" data-a-h="right" style="text-align: right;">{{ stock.amount }}</wo-td>
                <wo-td class="text-danger" data-a-h="right" style="text-align: right;">{{ getUnitById(stock.unit) }}</wo-td>
            </wo-tr>
            <wo-tr v-for="(key, value) in unitTotals" :key="key">
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{key}}</wo-td>
                <wo-td style="text-align: right;" data-a-h="right">{{value}}</wo-td>
            </wo-tr>
            <wo-tr>
                <wo-td></wo-td>
                <wo-td></wo-td>
                <wo-td data-f-bold="true" data-fill-color="d9d9d9" style="font-weight: bold;">TOPLAM</wo-td>
                <wo-td  data-fill-color="d9d9d9" style="text-align: right;" data-a-h="right">{{totalWeights.replace(".", ",")}}</wo-td>
                <wo-td style="text-align: right;">
                    <i class="fa fa-print fa-2x" aria-hidden="true" @click="exportPDF"></i>
                    <i class="fa fa-file-excel-o ml-3 fa-2x" aria-hidden="true" @click="downloadExcel"></i>
                </wo-td>
            </wo-tr>
        </template>
    </wo-table>
</template>

<script>
import { computed, watch, ref, onMounted, onUpdated} from '@vue/composition-api';
import {useModelPlanlamaState} from '../../composables/useModelPlanlamaState';
import { downloadPDF } from '../../helpers/modelPlanningApiService';
import TableToExcel from '@linways/table-to-excel';

export default {
    props: {
        stateKey: String,
    },
    setup(props) {
        const {state, stockUnits} = useModelPlanlamaState();

        const localData  = computed(() => {
            return state[props.stateKey].details;
        });

        const stocks = computed(() => {
            return localData.value.stocks;
        });

        const semiProducts = computed(() => {
            return localData.value.semiProducts;
        });

        const filteredSemiProducts = computed(() => {
            return semiProducts.value.filter(semiProduct => !semiProduct.semiProductDetails || semiProduct.semiProductDetails.length <= 0);
        });

        const getSemiProductDetails = (semiProductIndex) => {
            return state[props.stateKey].details.semiProducts[semiProductIndex].semiProductDetails;
        };

        const subSemiProducts = ref([]);

        const findSubSemiProductName = (code) => {
            let name = "";
             subSemiProducts.value.forEach(item => {
                if (item) {
                    item.forEach(subItem => {
                        if (subItem.stockID === code) {
                            name =  subItem.stockName;
                        }
                    });
                }
            });
            return name;
        };

        const subSemiProductsCodes = computed(() => {
            const codes = [];
            subSemiProducts.value.forEach(subSemiProduct => {
                if (subSemiProduct) {
                    subSemiProduct.forEach(item => {
                        if (!codes.includes(item.stockID)) {
                            codes.push(item.stockID);
                        }
                    });
                }
            });
            return codes;
        });

        // TODO filtreye gore getirilecek veriler icin helpers yaz
        const getUnitById = (id) => {
            let code = id;
            if (typeof id === "number") {
                subSemiProducts.value.forEach(item =>  {
                    if (item) {
                        item.forEach(subItem => {
                            if (parseInt(subItem.stockID) === id) {
                                code = subItem.unit;
                            }
                    });
                    }
                });
            }
            return stockUnits.value.find(item => item.id === parseInt(code)) ?  stockUnits.value.find(item => item.id === parseInt(code)).isim : '';
        };

        watch(
            () => state[props.stateKey].details.semiProducts,
            () => {
                subSemiProducts.value = [];
                for (let i = 0; i < state[props.stateKey].details.semiProducts.length; i++) {
                    subSemiProducts.value[i] = getSemiProductDetails(i);
                }
            },
            {
                deep: true,
            }
        );

        onMounted(() => {
            subSemiProducts.value = [];
            for (let i = 0; i < state[props.stateKey].details.semiProducts.length; i++) {
                subSemiProducts.value[i] = getSemiProductDetails(i);
            }
        });

        const collectMaterials = (key, obj) => {
                localData.value[key].forEach(item => {
                    if (!(key === 'semiProducts' && (item.semiProductDetails && item.semiProductDetails.length > 0))) {
                        if (!obj[item.entryID]) {
                            obj[item.entryID] = parseFloat("0,00");
                        }
                        obj[item.entryID] = (parseFloat(item.amount.replace(",", ".")) + parseFloat(obj[item.entryID])).toFixed(4);
                    }
                });
        };

        const totalWeight = computed(() => {
            const totals = {};
            semiProducts.value.forEach((semiProduct, index) => {
                if (subSemiProducts.value[index]) {
                    subSemiProducts.value[index].forEach(detail => {
                        if (!totals[detail.stockID]) {
                            totals[detail.stockID] = parseFloat("0.00");
                        }
                        totals[detail.stockID] += parseFloat(detail.amount.replace('.', '').replace(',', '.')).toFixed(3) * parseFloat(semiProduct.amount.replace('.', '').replace(',', '.')).toFixed(3);
                    });
                }
            });
           collectMaterials('stocks', totals);
           collectMaterials('semiProducts', totals);
            return totals;
        });

        const collectByUnit = (key, obj) => {
            let unitId = 0;
            if (key === 'semiProducts') {
                localData.value[key].forEach(item => {
                    if (item.semiProductDetails && item.semiProductDetails.length > 0) {
                        item.semiProductDetails.forEach(detail => {
                            unitId = getUnitById(detail.unit);
                            if (!obj[unitId]) {
                                obj[unitId] = parseFloat("0.00");
                            }
                            obj[unitId] = ((parseFloat(detail.amount.replace('.', '').replace(',', '.')) * parseFloat(item.amount.replace('.', '').replace(',', '.')) + parseFloat(obj[unitId]))).toFixed(4).replace(".", ",");
                        });
                    } else {
                        unitId = getUnitById(item.unit);
                        if (!obj[unitId]) {
                            obj[unitId] = parseFloat("0.00");
                        }
                        obj[unitId] = (parseFloat(item.amount.replace(',', '.')) + parseFloat(obj[unitId])).toFixed(4).replace(".", ",");
                    }
                });
            } else {
                localData.value[key].forEach(item => {
                    unitId = getUnitById(item.unit);
                    if (!obj[unitId]) {
                        obj[unitId] = parseFloat("0.00");
                    }
                    obj[unitId] = (parseFloat(item.amount.replace(',', '.')) + parseFloat(obj[unitId])).toFixed(4).replace(".", ",");
                });
            }
        };

        const unitTotals = computed(() => {
            const totals = {};
            collectByUnit('stocks', totals);
            collectByUnit('semiProducts', totals);
            return totals;
        });

        const totalWeights = computed(() => {
            let total = 0;
            for (const key in totalWeight.value) {
                total = parseFloat(total) + parseFloat(totalWeight.value[key]);
            }
            total = total.toFixed(4);
            return total;
        });

        const exportPDF = () => {
            downloadPDF(document.getElementById('table').outerHTML, state[props.stateKey].info.modelName, state[props.stateKey].info.modelCode);
        };

        const downloadExcel = () => {
            TableToExcel.convert(document.getElementById('table'), {name: `Model | ${state[props.stateKey].info.modelName} - ${state[props.stateKey].info.modelCode}.xlsx`});

        };

        onMounted(() => {
            const materailCastingListTableEvent = new CustomEvent('materialCastingListTableEvent', {detail: {data: document.getElementById('table')}}); 
            window.dispatchEvent(materailCastingListTableEvent);
        });

        onUpdated(() => {
            const materailCastingListTableEvent = new CustomEvent('materialCastingListTableEvent', {detail: {data: document.getElementById('table')}}); 
            window.dispatchEvent(materailCastingListTableEvent);
        });

        return {
            state,
            localData,
            stocks,
            semiProducts,
            getSemiProductDetails,
            subSemiProducts,
            totalWeights,
            totalWeight,
            subSemiProductsCodes,
            findSubSemiProductName,
            getUnitById,
            filteredSemiProducts,
            unitTotals,
            collectMaterials,
            collectByUnit,
            exportPDF,
            downloadExcel
        };
    }
};
</script>

<style>
</style>
