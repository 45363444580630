/* 
  Objeleri verilen key, dil ve type a gore siralamaya sokar.
  
  objA: karsilastiralacak ilk item => string, number
  objB: karsilastiralacak ikinci item  => string, number
  key: karsilastirirken baz alinacak obje key'i => string
  lang: karsilastirma dili

  muhtemel kullanim:
  obj.sort((a,b) => objectSorter(a,b,'key','tr'))
*/

export function objectSorterByValue(objA, objB, key, lang = 'tr') {
  let a = null,
    b = null;
  if (typeof objA === 'string') {
    if (lang === 'tr') {
      a = objA[key].toLocaleLowerCase('tr');
      b = objB[key].toLocaleLowerCase('tr');
    } else if (lang === 'en') {
      a = objA[key].toLowerCase();
      b = objB[key].toLowerCase();
    } else {
      console.log('Dogru dil parametresini veriniz.');
    }
  } else {
    a = objA[key];
    b = objB[key];
  }
  return a.localeCompare(b, lang);
}

export function objectSorterByKey(obj) {
  return Object.keys(obj)
    .sort((a, b) => {
      return a
        .toLocaleLowerCase('tr')
        .localeCompare(b.toLocaleLowerCase('tr'), 'tr');
    })
    .reduce(function(result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}
