<template>
    <div>
        <h5 class="text-xl font-medium mb-4">Modelhane Formu</h5>
        <ModelDesignInformation />
        <wo-divider />
        <ModelDesignTables />
        <ModelDesignButtons />
    </div>
</template>

<script>
import ModelDesignInformation from './ModelDesignInformation.vue';
import ModelDesignTables from './ModelDesignTables.vue';
import ModelDesignButtons from './ModelDesignButtons.vue';
export default {
    components: {
        ModelDesignInformation,
        ModelDesignTables,
        ModelDesignButtons
    },
    setup() {}
};
</script>