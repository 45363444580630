<template>
  <vs-prompt
    @close="$emit('update:active', false)"
    buttons-hidden
    :active="active"
    class="wide-dialog-1000 one-step-behind-dialog"
    title="Teklif Gönderilecek Firmalar"
  >
    <div class="space-y-4 p-1">
      <div
        class="flex flex-col md:flex-row justify-between responsive-margin-md"
      >
        <div
          class="flex flex-col sm:flex-row justify-center items-center responsive-margin-sm"
        >
          <wo-select v-model="selectValue" :data="selectData">
            <template v-slot:default="{ item }">
              <wo-select-item
                :itemValue="item.tablo"
                :text="item.text"
                :key="`${item.tablo}`"
              />
            </template>
          </wo-select>
          <wo-quick-search
            v-model="quickSearchValue"
            :queryParameters="`?tablo=${selectValue}&q=`"
            @selected="item => handleQuickSearchValue(item)"
          >
            <template v-slot:default="{ item }">
              <span> {{ item.isim }} </span>
              <a
                v-if="selectValue !== 'konseptler'"
                :href="
                  selectData.find(data => data.tablo === selectValue).key ===
                  'unvan'
                    ? `${$baseURL}/?s=cari&duzenle=${item.id}`
                    : `
                  ${$baseURL}/?s=rehber&duzenle=${item.id}`
                "
                target="_blank"
                tabindex="-1"
              >
                -
                <feather-icon
                  icon="CornerUpRightIcon"
                  svgClasses="h-4 w-4 mb-1 stroke-current hover:text-primary"
                />
              </a>
            </template>
          </wo-quick-search>
        </div>
        <div class="flex flex-row justify-center space-x-2">
          <wo-button text="Getir" @click="handleFetchButton" />
          <a :href="`${$baseURL}/?s=rehber&yeni=1`" target="_blank"
            ><wo-button text="Ekle"></wo-button
          ></a>
          <vs-input
            placeholder="Tabloda Arama"
            autocomplete="off"
            v-model="tableFilter"
          />
        </div>
      </div>
      <vs-table
        stripe
        hoverFlat
        multiple
        v-model="selectedCheckboxes"
        noDataText=""
        :data="tableFilteredData"
      >
        <template slot="thead">
          <vs-th>Konsept</vs-th>
          <vs-th>Firma</vs-th>
          <vs-th>Teklif Yetkili İsim</vs-th>
          <vs-th>Teklif Yetkilisi Mail</vs-th>
          <vs-th>İşlemler</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr v-for="item in data" :key="`tgf-${item.id}`" :data="item">
            <vs-td
              class="whitespace-nowrap"
              :class="
                item.konsept === 'Tanımlanmamış'
                  ? 'bg-danger underline hover:text-black p-0'
                  : ''
              "
            >
              <a
                v-if="item.konsept === 'Tanımlanmamış'"
                class="block h-full w-full"
                style="padding: 10px 15px"
                target="_blank"
                @click.stop
                tabindex="-1"
                :href="`${$baseURL}/?s=rehber&duzenle=${item.rehber_id}`"
              >
                {{ item.konsept }}
              </a>
              <span v-else>
                {{ item.konsept }}
              </span>
            </vs-td>
            <vs-td
              class="whitespace-nowrap"
              :class="
                item.unvan === 'Tanımlanmamış'
                  ? 'bg-danger underline hover:text-black p-0'
                  : ''
              "
            >
              <a
                v-if="item.unvan === 'Tanımlanmamış'"
                class="block h-full w-full"
                style="padding: 10px 15px"
                target="_blank"
                @click.stop
                tabindex="-1"
                :href="`${$baseURL}/?s=rehber&duzenle=${item.rehber_id}`"
              >
                {{ item.unvan }}
              </a>
              <span v-else>
                {{ item.unvan }}
              </span>
            </vs-td>
            <vs-td
              class="whitespace-nowrap"
              :class="
                item.teklif_yetkili === 'Tanımlanmamış'
                  ? 'bg-danger underline hover:text-black p-0'
                  : ''
              "
            >
              <a
                v-if="item.teklif_yetkili === 'Tanımlanmamış'"
                class="block h-full w-full"
                style="padding: 10px 15px"
                target="_blank"
                @click.stop
                tabindex="-1"
                :href="`${$baseURL}/?s=rehber&duzenle=${item.rehber_id}`"
              >
                {{ item.teklif_yetkili }}
              </a>
              <span v-else>
                {{ item.teklif_yetkili }}
              </span>
            </vs-td>
            <vs-td
              class="whitespace-nowrap"
              :class="
                item.teklif_yetkili_mail === 'Tanımlanmamış'
                  ? 'bg-danger underline hover:text-black p-0'
                  : ''
              "
            >
              <a
                v-if="item.teklif_yetkili_mail === 'Tanımlanmamış'"
                class="block h-full w-full"
                style="padding: 10px 15px"
                target="_blank"
                @click.stop
                tabindex="-1"
                :href="`${$baseURL}/?s=rehber&duzenle=${item.rehber_id}`"
              >
                {{ item.teklif_yetkili_mail }}
              </a>
              <span v-else>
                {{ item.teklif_yetkili_mail }}
              </span>
            </vs-td>
            <vs-td>
              <div class="flex flex-row space-x-2">
                <vx-tooltip text="Rehbere Git">
                  <a
                    :href="`${$baseURL}/?s=rehber&amp;duzenle=${item.rehber_id}`"
                    target="_blank"
                    tabindex="-1"
                  >
                    <feather-icon
                      icon="CornerUpRightIcon"
                      svgClasses="h-6 w-6 mb-1 stroke-current hover:text-primary"
                    />
                  </a>
                </vx-tooltip>
                <vx-tooltip text="Bu Firma için Kopyala">
                  <a
                    :href="`${$baseURL}/?s=teklifler&amp;kopyala=${$route.params.id}&amp;rehber_id=${item.rehber_id}`"
                    target="_blank"
                    tabindex="-1"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      svgClasses="h-6 w-6 mb-1 stroke-current hover:text-primary"
                    />
                  </a>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="flex flex-row justify-end">
        <wo-button
          text="Seçilileri Ekle"
          @click="handleTeklifGonderilecekFirmalarModal()"
        />
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import {
  getOfferTargetByConcept,
  getOfferTargetByPerson
} from '../helpers/ApiFactory.js';
import { errorDialog } from '@/helpers/errorDialogs';
import useCompaniesSendOffer from '../consumables/useCompaniesSendOffer.js';

import { ref, computed, watch } from '@vue/composition-api';

export default {
  props: {
    active: Boolean
  },
  setup(props, { root, emit }) {
    const { teklifGonderilecekFirmalarData, handleSelectedCompanies } =
      useCompaniesSendOffer();

    const selectData = [
      {
        tablo: 'konseptler',
        text: 'Konsept',
        key: 'konsept'
      },
      {
        tablo: 'teklif_firma&teklif_firma=1',
        text: 'Firma',
        key: 'unvan'
      },
      {
        tablo: 'teklif_firma&teklif_yetkili=1',
        text: 'Teklif Yetkili (Rehber)',
        key: 'teklif_yetkili'
      },
      {
        tablo: 'teklif_firma&teklif_yetkili_mail=1',
        text: 'Teklif Yetkili Mail (Rehber)',
        key: 'teklif_yetkili_mail'
      }
    ];
    const selectValue = ref(selectData[0].tablo);

    const quickSearchValue = ref('');
    const selectedCompanies = ref('');
    const handleQuickSearchValue = ({ isSelected, id, isim, unvan }) => {
      if (isSelected) {
        selectedCompanies.value = id;
        quickSearchValue.value = isim || unvan;
      } else if (!isSelected && selectedCompanies.value) {
        selectedCompanies.value = 0;
      }
    };

    const tableData = ref([]);

    const addToTableData = data => {
      if (data.length === 0) {
        errorDialog(
          'Bu firmaya ait rehber tanımlı değil veya teklif verilmesi için gerekli olan bilgiler eksiktir.'
        );
        return;
      }

      let addedItems = 0;

      for (const item of data) {
        item.rehber_id = selectedCompanies.value;
        if (
          !tableData.value.some(teklifItem => {
            return item.id === teklifItem.id;
          })
        ) {
          root.$set(tableData.value, tableData.value.length, item);
          addedItems++;
        }
      }
      if (!addedItems) {
        errorDialog(
          'Zaten listede olan bir rehberi tekrar eklemeye çalıştınız. Lütfen listeyi kontrol ediniz. Filtrelemeyi kullanarak listede ekli olan rehberi bulabilirsiniz.'
        );
      }
      selectedCompanies.value = 0;
      quickSearchValue.value = '';
    };

    const handleFetchButton = () => {
      if (selectValue.value === 'konseptler') {
        getOfferTargetByConcept(selectedCompanies.value)
          .then(addToTableData)
          .catch(() => {
            errorDialog('Lütfen geçerli bir konsept seçiniz.');
          });
      } else {
        getOfferTargetByPerson(selectedCompanies.value)
          .then(addToTableData)
          .catch(() => {
            errorDialog('Lütfen geçerli gönderilecek kişileri ekleyiniz.');
          });
      }
    };

    const tableFilter = ref('');
    const tableFilteredData = computed(() => {
      if (!tableData.value.length) {
        return [];
      }
      const filterKeys = selectData.reduce(
        (obj, item) => ((obj[item.tablo] = item.key), obj),
        {}
      );

      const filterKey = filterKeys[selectValue.value];

      return tableData.value.filter(item => {
        const filter = tableFilter.value.toLocaleLowerCase('tr');
        if (item[filterKey]) {
          return item[filterKey].toLocaleLowerCase('tr').includes(filter);
        }
        return false;
      });
    });

    const selectedCheckboxes = ref([]);

    const handleTeklifGonderilecekFirmalarModal = () => {
      tableData.value = tableData.value.filter(dataItem => {
        return selectedCheckboxes.value.some(
          checkboxItem => dataItem.id === checkboxItem.id
        );
      });
      const teklifGonderilecekFirmalarMarqueeData = [...tableData.value];
      handleSelectedCompanies(teklifGonderilecekFirmalarMarqueeData);
      emit('update:active', false);
    };

    // EDIT MODE
    const handleEditCompanyData = () => {
      for (const idx in teklifGonderilecekFirmalarData.value) {
        root.$set(
          tableData.value,
          idx,
          teklifGonderilecekFirmalarData.value[idx]
        );
        root.$set(
          selectedCheckboxes.value,
          idx,
          teklifGonderilecekFirmalarData.value[idx]
        );
      }
    };

    handleEditCompanyData();

    watch(
      () => teklifGonderilecekFirmalarData.value,
      () => {
        handleEditCompanyData();
      }
    );

    return {
      handleTeklifGonderilecekFirmalarModal,
      selectData,
      selectValue,
      quickSearchValue,
      handleQuickSearchValue,
      tableFilteredData,
      tableFilter,
      handleFetchButton,
      selectedCheckboxes,

      tableData
    };
  }
};
</script>
