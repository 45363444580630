import printDocument from './printDocument';

function renderImage(image) {
    if (image !== undefined) {
        const width = 150;
        const imageUrl = `${window.location.origin}/${image.image_path}${image.image_name}`;
        const img = new Image();
        img.src = imageUrl;
        let content = "";
        content += `<img src='${imageUrl}' width='${width}' heigth="125" style='align-self: end; margin-right: 30px;' />`;
        return content;
    } else {
        return '';
    }
}

export default function exportTable(objects, infoObject, image, caption, customTable) {
    let content = "";
    content += "<div style='font-family: Arial, Helvetica, sans-serif;'>";
    content += `<h1 style='font-size: 20px; margin-left: 30%; margin-top: 10px; color: red;'>${caption}</h1>`;
    content += "<div style='display: flex; flex-direction: row; justify-content: space-between; margin-top: 30px;'>";
    content += "<table style='font-size: 10px; margin-left: 50px;'>";
    if (infoObject) {
        const infoData = infoObject.data;
        const infoTitles = Object.keys(infoData);
        infoTitles.forEach(title => {
            content += "<tr>";
            content += `<th style="text-align: left;">${title}: </th><td style='text-align: left;'>${infoData[title]}</td>`;
            content += `</tr>`;
        });
    }
    content += "</table>";
    content += renderImage(image);
    content += `</div>`;
    objects.forEach((item, index) => {
        if (customTable && index === customTable.order) {
            content += customTable.table;
        }
        const object = item.data;
        content += "<table style='border: 1px; margin-top: 25px; width: 100%; border-collapse: collapse;'>";
        content += `<caption style='font-weight: bold; margin-bottom: 5px;'>${item.title}</caption>`;
        content += "<tr style='border: 1px solid black;'>";
        const rows = Object.keys(object);
        const isNested = typeof object[rows[0]] === 'object';
        const columns = isNested ?  Object.keys(object[rows[0]]) : rows;
        content += isNested ? "<th style='border: 1px solid black;'></th>" : '';
        columns.forEach(column => {
            content += `<th style='border: 1px solid black; text-align: center; min-width: 30px;'>${column}</th>`;
        });
        content += '</tr>';
        if (isNested) {
            rows.forEach(row => {
                const values = Object.keys(object[row]);
                content += "<tr style='border: 1px solid black;'>";
                content += `<td style='border: 1px solid black; text-align: right; min-width: 30px;'>${row}</td>`;
                values.forEach(value => {
                    content += `<td style='border: 1px solid black; text-align: right; min-width: 30px;'>${object[row][value]}</td>`;
                });
                content += '</tr>';
            });
        } else {
            content += "<tr style='border: 1px solid black;'>";
            Object.values(object).forEach(value => {
                content += `<td style='border: 1px solid black; text-align: right; min-width: 30px;'>${value}</td>`;
            });
            content += '</tr>';
        }
        content += '</table>';
        for (const key in item.footer) {
            content += `<div style='margin-top: 5px;'><span style='font-weight: bold;'>${key}: </span><span>${item.footer[key]}</span></div>`;
        }
    });
    content += "</div>";
   printDocument(content);
}