var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2 model-design-calculations"},[_c('wo-table',[_c('caption',{staticClass:"text-lg font-medium"},[_vm._v(" Toplamlar ")]),_vm._l((_vm.rows),function(row){return _c('wo-tr',{key:row.name},[(
          row.name !== 'Brüt' &&
          row.name !== 'Net' &&
          row.name !== 'Depodan Verilecek İpler'
        )?_c('wo-td',{staticClass:"text-left"},[_vm._v(_vm._s(row.name))]):(
          row.name === 'Brüt' ||
          row.name === 'Net' ||
          row.name === 'Depodan Verilecek İpler'
        )?_c('wo-td',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(row.name))]):_vm._e(),_c('wo-td',[_c('wo-int-input',{staticClass:"text-right-input",attrs:{"readonly":row.isReadonly},model:{value:(_vm.localState[row.name]),callback:function ($$v) {_vm.$set(_vm.localState, row.name, $$v)},expression:"localState[row.name]"}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }