<template>
  <WOModuleWrapper
    :moduleTitle="moduleConfig.title"
    informationModuleName="job_listing"
    :auth="moduleConfig.auth"
  >
    <JobListingForm />
  </WOModuleWrapper>
</template>

<script>
import WOModuleWrapper from '@/components/WO/WOModuleWrapper.vue';
import JobListingForm from './JobListingForm.vue';

export default {
  components: {
    WOModuleWrapper,
    JobListingForm
  },
  computed: {
    moduleConfig() {
      if (this.$route.params.id) {
        return {
          title: 'İş İlanı Düzenle',
          auth: 'is_ilani_duzenle'
        };
      } else {
        return { title: 'Yeni İş İlanı Ekle', auth: 'is_ilani_ekle' };
      }
    }
  }
};
</script>

<style scoped></style>
