import Vue from '@/main';

export function prevalentRenderer(item) {
  return `
    <a
      class="underlined-hovered-link"
      href="${Vue.$baseURL}/?s=cari&duzenle=${item.id}"
      >${item.kod}</a
    >
    <span> - ${item.isim}</span>
  `;
}
