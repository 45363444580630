import { numToTrStr } from '../NumberController';

export function stockCaliberCostRenderer(item) {
  return `
    <span class="font-semibold text-danger">
      ${numToTrStr(item.maliyet)} TL
    </span>
    <span>KF:</span>
    <span class="font-semibold text-primary">
      ${numToTrStr(item.kalibre_fiyat)} TL
    </span>
    <span> ${item.evrak_tarihi} - </span>
    <span> Kalan: 0.000000 - </span>
    <span> ${item.evrak_seri_sirano} - </span>
    <a href="${item.link}" class="underlined-hovered-link">
      Sayım Fişi
    </a>
  `;
}
