<template>
  <wo-tr>
    <wo-td v-if="entryTypeEditable">
      <wo-select
        :data="entryTypes"
        v-model="localState.entryType"
        @change="resetEntryData"
        elementClasses="w-32"
      >
        <template #default="{ item, index }">
          <wo-select-item
            :itemValue="index"
            :text="item"
            :key="`entry-types-new-${index}`"
          />
        </template>
      </wo-select>
    </wo-td>
    <wo-td>
      <wo-select
        :data="primaryMaterials"
        v-model="localState.primaryMaterial"
        elementClasses="w-28 m-auto"
      >
    <template #defaultValue>
        <wo-select-item :itemValue="0" text="- Seçiniz -" />
    </template>
        <template #default="{ item }">
          <wo-select-item
            :itemValue="item.id"
            :text="item.isim"
            :key="`primary-material-${item.id}`"
          />
        </template>
      </wo-select>
    </wo-td>
    <wo-td>
      <wo-select
        :data="processes"
        v-model="localState.process"
        elementClasses="w-28 m-auto"
      >
        <template #defaultValue>        
            <wo-select-item :itemValue="0" text="- Seçiniz -" />
        </template>
        <template #default="{ item }">
          <wo-select-item
            :itemValue="item.id"
            :text="item.isim"
            :key="`process-${item.id}`"
          />
        </template>
      </wo-select>
    </wo-td>    
    <wo-td>
      <wo-quick-search
        inputClasses="m-auto"
        v-model="localState.entryName"
        :queryParameters="formEntryConfig.queryParameters"
        @selected="(item) => formEntryConfig.handler(item)"
        :placeholder="formEntryConfig.placeholder"
        :itemRenderer="
          (item) =>
            nameAndCodeWithLinkRenderer(
              item,
              formEntryConfig.link,
              formEntryConfig.prefix
            )
        "
      />
    </wo-td>
    <wo-td>
      <wo-quick-search
        inputClasses="m-auto"
        v-model="localState.prevalentName"
        :queryParameters="`?tablo=cari&q=`"
        @selected="item => handlePrevalentQuickSearch(item)"
        :itemRenderer="prevalentRenderer"
      />
    </wo-td>
    <wo-td>
      <wo-float-input v-model="localState.amount" class="m-auto" decimal="3" />
    </wo-td>
    <wo-td v-if="localState.entryType === '2'">
      <wo-float-input v-model="localState.orderAmount" decimal="3" />
    </wo-td>
    <wo-td>
      <wo-select
        :data="stockUnits"
        v-model="localState.unit"
        elementClasses="w-28 m-auto"
        valueType="number"
      >
        <template #defaultValue>
          <wo-select-item :itemValue="0" text="- Seçiniz -" />
        </template>
        <template #default="{ item }">
          <wo-select-item
            :itemValue="item.id"
            :text="item.isim"
            :key="`unit-${item.id}`"
          />
        </template>
      </wo-select>
    </wo-td>
    <wo-td>
      <wo-float-input v-model="localState.cost" class="m-auto" decimal="3" />
    </wo-td>
    <wo-td>
      <wo-select
        :data="localExchangeRates"
        v-model="localState.currency"
        elementClasses="w-26"
      >
        <template #default="{ item }">
          <wo-select-item
            :itemValue="item.kod"
            :text="item.kod"
            :key="`currency-table-new-${item.kod}`"
          />
        </template>
      </wo-select>
    </wo-td>
    <wo-td>
      <wo-float-input v-model="localState.mainCurrencyPrice" :decimal="4" readonly class="m-auto" />
    </wo-td>
    <wo-td>
      <base-input v-model="localState.explanation" class="m-auto" />
    </wo-td>
    <wo-td>
      <slot name="processes" />
    </wo-td>
  </wo-tr>
</template>

<script>
import { computed, watch } from "@vue/composition-api";
import { nameAndCodeWithLinkRenderer } from "@/helpers/quickSearchListRenderers/qsNameAndCodeWithLink";
import { prevalentRenderer } from "@/helpers/quickSearchListRenderers/qsPrevalent";
import { useModelPlanlamaState } from "../../composables/useModelPlanlamaState";
// import { trStrToNum } from "@/helpers/NumberController";

export default {
  props: {
    source: [String, Object],
    stateKey: String,
    entryTypeEditable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { root, emit }) {
    const { 
    state, stockUnits, exchangeRates, entryTypes, primaryMaterials, processes
    } = useModelPlanlamaState();

    const localExchangeRates = computed(() => {
      return exchangeRates.value[props.stateKey];
    });

    const localState = computed(() => {
      if (props.source instanceof Object) {
        return props.source;
      }
      const source = props.source.split(".");
      if (source.length === 1) {
        return state[props.stateKey].details[source[0]];
      }
      return state[props.stateKey].details[source[0]][source[1]];
    });

    const calculateMainCurrencyPrice = () => {
      const currency = localState.value.currency;
      const currencyValue = parseFloat(
        localExchangeRates.value.find((a) => a.kod === currency).kur === 1
          ? 1
          : parseFloat(localExchangeRates.value.find((a) => a.kod === currency).kur).toFixed(3)
      ).toFixed(3);
      if (localState.value.cost !== 0 && localState.value.amount !== 0) {
        localState.value.mainCurrencyPrice =
          parseFloat(localState.value.cost.replace('.', '').replace(',', '.')) *
          parseFloat(localState.value.amount.replace('.', '').replace(',', '.')) *
          (parseFloat(currencyValue) / parseFloat(state[props.stateKey].info.currentCurrencyRate));
      }
    };

    watch(
      [
        () => localState.value.cost,
        () => localState.value.currency,
        () => localState.value.amount,
      ],
      () => {
        calculateMainCurrencyPrice();
      },      
    );

    const currentDepot = computed(() => {
      return state[props.stateKey].info.depot;
    });
    
    const handlePrevalentQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.prevalentID = item.id;
      } else if (!item.isSelected && localState.value.prevalentID) {
        localState.value.prevalentID = "";
        localState.value.prevalentName = "";
      }
    };

    const handleStockQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.entryID = item.id;
        localState.value.entryName = item.isim;
        localState.value.color = item.renk;
        localState.value.size = item.beden;
        localState.value.currency = item.para_birimi || window.anaparabirimi || "TL";
        if (item.birim) {
          localState.value.unit = item.birim;
        }
        if (item.fiyat) {
          localState.value.cost = item.fiyat;
        }
      } else if (!item.isSelected && localState.value.entryID) {
        localState.value.entryID = 0;
        localState.value.entryName = "";
      }
    };

    const handleExpenseQuickSearch = (item) => {
      if (item.isSelected) {
        localState.value.entryID = item.id;
        localState.value.entryName = item.gdr_isim;
      } else if (!item.isSelected && localState.value.entryID) {
        localState.value.entryID = 0;
        localState.value.entryName = "";
      }
    };

    const formEntryConfig = computed(() => {
      const isStock =
        +localState.value.entryType === 0 || +localState.value.entryType === 1;
      return {
        queryParameters: isStock
          ? `?tablo=stok&depo=${currentDepot.value}&q=`
          : "?tablo=giderler&q=",
        handler: isStock ? handleStockQuickSearch : handleExpenseQuickSearch,
        link: isStock ? "/?s=stok&duzenle=" : "/?s=giderler&duzenle=",
        prefix: isStock ? undefined : "gdr_",
        placeholder: isStock ? "Stok" : "Gider",
      };
    });
    
    const emitEntryType = () => {
      emit('entryType', localState.value.entryType);
    };

    const resetEntryData = () => {
      root.$set(localState.value, "entryID", 0);
      root.$set(localState.value, "entryName", "");
      root.$set(localState.value, "unit", 0);
      emitEntryType();
    };

    return {
      nameAndCodeWithLinkRenderer,
      prevalentRenderer,
      entryTypes,
      localExchangeRates,
      stockUnits,
      localState,
      calculateMainCurrencyPrice,
      handlePrevalentQuickSearch,
      formEntryConfig,
      resetEntryData,
      primaryMaterials,
      processes
    };
  },
};
</script>

<style lang="scss" scoped></style>
