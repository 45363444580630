<template>
  <div class="space-y-4">
    <CariFormSectionHeader> İletişim Bilgileri </CariFormSectionHeader>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Telefon - 1
        </template>
        <template #firstElement>
          <wo-tel-input v-model="state.contactFirstPhone" />
        </template>
        <template #secondLabel>
          Faks
        </template>
        <template #secondElement>
          <wo-tel-input v-model="state.contactFax" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Telefon - 2
        </template>
        <template #firstElement>
          <wo-tel-input v-model="state.contactSecondPhone" />
        </template>
        <template #secondLabel>
          Web Adresi
        </template>
        <template #secondElement>
          <base-input v-model="state.contactWeb" />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Cep
        </template>
        <template #firstElement>
          <wo-tel-input v-model="state.contactCell" />
        </template>
        <template #secondLabel>
          Genel/Sipariş Email
        </template>
        <template #secondElement>
          <base-input v-model="state.contactEmail" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Yetkili Telefon
        </template>
        <template #firstElement>
          <wo-tel-input v-model="state.contactAuthorizedPhone" />
        </template>
        <template #secondLabel>
          Yetkili E-Mail
        </template>
        <template #secondElement>
          <base-input v-model="state.contactAuthorizedEmail" />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>

    <CariFormQuadrupleFormWrapper>
      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Adres
        </template>
        <template #firstElement>
          <wo-textarea v-model="state.contactAddress" />
        </template>
        <template #secondLabel>
          Muhasebe E-Mail
        </template>
        <template #secondElement>
          <base-input v-model="state.contactAccountantEmail" />
        </template>
      </wo-double-form>

      <wo-double-form :labelSize="labelSize">
        <template #firstLabel>
          Müşteri E-Mail
        </template>
        <template #firstElement>
          <base-input v-model="state.contactCustomerEmail" />
        </template>
      </wo-double-form>
    </CariFormQuadrupleFormWrapper>
  </div>
</template>

<script>
import CariFormSectionHeader from '../common/CariFormSectionHeader.vue';
import CariFormQuadrupleFormWrapper from '../common/CariFormQuadrupleFormWrapper.vue';

import usePrevalentState from '../../consumables/usePrevalentState.js';

export default {
  components: {
    CariFormSectionHeader,
    CariFormQuadrupleFormWrapper
  },
  setup() {
    const { labelSize, state } = usePrevalentState();

    return {
      labelSize,
      state
    };
  }
};
</script>

<style lang="scss" scoped></style>
